// REACT
import React, { FunctionComponent, memo, useState, useEffect } from "react";
import { useT } from "@transifex/react";

// COMPONENTS
import { Row, Col } from "../../../../basic/grid";
import PhaseSixIcon from "../../../../basic/phaseSixIcon/PhaseSixIcon";

// STYLED COMPONENTS
import { Wrapper, ActionsWrapper, ActionClose, ActionSave, Input, InputError } from "./styles";

export type Props = {
    afterClose: () => void;
    onSave: (value: string) => Promise<void>;
};

const SubjectCreate: FunctionComponent<Props> = (props) => {
    const { afterClose, onSave } = props;
    const t = useT();
    const [isActive, setActive] = useState<boolean>(false);
    const [value, setValue] = useState<string>("");
    const [error, setError] = useState<string>("");

    useEffect(() => {
        setActive(true);
    }, []);

    const t_placeholder = t("e.g. animals", {});

    const closeSelf = () => {
        setActive(false);
        setTimeout(() => afterClose(), 300);
    };

    const onChange = ({ target: { value } }: any) => {
        setValue(value);
        setError("");
    };

    return (
        <Wrapper $active={isActive}>
            <Row vertical="center">
                <Col>
                    <Row>
                        <Col xs={12}>
                            <Input
                                value={value}
                                onChange={onChange}
                                placeholder={t_placeholder}
                                minLength={
                                    process.env.REACT_APP_INPUT_CHARS_MIN ? +process.env.REACT_APP_INPUT_CHARS_MIN : 1
                                }
                                maxLength={
                                    process.env.REACT_APP_INPUT_CHARS_MAX ? +process.env.REACT_APP_INPUT_CHARS_MAX : 254
                                }
                            />
                            {!!error && <InputError>{error}</InputError>}
                        </Col>
                    </Row>
                </Col>
                <Col xs="auto">
                    <ActionsWrapper>
                        <ActionClose
                            onClick={() => {
                                setActive(false);
                                closeSelf();
                            }}
                        >
                            <PhaseSixIcon name="close" />
                        </ActionClose>
                        <ActionSave
                            $disabled={!value}
                            onClick={() => {
                                if (!value) return;
                                onSave(value)
                                    .then(() => {
                                        closeSelf();
                                    })
                                    .catch((e: any) => {
                                        if (e) setError(e);
                                    });
                            }}
                        >
                            <PhaseSixIcon name="exercise-ok" />
                        </ActionSave>
                    </ActionsWrapper>
                </Col>
            </Row>
        </Wrapper>
    );
};

export default memo(SubjectCreate);
