import styled, { css } from "styled-components";
import PhaseSixIcon from "../../../basic/phaseSixIcon/PhaseSixIcon";

export const HeaderWrapper = styled.div`
    padding: ${({ theme: { base } }) => base.spacing} 0;
`;

const ButtonsStyles = css`
    cursor: pointer;

    &:hover {
        opacity: ${({ theme: { base } }) => base.hoverOpacity};
    }
`;

export const StyledPhaseSixButton = styled(PhaseSixIcon)`
    color: ${(props) => props.theme.colors.textOnBackground};
    cursor: pointer;
`;
export const BackButton = styled(PhaseSixIcon)`
    ${ButtonsStyles}
`;

export const CloseButton = styled(PhaseSixIcon)`
    ${ButtonsStyles};
    float: right;
`;

export const ProgressWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: ${({ theme: { base } }) => base.bigSize};
    color: ${({ theme: { colors } }) => colors.textOnBackground};
`;

export const ProgressResults = styled.div`
    > span:first-child {
        color: ${({ theme: { colors } }) => colors.primary};
    }
`;

export const ProgressContainer = styled.div`
    min-width: 280px;
    width: 400px;
    max-width: 100%;
    margin: 0 ${({ theme: { base } }) => base.spacingSmall};
`;

export const SubjectName = styled.div`
    max-width: 400px;
    font-size: ${({ theme: { base } }) => base.titleSize};
    color: ${({ theme: { colors } }) => colors.text};
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
`;

export const SubjectImage = styled.img`
    max-height: 35px;
`;
