// REACT
import React, { FunctionComponent } from "react";

// TYPES
import { AvatarsGroups, Avatar } from "p6m-avatars";

// COMPONENTS
import AvatarGroup from "./AvatarGroup";
import AvatarsContextWrapper from "../../../context/avatars";

// STYLED COMPONENTS
import { Wrapper } from "./styles";

type Props = {
    avatars: AvatarsGroups;
    userAvatar?: Avatar;
    saveAvatar: (avatar: Avatar) => void;
};

const SettingsAvatars: FunctionComponent<Props> = (props) => {
    const { avatars, userAvatar, saveAvatar } = props;

    return (
        <AvatarsContextWrapper>
            <Wrapper data-qa="settings-avatars">
                {Object.values(avatars).map((avatar) => (
                    <AvatarGroup
                        key={avatar.groupId}
                        onClick={saveAvatar}
                        group={avatar}
                        userAvatar={userAvatar}
                    />
                ))}
            </Wrapper>
        </AvatarsContextWrapper>
    );
};

export default SettingsAvatars;
