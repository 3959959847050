// REACT
import React, { FunctionComponent, PropsWithChildren, Children, memo } from "react";

// STYLED COMPONENTS
import { BadgeWrapper, BadgeItem } from "./styles";

export type Props = {
    count: number;
    type?: "default" | "red";
    style?: any;
    bigIcon?: boolean;
};

const Badge: FunctionComponent<PropsWithChildren<Props>> = (props) => {
    const { count, children, type = "default", bigIcon = false, ...otherProps } = props;
    const hasChildren: boolean = !!Children.count(children);

    return hasChildren ? (
        <BadgeWrapper bigIcon={bigIcon}>
            {children}
            <BadgeItem
                type={type}
                {...otherProps}
            >
                {count}
            </BadgeItem>
        </BadgeWrapper>
    ) : (
        <BadgeItem
            type={type}
            {...otherProps}
        >
            {count}
        </BadgeItem>
    );
};

export default memo(Badge);
