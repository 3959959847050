import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../store/store";
import ResponseConstants, { ResponseType } from "../../constants/ResponseConstants";
import { ResponseMessageKeys } from "../../components/connected/response";

interface ResponseState {
    show: boolean;
    text: string[];
    responseMessage?: ResponseMessageKeys;
    responseMessageReplacements?: { [key: string]: string }[];
    type: ResponseType;
}

const initialState: ResponseState = {
    show: false,
    text: [""],
    type: ResponseConstants.SUCCESS,
};

export const responseSlice = createSlice({
    name: "response",
    initialState,
    reducers: {
        showResponse: (
            state: ResponseState,
            {
                payload,
            }: PayloadAction<{
                type: ResponseType;
                text?: string[];
                responseMessage?: ResponseMessageKeys;
                responseMessageReplacements?: { [key: string]: string }[];
            }>
        ) => {
            const { type, text, responseMessage, responseMessageReplacements } = payload;
            state.type = type;
            if (text) state.text = text;
            state.responseMessage = responseMessage;
            state.responseMessageReplacements = responseMessageReplacements;
            state.show = true;
        },
        cancelResponse: (state: ResponseState) => {
            state.type = ResponseConstants.SUCCESS;
            state.text = [""];
            state.responseMessage = undefined;
            state.responseMessageReplacements = undefined;
            state.show = false;
        },
    },
});

/* EXPORTS */
export const { actions, reducer } = responseSlice;

export const selectors = {
    response: (state: RootState) => state.response,
};
