//DEPENDENCIES
import styled from "styled-components";

export const Content = styled.div`
    padding: ${({ theme: { base } }) => base.spacingSmall};

    p {
        margin: ${({ theme: { base } }) => base.spacingSmall} 0;
    }
`;

export const Footer = styled.div`
    padding: ${({ theme: { base } }) => base.spacingInContent} ${({ theme: { base } }) => base.spacing};
`;
