//DEPENDENCIES
import Cookie from "universal-cookie";

//TYPES
import { AvailableLanguageCodes } from "p6m-dictionary";
import { selectors } from "../redux/user/userSlice";

export const getPersistedDictionaryLanguage = (userId?: string) => {
    const cookie = new Cookie();

    const persistedDictionaryLanguage: AvailableLanguageCodes = cookie.get(`dictionaryLanguage_${userId}`);

    return !persistedDictionaryLanguage || !userId ? "en" : persistedDictionaryLanguage;
};

export const addPersistedDictionaryLanguage = ({
    language,
    userId,
}: {
    language: AvailableLanguageCodes;
    userId: string;
}) => {
    const cookie = new Cookie();
    cookie.set(`dictionaryLanguage_${userId}`, language);
};

