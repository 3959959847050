import React, { FunctionComponent } from "react";
import { T } from "@transifex/react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { Container, Row, Col } from "../../../../components/basic/grid";
import TimeLine, { Props as TimelineProps } from "../../../../components/complex/wordMatchGame/TimeLine/TimeLine";
import GameItem, { Props as GameItemProps } from "../../../../components/complex/wordMatchGame/GameItem/GameItem";
import Pagination, {
    Props as PaginationProps,
} from "../../../../components/complex/wordMatchGame/GamePagination/GamePagination";
import TimeStatistic from "../../../../components/complex/wordMatchGame/TimeStatistic/TimeStatistic";
import LoseModal from "../../../../components/complex/wordMatchGame/GameModals/LoseModal";
import VictoryModal from "../../../../components/complex/wordMatchGame/GameModals/VictoryModal";

import { Title, Background, BackIconContainer } from "../../styles";
import PhaseSixIcon from "../../../../components/basic/phaseSixIcon/PhaseSixIcon";

type Props = {
    gameStatus: "in progress" | "lose" | "win";
    cards: GameItemProps[];
    pagination: PaginationProps;
    timeline?: TimelineProps;
    modals: {
        newRecord: boolean;
        time: number;
        onExit: () => void;
        onPlay: () => void;
    };
};

const GameItemCol = styled(Col)`
    padding: ${({ theme: { base } }) => base.spacingInContent};
`;

const WordMatchGame: FunctionComponent<Props> = (props) => {
    const { gameStatus, cards, pagination, timeline = {}, modals } = props;

    return (
        <Container fullWidth>
            <Background>
                <Row vertical="center">
                    <Col
                        xs={12}
                        s="none"
                    >
                        <Row>
                            <Col xs="auto">
                                <BackIconContainer>
                                    <Link to="/games/wordMatch">
                                        <PhaseSixIcon name="arrow-left" />
                                    </Link>
                                </BackIconContainer>
                            </Col>
                            <Col xs="auto">
                                <Title>
                                    <T _str="Word Match" />
                                </Title>
                            </Col>
                        </Row>
                    </Col>
                    <Col
                        xs={4}
                        s="auto"
                    >
                        <Pagination {...pagination} />
                    </Col>
                    <Col
                        xs={8}
                        s="none"
                    >
                        <TimeStatistic {...timeline} />
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <TimeLine {...timeline} />
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <Row>
                            {gameStatus === "in progress" &&
                                cards.map((itemProps) => {
                                    return (
                                        <GameItemCol
                                            xs={6}
                                            s={4}
                                            m={3}
                                            key={itemProps.id}
                                        >
                                            <GameItem {...itemProps} />
                                        </GameItemCol>
                                    );
                                })}
                        </Row>
                    </Col>
                </Row>
            </Background>
            {gameStatus === "win" && <VictoryModal {...modals} />}
            {gameStatus === "lose" && <LoseModal {...modals} />}
        </Container>
    );
};

export default WordMatchGame;
