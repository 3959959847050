import styled from "styled-components";

export const Background = styled.div`
    height: 100%;
    width: 100%;
    padding: 0;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    overflow: hidden;
`;

export const StyledIframe = styled.iframe`
    display: block;
    box-sizing: border-box;
    width: 100%;
    height: 80vh;
    border: none;
`;

export const LoadingBox = styled.div`
    position: relative;
`;
