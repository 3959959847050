import styled from "styled-components";

export const Container = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: stretch;
`;

export const List = styled.div`
    margin-top: 25px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    overflow-y: scroll;
`;
export const Entry = styled.div<{ header?: boolean }>`
    padding: ${(props) => props.theme.base.spacing} 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: ${(props) => props.theme.colors.text};
    border-top: ${(props) => (props.header ? 0 : "1px")} solid ${(props) => props.theme.colors.textLight};
    > span {
        font-weight: ${(props) => (props.header ? "bold" : "normal")};
    }
`;
export const Question = styled.div`
    flex: 1;
`;
export const CorrectAnswer = styled.div`
    flex: 1;
`;
export const GivenAnswer = styled.div`
    flex: 1;
`;
