//DEPENDENCIES
import axios, { AxiosPromise } from "axios";

//UTILS
import { generateUuid } from "../helpers/Id";

//TYPES
import { IResponse } from "p6m-response";
import { ActivationDirection, SaveTestProps } from "p6m-activation";
import { IdToOwnerId } from "p6m-shared";
import { LastTestResult } from "p6m-tests";

export type ConsumeSharedTestResponse = {
    idToOwner: IdToOwnerId;
    userCardsTest: ConsumedTestInfo;
    revisionNumber: number;
};

export type ConsumeSharedTestError = {
    httpCode: number;
    exceptionClass: string;
    exceptionMessage: string;
};

export interface GetTestListResponse {
    objects: TestEntry[];
}

export type CardDirection = Exclude<ActivationDirection, "NONE">;

interface ConsumedTestInfo {
    createdDate: string;
    examResult: number;
    cardIds: IdToOwnerId[];
    subjectContentId?: IdToOwnerId;
    sharedTestId: {
        id: string | "";
        ownerId?: string;
    };
    shareLink?: string;
    direction: CardDirection;
    revisionNumber: number;
    modificationDate: string;
    changedByDirectly: string;
    changedByRequestEffect: string;
    testName?: string | "";
}

interface TestInfoV1 {
    userCardsTest: {
        cardIds: IdToOwnerId[];
        changedByDirectly: string;
        changedByRequestEffect: string;
        createdDate: string;
        direction: ActivationDirection;
        examResult: number;
        modificationDate: string;
        revisionNumber: number;
        shareLink?: string;
        subjectContentId?: IdToOwnerId;
        sharedTestId: IdToOwnerId;
        testName?: string | "";
        testDate: Date;
    };
    lastResult?: LastTestResult;
    revisionNumber: number;
}

interface SaveTestResultData {
    testId: string;
    cardsPracticed: number;
    cardsAnsweredRight: number;
    totalCardsInTest: number;
}

export interface TestResultDetails extends SaveTestResultData {
    testDate: string;
    revisionNumber: number;
    modificationDate: string;
    changedByDirectly: string;
    changedByRequestEffect: string;
}

type TestResult = [IdToOwnerId, TestResultDetails];

export interface TestResultsData {
    objects: TestResult[];
    revisionNumber: number;
}

export async function saveTest(data: SaveTestProps): Promise<string> {
    const newTestId = generateUuid();
    await axios.post(`/cardsTest/${newTestId}`, data);

    return newTestId;
}

export async function saveTestResult(data: SaveTestResultData) {
    return axios.post(`/cardsTestResult/${generateUuid()}`, {
        ...data,
        testDate: new Date().toISOString(),
    });
}

export function consumeSharedTest({
    teacherId,
    testId,
}: {
    teacherId: string;
    testId: string;
}): AxiosPromise<IResponse<ConsumeSharedTestResponse> | ConsumeSharedTestError> {
    return axios.get(`/cardsTestConsume/${teacherId}/${testId}`);
}

export type TestEntry = [IdToOwnerId, TestInfoV1];

export function getTestsList() {
    return axios.get<IResponse<GetTestListResponse>>("/v1/cardsTestList");
}

export const getTestResults = async ({ testId }: { testId: string }) => {
    return await axios.get<IResponse<TestResultsData>>(`/cardsTestResults/${testId}`);
};

export interface UpdateTestProps {
    testName: string;
    cardIds: IdToOwnerId[];
    subjectContentId: IdToOwnerId;
    direction: ActivationDirection;
}

export function updateTest(testId: string, props: UpdateTestProps) {
    return axios.post<IResponse<unknown>>("/cardsTest/" + testId, props);
}

export function deleteTest(testId: string) {
    return axios.delete("/cardsTest/" + testId);
}

export interface FetchSharedTestResponse {
    sharedTestId: IdToOwnerId;
    groupId: string;
    testName: string;
    createdDate: string;
    examResult: number;
    cardIds: IdToOwnerId[];
    subjectContentId: IdToOwnerId;
    shareLink: string;
    direction: string;
    revisionNumber: number;
    modificationDate: string;
    changedByDirectly: string;
    changedByRequestEffect: string;
}

export const fetchSharedTest = ({ testId, userId }: { userId: string; testId: string }) => {
    return axios.get<IResponse<FetchSharedTestResponse>>(`/sharedTest/${userId}/${testId}`);
};
