// REACT
import React, { useCallback } from "react";

import { ampli } from "../../../ampli";

// REDUX
import { useDispatch, useSelector } from "react-redux";
import { actions as themeActions, selectors as themeSelectors } from "../../../redux/theme/themeSlice";
import { selectors as goalSelectors } from "../../../redux/goals/goalsSlice";

// TYPES
import { IUserGoals } from "p6m-goalsData";

// COMPONENTS
import SettingsAppearance from "./SettingsAppearance";

// UTILS
import { Theme } from "../../../themes/constants";
import { getAmplitudeThemeName } from "../../../helpers/Tracking";

const SettingsAppearanceView: React.FC = () => {
    const userGoals: IUserGoals = useSelector(goalSelectors.goals) || ({} as IUserGoals);
    const currentTheme: Theme = useSelector(themeSelectors.themeName);

    const dispatch = useDispatch();

    const updateTheme = useCallback(
        async (newTheme: Theme) => {
            const ampliThemeName = getAmplitudeThemeName(newTheme);
            ampli.selectedSkin({
                skin: ampliThemeName,
            });
            
            dispatch(themeActions.setThemeName(newTheme));
        },
        [dispatch]
    );

    const appearanceProps = {
        currentTheme,
        userGoals,
        updateTheme,
    };

    return <SettingsAppearance {...appearanceProps} />;
};

export default SettingsAppearanceView;
