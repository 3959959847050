// DEPENDENCIES
import React, { FunctionComponent, useState, useCallback, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useT } from "@transifex/react";

// REDUX
import { actions as subjectsActions, selectors as subjectsSelector } from "../../redux/subjects/subjectsSlice";
import { selectors as testsSelector } from "../../redux/tests/testsSlice";

//HOOKS
import { useRequest } from "../../hooks/useRequest";

//UTILS
import {
    PromiseResult,
    Result,
    ampli,
    ActivationUnitsPage14Properties,
    ActivationSummaryPage34Properties,
} from "../../ampli";

//NETWORK
import { getSubjectUnitCards } from "../../networking/subjects";

// TYPES
import { SubjectUnitCard } from "p6m-subjects";
import { ActivationDirection } from "p6m-activation";

// COMPONENTS
import { ActivationSummary } from "../../components/connected/activationSummary";
import { ActivationList } from "../../components/connected/activationList";
import Modal from "../../components/basic/modal/Modal";
import { Row, Col } from "../../components/basic/grid";
import { ActivationNavigationButtons } from "../../components/connected/activationNavigationButtons";

// HOOKS
import { useFirstPracticeResult } from "../../hooks/useFirstPracticeResult";

//STYLES
import { ModalFooter, ExplanationText, FullHeightContainer } from "./styles";
import { BlankButton, FilledButton } from "../../components/basic/button/Button";

export type TResult = {
    cards?: SubjectUnitCard[];
    type?: number;
    direction?: number;
};

type Props = {
    isPrepareForTest?: boolean;
};

export enum activationSteps {
    LIST = "LIST",
    SUMMARY = "SUMMARY",
}

export const activationDirectionList: ActivationDirection[] = ["NORMAL", "BOTH", "OPPOSITE", "NONE"]; // todo: will this work with "NONE"?? (NONE should actually be -1)

const Activation: FunctionComponent<Props> = (props) => {
    const { subjectId } = useParams<{ subjectId: string }>();
    const { isPrepareForTest = false } = props;
    const dispatch = useDispatch();
    const t = useT();
    const fetchCards = useRequest(getSubjectUnitCards);

    const hasFirstPractice = useFirstPracticeResult();
    const hasActivatedCards = useSelector(subjectsSelector.hasActivatedCards);
    const activationMetaData = useSelector(testsSelector.activationMetaData);

    const [currentStep, setStep] = useState<activationSteps>(
        !!activationMetaData ? activationSteps.SUMMARY : activationSteps.LIST
    );
    const [showModal, setShowModal] = useState(false);
    const [result, setResult] = useState<TResult>({ type: 0, direction: 0 });

    const info_t = t(
        "Do you really want to add {number} cards for practice? We'd advice you to start with a maximum of 20 cards, you can always activate more.",
        { number: (result.cards || []).length, _tags: "paragraph, modal, explanation" }
    );
    const title_t = t("Recommended activation", { _tags: "button" });
    const addCards_t = t("Add only 20 cards (recommended)", { _tags: "button" });
    const addAllCards_t = t("Add all cards", { _tags: "button" });

    const onChange = useCallback((newResult: TResult) => {
        setResult((prev) => ({ ...prev, ...newResult }));
    }, []);

    const goToActivationList = useCallback(() => setStep(activationSteps.LIST), []);

    const goToActivationSummary = useCallback(() => {
        if (!showModal) {
            if ((result.cards || []).length > 20 && !isPrepareForTest) {
                return setShowModal(true);
            }
        }
        setShowModal(false);
        setStep(activationSteps.SUMMARY);
    }, [isPrepareForTest, result, showModal]);

    const closeModal = useCallback(() => {
        setShowModal(false);
    }, []);

    useEffect(() => {
        if (!activationMetaData) return;
        if (currentStep === activationSteps.LIST) {
            goToActivationSummary();
            dispatch(subjectsActions.setActiveSubjectId(subjectId));
        }

        const hydrateResult = async () => {
            const cardIds: string[] = activationMetaData!.cardIds.map((idToOwnerId) => idToOwnerId.id);
            const fetchedCards = await fetchCards({
                subjectId: subjectId,
                filterMode: "LIBRARY",
                cards: cardIds,
            });

            // todo: please refactor some day
            const directionAsNumber = activationDirectionList.indexOf(activationMetaData!.direction);

            setResult({ direction: directionAsNumber, cards: fetchedCards.data.replyContent.cards });
        };

        hydrateResult();
    }, [dispatch, activationMetaData]);

    useEffect(() => {
        //Once the user has gone through the onboarding, they know how to do it and there’s no need for us to track it.
        if (!hasFirstPractice && !hasActivatedCards) {
            const events: {
                [key in activationSteps]: (
                    options?: ActivationUnitsPage14Properties | ActivationSummaryPage34Properties
                ) => PromiseResult<Result>;
            } = {
                [activationSteps.LIST]: ampli.activationUnitsPage14.bind(ampli),
                [activationSteps.SUMMARY]: ampli.activationSummaryPage34.bind(ampli),
            };

            events[currentStep]();
        }
    }, [currentStep]);

    const openCardsOverloadWarningModal = useCallback(
        () => (
            <ModalFooter>
                <Row horizontal="end">
                    <Col xs="auto">
                        <BlankButton
                            onClick={goToActivationSummary}
                            textSize="xl"
                        >
                            {addAllCards_t}
                        </BlankButton>
                    </Col>
                    <Col xs="auto">
                        <FilledButton
                            onClick={() => {
                                setResult((prevResult) => {
                                    const { cards = [] } = prevResult;
                                    const cardsSlice = cards.slice(0, 20);
                                    return {
                                        ...prevResult,
                                        cards: cardsSlice,
                                    };
                                });
                                goToActivationSummary();
                            }}
                            textSize="xl"
                        >
                            {addCards_t}
                        </FilledButton>
                    </Col>
                </Row>
            </ModalFooter>
        ),
        [goToActivationSummary, addAllCards_t, addCards_t]
    );

    return (
        <>
            <FullHeightContainer fullWidth>
                {currentStep === activationSteps.LIST ? (
                    <ActivationList
                        subjectId={subjectId}
                        onChange={onChange}
                        isPrepareForTest={isPrepareForTest}
                        {...result}
                    />
                ) : (
                    <ActivationSummary
                        subjectId={subjectId}
                        onChange={onChange}
                        isPrepareForTest={isPrepareForTest}
                        {...result}
                    />
                )}

                <ActivationNavigationButtons
                    activationStep={currentStep}
                    isPrepareForTest={isPrepareForTest}
                    result={result}
                    onGoToActivationList={goToActivationList}
                    onGoToActivationSummary={goToActivationSummary}
                />
            </FullHeightContainer>
            {showModal && (
                <Modal
                    active
                    afterClose={closeModal}
                    title={title_t}
                    footerComponent={openCardsOverloadWarningModal}
                >
                    <ExplanationText>{info_t}</ExplanationText>
                </Modal>
            )}
        </>
    );
};

export default Activation;
