// REACT
import React from "react";
import { T, useT } from "@transifex/react";

//COMPONENTS
import CollapsableWordDetails from "../../../components/complex/collapsableWordDetails/CollapsableWordDetails";

// TYPES
import { DictionaryWordResults } from "p6m-dictionary";

// STYLED COMPONENTS
import {
    CollapsableWordDetailsContainer,
    Credits,
    HeadWord,
    SortedClass,
    SortedClassContainer,
    TitleContainer,
    WordsContainer,
    WordDetailsContainer,
} from "./styles";
import { NoResultsContainer, NoResultsText } from "../../../views/dictionary/styles";
import PhaseSixIcon from "../../basic/phaseSixIcon/PhaseSixIcon";

export interface WordDetailsProps {
    wordDetails: DictionaryWordResults;
    activeWordClassIndex: number;
    activeWordIndex: number;
    activeExampleIndex: number;
    exampleClicked: (wordClassIndex: number, wordIndex: number, exampleIndex: number) => void;
}

const WordDetails: React.FC<WordDetailsProps> = (props) => {
    const t = useT();
    const t_noResultsText = t("No results were found.", { _tags: "noResultsText" });

    const createMarkdown = (htmlString: string) => {
        return { __html: htmlString };
    };

    const currentYear: number = new Date().getFullYear();

    const motherTongueData =
        !!props.wordDetails.languages && props.wordDetails.languages.length ? props.wordDetails.languages[0] : false;
    const sortedClasses = motherTongueData && !!motherTongueData.sortedClasses ? motherTongueData.sortedClasses : [];
    const wordClasses = motherTongueData && !!motherTongueData.wordClasses ? motherTongueData.wordClasses : {};

    const doWordDetailsExist = !!sortedClasses.length && !!Object.keys(wordClasses).length;

    return (
        <WordDetailsContainer>
            {doWordDetailsExist ? (
                sortedClasses.map((sortedClass, wordClassIndex) => {
                    if (wordClasses[sortedClass]) {
                        const wordClass = wordClasses[sortedClass];
                        const headWord = wordClasses[sortedClass][0].headword;
                        return (
                            <SortedClassContainer key={"" + wordClassIndex}>
                                <TitleContainer>
                                    <HeadWord dangerouslySetInnerHTML={createMarkdown(headWord)} />
                                    <SortedClass>{sortedClass}</SortedClass>
                                </TitleContainer>
                                <WordsContainer>
                                    {wordClass.map((word, wordIndex) => {
                                        const multipleExamples = word.examples.length > 1;
                                        return (
                                            <CollapsableWordDetailsContainer key={"" + wordIndex}>
                                                {word.examples.map((example, exampleIndex) => {
                                                    if (exampleIndex === 0 || props.activeWordIndex === wordIndex) {
                                                        return (
                                                            <CollapsableWordDetails
                                                                key={"" + exampleIndex}
                                                                word={word.header}
                                                                active={
                                                                    wordIndex === props.activeWordIndex &&
                                                                    exampleIndex === props.activeExampleIndex &&
                                                                    wordClassIndex === props.activeWordClassIndex
                                                                }
                                                                border={
                                                                    wordIndex === props.activeWordIndex &&
                                                                    wordClassIndex === props.activeWordClassIndex
                                                                }
                                                                wordClassIndex={wordClassIndex}
                                                                wordIndex={wordIndex}
                                                                exampleIndex={exampleIndex}
                                                                exampleClicked={props.exampleClicked}
                                                                exampleSource={example.source}
                                                                exampleTarget={example.target}
                                                                multipleExamples={multipleExamples}
                                                            />
                                                        );
                                                    }
                                                    return undefined;
                                                })}
                                            </CollapsableWordDetailsContainer>
                                        );
                                    })}
                                </WordsContainer>
                            </SortedClassContainer>
                        );
                    }
                    return undefined;
                })
            ) : (
                <NoResultsContainer>
                    <PhaseSixIcon name={"search"} />
                    <NoResultsText>{t_noResultsText}</NoResultsText>
                </NoResultsContainer>
            )}
            <Credits>
                <T
                    _str="© PONS Ltd., Stuttgart {ponsYear}. All Rights reserved."
                    ponsYear={currentYear}
                    _tags="dictionary,pons"
                />
            </Credits>
        </WordDetailsContainer>
    );
};

export default WordDetails;
