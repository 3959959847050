//LIBRARIES
import React, { useState } from "react";
import styled from "styled-components";
import { useT } from "@transifex/react";

//COMPONENTS
import { DefaultHeader } from "../../basic/modal/DefaultHeader";

//TYPES
import { GlobalModalProps } from "../../../helpers/Modal";

//STYLES
import { BlankButton, FilledButton } from "../../basic/button/Button";

interface ConfirmEmailModalProps extends GlobalModalProps {
    userEmail: string;
    onChangeEmail: (userEmail: string) => any;
    onResendEmail: (userEmail: string) => any;
}

const Background = styled.div`
    height: 100%;
    width: 100%;
    padding: 0;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    overflow: hidden;
`;

const EmailModalWrapper = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0.5em;
    font-size: ${(props) => props.theme.base.bigSize};
    background: ${(props) => props.theme.colors.backgroundContrast};
    gap: ${({ theme }) => theme.base.smallSize};
`;

const ChangeEmailRow = styled.div`
    display: flex;
    align-items: center;
`;

const EmailFieldWrapper = styled.div`
    flex: 3;
    display: flex;
    align-items: center;
    justify-content: start;
    min-height: 1.5em;
`;

const InputLabel = styled.span`
    color: ${(props) => props.theme.colors.text};
    margin-right: 1em;
`;

const MailInput = styled.input`
    flex: 1;
    margin-right: 1em;
    padding: ${(props) => props.theme.base.spacingInContent};
    border-radius: ${(props) => props.theme.base.borderRadiusSmall};
    border: thin solid ${(props) => props.theme.colors.primary};
    box-shadow: none;
    display: inline;
    color: ${(props) => props.theme.colors.text};
    font-size: ${(props) => props.theme.base.bigSize};

    &:focus {
        outline: none;
    }
`;

const ActionsRowWrapper = styled.div`
    display: flex;
    justify-content: end;
`;

const CancelButton = styled(FilledButton)`
    min-width: 100px;
    max-width: 15%;
`;

const emailIsValid = function (email: string) {
    const validEmailRegex = /^[\w-+\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
    return validEmailRegex.test(email);
};

const ConfirmEmailModal: React.FC<ConfirmEmailModalProps> = (props) => {
    const t = useT();

    const [userEmail, setUserEmail] = useState(props.userEmail);
    const [confirmDisabled, setConfirmDisabled] = useState(true);

    const handleUserEmailChange = (newEmail: string) => {
        setConfirmDisabled(
            typeof newEmail == "undefined" || newEmail === "" || newEmail === props.userEmail || !emailIsValid(newEmail)
        );
        setUserEmail(newEmail);
    };

    const onChangeEmail = () => {
        props.onChangeEmail(userEmail);
        props.close();
    };
    const onResendEmail = () => {
        props.onResendEmail(userEmail);
        props.close();
    };

    const emailConfirm_t = t("Confirm my email", { _tags: "userMenu,header" });
    const emailChange_t = t("Change Email", { _tags: "userMenu, confirmEmailModal" });
    const emailResend_t = t("Resend Email", { _tags: "userMenu, confirmEmailModal" });
    const cancel_t = t("Cancel", { _tags: "userMenu, confirmEmailModal" });

    return (
        <Background>
            <DefaultHeader
                title={emailConfirm_t}
                close={props.close}
            />
            <EmailModalWrapper>
                <ChangeEmailRow>
                    <EmailFieldWrapper>
                        <InputLabel>Email:</InputLabel>
                        <MailInput
                            type="text"
                            value={userEmail}
                            onChange={(e) => {
                                handleUserEmailChange(e.target.value.trim());
                            }}
                        />
                    </EmailFieldWrapper>
                    <BlankButton
                        highlighted
                        onClick={onChangeEmail}
                        bold
                        disabled={confirmDisabled}
                        useModalZIndex
                        upperCase
                    >
                        {emailChange_t}
                    </BlankButton>
                    <BlankButton
                        highlighted
                        onClick={onResendEmail}
                        bold
                        useModalZIndex
                        upperCase
                    >
                        {emailResend_t}
                    </BlankButton>
                </ChangeEmailRow>
                <ActionsRowWrapper>
                    <CancelButton
                        highlighted
                        onClick={props.close}
                        bold
                        useModalZIndex
                        upperCase
                    >
                        {cancel_t}
                    </CancelButton>
                </ActionsRowWrapper>
            </EmailModalWrapper>
        </Background>
    );
};

export default ConfirmEmailModal;
