// REACT
import React from "react";

// STYLED COMPONENTS
import { UserAvatarContainer, UserAvatarImage, UserAvatarIcon } from "./styles";

interface UserAvatarProps {
    userAvatar: string | undefined;
    size?: TAvatarSize;
    isOnBackground?: boolean;
    highlighted?: boolean;
    onClick?: () => void;
}

export type TAvatarSize = "standard" | "medium" | "big";

const UserAvatar: React.FC<UserAvatarProps> = (props) => {
    const { children, userAvatar, isOnBackground = false, size = "standard", highlighted = false, onClick } = props;

    return (
        <UserAvatarContainer
            size={size}
            isOnBackground={isOnBackground}
            clickable={!!onClick}
            onClick={onClick ?? (() => {})}
        >
            {!!userAvatar ? (
                <UserAvatarImage
                    src={userAvatar}
                    alt="user-avatar"
                />
            ) : (
                <UserAvatarIcon
                    name={"user-avatar"}
                    size={size === "standard" ? "medium" : "big"}
                    highlighted={highlighted}
                />
            )}
            {children}
        </UserAvatarContainer>
    );
};

export default UserAvatar;
