import React from "react";
import { T } from "@transifex/react";
import { Row, Col } from "../../../components/basic/grid";
import KeyboardImg from "../../../assets/img/keyboard-arrows.png";
import {
    BaseContainer,
    GameContainer,
    GameMessage,
    MessageText,
    LowerMessageButtons,
    KeepPlayingButton,
    GridContainer,
    GridRow,
    GridCell,
    TitleContainer,
    RightContainer,
    ScoresContainer,
    ScoreContainer,
    GameHint,
    RestartButton,
} from "./styles";

import "./game-2048-styles.css";

export interface Game2048TemplateProps {
    onRestart: () => void;
}

const Game2048Template: React.FC<Game2048TemplateProps> = (props) => (
    <BaseContainer>
        <Row>
            <Col
                xs={12}
                l="none"
            >
                <GameContainer className="game-container">
                    <GameMessage className="game-message">
                        <MessageText />
                        <LowerMessageButtons style={{ display: "none" }}>
                            <KeepPlayingButton className="keep-playing-button">
                                <T _str="Keep going" />
                            </KeepPlayingButton>
                            <div className="retry-button">
                                <T _str="Try again" />
                            </div>
                        </LowerMessageButtons>
                    </GameMessage>

                    <GridContainer>
                        <GridRow>
                            <GridCell />
                            <GridCell />
                            <GridCell />
                            <GridCell />
                        </GridRow>
                        <GridRow>
                            <GridCell />
                            <GridCell />
                            <GridCell />
                            <GridCell />
                        </GridRow>
                        <GridRow>
                            <GridCell />
                            <GridCell />
                            <GridCell />
                            <GridCell />
                        </GridRow>
                        <GridRow>
                            <GridCell />
                            <GridCell />
                            <GridCell />
                            <GridCell />
                        </GridRow>
                    </GridContainer>
                    <TitleContainer className="tile-container" />
                </GameContainer>
            </Col>
            <Col xs="auto">
                <RightContainer>
                    <ScoresContainer>
                        <ScoreContainer className="score-container">
                            <b>0</b>
                            <br />
                            <span>
                                <T _str="Score" />
                            </span>
                        </ScoreContainer>
                        <ScoreContainer className="best-container">
                            <b>0</b>
                            <br />
                            <span>
                                <T _str="Highscore" />
                            </span>
                        </ScoreContainer>
                    </ScoresContainer>
                    <GameHint>
                        <img
                            src={KeyboardImg}
                            alt="keyboard"
                        />
                        <p>
                            <T _str="You can control the game using the arrow keys on your keyboard" />
                        </p>
                    </GameHint>
                    <RestartButton
                        className="restart-button button"
                        onClick={props.onRestart}
                    >
                        <T _str="New Game" />
                    </RestartButton>
                </RightContainer>
            </Col>
        </Row>
    </BaseContainer>
);

export default Game2048Template;
