// REACT
import React from "react";

// STYLED COMPONENTS
import { Range } from "./styles";

const RangeSlider: React.FC<React.InputHTMLAttributes<HTMLInputElement>> = (props) => {
    return (
        <Range
            type="range"
            {...props}
        />
    );
};

export default RangeSlider;
