// REACT
import React, { useCallback } from "react";

// COMPONENTS
import SettingsGeneral from "./SettingsGeneral";

// REDUX
import { useDispatch, useSelector } from "react-redux";
import { actions as userActions, selectors as userSelectors } from "../../../redux/user/userSlice";

// NETWORKING
import { SaveUserPreferencesParams } from "p6m-networking";

// TYPES
import { UserPreferences } from "p6m-user";

const SettingsGeneralView: React.FC = () => {
    const userPreferences = useSelector(userSelectors.userPreferences);

    const dispatch = useDispatch();

    const updateUserPreferences = useCallback(
        async (newPreferences: UserPreferences) => {
            let request: SaveUserPreferencesParams = {
                newPreferences: newPreferences,
                oldPreferences: { ...userPreferences },
            };
            dispatch(userActions.saveUserPreferences(request));
        },
        [userPreferences, dispatch]
    );

    async function updateUseFirstName(value: boolean) {
        let updatedPreference: UserPreferences = { ...userPreferences };
        updatedPreference["useFirstName"] = value;
        await updateUserPreferences(updatedPreference);
    }

    return (
        <SettingsGeneral
            userPreferences={userPreferences}
            parentSettings={userPreferences.parentSettings}
            updateUseFirstName={updateUseFirstName}
        />
    );
};

export default SettingsGeneralView;
