import styled from "styled-components";

type YPositions = "top" | "bottom";
type XPositions = "center" | "left" | "right";

export type TPosition = [YPositions, XPositions];

export const Wrapper = styled.div`
    position: relative;
`;

export const ChildWrapper = styled.div`
    > span {
        color: ${({ theme: { colors } }) => colors.text};
        cursor: pointer;
        font-size: ${({ theme: { base } }) => base.titleSize};
    }
`;

export const MenuWrapper = styled.div<{ position: TPosition; visible: boolean }>`
    position: absolute;
    z-index: 2;
    left: 50%;
    min-width: 200px;
    transition: opacity ${({ theme: { base } }) => base.transition};
    opacity: ${({ visible }) => +visible};

    ${({ position }) =>
        position.reduce((result: string, val: YPositions | XPositions) => {
            switch (val) {
                case "top":
                    return (
                        result +
                        `
                top: 0;
            `
                    );
                case "bottom":
                    return (
                        result +
                        `
                bottom: 0;
            `
                    );
                case "center":
                    return (
                        result +
                        `
                transform: translateX(-50%);
            `
                    );
                case "right":
                    return (
                        result +
                        `
                left: auto;
                right: 0;
            `
                    );
                case "left":
                    return (
                        result +
                        `
                left: 0;
            `
                    );
                default:
                    return result;
            }
        }, "")}
`;
