// LIBRARIES
import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { ampli } from "../ampli";

//NETWORK
import { consumeSharedTest, ConsumeSharedTestResponse } from "../networking/tests";

//REDUX
import { actions as testsActions } from "../redux/tests/testsSlice";
import { actions as responseActions } from "../redux/response/responseSlice";
import { actions as subjectsActions } from "../redux/subjects/subjectsSlice";

//UTILS
import { getShowWelcomeScreenData } from "../helpers/GetShowWelcomeScreen";

//TYPES
import { ResponseMessageKeys } from "../components/connected/response";
import { SharedTestMetaData } from "p6m-tests";
import { IResponse } from "p6m-response";

export const useSharedTestConsumption = ({ sharedTestMetaData }: { sharedTestMetaData: SharedTestMetaData }) => {
    const dispatch = useDispatch();

    const closeSubjectAssignmentModal = useCallback(
        (props: { reason?: "ERROR" | "USER" } = { reason: "USER" } ) => {
            const {reason} = props;
            dispatch(testsActions.setSharedTestMetaData(null));
            dispatch(subjectsActions.setSubjectAssignmentModal(null));

            if (reason !== "ERROR") {
                ampli.rejectedExercise({
                    test_origin: "teacher",
                    exercise_id: sharedTestMetaData.testId,
                });

                dispatch(
                    responseActions.showResponse({
                        type: "SUCCESS",
                        responseMessage: ResponseMessageKeys.SHARED_TEST_CONSUMPTION_CANCELED,
                    })
                );
            } else {
                dispatch(
                    responseActions.showResponse({
                        type: "ERROR",
                        responseMessage: ResponseMessageKeys.SHARED_TEST_DOESNT_EXIST,
                    })
                );
            }
        },
        [dispatch]
    );

    const handleSharedTestConsumption = useCallback(async () => {
        const consumingResult = await consumeSharedTest({
            teacherId: sharedTestMetaData.teacherId,
            testId: sharedTestMetaData.testId,
        });

        if (consumingResult.data.httpCode !== 200) {
            closeSubjectAssignmentModal({ reason: "ERROR" });
            return { success: false };
        } else {
            const { deleteWelcomeScreenCookie } = getShowWelcomeScreenData();
            deleteWelcomeScreenCookie();
        }

        const successfulResponse = consumingResult.data as IResponse<ConsumeSharedTestResponse>;

        dispatch(
            testsActions.setActivationMetaData({
                testId: successfulResponse.replyContent.idToOwner.id,
                cardIds: successfulResponse.replyContent.userCardsTest.cardIds,
                direction: successfulResponse.replyContent.userCardsTest.direction,
            })
        );
        return { success: true };
    }, [dispatch, closeSubjectAssignmentModal, sharedTestMetaData]);

    return { handleSharedTestConsumption, closeSubjectAssignmentModal };
};
