import { TIconNames } from "../components/basic/phaseSixIcon/PhaseSixIcon";
import { StyleTheme } from "p6m-themes";

export const getScoreByTime = (time: number): number => {
    if (time === 0) return 0; // 0 time means not played yet
    if (time < 50) return 5;
    if (time < 75) return 4;
    if (time < 100) return 3;
    if (time < 135) return 2;
    if (time < 180) return 1;
    return 0;
};

export const getMaxTimeByScore = (score: number): number => {
    switch (score) {
        case 5:
            return 50;
        case 4:
            return 75;
        case 3:
            return 100;
        case 2:
            return 135;
        case 1:
        case 0:
        default:
            return 180;
    }
};

export const getScoreColor = (score: number, theme: StyleTheme): string => {
    switch (score) {
        case 5:
            return theme.colors.primary ?? "#fff";
        case 2:
        case 1:
            return theme.colors.textLight ?? "#fff";
        default:
            return theme.colors.text ?? "#fff";
    }
};

export const getScoreIconName = (score: number): TIconNames => {
    switch (score) {
        case 5:
        case 4:
        case 3:
        case 2:
            return "cup";
        case 1:
            return "leaderboard";
        default:
            return "clock";
    }
};

export const getScoreTitle = (score: number): string => {
    switch (score) {
        case 5:
            return "Impressive";
        case 4:
            return "Very Good";
        case 3:
            return "Good";
        case 2:
            return "Improvable";
        case 1:
            return "Very Improvable";
        default:
            return "";
    }
};
