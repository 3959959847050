import styled from "styled-components";
import PhaseSixIcon from "../phaseSixIcon/PhaseSixIcon";

export const Container = styled.div<{
    selected: boolean;
    active: boolean;
    actionButtonsActivated: boolean;
    isSearchResult?: boolean;
}>`
    padding: ${(props) => props.theme.base.spacing};
    display: grid;
    grid-template-columns: ${(props) => !props.isSearchResult && "32px"} 1fr 1fr 0.5fr 215px;
    grid-gap: ${(props) => props.theme.base.spacingSmall};
    position: relative;
    color: ${(props) => (props.selected ? props.theme.colors.primary : props.theme.colors.text)};
    background-color: ${(props) => (props.actionButtonsActivated ? props.theme.colors.backgroundMain : "unset")};

    > * {
        display: flex;
        align-items: center;
        min-width: 0;
        max-width: 100%;
    }

    &:not(:last-of-type) {
        border-bottom: 1px solid ${(props) => props.theme.colors.backgroundMain};
    }

    > :not(.checkboxColumn, .actionAndDirectionColumn) {
        opacity: ${(props) => (props.active ? props.theme.base.inactiveOpacity : props.theme.base.disabledOpacity)};
    }

    .phase-directions {
        display: ${(props) => (props.actionButtonsActivated ? "none" : "flex")};
    }

    .cardActionButtons {
        display: ${(props) => (props.actionButtonsActivated ? "flex" : "none")};
    }

    &:hover {
        background-color: ${(props) => props.theme.colors.backgroundMain};

        .phase-directions {
            display: none;
        }

        .cardActionButtons {
            display: flex;
        }
    }

    @media screen and (max-width: 700px) {
        display: flex;
        flex-wrap: wrap;
        column-gap: 0;
        > div {
            flex-grow: 1;
        }

        > :not(.checkboxColumn) {
            padding: 0 ${(props) => `calc(${props.theme.base.spacingSmall} / 2)`};
        }

        .questionTextColumn,
        .answerTextColumn {
            flex-basis: calc((100% - 32px) / 2);
        }

        .unitLabelColumn {
            padding-left: ${(props) => props.theme.base.spacingSmall};
            padding-top: ${(props) => props.theme.base.spacingSmall};
            border-top: ${(props) => `1px solid ${props.theme.colors.text}30`};
            flex-basis: 50%;
        }

        .actionAndDirectionColumn {
            padding-left: 0;
            padding-top: ${(props) => props.theme.base.spacingSmall};
            border-top: ${(props) => `1px solid ${props.theme.colors.text}30`};
            flex-basis: 50%;
        }
    }
`;

export const ContainerLink = styled.a`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    cursor: pointer;
`;

export const ListCheckBoxWrapper = styled.div`
    justify-content: center;
    cursor: pointer;
    &:hover {
        .empty-checkbox {
            border-color: ${(props) => props.theme.colors.primary};
        }
    }
`;

export const WordWrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr auto auto;
    > * {
        min-width: 0;
    }
`;

export const Text = styled.div`
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
`;

export const IconContainer = styled.div`
    cursor: pointer;
    display: flex;
    &:hover .p6-icon-speaker {
        color: ${(props) => props.theme.colors.primary} !important;
    }
`;

export const LastColumn = styled.div`
    cursor: pointer;
    justify-content: flex-end;
    > * {
        display: flex;
        justify-content: flex-end;
        flex-wrap: wrap;
        align-items: center;
        gap: ${(props) => props.theme.base.spacingTiny};
    }

    @media screen and (max-width: 700px) {
        i {
            font-size: ${(props) => props.theme.base.cardPracticeTextSize} !important;
        }
    }
`;

export const PhaseDirection = styled.span<{ active: boolean }>`
    color: ${(props) => (!props.active ? props.theme.colors.text : "current")};
    opacity: ${(props) => (props.active ? 1 : props.theme.base.disabledOpacity)};
    display: flex;
    align-items: center;
`;

export const PhaseText = styled.span`
    width: 16px;
`;
export const ModalImageContainer = styled.div`
    width: 100%;
    padding: ${(props) => props.theme.base.spacing};
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
`;
export const ModalImage = styled.img`
    width: auto;
    height: auto;
    max-width: calc(50% - (2 * ${(props) => props.theme.base.spacing}));
`;
export const StyledPhaseSixIcon = styled(PhaseSixIcon)<{ active: boolean }>`
    opacity: ${(props) => (props.active ? 1 : props.theme.base.disabledOpacity)};
    z-index: 10;
`;
export const ArrowPhaseSixIcon = styled(PhaseSixIcon)`
    vertical-align: bottom;
    font-weight: bold;
`;
