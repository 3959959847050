import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../store/store";
import { ActivationType } from "p6m-activation";
import { StartParams } from "p6m-learning";

interface ActivationState {
    subjectId: string;
    type: ActivationType;
    userId?: string;
    groupId?: string;
    isSharedSubjectOfAdmin: boolean;
    step: number;
}

const initialState: ActivationState = {
    subjectId: "",
    type: "USER",
    userId: undefined,
    groupId: undefined,
    isSharedSubjectOfAdmin: false,
    step: 1,
};

export type ActivateCardsParams = StartParams & { subjectId: string; testId: string };

export const activationSlice = createSlice({
    name: "activation",
    initialState,
    reducers: {
        activateAndStart: (state, action: PayloadAction<ActivateCardsParams>) => {},
        setSubjectId: (state, action: PayloadAction<string>) => {
            state.subjectId = action.payload;
        },
        setType: (state, action: PayloadAction<ActivationType>) => {
            state.type = action.payload;
        },
        setUserId: (state, action: PayloadAction<string | undefined>) => {
            state.userId = action.payload;
        },
        setGroupId: (state, action: PayloadAction<string | undefined>) => {
            state.groupId = action.payload;
        },
        setSharedSubjectOfAdmin: (state, action: PayloadAction<boolean>) => {
            state.isSharedSubjectOfAdmin = action.payload;
        },
        setStep: (state, action: PayloadAction<number>) => {
            state.step = action.payload;
        },
    },
});

/* EXPORTS */
export const { actions, reducer } = activationSlice;

export const selectors = {
    subjectId: (state: RootState) => state.activation.subjectId,
    type: (state: RootState) => state.activation.type,
    userId: (state: RootState) => state.activation.userId,
    groupId: (state: RootState) => state.activation.groupId,
    isSharedSubjectOfAdmin: (state: RootState) => state.activation.isSharedSubjectOfAdmin,
    step: (state: RootState) => state.activation.step,
};
