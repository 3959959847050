// Deps
import React from "react";
import { useT } from "@transifex/react";

// Styles
import { Description as StyledDescription } from "../styles";

// Types
import { SubjectAssignmentModalType } from "p6m-subjects";

interface ConsumptionTextProps {
    modalType: SubjectAssignmentModalType;
}
interface DescriptionProps extends ConsumptionTextProps {
    totalCards: number;
}

const Description = ({ modalType, totalCards }: DescriptionProps) => {
    const t = useT();

    const userHasAccessRightsDescription_t = t(
        "You were invited to _ practice {cardsCount} words _ from the following book:",
        {
            _tags: "modal,description",
            cardsCount: totalCards,
        }
    );

    const trialPeriodExpired_t = t(
        "Trial period expired, in order to _ practice {cardsCount} words _ you need the following book:",
        {
            _tags: "modal,description",
            cardsCount: totalCards,
        }
    );

    let descriptionTextToSplit = "";
    switch (modalType) {
        case "NewSubject":
        case "AvailableSubject":
        case "DeletedActiveSubject":
        case "SelfCreatedContent":
        case "LoadedSelfCreatedContent": {
            descriptionTextToSplit = userHasAccessRightsDescription_t;
            break;
        }
        case "TrialExpired": {
            descriptionTextToSplit = trialPeriodExpired_t;
            break;
        }
        default:
    }

    const splitText = descriptionTextToSplit.split("_");
    const isThirdObsolete = splitText[2].length < 2;

    const formattedText = (
        <>
            {splitText[0]}
            <span>
                {splitText[1]}
                {isThirdObsolete && splitText[2]}
            </span>
            {!isThirdObsolete && splitText[2]}
        </>
    );

    return <StyledDescription>{formattedText}</StyledDescription>;
};

export default Description;
