import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import _ from "lodash";
import { RootState } from "../../store/store";
import {
    SubjectData,
    SubjectsByStatus,
    SubjectCounterType,
    SubjectUnitCard,
    SubjectUnitData,
    SubjectUnitCountResponseData,
    SubjectAssignmentModal,
} from "p6m-subjects";
import {
    getSubjectDataById,
    getFamilySubjectsWithUsernames,
    getCardCount,
    getCardCountForPracticeMoreToday,
    sortSubjectsByName,
} from "../../helpers/Subjects";
import { PostSubjectProps } from "../../networking/subjects";
import { PostUnitProps } from "../../networking/units";

export const SubjectCounterTypes: { [key: string]: SubjectCounterType } = {
    LIBRARY: "LIBRARY",
    LAST_PHASE: "LAST_PHASE",
    PRACTICE: "PRACTICE",
    LEARN_NEW: "LEARN_NEW",
    LEARNED_TODAY: "LEARNED_TODAY",
    SHOULD_PRACTICE_TODAY: "SHOULD_PRACTICE_TODAY",
    SHOULD_PRACTICE_TOMORROW: "SHOULD_PRACTICE_TOMORROW",
    ACTIVE_NOT_LEARNED: "ACTIVE_NOT_LEARNED",
    HOMEWORK_ACTIVATE_MIXED: "HOMEWORK_ACTIVATE_MIXED",
    HOMEWORK_ACTIVATE_MANUAL: "HOMEWORK_ACTIVATE_MANUAL",
    HOMEWORK_ACTIVATE_MATCH: "HOMEWORK_ACTIVATE_MATCH",
    HOMEWORK_LEARN: "HOMEWORK_LEARN",
    UNAPPROVED: "UNAPPROVED",
    TRIAL_TIME: "trialTime",
};

interface SubjectsState {
    subjects: Array<SubjectData>;
    units: { [subjectId: string]: any };
    cards: { [subjectId: string]: any };
    searchString: string;
    searchStringChanged: boolean;
    familySubjects: Array<SubjectData>;
    librarySubjects: Array<SubjectData>;
    activeSubjectId: string | null;
    areSubjectsLoaded: boolean;
    subjectAssignmentModal: SubjectAssignmentModal | null;
}

const initialState: SubjectsState = {
    subjects: [],
    units: {},
    cards: {},
    searchString: "",
    searchStringChanged: false,
    familySubjects: [],
    librarySubjects: [],
    activeSubjectId: null,
    areSubjectsLoaded: false,
    subjectAssignmentModal: null,
};

type TPostProps<T> = T extends PostUnitProps
    ? {
          deleted?: T[];
          created?: T[];
          updated?: T[];
      }
    : {
          deleted?: T[];
          created?: (T & { withDefaultUnit?: boolean })[];
          updated?: T[];
      };

export type TComplexSubjectsRestProps = TPostProps<PostSubjectProps>;

export type TComplexUnitsRestProps = TPostProps<PostUnitProps>;

export type SubjectTargetType = keyof Pick<SubjectsState, "subjects" | "librarySubjects" | "familySubjects">;

export const subjectsSlice = createSlice({
    name: "subjects",
    initialState,

    reducers: {
        setSubjectAssignmentModal: (state, action: PayloadAction<SubjectAssignmentModal | null>) => {
            state.subjectAssignmentModal = action.payload;
        },
        loadUserSubjects: (
            state,
            action: PayloadAction<
                undefined | { withUnits?: boolean; subjectIdForTestModalUpdate?: string; callback?: () => void }
            >
        ) => {},
        loadFamilySubjects: () => {},
        loadLibrarySubjects: () => {},
        setUserSubjects: (state, action: PayloadAction<Array<SubjectData>>) => {
            state.subjects = action.payload.sort(sortSubjectsByName);
            state.areSubjectsLoaded = true;
        },
        setFamilySubjects: (state, action: PayloadAction<Array<SubjectData>>) => {
            state.familySubjects = action.payload.sort(sortSubjectsByName);
        },

        setLibrarySubjects: (state, action: PayloadAction<Array<SubjectData>>) => {
            state.librarySubjects = action.payload.sort(sortSubjectsByName);
        },
        setActiveSubjectId: (state, action: PayloadAction<string | null>) => {
            state.activeSubjectId = action.payload;
        },
        loadSubjectUnits: (
            state,
            action: PayloadAction<string | { subjectId: string; target: SubjectTargetType }>
        ) => {},
        setLibrarySubjectCardCount: (
            state,
            action: PayloadAction<{
                subjectId: string;
                unitId: string;
                subjectCardCount: number;
                unitCardCount: number;
            }>
        ) => {
            const subjectToUpdate = state.librarySubjects.find(
                (subject) => subject.subjectMetadata.subjectIdToOwner.id === action.payload.subjectId
            );

            if (subjectToUpdate) {
                state.librarySubjects = [
                    ...state.librarySubjects.filter(
                        (s) =>
                            s.subjectMetadata.subjectIdToOwner.id !==
                            subjectToUpdate.subjectMetadata.subjectIdToOwner.id
                    ),
                    { ...subjectToUpdate, allCardsCount: action.payload.subjectCardCount },
                ].sort(sortSubjectsByName);
            }

            const unitToUpdate = subjectToUpdate?.units?.find((unit) => unit.unitId.id === action.payload.unitId);

            if (subjectToUpdate && unitToUpdate) {
                const updatedSubjects = [
                    ...state.librarySubjects.filter(
                        (subject) => subject.subjectMetadata.subjectIdToOwner.id !== action.payload.subjectId
                    ),
                    {
                        ...subjectToUpdate,
                        allCardsCount: action.payload.subjectCardCount,
                        units: [
                            ...subjectToUpdate.units!.filter((unit) => unit.unitId.id !== action.payload.unitId),
                            { ...unitToUpdate, cardCount: action.payload.unitCardCount },
                        ].sort(sortUnits),
                    },
                ].sort(sortSubjectsByName);

                state.librarySubjects = updatedSubjects;
            }
        },
        setSubjectUnits: (
            state,
            {
                payload: { units, subjectId, target = "subjects" },
            }: PayloadAction<{
                subjectId: string;
                units: SubjectData["units"];
                target?: SubjectTargetType;
            }>
        ) => {
            if (!units) return;
            const targetSubjects = state[target];
            const index: number = targetSubjects.findIndex((subject: SubjectData) => {
                const {
                    subjectMetadata: {
                        subjectIdToOwner: { id },
                    },
                } = subject;
                return id === subjectId;
            });
            if (index < 0) return;
            targetSubjects[index].units = units.sort(sortUnits);
        },
        setSubjectsUnits: (state, action: PayloadAction<{ [subjectId: string]: SubjectData["units"] }>) => {
            for (let subjectId in action.payload) {
                const index: number = state.subjects.findIndex((subject: SubjectData) => {
                    const {
                        subjectMetadata: {
                            subjectIdToOwner: { id },
                        },
                    } = subject;
                    return id === subjectId;
                });
                if (index < 0) return;
                state.subjects[index].units = action.payload[subjectId]?.sort(sortUnits);
            }
        },
        setSubjectCards: (
            state,
            action: PayloadAction<{
                subjectId: string;
                cards: SubjectUnitCard[];
            }>
        ) => {
            const index: number = state.subjects.findIndex((subject: SubjectData) => {
                const {
                    subjectMetadata: {
                        subjectIdToOwner: { id },
                    },
                } = subject;
                return id === action.payload.subjectId;
            });
            if (index < 0) return;
            if (state.searchStringChanged) {
                // replace previous cards
                state.subjects[index].cards = action.payload.cards;
            } else {
                // add cards
                const tmp = state.subjects[index].cards;
                let existingCards = tmp ? _.cloneDeep(tmp) : [];
                const cards = existingCards ? existingCards.concat(action.payload.cards) : action.payload.cards;
                state.subjects[index].cards = cards;
            }
            const chunkSize = process.env.REACT_APP_CARD_CHUNK_SIZE || 100;
            const cardsLoaded = action.payload.cards.length;
            // if no card or not the limit chunk size amount is returned we are finished
            state.subjects[index].allCardsLoaded = cardsLoaded === 0 || cardsLoaded % +chunkSize !== 0;
        },
        loadCardsFromSubjectByText: (state, action: PayloadAction<{ subject: SubjectData; searchText: string }>) => {
            const textHasChanged = action.payload.searchText !== state.searchString;
            state.searchStringChanged = textHasChanged;
            if (textHasChanged) {
                for (let i = 0; i < state.subjects.length; i++) {
                    state.subjects[i].allCardsLoaded = false;
                }
            }
            state.searchString = action.payload.searchText;
        },
        unsetAllCards: (state, action: PayloadAction) => {
            for (let i = 0; i < state.subjects.length; i++) {
                state.subjects[i].cards = [];
            }
        },
        // deleteSubjects: (state, action: PayloadAction<{subjects: SubjectData[]}>) => {},
        // postSubjects: (state, action: PayloadAction<{subjects: SubjectData[]}>) => {},
        complexSubjectsRest: (state, action: PayloadAction<TComplexSubjectsRestProps>) => {},
        complexUnitsRest: (state, action: PayloadAction<TComplexUnitsRestProps>) => {},
        clearSubjectSlice: (state) => {
            state.subjects = [];
            state.units = {};
            state.cards = {};
            state.searchString = "";
            state.searchStringChanged = false;
            state.familySubjects = [];
            state.librarySubjects = [];
            state.activeSubjectId = null;
            state.areSubjectsLoaded = false;
        },
        resetSubject: (state, action: PayloadAction<string>) => {},
    },
});

/* EXPORTS */
export const { actions, reducer } = subjectsSlice;

export const selectors = {
    subjects: (state: RootState) => state.subjects.subjects,
    // example:  const subject = useSelector(selectors.getSubjectById(subjectId));
    getSubjectById: (findId: string, target?: SubjectTargetType) => (state: RootState) => {
        const targetToSearch: SubjectTargetType[] = target
            ? [target]
            : ["subjects", "librarySubjects", "familySubjects"];
        const subjectsTarget = targetToSearch.reduce(function (result, currentTarget) {
            return result.concat(state.subjects[currentTarget]);
        }, [] as SubjectData[]);
        return subjectsTarget.find((subject) => {
            // "subject" without metadata should be a children with subjects
            if (subject.subjectMetadata === undefined) {
                const groupedSubjects = subject.subjects || [];
                return groupedSubjects.find((groupedSubject) => {
                    return groupedSubject[1].subjectMetadata.subjectIdToOwner.id === findId;
                });
            }
            const subjectId = subject.subjectMetadata.subjectIdToOwner.id;
            return findId === subjectId;
        });
    },
    // example:  const getSubjById = useSelector(selectors.getSubjectByIdAlt);
    // then anywhere: const subj = getSubjById(subjId);
    getSubjectByIdAlt: (state: RootState) => (findId: string, target?: SubjectTargetType) => {
        return selectors.getSubjectById(findId, target)(state);
    },
    getUnitsBySubjectId:
        (subjectId: string, target: SubjectTargetType = "subjects") =>
        (state: RootState) => {
            if (!subjectId) return [];
            const subjectsTarget = state.subjects[target];
            const subject = subjectsTarget.find((subject) => {
                const {
                    subjectMetadata: {
                        subjectIdToOwner: { id },
                    },
                } = subject;
                return subjectId === id;
            });
            if (!subject) return undefined;
            return subject.units;
        },
    getUnitsBySubjectIdAlt:
        (state: RootState) =>
        (subjectId: string, target: SubjectTargetType = "subjects") => {
            return selectors.getUnitsBySubjectId(subjectId, target)(state);
        },
    getUnitById:
        (unitId: string, subjectId: string, target?: SubjectTargetType) =>
        (state: RootState): (SubjectUnitData & SubjectUnitCountResponseData) | undefined => {
            if (!unitId || !subjectId) return undefined;
            const targets: SubjectTargetType[] = target ? [target] : ["librarySubjects", "subjects"]; // 'familySubjects'',

            for (let i = 0; i < targets.length; i++) {
                const currentTarget = targets[i];

                const units = selectors.getUnitsBySubjectId(subjectId, currentTarget)(state) || [];
                const searchedUnit = units.find((unit) => unit.unitId.id === unitId);
                if (searchedUnit) {
                    return searchedUnit;
                }
            }

            return undefined;
        },
    getUnitNameBySubjectIdAndUnitId: (subjectId: string, unitId: string) => (state: RootState) => {
        for (let i = 0; i < state.subjects.subjects.length; i++) {
            const subject = state.subjects.subjects[i];
            if (subject.subjectMetadata.subjectIdToOwner.id === subjectId) {
                if (subject.units) {
                    for (let k = 0; k < subject.units.length; k++) {
                        const unit = subject.units[k];
                        if (unit.unitId.id === unitId) {
                            return unit.unitContent.name;
                        }
                    }
                }
            }
        }
        return "";
    },
    familySubjects: (state: RootState) => state.subjects.familySubjects,
    familySubjectsDerived: (state: RootState) => {
        return getFamilySubjectsWithUsernames(state.subjects.familySubjects);
    },
    librarySubjects: (state: RootState) => state.subjects.librarySubjects,
    activeSubjectId: (state: RootState) => state.subjects.activeSubjectId,
    searchString: (state: RootState) => state.subjects.searchString,
    searchStringChanged: (state: RootState) => state.subjects.searchStringChanged,
    activeSubject: (state: RootState) => getSubjectDataById(state.subjects.subjects, state.subjects.activeSubjectId),
    getTrialTime: (findId: string) => (state: RootState) => {
        const subject = state.subjects.subjects.find((subject) => {
            const {
                subjectMetadata: {
                    subjectIdToOwner: { id },
                },
            } = subject;
            return findId === id;
        });

        return subject?.groupedCardCount?.trialTime || 0;
    },
    cardCountShouldPracticeToday: (state: RootState) => {
        const subject = selectors.activeSubject(state);
        if (!subject) return 0;
        return getCardCount(subject, SubjectCounterTypes.SHOULD_PRACTICE_TODAY);
    },
    hasActivatedCards: (state: RootState) => {
        const subject = selectors.activeSubject(state);
        if (!subject) return false;
        const cardCounts = subject.groupedCardCount?.cardCounts;
        if (cardCounts) {
            // if card was activated it's no longer in learn_new. library has all cards
            return cardCounts.LIBRARY.cardCount !== cardCounts.LEARN_NEW.cardCount;
        }
        return false;
    },
    additionalCardsCanBePracticedToday: (state: RootState) => {
        const subject = selectors.activeSubject(state);
        if (!subject) return 0;
        return getCardCountForPracticeMoreToday(subject);
    },
    cardCountShouldPracticeTomorrow: (state: RootState) => {
        const subject = selectors.activeSubject(state);
        if (!subject) return 0;
        return getCardCount(subject, SubjectCounterTypes.SHOULD_PRACTICE_TOMORROW);
    },
    cardCountLearnNew: (state: RootState) => {
        const subject = selectors.activeSubject(state);
        if (!subject) return 0;
        return getCardCount(subject, SubjectCounterTypes.LEARN_NEW);
    },
    subjectAssignmentModal: (state: RootState) => state.subjects.subjectAssignmentModal,
    getAreSubjectsLoaded: (state: RootState) => state.subjects.areSubjectsLoaded,
};

export const derived = {
    subjectsByStatus: createSelector(selectors.subjects, (subjects) => {
        const subjectsByStatus: SubjectsByStatus = {
            due: [],
            inactive: [],
            further: [],
            expired: [],
        };
        subjects.forEach((subject) => {
            if (subject.isExpired) subjectsByStatus.expired.push(subject);
            else if (getCardCount(subject, SubjectCounterTypes.PRACTICE) > 0) subjectsByStatus.due.push(subject);
            else if (getCardCount(subject, SubjectCounterTypes.LEARN_NEW) > 0) subjectsByStatus.inactive.push(subject);
            else subjectsByStatus.further.push(subject);
        });
        return subjectsByStatus;
    }),
    nonExpiredSubjects: createSelector(selectors.subjects, (subjects) => {
        return subjects.filter((subject) => !subject.isExpired);
    }),
    nonExpiredNotEmptySubjects: createSelector(selectors.subjects, (subjects) => {
        return subjects.filter((subject) => isNonExpiredNotEmptySubject(subject));
    }),
    dueSubjects: createSelector(selectors.subjects, (subjects) => {
        return subjects.filter(
            (subject) => !subject.isExpired && getCardCount(subject, SubjectCounterTypes.SHOULD_PRACTICE_TODAY) > 0
        );
    }),
    inactiveSubjects: createSelector(selectors.subjects, (subjects) => {
        return subjects.filter(
            (subject) => !subject.isExpired && getCardCount(subject, SubjectCounterTypes.SHOULD_PRACTICE_TODAY) > 0
        );
    }),
};

function isNonExpiredNotEmptySubject(subject: SubjectData) {
    return !subject.isExpired && subject.groupedCardCount && subject.groupedCardCount.cardCounts.LIBRARY.cardCount > 0;
}

function sortUnits(aUnit: Required<SubjectData>["units"][0], bUnit: Required<SubjectData>["units"][0]) {
    const {
        unitContent: { name: aName, order: aOrder },
    } = aUnit;
    const {
        unitContent: { name: bName, order: bOrder },
    } = bUnit;

    //@ts-ignore
    const result = parseInt(aOrder) - parseInt(bOrder);

    return result || (aName.toLowerCase() > bName.toLowerCase() ? 1 : -1);
}
