// REACT
import React, { FunctionComponent, memo } from "react";
import { T } from "@transifex/react";

// COMPONENTS
import Tooltip from "../../../basic/tooltip/Tooltip";
import PhaseSixIcon from "../../../basic/phaseSixIcon/PhaseSixIcon";

// STYLED COMPONENTS
import { IconWrapper, LanguageWrapper } from "./styles";

type Props = {
    language?: string;
    isSupported: boolean;
    disabled: boolean;
    text?: string;
    onClick: () => void;
};

const DictionaryButton: FunctionComponent<Props> = (props) => {
    const { language, isSupported, disabled, text, onClick } = props;

    return (
        <IconWrapper
            onClick={onClick}
            disabled={disabled}
        >
            <Tooltip
                content={
                    disabled ? (
                        isSupported ? (
                            <T
                                _str="You can only look up single meanings{newLine}in the PONS dictionary."
                                newLine={<br />}
                            />
                        ) : (
                            <T _str="The current language is not supported by the dictionary at the moment." />
                        )
                    ) : (
                        <T
                            _str='Look up the word "{text}" in the PONS dictionary.'
                            text={text}
                        />
                    )
                }
            >
                <PhaseSixIcon
                    name="dictionary"
                    size="medium"
                />
                {language ? <LanguageWrapper>{language}</LanguageWrapper> : null}
            </Tooltip>
        </IconWrapper>
    );
};

export default memo(DictionaryButton);
