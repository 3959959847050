// LIBRARIES
import { takeEvery, call, put } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";

// REDUX
import { actions } from "./notificationsSlice";

// NETWORKING
import { getNotifications, getRemindMe, changeRemindMe } from "../../networking/notifications";

// CONSTANTS
import { INotificationsEvents, TEvent } from "p6m-notifications";

const eventTypesToFilter: TEvent[] = ["PURCHASE", "PURCHASE_DEMO"];

export function* loadNotificationsSaga() {
    try {
        const {
            data: {
                replyContent: { events },
            },
        } = yield getNotifications();
        // remove any purchase and hidden events
        const items = events.filter(
            ({ status, eventType }: INotificationsEvents) =>
                !eventTypesToFilter.includes(eventType) && (!status || status !== "HIDDEN")
        );
        yield put(actions.setNotifications(items));
    } catch (e) {
        // fail silently
    }
}

export function* deleteNotificationSaga({ payload }: PayloadAction<INotificationsEvents[]>) {
    try {
        const {
            data: {
                replyContent: { events },
            },
        } = yield getNotifications();

        if (payload.length === 1) {
            const notificationToBeDeleted = payload[0];
            const remainingNotifications = events.filter((existingNotification: INotificationsEvents) => {
                // remove any purchase and hidden events
                if (
                    eventTypesToFilter.includes(existingNotification.eventType) ||
                    existingNotification.status === "HIDDEN"
                ) {
                    return false;
                }

                return !(notificationToBeDeleted.idToOwner.id === existingNotification.idToOwner.id);
            });
            yield put(actions.setNotifications(remainingNotifications));
        } else {
            // delete all notifications if it's not a single delete as we only have "delete single" and "clear all" buttons
            yield put(actions.setNotifications([]));
        }
    } catch (e) {
        // fail silently
    }
}

export function* getRemindMeSaga() {
    try {
        const {
            data: {
                replyContent: { remindMe },
            },
        } = yield getRemindMe();
        yield put(actions.changeRemindMe(remindMe));
    } catch (e) {
        console.log(e);
    }
}

export function* changeRemindMeSaga({ payload }: PayloadAction<boolean>) {
    try {
        const {
            data: { replyContent },
        } = yield call(changeRemindMe, payload);
        if (replyContent === "done") {
            yield put(actions.setRemindMe(payload));
        }
    } catch (e) {
        console.log(e);
    }
}

export function* notificationsSaga() {
    yield takeEvery(actions.loadNotifications.type, loadNotificationsSaga);
    yield takeEvery(actions.deleteNotification.type, deleteNotificationSaga);
    yield takeEvery(actions.getRemindMe.type, getRemindMeSaga);
    yield takeEvery(actions.changeRemindMe.type, changeRemindMeSaga);
}
