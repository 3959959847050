// LIBRARIES
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation, useRouteMatch } from "react-router-dom";

// REDUX
import { LoginDataType } from "../../redux/login/loginSaga";
import { actions, selectors } from "../../redux/login/loginSlice";
import { selectors as userSelectors } from "../../redux/user/userSlice";

// COMPONENTS
import Login from "./Login";

const LoginWrapper: React.FC = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();
    const match = useRouteMatch();
    const user = useSelector(userSelectors.user);
    const warning = useSelector(selectors.warning);
    const [checkedInitialRedirect, setCheckedInitialRedirect] = useState(false);


    useEffect(() => {
        if (!checkedInitialRedirect) {
            setCheckedInitialRedirect(true);
            if (user.jossoSessionId && user.jossoSessionId !== "") {
                // user is already logged in
                history.push("/home");
            }
        }
    }, [checkedInitialRedirect, user, history]);

    const onLogin = async (username: string, password: string, remember: boolean) => {
        const payload: LoginDataType = {
            username: username,
            password: password,
            remember: remember,
            history: history,
            location: location,
            match: match,
        };

        dispatch(actions.login(payload));
        dispatch(actions.unsetWarning());
    };

    return (
        <Login
            onLogin={onLogin}
            warning={warning}
        />
    );
};

export default LoginWrapper;
