import { SubjectUnitCard } from "p6m-subjects";
import { CardContent } from "p6m-cards";

export const CardDataTemplate: SubjectUnitCard = {
    normal: {
        active: false,
        wasActive: false,
        isDue: false,
        phase: 0,
        dueDate: "3021-01-01T00:00:00.000Z",
        modifiedTime: new Date().toISOString(),
        questionAnswerId: "",
        revisionNumber: 0,
    },
    opposite: {
        active: false,
        wasActive: false,
        isDue: false,
        phase: 0,
        dueDate: "3021-01-01T00:00:00.000Z",
        modifiedTime: new Date().toISOString(),
        questionAnswerId: "",
        revisionNumber: 0,
    },
    cardIdToOwner: { id: "", ownerId: "" },
    unitIdToOwner: { id: "", ownerId: "" },
    subjectIdToOwner: { id: "", ownerId: "" },
    cardContent: {
        question: "",
        answer: "",
        swappable: true,
        questionAnswerId: "",
        subjectIdToOwner: { id: "", ownerId: "" },
        unitIdToOwner: { id: "", ownerId: "" },
        subjectId: "",
        unitId: "",
        addSessionId: "",
        isAddedByUserId: true,
        modificationDate: new Date().toISOString(),
        changedByDirectly: "server_polly",
        changedByRequestEffect: "",
        revisionNumber: 0,
    } as SubjectUnitCard["cardContent"],
};

export const CardEditTemplate: CardContent = {
    addSessionId: "",
    addedByUserId: null,
    addedUserName: null,
    answer: "",
    answerExample: null,
    answerTranscription: null,
    modificationDate: null,
    order: null,
    ownerId: "",
    question: "",
    questionAnswerId: null,
    questionExample: null,
    questionTranscription: null,
    subjectIdToOwner: { id: "", ownerId: "" },
    swappable: true,
    unitIdToOwner: { id: "", ownerId: "" },
};
