import styled from "styled-components";
import { Col, Row } from "../../components/basic/grid";

export const ContentContainer = styled.div`
    padding: ${(props) => props.theme.base.spacingSmall} ${(props) => props.theme.base.spacing};
    background-color: ${(props) => props.theme.colors.backgroundContrast};
    min-height: 250px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch;
    border-radius: ${(props) => props.theme.base.borderRadius};
`;
export const DictionaryContainer = styled.div<{ $noMargins?: boolean }>`
    position: relative;
    z-index: 1;
    min-width: 36px;
    margin: ${({ $noMargins = false }) => ($noMargins ? "0" : "50px")} ${({ theme: { base } }) => base.spacing};
    font-size: ${({ theme: { base } }) => base.smallSize};
`;

export const FlagsCol = styled(Col)`
    display: flex;
    align-items: center;
`;

export const FlagsContainer = styled.div`
    display: flex;

    > * + * {
        margin-left: ${(props) => props.theme.base.spacingSmall};
    }
`;

export const SelectorsRow = styled(Row)`
    max-width: 800px;
`;
