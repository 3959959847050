// LIBRARIES
import React from "react";
import styled from "styled-components";
import { useT } from "@transifex/react";

// COMPONENTS
import PhaseSixIcon from "../../basic/phaseSixIcon/PhaseSixIcon";

// HELPERS
import { getCardCount, getShopUrl } from "../../../helpers/Subjects";

// TYPES
import { TIconNames } from "../../basic/phaseSixIcon/PhaseSixIcon";
import { PracticeType } from "p6m-practice";
import { SubjectActions, SubjectData } from "p6m-subjects";

export interface UserSubjectFurtherActionsProps {
    type: "due" | "inactive" | "further" | "expired";
    subject: SubjectData;
    subjectActions: SubjectActions;
    userId: string | undefined;
    isPremiumUser: boolean;
    firstPracticeFinished: boolean;
    allCardsLearned: boolean;
    allCardsInactive: boolean;
    isFamilySubject?: boolean;
    isClassroomSubject: boolean;
    hasNextPracticeDate: boolean;
    isLibraryAccessDisabled: boolean;
    totalCardCount: number;
    inactiveCardCount: number;
    deleteSubject: () => void;
    openResetSubjectModal: () => void;
    redirectToTestsAndExercises: () => void;
}

const List = styled.ul`
    list-style: none;
    padding: ${(props) => props.theme.base.spacingTiny};
    margin: 0;

    > :first-child {
        margin-top: 0;
    }

    > :last-child {
        margin-bottom: 0;
    }
`;
const StyledEntry = styled.li`
    margin: ${(props) => props.theme.base.spacing} 0;
`;
const EntryLink = styled.a<{ disabled?: boolean }>`
    display: flex;
    flex: 1;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
    cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
    color: ${(props) => (props.disabled ? props.theme.colors.textLight : props.theme.colors.text)};
    text-decoration: none;

    &:hover {
        color: ${(props) => (props.disabled ? props.theme.colors.textLight : props.theme.colors.primary)};
    }
`;
const EntryText = styled.span`
    position: relative;
    flex: 1;
    margin-left: ${(props) => props.theme.base.spacingSmall};
    white-space: nowrap;
`;

const EntryIcon = styled(PhaseSixIcon)`
    color: inherit;
`;

const Entry: React.FC<{
    key: string;
    iconName: TIconNames;
    onClick?: () => void;
    url?: string;
    disabled?: boolean;
}> = (props) => {
    // do not react on click of disabled
    if (props.disabled) {
        return (
            <StyledEntry>
                <EntryLink disabled={props.disabled}>
                    <EntryText>{props.children}</EntryText>
                    <EntryIcon name={props.iconName} />
                </EntryLink>
            </StyledEntry>
        );
    }
    if (props.url) {
        // external action => open in new tab
        return (
            <StyledEntry>
                <EntryLink
                    href={props.url}
                    target={"_blank"}
                >
                    <EntryText>{props.children}</EntryText>
                    <EntryIcon name={props.iconName} />
                </EntryLink>
            </StyledEntry>
        );
    }
    return (
        <StyledEntry onClick={props.onClick}>
            <EntryLink>
                <EntryText>{props.children}</EntryText>
                <EntryIcon name={props.iconName} />
            </EntryLink>
        </StyledEntry>
    );
};
const UserSubjectFurtherActions: React.FC<UserSubjectFurtherActionsProps> = (props) => {
    const { practice, homework, activate, activateFamily, activateGroup, add, manage, report, approve } =
        props.subjectActions;
    const t = useT();
    const subjectId = props.subject.subjectMetadata.subjectIdToOwner.id;
    const ownerId = props.subject.subjectMetadata.subjectIdToOwner.ownerId;

    const buy_t = t("Buy", { _tags: "list,home,subject,options,pop-up" });
    const delete_t = t("Delete", { _tags: "list,home,subject,options,pop-up" });
    const learnAgain_t = t("Learn again", {
        _tags: "list,home,subject,options,pop-up",
    });
    const practiceMore_t = t("Practice more", {
        _tags: "list,home,subject,options,pop-up",
    });
    const homework_t = t("Homework", {
        _tags: "list,home,subject,options,pop-up",
    });
    const newWords_t = t("New words", {
        _tags: "list,home,subject,options,pop-up",
    });
    const letsGo_t = t("Let's go", {
        _tags: "list,home,subject,options,pop-up",
    });
    const addCard_t = t("Add card", {
        _tags: "list,home,subject,options,pop-up",
    });
    const manageSubject_t = t("Manage subject", {
        _tags: "list,home,subject,options,pop-up",
    });
    const testsAndExercises_t = t("Tests & Exercises", {
        _tags: "list,home,subject,options,pop-up",
    });
    const reports_t = t("Reports", {
        _tags: "list,home,subject,options,pop-up",
    });
    const resetSubject_t = t("Reset subject", {
        _tags: "list,home,subject,options,pop-up",
    });
    const approveWords_t = t("Approve words", {
        _tags: "list,home,subject,options,pop-up",
    });
    const activate_t = t("Activate", {
        _tags: "list,home,subject,options,pop-up",
    });

    const deleteSubject = () => {
        props.deleteSubject();
    };

    const openResetSubjectModal = () => {
        props.openResetSubjectModal();
    };

    const totalCardCount = props.subject?.groupedCardCount?.cardCounts["LIBRARY"].cardCount || 0;

    let subjectsActionsList: Array<JSX.Element> = [];
    let createSubjectActions = () => {
        let actionsList = [];
        if (props.subject.isExpired) {
            // if type is "expired" => "buy" is primary action, so don't show here
            if (props.type !== "expired") {
                actionsList.push(
                    <Entry
                        key={"buy_action"}
                        iconName={"cart"}
                        url={getShopUrl(subjectId)}
                    >
                        {buy_t}
                    </Entry>
                );
            }
            actionsList.push(
                <Entry
                    key={"delete_action"}
                    iconName={"trash"}
                    onClick={deleteSubject}
                    disabled={props.isClassroomSubject && ownerId !== props.userId} // only delete own content
                >
                    {delete_t}
                </Entry>
            );
        } else {
            // if type is "due" => "learn" is primary action, so don't show here
            if (!props.subject.subjectMetadata.isSharedSubjectOfAdmin && props.type !== "due") {
                // Id of subjects with sequence learning starts with "gapSubject"
                let sequencedPractice = subjectId.indexOf("gapSubject") === 0;
                // no sequenced training when cards are pending
                sequencedPractice = sequencedPractice && getCardCount(props.subject, "SHOULD_PRACTICE_TODAY") < 1;
                actionsList.push(
                    <Entry
                        key={"learnAgain_action"}
                        iconName={"learn"}
                        disabled={props.allCardsLearned || props.allCardsInactive} // only learn again if NOT all cards finished (all cards in last phase) OR all cards inactive
                        onClick={() => practice(ownerId, subjectId, sequencedPractice)}
                    >
                        {props.allCardsLearned ? learnAgain_t : practiceMore_t}
                    </Entry>
                );
            }
            const homeworkCards =
                getCardCount(props.subject, "HOMEWORK_ACTIVATE_MANUAL") +
                getCardCount(props.subject, "HOMEWORK_ACTIVATE_MIXED") +
                getCardCount(props.subject, "HOMEWORK_ACTIVATE_MATCH") +
                getCardCount(props.subject, "HOMEWORK_LEARN");
            if (homeworkCards > 0) {
                // hasHomework
                actionsList.push(
                    <Entry
                        key={"homework_action"}
                        iconName={"learn"}
                        onClick={() => {
                            let practiceType: PracticeType = null;
                            if (getCardCount(props.subject, "HOMEWORK_ACTIVATE_MANUAL") > 0) {
                                practiceType = "MANUAL_HOMEWORK";
                            } else if (getCardCount(props.subject, "HOMEWORK_ACTIVATE_MIXED") > 0) {
                                practiceType = "MIXED";
                            } else if (getCardCount(props.subject, "HOMEWORK_ACTIVATE_MATCH") > 0) {
                                practiceType = "MATCH";
                            } else if (getCardCount(props.subject, "HOMEWORK_LEARN") > 0) {
                                practiceType = "HOMEWORK_PRACTICE";
                            }
                            homework(ownerId, subjectId, practiceType);
                        }}
                    >
                        {homework_t}
                    </Entry>
                );
            }
            // subjects with nextPracticeDate have "new words" action (instead of "let's go"-action); disabled if no cards (left) for activation
            if (props.hasNextPracticeDate) {
                actionsList.push(
                    <Entry
                        key={"new_words_action"}
                        iconName={"edit"}
                        onClick={() => activate(subjectId)}
                        disabled={props.inactiveCardCount === 0}
                    >
                        {newWords_t}
                    </Entry>
                );
            } else {
                // inactive subjects already have "let's go" as main action; otherwise: "let's go" action disabled if no cards (left) for activation
                if (props.type !== "inactive") {
                    actionsList.push(
                        <Entry
                            key={"lets_go_action"}
                            iconName={"edit"}
                            onClick={() => activate(subjectId)}
                            disabled={props.inactiveCardCount === 0}
                        >
                            {letsGo_t}
                        </Entry>
                    );
                }
            }

            actionsList.push(
                <Entry
                    key={"add_action"}
                    iconName={"add"}
                    onClick={() => add(subjectId)}
                >
                    {addCard_t}
                </Entry>
            );

            actionsList.push(
                <Entry
                    key={"manage_action"}
                    iconName={"library"}
                    disabled={!props.firstPracticeFinished || props.isLibraryAccessDisabled}
                    onClick={() => manage(subjectId)}
                >
                    {manageSubject_t}
                </Entry>
            );

            actionsList.push(
                <Entry
                    key={"tests_action"}
                    iconName={"clipboard"}
                    disabled={totalCardCount === 0}
                    onClick={props.redirectToTestsAndExercises}
                >
                    {testsAndExercises_t}
                </Entry>
            );
            actionsList.push(
                <Entry
                    key={"reports_action"}
                    iconName={"reports"}
                    onClick={() => report(subjectId)}
                >
                    {reports_t}
                </Entry>
            );

            // can only reset if any cards have been activated
            actionsList.push(
                <Entry
                    key={"reset_action"}
                    iconName={"reset"}
                    onClick={openResetSubjectModal}
                    disabled={props.allCardsInactive}
                >
                    {resetSubject_t}
                </Entry>
            );
            if (props.subject.subjectMetadata.expirationDate) {
                // isTrial
                actionsList.push(
                    <Entry
                        key={"buy_action_2"}
                        iconName={"cart"}
                        url={getShopUrl(subjectId)}
                    >
                        {buy_t}
                    </Entry>
                );
            }
            if (getCardCount(props.subject, "UNAPPROVED") > 0) {
                // hasUnapprovedCards
                actionsList.push(
                    <Entry
                        key={"approve_action"}
                        iconName={"school"}
                        onClick={() => approve(subjectId)}
                    >
                        {approveWords_t}
                    </Entry>
                );
            }
            if (ownerId !== props.userId && isNaN(+ownerId)) {
                // !isOwned
                actionsList.push(
                    <Entry
                        key={"delete_action_2"}
                        iconName={"trash"}
                        onClick={deleteSubject}
                        disabled={props.isClassroomSubject && ownerId !== props.userId} // only delete own content
                    >
                        {delete_t}
                    </Entry>
                );
            }
        }
        return actionsList;
    };

    if (props.isFamilySubject) {
        if (props.subject.groupData && props.subject.groupData.isAdmin) {
            //subject.familyOwner.userId === User.userId) {
            subjectsActionsList = createSubjectActions();
        } else {
            // is not family subject admin
            if (!props.subject.isExpired) {
                subjectsActionsList.push(
                    <Entry
                        key={"activate_action"}
                        iconName={"edit"}
                        onClick={() => activateFamily(subjectId, props.userId)}
                    >
                        {activate_t}
                    </Entry>
                );
                subjectsActionsList.push(
                    <Entry
                        key={"reports_action_2"}
                        iconName={"reports"}
                        onClick={() => report(subjectId)}
                    >
                        {reports_t}
                    </Entry>
                );
            }
        }
    } else {
        // is user subject
        subjectsActionsList = createSubjectActions();
    }
    return <List>{subjectsActionsList}</List>;
};

UserSubjectFurtherActions.defaultProps = {
    isFamilySubject: false,
};

export default UserSubjectFurtherActions;
