//NETWORK
import { postUnit } from "../networking/units";

//UTILS
import { generateUuid } from "./Id";
import { defaultUnitIdPrefix } from "../constants/ContentConstants";

//TYPES
import { SubjectUnitData, UnitsList } from "p6m-subjects";
import {
    DROPDOWN_CONTENT_TYPES,
    DROPDOWN_EDIT_TYPES,
    EDIT_RESPONSE_TYPES,
} from "../hooks/dropdown/useDropdownEditResponsePopup";

export function getDefaultUnitId(subjectId: string): string {
    return defaultUnitIdPrefix + subjectId;
}

export function isDefaultUnitId(id: string): boolean {
    return id.startsWith(defaultUnitIdPrefix);
}

export function isDefaultUnit(unit: SubjectUnitData): boolean {
    const {
        unitId: { id },
    } = unit;
    return isDefaultUnitId(id);
}

export function sortUnitsCallback(firstUnit: SubjectUnitData, secondUnit: SubjectUnitData): number {
    if (isDefaultUnit(firstUnit)) return 1;
    if (isDefaultUnit(secondUnit)) return -1;
    const {
        unitContent: { modificationDate },
    } = firstUnit;
    const {
        unitContent: { modificationDate: secondModifDate },
    } = secondUnit;
    const date = new Date(modificationDate).getTime();
    const secondDate = new Date(secondModifDate).getTime();

    return date - secondDate;
}

// Finding the max order in the units that are not general units, then using next number.
export const getNextUnitOrder = (units?: UnitsList, index: number = 0): number => {
    let nextUnitOrderValue = 1;
    if (units && units.length) {
        const unitOrders = units.map((u) => (isDefaultUnit(u) ? 0 : u.unitContent.order || 0));
        const maxOrder = Math.max(...unitOrders);

        if (!isNaN(maxOrder)) {
            nextUnitOrderValue = maxOrder + index + 1;
        }
    }

    return nextUnitOrderValue;
};

export const createSingleUnit = async ({
    userId,
    subjectId,
    unitId,
    unitOrder,
    unitName,
    onError,
}: {
    userId?: string;
    subjectId: string;
    unitId?: string;
    unitOrder?: number;
    unitName?: string;
    onError?: (
        contentType: DROPDOWN_CONTENT_TYPES,
        editType: DROPDOWN_EDIT_TYPES,
        responseType: EDIT_RESPONSE_TYPES,
        name: string
    ) => void;
}) => {
    try {
        if (userId && subjectId !== "default" && unitName) {
            const unitIdToUse = unitId || generateUuid();
            const unitCreationRes = await postUnit({
                ownerId: userId,
                unitId: unitIdToUse,
                subjectId,
                name: unitName,
                order: unitOrder,
            });
            return { ...unitCreationRes.data, name: unitName };
        } else {
            throw new Error(`Could not create unit. userId: ${userId}; subjectId: ${subjectId}; unitName: ${unitName}`);
        }
    } catch (e) {
        onError?.(DROPDOWN_CONTENT_TYPES.UNIT, DROPDOWN_EDIT_TYPES.CREATION, EDIT_RESPONSE_TYPES.ERROR, unitName || "");
    }
};
