//DEPENDENCIES
import React, { FunctionComponent, useEffect, useMemo, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

//REDUX
import { selectors, actions } from "../../../../redux/games/gamesSlice";
import { actions as modalActions } from "../../../../redux/modal/modalSlice";
import { actions as goalsActions, selectors as goalsSelectors } from "../../../../redux/goals/goalsSlice";

//UTILS
import { getSubjectCover } from "../../../../helpers/Subjects";

//COMPONENTS
import Component from "./WordMatchList";

//TYPES
import { GameSubject } from "p6m-games";
import { CardCounts, GroupedCardCount } from "p6m-subjects";
import { GlobalModalView } from "../../../../helpers/Modal";
import { WordMatchGameType } from "../WordMatchGame";

export const WordMatchList: FunctionComponent = () => {
    const matchGameSubjects: GameSubject[] | undefined = useSelector(selectors.matchGameSubjects);
    const userGoalsData = useSelector(goalsSelectors.goals);
    const practiced50Cards = userGoalsData?.goals.practiced50Cards;

    const history = useHistory();
    const dispatch = useDispatch();

    useEffect(() => {
        if (practiced50Cards) return;
        if (!userGoalsData) {
            dispatch(goalsActions.fetchGoals());
            return;
        }
        history.push("/home");
    }, [practiced50Cards, userGoalsData, dispatch, history]);

    const gameList = useMemo(() => {
        if (!matchGameSubjects || !matchGameSubjects.length) return [];
        return matchGameSubjects.map((gameSubject: GameSubject) => {
            const {
                score: { match: time = 0 },
                subjectContent: { name },
                subjectMetadata: {
                    subjectIdToOwner: { id },
                },
                groupedCardCount: { cardCounts = {} as CardCounts } = {} as GroupedCardCount,
            } = gameSubject;

            const image = getSubjectCover(gameSubject);

            // \js\games\services\matchGameSrv.js line: 73
            const hasActiveCards: boolean = (() => {
                type CountsType = Record<Extract<keyof CardCounts, "LIBRARY" | "LEARN_NEW">, number>;
                const counts: CountsType = {
                    LIBRARY: 0,
                    LEARN_NEW: 0,
                };
                (Object.keys(counts) as (keyof CountsType)[]).forEach((key) => {
                    const count: number = cardCounts[key]?.cardCount || 0;
                    counts[key] = count;
                });
                return counts.LIBRARY - counts.LEARN_NEW >= 24;
            })();

            return {
                id,
                image,
                name,
                time,
                hasActiveCards,
            };
        });
    }, [matchGameSubjects]);

    useEffect(() => {
        if (matchGameSubjects) return;
        dispatch(actions.fetchMatchGame());
    }, [matchGameSubjects, dispatch]);

    const fetchCards = useCallback(
        (id: string, type: WordMatchGameType) => {
            dispatch(actions.fetchMatchGameCards({ id, type }));
        },
        [dispatch]
    );
    const showModal = () => {
        dispatch(modalActions.setModalView(GlobalModalView.AddSubject));
    };

    return (
        <Component
            subjects={gameList}
            beforeStartGame={fetchCards}
            showModal={showModal}
        />
    );
};
