import { BrowserConfig, EnrichmentPlugin, Event, PluginType } from "@amplitude/analytics-types";

export class AddAppVersionPlugin implements EnrichmentPlugin {
    name = "add-app-version";
    type = PluginType.ENRICHMENT as const;
    appVersion = process.env.REACT_APP_VERSION;

    /**
     * setup() is called on plugin installation
     * example: client.add(new AddEventIdPlugin());
     */
    async setup(config: BrowserConfig): Promise<undefined> {
        return;
    }

    /**
     * execute() is called on each event instrumented
     * example: client.track('New Event');
     */
    async execute(event: Event): Promise<Event> {
        event.app_version = this.appVersion;
        return event;
    }
}
