import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PracticeType, Item, Slice, ResultActions } from "p6m-practice";
import { SubjectData, SubjectUnitCard } from "p6m-subjects";
import { RootState } from "../../store/store";
import { generateUuid } from "../../helpers/Id";

interface PracticeState {
    ownerId: string;
    subjectId: string;
    sequencedPractice: boolean;
    practiceType: PracticeType;
    startPractice?: {
        subjectId: string;
        unit?: SubjectData["units"];
        cards: SubjectUnitCard[];
        type: number;
        direction: number;
        session?: string;
    };
    practiceSettings?: {
        typing?: boolean;
        accelerate?: boolean;
        playAudio?: boolean;
        enforce?: 0 | 1 | 2;
    };
}

const initialState: PracticeState = {
    ownerId: "",
    subjectId: "",
    sequencedPractice: false,
    practiceType: null,
};

export type CompareProps = {
    card: SubjectUnitCard;
    result: string;
    correctAnswerText: string;
    direction: "NORMAL" | "OPPOSITE";
};

export const practiceSlice = createSlice({
    name: "practice",
    initialState,
    reducers: {
        setInitialData: (state, action: PayloadAction<{ ownerId: string; subjectId: string }>) => {
            state.ownerId = action.payload.ownerId;
            state.subjectId = action.payload.subjectId;
        },
        setSequencedPractice: (state, action: PayloadAction<boolean>) => {
            state.sequencedPractice = action.payload;
        },
        setPracticeType: (state, action: PayloadAction<PracticeType>) => {
            state.practiceType = action.payload;
        },
        sendAnswer: (
            state,
            action: PayloadAction<{
                slice: Slice;
                item: Item;
                card: SubjectUnitCard;
                actionType: keyof ResultActions;
                value?: string;
                callback: (result: boolean, newData?: SubjectUnitCard) => void;
            }>
        ) => {},
        startPractice: (state, action: PayloadAction<PracticeState["startPractice"]>) => {
            if (!action.payload) return;
            state.startPractice = {
                ...action.payload,
                session: generateUuid(),
            };
        },
        startPracticeWithDueCards: (state, action: PayloadAction<{ subjectId: string }>) => {},
        startPracticeWithAdditionalCards: (state, action: PayloadAction<{ subjectId: string }>) => {},
        activateAllCards: (
            state,
            action: PayloadAction<{
                cardsId: string[];
                subjectId: string;
                direction: "NORMAL" | "OPPOSITE";
            }>
        ) => {},
        stopPractice: (state) => {
            state.startPractice = undefined;
        },
        compareAnswer: (state, action: PayloadAction<CompareProps>) => {},
        updateCard: (state, action: PayloadAction<SubjectUnitCard>) => {
            const {
                payload,
                payload: {
                    cardIdToOwner: { id },
                },
            } = action;
            if (!state.startPractice) return;
            const {
                startPractice: { cards = [] },
            } = state;
            const cardIndex = cards.findIndex((card) => {
                const {
                    cardIdToOwner: { id: findId },
                } = card;
                return id === findId;
            });

            if (cardIndex < 0) return;
            state.startPractice.cards[cardIndex] = payload;
        },
        setSettings: (state, action: PayloadAction<Required<PracticeState>["practiceSettings"]>) => {
            const { payload } = action;
            const { practiceSettings } = state;
            state.practiceSettings = {
                ...practiceSettings,
                ...payload,
            };
        },
    },
});

/* EXPORTS */
export const { actions, reducer } = practiceSlice;

export const selectors = {
    ownerId: (state: RootState) => state.practice.ownerId,
    subjectId: (state: RootState) => state.practice.subjectId,
    practiceData: (state: RootState) => state.practice.startPractice,
    getPracticeSessionId: (state: RootState) => state.practice.startPractice?.session || "",
    getSettings: (state: RootState) => {
        const {
            inputEnabledForPracticeWeb = true,
            acceleratePractice = true,
            audioPlaybackSetting = true,
            retypeCorrectAnswerWeb = false,
            enforceCorrectAnswerWeb = false,
        } = state.user.userPreferences;

        const enforce = +retypeCorrectAnswerWeb + +enforceCorrectAnswerWeb;

        return {
            typing: inputEnabledForPracticeWeb,
            accelerate: acceleratePractice,
            playAudio: audioPlaybackSetting,
            enforce,
            ...state.practice.practiceSettings,
        };
    },
};
