// REDUX
import React, { useState } from "react";
import { useT } from "@transifex/react";

// STYLED COMPONENTS
import styled from "styled-components";

// COMPONENTS
import SettingsToggleInput, { ToggleTypes } from "../../basic/settingsToggleInput/SettingsToggleInput";
import SettingsItemWrapper from "../settingsItemWrapper/SettingsItemWrapper";

// TYPES
import { UserPreferences } from "p6m-user";

export interface SettingsLanguageProps {
    preferences: UserPreferences;
    changeLanguageAction: (newLanguage: string) => any;
    currentLanguage: string;
}

const MainWrapper = styled.div`
    margin-top: ${(props) => props.theme.base.spacing};
`;

const SettingsLanguage: React.FC<SettingsLanguageProps> = (props) => {
    const t = useT();

    const t_language = t("language_selector_label", { _tags: "languageSettings" });
    const t_languageGerman = t("German", { _tags: "languageSettings" });
    const t_languageEnglish = t("English", { _tags: "languageSettings" });
    // todo: uncomment once we have enough translations for those languages
    // const t_languageSpanish = t("Spanish", { _tags: "languageSettings" });
    // const t_languagePolish = t("Polish", { _tags: "languageSettings" });
    const t_languageInfo = t("language_selector_information", { _tags: "languageSettings" });

    const [selectedLanguage, setSelectedLanguage] = useState(props.currentLanguage);

    const handleLanguageChangedAction = (lang: string) => {
        setSelectedLanguage(lang);
        props.changeLanguageAction(lang);
    };

    return (
        <MainWrapper>
            <SettingsItemWrapper
                title={t_language}
                description={t_languageInfo}
            >
                <SettingsToggleInput
                    labelText={t_languageGerman}
                    checkedValue={selectedLanguage === "de"}
                    toggleType={ToggleTypes.CheckIcon}
                    onValueChanged={(checked) => {
                        if (!!checked) handleLanguageChangedAction("de");
                    }}
                />
                <SettingsToggleInput
                    labelText={t_languageEnglish}
                    checkedValue={selectedLanguage === "en_GB"}
                    toggleType={ToggleTypes.CheckIcon}
                    onValueChanged={(checked) => {
                        if (!!checked) handleLanguageChangedAction("en_GB");
                    }}
                />
                {/* todo: uncomment once we have enough translations for those languages */}
                {/*<SettingsToggleInput*/}
                {/*    labelText={t_languageSpanish}*/}
                {/*    checkedValue={selectedLanguage === "es"}*/}
                {/*    toggleType={ToggleTypes.CheckIcon}*/}
                {/*    onValueChanged={(checked) => {*/}
                {/*        if (!!checked) handleLanguageChangedAction("es");*/}
                {/*    }}*/}
                {/*/>*/}
                {/*<SettingsToggleInput*/}
                {/*    labelText={t_languagePolish}*/}
                {/*    checkedValue={selectedLanguage === "pl"}*/}
                {/*    toggleType={ToggleTypes.CheckIcon}*/}
                {/*    onValueChanged={(checked) => {*/}
                {/*        if (!!checked) handleLanguageChangedAction("pl");*/}
                {/*    }}*/}
                {/*/>*/}
            </SettingsItemWrapper>
        </MainWrapper>
    );
};

export default SettingsLanguage;
