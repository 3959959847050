import styled from "styled-components";

export const Container = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    overflow-y: scroll;
`;
export const Header = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: ${(props) => props.theme.base.spacing};
    border-bottom: 1px solid ${(props) => props.theme.colors.textLight};
`;
export const QuestionHeader = styled.span`
    flex: 2;
    font-weight: bold;
    color: ${(props) => props.theme.colors.text};
`;
export const AnswerHeader = styled.span`
    flex: 2;
    font-weight: bold;
    color: ${(props) => props.theme.colors.text};
`;
export const CountHeader = styled.span`
    flex: 1;
    font-weight: bold;
    color: ${(props) => props.theme.colors.text};
`;
export const Entry = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
    border-bottom: 1px solid ${(props) => props.theme.colors.textLight};
`;
export const Statistics = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: ${(props) => props.theme.base.spacing};
`;
export const QuestionText = styled.div`
    flex: 2;
    color: ${(props) => props.theme.colors.text};
`;
export const AnswerText = styled.div`
    flex: 2;
    color: ${(props) => props.theme.colors.text};
`;
export const CountText = styled.span`
    flex: 1;
    color: ${(props) => props.theme.colors.text};
`;
