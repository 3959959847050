import { useEffect, useState } from "react";

export const useAppVersionCheck = () => {
    const [isVersionDifferent, setIsVersionDifferent] = useState<boolean>(false);

    useEffect(() => {
        if (process.env.NODE_ENV !== "production") {
            return;
        }

        const compareVersion = async () => {
            const cachedVersion = process.env.REACT_APP_VERSION;
            const res = await fetch(getVersionFileUrl());
            const data = JSON.parse(await res.text());
            const serverVersion = data.versionNumber;

            setIsVersionDifferent(cachedVersion !== serverVersion);
        };

        void compareVersion();
    }, [setIsVersionDifferent]);

    useEffect(() => {
        if (isVersionDifferent) {
            window.location.reload();
        }
    }, [isVersionDifferent]);
};

function getVersionFileUrl() {
    const hasPathPrefix = window.location.pathname.startsWith("/v2");
    let basePath = window.location.origin;

    if (hasPathPrefix) {
        basePath += "/v2";
    }

    return basePath + "/static/ui-version.json?revision=" + Date.now();
}
