import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { actions as goalsActions, selectors as goalsSelectors } from "../redux/goals/goalsSlice";
import { selectors as userSelectors } from "../redux/user/userSlice";

export const useFirstPracticeResult = () => {
    const dispatch = useDispatch();
    const goals = useSelector(goalsSelectors.goals);
    const metaData = useSelector(userSelectors.userMetadata);

    useEffect(() => {
        if (!goals) {
            dispatch(goalsActions.fetchGoals());
        }
    }, [goals]);

    return metaData.hasFirstPractice || !!goals?.goals.firstPracticeFinished;
};
