// REACT
import React from "react";

// STYLED COMPONENTS
import { CheckboxWrapper, SelectableSquareDiv, StyledPhaseSixIcon } from "./styles";
export interface CheckboxInputProps {
    checked: boolean;
    contrastColors?: boolean;
    onChange?: (checked: boolean) => any;
    stopPropagation?: boolean;
    dataTestId?: string;
}

const ListCheckbox: React.FC<CheckboxInputProps> = (props) => {
    const onClick = (e: React.MouseEvent<HTMLDivElement>) => {
        if (props.stopPropagation) {
            e.stopPropagation();
        }
        if (props.onChange) {
            // return changed value
            return props.onChange(!props.checked);
        }
    };

    return (
        <CheckboxWrapper
            checked={props.checked}
            data-testid={props.dataTestId}
            onClick={onClick}
        >
            {props.checked ? (
                <StyledPhaseSixIcon
                    name="exercise-ok"
                    contrastColors={!!props.contrastColors}
                />
            ) : (
                <SelectableSquareDiv
                    className="empty-checkbox"
                    contrastColors={!!props.contrastColors}
                />
            )}
        </CheckboxWrapper>
    );
};

export default ListCheckbox;
