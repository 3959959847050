// LIBRARIES
import React, { useCallback, useEffect, createRef, useState } from "react";
import { ampli } from "../../../../ampli";
import { UserPreferences } from "p6m-user";
import { T, useT } from "@transifex/react";
import { useMediaQuery } from "react-responsive";

// TYPES
import { SubjectData, UnitPhasesStatistic } from "p6m-subjects";

// COMPONENTS
import PhaseSixIcon from "../../../../components/basic/phaseSixIcon/PhaseSixIcon";

// NETWORKING
import { getReportPhasesData } from "../../../../networking/reports";

// HELPERS
import { isLongTermMemoryPhase } from "../../../../helpers/isLongTermMemoryPhase";

// STYLES
import {
    Container,
    InformationBox,
    InfoImage,
    InfoTextContainer,
    InfoText,
    InfoSubtext,
    InfoLinkContainer,
    InfoLink,
    InfoLinkText,
    InfoLinkTextSmall,
    Phases,
    Phase,
    Number,
    FilledArea,
    BarArea,
    PhaseName,
    NonFilledArea,
} from "./styles";
import { InfoImageContainer } from "../styles";

export interface ReportsPhasesProps {
    ownerId: string | undefined;
    ownerName: string | undefined;
    userPreferences: UserPreferences;
    selectedSubject: SubjectData;
}

const ReportPhases: React.FC<ReportsPhasesProps> = (props) => {
    const t = useT();
    const [selectedPhase, setSelectedPhase] = useState<number | null>(null);
    const [data, setData] = useState<UnitPhasesStatistic | null>(null);

    const lowerBorderQuery = "(max-width: " + process.env.REACT_APP_LOWER_GRID_STEP_BORDER + "px)";
    let isMobileView: Boolean = useMediaQuery({
        query: lowerBorderQuery,
    });

    const loadData = useCallback(async () => {
        const selectedSubjectIdToOwner = props.selectedSubject.subjectMetadata.subjectIdToOwner;
        const {
            data: { replyContent: responseData },
        } = await getReportPhasesData(props.ownerId ?? "", selectedSubjectIdToOwner);
        const subjectSummaryData = responseData.phasesStatistic.find(
            (entry) => entry["0"].id === selectedSubjectIdToOwner.id
        );
        if (subjectSummaryData) {
            setData(subjectSummaryData);
        }
    }, [props.ownerId, props.selectedSubject.subjectMetadata.subjectIdToOwner]);

    useEffect(() => {
        loadData();
    }, [loadData]);

    useEffect(() => {
        ampli.openReportsTab({
            reports_tab: "phases",
        });
    }, []);

    let phaseCount = 8; // includes stock, phase 1-6 and finished
    let phaseTrainingFrequency = [0, 0, 1, 2, 3, 5, 8, 13];
    if (props.userPreferences.phases) {
        phaseCount = props.userPreferences.phases.phaseList.length;
        phaseTrainingFrequency = props.userPreferences.phases.phaseList.map((e) => e.durationDays);
    }

    const cardCounts =
        data && data["1"].phasesInPercents.length > 0 ? data["1"].phasesInPercents : [0, 0, 0, 0, 0, 0, 0, 0, 0];
    const phaseRefs = cardCounts.map(() => createRef<HTMLDivElement>());

    const total = cardCounts.reduce((acc, entry) => {
        if (isNaN(entry)) return acc;
        return acc + entry;
    }, 0);

    const onPhaseClick = (index: number) => {
        setSelectedPhase(index);
    };

    // unselect phases
    const handleClick = useCallback(
        (event: MouseEvent) => {
            const target = event.target;
            const phaseClicked = phaseRefs.some(
                (ref) => ref && target instanceof Node && ref.current?.contains(target)
            );
            if (!phaseClicked) {
                setTimeout(() => {
                    setSelectedPhase(null);
                }, 100);
            }
        },
        [phaseRefs, setSelectedPhase]
    );

    useEffect(() => {
        if (selectedPhase !== null) {
            document.addEventListener("mousedown", handleClick);
            return () => document.removeEventListener("mousedown", handleClick);
        }
    }, [selectedPhase, handleClick]);

    const renderInfoBox = () => {
        if (isMobileView) return null;
        let imgSource = require("../../../../assets/img/avatar/monster_show_right.png");
        let infoText = <T _str="Here you can see which cards belong to which phase." />;
        let infoSubText = null;
        let message = (
            <InfoLink>
                <InfoLinkTextSmall>
                    <T _str="To see more, click on the bars." />
                </InfoLinkTextSmall>
                <PhaseSixIcon name={"chevron-right"} />
            </InfoLink>
        );
        if (selectedPhase !== null) {
            imgSource = require("../../../../assets/img/avatar/monster_raising_arms.png");
            if (selectedPhase === 0) {
                if (cardCounts[selectedPhase] === 0) {
                    infoText = <T _str="You have no cards left in stock." />;
                    infoSubText = <T _str="You know many words already." />;
                    message = (
                        <InfoLink>
                            <InfoLinkText>
                                <T _str="Starke Leistung!" />
                            </InfoLinkText>
                        </InfoLink>
                    );
                } else {
                    infoText = (
                        <T
                            _str="You still have {count} cards you can learn in stock."
                            count={cardCounts[selectedPhase].toLocaleString("de-DE")}
                        />
                    );
                    infoSubText = <T _str="Activate cards to practice them in training." />;
                    message = (
                        <InfoLink>
                            <InfoLinkText>
                                <T _str="Let's go!" />
                            </InfoLinkText>
                        </InfoLink>
                    );
                }
            } else if (selectedPhase === phaseCount - 1) {
                if (cardCounts[selectedPhase] === 0) {
                    imgSource = require("../../../../assets/img/avatar/monster_basic.png");
                    infoText = (
                        <T
                            _str="You do not have any cards permanently memorized yet."
                            count={cardCounts[selectedPhase]}
                        />
                    );
                    infoSubText = <T _str="Stick to it and do your practices." />;
                    message = (
                        <InfoLink>
                            <InfoLinkText>
                                <T _str="You can do it!" />
                            </InfoLinkText>
                        </InfoLink>
                    );
                } else {
                    infoText = (
                        <T
                            _str="Cool{name}, you already memorized {count} cards permanently."
                            name={props.ownerName ? " " + props.ownerName : ""}
                            count={cardCounts[selectedPhase].toLocaleString("de-DE")}
                        />
                    );
                    infoSubText = <T _str="Good job." />;
                    message = (
                        <InfoLink>
                            <InfoLinkText>
                                <T _str="Congratulations!" />
                            </InfoLinkText>
                        </InfoLink>
                    );
                }
            } else {
                if (cardCounts[selectedPhase] === 0) {
                    imgSource = require("../../../../assets/img/avatar/monster_basic.png");
                    infoText = (
                        <T
                            _str="You currently have no cards in phase {selectedPhase}"
                            selectedPhase={selectedPhase}
                        />
                    );
                    infoSubText = (
                        <T
                            _str="Starting from this phase, cards will be checked every {frequency} days."
                            frequency={phaseTrainingFrequency[selectedPhase]}
                        />
                    );
                } else {
                    infoText = (
                        <T
                            _str="Wow{name}, {count} cards in phase {selectedPhase} already"
                            name={props.ownerName ? " " + props.ownerName : ""}
                            count={cardCounts[selectedPhase].toLocaleString("de-DE")}
                            selectedPhase={selectedPhase}
                        />
                    );
                    infoSubText = (
                        <T
                            _str="Starting from this phase, cards will be checked {frequency}."
                            frequency={
                                phaseTrainingFrequency[selectedPhase] === 1
                                    ? t("every day")
                                    : t("every {count} days", { count: phaseTrainingFrequency[selectedPhase] })
                            }
                        />
                    );
                }
                message = (
                    <InfoLink>
                        <InfoLinkText>
                            <T _str="Keep it up!" />
                        </InfoLinkText>
                    </InfoLink>
                );
            }
        }
        return (
            <InformationBox>
                <InfoImageContainer>
                    <InfoImage src={imgSource} />
                </InfoImageContainer>
                <InfoTextContainer selected={selectedPhase !== null}>
                    <InfoText>{infoText}</InfoText>
                    <InfoSubtext>{infoSubText}</InfoSubtext>
                </InfoTextContainer>
                <InfoLinkContainer selected={selectedPhase !== null}>{message}</InfoLinkContainer>
            </InformationBox>
        );
    };

    return (
        <Container>
            {renderInfoBox()}
            <Phases>
                {data &&
                    cardCounts.map((phase, index) => {
                        const value = isNaN(phase) ? 0 : phase;
                        return (
                            <Phase
                                key={"phase_" + index}
                                ref={phaseRefs[index]}
                                onClick={() => onPhaseClick(index)}
                            >
                                <BarArea selected={index === selectedPhase}>
                                    <NonFilledArea fill={total - value} />
                                    <FilledArea
                                        fill={value}
                                        selected={index === selectedPhase}
                                    >
                                        <Number selected={index === selectedPhase}>
                                            {value.toLocaleString("de-DE")}
                                        </Number>
                                    </FilledArea>
                                </BarArea>
                                <PhaseName>
                                    {index === 0 ? (
                                        t("Stock")
                                    ) : isLongTermMemoryPhase(index, phaseCount - 1) ? (
                                        <PhaseSixIcon
                                            name="p6"
                                            highlighted
                                        />
                                    ) : (
                                        index
                                    )}
                                </PhaseName>
                            </Phase>
                        );
                    })}
            </Phases>
        </Container>
    );
};

export default ReportPhases;
