// private function for listening to keydown events
const _keyDownListener = (keyCodes: Array<string>, callback: Function) => {
    // we don't use "this" here, but it comes as param from the dom listener
    function onKeyDown(this: Window, ev: KeyboardEvent) {
        if (keyCodes.indexOf(ev.code) > -1) {
            callback();
        }
    }

    window.addEventListener("keydown", onKeyDown);
    // Remove event listeners on cleanup
    return () => {
        window.removeEventListener("keydown", onKeyDown);
    };
};

export const enterKeyListener = (callback: Function) => {
    return _keyDownListener(["Enter", "NumpadEnter"], callback);
};
