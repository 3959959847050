import styled from "styled-components";

export const Container = styled.div`
    height: 100%;
    margin-right: ${(props) => props.theme.base.spacing};
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
`;
export const Header = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: ${(props) => props.theme.base.spacing};
    padding-right: calc(
        ${(props) => props.theme.base.spacing} + 15px
    ); // add 15px to compensate for content scrollbar width
    border-radius: ${(props) => props.theme.base.borderRadius};
    background: ${(props) => props.theme.colors.backgroundContrast};
    color: ${(props) => props.theme.colors.text};
    font-size: ${(props) => props.theme.base.bigSize};
`;
export const List = styled.div`
    max-height: calc(100vh - 190px);
    flex: 1;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    overflow-y: scroll;
`;
export const Subject = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: ${(props) => props.theme.base.spacingSmall};
    padding: ${(props) => props.theme.base.spacingSmall};
    border-radius: ${(props) => props.theme.base.borderRadius};
    background: ${(props) => props.theme.colors.backgroundContrast};
    color: ${(props) => props.theme.colors.text};
    font-size: ${(props) => props.theme.base.bigSize};
    cursor: pointer;
`;
export const Title = styled.div`
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    overflow: hidden;
`;
export const SubjectTitle = styled.span`
    padding-left: ${(props) => props.theme.base.spacing};
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;
export const Statistics = styled.div`
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`;
export const StatisticEntry = styled.div`
    flex: 1;
    text-align: center;
    border-right: 1px solid ${(props) => props.theme.colors.text};
`;
export const Total = styled.div`
    flex: 1;
    text-align: center;
    text-transform: uppercase;
`;
export const LastPractice = styled.span`
    flex: 1;
    text-transform: uppercase;
    text-align: center;
`;

export const NoPhasesContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    background: ${(props) => props.theme.colors.backgroundContrast};
    border-radius: ${(props) => props.theme.base.borderRadius};
`;
export const NoPhasesIcon = styled.div`
    display: flex;
    height: fit-content;
    justify-content: center;
    padding: ${(props) => props.theme.base.spacing};
    margin: ${(props) => props.theme.base.spacing};
    background: ${(props) => props.theme.colors.backgroundMain};
    border-radius: 50%;
`;
export const NoPhasesText = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: ${(props) => props.theme.base.spacing};
`;
export const NoPhasesHeader = styled.p`
    margin-bottom: ${(props) => props.theme.base.spacing};
    color: ${(props) => props.theme.colors.primary};
    font-size: ${(props) => props.theme.base.titleBigSize};
    font-weight: ${(props) => props.theme.base.bigWeight};
`;
export const NoPhasesBody = styled.div``;
export const NoPhasesInfo = styled.p`
    color: ${(props) => props.theme.colors.text};
`;
export const NoPhasesButton = styled.a`
    border-radius: ${(props) => props.theme.base.borderRadiusSmall};
    background-color: ${(props) => props.theme.colors.primary};
    margin-top: ${(props) => props.theme.base.spacing};
    padding: ${(props) => props.theme.base.spacingSmall};
    align-self: flex-start;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    color: ${(props) => props.theme.colors.backgroundContrast};
    font-size: ${(props) => props.theme.base.bigSize};
    cursor: pointer;
    text-decoration: none;
    transition: all 0.25s;

    &:hover {
        opacity: 0.7;
    }
`;
