// REACT
import React, { FunctionComponent, memo } from "react";

// LIBRARIES
import Modal from "../modal/Modal";

// STYLED COMPONENTS
import { Container, Img, Audio, Video } from "./styles";

const relation = {
    image: Img,
    video: Video,
    audio: (props: any) => (
        <Audio
            {...props}
            controls
            autoPlay
            preload="none"
            type="audio/mpeg"
        />
    ),
};

export type Props = {
    url?: string;
    type?: "image" | "audio" | "video";
    onClose: () => void;
};

const FilePreview: FunctionComponent<Props> = (props) => {
    const { url = "", type = "image", onClose } = props;

    const MediaComponent: FunctionComponent<any> = relation[type];

    return (
        <Modal
            active={!!url}
            afterClose={onClose}
            destroyOnClose
            footerComponent={false}
        >
            <Container>
                <MediaComponent src={url} />
            </Container>
        </Modal>
    );
};

export default memo(FilePreview);
