import styled from "styled-components";
import MouseEventIcon from "../../basic/mouseEventIcon/MouseEventIcon";
import ReactTooltip from "react-tooltip";

export const Text = styled.p`
    position: relative;
    margin-left: ${(props) => props.theme.base.spacingInContent};
    font-size: ${(props) => props.theme.base.bigSize};
    color: ${(props) => props.theme.colors.text};
    text-align: left;

    > p {
        color: ${(props) => props.theme.colors.text};
    }
`;

export const StyledCategoryWrapper = styled.div`
    width: 100%;
    cursor: pointer;
    margin-bottom: ${(props) => props.theme.base.spacingInContent};
    border-radius: ${(props) => props.theme.base.borderRadius};
    background: ${(props) => props.theme.colors.backgroundContrast};
    color: ${(props) => props.theme.colors.text};
    display: flex;
    align-items: center;

    &.no-hover:hover {
        background: unset;
    }

    &:hover {
        * {
            color: ${(props) => props.theme.colors.primary};
        }
    }

    &.disabled {
        cursor: not-allowed;

        * {
            color: ${(props) => props.theme.colors.textLight};
        }
    }
`;

export const StyledActionItemContainer = styled.div`
    width: 100%;
    height: 100%;
    text-decoration: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: ${(props) => props.theme.base.spacingSmall};
`;

export const StyledReactTooltip = styled(ReactTooltip)`
    color: #fff !important;
`;

export const IconAndTextContainer = styled.div`
    display: flex;
    flex: 4;
    align-items: center;
    justify-content: flex-start;
`;

export const CategoryIcon = styled(MouseEventIcon)`
    width: 30px !important;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${(props) => props.theme.colors.text};
`;

export const Subtitle = styled.span`
    color: ${(props) => props.theme.colors.textLight};

    > p {
        color: ${(props) => props.theme.colors.text};
    }
`;

export const DisabledTextSpan = styled.span``;
