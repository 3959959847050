// LIBRARIES
import React from "react";
import { T } from "@transifex/react";

// TYPES
import { SubjectAssignmentModalType } from "p6m-subjects";

// STYLES
import { CartIcon } from "../styles";

interface ConsumptionTextProps {
    modalType: SubjectAssignmentModalType;
    isStrictMode: boolean;
}

export const ConsumeButtonText = ({ modalType, isStrictMode }: ConsumptionTextProps) => {
    switch (modalType) {
        case "AvailableSubject": {
            return (
                <T
                    _str="Learn now"
                    _tags="Learn"
                />
            );
        }
        case "NewSubject": {
            return isStrictMode ? (
                <T
                    _str="Download now"
                    _tags="Learn"
                />
            ) : (
                <T
                    _str="Try 30 days free"
                    _tags="Learn"
                />
            );
        }
        case "DeletedActiveSubject": {
            return (
                <T
                    _str="Download now"
                    _tags="Learn"
                />
            );
        }
        case "SelfCreatedContent": {
            return (
                <T
                    _str="Download now"
                    _tags="Learn"
                />
            );
        }
        case "LoadedSelfCreatedContent": {
            return (
                <T
                    _str="Learn now"
                    _tags="Learn"
                />
            );
        }
        case "TrialExpired": {
            return (
                <>
                    <CartIcon
                        name="cart"
                        size="standard"
                    />
                    <T
                        _str="Buy now"
                        _tags="tests"
                    />
                </>
            );
        }
        default: {
            return null;
        }
    }
};

export default ConsumeButtonText;
