// REACT
import React, { useEffect, useState } from "react";
import { useT } from "@transifex/react";
import { InView } from "react-intersection-observer";

//STYLED COMPONENTS
import {
    Container,
    Row,
    Column,
    ColumnWide,
    Block,
    Label,
    Value,
    More,
    HighlightedLabel,
    HighlightedValue,
    HoverColorAndVisibilityIcon,
    HoverColorIcon,
    UserAvatarWrapper,
} from "./styles";

//TYPES
import { LeaderboardStats } from "p6m-user";

// COMPONENTS
import LeaderBoardUserStatistic from "../../leaderboardUserStatistic/LeaderBoardUserStatistic";
import PhaseSixIcon from "../../phaseSixIcon/PhaseSixIcon";
import UserAvatar from "../../userAvatar/UserAvatar";

// NETWORKING
import { addFavouriteSchool, deleteFavouriteSchool } from "../../../../networking/leaderBoard";

export interface leaderBoardUserItemProps {
    user: LeaderboardStats;
    userAvatarLink?: string;
    isCurrentUser: boolean;
    selectedTab: string;
    userId: string;
}

const LeaderBoardUserItem: React.FC<leaderBoardUserItemProps> = (props) => {
    const t = useT();
    const [details, toggleDetails] = useState<boolean>(false);
    const [isFavourite, setIsFavourite] = useState<boolean>(false);

    const regularlyLearned_t = t("Regularly learned", { _tags: "label" });
    const learnedForTest_t = t("Learned for Test", { _tags: "label" });
    const newVocabulary_t = t("New vocabulary", { _tags: "label" });
    const typedLetters_t = t("Typed letters", { _tags: "label" });

    useEffect(() => {
        setIsFavourite(!!props.user.isFavorit);
    }, [props.user.isFavorit]);

    const toggleFavourite = (entry: LeaderboardStats) => {
        const userId = props.userId;
        setIsFavourite(!isFavourite);
        if (isFavourite) {
            deleteFavouriteSchool(parseInt(entry.userId, 10), userId);
        } else {
            addFavouriteSchool(parseInt(entry.userId, 10), userId);
        }
    };

    return (
        <InView>
            {({ inView, ref }) => (
                <Container ref={ref}>
                    {inView ? (
                        <>
                            <Row>
                                <Column>
                                    {props.isCurrentUser ? (
                                        <HighlightedLabel>
                                            {(props.user.position + 1).toLocaleString("de-DE")}
                                        </HighlightedLabel>
                                    ) : (
                                        <Label>{(props.user.position + 1).toLocaleString("de-DE")}</Label>
                                    )}
                                </Column>
                                {(props.selectedTab === "allSchools" || props.selectedTab === "favourites") && (
                                    <Column>
                                        {props.isCurrentUser ? (
                                            <PhaseSixIcon
                                                name={"star-full"}
                                                highlighted
                                            />
                                        ) : props.selectedTab === "allSchools" && isFavourite ? (
                                            <HoverColorIcon
                                                name={isFavourite ? "star-full" : "no-star"}
                                                onClick={() => toggleFavourite(props.user)}
                                            />
                                        ) : (
                                            <HoverColorAndVisibilityIcon
                                                name={isFavourite ? "star-full" : "no-star"}
                                                onClick={() => toggleFavourite(props.user)}
                                            />
                                        )}
                                    </Column>
                                )}
                                <Column>
                                    {props.selectedTab === "allSchools" || props.selectedTab === "favourites" ? (
                                        <PhaseSixIcon
                                            name={"school_building"}
                                            size={"medium"}
                                            highlighted={props.isCurrentUser}
                                        />
                                    ) : (
                                        <UserAvatarWrapper>
                                            <UserAvatar
                                                userAvatar={props.userAvatarLink}
                                                highlighted={props.isCurrentUser}
                                            />
                                        </UserAvatarWrapper>
                                    )}
                                </Column>
                                <Block>
                                    <ColumnWide>
                                        {props.isCurrentUser ? (
                                            <HighlightedLabel>
                                                {props.user.userName}
                                                <br />
                                                {!!props.user.countryRegionName &&
                                                    `${props.user.countryRegionName + " - "}`}
                                                {!!props.user.street && `${props.user.street} `}
                                                {!!props.user.zip && `${props.user.zip} `}
                                                {!!props.user.city && `${props.user.city} `}
                                            </HighlightedLabel>
                                        ) : (
                                            <Label>
                                                {props.user.userName}
                                                <br />
                                                {!!props.user.countryRegionName &&
                                                    `${props.user.countryRegionName + " - "}`}
                                                {!!props.user.street && `${props.user.street} `}
                                                {!!props.user.zip && `${props.user.zip} `}
                                                {!!props.user.city && `${props.user.city} `}
                                            </Label>
                                        )}
                                    </ColumnWide>
                                    <Column>
                                        {props.isCurrentUser ? (
                                            <HighlightedValue>
                                                {props.user.points.toLocaleString("de-DE")}
                                            </HighlightedValue>
                                        ) : (
                                            <Value>{props.user.points.toLocaleString("de-DE")}</Value>
                                        )}
                                    </Column>
                                </Block>
                                <Column>
                                    <More
                                        key={"up" + props.user.userId}
                                        onClick={() => toggleDetails(!details)}
                                    >
                                        <PhaseSixIcon
                                            name={details ? "chevron-up" : "chevron-down"}
                                            highlighted={props.isCurrentUser}
                                        />
                                    </More>
                                </Column>
                            </Row>
                            {details && (
                                <Row>
                                    <Column />
                                    <Column />
                                    {(props.selectedTab === "allSchools" || props.selectedTab === "favourites") && (
                                        <Column />
                                    )}
                                    <Block>
                                        <ColumnWide>
                                            {details ? (
                                                <LeaderBoardUserStatistic
                                                    lineOne={regularlyLearned_t}
                                                    lineTwo={learnedForTest_t}
                                                    lineThree={newVocabulary_t}
                                                    lineFour={typedLetters_t}
                                                    highlighted={props.isCurrentUser}
                                                />
                                            ) : null}
                                        </ColumnWide>
                                        <Column>
                                            {details ? (
                                                <LeaderBoardUserStatistic
                                                    lineOne={props.user.learnedCards.toLocaleString("de-DE")}
                                                    lineTwo={props.user.learnedCardsForTest.toLocaleString("de-DE")}
                                                    lineThree={props.user.activatedCards.toLocaleString("de-DE")}
                                                    lineFour={props.user.typedLetters.toLocaleString("de-DE")}
                                                    highlighted={props.isCurrentUser}
                                                />
                                            ) : null}
                                        </Column>
                                    </Block>
                                    <Column />
                                </Row>
                            )}
                        </>
                    ) : null}
                </Container>
            )}
        </InView>
    );
};

export default LeaderBoardUserItem;
