import styled from "styled-components";
import PhaseSixIcon from "../phaseSixIcon/PhaseSixIcon";
import { processedColor } from "p6m-themes";

export const StyledPhaseSixIcon = styled(PhaseSixIcon)<{ color: processedColor }>`
    color: ${(props) => props.color};
`;
export const IconContainer = styled.div`
    margin: auto;
    padding-top: 10px;
    text-align: center;

    > svg {
        width: 24px;
        max-height: 60%;
    }

    @media (max-width: ${process.env.REACT_APP_LOWER_GRID_STEP_BORDER}px) {
        margin: 0 1em 0 0.5em;
        padding: 0;
        width: 35px;
    }
`;
