// LIBRARIES
import React from "react";

// CONSTANTS
import ResponseConstants, { ResponseType } from "../../../constants/ResponseConstants";

//COMPONENTS
import {
    StyledText,
    StyledOuterContainer,
    StyledSuccessContainer,
    StyledWarningContainer,
    StyledErrorContainer,
} from "./styles";

export interface ResponseProps {
    show: boolean;
    text: string[];
    cancelResponse: () => void;
    responseType: ResponseType;
}

const Response: React.FC<ResponseProps> = ({ responseType, text, show, cancelResponse }) => {
    if (show) {
        let content = text.map((txt, index) => {
            return <StyledText key={"response_" + index}>{txt}</StyledText>;
        });

        let innerContainer = (
            <StyledSuccessContainer
                onClick={cancelResponse}
                children={content}
            />
        );

        if (responseType === ResponseConstants.ERROR)
            innerContainer = (
                <StyledErrorContainer
                    onClick={cancelResponse}
                    children={content}
                />
            );
        if (responseType === ResponseConstants.WARNING)
            innerContainer = (
                <StyledWarningContainer
                    onClick={cancelResponse}
                    children={content}
                />
            );

        return <StyledOuterContainer>{innerContainer}</StyledOuterContainer>;
    }

    return null;
};

export default Response;
