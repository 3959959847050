// REACT
import React from "react";

// LIBRARIES
import moment from "moment";

// STYLED COMPONENTS
import { Background, DateText } from "./styles";

const DateBox: React.FC = () => {
    const date = new Date();
    return (
        <Background>
            <DateText>{moment(date).format("DD.MM.YYYY")}</DateText>
        </Background>
    );
};

export default DateBox;
