import styled from "styled-components";
import PhaseSixIcon from "../../../basic/phaseSixIcon/PhaseSixIcon";

export const Textarea = styled.textarea`
    display: block;
    width: 100%;
    margin: 0;
    padding: ${({ theme: { base } }) => base.spacingInContent} ${({ theme: { base } }) => base.spacingSmall};
    background: none;
    border: none;
    resize: none;
    word-break: break-word;
    font-family: ${(props) => props.theme.base.fontFamily};
    font-size: ${(props) => props.theme.base.mediumSize};
    color: ${(props) => props.theme.colors.text};

    &:focus {
        outline: 0;
    }
`;

export const IconContainer = styled.div`
    color: ${(props) => props.theme.colors.text};
    cursor: pointer;
    display: flex;
`;

export const MainIcon = styled(PhaseSixIcon)<{ active: boolean }>`
    color: ${(props) => (props.active ? props.theme.colors.primary : props.theme.colors.text)};
`;

export const Wrapper = styled.div`
    position: relative;
    width: 100%;
`;
