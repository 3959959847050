import React from "react";
import { useT } from "@transifex/react";

export enum RequirementNames {
    DefaultProcessor,
    FinishedFirstPracticeProcessor,
    AllTimeLearnedCardsProcessor,
    AllTimePointsProcessor,
    MinDaysInAStreakProcessor,
    AllTimeTypedLettersProcessor,
    CurrentNegativeStreakProcessor,
    ReferredPeopleProcessor,
    FinishedWeekInTopPercentOfAllUsersProcessor,
    AllTimeTestLearnedCardsProcessor,
    FinishedWeekInTopSchoolProcessor,
    Challenge2022Processor,
    GtTopicAmountRuleProcessor,
}

export enum RequirementText {
    description,
    finished,
    incomplete,
}

export interface AvatarsContextInterface {
    getTranslation: (name: RequirementNames, textType: RequirementText, param: number) => string;
}

export const AvatarsContext = React.createContext<AvatarsContextInterface>({
    getTranslation: (name: RequirementNames, textType: RequirementText, param: number) => "", // Avatar translations not yet initialised
});

const AvatarsContextWrapper: React.FC = (props) => {
    const t = useT();
    const getTranslation = (name: RequirementNames, textType: RequirementText, param: number) => {
        switch (name) {
            case RequirementNames.DefaultProcessor:
                if (textType === RequirementText.description) {
                    return t("This is your default avatar. All data for avatars is counted starting with 2021.");
                }
                break;
            case RequirementNames.FinishedFirstPracticeProcessor:
                if (textType === RequirementText.description) {
                    return t("Finish first practise");
                } else if (textType === RequirementText.finished) {
                    return t("Finished first practise");
                } else if (textType === RequirementText.incomplete) {
                    return t("Not completed first practise");
                }
                break;
            case RequirementNames.AllTimeLearnedCardsProcessor:
                if (textType === RequirementText.description) {
                    return t("Study {amount} cards regularly", { amount: param });
                } else if (textType === RequirementText.finished) {
                    return t("All time regular cards learned: {amount}", { amount: param });
                } else if (textType === RequirementText.incomplete) {
                    return t("Cards regularly learned: {amount}", { amount: param });
                }
                break;
            case RequirementNames.AllTimePointsProcessor:
                if (textType === RequirementText.description) {
                    return t("Earn {amount} points in Leaderboard", { amount: param });
                } else if (textType === RequirementText.finished) {
                    return t("All time points earned: {amount}", { amount: param });
                } else if (textType === RequirementText.incomplete) {
                    return t("Points earned: {amount}", { amount: param });
                }
                break;
            case RequirementNames.MinDaysInAStreakProcessor:
                if (textType === RequirementText.description) {
                    return t("Learn {amount} days in a row", { amount: param });
                } else if (textType === RequirementText.finished) {
                    return t("Record of days learned in a row: {amount}", { amount: param });
                } else if (textType === RequirementText.incomplete) {
                    return t("Current days learned in a row: {amount}", { amount: param });
                }
                break;
            case RequirementNames.AllTimeTypedLettersProcessor:
                if (textType === RequirementText.description) {
                    return t("Typed {amount} Letters during practise", { amount: param });
                } else if (textType === RequirementText.finished) {
                    return t("Record of letters typed: {amount}", { amount: param });
                } else if (textType === RequirementText.incomplete) {
                    return t("Current typed letters: {amount}", { amount: param });
                }
                break;
            case RequirementNames.CurrentNegativeStreakProcessor:
                if (textType === RequirementText.description) {
                    return t("{amount} days inactive in a row", { amount: param });
                } else if (textType === RequirementText.finished) {
                    return t("Record days inactive: {amount}", { amount: param });
                } else if (textType === RequirementText.incomplete) {
                    return t("Current days inactive: {amount}", { amount: param });
                }
                break;
            case RequirementNames.ReferredPeopleProcessor:
                if (textType === RequirementText.description) {
                    return t("Refer a Friend");
                } else if (textType === RequirementText.finished) {
                    return t("Referred friends in total: {amount}", { amount: param });
                } else if (textType === RequirementText.incomplete) {
                    return t("Friends referred: {amount}", { amount: param });
                }
                break;
            case RequirementNames.FinishedWeekInTopPercentOfAllUsersProcessor:
                if (textType === RequirementText.description) {
                    return t("Finish in the top 10% of the weeks leaderboards");
                } else if (textType === RequirementText.finished) {
                    return t("Current Position: {amount}", { amount: param });
                } else if (textType === RequirementText.incomplete) {
                    return t("Current Position: {amount}", { amount: param });
                }
                break;
            case RequirementNames.AllTimeTestLearnedCardsProcessor:
                if (textType === RequirementText.description) {
                    return t("Learn {amount} cards for a test", { amount: param });
                } else if (textType === RequirementText.finished) {
                    return t("Total of cards learned for a test: {amount}", { amount: param });
                } else if (textType === RequirementText.incomplete) {
                    return t("Cards learned for a test: {amount}", { amount: param });
                }
                break;
            case RequirementNames.FinishedWeekInTopSchoolProcessor:
                if (textType === RequirementText.description) {
                    return t("Finish in the top {amount} of the leaderboard with your school.", { amount: param });
                } else if (textType === RequirementText.finished) {
                    return t("Last week: {amount}", { amount: param });
                } else if (textType === RequirementText.incomplete) {
                    return t("Last week: {amount}", { amount: param });
                }
                break;
            case RequirementNames.Challenge2022Processor:
                if (textType === RequirementText.description) {
                    return t("For participation in the school championship 2022");
                } else if (textType === RequirementText.finished) {
                    return t("Participated in the school championship");
                } else if (textType === RequirementText.incomplete) {
                    return t("Didn't participate in the school championship");
                }
                break;
            case RequirementNames.GtTopicAmountRuleProcessor:
                if (textType === RequirementText.description) {
                    return t("Finish one grammar topic");
                } else if (textType === RequirementText.finished) {
                    return t("Finished one grammar topic");
                } else if (textType === RequirementText.incomplete) {
                    return t("Not yet finished any grammar topiс");
                }
                break;
            default:
                return "";
        }
    };

    return <AvatarsContext.Provider value={{ getTranslation }}>{props.children}</AvatarsContext.Provider>;
};

export default AvatarsContextWrapper;
