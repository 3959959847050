import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../store/store";
import { INotificationsEvents } from "p6m-notifications";

interface NotificationsState {
    notifications: INotificationsEvents[];
    remindMe?: boolean;
}

const initialState: NotificationsState = {
    notifications: [],
    remindMe: undefined,
};

export const notificationsSlice = createSlice({
    name: "notifications",
    initialState,
    reducers: {
        loadNotifications: () => {},
        setNotifications: (state, action: PayloadAction<INotificationsEvents[]>) => {
            state.notifications = action.payload;
        },
        deleteNotification: (state, action: PayloadAction<INotificationsEvents[]>) => {},
        getRemindMe: () => {},
        changeRemindMe: (state, action: PayloadAction<boolean>) => {},
        setRemindMe: (state, action: PayloadAction<boolean>) => {
            state.remindMe = action.payload;
        },
    },
});

/* EXPORTS */
export const { actions, reducer } = notificationsSlice;

export const selectors = {
    notifications: (state: RootState) => state.notifications.notifications,
    remindMe: (state: RootState) => state.notifications.remindMe,
};
