// LIBRARIES
import React, { useEffect, useState, useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

//REDUX
import { actions as testsActions, selectors as testsSelectors } from "../../../redux/tests/testsSlice";
import { actions as responseActions } from "../../../redux/response/responseSlice";
import { actions as subjectsActions, selectors as subjectsSelectors } from "../../../redux/subjects/subjectsSlice";
import { actions as learningActions } from "../../../redux/learning/learningSlice";

//UTILS
import { purchaseTrial } from "../../../networking/subjects";
import { useSharedTestConsumption } from "../../../hooks/useSharedTestConsumption";
import { getSubjectCover } from "../../../helpers/Subjects";

//COMPONENTS;
import { SubjectAssignmentModal } from "./SubjectAssignmentModal";
import { selectors as userSelectors } from "../../../redux/user/userSlice";
import { actions as appStatusActions } from "../../../redux/appStatus/appStatusSlice";

//TYPES
import { ResponseMessageKeys } from "../response";
import { SharedTestMetaData } from "p6m-tests";
import { SubjectActions, SubjectData } from "p6m-subjects";

//ANALYTICS
import { ampli } from "../../../ampli";

const SubjectAssignmentModalWrapper = ({
    subject,
    subjectActions,
    sharedTestMetaData,
}: {
    subject: SubjectData | undefined;
    subjectActions: SubjectActions;
    sharedTestMetaData: SharedTestMetaData;
}) => {
    const dispatch = useDispatch();
    const modal = useSelector(subjectsSelectors.subjectAssignmentModal);
    const sharedTest = useSelector(testsSelectors.sharedTestMetaData);
    const isStrictMode = useSelector(userSelectors.isStrict);
    const shopFreeContentsCounts = useSelector(userSelectors.shopFreeContentsCounts);

    const [showOverlay, setShowOverlay] = useState(false);

    // remove loading overlay when modal changed, e.g. because content has arrived
    useEffect(() => {
        setShowOverlay(false);
    }, [modal]);

    const subjectData = useMemo(
        () => ({
            id: subject?.subjectMetadata.subjectIdToOwner.id,
            name: subject?.subjectContent.name,
            coverImageId: getSubjectCover(subject),
        }),
        [subject]
    );

    useEffect(() => {
        dispatch(testsActions.fetchSharedTestFromMetadata());
    }, [dispatch, sharedTestMetaData]);

    useEffect(() => {
        if (!subject?.units && !!subjectData.id) {
            dispatch(subjectsActions.loadSubjectUnits(subjectData.id));
        }
    }, [dispatch, subject, subjectData]);

    const { handleSharedTestConsumption, closeSubjectAssignmentModal } = useSharedTestConsumption({
        sharedTestMetaData,
    });

    const handleSubmit = useCallback(async () => {
        if (!modal || !sharedTest) {
            return;
        }

        switch (modal.type) {
            case "AvailableSubject": {
                ampli.acceptedExercise({
                    test_origin: "teacher",
                    exercise_id: sharedTestMetaData.testId,
                });

                const { success } = await handleSharedTestConsumption();
                dispatch(
                    learningActions.setData({
                        practiceType: "exercise",
                    })
                );
                !!success && !!subjectData.id && subjectActions.prepare(subjectData.id);
                break;
            }
            case "NewSubject": {
                if (isStrictMode && shopFreeContentsCounts === 0) {
                    dispatch(
                        responseActions.showResponse({
                            type: "ERROR",
                            responseMessage: ResponseMessageKeys.SHARED_TEST_STRICT_SUBJECT_LIMIT_REACHED,
                        })
                    );
                } else {
                    setShowOverlay(true);
                    dispatch(appStatusActions.setLoading(true));
                    purchaseTrial({ identifier: modal.contentIdentifier, extended: true });
                }
                break;
            }
            case "DeletedActiveSubject": {
                setShowOverlay(true);
                dispatch(appStatusActions.setLoading(true));
                purchaseTrial({ identifier: modal.contentIdentifier, extended: false });
                break;
            }
            case "TrialExpired": {
                dispatch(subjectsActions.setSubjectAssignmentModal(null));
                dispatch(testsActions.setSharedTestMetaData(null));
                setTimeout(() => {
                    window.location.href =
                        "https://www.phase-6.de/classic/shop/basket.html?ArticleUUID=" + sharedTest.subjectId;
                }, 500);
                break;
            }
            case "SelfCreatedContent": {
                setShowOverlay(true);
                dispatch(appStatusActions.setLoading(true));
                handleSharedTestConsumption();
                break;
            }
            case "LoadedSelfCreatedContent": {
                const { success } = await handleSharedTestConsumption();
                dispatch(
                    learningActions.setData({
                        practiceType: "exercise",
                    })
                );
                !!success && !!subjectData.id && subjectActions.prepare(subjectData.id);
                break;
            }
        }
    }, [dispatch, modal, sharedTest, handleSharedTestConsumption]);

    return !modal ? null : (
        <SubjectAssignmentModal
            data={modal}
            showOverlay={showOverlay}
            isStrictMode={isStrictMode}
            onSubmit={handleSubmit}
            onCancel={closeSubjectAssignmentModal}
        />
    );
};

export default SubjectAssignmentModalWrapper;
