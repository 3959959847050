// REACT
import React, {
    FunctionComponent,
    PropsWithChildren,
    useState,
    useEffect,
    useCallback,
    Children,
    useContext,
} from "react";

// COMPONENTS
import PhaseSixIcon from "../phaseSixIcon/PhaseSixIcon";
import Component, { Props as ComponentProps } from "./SpecialCharSelect";
import { SpecialCharMenu } from "./SpecialCharMenu";

// UTILS
import { PracticeContext } from "../../../context/practice";

export type Props = {
    isOpen?: boolean;
    lang?: string;
    onCharSelect: (selectedChar: string) => void;
} & Partial<Omit<ComponentProps, "isOpen" | "menu">>;

export const SpecialCharSelect: FunctionComponent<PropsWithChildren<Props>> = (props) => {
    const practiceContext = useContext(PracticeContext);
    const { children, lang, isOpen: propsIsOpen = false, onCharSelect, ...restComponentWrapperProps } = props;
    const [isOpen, setOpen] = useState(propsIsOpen);
    const [selectedLang, setSelectedLang] = useState<string | undefined>();

    useEffect(() => {
        if (lang === selectedLang) return;
        setSelectedLang(lang);
    }, [propsIsOpen]);

    useEffect(() => {
        if (typeof propsIsOpen === "undefined") return;
        setOpen(propsIsOpen);
    }, [propsIsOpen]);

    const openCallback = useCallback(() => {
        setOpen(true);
        practiceContext.setIsCharMenuOpen(true);
    }, []);

    const closeCallback = useCallback(() => {
        setOpen(false);
        practiceContext.setIsCharMenuOpen(false);
        practiceContext.setSelectedCharLanguage(undefined);
    }, []);

    return (
        <Component
            isOpen={isOpen}
            menu={
                <SpecialCharMenu
                    onClose={closeCallback}
                    onCharSelect={onCharSelect}
                    lang={practiceContext.selectedCharLanguage || lang}
                    onChangeLang={(lang: string) => {
                        practiceContext.setSelectedCharLanguage(lang);
                        setSelectedLang(lang);
                    }}
                />
            }
            {...restComponentWrapperProps}
        >
            {!!children ? (
                Children.only(children)
            ) : (
                <span onClick={() => openCallback()}>
                    <PhaseSixIcon name="special-character" />
                </span>
            )}
        </Component>
    );
};
