// REACT
import React, { FunctionComponent, useState, useEffect, useCallback } from "react";
import { useT } from "@transifex/react";

// REDUX
import { useDispatch } from "react-redux";
import { actions as appStatusActions } from "../../../../redux/appStatus/appStatusSlice";
import { actions as responseActions } from "../../../../redux/response/responseSlice";

// NETWORKING
import { upload } from "../../../../networking/images";

// UTILS
import { Props as FilesProps, IdTypeDivider } from "../files/Files";
import { getPreferredMimeType } from "../../../../helpers/GetPreferredMimetype";

// COMPONENTS
import Component from "./RecordAudio";

const fileLimit: number = parseInt(process.env.REACT_APP_MEDIA_MAX_SIZE_MB || "3");

type Props = {
    onChange?: (files: FilesProps["files"][0]) => void;
    disabled?: boolean;
};

export const RecordAudio: FunctionComponent<Props> = (props) => {
    const { onChange = () => {}, disabled = false } = props;
    const [isRecording, setRecording] = useState<boolean>(false);
    const [canRecord, setCanRecord] = useState<boolean>(false);
    const [hasPermission, setHasPermission] = useState<boolean>(false);
    const dispatch = useDispatch();
    const t = useT();

    const T_ServerError = t("Server Error, try later", { _tags: "response" });
    const T_UploadSuccessMessage = t("Media Upload Successful", { _tags: "response" });
    const T_MaxSizeLimit = t("Maximum file size to upload: {limit}.00 MB", { limit: fileLimit });

    useEffect(() => {
        if (disabled) return setCanRecord(false);
        setCanRecord(checkCanRecord());
    }, [disabled]);

    useEffect(() => {
        if (!canRecord) return;
        checkHasPermission(setHasPermission);
    }, [canRecord]);

    useEffect(() => {
        if (!canRecord || !isRecording) return;

        const recordedChunks: any[] = [];
        //@ts-ignore
        let mediaRecorder: MediaRecorder | undefined;

        const onSuccess = (stream: MediaStream) => {
            const mimeType = getPreferredMimeType();
            const options = { mimeType };

            try {
                //@ts-ignore
                mediaRecorder = new MediaRecorder(stream, options);
            } catch (e) {
                throw new Error(`MediaRecorder failed: ${e}`);
            }

            mediaRecorder.addEventListener("dataavailable", (event: any) => {
                if (event.data.size <= 0) return;
                recordedChunks.push(event.data);
            });

            mediaRecorder.addEventListener("stop", async () => {
                stream.getTracks().forEach((track) => track.stop());
                const result: File = new File(recordedChunks, Date(), { type: mimeType });
                const limitInBytes = fileLimit * 1000000;
                if (result.size > limitInBytes) {
                    dispatch(responseActions.showResponse({ type: "ERROR", text: [T_MaxSizeLimit] }));
                    return;
                }

                dispatch(appStatusActions.setLoading(true));
                try {
                    const { data } = await upload([result]);

                    if (data.httpCode !== 200) {
                        dispatch(responseActions.showResponse({ type: "ERROR", text: [T_ServerError] }));
                        return;
                    }

                    const {
                        replyContent: {
                            ids: [idWithOwner],
                        },
                    } = data;

                    if (!idWithOwner) {
                        dispatch(responseActions.showResponse({ type: "ERROR", text: [T_ServerError] }));
                        return;
                    }

                    dispatch(responseActions.showResponse({ type: "SUCCESS", text: [T_UploadSuccessMessage] }));

                    const newFile: string = idWithOwner + IdTypeDivider + "audio";

                    onChange(newFile);
                } catch (e) {
                    console.log(e);
                    dispatch(responseActions.showResponse({ type: "ERROR", text: [T_ServerError] }));
                } finally {
                    dispatch(appStatusActions.setLoading(false));
                }
            });

            mediaRecorder.start();
        };

        navigator.mediaDevices
            .getUserMedia({ audio: true, video: false })
            .then(onSuccess)
            .catch((e) => {
                console.log("Error AudioRecording", e);
                setHasPermission(false);
                setRecording(false);
            });

        return () => {
            if (!mediaRecorder) return;
            mediaRecorder.stop();
            mediaRecorder = undefined;
        };
    }, [canRecord, isRecording, onChange, dispatch, T_ServerError, T_UploadSuccessMessage, T_MaxSizeLimit]);

    const onStartCallback = useCallback(() => {
        if (!canRecord) return;
        setRecording(true);
    }, [canRecord]);

    const onStopCallback = useCallback(() => {
        setRecording(false);
    }, []);

    return (
        <Component
            canRecord={canRecord}
            isRecording={isRecording}
            hasPermission={hasPermission}
            onStart={onStartCallback}
            onStop={onStopCallback}
        />
    );
};

function checkCanRecord(): boolean {
    return !(!navigator || !navigator.mediaDevices || !navigator.mediaDevices.getUserMedia);
}

function checkHasPermission(callback: (hasPermission: boolean) => void) {
    if (!navigator || !navigator.permissions) return callback(true);
    //@ts-ignore
    return navigator.permissions.query({ name: "microphone" }).then((permissionStatus) => {
        callback(permissionStatus.state === "granted");
        permissionStatus.addEventListener("change", function () {
            const { state } = this;
            callback(state === "granted");
        });
    });
}
