// REACT
import React, { FunctionComponent, memo } from "react";

// LIBRARIES
import DOMPurify from "dompurify";

// COMPONENTS
import { CardAudio } from "./CardAudio";
import { CardImages } from "./CardImages";
import Annotate from "../Annotate/Annotate";

// STYLED COMPONENTS
import { CardContent, CardTitle, VolumeWrapper, AnnotateWrapper, AnswerExample } from "./styles";

type Props = {
    htmlTitle: string;
    example?: string;
    annotation?: string;
    transcription?: string;
    audios?: string[];
    images?: string[];
    onAnnotate?: (...props: any) => void;
};

const PracticeCardContent: FunctionComponent<Props> = (props) => {
    const { htmlTitle, audios = [], onAnnotate, example, annotation, transcription, images = [] } = props;

    return (
        <CardContent data-qa="card-content">
            <CardTitle
                noSelect
                dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(htmlTitle, { USE_PROFILES: { html: true } }) }}
            />

            {!!transcription && <CardTitle>[{transcription}]</CardTitle>}

            {!!audios.length && (
                <VolumeWrapper>
                    {audios.map((audio) => (
                        <CardAudio
                            key={audio}
                            audio={audio}
                        />
                    ))}
                </VolumeWrapper>
            )}

            {!!images.length && <CardImages images={images} />}

            {!!example && (
                <AnswerExample
                    dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(example, { USE_PROFILES: { html: true } }) }}
                />
            )}

            {!!onAnnotate && (
                <AnnotateWrapper>
                    <Annotate
                        value={annotation}
                        onAnnotate={onAnnotate}
                    />
                </AnnotateWrapper>
            )}
        </CardContent>
    );
};

export default memo(PracticeCardContent);
