//DEPENDENCIES
import styled from "styled-components";
import { Row } from "../../../basic/grid";
import PhaseSixIcon from "../../../basic/phaseSixIcon/PhaseSixIcon";

//STYLE
import { BlankButton } from "../../../basic/button/Button";

export const ModalHeader = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    background-color: ${({ theme: { colors } }) => colors.backgroundMain};
    font-size: ${({ theme: { base } }) => base.size};
    font-weight: bold;
    color: ${({ theme: { colors } }) => colors.text};
`;

export const Title = styled.h2`
    margin-top: 20px;
    margin-bottom: 0;
    font-size: ${({ theme: { base } }) => base.iconSmall};
    font-weight: bold;
    color: ${({ theme: { colors } }) => colors.text};
    text-align: center;
`;

export const IconWrapper = styled.div`
    margin-top: 20px;
    font-size: 140px;
    color: ${({ theme: { colors } }) => colors.text};
    text-align: center;
    & > * {
        font-size: inherit;
    }
`;

export const ScoreWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 20px;

    > p {
        font-size: ${({ theme: { base } }) => base.extraBigSize};
    }
`;

export const ButtonWrapper = styled(Row)`
    margin: 0 !important;
`;

export const ModalButton = styled(BlankButton)<{
    fullWidth?: boolean;
    useBackgroundColor?: boolean;
}>`
    min-height: 40px;
    ${({ fullWidth = false }) => (fullWidth ? "width: 100%;" : "")}
    min-width: 100px;
    background: ${({ useBackgroundColor = false, theme: { colors } }) => {
        return !useBackgroundColor ? "none" : colors.backgroundMain;
    }};
`;

export const StyledPhaseSixIcon = styled(PhaseSixIcon)`
    color: inherit;
`;
