import styled from "styled-components";
import SimpleLink from "../../basic/simpleLink/SimpleLink";
import PhaseSixIcon from "../../basic/phaseSixIcon/PhaseSixIcon";

export const Container = styled.div`
    display: flex;
    gap: ${(props) => props.theme.base.spacingTiny};
`;

export const StyledSimpleLink = styled(SimpleLink)`
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover {
        color: ${(props) => props.theme.colors.primary};
    }
`;

export const StyledInactiveSimpleLink = styled(SimpleLink)`
    position: relative;
    color: ${(props) => props.theme.colors.text};
`;

// action dropdowns
export const List = styled.ul`
    list-style: none;
    padding: 0 ${(props) => props.theme.base.spacingSmall};
`;

export const StyledEntry = styled.li`
    margin-bottom: ${(props) => props.theme.base.spacingSmall};
`;

export const EntryLink = styled.a`
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    color: ${(props) => props.theme.colors.text};
    text-decoration: none;

    &:hover {
        color: ${(props) => props.theme.colors.primary};
    }
`;

export const EntryText = styled.span`
    flex: 1;
    margin-right: ${(props) => props.theme.base.spacingSmall};
    white-space: nowrap;
`;

export const ActionText = styled.span`
    font-size: ${(props) => props.theme.base.mediumSize};
    text-transform: uppercase;
    display: block;
`;

export const ActionTooltip = styled.span`
    font-size: ${(props) => props.theme.base.size};
    display: block;
`;

export const StyledPhaseSixIcon = styled(PhaseSixIcon)<{ disabled?: boolean }>`
    color: ${(props) => (props.disabled ? props.theme.colors.text : "inherit")};
    opacity: ${(props) => (props.disabled ? 0.5 : 1)};
    cursor: ${(props) => (props.disabled ? "default" : "pointer")};
    display: flex;
    justify-content: center;
    align-items: center;
`;
