// DEPENDENCIES
import React, { useCallback } from "react";
import { useSelector } from "react-redux";
import { T } from "@transifex/react";
import { ampli } from "../../../ampli";

// STATE
import { selectors } from "../../../redux/user/userSlice";

// ASSETS
import Image from "../../../assets/img/gt-sidney.png";

// STYLES
import { Badge, Wrapper, Title, GrammarTutorButton, Img } from "./styles";

const grammarTutorUrl = process.env.REACT_APP_GRAMMAR_TUTOR_AUTOLOGIN_URL;

export const GrammarTrainerBanner = () => {
    const jossoSessionId = useSelector(selectors.jossoId);

    const openGrammarTrainer = useCallback(() => {
        if (grammarTutorUrl && jossoSessionId) {
            ampli.clickedWebAppToGrammarLink({
                click_source: "learncenter_web",
            });
            window.open(grammarTutorUrl + jossoSessionId);
        }
    }, [jossoSessionId]);

    return (
        <Wrapper>
            <Badge>
                <T _str="New" />
            </Badge>
            <Img
                width="120"
                height="120"
                src={Image}
                alt="Grammar Trainer"
            />
            <Title>
                <T _str="Have you learned English grammar today?" />
            </Title>
            <GrammarTutorButton onClick={openGrammarTrainer}>
                <T _str="Grammar-Trainer" />
            </GrammarTutorButton>
        </Wrapper>
    );
};
