// REACT
import React, { useRef, useEffect } from "react";

// COMPONENTS
import PhaseSixIcon from "../../basic/phaseSixIcon/PhaseSixIcon";

// STYLED COMPONENTS
import { ArrowContainer, ArrowRightContainer, ExampleContainer, ExampleSource, ExampleTarget, Word } from "./styles";

export interface CollapsableWordDetailsProps {
    exampleSource: string;
    exampleTarget: string;
    word: string;
    active: boolean;
    border: boolean;
    wordClassIndex: number;
    wordIndex: number;
    exampleIndex: number;
    exampleClicked: (wordClassIndex: number, wordIndex: number, exampleIndex: number) => void;
    multipleExamples: boolean;
}

const CollapsableWordDetails: React.FC<CollapsableWordDetailsProps> = (props) => {
    let scrollRef = useRef<HTMLButtonElement | null>(null);

    const createMarkdown = (htmlString: string) => {
        return { __html: htmlString };
    };

    const exampleClicked = () => {
        props.exampleClicked(props.wordClassIndex, props.wordIndex, props.exampleIndex);
    };

    let word = <Word active={props.active} />;
    if (props.exampleIndex === 0) {
        word = (
            <Word
                active={props.active}
                dangerouslySetInnerHTML={createMarkdown(props.word)}
            />
        );
    }

    useEffect(() => {
        if (scrollRef.current) {
            scrollRef.current.scrollIntoView({ block: "end" });
        }
    });

    return (
        <ExampleContainer
            active={props.active}
            border={props.border}
            multipleExamples={props.multipleExamples}
            onClick={exampleClicked}
            ref={props.active ? scrollRef : null}
        >
            {word}
            <ExampleSource
                active={props.active}
                dangerouslySetInnerHTML={createMarkdown(props.exampleSource)}
            />
            <ArrowContainer>
                <PhaseSixIcon
                    name={"arrow-right"}
                    highlighted={props.active}
                />
            </ArrowContainer>
            <ExampleTarget
                active={props.active}
                dangerouslySetInnerHTML={createMarkdown(props.exampleTarget)}
            />
            {props.exampleIndex === 0 && !props.active && !props.border && props.multipleExamples ? (
                <ArrowRightContainer>
                    <PhaseSixIcon name={"chevron-down"} />
                </ArrowRightContainer>
            ) : props.active ? (
                <ArrowRightContainer>
                    <PhaseSixIcon
                        name={"exercise-ok"}
                        highlighted
                    />
                </ArrowRightContainer>
            ) : (
                <ArrowRightContainer />
            )}
        </ExampleContainer>
    );
};

export default CollapsableWordDetails;
