// REACT
import React, { FunctionComponent, useState, useMemo, useCallback } from "react";

// COMPONENT
import Component from "./SpecialCharMenu";
import { getCharsByKey, getLangNames } from "../../../../helpers/charsList";

type Props = {
    lang?: string;
    onCharSelect: (selectedChar: string) => void;
    onClose?: () => void;
    onChangeLang: (lang: string) => void;
};

const languageNames = getLangNames();
const defaultLangKey = "de";

export const SpecialCharMenu: FunctionComponent<Props> = (props) => {
    const { lang = defaultLangKey, onClose, onCharSelect } = props;
    const [isUppercase, setUppercase] = useState(false);
    const [selectedLang, setSelectedLang] = useState(lang);

    const charObj = useMemo(() => {
        let langForChars;
        // to avoid duplicated entries
        switch (selectedLang) {
            case "bg": // Bulgarian
            case "uk": // Ukrainian
                langForChars = "ru";
                break;
            case "bp": // Brazilian Portuguese
                langForChars = "pt";
                break;
            case "ag": // Ancient Greek
            case "el": // Greek
                langForChars = "greek";
                break;
            default:
                langForChars = selectedLang;
        }

        return getCharsByKey(langForChars) || getCharsByKey(defaultLangKey);
    }, [selectedLang]);

    const getChars = () => {
        const key: keyof typeof charObj = isUppercase ? "upperChars" : "chars";
        return charObj[key];
    };

    const toggleUpperCase = useCallback(() => {
        setUppercase((prevState) => !prevState);
    }, []);

    const onLanguageSelect = useCallback((selectedLangKey: string) => {
        setSelectedLang(selectedLangKey);
        props.onChangeLang(selectedLangKey);
    }, []);

    return (
        <Component
            chars={getChars()}
            lang={charObj.name}
            langList={languageNames}
            isUppercase={isUppercase}
            onLangSelect={onLanguageSelect}
            onClose={onClose}
            onCaseToggle={toggleUpperCase}
            onCharSelect={onCharSelect}
        />
    );
};
