// Dependencies
import React, { useCallback, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { T, useT } from "@transifex/react";

// redux
import { useDispatch } from "react-redux";
import { actions as testsActions } from "../../../redux/tests/testsSlice";
import { actions as prepareActions } from "../../../redux/prepare/prepareSlice";
import { actions as learningActions } from "../../../redux/learning/learningSlice";

// Components
import PhaseSixIcon, { TIconNames } from "../../../components/basic/phaseSixIcon/PhaseSixIcon";
import { DropdownActions } from "../DropdownActions/DropdownActions";
import { DropdownActionsItem } from "../DropdownActions/DropdownActionsItem";
import Popup from "../../../components/basic/popup/Popup";

//TYPE
import { TestListItemInfo as TestListItemInfoT, TestsType } from "p6m-tests";
import { PracticeType } from "p6m-learning";

// Styles
import {
    TestListItemIcon,
    TestListItemContainer,
    TestListItemInfo,
    TestName,
    TotalCards,
    TestListItemDetailsContainer,
    LastElementOfListItem,
    ActionsMenu,
    ForwardToDetailsSection,
    LastResult,
    NewTestFlag,
} from "../styles";

interface TestListItemProps extends React.HTMLAttributes<HTMLDivElement> {
    test: TestListItemInfoT;
    clickable?: boolean;
    onDelete?: (testId: string) => void;
    onRename?: ({ id, subjectContentId, cardIds, direction, name }: TestListItemInfoT) => void;
}

export const TestListItem = ({ test, clickable, onDelete, onRename, ...rest }: TestListItemProps) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const t = useT();

    const iconsByTestTypeKeys: { [key in TestsType]: TIconNames } = useMemo(
        () => ({
            OWN_TESTS: "clipboard",
            //TODO: check with design team how and when to replace with new school-building icon from prototype
            SHARED_TESTS: "school-building",
        }),
        []
    );

    const goToTestDetails = useCallback(() => {
        if (!clickable) return;

        // show activation summary page for new tests
        if (test.lastTestResultPercent === -1) {
            // save practice type
            let practiceType: PracticeType;
            switch (test.type) {
                case "OWN_TESTS":
                    practiceType = "test";
                    break;
                case "SHARED_TESTS":
                    practiceType = "exercise";
                    break;
                default:
                    practiceType = "practice";
            }

            dispatch(learningActions.setData({ practiceType }));

            dispatch(
                testsActions.setActivationMetaData({
                    testId: test.id,
                    cardIds: test.cardIds,
                    direction: test.direction,
                })
            );
            dispatch(prepareActions.setSubjectId(test.subjectContentId.id));
            history.push(`/prepare-test/${test.subjectContentId.id}`);
        } else {
            history.push(`tests/${test.id}`);
        }
    }, [history, test.id, test.cardIds, test.direction, test.subjectContentId, clickable, dispatch]);

    return (
        <TestListItemContainer {...rest}>
            <ForwardToDetailsSection
                onClick={goToTestDetails}
                style={{ cursor: clickable ? "pointer" : "unset" }}
            >
                <TestListItemInfo>
                    <TestListItemIcon>
                        <PhaseSixIcon
                            size="standard"
                            name={iconsByTestTypeKeys[test.type]}
                        />
                    </TestListItemIcon>
                    <TestListItemDetailsContainer>
                        <TestName>{test.name || "\u00A0"}</TestName>
                        <TotalCards>
                            <PhaseSixIcon
                                size="small"
                                name="documents"
                            />
                            <span>
                                {test.totalCards}{" "}
                                <T
                                    _str="cards"
                                    _tags="tests"
                                />
                            </span>
                        </TotalCards>
                    </TestListItemDetailsContainer>
                </TestListItemInfo>
                <LastResult>
                    {test.lastTestResultPercent >= 0 ? (
                        <span>{test.lastTestResultPercent}%</span>
                    ) : (
                        <NewTestFlag>
                            <T
                                _str="NEW"
                                _tags="tests,flag"
                            />
                        </NewTestFlag>
                    )}
                </LastResult>
            </ForwardToDetailsSection>
            <LastElementOfListItem>
                <Popup
                    arrow={true}
                    position={["top", "right"]}
                    content={({ closePopup }) => (
                        <DropdownActions
                            renderActions={() => (
                                <>
                                    <DropdownActionsItem
                                        text={t("Rename", { _tags: "tests,button" })}
                                        iconName="edit"
                                        onClick={() => {
                                            onRename?.(test);
                                            closePopup();
                                        }}
                                    />
                                    <DropdownActionsItem
                                        text={t("Delete", { _tags: "tests,button" })}
                                        iconName="trash"
                                        onClick={() => {
                                            onDelete?.(test.id);
                                            closePopup();
                                        }}
                                    />
                                </>
                            )}
                        />
                    )}
                >
                    <div data-tip="">
                        <ActionsMenu name="dot-menu" />
                    </div>
                </Popup>
            </LastElementOfListItem>
        </TestListItemContainer>
    );
};
