// REACT
import React, { FunctionComponent, useEffect, useMemo, useState } from "react";
import { T } from "@transifex/react";

// COMPONENTS
import Modal from "../../../basic/modal/Modal";
import PhaseSixIcon from "../../../basic/phaseSixIcon/PhaseSixIcon";
import MatchGameScoreIcon from "../../../basic/matchGameScoreIcon/MatchGameScoreIcon";
import { Col } from "../../../basic/grid";
import { DefaultFocus } from "../../../basic/defaultFocus/DefaultFocus";

// STYLED COMPONENTS
import { ScoreTime, ItemScoreName } from "../SubjectItem/styles";
import {
    ModalHeader,
    Title,
    IconWrapper,
    ScoreWrapper,
    ModalButton,
    ButtonWrapper,
    StyledPhaseSixIcon,
} from "./styles";

// UTILS
import { getScoreByTime, getScoreTitle } from "../../../../helpers/MatchGame";
import timeFormat from "../../../../helpers/timeFormat";
import { FilledButton } from "../../../basic/button/Button";

type Props = {
    newRecord: boolean;
    time: number;
    onExit: () => void;
    onPlay: () => void;
};

const VictoryModal: FunctionComponent<Props> = (props) => {
    const { newRecord, time, onExit, onPlay } = props;
    const [showModalAndCallback, setModal] = useState<string>("");
    const [scoreTitle, setScoreTitle] = useState<string>("");
    const [scoreValue, setScoreValue] = useState<number>(0);

    const formattedTime: string = useMemo(() => {
        return timeFormat(time);
    }, [time]);

    useEffect(() => {
        const score = getScoreByTime(time);
        setScoreValue(score);
        setScoreTitle(getScoreTitle(score));
    }, [time]);

    return (
        <Modal
            active={!showModalAndCallback}
            headerComponent={() => (
                <>
                    <DefaultFocus />
                    <ModalHeader>
                        <T _str="You Win" />
                    </ModalHeader>
                </>
            )}
            afterClose={() => (showModalAndCallback === "onExit" ? onExit() : onPlay())}
            preventCloseOnBg
        >
            <Title>{newRecord ? <T _str="New record" /> : <T _str="No new record" />}</Title>
            <IconWrapper>
                <MatchGameScoreIcon
                    time={time}
                    inModal
                />
            </IconWrapper>
            <ScoreWrapper>
                <ScoreTime>
                    {formattedTime}&nbsp;
                    <PhaseSixIcon name="running-time" />
                </ScoreTime>
                <ItemScoreName score={scoreValue}>
                    <T _str={scoreTitle} />
                </ItemScoreName>
            </ScoreWrapper>
            <ButtonWrapper>
                <Col xs="auto">
                    <ModalButton
                        onClick={() => {
                            setModal("onExit");
                        }}
                        textSize="xl"
                        useBackgroundColor
                    >
                        <T _str="Exit" />
                    </ModalButton>
                </Col>
                <Col>
                    <ModalButton
                        onClick={() => {
                            setModal("onPlay");
                        }}
                        textSize="xl"
                        fullWidth
                    >
                        <T _str="Play again!" />
                    </ModalButton>
                </Col>
                <Col xs="auto">
                    <FilledButton disabled>
                        <StyledPhaseSixIcon name="share" />
                    </FilledButton>
                </Col>
            </ButtonWrapper>
        </Modal>
    );
};

export default VictoryModal;
