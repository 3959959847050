import styled from "styled-components";
import PhaseSixIcon from "../phaseSixIcon/PhaseSixIcon";

export const Container = styled.div<{ withBorder?: boolean }>`
    margin-right: ${(props) => props.theme.base.spacingSmall};
    padding: ${(props) => props.theme.base.spacingSmall};
    height: 50px;
    flex: 1;
    background-color: ${(props) => props.theme.colors.backgroundContrast};
    border-radius: 25px;
    border: ${(props) => (props.withBorder ? "1px solid " + props.theme.colors.textLight : "none")};
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    position: relative;
`;
export const TitleContainer = styled.div`
    position: absolute;
    width: calc(100% - (2 * ${(props) => props.theme.base.spacingSmall}));
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
`;
export const Label = styled.span`
    margin-right: ${(props) => props.theme.base.spacingSmall};
    color: ${(props) => props.theme.colors.text};
    white-space: nowrap;
    align-self: center;

    @media (max-width: ${process.env.REACT_APP_UPPER_GRID_STEP_BORDER}px) {
        display: none;
    }
`;
export const Selection = styled.span`
    flex-grow: 1;
    max-width: 100%; // keep space for label and icon
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    align-self: center;
    color: ${(props) => props.theme.colors.primary};
`;
export const Toggle = styled.div`
    align-self: flex-end;
    background: none repeat scroll 0 0 rgba(0, 0, 0, 0);
    color: ${(props) => props.theme.colors.textLight};
    border: medium none;
    box-shadow: none;
    display: inline;
    line-height: 1;
    cursor: pointer;
`;
export const Dropdown = styled.div`
    position: absolute;
    z-index: 1000;
    background: ${(props) => props.theme.colors.backgroundContrast};
    padding: ${(props) => props.theme.base.spacingSmall};
    border-radius: ${(props) => props.theme.base.borderRadius};
    box-shadow: 0 0 30px rgb(0 0 0 / 50%);
    top: 100%;
    margin-top: 10px;
    right: 0;
    width: 100%;
    ::after {
        content: "";
        position: absolute;
        top: -10px;
        left: 18px;
        margin: auto;
        height: 20px;
        width: 20px;
        border: 1px solid #fff;
        transform: rotate(45deg);
        background-color: #fff;
    }
`;

export const Content = styled.ul`
    list-style: none;
    max-height: 360px;
    margin: 0;
    padding: 0;
    overflow-y: auto;
    height: 25vh;
`;
export const Footer = styled.div`
    padding: ${(props) => props.theme.base.spacing} ${(props) => props.theme.base.spacingSmall};
    color: ${(props) => props.theme.colors.text};
    border-top: 1px solid ${(props) => props.theme.colors.backgroundMain};
`;
export const OptionCount = styled.span`
    color: ${(props) => props.theme.colors.text};
    padding: ${(props) => props.theme.base.spacingTiny} ${(props) => props.theme.base.spacingSmall};
    flex-grow: 1;
`;
export const Header = styled.div`
    padding-bottom: ${(props) => props.theme.base.spacingSmall};
    margin-bottom: ${(props) => props.theme.base.spacingSmall};
    border-bottom: 1px solid ${(props) => props.theme.colors.backgroundMain};
    overflow-y: auto;
`;
export const HeaderContainer = styled.div`
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    white-space: nowrap;
    align-items: center;
`;
export const HeaderRow = styled.div`
    display: flex;
    flex-direction: column;
    white-space: nowrap;
`;
export const InputRow = styled.div`
    display: flex;
    flex-direction: row;
    white-space: nowrap;
`;
export const AddEntry = styled.li`
    margin: ${(props) => props.theme.base.spacingSmall} 0;
    display: flex;
    flex-direction: row;
    white-space: nowrap;
`;
export const AddEntryName = styled.input`
    flex: 1;
    margin: 0 ${(props) => props.theme.base.spacingSmall};
    color: ${(props) =>
        props.color && props.color === "primary" ? props.theme.colors.primary : props.theme.colors.text};
    white-space: break-spaces;
`;
export const DropdownEntry = styled.li`
    display: flex;
    flex-direction: column;
    padding: ${(props) => props.theme.base.spacingSmall} 0;
`;
export const DropdownEntryContentWrapper = styled.div`
    display: flex;
    flex-direction: row;
    color: ${(props) =>
        props.color
            ? props.color === "primary"
                ? props.theme.colors.primary
                : props.color === "red"
                ? "red"
                : props.theme.colors.text
            : props.theme.colors.text};
    cursor: default;
`;
export const DropdownText = styled.span<{ deleted?: boolean; notEditable?: boolean }>`
    flex: 1;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding: ${(props) => props.theme.base.spacingTiny} ${(props) => props.theme.base.spacingSmall};
    color: ${(props) => (props.deleted ? "red" : props.notEditable ? props.theme.colors.textLight : "inherit")};
    text-decoration: ${(props) => (props.deleted ? "line-through" : "none")};
    cursor: ${(props) => (props.deleted ? "default" : props.notEditable ? "default" : "pointer")};
    margin-left: ${(props) => (props.deleted ? props.theme.base.spacingInContent : "0")};
`;
export const DropdownTextInput = styled.input`
    flex: 1;
    background-color: ${(props) => props.theme.colors.backgroundMain};
    color: ${(props) => props.theme.colors.text};
    font-size: ${(props) => props.theme.base.mediumSize};
    box-sizing: border-box;
    padding: ${(props) => props.theme.base.spacingTiny} ${(props) => props.theme.base.spacingSmall};
    border: none;
    border-radius: ${(props) => props.theme.base.borderRadius};

    &:focus {
        outline: none;
    }
`;
export const InvalidInputMessage = styled.span`
    flex-basis: 0;
    text-align: right;
    color: ${(props) => props.theme.colors.red};
    font-size: ${(props) => props.theme.base.smallSize};
    padding-left: ${(props) => props.theme.base.spacingTiny} ${(props) => props.theme.base.spacingSmall};
`;
export const IconWrapper = styled.div<{ flexEnd?: boolean; hide?: boolean; alignCenter?: boolean }>`
    display: ${(props) => (props.hide ? "none" : "flex")};
    justify-content: ${(props) => (props.flexEnd ? "flex-end" : "flex-start")};
    align-items: ${(props) => (props.alignCenter ? "center" : "flex-start")};
    width: 25px;
`;
export const SelectedIconContainer = styled.div`
    color: ${(props) => props.theme.colors.primary};
    cursor: pointer;
`;
export const StyledPhaseSixIcon = styled(PhaseSixIcon)`
    margin-left: -5px;
    color: inherit;
`;
export const SelectableSquareDiv = styled.div<{ selected?: boolean; deleted?: string }>`
    cursor: pointer;
    height: 14px;
    width: 14px;
    border: 1.5px solid
        ${(props) =>
            props.deleted === "true" ? "red" : props.selected ? props.theme.colors.primary : props.theme.colors.text};
    border-radius: 2px;
`;
export const SelectableIconContainer = styled.div<{ selected?: boolean; deleted?: string }>`
    color: ${(props) =>
        props.deleted === "true" ? "red" : props.selected ? props.theme.colors.primary : props.theme.colors.text};
    cursor: pointer;
`;
export const InactiveIconContainer = styled.div`
    color: ${(props) => props.theme.colors.textLight};
    cursor: not-allowed;
`;
export const FinishButtons = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    padding: ${(props) => props.theme.base.spacingSmall} 0 0 0;
`;
export const CancelButton = styled.span`
    padding: 2px ${(props) => props.theme.base.spacingSmall};
    color: ${(props) => props.theme.colors.primary};
    cursor: pointer;

    border-width: 1px;
    border-style: solid;
    border-radius: ${(props) => props.theme.base.borderRadiusSmall};
    border-color: transparent;

    &:hover {
        border-color: ${(props) => props.theme.colors.primary};
    }
`;
export const SaveButton = styled.button`
    margin-left: ${(props) => props.theme.base.spacingSmall};
    padding: 2px ${(props) => props.theme.base.spacingSmall};
    color: ${(props) => props.theme.colors.backgroundContrast};
    font: inherit;
    border: none;
    background-color: ${(props) => props.theme.colors.primary};
    border-radius: ${(props) => props.theme.base.borderRadiusSmall};
    cursor: pointer;

    &:hover,
    &:disabled {
        opacity: 0.8;
    }
`;
export const InvalidInputWarning = styled.span`
    flex-basis: 0;
    text-align: right;
    color: ${(props) => props.theme.colors.red};
    font-size: ${(props) => props.theme.base.smallSize};
    padding-left: ${(props) => props.theme.base.spacingTiny} ${(props) => props.theme.base.spacingSmall};
`;
export const DeleteWarning = styled.p`
    margin: 0;
    font-size: ${({ theme: { base } }) => base.size};
    color: #d2002c;
`;
