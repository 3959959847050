import {InfoImage, InfoImageContainer, InformationBox, InfoText, InfoTextContainer} from "../styles";
import {Limit, LimitLabel} from "./styles";
import ReportTTHSettingView from "../../../../components/connected/reportTTHSetting";
import React from "react";
import {useT} from "@transifex/react";

export interface ActivityInfoBoxProps {
    isOwnSubject: boolean;
    loadData: () => void;
}

const ActivityInfoBox: React.FC<ActivityInfoBoxProps> = ({isOwnSubject, loadData}) => {
    let image = require("../../../../assets/img/avatar/monster_basic.png");

    const t = useT();
    const daily_limit_t = t("Daily card limit", { _tags: "report,label" });
    const description_t = t(
        "This report shows how many cards have been due on the particular day and how many cards have been learned.",
        {
            _tags: "report,description",
        }
    );

    return (
        <InformationBox>
            <InfoImageContainer>
                <InfoImage src={image} />
            </InfoImageContainer>
            <InfoTextContainer>
                <InfoText>{description_t}</InfoText>
            </InfoTextContainer>
            <Limit>
                {isOwnSubject && (
                    <>
                        <LimitLabel>{daily_limit_t}</LimitLabel>
                        <ReportTTHSettingView onUpdate={loadData} />
                    </>
                )}
            </Limit>
        </InformationBox>
    );
};

export default ActivityInfoBox;
