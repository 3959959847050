import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../store/store";

interface PrepareState {
    subjectId: string;
    step: number;
}

const initialState: PrepareState = {
    subjectId: "",
    step: 1,
};

export const prepareSlice = createSlice({
    name: "activation",
    initialState,
    reducers: {
        setSubjectId: (state, action: PayloadAction<string>) => {
            state.subjectId = action.payload;
        },
        setStep: (state, action: PayloadAction<number>) => {
            state.step = action.payload;
        },
    },
});

/* EXPORTS */
export const { actions, reducer } = prepareSlice;

export const selectors = {
    subjectId: (state: RootState) => state.prepare.subjectId,
    step: (state: RootState) => state.prepare.step,
};
