// LIBRARIES
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

// REDUX
import { actions as responseActions, selectors as responseSelector } from "../../../redux/response/responseSlice";

//UTILS
import { useT } from "@transifex/react";
import { RESPONSE_TIMEOUT } from "../../../constants/ResponseConstants";

// COMPONENTS
import Response from "./Response";

export enum ResponseMessageKeys {
    ERROR_CREATING_SUBJECT,
    ERROR_UPDATING_SUBJECT,
    ERROR_DELETING_SUBJECT,
    ERROR_CREATING_UNIT,
    ERROR_UPDATING_UNIT,
    ERROR_DELETING_UNIT,
    ERROR_DELETING_CARD,
    ERROR_NOTIFICATIONS,
    // userSaga
    LOGIN_HAS_TIMED_OUT,
    CONFIRM_EMAIL_CHANGE,
    ERROR_REQUESTING_EMAIL_CHANGE,
    CONFIRMATION_EMAIL_HAS_BEEN_RESENT,
    ERROR_REQUESTING_NEW_CONFIRMATION_EMAIL,
    NEW_PASSWORD_HAS_BEEN_SENT,
    ERROR_SENDING_NEW_PASSWORD,
    ERROR_SAVING_NEW_PREFERENCES,
    // loginSaga
    WRONG_USER_OR_PASSWORD,
    LOGIN_FAILED,
    // subjectsSaga
    COULD_NOT_LOAD_SUBJECTS_FAMILY,
    COULD_NOT_LOAD_SUBJECTS_USER,
    COULD_NOT_LOAD_SUBJECTS_LIBRARY,
    UNIT_DELETED,
    UNIT_CREATED,
    UNIT_UPDATED,
    SUBJECT_DELETED,
    SUBJECT_CREATED,
    SUBJECT_UPDATED,
    // addSaga
    CARD_SAVED,
    CARD_UPDATED,
    CARD_DELETED,
    SAVING_FAILED,
    SAVING_FAILED_VIA_CONTENT_DUPLICATION,
    TO_SAVE_SELECT_SUBJECT_UNIT,
    TO_SAVE_QUESTION_ANSWER_REQUIRED,
    // learningSaga
    SESSION_MEMORIZED_FINISHED,
    WRONG_ANSWER,
    FEEDBACK_SENT,
    FEEDBACK_EMPTY,
    NOTE_SAVED,
    // warningsSaga
    WARNING_HONESTY,
    WARNING_QUICK_LEARNING,
    WARNING_COUNTING_STOPPED,
    WARNING_NO_POINTS_ANYMORE,
    ANNOTATION_VALIDATION_ERROR,
    // test&exercises
    SHARED_TEST_CONSUMPTION_CANCELED,
    SHARED_TEST_DOESNT_EXIST,
    SHARED_TEST_STRICT_SUBJECT_LIMIT_REACHED,
}

const ResponseView: React.FC = () => {
    const response = useSelector(responseSelector.response);
    const dispatch = useDispatch();
    const t = useT();

    const cancelResponse = () => {
        dispatch(responseActions.cancelResponse());
    };

    const errorCreatingSubject_t = t("Error creating subject");
    const errorUpdatingSubject_t = t("Error updating subject");
    const errorDeletingSubject_t = t("Error deleting subject");
    const errorCreatingUnit_t = t("Error creating unit");
    const errorUpdatingUnit_t = t("Error updating unit");
    const errorDeletingUnit_t = t("Error deleting unit");
    const errorDeletingCard_t = t("Error deleting card");
    const errorNotifications_t = t("Notifications Error");
    // userSaga
    const yourLoginHasTimedOut_t = t("Your login has timed out.");
    const confirmEmailChange_t = t("Please check your emails and confirm.");
    const errorRequestingEmailChange_t = t("Error requesting email change.");
    const confirmationEmailHasBeenResent_t = t("The confirmation email has been resent.");
    const errorRequestingConfirmationEmail_t = t("Error requesting new confirmation email.");
    const yourPasswordHasBeenResent_t = t("Your password has been resent.");
    const errorSendingNewPassword_t = t("An error occurred in resending password.");
    const errorSavingNewPreferences_t = t("An error occurred while saving your preferences.");
    // loginSaga
    const wrongUserOrPassword_t = t("User or password are wrong.");
    const loginFailed_t = t("Login failed.");
    // subjectsSaga
    const errorLoadingFamilySubjects_t = t("Could not load family subjects");
    const errorLoadingUserSubjects_t = t("Could not load user subjects");
    const errorLoadingLibrarySubjects_t = t("Could not load library subjects");
    // addSaga
    const cardSaved_t = t("Card saved");
    const cardUpdated_t = t("Card updated");
    const cardDeleted_t = t("Card deleted");
    const savingFailed_t = t("Saving failed");
    const savingFailedViaContentDuplication_t = t("Card was not saved because it was a duplicate");
    const toSaveSelectSubjectUnit_t = t("To save the card you need to select subject and unit");
    const toSaveQuestionAnswerRequired_t = t("To save the card question and answer are required");
    // learningSaga
    const memorizeSessionFinished_t = t(
        "Congratulations, you have finished memorize session! Now practice those memorized cards."
    );
    const wrongAnswer_t = t("Wrong Answer");
    const feedbackSent_t = t("Feedback sent");
    const feedbackEmpty_t = t("Feedback is empty");
    const noteWasSaved_t = t("Your note has been saved!");
    // warningsSaga
    const warningHonesty_t = t(
        "We notice that you have everything right in practice. phase6 only works well if you are honest in the assessment."
    );
    const warningQuickLearning_t = t(
        "You continue to learn very quickly and get almost everything right. This way you collect a lot of points very quickly. Maybe you enjoy it more this way?"
    );
    const warningCountingStopped_t = t(
        "It's your data and progress and you can use it as you like, of course. To keep the comparability with the other learners it is important for us that we have only learners in the leaderboards who learn correctly. You can continue to learn as you do, but we will soon stop counting your points for this week."
    );

    //tests & exercises
    const sharedTestConsumptionCanceled_t = t(
        "You can always practice later by clicking again on the link that was shared with you."
    );
    const sharedTestDoesntExist_t = t("This exercise doesn’t exist, it probably was deleted by your teacher.");
    const strictModeSubjectLimitReached_t = t(
        "Limit reached. No more vocabulary collections selectable. Please contact your school / institution"
    );

    let text = response.text;
    let name = "";
    // if called from saga select from setup translations
    if (response.responseMessage !== undefined) {
        switch (response.responseMessage) {
            case ResponseMessageKeys.ERROR_CREATING_SUBJECT:
                text = [errorCreatingSubject_t];
                break;
            case ResponseMessageKeys.ERROR_UPDATING_SUBJECT:
                text = [errorUpdatingSubject_t];
                break;
            case ResponseMessageKeys.ERROR_DELETING_SUBJECT:
                text = [errorDeletingSubject_t];
                break;
            case ResponseMessageKeys.ERROR_CREATING_UNIT:
                text = [errorCreatingUnit_t];
                break;
            case ResponseMessageKeys.ERROR_UPDATING_UNIT:
                text = [errorUpdatingUnit_t];
                break;
            case ResponseMessageKeys.ERROR_DELETING_UNIT:
                text = [errorDeletingUnit_t];
                break;
            case ResponseMessageKeys.ERROR_DELETING_CARD:
                text = [errorDeletingCard_t];
                break;
            case ResponseMessageKeys.ERROR_NOTIFICATIONS:
                text = [errorNotifications_t];
                break;
            // userSaga
            case ResponseMessageKeys.LOGIN_HAS_TIMED_OUT:
                text = [yourLoginHasTimedOut_t];
                break;
            case ResponseMessageKeys.CONFIRM_EMAIL_CHANGE:
                text = [confirmEmailChange_t];
                break;
            case ResponseMessageKeys.ERROR_REQUESTING_EMAIL_CHANGE:
                text = [errorRequestingEmailChange_t];
                break;
            case ResponseMessageKeys.CONFIRMATION_EMAIL_HAS_BEEN_RESENT:
                text = [confirmationEmailHasBeenResent_t, confirmEmailChange_t];
                break;
            case ResponseMessageKeys.ERROR_REQUESTING_NEW_CONFIRMATION_EMAIL:
                text = [errorRequestingConfirmationEmail_t];
                break;
            case ResponseMessageKeys.NEW_PASSWORD_HAS_BEEN_SENT:
                text = [yourPasswordHasBeenResent_t];
                break;
            case ResponseMessageKeys.ERROR_SENDING_NEW_PASSWORD:
                text = [errorSendingNewPassword_t];
                break;
            case ResponseMessageKeys.ERROR_SAVING_NEW_PREFERENCES:
                text = [errorSavingNewPreferences_t];
                break;
            // loginSaga
            case ResponseMessageKeys.WRONG_USER_OR_PASSWORD:
                text = [wrongUserOrPassword_t];
                break;
            case ResponseMessageKeys.LOGIN_FAILED:
                text = [loginFailed_t];
                break;
            // subjectsSaga
            case ResponseMessageKeys.COULD_NOT_LOAD_SUBJECTS_FAMILY:
                text = [errorLoadingFamilySubjects_t];
                break;
            case ResponseMessageKeys.COULD_NOT_LOAD_SUBJECTS_USER:
                text = [errorLoadingUserSubjects_t];
                break;
            case ResponseMessageKeys.COULD_NOT_LOAD_SUBJECTS_LIBRARY:
                text = [errorLoadingLibrarySubjects_t];
                break;
            case ResponseMessageKeys.UNIT_DELETED:
                name = response.responseMessageReplacements?.[0]["name"] ?? "";
                text = [t('Unit "{name}" deleted', { name })];
                break;
            case ResponseMessageKeys.UNIT_CREATED:
                name = response.responseMessageReplacements?.[0]["name"] ?? "";
                text = [t('Unit "{name}" created', { name })];
                break;
            case ResponseMessageKeys.UNIT_UPDATED:
                name = response.responseMessageReplacements?.[0]["name"] ?? "";
                text = [t('Unit "{name}" updated', { name })];
                break;
            case ResponseMessageKeys.SUBJECT_DELETED:
                name = response.responseMessageReplacements?.[0]["name"] ?? "";
                text = [t('Subject "{name}" deleted', { name })];
                break;
            case ResponseMessageKeys.SUBJECT_CREATED:
                name = response.responseMessageReplacements?.[0]["name"] ?? "";
                text = [t('Subject "{name}" created', { name })];
                break;
            case ResponseMessageKeys.SUBJECT_UPDATED:
                name = response.responseMessageReplacements?.[0]["name"] ?? "";
                text = [t('Subject "{name}" updated', { name })];
                break;
            // addSaga
            case ResponseMessageKeys.CARD_SAVED:
                text = [cardSaved_t];
                break;
            case ResponseMessageKeys.CARD_UPDATED:
                text = [cardUpdated_t];
                break;
            case ResponseMessageKeys.CARD_DELETED:
                text = [cardDeleted_t];
                break;
            case ResponseMessageKeys.SAVING_FAILED:
                text = [savingFailed_t];
                break;
            case ResponseMessageKeys.SAVING_FAILED_VIA_CONTENT_DUPLICATION:
                text = [savingFailedViaContentDuplication_t];
                break;
            case ResponseMessageKeys.TO_SAVE_SELECT_SUBJECT_UNIT:
                text = [toSaveSelectSubjectUnit_t];
                break;
            case ResponseMessageKeys.TO_SAVE_QUESTION_ANSWER_REQUIRED:
                text = [toSaveQuestionAnswerRequired_t];
                break;
            // learningSaga
            case ResponseMessageKeys.SESSION_MEMORIZED_FINISHED:
                text = [memorizeSessionFinished_t];
                break;
            case ResponseMessageKeys.WRONG_ANSWER:
                text = [wrongAnswer_t];
                break;
            case ResponseMessageKeys.FEEDBACK_SENT:
                text = [feedbackSent_t];
                break;
            case ResponseMessageKeys.FEEDBACK_EMPTY:
                text = [feedbackEmpty_t];
                break;
            case ResponseMessageKeys.NOTE_SAVED:
                text = [noteWasSaved_t];
                break;
            // warningsSaga
            case ResponseMessageKeys.WARNING_HONESTY:
                text = [warningHonesty_t];
                break;
            case ResponseMessageKeys.WARNING_QUICK_LEARNING:
                text = [warningQuickLearning_t];
                break;
            case ResponseMessageKeys.WARNING_COUNTING_STOPPED:
                text = [warningCountingStopped_t];
                break;
            case ResponseMessageKeys.WARNING_NO_POINTS_ANYMORE:
                const points = response.responseMessageReplacements?.[0]["points"] ?? "...";
                text = [
                    t(
                        "You have received {points} points this week. You can get more points next week. Of course, you can continue learning without points.",
                        { points }
                    ),
                ];
                break;
            case ResponseMessageKeys.ANNOTATION_VALIDATION_ERROR:
                text = [
                    t("Your annotation text is too close to correct one"),
                    t("Please choose another annotation text"),
                ];
                break;
            case ResponseMessageKeys.SHARED_TEST_CONSUMPTION_CANCELED:
                text = [sharedTestConsumptionCanceled_t];
                break;
            case ResponseMessageKeys.SHARED_TEST_DOESNT_EXIST:
                text = [sharedTestDoesntExist_t];
                break;
            case ResponseMessageKeys.SHARED_TEST_STRICT_SUBJECT_LIMIT_REACHED:
                text = [strictModeSubjectLimitReached_t];
                break;
            default:
                break;
        }
    }

    useEffect(() => {
        if (response.show) {
            setTimeout(() => {
                cancelResponse();
            }, RESPONSE_TIMEOUT);
        }
    }, [response.show, cancelResponse]);

    return (
        <Response
            show={response.show}
            text={text}
            responseType={response.type}
            cancelResponse={cancelResponse}
        />
    );
};

export default ResponseView;
