import { SubjectMetadata } from "p6m-subjects";
import dayjs from "dayjs";

export type PublisherSubjectStatus =
    | "NEW"
    | "PURCHASED"
    | "ACTIVE_7D_TRIAL"
    | "EXPIRED_7D_TRIAL"
    | "ACTIVE_30D_TRIAL"
    | "EXPIRED_30D_TRIAL";

export const getPublisherSubjectStatus = (subjectMetaData?: SubjectMetadata): PublisherSubjectStatus => {
    if (!subjectMetaData) {
        return "NEW";
    } else {
        const isPurchased = !subjectMetaData.expirationDate;

        if (isPurchased) {
            return "PURCHASED";
        } else {
            const today = dayjs();
            const expirationDate = dayjs(subjectMetaData.expirationDate);
            const purchaseDate = dayjs(subjectMetaData.purchasedDate);
            const diff = expirationDate.diff(purchaseDate, "days");
            const isExpired = today.isAfter(expirationDate, "days");

            if (isExpired) {
                // The length of the trial period may not be very precise. Example: 6.999998611111111 (days)
                if (diff <= 7) {
                    return "EXPIRED_7D_TRIAL";
                } else {
                    return "EXPIRED_30D_TRIAL";
                }
            } else {
                if (diff <= 7) {
                    return "ACTIVE_7D_TRIAL";
                } else {
                    return "ACTIVE_30D_TRIAL";
                }
            }
        }
    }
};
