import styled from "styled-components";

// info boxes that are shown on most tabs
export const InformationBox = styled.div`
    margin-top: calc(25px + ${(props) => props.theme.base.spacing});
    margin-right: calc(25px + ${(props) => props.theme.base.spacing});
    padding: ${(props) => props.theme.base.spacing};
    border-radius: ${(props) => props.theme.base.borderRadiusBig};
    border: 1px solid ${(props) => props.theme.colors.textLight};
    background: ${(props) => props.theme.colors.backgroundContrast};
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
`;
export const InfoImageContainer = styled.div`
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
`;
export const InfoImage = styled.img`
    height: auto;
    width: 230px;
`;
export const InfoTextContainer = styled.div`
    flex: 2;
    display: flex;
    align-items: center;
`;
export const InfoText = styled.p`
    text-align: center;
    font-size: ${(props) => props.theme.base.bigSize};
    color: ${(props) => props.theme.colors.text};
`;
