import styled from "styled-components";
import { ReactComponent as SidneyCheck } from "../../assets/img/avatar/p6_monster_check.svg";

export const Background = styled.div`
    background-color: ${(props) => props.theme.colors.backgroundMain};
    display: flex;
    flex-grow: 1;
    align-self: stretch;
    justify-self: stretch;
`;
export const Overlay = styled.div`
    display: flex;
    align-self: stretch;
    justify-self: stretch;
    flex-grow: 1;
    justify-content: center;
    align-items: center;
`;
export const LoginContainer = styled.div`
    min-width: 425px;
    width: 425px;
    padding: 30px 35px;
    background: ${(props) => props.theme.colors.backgroundContrast};
    border-radius: 0.5rem;
    border-spacing: 0;
    text-align: center;
    @media (max-width: 767px) {
        min-width: unset;
        width: calc(100% - 100px); /* - 2*15px margin - 2*35px padding */
        margin: 0 15px;
    }
`;
export const LinkContainer = styled.div`
    width: 100%;
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
`;
export const Message = styled.span`
    color: ${(props) => props.theme.colors.red};
`;
export const AgreementLink = styled.span`
    color: ${(props) => props.theme.colors.red};
    text-decoration: underline;
    cursor: pointer;
`;
export const StyledSidneyCheck = styled(SidneyCheck)`
    width: 95%;
    margin-bottom: 20px;
`;
