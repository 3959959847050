import styled from "styled-components";

export const GameCard = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding: 0.5rem;
    color: ${({ theme: { colors } }) => colors.text};
    background-color: ${({ theme: { colors } }) => colors.primary + "80"};
    transition: 0.3s;
    cursor: default;
    border-radius: ${({ theme: { base } }) => base.borderRadiusBig};
`;

export const CardText = styled.div`
    width: 100%;
    max-height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
`;

export const GameItemWrapper = styled.div<{
    question?: boolean;
    animations?: ("dragstart" | "error" | "done" | "selected")[];
}>`
    width: 100%;
    height: 120px;

    @media (max-width: 767px) {
        height: 80px;
    }

    ${({ question = false, theme: { colors } }) =>
        question
            ? `
        ${GameCard} {
            background-color: ${colors.backgroundContrast + "cc"};
        }
    `
            : ""}

    ${({ animations = [], theme: { colors } }) => {
        const animationStyles = {
            selected: `
                background-color: ${colors.green + "80"};
            `,
            dragstart: `
                opacity: 0.7;
            `,
            error: `
                background-color: ${colors.red + "80"};
            `,
            done: `
                opacity: 0;
            `,
        };
        return animations
            .map((animation) => animationStyles[animation])
            .map(
                (style) => `${GameCard} {
                ${style}
            }`
            )
            .join("");
    }}
`;
