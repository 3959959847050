// DEPS
import React, { FunctionComponent, useState } from "react";
import { T } from "@transifex/react";

// COMPONENTS
import Modal from "../../../basic/modal/Modal";
import PhaseSixIcon from "../../../basic/phaseSixIcon/PhaseSixIcon";
import { Col } from "../../../basic/grid";
import { DefaultFocus } from "../../../basic/defaultFocus/DefaultFocus";

// STYLED COMPONENTS
import { ItemScoreName } from "../SubjectItem/styles";
import { ModalHeader, IconWrapper, ScoreWrapper, ModalButton, StyledPhaseSixIcon, ButtonWrapper } from "./styles";
import { FilledButton } from "../../../basic/button/Button";

type Props = {
    onExit: () => void;
    onPlay: () => void;
};

const LoseModal: FunctionComponent<Props> = (props) => {
    const { onExit, onPlay } = props;
    const [showModalAndCallback, setModal] = useState<string>("");

    return (
        <Modal
            active={!showModalAndCallback}
            headerComponent={() => (
                <>
                    <DefaultFocus />
                    <ModalHeader>
                        <T _str="You Lose" />
                    </ModalHeader>
                </>
            )}
            afterClose={() => (showModalAndCallback === "onExit" ? onExit() : onPlay())}
            preventCloseOnBg
        >
            <IconWrapper>
                <PhaseSixIcon name={"clock"} />
            </IconWrapper>
            <ScoreWrapper>
                <ItemScoreName score={0}>
                    <T _str="Game Over!" />
                </ItemScoreName>
            </ScoreWrapper>
            <ButtonWrapper>
                <Col xs="auto">
                    <ModalButton
                        textSize="xl"
                        onClick={() => {
                            setModal("onExit");
                        }}
                        useBackgroundColor
                    >
                        <T _str="Exit" />
                    </ModalButton>
                </Col>
                <Col>
                    <ModalButton
                        textSize="xl"
                        fullWidth
                        onClick={() => {
                            setModal("onPlay");
                        }}
                    >
                        <T _str="Play again!" />
                    </ModalButton>
                </Col>
                <Col xs="auto">
                    <FilledButton disabled>
                        <StyledPhaseSixIcon name="share" />
                    </FilledButton>
                </Col>
            </ButtonWrapper>
        </Modal>
    );
};

export default LoseModal;
