// LIBRARIES
import React, { useCallback, useEffect, useState, useRef } from "react";

import { getDataProtectionUrl } from "../../../helpers/Url";
import { actions as modalActions } from "../../../redux/modal/modalSlice";
import { useDispatch } from "react-redux";
import { GlobalModalView } from "../../../helpers/Modal";

import Component from "./DataProtectionModal";

type Props = {
    url?: string;
};

const DataProtectionModal: React.FC<Props> = (props) => {
    const { url = getDataProtectionUrl() } = props;
    const iframeElement = useRef<HTMLIFrameElement>();
    const [isIframeLoading, setIframeLoading] = useState(true);

    const dispatch = useDispatch();

    useEffect(() => {
        if (!iframeElement.current) return;
        const iframe = iframeElement.current;

        const onLoad: EventListener = () => {
            setIframeLoading(false);
        }

        iframe.addEventListener('load', onLoad);

        return () => {
            iframe.removeEventListener('load', onLoad);
        };
    }, [iframeElement]);

    const close = useCallback(() => {
        dispatch(modalActions.setModalView(GlobalModalView.None));
    }, [dispatch]);

    return (
        <Component
            ref={(refElement) => (iframeElement.current = refElement || undefined)}
            url={url}
            isIframeLoading={isIframeLoading}
            close={close}
        />
    );
};

export default DataProtectionModal;
