import { ExtendedOption } from "p6m-viewData";

export function checkMinValue(value: string, target: number = 1): boolean {
    return value.length < target;
}

export function checkMaxValue(value: string, target: number = 256): boolean {
    return value.length > target;
}

export function checkUniqValue(value: string, target: string[]): boolean {
    return !!target.find((search: string) => value === search);
}

export enum InputValidationResultType {
    Valid,
    Empty,
    Duplicate,
}

export function validateInput(index: number, name: string, options: ExtendedOption[]): InputValidationResultType {
    if (name.trim() === "") return InputValidationResultType.Empty;
    for (let i = 0; i < options.length; i++) {
        if (i === index) continue;
        if (options[i].title.toLowerCase() === name.trim().toLowerCase()) return InputValidationResultType.Duplicate;
    }
    return InputValidationResultType.Valid;
}

export function checkInputDuplication(index: number, options: ExtendedOption[]): number[] {
    const duplicatesIndices: number[] = [];
    for (let i = 0; i < options.length; i++) {
        if (i === index || options[i].validationResult === InputValidationResultType.Empty) continue;
        if (options[i].title.toLowerCase() === options[index].title.toLowerCase()) {
            duplicatesIndices.push(i);
        }
    }
    return duplicatesIndices;
}

// returns all indexes with duplicate values
// export function getSameValues (values: string[]): number[] {
//     return values
//         .map((value, i) => !checkUniqValue(value, values.slice(i, -1)) || i)
//         .filter((value) => value !== true) as number[];
// }
