// REACT
import React, {
    FunctionComponent,
    PropsWithChildren,
    HTMLAttributes,
    useState,
    useEffect,
    ReactNode,
    Children,
} from "react";

// STYLED COMPONENTS
import { Wrapper, ChildWrapper, TPosition, MenuWrapper } from "./styles";

export type Props = {
    isOpen: boolean;
    menu: ReactNode;
    position?: TPosition;
    childWrapperProps?: HTMLAttributes<HTMLDivElement>;
    menuWrapperProps?: HTMLAttributes<HTMLDivElement>;
} & HTMLAttributes<HTMLDivElement>;

const SpecialCharSelect: FunctionComponent<PropsWithChildren<Props>> = (props) => {
    const {
        isOpen,
        menu,
        children,
        position = ["bottom", "center"],
        childWrapperProps = {},
        menuWrapperProps = {},
        ...restProps
    } = props;
    const [isRendered, setRender] = useState(isOpen);

    useEffect(() => {
        if (isOpen) return setRender(true);
        setTimeout(() => setRender(false), 300);
    }, [isOpen]);

    return (
        <Wrapper {...restProps}>
            <ChildWrapper {...childWrapperProps}>{Children.only(children)}</ChildWrapper>
            {isRendered && (
                <MenuWrapper
                    {...menuWrapperProps}
                    position={position}
                    visible={isOpen}
                >
                    {menu}
                </MenuWrapper>
            )}
        </Wrapper>
    );
};

export default SpecialCharSelect;
