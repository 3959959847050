import axios, { AxiosPromise } from "axios";
import { IResponse } from "p6m-response";

export function synchronize(): AxiosPromise<IResponse<any>> {
    const data = {
        queryMode: "GET_CURRENT_REVISION_ONLY",
    };
    return axios.post("/synchronization", data);
}

export function synchronizeAsync(revision: string): AxiosPromise<IResponse<any>> {
    const data = {
        queryMode: "ONLY_GET_OTHER_CLIENT_CHANGES",
        revisionFrom: revision,
        timeoutMilliseconds: 60000,
    };
    return axios.post("/synchronization-async", data);
}
