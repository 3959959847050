// LIBRARIES
import styled from "styled-components";
import React, { useContext, useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { push as Menu } from "react-burger-menu";

// COMPONENTS
import MaintenancePage from "../../components/basic/maintenancePage/MaintenancePage";
import TopNavigation from "../../components/complex/topNavigation/TopNavigation";
import SideNavigation from "../../components/connected/sideNavigation/";
import { ThemeContext } from "styled-components";
import UserMenu from "../../components/connected/userMenu/";
import { useLocation } from "react-router-dom";

// REDUX
import { useDispatch, useSelector } from "react-redux";
import { selectors as appStatusSelectors } from "../../redux/appStatus/appStatusSlice";
import { actions as userActions } from "../../redux/user/userSlice";

const FullScreen = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    align-self: stretch;
    justify-self: stretch;
    height: 100%;
    overflow: auto;
`;

const Content = styled.div`
    position: relative;
    display: flex;
    flex: 1;
    max-width: 100vw;
    align-self: stretch;
    flex-direction: row;
    justify-content: flex-start;
    align-items: stretch;
`;

const Scroll = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-self: stretch;
    justify-content: stretch;
    align-items: stretch;
    flex-grow: 1;
    max-width: calc(100% - 120px);
    overflow-y: auto;
`;

const InnerContent = styled.div`
    flex: 1 1 auto;
    height: 0;
`;

const MobileContent = styled.div`
    flex: 1 1 auto;
    overflow-y: auto;
    height: 0;
`;

const MobileScroll = styled.div`
    position: relative;
    display: flex;
    flex: 1;
    margin-left: 10px;
    align-self: stretch;
    justify-self: stretch;
    flex-direction: column;
    justify-content: stretch;
    align-items: stretch;
    overflow-y: auto;
`;

const MobileTopNavigationContainer = styled.div`
    width: 100%;
    height: 100px;
    display: flex;

    > div:first-child {
        width: 60px;
    }

    > div:last-child {
        width: calc(100% - 60px);
    }
`;

let STYLES = {
    bmBurgerButton: {
        position: "fixed",
        width: "36px",
        height: "30px",
        left: "20px",
        top: "36px",
        zIndex: "2",
    },
    bmBurgerBars: {
        background: "#373a47",
    },
    bmBurgerBarsHover: {
        background: "#a90000",
    },
    bmCrossButton: {
        height: "24px",
        width: "24px",
    },
    bmCross: {
        background: "#bdc3c7",
    },
    bmMenuWrap: {
        position: "fixed",
        height: "100%",
        boxSizing: "border-box",
    },
    bmMenu: {
        background: "",
        padding: "1.5em 0.5em 0",
        fontSize: "1.15em",
        boxSizing: "border-box",
    },
    bmMorphShape: {
        fill: "#373a47",
    },
    bmItemList: {
        color: "#b8b7ad",
        padding: "0.8em",
        boxSizing: "border-box",
    },
    bmItem: {
        display: "inline-block",
        boxSizing: "border-box",
    },
    bmOverlay: {
        background: "rgba(0, 0, 0, 0.3)",
    },
};

const ContentWithNav: React.FunctionComponent<any> = ({ children, showSmallDate = false }) => {
    const dispatch = useDispatch();
    const showMaintenancePage: boolean = useSelector(appStatusSelectors.showMaintenancePage);
    const themeContext = useContext(ThemeContext);
    const location = useLocation();
    const [currentLocation, setCurrentLocation] = useState(""); // initialize empty to call jossoUpdate in useEffect on creation. Reset to location.pathname when background updates provide premium role faster

    useEffect(() => {
        if (location.pathname !== currentLocation) {
            // && showMaintenancePage
            // always dispatch jossoUpdate for now, because user having bought premium is not updated correctly/fast enough via background synchronisation
            // with condition "showMaintenancePage &&" reinstated it will continue checking connection on path change via interceptor, because showing maintenance replaces components and their data requests
            dispatch(userActions.jossoUpdate());
        }
        setCurrentLocation(location.pathname);
    }, [location]);

    let styles = STYLES;
    styles.bmMenu.background = themeContext.colors.backgroundContrast;

    const [menuIsShown, setMenuIsShown] = useState(false);

    const lowerBorderQuery = "(max-width: " + process.env.REACT_APP_LOWER_GRID_STEP_BORDER + "px)";
    let isMobileView: Boolean = useMediaQuery({
        query: lowerBorderQuery,
    });

    if (isMobileView) {
        return (
            <FullScreen>
                <MobileTopNavigationContainer id="mobile-top-navigation-container">
                    <Menu
                        outerContainerId={"root"}
                        pageWrapId={"main-content"}
                        styles={styles}
                        disableAutoFocus
                        isOpen={menuIsShown}
                        onStateChange={(state) => {
                            setMenuIsShown(state.isOpen);
                        }}
                    >
                        <UserMenu
                            isMobileView={true}
                            closeMobileMenu={() => {
                                setMenuIsShown(false);
                            }}
                        />
                        <SideNavigation
                            closeMenu={() => {
                                setMenuIsShown(false);
                            }}
                        />
                    </Menu>
                    <TopNavigation isMobileView={true} />
                </MobileTopNavigationContainer>
                <MobileScroll>
                    {showMaintenancePage ? (
                        <MaintenancePage />
                    ) : (
                        <MobileContent id="main-content">{children}</MobileContent>
                    )}
                </MobileScroll>
            </FullScreen>
        );
    }
    return (
        <FullScreen>
            <TopNavigation showSmallDate={showSmallDate} />
            <Content>
                <SideNavigation />
                <Scroll>{showMaintenancePage ? <MaintenancePage /> : <InnerContent>{children}</InnerContent>}</Scroll>
            </Content>
        </FullScreen>
    );
};

export default ContentWithNav;
