import axios from "axios";

export const upload = (files: File[], onUploadProgress?: (event: any) => void) => {
    let formData = new FormData();

    files.forEach((file: File) => formData.append("file[]", file));

    return axios.post("/media", formData, {
        headers: {
            "Content-Type": "multipart/form-data",
        },
        onUploadProgress,
    });
};

export const getFiles = async (ids: string[]): Promise<File[]> => {
    let getFiles: Promise<File>[] = ids.map((id) => {
        return new Promise(async (resolve) => {
            let response = await axios.get("/media/" + id);
            console.log(response);
            // resolve(response.data?.replyContent?.file);
        });
    });
    return await Promise.all(getFiles);
};
