import styled, { css } from "styled-components";
import { Col, Row } from "../../../basic/grid";
import { CardContent } from "../CardContent/styles";
import { SpecialCharSelect } from "../../../basic/specialCharSelect";
import PhaseSixIcon from "../../../basic/phaseSixIcon/PhaseSixIcon";
import {
    ButtonColors,
    focusStyles,
    getButtonColor,
    getButtonTextColor,
    hoverStyles,
} from "../../../basic/button/Button";

export const Textarea = styled.textarea`
    position: relative;
    z-index: 1;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: ${({ theme: { base } }) => base.spacing};
    background-color: ${({ theme: { colors } }) => `${colors.backgroundMain}80`};
    border: 1px solid ${({ theme: { colors } }) => colors.backgroundMain};
    border-radius: ${({ theme: { base } }) => base.borderRadiusBig};
    box-shadow: inset 0 2px 8px ${({ theme: { colors } }) => colors.shadow};
    text-align: center;
    font-size: ${({ theme: { base } }) => base.titleSize};
    font-family: ${({ theme: { base } }) => base.fontFamily};
    resize: none;
    color: ${({ theme: { colors } }) => colors.text};
    word-break: break-word;

    &:focus {
        outline: 0;
    }

    &::placeholder {
        color: ${({ theme: { colors } }) => colors.textLight};
    }
`;

export const TextareaWrapper = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
`;

export const TextareaFooter = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    left: 1px;
    bottom: 1px;
    right: 1px;
    z-index: 2;
    padding: ${({ theme: { base } }) => base.spacingSmall};
`;

export const Flag = styled.img`
    position: absolute;
    height: 24px;
    border-radius: ${({ theme: { base } }) => base.borderRadiusSmall};
    vertical-align: middle;
`;

export const CharSelect = styled(SpecialCharSelect)`
    width: 100%;
`;

export const charsMenuCss = {
    left: "-10px",
    right: "-10px",
    bottom: "-10px",
};

export const charChildCss = { textAlign: "right" };

export const CardResultWrapper = styled(CardContent)`
    padding-top: 0;
`;

export const ActionsWrapper = styled(Row)`
    margin-top: auto;
`;

export const ActionIcon = styled(PhaseSixIcon)`
    font-size: ${({ theme: { base } }) => base.iconSmall};
    color: ${({ theme: { colors } }) => colors.text};
    cursor: pointer;
    transition: opacity ${({ theme: { base } }) => base.transition}s;

    &:hover {
        opacity: ${({ theme: { base } }) => base.hoverOpacity};
    }
`;

export const Button = styled.button<{
    colorType?: ButtonColors;
    disabled?: boolean;
}>`
    width: 100%;
    padding: ${({ theme: { base } }) => base.spacingSmall};
    background: ${getButtonColor};
    border: 1px solid ${getButtonColor};
    border-radius: ${({ theme: { base } }) => base.borderRadius};
    font-size: ${({ theme: { base } }) => base.bigSize};
    color: ${getButtonTextColor};
    cursor: ${({ disabled }) => (disabled ? "default" : "pointer")};
    opacity: ${({ disabled }) => (disabled ? "0.3" : "1")};
    word-break: break-word;

    ${({ disabled }) =>
        !disabled &&
        css`
            ${focusStyles}
            ${hoverStyles}
        `}
`;

export const ResultIcon = styled(PhaseSixIcon)`
    display: block;
    margin: ${({ theme: { base } }) => base.spacingSmall} auto 0 auto;
    color: inherit;
`;

export const ResultBox = styled.div<{
    colorType?: "basic" | "primary" | "danger" | "success" | "neutral";
    state: string;
}>`
    height: 100%;
    padding: ${({ theme: { base } }) => base.spacingSmall};
    background-color: ${({ state, theme: { colors } }) =>
        ["wrong", "dontKnow"].includes(state)
            ? colors.red + "20"
            : state === "success"
            ? colors.green + "20"
            : colors.backgroundMain};
    border-radius: ${({ theme: { base } }) => base.borderRadiusBig};
    font-size: ${({ theme: { base } }) => base.bigSize};
    text-align: center;
    color: ${getButtonColor};
    margin-bottom: ${({ state, theme: { base } }) =>
        ["wrong", "dontKnow"].includes(state) ? base.spacingInContent : "0"};

    & + ${Textarea} {
        margin-top: ${({ theme: { base } }) => base.spacingSmall};
    }

    & > div {
        word-break: break-word;
    }
`;

export const ClearButton = styled.button`
    padding: 0;
    margin: 0;
    background: none;
    border: none;
    border-color: ${({ theme: { colors } }) => colors.textLight}; // todo check other themes
    border-radius: ${({ theme: { base } }) => base.borderRadius};

    ${({ disabled, theme: { base } }) =>
        disabled
            ? `
        opacity: ${base.disabledOpacity};
    `
            : focusStyles}
`;

export const ClearButtonContainer = styled(Col)`
    margin-bottom: -12px;
`;

export const OpenSettingsButton = styled.span`
    color: blue;
`;

export const ArrowsInfo = styled.div`
    padding: ${({ theme: { base } }) => base.spacingSmall};
    border-radius: ${({ theme: { base } }) => base.borderRadius};
    background-color: ${({ theme: { colors } }) => colors.backgroundMain};
    font-size: ${({ theme: { base } }) => base.mediumSize};
    text-align: center;
    color: ${({ theme: { colors } }) => colors.primary};
    word-break: break-word;

    > img {
        width: 160px;
        max-width: 100%;
    }
`;
