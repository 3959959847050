import { charsList, CharContent } from "../constants/CharsList";

function getDataGenerator<T>(keyToGenerate: keyof CharContent): (list?: typeof charsList) => { [key: string]: T } {
    return (list = charsList) => {
        return Object.entries(list).reduce((result, [key, value]) => {
            result[key] = value[keyToGenerate];
            return result;
        }, {} as any);
    };
}

export const getLangNames = getDataGenerator<string>("name");

export function getCharsByKey(key: string, list = charsList) {
    return list[key];
}
