import React from "react";
import styled, { ThemeProvider } from "styled-components";
import { useT } from "@transifex/react";
import { UserAgentType } from "../../helpers/UserAgent";
import { ReactComponent as Phase6Logo } from "../../assets/img/p6_logo.svg";
import iOSImg from "../../assets/img/browser/app-store-icon_x200.png";
import androidImg from "../../assets/img/browser/google_play_icon_x200.png";

const Background = styled.div`
    background-color: ${(props) => props.theme.colors.backgroundMain};
    display: flex;
    flex: 1;
    width: 100%;
    align-self: stretch;
    justify-self: stretch;
`;
const Overlay = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
    align-items: center;
`;
const InfoContainer = styled.div`
    min-width: 425px;
    width: 425px;
    padding: 30px 35px;
    background: ${(props) => props.theme.colors.backgroundContrast};
    border-radius: 0.5rem;
    border-spacing: 0;
    text-align: center;
    justify-content: center;
    @media (max-width: 767px) {
        min-width: unset;
        width: calc(100% - 100px); /* - 2*15px margin - 2*35px padding */
        margin: 0 15px;
    }
`;
const InfoTextContainer = styled.div`
    margin-bottom: ${(props) => props.theme.base.spacing};
`;
const InfoText = styled.p<{ highlighted?: boolean }>`
    text-align: center;
    color: ${(props) => (props.highlighted ? props.theme.colors.primary : props.theme.colors.text)};
`;
const StoreLink = styled.a``;
const ButtonImage = styled.img`
    width: 75%;
`;
const StyledPhase6Logo = styled(Phase6Logo)`
    width: 100%;
`;

const MobileAppAdvertisement = ({ os, theme }: { os: UserAgentType; theme: any }) => {
    const iOSUrl = "https://apps.apple.com/de/app/phase6-vokabeltrainer/id441493173";
    const androidUrl = "https://play.google.com/store/search?q=phase6&c=apps&gl=DE";
    const url = os === "iOS" ? iOSUrl : os === "Android" ? androidUrl : "";
    const imagePath = os === "iOS" ? iOSImg : os === "Android" ? androidImg : "";

    const t = useT();

    const device_t = t("Für ihr Gerät steht der", { _tags: "info, text" });
    const phase6_t = t("phase6 Vokabeltrainer", { _tags: "info, text" });
    const download_t = t("als optimierte App zum Download bereit", { _tags: "info, text" });

    return (
        <ThemeProvider theme={theme}>
            <Background>
                <Overlay>
                    <InfoContainer>
                        <StyledPhase6Logo title={"Phase 6 Logo"} />
                        <InfoTextContainer>
                            <InfoText>{device_t}</InfoText>
                            <InfoText highlighted>{phase6_t}</InfoText>
                            <InfoText>{download_t}</InfoText>
                        </InfoTextContainer>
                        <StoreLink href={url}>
                            <ButtonImage src={imagePath} />
                        </StoreLink>
                    </InfoContainer>
                </Overlay>
            </Background>
        </ThemeProvider>
    );
};

export default MobileAppAdvertisement;
