import React, { FunctionComponent } from "react";
import styled from "styled-components";
import Modal from "../../../basic/modal/Modal";
import { useT } from "@transifex/react";
import PhaseSixIcon from "../../../basic/phaseSixIcon/PhaseSixIcon";
import Tooltip from "../../../basic/tooltip/Tooltip";

type Props = {
    link: string;
    showModal: boolean;
    toggleModal: () => void;
};

const Container = styled.div`
    cursor: pointer;
`;
const StyledIframe = styled.iframe`
    display: block;
    box-sizing: border-box;
    width: 100%;
    height: 80vh;
    border: none;
`;

const StyledPhaseSixIcon = styled(PhaseSixIcon)`
    color: ${(props) => props.theme.colors.textOnBackground};
`;

const SurveyItem: FunctionComponent<Props> = (props) => {
    const { link, showModal, toggleModal } = props;
    const t = useT();

    const titleTranslation = t("Do you like our application?", { _tags: "survey,header" });

    return (
        <Tooltip content={titleTranslation}>
            <Container onClick={toggleModal}>
                <StyledPhaseSixIcon
                    aria-label="Survey"
                    aria-hidden
                    name="support"
                    size="medium"
                />
                {showModal && (
                    <Modal
                        active={showModal}
                        afterClose={toggleModal}
                        title={titleTranslation}
                        style={{ padding: 0 }}
                    >
                        <StyledIframe
                            title="survey"
                            src={link}
                        />
                    </Modal>
                )}
            </Container>
        </Tooltip>
    );
};

export default SurveyItem;
