import axios, { AxiosPromise } from "axios";
import { IJossoUpdateReplyData, IResponse, LoginResponseType } from "p6m-response";
import { IUserGoals } from "p6m-goalsData";
import { UserGroupsData, UserPreferences, IUserMetadata, IUserStars } from "p6m-user";
import { ChangeEmailParamsType, ResendPasswordParamsType, UserFamilyMembersResponse } from "p6m-networking";

import { AvatarsGroupsResponse } from "p6m-avatars";

export function getUserMetadata(): AxiosPromise<IResponse<{ userData: IUserMetadata }>> {
    return axios.get("/userMetadata");
}

export function setUserMetadata(payload: any): AxiosPromise<IResponse<{ userMetaData: IUserMetadata }>> {
    return axios.post("/userMetadata", payload);
}

export function getUserGoals(): AxiosPromise<IResponse<IUserGoals>> {
    return axios.get("/userGoals");
}

export function setFirstPracticeFinished() {
    const data = {
        userGoal: "FIRST_PRACTICE",
        eventTime: new Date(),
    };
    return axios.post("/userGoal", data);
}

export function changeEmail(payload: ChangeEmailParamsType): AxiosPromise<string> {
    return axios.post("changelogin", payload);
}

export function resendPassword(payload: ResendPasswordParamsType): AxiosPromise<string> {
    return axios.post("resendPassword", payload);
}

export function getUserGameStars(): AxiosPromise<IResponse<IUserStars>> {
    return axios.get("/userStars");
}

export function addUserGameStar(): AxiosPromise<IResponse<IUserStars>> {
    return axios.get("/userStarsAdd");
}

export function removeUserGameStar(): AxiosPromise<IResponse<IUserStars>> {
    return axios.get("/userStarsRemove");
}

export function getUserGroupsList(payload: string): AxiosPromise<UserGroupsData> {
    return axios.get(payload + "/usergroups");
}

export function getUserFamilyMembers(): AxiosPromise<UserFamilyMembersResponse> {
    return axios.get("GroupUserPreferences");
}

// export function postParentSettings(settings, userId) {
//     return axios.post('foreignPreferencesAndParentSettings/' + userId, settings);
// }

export function getUserPreferences(): AxiosPromise<UserPreferences> {
    return axios.get("preferences");
}

export function postUserPreferences(payload: UserPreferences): AxiosPromise<IResponse<string>> {
    return axios.post("preferences", payload);
}

export function postChildrenPreferences(
    payload: UserPreferences & { userId: string }
): AxiosPromise<IResponse<string>> {
    return axios.post("foreignPreferencesAndParentSettings/" + payload.userId, payload);
}

export function getUserAvatars(): AxiosPromise<IResponse<AvatarsGroupsResponse>> {
    return axios.get("user/avatars");
}

export function jossoUpdate(): AxiosPromise<IResponse<IJossoUpdateReplyData>> {
    return axios.get("jossoInfo/update");
}

export function jossoReload(): AxiosPromise<IResponse<IJossoUpdateReplyData>> {
    return axios.get("jossoInfo/reload");
}

export function jossoCached(): AxiosPromise<IResponse<IJossoUpdateReplyData>> {
    return axios.get("jossoInfo/cached");
}

export function getJossoInfoLoginData(jossoSessionId: string, email?: string): AxiosPromise<LoginResponseType> {
    const headers: any = { "X-JAUTHTOKEN": jossoSessionId };
    if (email) headers["X-LBTOKEN"] = email.toLowerCase();
    return axios.get("jossoInfo", { headers: headers });
}

export function deactivateReactUserRole(): AxiosPromise<IResponse<string>> {
    return axios.post("setReactRole", { setReactRole: false });
}

/*
This request tells the backend to set the role "p6o.p6o2" indicating user has accepted the terms & conditions
 */
export function assignRole(): AxiosPromise<IResponse<string>> {
    return axios.get("assignRole");
}

/*
 * This request tells the backend to remove a specific role from a user
 *
 * @param role The name of the role that should be removed - e.g. "p6o.p6o2.react-switch"
 */
export function removeUserRole(role: string): AxiosPromise<IResponse<string>> {
    return axios.post("setUserRole", { role: role, setRole: false });
}
