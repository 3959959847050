// REACT
import React from "react";

// STYLED COMPONENTS
import styled from "styled-components";

export interface LinkInputProps {
    children?: string | JSX.Element | (string | JSX.Element)[];
    href?: string;
    target?: string;
    onClick?: () => void;
}

const StyledLink = styled.a`
    color: ${(props) => props.theme.colors.text};
    text-align: left;
    font-size: ${(props) => props.theme.base.mediumSize};
    font-family: ${(props) => props.theme.links.base.fontFamily};
    text-decoration: none;
    cursor: pointer;
    &:focus {
        outline: none;
    }
    &:hover {
        color: #666;
    }
`;

const SimpleLink: React.FC<LinkInputProps> = (props) => {
    return <StyledLink {...props}>{props.children}</StyledLink>;
};

export default SimpleLink;
