import React, {FunctionComponent, memo} from 'react';
import {T} from '@transifex/react';
import {Row, Col} from '../../../../basic/grid';

import {
    Wrapper,
    Title,
    ItemInformationCol,
    ItemRow,
} from './styles';

export type Props = {
    onSelect: () => void;
};

const DefaultUnitItem: FunctionComponent<Props> = (props) => {
    const {onSelect} = props;

    return (
        <Wrapper $edit={false} onClick={onSelect}>
            <ItemRow vertical="center">
                <ItemInformationCol>
                    <Row>
                        <Col xs={12}>
                            <Title><T _str="General" /></Title>
                        </Col>
                    </Row>
                </ItemInformationCol>
            </ItemRow>
        </Wrapper>
    );
};

export default memo(DefaultUnitItem);
