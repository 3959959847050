// LIBRARIES
import React, { useCallback, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useT } from "@transifex/react/dist";

//REDUX
import { actions as addActions } from "../../../redux/add/addSlice";
import { selectors as userSelectors, actions } from "../../../redux/user/userSlice";
import { selectors as goalsSelectors } from "../../../redux/goals/goalsSlice";
import { actions as modalActions } from "../../../redux/modal/modalSlice";

// COMPONENTS
import SideNavigation from "./SideNavigation";

//UTILS
import { GlobalModalView } from "../../../helpers/Modal";

// HOOKS
import { useFirstPracticeResult } from "../../../hooks/useFirstPracticeResult";

export interface SideNavigationProps {
    closeMenu?: () => any;
}

const SideNavigationView: React.FC<SideNavigationProps> = (props) => {
    const dispatch = useDispatch();
    const t = useT();

    const user = useSelector(userSelectors.user);
    const userPreferences = useSelector(userSelectors.userPreferences);
    const userMetadata = useSelector(userSelectors.userMetadata);
    const userStars = useSelector(userSelectors.userStars);
    const isTeacher = useSelector(userSelectors.isTeacher);
    const isUserAnonymous = useSelector(userSelectors.isUserAnonymous);
    const { starsCount = 0 } = userStars || { starsCount: 0 };
    const goals = useSelector(goalsSelectors.goals);
    const hasFirstPractice = useFirstPracticeResult();

    const hasDashboardAccess = isTeacher && !!user.roles && user.roles.includes("teacher.dashboard.show");
    const homeName =
        hasDashboardAccess && !isUserAnonymous && !!user.firstName
            ? user.firstName
            : t("Home", { _tags: "sideNavigation,home" });
    const practiced50Cards = !!goals && goals.goals.practiced50Cards;
    const recommendUrl =
        "https://www.phase-6.de/classic/recommend/?UserDnsID=" + user.userDnsId + "&hideThankyouButton=true";

    // The libraryAccess flag is defined as optional, so we better check first if it exists
    // and then we proceed to negate the value.
    let isLibraryAccessDisabled = false;
    if (userPreferences.parentSettings && typeof userPreferences.parentSettings.libraryAccess !== "undefined") {
        isLibraryAccessDisabled = !userPreferences.parentSettings.libraryAccess;
    }

    const handleOpenContentModal = useCallback(() => {
        dispatch(modalActions.setModalView(GlobalModalView.AddSubject));
    }, []);

    const handleMenuButtonClick = useCallback((args?: { showAddCardModal?: boolean }) => {
        const { showAddCardModal = false } = args || {};

        showAddCardModal && dispatch(addActions.setModal("subject"));
        props.closeMenu && props.closeMenu();
    }, []);

    const propsObject = {
        ...props,
        user,
        userPreferences,
        userMetadata,
        isTeacher,
        isUserAnonymous,
        isLibraryAccessDisabled,
        homeName,
        practiced50Cards,
        hasFirstPractice: isTeacher || hasFirstPractice,
        recommendUrl,
        starsCount,
        openContentModal: handleOpenContentModal,
        clickMenuButton: handleMenuButtonClick,
    };

    useEffect(() => {
        if (userStars) return;
        dispatch(actions.fetchUserStars());
    }, [userStars, dispatch]);

    return <SideNavigation {...propsObject} />;
};

export default SideNavigationView;
