// LIBRARIES
import React, { FunctionComponent, useMemo, useCallback, useEffect } from "react";

// REDUX
import { useDispatch, useSelector } from "react-redux";
import { selectors, actions } from "../../../../redux/learning/learningSlice";

// UTILS
import { getCardData } from "../../../../helpers/Cards";
import { getPlaylist } from "../../../../helpers/Audio";

// TYPES
import { Annotations } from "p6m-learning";

// COMPONENTS
import Component from "./CardContent";

type Props = {
    isAnswer?: boolean;
};

export const CardContent: FunctionComponent<Props> = (props) => {
    const { isAnswer = false } = props;
    const { text, example, transcription } = useSelector(selectors.cardTexts(isAnswer));
    const currentAnnotations = useSelector(selectors.currentAnnotations) || {};
    const annotation = useSelector(selectors.cardAnnotation(isAnswer));
    const direction = useSelector(selectors.cardDirection);
    const autoplay = useSelector(selectors.playAudio);

    const dispatch = useDispatch();

    const { htmlTitle, audios, images } = useMemo(() => getCardData(text || ""), [text]);

    useEffect(() => {
        if (!audios.length || !autoplay) return;
        const playlist = getPlaylist();
        playlist.setAudios([audios[0]], true);
        return () => {
            playlist.stop();
        };
    }, [autoplay, audios]);

    const onAnnotate = useCallback(
        (text: string) => {
            const keys: (keyof Annotations)[] =
                direction === "opposite" ? ["answer", "question"] : ["question", "answer"];
            const keyToUpdate = keys[+isAnswer];
            const result: Annotations = { ...currentAnnotations };
            result[keyToUpdate] = text;
            dispatch(actions.saveAnnotations({ ...result, isAnswer }));
        },
        [dispatch, currentAnnotations, isAnswer]
    );

    return (
        <Component
            htmlTitle={htmlTitle}
            example={example || ""}
            transcription={transcription || ""}
            audios={audios}
            images={images}
            annotation={annotation || ""}
            onAnnotate={onAnnotate}
        />
    );
};
