// LIBRARIES
import React, { ReactNode, FunctionComponent } from "react";
import { useDispatch } from "react-redux";

//REDUX
import { actions as modalActions } from "../../../../../redux/modal/modalSlice";

//COMPONENTS
import ModifyCard from "./ModifyCard";
import { GlobalModalView } from "../../../../../helpers/Modal";

interface ModifyCardProps {
    title: string;
    confirmButtonText: string;
    descriptionWarning: ReactNode;
    additionalNotice?: ReactNode;
    onClickMethod: () => void;
    closeOverwrite?: () => void;
}

const ModifyCardWrapper: FunctionComponent<ModifyCardProps> = (props) => {
    const dispatch = useDispatch();

    // todo: confirmButtonText seems to be "OK" 90% of time - maybe make it the default and add an optional overwrite?
    const { title, confirmButtonText, descriptionWarning, additionalNotice, onClickMethod, closeOverwrite } = props;

    const close = closeOverwrite
        ? closeOverwrite
        : () => {
              dispatch(modalActions.setModalView(GlobalModalView.None));
          };

    return (
        <ModifyCard
            title={title}
            confirmButtonText={confirmButtonText}
            descriptionWarning={descriptionWarning}
            additionalNotice={additionalNotice}
            onClickMethod={onClickMethod}
            close={close}
        />
    );
};

export default ModifyCardWrapper;
