import React, { FunctionComponent, memo } from "react";
import { NavigationWrapper, StyledItem } from "./styles";
import { RocketItem } from "./rocketItem";
import { LeaderBoardItem } from "./LeaderBoardItem";
import { TrophyItem } from "./trophyItem";
import { SurveyItem } from "./SurveyItem";

export type Props = {
    hasFirstPractice: boolean;
};

const TopNavigationCentralMenu: FunctionComponent<Props> = (props) => {
    return (
        <NavigationWrapper>
            {props.hasFirstPractice && (
                <StyledItem>
                    <RocketItem />
                </StyledItem>
            )}
            {props.hasFirstPractice && (
                <StyledItem>
                    <TrophyItem />
                </StyledItem>
            )}
            <StyledItem>
                <LeaderBoardItem />
            </StyledItem>
            <StyledItem>
                <SurveyItem />
            </StyledItem>
        </NavigationWrapper>
    );
};

export default memo(TopNavigationCentralMenu);
