import styled from "styled-components";

export const StyledInput = styled.input<{ size?: number }>`
    background: none repeat scroll 0 0 rgba(0, 0, 0, 0);
    border: medium none;
    box-shadow: none;
    display: inline;
    margin-right: 10px;
    font-size: 16px;
    width: ${({ size = 16 }) => size}px;
    height: ${({ size = 16 }) => size}px;
    font-family: ${(props) => props.theme.base.fontFamily};
    color: ${(props) => props.theme.colors.text};
    transition: all 0.15s ease-in-out 0s;
    &:focus {
        outline: none;
    }
`;

export const StyledLabel = styled.label`
    display: flex;
    flex: 1;
    align-items: center;
`;

export const StyledLabelText = styled.span`
    text-align: left;
    font-size: 16px;
    font-family: ${(props) => props.theme.base.fontFamily};
    color: ${(props) => props.theme.colors.text};
`;

export const InputContainer = styled.div`
    width: 100%;
    margin: 10px 0;
    display: flex;
`;
