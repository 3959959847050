import styled, { css, keyframes } from "styled-components";

const animations = [
    keyframes`
            0% {
                opacity: 1;
            }
            100% {
                opacity: 0;
            }
        `,
    keyframes`
            0% {
                left: -12px;
                top: -12px;
                right: -12px;
                bottom: -12px;
            }
            100% {
                left: -4px;
                top: -4px;
                right: -4px;
                bottom: -4px;
            }
        `,
];

const commonStyles = css`
    position: relative;
    transition: opacity 0.5s;

    &:after {
        content: " ";
        display: block;
        position: absolute;
        left: -4px;
        top: -4px;
        right: -4px;
        bottom: -4px;
        z-index: -1;
        border: 1px solid;
        border-color: inherit;
        border-radius: inherit;
        opacity: 0;
    }
`;

export const hoverStyles = css`
    ${commonStyles}

    &:hover {
        opacity: ${({ theme: { base } }) => base.hoverOpacity};
    }
`;

export const focusStyles = css`
    ${commonStyles}

    &:focus {
        outline: 0;
    }
    &:focus:after {
        opacity: 1;
        animation-name: ${animations[1]};
        animation-duration: 0.5s;
        animation-iteration-count: 1;
        animation-direction: alternate;
    }
`;

interface BlankButtonProps {
    highlighted?: boolean;
    borderRadius?: "small" | "big";
    bold?: boolean;
    upperCase?: boolean;
    textSize?: "s" | "m" | "xl" | "xxl";
    useWarningColor?: boolean;
    useVerticalPadding?: boolean;
    useModalZIndex?: boolean;
}

export type ButtonColors = "basic" | "primary" | "danger" | "success" | "neutral";

export const getButtonColor = ({
    colorType = "basic",
    theme: { colors },
}: {
    colorType?: ButtonColors;
    theme: any;
}): string => {
    const colorsRelation: Record<ButtonColors, string> = {
        primary: colors.primary as string,
        danger: colors.red as string,
        success: colors.green as string,
        neutral: colors.text as string,
        basic: colors.backgroundMain as string,
    };

    return colorsRelation[colorType];
};

export const getButtonTextColor = ({
    colorType = "basic",
    theme: { colors },
}: {
    colorType?: ButtonColors;
    theme: any;
}): string => {
    const colorsRelation: Record<ButtonColors, string> = {
        primary: colors.backgroundContrast as string,
        danger: "#fff",
        success: "#fff",
        neutral: "#fff",
        basic: colors.text as string,
    };

    return colorsRelation[colorType];
};

export const BlankButton = styled.button<BlankButtonProps>`
    display: flex;
    padding: ${({ theme, useVerticalPadding }) =>
        useVerticalPadding ? theme.base.spacing : `0 ${theme.base.spacing}`};
    min-height: 40px;
    justify-content: center;
    align-items: center;
    font-size: ${({
        textSize,
        theme: {
            base: { smallSize, mediumSize, bigSize, titleSize },
        },
    }) =>
        !textSize || textSize === "m"
            ? mediumSize
            : textSize === "s"
            ? smallSize
            : textSize === "xl"
            ? bigSize
            : titleSize};
    font-weight: ${({ bold, theme }) => (bold ? theme.base.bigWeight : theme.base.weight)};
    background-color: unset;
    border-radius: ${({ borderRadius = "small", theme: { base } }) =>
        borderRadius === "small" ? base.borderRadius : "50px"};
    border: none;
    transition: background 0.25s, color 0.25s, border-color 0.25s;
    z-index: ${({ useModalZIndex, theme }) => (useModalZIndex ? theme.base.modalZIndex : "unset")};
    color: ${({ highlighted, useWarningColor, theme: { colors } }) =>
        useWarningColor ? colors.red : highlighted ? colors.primary : colors.text};
    border-color: ${({ highlighted, useWarningColor, theme: { colors } }) =>
        useWarningColor ? colors.red : highlighted ? colors.primary : colors.text};
    text-transform: ${({ upperCase }) => (upperCase ? "uppercase" : "unset)")};

    &:disabled {
        opacity: ${({ theme }) => theme.base.disabledOpacity};
    }

    &:not(:disabled) {
        &:hover {
            cursor: pointer;
        }

        ${hoverStyles}

        ${focusStyles}
    }
`;

export const OutlinedButton = styled(BlankButton)`
    border: 1px solid;
`;

export const FilledButton = styled(BlankButton)`
    color: ${({ useWarningColor, theme }) => (useWarningColor ? "white" : theme.buttons.primary.textColor)};
    background-color: ${({ useWarningColor, theme }) =>
        useWarningColor ? theme.colors.red : theme.buttons.primary.backgroundColor};
    border-color: ${({ useWarningColor, theme }) =>
        useWarningColor ? theme.colors.red : theme.buttons.primary.backgroundColor};

    &:not(:disabled) {
        &:hover {
            background-color: ${({ useWarningColor, theme }) =>
                useWarningColor ? theme.colors.red : theme.buttons.primary.hoverBackgroundColor};
        }

        ${focusStyles}
    }
`;
