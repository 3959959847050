import styled from "styled-components";

export const ToggleWrapper = styled.div`
    width: 52px;
    padding: ${(props) => props.theme.base.spacingTiny};
    border-radius: 20px;
    transition: all 0.4s ease 0s;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: left;
    border: 2px solid ${(props) => props.theme.colors.textLight};

    &.checked {
        background: ${(props) => props.theme.colors.primary};
        justify-content: right;
        border-color: ${(props) => props.theme.colors.primary};
    }

    &:disabled {
        background: ${(props) => props.theme.colors.text + "50"};
        opacity: ${(props) => props.theme.base.disabledOpacity};
        cursor: not-allowed;
    }
`;

export const ToggleInterior = styled.div`
    width: 20px;
    height: 20px;
    border-radius: 50%;
    cursor: pointer;
    background: none;
    border: 2px solid ${(props) => props.theme.colors.textLight};

    &.checked {
        background: ${(props) => props.theme.colors.backgroundContrast};
        border-color: ${(props) => props.theme.colors.backgroundContrast};
    }
`;
