// LIBRARIES
import React, { useState, useContext, useRef } from "react";
import styled, { ThemeContext } from "styled-components";
import { useT } from "@transifex/react";

// COMPONENTS
import PhaseSixIcon from "../../basic/phaseSixIcon/PhaseSixIcon";
import PopUp from "../../basic/popupAnchored/Popup";
import { PopupContainer } from "../../complex/userSubjectsList/userSubjectListEntry/styles";

// CONSTANTS
import DictionaryConstants from "../../../constants/DictionaryConstants";

// TYPES
import { AvailableLanguageCodes, DictionarySearchResult } from "p6m-dictionary";

// CONTEXT
import { LanguagesContext } from "../../../context/languages";

export interface SearchInDictionaryProps {
    searchValue: string;
    handleChange: (input: string) => any;
    clearSearch: () => any;
    searchResults: Array<DictionarySearchResult>;
    dictionarySearchLanguage: AvailableLanguageCodes;
    onLanguageSelect: (languageCode: AvailableLanguageCodes) => any;
    activePosition: number;
    increaseActiveIndex: () => void;
    decreaseActiveIndex: () => void;
    navigateToDictionary: (word: string) => void;
}

const Container = styled.div`
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    position: relative;
`;

const InputContainer = styled.div`
    background: ${(props) => props.theme.colors.backgroundContrast};
    color: ${(props) => props.theme.colors.textLight};
    flex: 1;
    height: 50px;
    transition: all 0.15s ease-in-out 0s;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    box-shadow: inset 0 2px 8px ${(props) => props.theme.colors.shadow};
    padding: ${(props) => props.theme.base.spacingInContent} ${(props) => props.theme.base.spacing};
    border-radius: 25px;
`;

const StyledInput = styled.input`
    width: 100%;
    min-width: 150px;
    background: none repeat scroll 0 0 rgba(0, 0, 0, 0);
    border: medium none;
    box-shadow: none;
    display: inline;
    height: auto;
    flex: 1;
    padding: 0 0 0 ${(props) => props.theme.base.spacingInContent};
    font-size: ${(props) => props.theme.base.bigSize};
    font-family: ${(props) => props.theme.base.fontFamily};
    color: ${(props) => props.theme.colors.text};
    transition: all 0.15s ease-in-out 0s;

    &:focus {
        outline: none;
    }
`;

const LanguageSelection = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    position: relative;
`;

const SelectToggle = styled.button`
    background: none repeat scroll 0 0 rgba(0, 0, 0, 0);
    border: medium none;
    box-shadow: none;
    display: flex;
    align-items: center;
    cursor: pointer;
    outline: inherit;
    padding: 0;
`;
const AngleIcon = styled.div`
    line-height: 1;
    padding-left: 4px;
`;

const LanguageList = styled.div`
    background: ${(props) => props.theme.colors.backgroundContrast};

    border-radius: ${(props) => props.theme.base.borderRadius};
    top: 100%;
    margin-top: 5px;
    right: 0;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    div:last-child {
        margin-bottom: 0;
    }
`;
const LanguageListEntry = styled.div`
    display: flex;
    flex-grow: 1;
    background: ${(props) => props.theme.colors.backgroundContrast};
    align-items: center;
    cursor: pointer;
    outline: inherit;
    padding: ${(props) => props.theme.base.spacingSmall};
    border: none;

    &:hover {
        background: ${(props) => props.theme.colors.backgroundMain};
    }

    > i {
        color: ${(props) => props.theme.colors.text};
    }
`;

const LanguageText = styled.span`
    margin-left: ${(props) => props.theme.base.spacingSmall};
    font-size: 16px;
    font-family: ${(props) => props.theme.base.fontFamily};
    color: ${(props) => props.theme.colors.text};
    flex: 1;
    white-space: nowrap;
`;
const SelectedIconContainer = styled.div`
    margin-left: ${(props) => props.theme.base.spacingSmall};
    > i {
        color: ${(props) => props.theme.colors.primary};
    }
`;
const AngleRightIcon = styled(PhaseSixIcon)`
    margin: 0 ${(props) => props.theme.base.spacingSmall};
`;
const FlagImage = styled.img`
    height: 24px;
    border-radius: ${(props) => props.theme.base.borderRadius};
`;
const FlagImageList = styled.img`
    height: 32px;
    border-radius: ${(props) => props.theme.base.borderRadius};
`;
const SearchResultsContainer = styled.div`
    position: absolute;
    background: ${(props) => props.theme.colors.backgroundContrast};
    border-bottom-left-radius: ${(props) => props.theme.base.borderRadius};
    border-bottom-right-radius: ${(props) => props.theme.base.borderRadius};
    border-top-right-radius: ${(props) => props.theme.base.borderRadius};
    top: 100%;
    width: 100%;
    border-width: 1px;
    border-color: ${(props) => props.theme.colors.primary};
    z-index: 3;
    overflow: hidden;
`;
const SearchResultContainer = styled.div<{ active?: boolean }>`
    padding: ${(props) => props.theme.base.spacingSmall} ${(props) => props.theme.base.spacing};
    width: 100%;
    border-width: 1px;
    border-bottom-color: ${(props) => props.theme.colors.backgroundMain};
    background: ${(props) => (props.active ? props.theme.colors.backgroundMain : "none")};
    display: flex;
    flex-direction: row;

    &:hover {
        background: ${(props) => props.theme.colors.backgroundMain};
        cursor: pointer;
    }
`;
const SearchResultImage = styled.img`
    width: 32px;
    margin-right: ${(props) => props.theme.base.spacingInContent};
    border-radius: ${(props) => props.theme.base.spacingInContent};
`;
const SearchResultText = styled.p`
    font-family: ${(props) => props.theme.base.fontFamily};
    color: ${(props) => props.theme.colors.text};
    font-size: ${(props) => props.theme.base.mediumSize};
`;
const NoResultsContainer = styled(SearchResultContainer)`
    background: ${(props) => props.theme.colors.backgroundMain};

    &:hover {
        background: ${(props) => props.theme.colors.backgroundMain};
        cursor: default;
    }
`;
const NoResultText = styled(SearchResultText)`
    color: ${(props) => props.theme.colors.primary};
`;

const ClearInputButton = styled(PhaseSixIcon)`
    cursor: pointer;
`;

const SearchInDictionary = (props: SearchInDictionaryProps): JSX.Element => {
    const t = useT();

    const themeContext = useContext(ThemeContext);
    const { languageTranslations, getFlagSource } = useContext(LanguagesContext);

    const [isFocused, setIsFocused] = useState<boolean>(false);
    const [showLanguagesSelect, setShowLanguagesSelect] = useState<boolean>(false);
    const inputRef = useRef<HTMLInputElement>(null);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        props.handleChange(event.target.value);
    };

    const handleKeyPressed = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === "ArrowDown") {
            event.preventDefault();
            props.increaseActiveIndex();
        }
        if (event.key === "ArrowUp") {
            event.preventDefault();
            props.decreaseActiveIndex();
        }
        if (event.key === "Enter") {
            event.preventDefault();
            props.navigateToDictionary(props.searchResults[props.activePosition].word);
        }
    };
    const onLanguageSelect = (languageCode: AvailableLanguageCodes) => {
        props.onLanguageSelect(languageCode);
        setShowLanguagesSelect(false);
    };

    const toggleLanguageSelection = () => {
        props.clearSearch();
        setShowLanguagesSelect(!showLanguagesSelect);
    };

    const focusInput = () => {
        inputRef.current && inputRef.current.focus();
    };

    const clearInput = () => {
        props.clearSearch();
        focusInput();
    };

    const searchPlaceholder_t = t("Search Dictionary", { _tags: "dictionary,search" });
    const german_t = t("German", { _tags: "dictionary,list" });
    const wordNotFound_t = t("word not found", { _tags: "dictionary,list" });

    const color = isFocused ? themeContext.colors.primary : themeContext.colors.text;
    return (
        <Container onBlur={() => setIsFocused(false)}>
            <InputContainer style={{ borderColor: color }}>
                <PhaseSixIcon name="search" />
                <StyledInput
                    onFocus={() => setIsFocused(true)}
                    onChange={handleChange}
                    onKeyDown={handleKeyPressed}
                    value={props.searchValue}
                    placeholder={searchPlaceholder_t}
                    ref={inputRef}
                />
                {props.searchValue ? (
                    <ClearInputButton
                        name="exercise-failed"
                        highlighted={isFocused}
                        onClick={clearInput}
                    />
                ) : (
                    <LanguageSelection>
                        <PopupContainer>
                            <PopUp
                                onToggle={toggleLanguageSelection}
                                arrow={false}
                                position={["bottom", "right"]}
                                content={
                                    <LanguageList>
                                        {DictionaryConstants.targetLanguages.map((language, id) => {
                                            const active = props.dictionarySearchLanguage === language.languageCode;
                                            return (
                                                <LanguageListEntry
                                                    key={id}
                                                    onClick={() => onLanguageSelect(language.languageCode)}
                                                >
                                                    <FlagImageList src={getFlagSource("de")} />
                                                    <AngleRightIcon name="chevron-right" />
                                                    <FlagImageList src={getFlagSource(language.languageCode)} />
                                                    <LanguageText>
                                                        {`${german_t}-${
                                                            languageTranslations[language.languageCode || "others"]
                                                        }`}
                                                    </LanguageText>
                                                    <SelectedIconContainer>
                                                        {active && <PhaseSixIcon name="exercise-ok" />}
                                                    </SelectedIconContainer>
                                                </LanguageListEntry>
                                            );
                                        })}
                                    </LanguageList>
                                }
                            >
                                <SelectToggle onClick={toggleLanguageSelection}>
                                    <FlagImage src={getFlagSource(props.dictionarySearchLanguage)} />
                                    <AngleIcon>
                                        <PhaseSixIcon name={showLanguagesSelect ? "chevron-up" : "chevron-down"} />
                                    </AngleIcon>
                                </SelectToggle>
                            </PopUp>
                        </PopupContainer>
                    </LanguageSelection>
                )}
            </InputContainer>

            {props.searchValue && (
                <SearchResultsContainer>
                    {props.searchResults?.map((searchResult, index) => (
                        <SearchResultContainer
                            key={index}
                            active={index === props.activePosition}
                            onClick={() => props.navigateToDictionary(searchResult.word)}
                        >
                            <SearchResultImage src={getFlagSource(searchResult.lang)} />
                            <SearchResultText>{searchResult.word}</SearchResultText>
                        </SearchResultContainer>
                    )) || (
                        <NoResultsContainer>
                            <NoResultText>{wordNotFound_t}</NoResultText>
                        </NoResultsContainer>
                    )}
                </SearchResultsContainer>
            )}
        </Container>
    );
};

export default SearchInDictionary;
