// REACT
import React from "react";

// LIBRARIES
import { useMediaQuery } from "react-responsive";

// COMPONENTS
import PlusFlag from "../../basic/plusFlag/PlusFlag";

// TYPES
import { TIconNames } from "../../basic/phaseSixIcon/PhaseSixIcon";
import {
    CategoryIcon,
    DisabledTextSpan,
    Subtitle,
    StyledCategoryWrapper,
    StyledActionItemContainer,
    IconAndTextContainer,
    Text,
    StyledReactTooltip,
} from "./styles";

export interface SettingsCategoryEntryProps {
    title: string;
    subtitle?: string;
    plusFlag?: boolean;
    icon?: TIconNames;
    svgIcon?: React.FC;
    onClickAction?: () => any;
    userDefinedIconColor?: string;
    disableHover?: boolean;
    showArrow?: boolean;
    disabled?: boolean;
    disabledTooltipText?: string;
    className?: string;
    "data-qa"?: string;
}

const SettingsMainStyledCTA: React.FC<SettingsCategoryEntryProps> = (props) => {
    const { disabled, disabledTooltipText, disableHover, title } = props;

    let icon = null;
    let isP6Icon = !!props.icon;
    let isSvgIcon = !!props.svgIcon;
    if (isP6Icon || isSvgIcon) {
        icon = (
            <CategoryIcon
                {...props}
                isActive={false}
            />
        );
    }

    let arrowIcon = null;
    if (props.showArrow) {
        arrowIcon = (
            <CategoryIcon
                icon={"chevron-right"}
                isActive={false}
            />
        );
    }

    // Media query for the mobile rendering
    const lowerBorderQuery = "(max-width: " + process.env.REACT_APP_LOWER_GRID_STEP_BORDER + "px)";
    let isMobileView: Boolean = useMediaQuery({
        query: lowerBorderQuery,
    });

    // props for the Tooltip to work
    let tooltip = null;
    let linkProps: { [key: string]: any } = {};
    if (disabled && typeof disabledTooltipText != "undefined" && disabledTooltipText.length > 0) {
        if (isMobileView) {
            tooltip = (
                <DisabledTextSpan>
                    <br />({disabledTooltipText})
                </DisabledTextSpan>
            );
        } else {
            linkProps = {
                "data-tip": "",
                "data-for": props.title + "_tooltip",
            };
            tooltip = "(" + disabledTooltipText + ")";
        }
    }

    let subtitle = null;
    if (props.subtitle) {
        subtitle = <Subtitle>{props.subtitle}</Subtitle>;
    }

    let flag = null;
    if (props.plusFlag) {
        flag = <PlusFlag />;
    }

    if (isMobileView) {
        return (
            <StyledCategoryWrapper
                className={`${disableHover ? "no-hover" : ""} ${!!props.className ? props.className : ""} ${
                    disabled ? "disabled" : ""
                }`}
                data-qa={props["data-qa"]}
            >
                <StyledActionItemContainer onClick={disabled ? () => {} : props.onClickAction}>
                    <IconAndTextContainer>
                        {icon}
                        <Text>
                            {title}
                            {subtitle}
                            {flag}
                            {tooltip}
                        </Text>
                    </IconAndTextContainer>
                    {arrowIcon}
                </StyledActionItemContainer>
            </StyledCategoryWrapper>
        );
    }

    return (
        <StyledCategoryWrapper
            className={`${props.disableHover ? "no-hover" : ""} ${!!props.className ? props.className : ""} ${
                disabled ? "disabled" : ""
            }`}
            data-qa={props["data-qa"]}
        >
            <StyledActionItemContainer
                onClick={disabled ? () => {} : props.onClickAction}
                {...linkProps}
            >
                <IconAndTextContainer>
                    {icon}
                    <Text>
                        {title}
                        {subtitle}
                        {flag}
                    </Text>
                </IconAndTextContainer>
                {arrowIcon}
            </StyledActionItemContainer>
            {tooltip && <StyledReactTooltip id={props.title + "_tooltip"}>{tooltip}</StyledReactTooltip>}
        </StyledCategoryWrapper>
    );
};

SettingsMainStyledCTA.defaultProps = {
    showArrow: true,
};

export default SettingsMainStyledCTA;
