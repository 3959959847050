// REACT
import React, { ButtonHTMLAttributes } from "react";

// STYLED COMPONENTS
import { StyledButton, StyledButtonText } from "./styles";

export interface PrimaryButtonProps extends Partial<ButtonHTMLAttributes<HTMLButtonElement>> {
    onClick: () => void;
    color?: string;
}

const PrimaryButton: React.FC<PrimaryButtonProps> = (props) => {
    return (
        <StyledButton {...props}>
            <StyledButtonText>{props.children}</StyledButtonText>
        </StyledButton>
    );
};

export default PrimaryButton;
