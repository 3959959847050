// REACT
import React, { memo, forwardRef } from "react";

// COMPONENT
import { Container, Row, Col } from "../../../basic/grid";
import Progress from "../Progress/Progress";

// STYLED COMPONENTS
import {
    HeaderWrapper,
    BackButton,
    ProgressWrapper,
    StyledPhaseSixButton,
    ProgressResults,
    ProgressContainer,
} from "./styles";

export type Props = {
    total: number;
    practiced?: number;
    memorised?: number;
    cardPhase: number;
    name?: string;
    image?: string;
    onClose?: () => void;
    onGoBack?: () => void;
};

const Header = forwardRef<HTMLDivElement, Props>((props, ref) => {
    const { total, practiced, memorised, cardPhase, onClose, onGoBack } = props;

    return (
        <HeaderWrapper ref={ref}>
            <Container fullWidth>
                <Row
                    vertical="center"
                    horizontal="between"
                    noWrap
                >
                    <Col xs="auto">
                        {!!onGoBack && (
                            <BackButton
                                name="arrow-left"
                                onClick={onGoBack}
                            />
                        )}
                    </Col>
                    <Col xs="auto">
                        <ProgressWrapper>
                            <StyledPhaseSixButton name={"phase-up-down"} />
                            {cardPhase}
                            <ProgressContainer>
                                <Progress
                                    total={total}
                                    practiced={practiced}
                                    memorised={memorised}
                                />
                            </ProgressContainer>
                            <ProgressResults>
                                {`${typeof practiced === "number" ? `${practiced} / ` : ""}${
                                    typeof memorised === "number" ? `${memorised} / ` : ""
                                }${total}`}
                            </ProgressResults>
                        </ProgressWrapper>
                    </Col>
                    <Col xs="auto">
                        <Row
                            vertical="center"
                            noWrap
                        >
                            {!!onClose && (
                                <Col
                                    xs="auto"
                                    style={{ marginLeft: "auto" }}
                                >
                                    <StyledPhaseSixButton
                                        name={"close"}
                                        onClick={onClose}
                                    />
                                </Col>
                            )}
                        </Row>
                    </Col>
                </Row>
            </Container>
        </HeaderWrapper>
    );
});

export default memo(Header);
