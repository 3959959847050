import styled from "styled-components";

export const Container = styled.div`
    width: 100%;
    max-height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
`;

export const ContentContainer = styled.div`
    padding-right: 10px;
    overflow: auto;
    overscroll-behavior-x: none;
`;

export const TitleContainer = styled.div`
    padding-right: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`;

export const Title = styled.h2`
    margin: 0;
    color: ${(props) => props.theme.colors.textOnBackground};
    font-weight: 500;
    font-size: ${(props) => props.theme.base.cardPracticeTextSize};
`;

export const EmptyResultContainer = styled.div`
    display: flex;
    margin-top: ${(props) => props.theme.base.spacingTiny};
    background-color: ${(props) => props.theme.colors.backgroundContrast};
    padding: ${(props) => props.theme.base.spacingSmall};
    border-radius: ${(props) => props.theme.base.borderRadius};
    height: 92px; // equal to the image height plus margin and padding to keep the height of the container the same
    align-items: center;
`;

export const EmptyResultText = styled.span`
    padding-left: ${(props) => props.theme.base.spacingSmall};
    color: ${(props) => props.theme.colors.text};
`;
