//Libraries
import React, { useContext } from "react";
import { useT } from "@transifex/react";
import { ThemeContext } from "styled-components";

//Components
import CustomToggle from "../../basic/customToggle/CustomToggle";
import PhaseSixIcon from "../../basic/phaseSixIcon/PhaseSixIcon";
import { DefaultFocus } from "../../basic/defaultFocus/DefaultFocus";
import {
    HeaderSection,
    Section,
    Row,
    HeadingContainer,
    Headline,
    CloseIcon,
    PopupContentContainerLight,
    DataTextLine,
    Text,
    ContainerLightContent,
    Information,
    Footer,
    SettingsContainer,
    Value,
    ContentWrapper,
} from "./styles";

//TYPES
import Modal from "../../basic/modal/Modal";

export interface PersonalPreferencesPopupContentProps {
    togglePopup: any;
    useFirstName: boolean;
    toggleUseFirstName: any;
    isModalActive: boolean;
}

const PersonalPreferencesPopupContent: React.FC<PersonalPreferencesPopupContentProps> = (props) => {
    const t = useT();

    const weeklyRanking = t("Weekly ranking:", { _tags: "title" });
    const information = t("Information about your activity and the activity of other users", { _tags: "paragraph" });
    const pointsForActivityDescription = t("Points for your learning activity", { _tags: "title" });
    const activity = t("Learning activity", { _tags: "subtitle" });
    const points = t("Points", { _tags: "label" });
    const regularlyLearned = t("Regularly learned", { _tags: "label" });
    const learnedForTest = t("Learned for Test", { _tags: "label" });
    const newVocabulary = t("New vocabulary", { _tags: "label" });
    const typedLetters = t("Typed letters", { _tags: "label" });
    const privacy = t("Privacy", { _tags: "title" });
    const replaceFirstNameLabel = t("Replace first name", { _tags: "subtitle" });
    const footer = t(
        "You can select, if your firstname should be replaced by a pseudonym. Other users will see the pseudonym instead of your firstname in the leaderboard.",
        { _tags: "paragraph" }
    );

    const theme = useContext(ThemeContext);

    const clickUsernameToggle = () => {
        props.toggleUseFirstName();
    };

    const renderModalContent = () => {
        return (
            <ContentWrapper id={"personal-preferences-popup-content"}>
                <Section>
                    <Headline>{pointsForActivityDescription}</Headline>
                    <PopupContentContainerLight>
                        <DataTextLine>
                            <Text>{activity}</Text>
                            <Value>{points}</Value>
                        </DataTextLine>
                        <ContainerLightContent>
                            <DataTextLine>
                                <Text>{regularlyLearned}</Text>
                                <Value>10</Value>
                            </DataTextLine>
                            <DataTextLine>
                                <Text>{learnedForTest}</Text>
                                <Value>5</Value>
                            </DataTextLine>
                            <DataTextLine>
                                <Text>{newVocabulary}</Text>
                                <Value>5</Value>
                            </DataTextLine>
                            <DataTextLine>
                                <Text>{typedLetters} (max: 10)</Text>
                                <Value>1</Value>
                            </DataTextLine>
                        </ContainerLightContent>
                    </PopupContentContainerLight>
                </Section>

                <Section>
                    <Headline>{privacy}</Headline>
                    <SettingsContainer>
                        <Text>{replaceFirstNameLabel}</Text>
                        <div onClick={clickUsernameToggle}>
                            <CustomToggle checkedValue={!props.useFirstName} />
                        </div>
                    </SettingsContainer>
                </Section>
            </ContentWrapper>
        );
    };

    const renderModalHeader = () => {
        return (
            <HeaderSection>
                <Row>
                    <HeadingContainer>
                        <DefaultFocus />
                        <Headline>{weeklyRanking}</Headline>
                        <Information>{information}</Information>
                    </HeadingContainer>
                    <CloseIcon onClick={() => props.togglePopup(false)}>
                        <PhaseSixIcon name="close" />
                    </CloseIcon>
                </Row>
            </HeaderSection>
        );
    };

    const renderModalFooter = () => {
        return <Footer>{footer}</Footer>;
    };

    return (
        <Modal
            active={props.isModalActive}
            title={weeklyRanking}
            headerComponent={renderModalHeader}
            footerComponent={renderModalFooter}
            modalBodyStyle={{ background: theme.colors.backgroundMain }}
            afterClose={props.togglePopup}
        >
            {renderModalContent()}
        </Modal>
    );
};

export default PersonalPreferencesPopupContent;
