// REACT
import React, { FunctionComponent, memo } from "react";

// COMPONENTS
import GameItem, { Props as GameItemProps } from "../../wordMatchGame/GameItem/GameItem";
import { Row, Col } from "../../../basic/grid";

export type Props = {
    cards: GameItemProps[];
};

const DragDrop: FunctionComponent<Props> = (props) => {
    const { cards } = props;

    return (
        <Row>
            {cards.map((itemProps: any) => (
                <Col
                    xs={6}
                    s={4}
                    m={3}
                    key={itemProps.id}
                >
                    <GameItem key={itemProps.id} {...itemProps} />
                </Col>
            ))}
        </Row>
    );
};

export default memo(DragDrop);
