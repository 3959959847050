import styled, { css } from "styled-components";
import { Link } from "react-router-dom";

export const Icon = styled.div`
    color: inherit;
    font-size: 52px;
`;

export const Title = styled.div`
    color: inherit;
    font-size: ${({ theme: { base } }) => base.bigSize};
    word-break: break-word;
`;

export const GoalsDescription = styled.div`
    color: ${({ theme: { colors } }) => colors.text};
    font-size: ${({ theme: { base } }) => base.mediumSize};
    word-break: break-word;
`;

const wrapperStyles = css`
    min-height: 100%;
    box-sizing: border-box;
    padding: 16px 32px 32px 32px;
    background-color: ${({ theme: { colors } }) => colors.backgroundContrast};
    border-radius: ${({ theme: { base } }) => base.borderRadius};
    font-size: ${({ theme: { base } }) => base.extraBigSize};
    color: ${({ theme: { colors } }) => colors.primary};
    line-height: 1;

    &:not(.achieved) {
        background-color: ${({ theme: { colors } }) => colors.backgroundContrast};
        opacity: 0.5;
        color: ${({ theme: { colors } }) => colors.text};
    }

    & > ${Icon}, & > ${Title}, & > ${GoalsDescription} {
        margin: ${({ theme: { base } }) => base.spacingTiny} 0;
        text-align: center;
    }
`;

export const Wrapper = styled.div`
    ${wrapperStyles};
    ${({ onClick }) =>
        onClick
            ? `
        cursor: pointer;
        `
            : `
        cursor: default;
    `}
`;

export const LinkWrapper = styled(Link)`
    ${wrapperStyles};
    display: block;
    text-decoration: none;
    cursor: pointer;
    padding: 16px 32px 32px 32px;
`;
