//DEPENDENCIES
import React, { useMemo } from "react";
import { useSelector } from "react-redux";

//REDUX
import { selectors } from "../../../redux/subjects/subjectsSlice";

//UTILS
import { useUnitsInfoFromCards } from "../../../hooks/useUnitsInfoFromCards";

//TYPES
import { SubjectData, SubjectUnitCard } from "p6m-subjects";

//COMPONENTS
import Component from "./ActivationSummary";

export type ActivationSummaryProps = {
    subjectId?: string;
    // 0 - memorise first, 1 - drag & drop, 2 - mixed memorise & practice, 3 - practice immediately
    type?: number;
    direction?: number;
    cards?: SubjectUnitCard[];
    isPrepareForTest?: boolean;
    onChange: (result: { type?: number; direction?: number }) => void;
};

export const ActivationSummary = ({
    subjectId = "",
    type = 0,
    cards = [],
    isPrepareForTest = false,
    ...restProps
}: ActivationSummaryProps) => {
    const subject = useSelector(selectors.getSubjectById(subjectId)) || ({} as SubjectData);
    const { unitNamesString, unitsCardsAmount } = useUnitsInfoFromCards({
        cards,
        subjectId,
    });

    const canSwap: boolean = useMemo(() => {
        if (isPrepareForTest) return true;
        const swapTypes = [0, 2, 3];
        if (!swapTypes.includes(type)) return false;

        return !cards.find((card) => {
            const {
                cardContent: { swappable },
            } = card;
            return !swappable;
        });
    }, [cards, type, isPrepareForTest]);

    return (
        <Component
            subject={subject}
            canSwap={canSwap}
            isPrepareForTest={isPrepareForTest}
            type={type}
            cardsCount={cards.length}
            unitNamesString={unitNamesString}
            allCardsCount={unitsCardsAmount}
            {...restProps}
        />
    );
};
