import styled from "styled-components";

export const Title = styled.h2<{ right?: boolean }>`
    margin: 0;
    line-height: 1;
    font-size: ${({ theme: { base } }) => base.bigSize};
    font-weight: normal;
    color: ${({ theme: { colors } }) => colors.text};
    ${({ right = false }) =>
        right &&
        `
        text-align: right;
    `};

    @media (max-width: 575px) {
        font-size: 14px;
    }
`;
