//LIBRARIES
import React from "react";
import { T, useT } from "@transifex/react";

//STYLES
import { DescriptionHighlighted } from "../../complex/cardActionButtons/Modals/ModifyCard/styles";

//TYPES
import { EditableContentType } from "p6m-viewData";

// COMPONENTS
import ModifyCard from "../../complex/cardActionButtons/Modals/ModifyCard";

interface DeleteContentProps {
    contentType: EditableContentType;
    names: string[];
    deletableCardCount: number;
    selectedCardCount?: number;
    deleteContent: () => void;
}

const DeleteContentModal: React.FC<DeleteContentProps> = (props) => {
    const t = useT();

    const { contentType, names, deletableCardCount, selectedCardCount = 0, deleteContent } = props;

    let delete_title_t = "";
    switch (contentType) {
        case "subjects":
            delete_title_t = t("Delete subject", { _tags: "delete,modal,title" });
            break;
        case "units":
            delete_title_t = t("Delete unit", { _tags: "delete,modal,title" });
            break;
        case "cards":
            delete_title_t = t("Delete card", { _tags: "delete,modal,title" });
            break;
    }

    const delete_t = t("Delete", { _tags: "delete,modal,button" });

    const nonDeletableCardCount = selectedCardCount - deletableCardCount;

    const contentNames = <DescriptionHighlighted>{names.join(", ")}</DescriptionHighlighted>;
    const cardAmount = <DescriptionHighlighted>{deletableCardCount}</DescriptionHighlighted>;
    const cardAmountNonDeletable = <DescriptionHighlighted>{nonDeletableCardCount}</DescriptionHighlighted>;

    const descriptionWarning = (
        <>
            {contentType !== "cards" ? (
                <T
                    _str="You are about to delete the content {contentNames}."
                    contentNames={contentNames}
                />
            ) : (
                <T
                    _str="You are about to delete {cardAmount} card(s)."
                    cardAmount={cardAmount}
                />
            )}{" "}
            <T _str="This action can not be undone." />
        </>
    );

    const additionalNotice =
        deletableCardCount > 0 &&
        (contentType === "subjects" ? (
            <T
                _str="{cardAmount} card(s) will be deleted in this process!"
                cardAmount={cardAmount}
                _tags="delete,modal,warning"
            />
        ) : contentType === "units" ? (
            <T
                _str="{cardAmount} card(s) will be deleted. This action cannot be undone. Do you want to delete this unit now?"
                cardAmount={cardAmount}
                _tags="delete,modal,warning"
            />
        ) : (
            contentType === "cards" &&
            nonDeletableCardCount > 0 && (
                <T
                    _str="Please note that {cardAmount} card(s) belong to a publisher content. They cannot be deleted."
                    cardAmount={cardAmountNonDeletable}
                    _tags="delete,modal,warning"
                />
            )
        ));

    return (
        <ModifyCard
            confirmButtonText={delete_t}
            title={delete_title_t}
            descriptionWarning={descriptionWarning}
            additionalNotice={additionalNotice}
            onClickMethod={deleteContent}
        />
    );
};

export default DeleteContentModal;
