import axios, { AxiosPromise } from "axios";
import { IResponse } from "p6m-response";
import { WarningsResponse, WarningType } from "p6m-warnings";

export function getWarnings(): AxiosPromise<IResponse<WarningsResponse>> {
    return axios.get("/leaderboard/warningCount");
}

export function postWarning(warningType: WarningType): AxiosPromise<IResponse<any>> {
    const warningIdNumber = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
    return axios.post(`/leaderboard/warning/web_${warningIdNumber}`, { warningType });
}
