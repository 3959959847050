// REACT
import React, { FunctionComponent, memo } from "react";

// COMPONENTS
import { Autoactivation } from "./Autoactivation";
import { Done } from "./Done";
import { Phase } from "./Phase";
import { Update } from "./Update";
import { Feedback } from "./Feedback";
import { Exit } from "./Exit";
import { ExitFirstPractice } from "./ExitFirstPractice";

// TYPES
import { Props as ModalProps } from "../../../basic/modal/Modal";
import { Modals as TModals } from "p6m-learning";

type Props = {
    modal?: TModals;
    hasFirstPractice?: boolean;
} & Partial<ModalProps>;

const PracticeModals: FunctionComponent<Props> = (props) => {
    const { modal, hasFirstPractice, ...restProps } = props;

    return (
        <>
            {!!modal &&
                ((modal === "done" && (
                    <Done
                        hasFirstPractice={hasFirstPractice}
                        {...restProps}
                    />
                )) ||
                    (modal === "exit" && <Exit {...restProps} />) ||
                    (modal === "newPhase" && <Phase {...restProps} />) ||
                    (modal === "autoActivate" && <Autoactivation {...restProps} />) ||
                    (modal === "update" && <Update {...restProps} />) ||
                    (modal === "feedback" && <Feedback {...restProps} />) ||
                    (modal === "exitFirstPractice" && <ExitFirstPractice {...restProps} />))}
        </>
    );
};

export default memo(PracticeModals);
