import React, { FunctionComponent, useState, useMemo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Component from "./TopNotifications";
import { uploadNotifications } from "../../../networking/notifications";
import { actions as responseActions } from "../../../redux/response/responseSlice";
import {
    actions as notificationsActions,
    selectors as notificationsSelectors,
} from "../../../redux/notifications/notificationsSlice";
import { selectors as userSelectors } from "../../../redux/user/userSlice";
import ResponseConstants from "../../../constants/ResponseConstants";
import { INotificationsEvents } from "p6m-notifications";
import { ResponseMessageKeys } from "../response";

export const TopNotifications: FunctionComponent = () => {
    const notifications = useSelector(notificationsSelectors.notifications);
    const remindMe = useSelector(notificationsSelectors.remindMe);
    const email = useSelector(userSelectors.email);
    const [shouldLoad, setShouldLoad] = useState(true);
    const [showAll, setShowAll] = useState(false);
    const dispatch = useDispatch();
    const maxEvents: number = 10;

    useEffect(() => {
        if (remindMe !== undefined) return;
        dispatch(notificationsActions.getRemindMe());
    }, [remindMe, dispatch]);

    useEffect(() => {
        if (shouldLoad && notifications.length === 0) {
            setShouldLoad(false);
            dispatch(notificationsActions.loadNotifications());
        }
    });

    const filteredItems: INotificationsEvents[] = useMemo(() => {
        if (showAll) return notifications;
        if (notifications.length === 0) return [];
        if (notifications.length <= maxEvents) setShowAll(true);
        return notifications.slice(0, maxEvents);
    }, [notifications, showAll]);

    const counter: number = useMemo(() => {
        return notifications.filter(({ status }: INotificationsEvents) => status && status === "NEW").length;
    }, [notifications]);

    const onToggleSubscription = () => {
        if (remindMe !== undefined) {
            dispatch(notificationsActions.changeRemindMe(!remindMe));
        }
    };

    return (
        <Component
            items={filteredItems}
            count={counter}
            onClearAll={async () => {
                try {
                    await uploadNotifications(notifications.map((item) => ({ ...item, status: "HIDDEN" })));
                    dispatch(notificationsActions.deleteNotification(notifications));
                } catch (e) {
                    dispatch(
                        responseActions.showResponse({
                            type: ResponseConstants.ERROR,
                            responseMessage: ResponseMessageKeys.ERROR_NOTIFICATIONS,
                        })
                    );
                }
            }}
            onClearEach={async (e, item: INotificationsEvents) => {
                if (!item) return;
                try {
                    await uploadNotifications([{ ...item, status: "HIDDEN" }]);
                    dispatch(notificationsActions.deleteNotification([item]));
                } catch (e) {
                    dispatch(
                        responseActions.showResponse({
                            type: ResponseConstants.ERROR,
                            responseMessage: ResponseMessageKeys.ERROR_NOTIFICATIONS,
                        })
                    );
                }
            }}
            onMarkAsRead={async () => {
                try {
                    await uploadNotifications(
                        notifications.map((item) => {
                            if (item.status === "NEW") {
                                return { ...item, status: "READ" };
                            }
                            return item;
                        })
                    );
                    dispatch(notificationsActions.loadNotifications());
                } catch (e) {
                    dispatch(
                        responseActions.showResponse({
                            type: ResponseConstants.ERROR,
                            responseMessage: ResponseMessageKeys.ERROR_NOTIFICATIONS,
                        })
                    );
                }
            }}
            onShowMore={showAll ? undefined : () => setShowAll(true)}
            showReminderSubscription={!!email && email.indexOf("phase-6.org") === -1} // do not show for anonymous with phase-6.org mail adress
            remindMe={remindMe}
            onToggleSubscription={onToggleSubscription}
        />
    );
};
