import React from "react";
import { T } from "@transifex/react";

// Styles
import { WarningContainer } from "../styles";

// Types
import { SubjectAssignmentModalType } from "p6m-subjects";

//Components
import PhaseSixIcon from "../../../basic/phaseSixIcon/PhaseSixIcon";

interface ConsumptionTextProps {
    modalType: SubjectAssignmentModalType;
    isStrictMode?: boolean;
}

export const Warning = ({ modalType, isStrictMode = false }: ConsumptionTextProps) => {
    switch (modalType) {
        case "NewSubject": {
            if (!isStrictMode) return null;
            return (
                <WarningContainer>
                    <PhaseSixIcon name="info" />
                    <T
                        _str="You can download vocabulary collections for free within your school's contingent."
                        _tags="subject,modal"
                    />
                </WarningContainer>
            );
        }
        case "LoadedSelfCreatedContent":
        case "SelfCreatedContent": {
            return (
                <WarningContainer>
                    <PhaseSixIcon name="info" />
                    <T
                        _str="The cards in this subject were created by another user and are not subject to our control over the content."
                        _tags="subject,modal"
                    />
                </WarningContainer>
            );
        }
        case "TrialExpired": {
            return (
                <WarningContainer>
                    <PhaseSixIcon name="info" />
                    <T
                        _str="Please open the link once more after you purchased the book."
                        _tags="tests,modal"
                    />
                </WarningContainer>
            );
        }
        default: {
            return null;
        }
    }
};

export default Warning;
