import React from "react";
import { SubjectUnitCard } from "p6m-subjects";
import CardEntry from "../../basic/cardEntry/CardEntry";

interface ListRowProps {
    index: number;
    style: any;
    data: {
        allCardsSelected: boolean;
        selectedCardsIds: string[];
        toggleSingleCard: (cardId: string) => void;
        subjectId: string;
        cards: SubjectUnitCard[];
        units: Array<{ title: string; value?: string | number }>;
        refreshCardList: () => void;
    };
}

export const ListRow = ({ index, data, style }: ListRowProps) => {
    const card = data.cards[index];
    const cardUnit = data.units.find((unit) => card.unitIdToOwner.id === unit.value);
    const isSelected = data.allCardsSelected || data.selectedCardsIds.includes(card.cardIdToOwner.id);

    return (
        <div
            style={style}
            data-index={index}
            data-qa="card"
        >
            <CardEntry
                key={`card_${index}_${card.cardIdToOwner.id}`}
                cardId={card.cardIdToOwner.id}
                cardOwnerId={card.cardIdToOwner.ownerId}
                isSearchResult={false}
                isSelected={isSelected}
                normalIsActive={card.normal.active}
                normalWasActive={card.normal.wasActive}
                oppositeIsActive={card.opposite.active}
                oppositeWasActive={card.opposite.wasActive}
                normalPhase={card.normal.phase}
                oppositePhase={card.opposite.phase}
                contentQuestion={card.cardContent.question}
                contentAnswer={card.cardContent.answer}
                cardIsSwappable={card.cardContent.swappable}
                toggleCard={data.toggleSingleCard}
                unitName={cardUnit?.title || ""}
                unitId={cardUnit?.value?.toString()}
                subjectId={data.subjectId}
                refreshCardList={data.refreshCardList}
            />
        </div>
    );
};
