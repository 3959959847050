//DEPENDENCIES
import styled from "styled-components";
import { Container } from "../../components/basic/grid";

export const FullHeightContainer = styled(Container)`
    display: flex;
    flex-direction: column;
    overflow: hidden;
    height: 100%;
`;

export const ModalFooter = styled.div`
    padding: ${({ theme: { base } }) => base.spacing};
`;

export const ExplanationText = styled.p`
    padding: ${({ theme: { base } }) => base.spacing};
    color: ${({ theme: { colors } }) => colors.text};
`;
