import styled from "styled-components";
import PhaseSixIcon from "../../phaseSixIcon/PhaseSixIcon";

export const SelectedLang = styled.div`
    cursor: pointer;
    display: flex;
    align-items: center;
    word-break: break-word;
`;

export const MenuTitle = styled.h2`
    margin: 0;
    padding: 0;
    font-size: ${({ theme: { base } }) => base.size};
    font-weight: normal;
    color: ${({ theme: { colors } }) => colors.text};
`;

export const MenuWrapper = styled.ul`
    list-style: none;
    padding: 0;
    margin: 0 -${({ theme: { base } }) => base.spacingSmall};
    max-height: 190px;
    overflow-y: auto;
    white-space: nowrap;
    color: ${({ theme: { colors } }) => colors.text};
`;

export const MenuItem = styled.li<{ selected: boolean }>`
    display: block;
    position: relative;
    padding: ${({ theme: { base } }) => base.spacingInContent};
    padding-left: 32px;
    margin: 0;
    cursor: pointer;
    transition: ${({ theme: { base } }) => base.transition}s;

    ${({ theme: { colors }, selected }) =>
        selected
            ? `color: ${colors.primary};`
            : `> span {
                display: none;
            }`}

    &:hover {
        background-color: ${({ theme: { colors } }) => colors.textLight};
    }
`;

export const StyledPhaseSixIcon = styled(PhaseSixIcon)`
    position: absolute;
    top: ${({ theme: { base } }) => base.spacingTiny};
    left: ${({ theme: { base } }) => base.spacingTiny};
    color: inherit;
`;
