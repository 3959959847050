import React from "react";
import BG2048 from "../assets/img/bg_2048.jpg";
import BGMatch from "../assets/img/bg_match_game.jpg";
import { useT } from "@transifex/react";

export const GamesContext = React.createContext<
    {
        title: string;
        description: string;
        image: any;
        link: string;
        cost: number;
    }[]
>([]);

const GamesContextWrapper: React.FC = (props) => {
    const t = useT();
    const games = [
        {
            title: t("2048"),
            description: t("2048 game description"),
            image: BG2048,
            link: "/games/2048",
            cost: 1,
        },
        {
            title: t("Match game"),
            description: t("Match game description"),
            image: BGMatch,
            link: "/games/wordMatch",
            cost: 0,
        },
    ];

    return <GamesContext.Provider value={games}>{props.children}</GamesContext.Provider>;
};

export default GamesContextWrapper;
