// REACT
import React from "react";

// LIBRARIES
import { useT } from "@transifex/react/dist";

// COMPONENTS
import PhaseSixIcon from "../phaseSixIcon/PhaseSixIcon";

// STYLED COMPONENTS
import {
    Container,
    Header,
    Title,
    List,
    FamilyMemberEntry,
    FamilyMemberTitle,
    AddFamilyMember,
    AddFamilyMemberText,
} from "./styles";

// TYPES
import { FamilyMember } from "p6m-user";

export interface FamilyMemberSelectorProps {
    userDnsId?: string;
    familyMembers?: FamilyMember[];
    selectedFamilyMember?: FamilyMember;
    onFamilyMemberSelect?: (id: string | undefined) => void;
    onInviteChild?: () => void;
}

const ReportsSelector: React.FC<FamilyMemberSelectorProps> = (props) => {
    const t = useT();

    const t_myFamily = t("My Family", { _tags: "reports,selector,groups,title" });
    const t_inviteChild = t("Invite Child", { _tags: "reports,selector,groups,button" });
    const t_me = t("I", { _tags: "reports,selector,groups,button" });

    const renderFamilyMembers = () => {
        return props.familyMembers?.map((member) => {
            return (
                <FamilyMemberEntry
                    key={"familyMember_" + member.userId}
                    color={member.userId === props.selectedFamilyMember?.userId ? "primary" : ""}
                    onClick={() => props.onFamilyMemberSelect?.(member.userId)}
                >
                    <FamilyMemberTitle>{member.userId === props.userDnsId ? t_me : member.name}</FamilyMemberTitle>
                </FamilyMemberEntry>
            );
        });
    };

    return (
        <Container>
            <Header>
                <Title>{t_myFamily}</Title>
                <PhaseSixIcon name="family" />
            </Header>
            <List>{renderFamilyMembers()}</List>
            <AddFamilyMember onClick={props.onInviteChild}>
                <AddFamilyMemberText>{t_inviteChild}</AddFamilyMemberText>
                <PhaseSixIcon name={"add"} />
            </AddFamilyMember>
        </Container>
    );
};

export default ReportsSelector;
