// LIBRARIES
import React, { useMemo, useCallback } from "react";
import { T } from "@transifex/react";

// Components
import TestsHeader from "../TestsHeader/TestsHeader";
import { TestsList } from "./TestsList";

// TYPES
import { SubjectData } from "p6m-subjects";
import { TestListItemInfo, TestsType } from "p6m-tests";

// STYLES
import { TabMenu, Tab, TabText, Content, TestsPrimaryBtn as AddTestBtn, AddIcon, MainSection } from "../styles";

export interface TestsOverviewProps {
    overviewScreenType: TestsType;
    selectedSubject: SubjectData;
    ownTests: TestListItemInfo[];
    sharedTests: TestListItemInfo[];
    onChangeOverviewScreen: (type: TestsType) => void;
    onAddTest: () => void;
}

const TestsOverview: React.FC<TestsOverviewProps> = ({
    overviewScreenType,
    selectedSubject,
    ownTests,
    sharedTests,
    onChangeOverviewScreen,
    onAddTest,
}) => {
    const testListsByKey: { [key in TestsType]: TestListItemInfo[] } = useMemo(
        () => ({
            OWN_TESTS: ownTests || [],
            SHARED_TESTS: sharedTests || [],
        }),
        [ownTests, sharedTests]
    );

    const switchToOwnTest = useCallback(() => {
        onChangeOverviewScreen("OWN_TESTS");
    }, [onChangeOverviewScreen]);

    const switchToSharedTest = useCallback(() => {
        onChangeOverviewScreen("SHARED_TESTS");
    }, [onChangeOverviewScreen]);

    return (
        <>
            <TestsHeader selectedSubject={selectedSubject} />
            <MainSection>
                <TabMenu>
                    <Tab
                        key="OWN_TESTS"
                        selected={overviewScreenType === "OWN_TESTS"}
                        onClick={switchToOwnTest}
                    >
                        <TabText>
                            <T
                                _str="Tests"
                                _tags="tests,type,tab,button"
                            />
                        </TabText>
                    </Tab>
                    <Tab
                        key="SHARED_TESTS"
                        selected={overviewScreenType === "SHARED_TESTS"}
                        onClick={switchToSharedTest}
                    >
                        <TabText>
                            <T
                                _str="Teacher Exercises"
                                _tags="tests,type,tab,button"
                            />
                        </TabText>
                    </Tab>
                </TabMenu>
                <Content
                    fullHeight
                    className="content"
                >
                    <TestsList
                        testsType={overviewScreenType}
                        tests={testListsByKey[overviewScreenType]}
                    />
                </Content>
            </MainSection>

            {overviewScreenType === "OWN_TESTS" && (
                <AddTestBtn onClick={onAddTest}>
                    <AddIcon
                        size="standard"
                        name="add"
                    />
                    <T
                        _str="Add test"
                        _tags="tests,button"
                    />
                </AddTestBtn>
            )}
        </>
    );
};

export default TestsOverview;
