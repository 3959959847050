import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../store/store";
import { AvatarsGroups, Avatar } from "p6m-avatars";

const initialState: {
    groups: AvatarsGroups | false;
} = {
    groups: false,
};

export const avatarsSlice = createSlice({
    name: "avatars",
    initialState,
    reducers: {
        fetchAvatars: () => {},
        setAvatars: (state, { payload }: PayloadAction<AvatarsGroups>) => {
            state.groups = payload;
        },
        unsetAvatars: (state) => {
            state.groups = false;
        },
    },
});

export const { actions, reducer } = avatarsSlice;

export const selectors = {
    getAvatars: (state: RootState) => state.avatars.groups,
    getAvatarById:
        (avatarId?: string) =>
        (state: RootState): Avatar | undefined => {
            if (!avatarId) return undefined;
            const { groups = {} } = state.avatars;
            return Object.values(groups)
                .reduce((result, group) => {
                    const { userAvatars } = group;
                    return result.concat(userAvatars);
                }, [])
                .find((avatar: Avatar) => {
                    return avatarId === avatar.avatarId;
                });
        },
    getFilteredAvatars: (state: RootState) => {
        const { groups = {} } = state.avatars;
        if (groups === false) return false;
        const filteredGroups = Object.entries(groups)
            .filter(([, { isActive }]) => isActive)
            .filter(([, { isAvailable, isHidden }]) => !isHidden || isAvailable);

        return Object.fromEntries(filteredGroups);
    },
};
