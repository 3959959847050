// REACT
import React, { FunctionComponent, memo, useContext } from "react";
import { T } from "@transifex/react";

// TYPES
import { ResultActions } from "p6m-learning";

// COMPONENTS
import { Row, Col } from "../../../basic/grid";
import { OpenSettingsPopup } from "./OpenSettingsPopup";

// UTILS
import { PracticeContext } from "../../../../context/practice";

// STYLED COMPONENTS
import { ButtonColors } from "../../../basic/button/Button";
import { Button } from "../CardResult/styles";

type ButtonProps = {
    text: string;
    actionKey: ResultActions | "disabled";
    isLoading: boolean;
    type?: ButtonColors;
    callback: (actionKey: ResultActions) => void;
    "data-qa"?: string;
};

const BasicButton: FunctionComponent<ButtonProps> = memo((props) => {
    const { text, type, actionKey, isLoading, callback } = props;

    const isDisabled = actionKey === "disabled";
    const tabIndex = actionKey === "onGoNext" ? 1 : 2;

    const ButtonItem = ({ disabled }: { disabled: boolean }) => (
        <Button
            tabIndex={!isDisabled ? tabIndex : -1}
            colorType={type}
            disabled={disabled}
            onClick={() => {
                if (isDisabled || disabled) return;
                callback(actionKey as ResultActions);
            }}
            data-qa={props["data-qa"]}
        >
            <T _str={text} />
        </Button>
    );

    return isDisabled ? (
        <OpenSettingsPopup>
            <ButtonItem disabled />
        </OpenSettingsPopup>
    ) : (
        <ButtonItem disabled={isLoading} />
    );
});

export type Props = {
    buttonsIndex: number;
    isLoading: boolean;
    callback: ButtonProps["callback"];
};

const Buttons: FunctionComponent<Props> = (props) => {
    const practiceContext = useContext(PracticeContext);
    const { buttonsIndex, isLoading, callback } = props;

    const currentButtons = practiceContext.getButtonSet(buttonsIndex);

    return (
        <Row>
            {currentButtons.map(([text, type, actionKey]) => (
                <Col key={actionKey}>
                    <BasicButton
                        text={text}
                        type={type}
                        actionKey={actionKey}
                        callback={callback}
                        isLoading={isLoading}
                        data-qa={`practice-buttons ${actionKey}`}
                    />
                </Col>
            ))}
        </Row>
    );
};

export default memo(Buttons);
