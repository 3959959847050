import { useMemo } from "react";

import { useSelector } from "react-redux";
import { selectors } from "../redux/subjects/subjectsSlice";
import { SubjectUnitCard } from "p6m-subjects";

type GetUnitsNameListFromCards = {
    cards: SubjectUnitCard[];
    subjectId: string;
    //-1 means no limit
    displayedUnitsLimit?: number;
};

export const useUnitsInfoFromCards = ({
    cards,
    subjectId,
    displayedUnitsLimit = 3,
}: GetUnitsNameListFromCards): { unitNamesString: string; unitsCardsAmount: number } => {
    const allUnits = useSelector(selectors.getUnitsBySubjectId(subjectId));

    const [unitNamesList, unitsCardsAmount] = useMemo(() => {
        const selectedUnitsId = cards.map(({ unitIdToOwner: { id } }) => id);
        const selectedUnits = allUnits?.filter(({ unitId: { id } }) => selectedUnitsId.includes(id)) || [];

        const namesList = selectedUnits.map(({ unitContent: { name } }) => name);
        const cardsCount = selectedUnits.reduce((result, { cardCount }) => result + cardCount, 0);

        return [namesList, cardsCount];
    }, [allUnits, cards]);

    const unitNamesString = useMemo(() => {
        if (unitNamesList.length > displayedUnitsLimit && displayedUnitsLimit > -1) {
            const remainingUnitCount = unitNamesList.length - displayedUnitsLimit;
            const slicedUnitNames = unitNamesList.slice(0, displayedUnitsLimit);
            return slicedUnitNames.join(", ") + ", ... (" + remainingUnitCount + " more)";
        } else {
            return unitNamesList.join(", ");
        }
    }, [unitNamesList, displayedUnitsLimit]);

    return { unitNamesString, unitsCardsAmount };
};
