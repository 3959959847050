import styled from "styled-components";

export const Label = styled.div`
    padding-bottom: ${({ theme: { base } }) => base.spacingTiny};
    color: ${({ theme: { colors } }) => colors.text};
`;

export const InputRow = styled.div`
    margin: ${({ theme: { base } }) => base.spacingSmall};
`;

export const Input = styled.input<{ disabled?: boolean }>`
    height: 30px;
    width: 100%;
    padding: 0 5px;
    border-radius: 5px;
    background-color: transparent;
    border: 1px solid ${({ theme: { colors } }) => colors.textLight};
    font-size: 14px;
    line-height: 30px;
    color: ${({ theme: { colors } }) => colors.text};

    &:focus {
        outline: none;
    }

    ${({ disabled = false, theme: { colors } }) =>
        disabled &&
        `
        background-color: ${colors.textLight};
        cursor: default;
        &:focus {
            outline: none;
        }
    `}
`;

export const Textarea = styled(Input.withComponent("textarea"))`
    height: 100px;
`;
