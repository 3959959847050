import { takeEvery, call, put } from "redux-saga/effects";
import { actions } from "./avatarsSlice";
import { actions as appStatusActions } from "../appStatus/appStatusSlice";
import { actions as userAction } from "../user/userSlice";

import { getUserAvatars } from "../../networking/user";
import { AvatarGroupResponse, AvatarsGroups, AvatarsGroupsResponse } from "p6m-avatars";

function* getAvatars() {
    yield appStatusActions.setLoading(true);
    try {
        const response = yield call(getUserAvatars);

        if (response.data.httpCode === 401) {
            yield put(userAction.logout());
        } else {
            const {
                data: { replyContent },
            } = response;
            const avatarGroups: AvatarsGroupsResponse = replyContent.avatarGroups;

            const result: AvatarsGroups = {};

            Object.values(avatarGroups as AvatarsGroupsResponse)
                .sort((first, second) => first.sortOrder - second.sortOrder)
                .forEach((group: AvatarGroupResponse) => {
                    const { groupId, ruleResults: avatarRequirements, userAvatars } = group;

                    const requirementsWithRoundedProgress = avatarRequirements.map((requirement) => {
                        const { rulePercentProgress: requirementProgress } = requirement;
                        const progress = Math.round(requirementProgress);

                        return {
                            ...requirement,
                            progress,
                        };
                    });

                    const newAvatars = userAvatars
                        .sort((first, second) => first.sortOrder - second.sortOrder)
                        .map((avatar) => {
                            const { avatarMediaId } = avatar;
                            const link = process.env.REACT_APP_BACKEND_API_URL + "media/" + avatarMediaId;
                            return {
                                ...avatar,
                                link,
                            };
                        })
                        .sort((first, second) => {
                            return first.sortOrder - second.sortOrder;
                        });

                    result[groupId] = {
                        ...group,
                        name: getAvatarGroupName(groupId),
                        requirementInformation: requirementsWithRoundedProgress,
                        userAvatars: newAvatars,
                    };
                });

            yield put(actions.setAvatars(result));
        }
    } catch (e) {
        console.log(e);
    } finally {
        yield appStatusActions.setLoading(false);
    }
}

export function* avatarsSaga() {
    yield takeEvery(actions.fetchAvatars, getAvatars);
}

function getAvatarGroupName(groupId: string): string {
    const avatarGroupNames: { [key: string]: string } = {
        Mumie: "Mortified Mummy",
        Default: "Dull Default",
        Dog: "Smiling Shiba",
        Orange_Monster: "Sidney",
        DJ_Mumie: "DJ Dennis",
        Unicorn: "Usual Unicorn",
        Masked_man: "Mystery Man",
        Girl: "Powerful Pam",
        Zombie: "Relentless Rick",
        Star: "Social Star",
        Marshmallow: "Massive Marshmallow",
        Cat: "Cozy Cat",
        Corona_Man: "Tested Toni",
        Spider: "Timeless Tarantula",
        Leading_Lion: "Leading Lion",
        Enthusiastic_Elephant: "Enthusiastic Elephant",
        Challenging_Chick: "Challenging Chick",
        Lifesaving_Leaf: "Lifesaving Leaf",
        Sherlock_Sidney: "Sherlock Sidney",
    };

    return avatarGroupNames[groupId] || groupId;
}
