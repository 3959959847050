// REACT
import React from "react";

// REACT COMPONENTS
import {
    ChildrenContainer,
    DeactivatedText,
    DescriptionText,
    FlexTitleContainer,
    LockComponentWrapper,
    MainTitle,
    MainWrapper,
    StyledPhaseSixIcon,
} from "./styles";

export interface SettingsItemWrapperProps {
    title?: string;
    description?: string;
    children: React.ReactNode;
    className?: string;
    disabled?: boolean;
    disabledDescription?: string;
    lockComponent?: React.ReactNode;
}

const SettingsItemWrapper: React.FC<SettingsItemWrapperProps> = (props) => {
    let TopBar = null;

    if (props.title) {
        if (props.lockComponent) {
            TopBar = (
                <FlexTitleContainer>
                    <MainTitle>{props.title}</MainTitle>
                    <LockComponentWrapper>{props.lockComponent}</LockComponentWrapper>
                </FlexTitleContainer>
            );
        } else {
            TopBar = <MainTitle>{props.title}</MainTitle>;
        }
    }

    return (
        <MainWrapper {...props.className}>
            {TopBar}
            <ChildrenContainer className={props.disabled ? "disabled" : ""}>
                {props.children}
                {props.description && <DescriptionText>{props.description}</DescriptionText>}
            </ChildrenContainer>
            {props.disabled && props.disabledDescription && (
                <DeactivatedText>
                    <StyledPhaseSixIcon name={"lock"} />
                    {props.disabledDescription}
                </DeactivatedText>
            )}
        </MainWrapper>
    );
};

export default SettingsItemWrapper;
