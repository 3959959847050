// REACT
import React from "react";

// LIBRARIES
import { tx } from "@transifex/native";

// COMPONENTS
import SettingsLanguage from "./SettingsLanguage";

// REDUX
import { useDispatch, useSelector } from "react-redux";
import { actions as userActions, selectors as userSelectors } from "../../../redux/user/userSlice";

const SettingsLanguageView: React.FC = () => {
    const dispatch = useDispatch();

    let currentLanguage = tx.getCurrentLocale();
    const preferences = useSelector(userSelectors.userPreferences);

    let changeLanguageAction = function (lang: string) {
        if (lang === currentLanguage) {
            return;
        }

        const userPreferences = { ...preferences };
        userPreferences.lang = lang;
        dispatch(
            userActions.saveUserPreferences({ newPreferences: userPreferences, oldPreferences: { ...preferences } })
        );
    };

    return (
        <SettingsLanguage
            changeLanguageAction={changeLanguageAction}
            currentLanguage={currentLanguage}
            preferences={preferences}
        />
    );
};

export default SettingsLanguageView;
