import styled from "styled-components";
import MouseEventIcon from "../../basic/mouseEventIcon/MouseEventIcon";

export const MainWrapper = styled.div`
    margin-top: ${(props) => props.theme.base.spacing};
`;

export const ChildrenAnswerLockWrapper = styled.div`
    background: ${(props) => props.theme.colors.backgroundMain};
    padding: 2px;
    border-radius: 20px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &.highlighted {
        background: ${(props) => props.theme.colors.primary};

        svg {
            color: white;
        }
    }
`;

export const StyledSvgIcon = styled(MouseEventIcon)`
    width: 35px;
    margin: 0;
    padding: 0;
`;
