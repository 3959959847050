import React, { useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { selectors as userSelectors } from "../../../redux/user/userSlice";
import { selectors as avatarsSelectors, actions as avatarsActions } from "../../../redux/avatars/avatarsSlice";
import SettingsMainBody from "./SettingsMainBody";
import { SettingsView } from "../../../helpers/Modal";

export interface SettingsMainBodyViewProps {
    changeViewCallback: (view: SettingsView) => void;
    contentTitles: { [key in SettingsView]: string };
    logoutAction: () => any;
}

const SettingsMainBodyView: React.FC<SettingsMainBodyViewProps> = (props) => {
    const user = useSelector(userSelectors.user);
    const userPreferences = useSelector(userSelectors.userPreferences);

    const hasPremium = useSelector(userSelectors.userHasPremium);
    const userIsAdmin = useSelector(userSelectors.userIsGroupAdmin);
    const avatars = useSelector(avatarsSelectors.getAvatars);
    const userAvatarId = useSelector(userSelectors.getUserAvatarId);
    const userAvatar = useSelector(avatarsSelectors.getAvatarById(userAvatarId));
    const dispatch = useDispatch();

    useEffect(() => {
        if (avatars !== false) return;
        dispatch(avatarsActions.fetchAvatars());
    }, [avatars, dispatch]);

    const userAvatarLink = useMemo(() => {
        if (!userAvatar) return;

        return userAvatar.link;
    }, [userAvatar]);

    return (
        <SettingsMainBody
            userEmail={user.email ? user.email : ""}
            userPreferences={userPreferences}
            hasPremium={hasPremium}
            userIsAdmin={userIsAdmin}
            changeViewCallback={props.changeViewCallback}
            contentTitles={props.contentTitles}
            logoutAction={props.logoutAction}
            userName={user.firstName + " " + user.lastName}
            userAvatar={userAvatarLink}
        />
    );
};

export default SettingsMainBodyView;
