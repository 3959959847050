import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../store/store";

interface AppStatusState {
    showMaintenancePage: boolean;
    loadingCounter: number;
    backgroundLoadingCounter: number;
}

const initialState: AppStatusState = {
    showMaintenancePage: false,
    loadingCounter: 0,
    backgroundLoadingCounter: 0,
};

export const appStatusSlice = createSlice({
    name: "appStatus",
    initialState,
    reducers: {
        setMaintenance: (state, action: PayloadAction<boolean>) => {
            state.showMaintenancePage = action.payload;
        },
        setLoading: loadingReducerGenerator(),
        setBackgroundLoading: loadingReducerGenerator(true),
        forceLoading: (state, action: PayloadAction<boolean>) => {
            const { payload } = action;
            state.loadingCounter = +payload;
            state.backgroundLoadingCounter = 0;
        },
    },
});

/* EXPORTS */
export const { actions, reducer } = appStatusSlice;

export const selectors = {
    isLoading: (state: RootState) => state.appStatus.loadingCounter > 0,
    isBackgroundLoading: (state: RootState) => state.appStatus.backgroundLoadingCounter > 0,
    showMaintenancePage: (state: RootState) => state.appStatus.showMaintenancePage,
};

function loadingReducerGenerator(isBackgroundLoading?: boolean) {
    return function (state: AppStatusState, action: PayloadAction<boolean>) {
        const { payload } = action;
        const { loadingCounter } = state;
        const value: number = payload ? 1 : -1;
        const result = loadingCounter + value;
        state.loadingCounter = result < 0 ? 0 : result;

        if (state.loadingCounter <= 0) {
            state.backgroundLoadingCounter = 0;
            return;
        }

        if (!isBackgroundLoading) return;
        state.backgroundLoadingCounter = result < 0 ? 0 : result;
    };
}
