//DEPENDENCIES
import { useCallback, useState } from "react";
import { useDispatch } from "react-redux";

//TYPES
import { TestListItemInfo } from "p6m-tests";

// REDUX
import { actions, UpdateTestPayload } from "../redux/tests/testsSlice";

export const useTestActions = () => {
    const dispatch = useDispatch();

    const [showRenameModal, setShowRenameModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [renamePayload, setRenamePayload] = useState<UpdateTestPayload | null>(null);
    const [deletePayload, setDeletePayload] = useState<string | null>(null);

    //TEST-RENAMING-HANDLERS
    const handlePrepareTestRenaming = useCallback(
        ({ id, subjectContentId, cardIds, direction, name }: TestListItemInfo) => {
            setRenamePayload({
                testId: id,
                data: {
                    testName: name,
                    subjectContentId,
                    cardIds,
                    direction,
                },
            });
            setShowRenameModal(true);
        },
        []
    );

    const closeRenameModal = useCallback(() => {
        setShowRenameModal(false);
    }, []);

    const renameTest = useCallback(
        (testName: string) => {
            if (renamePayload) {
                const payload = {
                    ...renamePayload,
                    data: { ...renamePayload.data, testName: testName },
                };
                dispatch(actions.updateTest(payload));
            }
        },
        [dispatch, renamePayload]
    );

    //TEST-DELETION-HANDLERS
    const handlePrepareTestDeletion = useCallback((testId: string) => {
        setDeletePayload(testId);
        setShowDeleteModal(true);
    }, []);

    const closeDeleteModal = useCallback(() => {
        setShowDeleteModal(false);
    }, []);

    const deleteTest = useCallback(() => {
        deletePayload && dispatch(actions.deleteTest(deletePayload));
    }, [dispatch, deletePayload]);

    return {
        rename: {
            oldTestName: renamePayload?.data.testName ?? "",
            handlePrepareTestDeletion,
            showRenameModal,
            closeRenameModal,
            handlePrepareTestRenaming,
            renameTest,
        },
        delete: {
            showDeleteModal,
            closeDeleteModal,
            handlePrepareTestDeletion,
            deleteTest,
        },
    };
};
