// LIBRARIES
import React, {memo} from "react";
import styled from "styled-components";

const InvisibleIframe = styled.iframe`
  display: none;
`;

const LogoutIframe: React.FC = () => {
    const randomV = Math.random() * 1000000;
    const url = `${process.env.REACT_APP_BACKEND_OPEN_CMS_URL}josso_logout/?v=${randomV.toFixed(0)}`;
    return (
        <InvisibleIframe src={url}/>
    );
};

export default memo(LogoutIframe);
