import styled from "styled-components";

export const PremiumInfoTextWrapper = styled.p`
    margin: 0;
`;

export const PremiumTextWrapper = styled.span`
    color: ${(props) => props.theme.colors.textOnBackgroundHighlighted};

    @media (max-width: ${process.env.REACT_APP_LOWER_GRID_STEP_BORDER}px) {
        color: ${(props) => props.theme.colors.text};
    }
`;

export const PremiumInfoText = styled.span`
    color: ${(props) => props.theme.colors.textOnBackground};

    @media (max-width: ${process.env.REACT_APP_LOWER_GRID_STEP_BORDER}px) {
        color: ${(props) => props.theme.colors.text};
    }
`;
