// REACT
import React, { FunctionComponent, useState, useEffect, useContext } from "react";

// UTILS
import { ThemeContext } from "styled-components";
import { getScoreByTime, getScoreColor, getScoreIconName } from "../../../helpers/MatchGame";

// COMPONENTS
import { TIconNames } from "../phaseSixIcon/PhaseSixIcon";

// STYLED COMPONENTS
import { BestScoreWrapper, CustomColorPhaseSixIcon } from "../../complex/wordMatchGame/SubjectItem/styles";

export type Props = {
    time: number;
    inModal?: boolean;
};

const MatchGameScoreIcon: FunctionComponent<Props> = (props) => {
    const [scoreIconName, setScoreIconName] = useState<TIconNames>("clock");
    const [scoreValue, setScoreValue] = useState<number>(0);
    const [scoreColor, setScoreColor] = useState<string>("#fff");
    const theme = useContext(ThemeContext);

    useEffect(() => {
        const score = getScoreByTime(props.time);
        setScoreValue(score);
        setScoreIconName(getScoreIconName(score));
        setScoreColor(getScoreColor(score, theme));
    }, [props.time]);

    if (scoreValue === 5) {
        return (
            <BestScoreWrapper inModal={props.inModal}>
                <CustomColorPhaseSixIcon
                    name="star-full"
                    color={scoreColor}
                />
                <CustomColorPhaseSixIcon
                    name="cup"
                    size={props.inModal ? "big" : "medium"}
                    color={scoreColor}
                />
            </BestScoreWrapper>
        );
    }
    return (
        <CustomColorPhaseSixIcon
            name={scoreIconName}
            size={"medium"}
            color={scoreColor}
        />
    );
};

export default MatchGameScoreIcon;
