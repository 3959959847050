import styled from "styled-components";

export const Background = styled.div<{ bgImage?: any }>`
    position: relative;
    display: flex;
    flex: 1;
    width: 100%;
    align-self: stretch;
    justify-self: stretch;
    background: ${(props) => (props.bgImage ? "url(" + props.bgImage + ")" : props.theme.colors.backgroundMain)}
        no-repeat 0 0 fixed;
    background-size: 100% 100%;
`;
