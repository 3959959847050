// LIBRARIES
import React, { useCallback } from "react";
import { t } from "@transifex/native";
import { useDispatch, useSelector } from "react-redux";
import { ampli } from "../../../ampli";

//NETWORK
import { changePhase, deactivateCards, reactivateCards, setCardsDirection } from "../../../networking/cards";

//REDUX
import { actions as modalActions, selectors as modalSelectors } from "../../../redux/modal/modalSlice";

//TYPES
import { IdToOwnerId } from "p6m-subjects";
import { LEARNING_DIRECTION, PHASE_OPTION, ModalTypeKeymap, CardInfoForActionButtons } from "p6m-cards";

//COMPONENTS
import ChangeCardActivationModal from "./changeCardActivationModal/ChangeCardActivationModal";
import { actions as responseActions } from "../../../redux/response/responseSlice";
import { ResponseType } from "../../../constants/ResponseConstants";
import { GlobalModalView } from "../../../helpers/Modal";
import ChangeCardDirectionModal from "./changeCardDirectionModal/ChangeCardDirectionModal";
import ChangeCardPhaseStatusModal from "./changeCardPhaseStatusModal/ChangeCardPhaseStatusModal";

// HELPERS
import { isActivationElement, isLearningDirection, isPhaseOption } from "../../../helpers/TypeGuards";

const modalDetails: {
    [key in keyof ModalTypeKeymap]: React.FC<any>;
} = {
    ACTIVATION_STATUS: (props: any) => <ChangeCardActivationModal {...props} />,
    PHASE_OPTION: (props: any) => <ChangeCardPhaseStatusModal {...props} />,
    LEARNING_DIRECTION: (props: any) => <ChangeCardDirectionModal {...props} />,
};

const ModifyCardAttributeModalsWrapper: React.FC = () => {
    const dispatch = useDispatch();

    const t_DeactivatedCardsSuccessfully = t("Card(s) deactivated successfully");
    const t_ReactivatedCardsSuccessfully = t("Card(s) reactivated successfully");
    const t_ChangedCardsDirectionsSuccessfully = t("Changed learning direction successfully");
    const t_ChangedCardsPhasesSuccessfully = t("Card(s) moved successfully");
    const t_ErrorMessage = t("An error occurred");

    const {
        itemIds,
        modalType,
        changeOption,
        changeableCardCount,
        selectedCardCount,
        refreshCardList,
        cardDataById,
    }: {
        itemIds: IdToOwnerId[];
        modalType: keyof ModalTypeKeymap;
        changeOption: ModalTypeKeymap[keyof ModalTypeKeymap];
        changeableCardCount: number;
        selectedCardCount?: number;
        refreshCardList?: (initialValue?: number) => void;
        cardDataById: { [key: string]: CardInfoForActionButtons };
    } = useSelector(modalSelectors.data);

    const showError = useCallback(() => {
        dispatch(
            responseActions.showResponse({
                type: "ERROR",
                text: [t_ErrorMessage],
            })
        );
    }, [dispatch]);

    const close = useCallback(() => {
        dispatch(modalActions.setModalView(GlobalModalView.None));
    }, [dispatch]);

    const showResponsePopUp = useCallback(
        (type: ResponseType, message: string) => {
            dispatch(
                responseActions.showResponse({
                    type,
                    text: [message],
                })
            );
        },
        [dispatch]
    );

    const changeCardActivation = async (): Promise<void> => {
        if (!isActivationElement(changeOption)) {
            console.log("changeOption is not of type ACTIVATION_STATUS: ", changeOption);
            showError();
            return;
        }
        let success;
        let userAction: Parameters<(typeof ampli)["clickedEditCardsButton"]>[0]["user_action"] = "deactivateCards";
        if (changeOption === "DEACTIVATE") {
            const result = await deactivateCards(itemIds.map((card) => card.id));
            success = result.success;
        } else {
            const result = await reactivateCards(itemIds);
            success = result.every((reactivation) => reactivation.success);
            userAction = "reactiveCards";
        }

        if (!success) {
            showError();
            return;
        }

        ampli.clickedEditCardsButton({
            cards_number: itemIds.length,
            user_action: userAction,
        });

        showResponsePopUp(
            "SUCCESS",
            changeOption === "DEACTIVATE" ? t_DeactivatedCardsSuccessfully : t_ReactivatedCardsSuccessfully
        );
        refreshCardList && refreshCardList();
        close();
    };
    const changeCardDirection = async (): Promise<void> => {
        if (!isLearningDirection(changeOption)) {
            console.log("changeOption is not of type LEARNING_DIRECTION: ", changeOption);
            showError();
            return;
        }

        const cardIdsToChange = itemIds
            .map((card) => cardDataById[card.id])
            .filter((cardData): cardData is CardInfoForActionButtons => !!cardData)
            .filter((cardData) => {
                const {
                    normal: { active: normalIsActive },
                    opposite: { active: oppositeIsActive },
                } = cardData;

                const currentCardDirection =
                    normalIsActive && oppositeIsActive ? "BOTH" : normalIsActive ? "FORWARD" : "BACKWARD";

                return changeOption !== currentCardDirection;
            })
            .map((cardData) => cardData.idToOwnerId.id);

        const { success } = cardIdsToChange.length
            ? await setCardsDirection(cardIdsToChange, changeOption as LEARNING_DIRECTION)
            : { success: true };

        if (!success) {
            showError();
            return;
        } else {
            ampli.clickedEditCardsButton({
                cards_number: cardIdsToChange.length,
                user_action: "changeDirection",
            });
        }

        showResponsePopUp("SUCCESS", t_ChangedCardsDirectionsSuccessfully);
        refreshCardList && refreshCardList();
        close();
    };
    const changePhaseOfCards = async (): Promise<void> => {
        if (!isPhaseOption(changeOption)) {
            console.log("changeOption is not of type PHASE_OPTION: ", changeOption);
            showError();
            return;
        }
        const { success } = await changePhase(
            itemIds.map((card) => card.id),
            changeOption as PHASE_OPTION
        );
        if (!success) {
            showError();
            return;
        }

        const userAction: Parameters<(typeof ampli)["clickedEditCardsButton"]>[0]["user_action"] =
            changeOption === "RELEARN" ? "resetPhase" : "moveToLongTerm";
        ampli.clickedEditCardsButton({
            cards_number: itemIds.length,
            user_action: userAction,
        });

        showResponsePopUp("SUCCESS", t_ChangedCardsPhasesSuccessfully);
        refreshCardList && refreshCardList();
        close();
    };

    const changeCardOptions: { [modal in keyof ModalTypeKeymap]: () => void } = {
        ACTIVATION_STATUS: changeCardActivation,
        LEARNING_DIRECTION: changeCardDirection,
        PHASE_OPTION: changePhaseOfCards,
    };

    return modalDetails[modalType]({
        changeableCardCount: changeableCardCount,
        selectedCardCount: selectedCardCount,
        close: close,
        onClick: changeCardOptions[modalType],
    });
};

export default ModifyCardAttributeModalsWrapper;
