// REACT
import React from "react";
import { useMediaQuery } from "react-responsive";

// COMPONENTS
import Modal from "../../basic/modal/Modal";

// TYPES
import { TIconNames } from "../../basic/phaseSixIcon/PhaseSixIcon";

// STYLED COMPONENTS
import {
    StyledExternalLinkContainer,
    StyledIframe,
    StyledLinkContainer,
    StyledLinkWrapper,
    StyledModalTriggerContainer,
    Text,
    UserMenuIcon,
} from "./styles";

export interface UserMenuEntryProps {
    title: string;
    icon?: TIconNames;
    svgIcon?: React.FC;
    navTarget?: string;
    external?: boolean;
    modal?: boolean;
    showModal?: boolean;
    modalTitle?: string;
    modalInnerComponent?: React.ReactNode;
    onClickAction?: (showModal?: boolean | any) => any;
    userDefinedIconColor?: string;
    disableHover?: boolean;
    headerComponent?: boolean;
    provideModalActions?: boolean;
    modalBodyStyles?: any;
    disabled?: boolean;
}

const UserMenuEntry: React.FC<UserMenuEntryProps> = (props) => {
    const { disabled = false } = props;
    let icon = null;

    let isP6Icon = !!props.icon;
    let isSvgIcon = !!props.svgIcon;

    if (isP6Icon || isSvgIcon) {
        icon = (
            <UserMenuIcon
                {...props}
                isActive={false}
            />
        );
    }

    // Media query for the mobile rendering
    const lowerBorderQuery = "(max-width: " + process.env.REACT_APP_LOWER_GRID_STEP_BORDER + "px)";
    let isMobileView: Boolean = useMediaQuery({
        query: lowerBorderQuery,
    });

    const { title, modal, disableHover, navTarget, external } = props;

    if (modal && !disabled) {
        const { onClickAction = () => {} } = props;

        return (
            <StyledLinkWrapper
                className={disableHover ? "no-hover" : "aaaaa"}
                data-qa={`user-menu-dropdown_menu-item`}
                disableHover={disableHover}
            >
                <StyledModalTriggerContainer
                    className={`${isMobileView ? "reverse-direction" : ""}`}
                    onClick={() => onClickAction(true)}
                >
                    {icon}
                    <Text>{title}</Text>
                </StyledModalTriggerContainer>
                {props.showModal && (
                    <Modal
                        style={{ padding: 0, height: `100%` }}
                        title={props.modalTitle}
                        active={!!props.showModal}
                        provideActions={props.provideModalActions}
                        modalBodyStyle={props.modalBodyStyles}
                        headerComponent={props.headerComponent === false ? false : undefined}
                        afterClose={() => onClickAction(false)}
                    >
                        {external && navTarget && <StyledIframe src={navTarget} />}
                        {props.modalInnerComponent}
                    </Modal>
                )}
            </StyledLinkWrapper>
        );
    } else if (navTarget && !disabled) {
        if (external) {
            return (
                <StyledLinkWrapper
                    className={disableHover ? "no-hover" : ""}
                    data-qa={`user-menu-dropdown_menu-item`}
                >
                    <StyledExternalLinkContainer
                        href={navTarget!}
                        target={"_blank"}
                        onClick={props.onClickAction}
                        className={`${isMobileView ? "reverse-direction" : ""}`}
                    >
                        {icon}
                        <Text>{title}</Text>
                    </StyledExternalLinkContainer>
                </StyledLinkWrapper>
            );
        } else {
            return (
                <StyledLinkWrapper
                    className={disableHover ? "no-hover" : ""}
                    data-qa={`user-menu-dropdown_menu-item`}
                >
                    <StyledLinkContainer
                        to={navTarget!}
                        onClick={props.onClickAction}
                        className={`${isMobileView ? "reverse-direction" : ""}`}
                    >
                        {icon}
                        <Text>{title}</Text>
                    </StyledLinkContainer>
                </StyledLinkWrapper>
            );
        }
    } else {
        return (
            <StyledLinkWrapper
                className={disableHover ? "no-hover" : ""}
                data-qa={`user-menu-dropdown_menu-item`}
            >
                <StyledModalTriggerContainer
                    className={`${isMobileView ? "reverse-direction" : ""}`}
                    onClick={props.onClickAction}
                    disabled={disabled}
                >
                    {icon}
                    <Text>{title}</Text>
                </StyledModalTriggerContainer>
            </StyledLinkWrapper>
        );
    }
};

export default UserMenuEntry;
