export type CharContent = {
    name: string;
    alias: string;
    chars: string[];
    upperChars: string[];
};

export const charsList: { [key: string]: CharContent } = {
    af: {
        name: "Afrikaans",
        alias: "af",
        //chars: [ '\u00e9', '\u00e8', '\u00eb', '\u00ea', '\u00ef', '\u00ee', '\u0149', '\u00f4', '\u00fb' ],
        chars: ["é", "è", "ë", "ê", "î", "ï", "ô", "û", "ŉ"],
        //upperChars: [ '\u00C9', '\u00C8', '\u00CB', '\u00CA', '\u00CF', '\u00CE', '\u0149', '\u00D4', '\u00DB' ]
        upperChars: ["È", "É", "Ê", "Ë", "Î", "Ï", "Ô", "Û", "ŉ"],
    },
    da: {
        name: "Danish",
        alias: "da",
        //chars: [ '\u00e5', '\u00f8', '\u00e6' ],
        chars: ["å", "æ", "ø"],
        //upperChars: [ '\u00C5', '\u00D8', '\u00C6' ]
        upperChars: ["Å", "Æ", "Ø"],
    },
    de: {
        name: "German",
        alias: "de",
        //chars: [ '\u00e4', '\u00f6', '\u00fc', '\u00df' ],
        chars: ["ß", "ä", "ö", "ü"],
        //upperChars: [ '\u00C4', '\u00D6', '\u00DC', '\u00df' ]
        upperChars: ["ß", "Ä", "Ö", "Ü"],
    },
    eo: {
        name: "Esperanto",
        alias: "eo",
        //chars: [ '\u0109', '\u011d', '\u0125', '\u0135', '\u015d', '\u016d' ],
        chars: ["ĉ", "ĝ", "ĥ", "ĵ", "ŝ", "ŭ"],
        //upperChars: [ '\u0108', '\u011C', '\u0124', '\u0134', '\u015C', '\u016C' ]
        upperChars: ["Ĉ", "Ĝ", "Ĥ", "Ĵ", "Ŝ", "Ŭ"],
    },
    fr: {
        name: "French",
        alias: "fr",
        //chars: [ '\u00e0', '\u00e1', '\u00e2', '\u00e6', '\u00e7', '\u00e8', '\u00e9', '\u00ea', '\u00eb', '\u00ee', '\u00ef', '\u00f4', '\u0153', '\u00f9', '\u00fb', '\u00ff', '\u00bb', '\u00ab', '\u203a', '\u2039' ],
        chars: ["à", "á", "â", "æ", "ç", "è", "é", "ê", "ë", "î", "ï", "ô", "ù", "û", "ÿ", "œ"],
        //upperChars: [ '\u00C0', '\u00C1', '\u00C2', '\u00C6', '\u00C7', '\u00C8', '\u00C9', '\u00CA', '\u00CB', '\u00CE', '\u00CF', '\u00D4', '\u0152', '\u00D9', '\u00DB', '\u0178', '\u00bb', '\u00ab', '\u203a', '\u2039' ]
        upperChars: ["À", "Á", "Â", "Æ", "Ç", "È", "É", "Ê", "Ë", "Î", "Ï", "Ô", "Ù", "Û", "Ÿ", "Œ"],
    },
    greek: {
        name: "Greek Symbols",
        alias: "greek",
        //chars: [ '\u03b1', '\u03b2', '\u03b3', '\u03b4', '\u03b5', '\u03b6', '\u03b7', '\u03b8', '\u03b9', '\u03ba', '\u03bb', '\u03bc', '\u03bd', '\u03be', '\u03bf', '\u03c0', '\u03c1', '\u03c3', '\u03c2', '\u03c4', '\u03c5', '\u03c6', '\u03c7', '\u03c8', '\u03c9', '\u03d1', '\u03d2', '\u03d5', '\u03d6' ],
        chars: [
            "α",
            "β",
            "γ",
            "δ",
            "ε",
            "ζ",
            "η",
            "θ",
            "ι",
            "κ",
            "λ",
            "μ",
            "ν",
            "ξ",
            "ο",
            "π",
            "ρ",
            "ς",
            "σ",
            "τ",
            "υ",
            "φ",
            "χ",
            "ψ",
            "ω",
            "ϑ",
            "ϒ",
            "ϕ",
            "ϖ",
        ],
        //upperChars: [ '\u0391', '\u0392', '\u0393', '\u0394', '\u0395', '\u0396', '\u0397', '\u0398', '\u0399', '\u039A', '\u039B', '\u039C', '\u039D', '\u039E', '\u039F', '\u03A0', '\u03A1', '\u03A3', '\u03A3', '\u03A4', '\u03A5', '\u03A6', '\u03A7', '\u03A8', '\u03A9', '\u0398', '\u03D2', '\u03A6', '\u03A0' ]
        upperChars: [
            "Α",
            "Β",
            "Γ",
            "Δ",
            "Ε",
            "Ζ",
            "Η",
            "Θ",
            "Ι",
            "Κ",
            "Λ",
            "Μ",
            "Ν",
            "Ξ",
            "Ο",
            "Π",
            "Ρ",
            "Σ",
            "Σ",
            "Τ",
            "Υ",
            "Φ",
            "Χ",
            "Ψ",
            "Ω",
            "Θ",
            "ϒ",
            "Φ",
            "Π",
        ],
    },
    is: {
        name: "Icelandic",
        alias: "is",
        //chars: [ '\u00fd', '\u00f0', '\u00fe', '\u00e6' ],
        chars: ["æ", "ð", "ý", "þ"],
        //upperChars: [ '\u00DD', '\u00D0', '\u00DE', '\u00C6' ]
        upperChars: ["Æ", "Ð", "Ý", "Þ"],
    },
    it: {
        name: "Italian",
        alias: "it",
        //chars: [ '\u00e0', '\u00e8', '\u00e9', '\u00ec', '\u00ed', '\u00ef', '\u00f2', '\u00f3', '\u00f9', '\u00fa' ],
        chars: ["à", "è", "é", "ì", "í", "ï", "ò", "ó", "ù", "ú"],
        //upperChars: [ '\u00C0', '\u00C8', '\u00C9', '\u00CC', '\u00CD', '\u00CF', '\u00D2', '\u00D3', '\u00D9', '\u00DA' ]
        upperChars: ["À", "È", "É", "Ì", "Í", "Ï", "Ò", "Ó", "Ù", "Ú"],
    },
    hr: {
        name: "Croatian",
        alias: "hr",
        //chars: [ '\u0107', '\u010d', '\u0111', '\u0161', '\u017e' ],
        chars: ["ć", "č", "đ", "š", "ž"],
        //upperChars: [ '\u0106', '\u010C', '\u0110', '\u0160', '\u017D' ]
        upperChars: ["Ć", "Č", "Đ", "Š", "Ž"],
    },
    ru: {
        name: "Cyrillic Symbols",
        alias: "ru",
        //chars: [ '\u0430', '\u0431', '\u0432', '\u0433', \u0491, '\u0434', '\u0435', '\u0451', '\u0436', '\u0437', '\u0438', '\u0439', '\u043A', '\u043B', '\u043C', '\u043D', '\u043E', '\u043F', '\u0440', '\u0441', '\u0442', '\u0443', '\u0444', '\u0445', '\u0446', '\u0447', '\u0448', '\u0449', '\u044A', '\u044B', '\u044C', '\u044D', '\u044E', '\u044F', '\u0456', '\u0457', '\u0454' ],
        chars: [
            "а",
            "б",
            "в",
            "г",
            "ґ",
            "д",
            "е",
            "ё",
            "є",
            "ж",
            "з",
            "и",
            "і",
            "ї",
            "й",
            "к",
            "л",
            "м",
            "н",
            "о",
            "п",
            "р",
            "с",
            "т",
            "у",
            "ф",
            "х",
            "ц",
            "ч",
            "ш",
            "щ",
            "ъ",
            "ы",
            "ь",
            "э",
            "ю",
            "я",
        ],
        //upperChars: [ '\u0410', '\u0411', '\u0412', '\u0413', \u0490, '\u0414', '\u0415', '\u0401', '\u0416', '\u0417', '\u0418', '\u0419', '\u041A', '\u041B', '\u041C', '\u041D', '\u041E', '\u041F', '\u0420', '\u0421', '\u0422', '\u0423', '\u0424', '\u0425', '\u0426', '\u0427', '\u0428', '\u0429', '\u042A', '\u042B', '\u042C', '\u042D', '\u042E', '\u042F', '\u0406', '\u0407', '\u0404' ]
        upperChars: [
            "А",
            "Б",
            "В",
            "Г",
            "Ґ",
            "Д",
            "Е",
            "Ё",
            "Є",
            "Ж",
            "З",
            "И",
            "І",
            "Ї",
            "Й",
            "К",
            "Л",
            "М",
            "Н",
            "О",
            "П",
            "Р",
            "С",
            "Т",
            "У",
            "Ф",
            "Х",
            "Ц",
            "Ч",
            "Ш",
            "Щ",
            "Ъ",
            "Ы",
            "Ь",
            "Э",
            "Ю",
            "Я",
        ],
    },
    cyrillica: {
        name: "Cyrillic vowels with accents",
        alias: "cyrillica",
        //chars: [ '\u0430', '\u0301', '\u0435', '\u0451', '\u0438', '\u043E', '\u0443', '\u044B', '\u044D', '\u044E', '\u044F', '\u0456', '\u0457', '\u0454', '\u044A' ],
        chars: ["\u0301", "а", "е", "ё", "є", "и", "і", "ї", "о", "у", "ъ", "ы", "э", "ю", "я"],
        //upperChars: [ '\u0410', '\u0301', '\u0415', '\u0401', '\u0418', '\u041E', '\u0423', '\u042B', '\u042D', '\u042E', '\u042F', '\u0406', '\u0407', '\u0404', '\u042A' ]
        upperChars: ["\u0301", "А", "Ё", "Е", "Є", "И", "І", "Ї", "О", "У", "Ъ", "Ы", "Э", "Ю", "Я"],
    },
    la: {
        name: "Latin",
        alias: "la",
        //chars: [ '\u0101', '\u0113', '\u012b', '\u014d', '\u016b', '\u0103', '\u0115', '\u012d', '\u014f', '\u016d', '\u0301', '\u1e17', '\u1e53', '\u0300', '\u1e15', '\u1e51' ],
        chars: ["ā", "ă", "ē", "ĕ", "ī", "ĭ", "ō", "ŏ", "ū", "ŭ", "ḕ", "ḗ", "ṑ", "ṓ", "\u0300", "\u0301"],
        //upperChars: [ '\u0100', '\u0112', '\u012A', '\u014C', '\u016A', '\u0102', '\u0114', '\u012C', '\u014E', '\u016C', '\u0301', '\u1E16', '\u1E52', '\u0300', '\u1E14', '\u1E50' ]
        upperChars: ["Ā", "Ă", "Ē", "Ĕ", "Ī", "Ĭ", "Ō", "Ŏ", "Ū", "Ŭ", "Ḕ", "Ḗ", "Ṑ", "Ṓ", "\u0300", "\u0301"],
    },
    lv: {
        name: "Latvian",
        alias: "lv",
        //chars: [ '\u0101', '\u010d', '\u0113', '\u0123', '\u012b', '\u0137', '\u013c', '\u0146', '\u014d', '\u0157', '\u0161', '\u016b', '\u017e' ],
        chars: ["ā", "č", "ē", "ģ", "ī", "ķ", "ļ", "ņ", "ō", "ŗ", "š", "ū", "ž"],
        //upperChars: [ '\u0100', '\u010C', '\u0112', '\u0122', '\u012A', '\u0136', '\u013B', '\u0145', '\u014C', '\u0156', '\u0160', '\u016A', '\u017D' ]
        upperChars: ["Ā", "Č", "Ē", "Ģ", "Ī", "Ķ", "Ļ", "Ņ", "Ō", "Ŗ", "Š", "Ū", "Ž"],
    },
    math1: {
        name: "Math Symbols",
        alias: "math1",
        //chars: [ '\u00b1', '\u00f7', '\u03bc', '\u03c0', '\u2115', '\u211d', '\u2124', '\u21d0', '\u21d2', '\u21d4', '\u2200', '\u2203', '\u2208', '\u2209', '\u2211', '\u221a', '\u221e', '\u2229', '\u222A', '\u222b', '\u2248', '\u2260', '\u2263', '\u2264', '\u2265', '\u2282', '\u2286', '\u22c0', '\u22c1', '\u30fb' ],
        chars: [
            "±",
            "÷",
            "μ",
            "π",
            "ℕ",
            "ℝ",
            "ℤ",
            "⇐",
            "⇒",
            "⇔",
            "∀",
            "∃",
            "∈",
            "∉",
            "∑",
            "√",
            "∞",
            "∩",
            "∪",
            "∫",
            "≈",
            "≠",
            "≣",
            "≤",
            "≥",
            "⊂",
            "⊆",
            "⋀",
            "⋁",
            "・",
        ],
        //upperChars: [ '\u00b1', '\u00f7', '\u039C', '\u03A0', '\u2115', '\u211d', '\u2124', '\u21d0', '\u21d2', '\u21d4', '\u2200', '\u2203', '\u2208', '\u2209', '\u2211', '\u221a', '\u221e', '\u2229', '\u222A', '\u222b', '\u2248', '\u2260', '\u2263', '\u2264', '\u2265', '\u2282', '\u2286', '\u22c0', '\u22c1', '\u30fb' ]
        upperChars: [
            "±",
            "÷",
            "Μ",
            "Π",
            "ℕ",
            "ℝ",
            "ℤ",
            "⇐",
            "⇒",
            "⇔",
            "∀",
            "∃",
            "∈",
            "∉",
            "∑",
            "√",
            "∞",
            "∩",
            "∪",
            "∫",
            "≈",
            "≠",
            "≣",
            "≤",
            "≥",
            "⊂",
            "⊆",
            "⋀",
            "⋁",
            "・",
        ],
    },
    no: {
        name: "Norwegian",
        alias: "no",
        //chars: [ '\u00e5', '\u00f8', '\u00e6', '\u00df' ],
        chars: ["ß", "å", "æ", "ø"],
        //upperChars: [ '\u00C5', '\u00D8', '\u00C6', '\u00df' ]
        upperChars: ["ß", "Å", "Æ", "Ø"],
    },
    zh: {
        name: "Pinyin Voval",
        alias: "zh",
        //chars: [ '\u0101', '\u00e1', '\u01ce', '\u00e0', '\u0113', '\u00e9', '\u011b', '\u00e8', '\u012b', '\u00ed', '\u01d0', '\u00ec', '\u014d', '\u00f3', '\u01d2', '\u00f2', '\u016b', '\u00fa', '\u01d4', '\u00f9', '\u01d6', '\u01d8', '\u01da', '\u01dc' ],
        chars: [
            "à",
            "á",
            "ā",
            "ǎ",
            "ē",
            "ě",
            "è",
            "é",
            "ì",
            "í",
            "ī",
            "ǐ",
            "ō",
            "ò",
            "ó",
            "ǒ",
            "ù",
            "ú",
            "ǔ",
            "ǖ",
            "ǘ",
            "ǚ",
            "ǜ",
            "ū",
        ],
        //upperChars: [ '\u0100', '\u00C1', '\u01CD', '\u00C0', '\u0112', '\u00C9', '\u011A', '\u00C8', '\u012A', '\u00CD', '\u01CF', '\u00CC', '\u014C', '\u00D3', '\u01D1', '\u00D2', '\u016A', '\u00DA', '\u01D3', '\u00D9', '\u01D5', '\u01D7', '\u01D9', '\u01DB' ]
        upperChars: [
            "À",
            "Á",
            "Ā",
            "Ǎ",
            "Ē",
            "Ě",
            "È",
            "É",
            "Ì",
            "Í",
            "Ī",
            "Ǐ",
            "Ō",
            "Ò",
            "Ó",
            "Ǒ",
            "Ù",
            "Ú",
            "Ǔ",
            "Ǖ",
            "Ǘ",
            "Ǚ",
            "Ǜ",
            "Ū",
        ],
    },
    pl: {
        name: "Polish",
        alias: "pl",
        //chars: [ '\u0105', '\u0107', '\u0119', '\u0142', '\u0144', '\u00f3', '\u015b', '\u017a', '\u017c' ],
        chars: ["ó", "ą", "ć", "ę", "ł", "ń", "ś", "ź", "ż"],
        //upperChars: [ '\u0104', '\u0106', '\u0118', '\u0141', '\u0143', '\u00D3', '\u015A', '\u0179', '\u017B' ]
        upperChars: ["Ó", "Ą", "Ć", "Ę", "Ł", "Ń", "Ś", "Ź", "Ż"],
    },
    pt: {
        name: "Portuguese",
        alias: "pt",
        //chars: [ '\u00e2', '\u00e3', '\u00e7', '\u00ea', '\u00f3', '\u00f4', '\u00f5', '\u00fc' ],
        chars: ["á", "â", "ã", "à", "ç", "é", "ê", "í", "ó", "ô", "õ", "ü", "ú"],
        //upperChars: [ '\u00C2', '\u00C3', '\u00C7', '\u00CA', '\u00D3', '\u00D4', '\u00D5', '\u00DC' ]
        upperChars: ["Á", "Â", "Ã", "À", "Ç", "É", "Ê", "Í", "Ó", "Ô", "Õ", "Ü", "Ú"],
    },
    sv: {
        name: "Swedish",
        alias: "sv",
        //chars: [ '\u00e5' ],
        chars: ["å", "ä", "ö"],
        //upperChars: [ '\u00C5' ]
        upperChars: ["Å", "Ä", "Ö"],
    },
    special: {
        name: "Special chars",
        alias: "special",
        //chars: [ '\u00a3', '\u00a5', '\u00a9', '\u00ae', '\u2122', '\u2020', '\u2030', '\u2026' ],
        chars: ["£", "¥", "©", "®", "†", "…", "‰", "™"],
        //upperChars: [ '\u00a3', '\u00a5', '\u00a9', '\u00ae', '\u2122', '\u2020', '\u2030', '\u2026' ]
        upperChars: ["£", "¥", "©", "®", "†", "…", "‰", "™"],
    },
    es: {
        name: "Spanish",
        alias: "es",
        //chars: [ '\u00e1', '\u00e9', '\u00ed', '\u00f3', '\u00fa', '\u00f1', '\u00a1', '\u00bf', '\u00fc', '\u00e7', '\u00aa', '\u00ba' ],
        chars: ["¡", "ª", "º", "¿", "á", "ç", "é", "í", "ñ", "ó", "ú", "ü"],
        //upperChars: [ '\u00C1', '\u00C9', '\u00CD', '\u00D3', '\u00DA', '\u00D1', '\u00a1', '\u00bf', '\u00DC', '\u00C7', '\u00aa', '\u00ba' ]
        upperChars: ["¡", "ª", "º", "¿", "Á", "Ç", "É", "Í", "Ñ", "Ó", "Ú", "Ü"],
    },
    cs: {
        name: "Czech",
        alias: "cs",
        //chars: [ '\u010d', '\u010f', '\u011b', '\u0148', '\u0159', '\u0161', '\u0165', '\u016f', '\u00fd', '\u017e' ],
        chars: ["á", "č", "ď", "é", "ě", "í", "ň", "ó", "ř", "š", "ť", "ú", "ů", "ý", "ž"],
        //upperChars: [ '\u010C', '\u010E', '\u011A', '\u0147', '\u0158', '\u0160', '\u0164', '\u016E', '\u00DD', '\u017D' ]
        upperChars: ["Á", "Č", "Ď", "É", "Ě", "Í", "Ň", "Ó", "Ř", "Š", "Ť", "Ú", "Ů", "Ý", "Ž"],
    },
    tr: {
        name: "Turkish",
        alias: "tr",
        //chars: [ '\u00e7', '\u011f', '\u00f6', '\u015f', '\u00fc', '\u0131', '\u0069' ],
        chars: ["â", "ç", "ğ", "ı", "î", "ö", "ş", "ü", "û"],
        //upperChars: [ '\u00C7', '\u011E', '\u00D6', '\u015E', '\u00DC', '\u0049', '\u0130' ]
        upperChars: ["Â", "Ç", "Ğ", "İ", "Î", "Ö", "Ş", "Ü", "Û"],
    },
    hu: {
        name: "Hungarian",
        alias: "hu",
        //chars: [ '\u0151', '\u0171', '\u00e1', '\u00fa', '\u00e9', '\u00f3', '\u00ed' ],
        chars: ["á", "é", "í", "ó", "ö", "ő", "ú", "ü", "ű"],
        //upperChars: [ '\u0150', '\u0170', '\u00C1', '\u00DA', '\u00C9', '\u00D3', '\u00CD' ]
        upperChars: ["Á", "É", "Í", "Ó", "Ö", "Ő", "Ú", "Ü", "Ű"],
    },
    currency: {
        name: "Currency Symbols",
        alias: "currency",
        //chars: [ '\u060b', '\u0e3f', '\u20b5', '\u00a2', '\u20a1', '\u0024', '\u20ab', '\u20ac', '\u20b2', '\u20b4', '\u17db', '\u20ad', '\u0192', '\u20a4', '\u20a6', '\u20b1', '\u00a3', '\ufdfc', '\u20a8', '\u0bf9', '\u0af1', '\u09f3', '\u09f2', '\u20aa', '\u20ae', '\u20a9', '\u00a5', '\u5143', '\u5713' ],
        chars: [
            "$",
            "¢",
            "£",
            "¥",
            "ƒ",
            "؋",
            "৲",
            "৳",
            "૱",
            "௹",
            "฿",
            "៛",
            "₡",
            "₤",
            "₦",
            "₨",
            "₩",
            "₪",
            "₫",
            "€",
            "₭",
            "₮",
            "₱",
            "₲",
            "₴",
            "₵",
            "元",
            "圓",
            "﷼",
        ],
        //upperChars: [ '\u060b', '\u0e3f', '\u20b5', '\u00a2', '\u20a1', '\u0024', '\u20ab', '\u20ac', '\u20b2', '\u20b4', '\u17db', '\u20ad', '\u0191', '\u20a4', '\u20a6', '\u20b1', '\u00a3', '\ufdfc', '\u20a8', '\u0bf9', '\u0af1', '\u09f3', '\u09f2', '\u20aa', '\u20ae', '\u20a9', '\u00a5', '\u5143', '\u5713' ]
        upperChars: [
            "$",
            "¢",
            "£",
            "¥",
            "Ƒ",
            "؋",
            "৲",
            "৳",
            "૱",
            "௹",
            "฿",
            "៛",
            "₡",
            "₤",
            "₦",
            "₨",
            "₩",
            "₪",
            "₫",
            "€",
            "₭",
            "₮",
            "₱",
            "₲",
            "₴",
            "₵",
            "元",
            "圓",
            "﷼",
        ],
    },
};
