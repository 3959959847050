import React, { FunctionComponent, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { selectors, actions } from "../../../redux/user/userSlice";
import { actions as goalsActions, selectors as goalsSelectors } from "../../../redux/goals/goalsSlice";
import { Link } from "react-router-dom";
import { ampli } from "../../../ampli";
import { T } from "@transifex/react";
import LoadScripts from "../../../helpers/DynamicScriptLoading";
import { Container, Row, Col } from "../../../components/basic/grid";
import { Background, Title, SecondaryTitle, BackIconContainer } from "../styles";
import Template from "./Game2048Template";
import PhaseSixIcon from "../../../components/basic/phaseSixIcon/PhaseSixIcon";

const lib = [
    "bind_polyfill.js",
    "classlist_polyfill.js",
    "animframe_polyfill.js",
    "keyboard_input_manager.js",
    "html_actuator.js",
    "grid.js",
    "tile.js",
    "local_storage_manager.js",
    "game_manager.js",
].map((path: string) => `lib-2048/${path}`);

export const Game2048: FunctionComponent = () => {
    const userStars = useSelector(selectors.userStars);
    const userGoalsData = useSelector(goalsSelectors.goals);
    const practiced50Cards = userGoalsData?.goals.practiced50Cards;

    const [loading, setLoading] = useState(true);
    const history = useHistory();
    const dispatch = useDispatch();

    const { starsCount = 0 } = userStars || { starsCount: 0 };

    useEffect(() => {
        ampli.gamePlayedPxp({
            game: "2048",
        });
    }, []);

    useEffect(() => {
        if (!userGoalsData) {
            dispatch(goalsActions.fetchGoals());
            history.push("/games");
            return;
        }
        if (practiced50Cards) return;
        history.push("/home");
    }, [practiced50Cards, userGoalsData, dispatch, history]);

    useEffect(() => {
        if (!starsCount) {
            history.push("/games");
        }
    }, [starsCount, history]);

    useEffect(() => {
        if (!userGoalsData) return;
        if (!starsCount) return;
        LoadScripts(lib)
            .then(() => {
                // @ts-ignore
                new GameManager(4, KeyboardInputManager, HTMLActuator, LocalStorageManager);
            })
            .catch((e: any) => console.log(e))
            .finally(() => setLoading(false));
    }, [starsCount, userGoalsData]);

    const onRestart = () => {
        dispatch(actions.fetchRemoveUserStar());
    };

    return (
        <Container fullWidth>
            <Background>
                {loading && <h2>Loading</h2>}
                <Row>
                    <Col xs="auto">
                        <BackIconContainer>
                            <Link to="/games">
                                <PhaseSixIcon name="arrow-left" />
                            </Link>
                        </BackIconContainer>
                    </Col>
                    <Col xs="auto">
                        <Title>2048</Title>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <SecondaryTitle>
                            <T _str="Schiebe gleiche Zahlen zusammen und verbessere deinen Rekord!" />
                        </SecondaryTitle>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <Template onRestart={onRestart} />
                    </Col>
                </Row>
            </Background>
        </Container>
    );
};

export default Game2048;
