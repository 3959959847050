import styled from "styled-components";

type TCoordinates = {
    top: number;
    left: number;
    width: number;
    height: number;
};

export const ChildrenWrapper = styled.span<{ fullWidth?: boolean }>`
    ${({ fullWidth = false }) =>
        fullWidth
            ? `
                display: block;
                width: 100%;
            `
            : "display: inline-block;"}
`;

export const PopupPortal = styled.div<TCoordinates>`
    position: absolute;
    z-index: ${({ theme: { base } }) => base.modalZIndex};
    ${({ top, left, width, height }) =>
        Object.entries({ top, left, width, height })
            .map(([key, value]) => `${key}: ${value}px;`)
            .join("\n")}
`;

export const PopupBody = styled.div<{ visible: boolean; position: string[]; arrow: boolean; hasSetWidth: boolean }>`
    position: absolute;
    left: 50%;
    top: 0;
    z-index: 3;
    margin: 10px 0;
    padding: ${({ theme: { base } }) => base.spacingSmall};
    border-radius: ${({ theme: { base } }) => base.borderRadius};
    background-color: ${({ theme: { colors } }) => colors.backgroundContrast};
    box-shadow: 0 2px 10px ${({ theme: { colors } }) => colors.shadow};
    font-size: ${({ theme: { base } }) => base.size}px;
    opacity: ${({ visible }) => +visible};
    transition: opacity 0.3s;
    width: ${({ hasSetWidth }) => (hasSetWidth ? "inherit" : "")};

    ${({ position = [] }) =>
        position.reduce((result: string, val: string) => {
            switch (val) {
                case "top":
                    return (
                        result +
                        `
                top: auto;
                bottom: 100%;
            `
                    );
                case "bottom":
                    return (
                        result +
                        `
                top: 100%;
            `
                    );
                case "center":
                    return (
                        result +
                        `
                transform: translateX(-50%);
            `
                    );
                case "right":
                    return (
                        result +
                        `
                left: auto;
                right: -10px;
            `
                    );
                case "left":
                    return (
                        result +
                        `
                left: 0px;
            `
                    );
                default:
                    return result;
            }
        }, "")}

    ${({ arrow, position = [], theme: { base, colors } }) =>
        arrow
            ? `
        &:before {
            content: ' ';
            position: absolute;
            z-index: 2;
            border: solid transparent;
            height: 0;
            width: 0;
            border-width: ${base.borderRadiusSmall};
            ${position.reduce((result: string, val: string) => {
                switch (val) {
                    case "top":
                        return (
                            result +
                            `
                        top: 100%;
                        border-top-color: ${colors.backgroundContrast};
                    `
                        );
                    case "bottom":
                        return (
                            result +
                            `
                        bottom: 100%;
                        border-bottom-color: ${colors.backgroundContrast};
                    `
                        );
                    case "center":
                        return (
                            result +
                            `
                        left: 50%;
                        transform: translateX(-50%);
                    `
                        );
                    case "right":
                        return (
                            result +
                            `
                        right: 10px;
                    `
                        );
                    case "left":
                        return (
                            result +
                            `
                        left: 10px;
                    `
                        );
                    default:
                        return result;
                }
            }, "")}
        }
    `
            : ""}
`;

export const PopupContainer = styled.div`
    box-sizing: border-box;
    padding: ${({ theme: { base } }) => base.spacingSmall};
    margin-left: -${({ theme: { base } }) => base.spacingSmall};
    margin-right: -${({ theme: { base } }) => base.spacingSmall};
    &:first-child {
        margin-top: -${({ theme: { base } }) => base.spacingSmall};
    }
    &:last-child {
        margin-bottom: -${({ theme: { base } }) => base.spacingSmall};
    }

    &:not(:last-child) {
        border-bottom: 1px solid ${({ theme: { colors } }) => colors.border};
    }
`;
