//LIBRARIES
import React, { useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { useT } from "@transifex/react";

//UTILS
import { useGetSearchParams } from "../../../hooks/useGetSearchParams";

//NETWORK
import { getJossoInfoLoginData } from "../../../networking/user";

//REDUX
import { actions as loginActions } from "../../../redux/login/loginSlice";
import { actions as responseActions } from "../../../redux/response/responseSlice";
import { actions as testsActions } from "../../../redux/tests/testsSlice";

//COMPONENTS
import Loading from "../../../components/connected/loading";

//ANALYTICS
import { ampli } from "../../../ampli";

const SSO: React.FC = () => {
    const t = useT();
    const { jossoId, email } = useParams<{ jossoId: string; email?: string }>();
    const { redirectUrl, testId, subjectId, rid: teacherId, cardsCount } = useGetSearchParams();

    const dispatch = useDispatch();
    const history = useHistory();

    const t_loginFailed = t("Failed to recognize your user. Please login!", { _tags: "login,alert,message" });

    const getLoginData = useCallback(async () => {
        const loginData = await getJossoInfoLoginData(jossoId, email);

        if (loginData.data.httpCode !== 200) {
            dispatch(responseActions.showResponse({ type: "ERROR", text: [t_loginFailed] }));
            history.push("/login");
        } else {
            const sharedTestMetaDataIsComplete = !!testId && !!subjectId && !!teacherId && !!cardsCount;
            if (sharedTestMetaDataIsComplete) {
                dispatch(testsActions.setSharedTestMetaData({ testId, subjectId, teacherId, cardsCount }));
                ampli.openedExercise({
                    test_origin: "teacher",
                    exercise_id: testId,
                });
            }
            dispatch(loginActions.loginWithSSOData({ data: loginData.data, history: history, redirectUrl }));
        }
    }, [jossoId, email, dispatch, history, redirectUrl, t_loginFailed, testId, subjectId, teacherId, cardsCount]);

    useEffect(() => {
        getLoginData();
    }, [getLoginData]);

    return <Loading />;
};

export default SSO;
