import styled from "styled-components";

export const Wrapper = styled.div`
    flex: 2;
    position: relative;
    background-color: ${(props) => props.theme.colors.backgroundContrast};
    padding: ${(props) => props.theme.base.spacing};
    border-radius: ${(props) => props.theme.base.borderRadiusBig};
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    @media (max-width: ${process.env.REACT_APP_UPPER_GRID_STEP_BORDER}px) {
        flex: 3;
    }
`;

export const Badge = styled.span`
    position: absolute;
    top: 0;
    right: 0;
    padding: ${(props) => props.theme.base.spacingTiny} ${(props) => props.theme.base.spacing};
    border-radius: 0 ${(props) => props.theme.base.borderRadiusBig};
    font-size: ${(props) => props.theme.base.size};
    font-weight: ${(props) => props.theme.base.bigWeight};
    display: inline-flex;
    color: ${(props) => props.theme.buttons.primary.textColor};
    background: linear-gradient(90deg, ${(props) => props.theme.colors.primary} 23.88%, #fca24c 100%);
`;

export const Img = styled.img`
    display: flex;
    margin: 0 auto;
`;

export const Title = styled.h4`
    color: ${(props) => props.theme.colors.text};
    text-align: center;
    font-weight: ${(props) => props.theme.base.weight};
    font-size: ${(props) => props.theme.base.mediumSize};
    margin: ${(props) => props.theme.base.spacingBig} 0;
`;

export const GrammarTutorButton = styled.button`
    width: 100%;
    padding: ${({ theme: { base } }) => `${base.size} ${base.spacing}`};
    background: ${({ theme: { colors } }) => colors.primary};
    border: 0;
    border-radius: ${({ theme: { base } }) => base.borderRadiusBig};
    font-size: ${({ theme: { base } }) => base.bigSize};
    font-weight: ${(props) => props.theme.base.bigWeight};
    color: ${({ theme: { colors } }) => colors.backgroundContrast};
    cursor: pointer;
    opacity: 1;
    display: inline-flex;
    justify-content: center;
    align-items: center;
`;
