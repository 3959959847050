import styled from "styled-components";
import { Row, Col } from "../../../basic/grid";

export const Card = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 2;
    width: 100%;
    height: 100%;
    padding: ${({ theme: { base } }) => base.spacingSmall};
    background-color: ${({ theme: { colors } }) => colors.backgroundContrast};
    border-radius: ${({ theme: { base } }) => base.borderRadiusBig};
    border: 3px solid ${({ theme: { colors } }) => colors.backgroundMain};
`;

export const CardContentRow = styled(Row)`
    height: 100%;
    overflow: hidden;
`;

export const CardContentCol = styled(Col)`
    max-height: 100%;
    overflow-y: auto;
`;

export const Flag = styled.img`
    height: 32px;
    border-radius: ${({ theme: { base } }) => base.borderRadiusSmall};
    vertical-align: middle;
`;
