//DEPENDENCIES
import React, { useMemo } from "react";
import { T } from "@transifex/react";

//UTILS
import { LeaderboardTimespan } from "../helpers/LeaderboardTooltip";

//TYPES
import { LeaderboardTabs, LeaderboardToolTipByTabAndTimespan } from "p6m-user";

export const useLeaderboardTooltips = ({
    participantsAmount,
    learnedCardsAmount,
    position,
    selectedTab,
    selectedTimespan,
}: {
    participantsAmount: string;
    learnedCardsAmount: string;
    position: string;
    selectedTab: LeaderboardTabs;
    selectedTimespan: LeaderboardTimespan;
}) => {
    const { LAST_WEEK, THIS_WEEK, SINCE_2021 } = LeaderboardTimespan;

    //TOOLTIPRESOLVER: ACTIVER USER AMOUNT
    const participantToolTipByTabAndTimespan: LeaderboardToolTipByTabAndTimespan = useMemo(
        () => ({
            all: {
                [LAST_WEEK]: (
                    <T
                        _str="There were {amount} participants last week"
                        amount={participantsAmount}
                    />
                ),
                [THIS_WEEK]: (
                    <T
                        _str="There are {amount} participants this week"
                        amount={participantsAmount}
                    />
                ),
                [SINCE_2021]: (
                    <T
                        _str="There are {amount} participants since 2021"
                        amount={participantsAmount}
                    />
                ),
            },
            mySchool: {
                [LAST_WEEK]: (
                    <T
                        _str="There were {amount} participants in your school last week"
                        amount={participantsAmount}
                    />
                ),
                [THIS_WEEK]: (
                    <T
                        _str="There are {amount} participants in your school this week"
                        amount={participantsAmount}
                    />
                ),
                [SINCE_2021]: (
                    <T
                        _str="There are {amount} participants in your school since 2021"
                        amount={participantsAmount}
                    />
                ),
            },
            allSchools: {
                [LAST_WEEK]: (
                    <T
                        _str="{amount} school(s) participated last week"
                        amount={participantsAmount}
                    />
                ),
                [THIS_WEEK]: (
                    <T
                        _str="{amount} school(s) participated this week"
                        amount={participantsAmount}
                    />
                ),
                [SINCE_2021]: (
                    <T
                        _str="{amount} school(s) participated since 2021"
                        amount={participantsAmount}
                    />
                ),
            },
            favourites: {
                [LAST_WEEK]: (
                    <T
                        _str="{amount} of your favourite school(s) participated last week"
                        amount={participantsAmount}
                    />
                ),
                [THIS_WEEK]: (
                    <T
                        _str="{amount} of your favourite school(s) participated this week"
                        amount={participantsAmount}
                    />
                ),
                [SINCE_2021]: (
                    <T
                        _str="{amount} of your favourite school(s) participated since 2021"
                        amount={participantsAmount}
                    />
                ),
            },
            family: {
                [LAST_WEEK]: (
                    <T
                        _str="There were {amount} participants from your family last week"
                        amount={participantsAmount}
                    />
                ),
                [THIS_WEEK]: (
                    <T
                        _str="There are {amount} participants from your family this week"
                        amount={participantsAmount}
                    />
                ),
                [SINCE_2021]: (
                    <T
                        _str="There are {amount} participants from your family since 2021"
                        amount={participantsAmount}
                    />
                ),
            },
        }),
        [participantsAmount]
    );

    //TOOLTIPRESOLVER: POSITION
    const positionToolTipByTabAndTimespanForInactivePosition: LeaderboardToolTipByTabAndTimespan = useMemo(
        () => ({
            all: {
                [LAST_WEEK]: <T _str="You had no activity last week" />,
                [THIS_WEEK]: <T _str="You have no activity this week" />,
                [SINCE_2021]: <T _str="You have no activity since 2021" />,
            },
            mySchool: {
                [LAST_WEEK]: <T _str="You had no activity last week" />,
                [THIS_WEEK]: <T _str="You have no activity this week" />,
                [SINCE_2021]: <T _str="You have no activity since 2021" />,
            },
            allSchools: {
                [LAST_WEEK]: <T _str="Your school had no activity last week" />,
                [THIS_WEEK]: <T _str="Your school has no activity this week" />,
                [SINCE_2021]: <T _str="Your school has no activity since 2021" />,
            },
            favourites: {
                [LAST_WEEK]: <T _str="Your school had no activity last week" />,
                [THIS_WEEK]: <T _str="Your school has no activity this week" />,
                [SINCE_2021]: <T _str="Your school has no activity since 2021" />,
            },
            family: {
                [LAST_WEEK]: <T _str="Your family had no activity last week" />,
                [THIS_WEEK]: <T _str="Your family has no activity this week" />,
                [SINCE_2021]: <T _str="Your family has no activity since 2021" />,
            },
        }),
        []
    );
    const positionToolTipByTabAndTimespanForActivePosition: LeaderboardToolTipByTabAndTimespan = useMemo(
        () => ({
            all: {
                [LAST_WEEK]: (
                    <T
                        _str="Among all active users you were in position {position} last week"
                        position={position}
                    />
                ),
                [THIS_WEEK]: (
                    <T
                        _str="Among all active users you are in position {position} this week"
                        position={position}
                    />
                ),
                [SINCE_2021]: (
                    <T
                        _str="Among all active users you are in position {position} since 2021"
                        position={position}
                    />
                ),
            },
            mySchool: {
                [LAST_WEEK]: (
                    <T
                        _str="You were in position {position} in your school last week"
                        position={position}
                    />
                ),
                [THIS_WEEK]: (
                    <T
                        _str="You are in position {position} in your school this week"
                        position={position}
                    />
                ),
                [SINCE_2021]: (
                    <T
                        _str="You are in position {position} in your school since 2021"
                        position={position}
                    />
                ),
            },
            allSchools: {
                [LAST_WEEK]: (
                    <T
                        _str="Among all active schools your school was in position {position} last week"
                        position={position}
                    />
                ),
                [THIS_WEEK]: (
                    <T
                        _str="Among all active schools your school is in position {position} this week"
                        position={position}
                    />
                ),
                [SINCE_2021]: (
                    <T
                        _str="Among all active schools your school is in position {position} since 2021"
                        position={position}
                    />
                ),
            },
            favourites: {
                [LAST_WEEK]: (
                    <T
                        _str="Among all active schools your school was in position {position} last week"
                        position={position}
                    />
                ),
                [THIS_WEEK]: (
                    <T
                        _str="Among all active schools your school is in position {position} this week"
                        position={position}
                    />
                ),
                [SINCE_2021]: (
                    <T
                        _str="Among all active schools your school is in position {position} since 2021"
                        position={position}
                    />
                ),
            },
            family: {
                [LAST_WEEK]: (
                    <T
                        _str="Among your family you were in position {position} last week"
                        position={position}
                    />
                ),
                [THIS_WEEK]: (
                    <T
                        _str="Among your family you are in position {position} this week"
                        position={position}
                    />
                ),
                [SINCE_2021]: (
                    <T
                        _str="Among your family you are in position {position} since 2021"
                        position={position}
                    />
                ),
            },
        }),
        [position]
    );
    const positionToolTipByTabAndTimespan: LeaderboardToolTipByTabAndTimespan = useMemo(
        () =>
            position === "-"
                ? positionToolTipByTabAndTimespanForInactivePosition
                : positionToolTipByTabAndTimespanForActivePosition,
        [positionToolTipByTabAndTimespanForActivePosition, positionToolTipByTabAndTimespanForInactivePosition]
    );

    //TOOLTIPRESOLVER: LEARNED CARDS AMOUNT
    const learnedCardsToolTipByTabAndTimespan: LeaderboardToolTipByTabAndTimespan = useMemo(
        () => ({
            all: {
                [LAST_WEEK]: (
                    <T
                        _str="Cards learned last week (overall): {amount}"
                        amount={learnedCardsAmount}
                    />
                ),
                [THIS_WEEK]: (
                    <T
                        _str="Cards learned this week (overall): {amount}"
                        amount={learnedCardsAmount}
                    />
                ),
                [SINCE_2021]: (
                    <T
                        _str="Cards learned since 2021 (overall): {amount}"
                        amount={learnedCardsAmount}
                    />
                ),
            },
            mySchool: {
                [LAST_WEEK]: (
                    <T
                        _str="In your school {amount} cards have been learned last week"
                        amount={learnedCardsAmount}
                    />
                ),
                [THIS_WEEK]: (
                    <T
                        _str="In your school {amount} cards have been learned this week"
                        amount={learnedCardsAmount}
                    />
                ),
                [SINCE_2021]: (
                    <T
                        _str="In your school {amount} cards have been learned since 2021"
                        amount={learnedCardsAmount}
                    />
                ),
            },
            allSchools: {
                [LAST_WEEK]: (
                    <T
                        _str="Across all schools {amount} cards have been learned last week"
                        amount={learnedCardsAmount}
                    />
                ),
                [THIS_WEEK]: (
                    <T
                        _str="Across all schools {amount} cards have been learned this week"
                        amount={learnedCardsAmount}
                    />
                ),
                [SINCE_2021]: (
                    <T
                        _str="Across all schools {amount} cards have been learned since 2021"
                        amount={learnedCardsAmount}
                    />
                ),
            },
            favourites: {
                [LAST_WEEK]: (
                    <T
                        _str="Among your favourite school(s) {amount} cards have been learned last week"
                        amount={learnedCardsAmount}
                    />
                ),
                [THIS_WEEK]: (
                    <T
                        _str="Among your favourite school(s) {amount} cards have been learned this week"
                        amount={learnedCardsAmount}
                    />
                ),
                [SINCE_2021]: (
                    <T
                        _str="Among your favourite school(s) {amount} cards have been learned since 2021"
                        amount={learnedCardsAmount}
                    />
                ),
            },
            family: {
                [LAST_WEEK]: (
                    <T
                        _str="In your family {amount} cards have been learned last week"
                        amount={learnedCardsAmount}
                    />
                ),
                [THIS_WEEK]: (
                    <T
                        _str="In your family {amount} cards have been learned this week"
                        amount={learnedCardsAmount}
                    />
                ),
                [SINCE_2021]: (
                    <T
                        _str="In your family {amount} cards have been learned since 2021"
                        amount={learnedCardsAmount}
                    />
                ),
            },
        }),
        [learnedCardsAmount]
    );

    return {
        ParticipiantsTooltip: participantToolTipByTabAndTimespan[selectedTab][selectedTimespan],
        LearnedCardsTooltip: learnedCardsToolTipByTabAndTimespan[selectedTab][selectedTimespan],
        GoToPositionTooltip: positionToolTipByTabAndTimespan[selectedTab][selectedTimespan],
    };
};
