// LIBRARIES
import React, { useCallback, useMemo } from "react";
import { T } from "@transifex/react";
import { orderBy } from "lodash";

// TYPES
import { SubjectData } from "p6m-subjects";
import { TestListItemInfo } from "p6m-tests";
import { TestResultsData } from "../../../networking/tests";

// Components
import TestsHeader from "../TestsHeader/TestsHeader";
import { TestResultListItem } from "./TestResultListItem";
import { TestListItem } from "../TestListItem/TestListItem";
import Tooltip from "../../../components/basic/tooltip/Tooltip";

// HOOKS
import { useTestActions } from "../../../hooks/useTestActions";

// STYLES
import {
    Content,
    TestsBtnContainer,
    TestsPrimaryBtn as StartPracticeBtn,
    TestsSecondaryBtn as GoBackBtn,
    TestsListContainer,
    MainSection,
    ToolTipWrapper,
} from "../styles";
import { RenameTestModal } from "../modals/RenameTestModal";
import { DeleteTestModal } from "../modals/DeleteTestModal";

export interface TestDetailsProps {
    selectedSubject: SubjectData;
    testInfo: TestListItemInfo;
    testResults: TestResultsData;
    onGoBack: () => void;
    onStartPractice: () => void;
    userHasPremium?: boolean;
}

const TestDetails: React.FC<TestDetailsProps> = ({
    selectedSubject,
    testInfo: test,
    testResults,
    onGoBack,
    onStartPractice,
    userHasPremium = false,
}) => {
    const {
        delete: { showDeleteModal, closeDeleteModal, handlePrepareTestDeletion, deleteTest },
        rename: { oldTestName, showRenameModal, closeRenameModal, handlePrepareTestRenaming, renameTest },
    } = useTestActions();

    const testResultsReduced = useMemo(() => testResults.objects.map((result) => result[1]), [testResults]);

    const sortedTestResults = useMemo(
        () => orderBy(testResultsReduced, ["modificationDate"], ["desc"]),
        [testResultsReduced]
    );

    const handleTestDeletion = useCallback(() => {
        deleteTest();
        onGoBack();
    }, [deleteTest, onGoBack]);

    return (
        <>
            <TestsHeader selectedSubject={selectedSubject} />

            <MainSection hideBackgroundColor>
                <Content>
                    <TestsListContainer>
                        <TestListItem
                            id={test.id}
                            test={test}
                            onDelete={handlePrepareTestDeletion}
                            onRename={handlePrepareTestRenaming}
                        />
                    </TestsListContainer>
                    <RenameTestModal
                        oldName={oldTestName}
                        active={showRenameModal}
                        onClose={closeRenameModal}
                        onSubmit={renameTest}
                    />

                    <DeleteTestModal
                        active={showDeleteModal}
                        onClose={closeDeleteModal}
                        onSubmit={handleTestDeletion}
                    />
                </Content>

                <Content fullHeight>
                    <TestsListContainer>
                        {sortedTestResults.map((result, index) => (
                            <TestResultListItem
                                key={index}
                                resultDetails={result}
                            />
                        ))}
                    </TestsListContainer>
                </Content>
            </MainSection>

            <TestsBtnContainer>
                <GoBackBtn onClick={onGoBack}>
                    <T
                        _str="Back"
                        _tags="tests,button"
                    />
                </GoBackBtn>
                <Tooltip
                    content={
                        <ToolTipWrapper>
                            <T
                                _str="You need PLUS to use this feature."
                                _tags="tooltip"
                            />
                        </ToolTipWrapper>
                    }
                    position="top"
                    showTooltip={!userHasPremium}
                >
                    <StartPracticeBtn
                        onClick={onStartPractice}
                        disabled={!userHasPremium}
                    >
                        <T
                            _str="Start practice"
                            _tags="tests,button"
                        />
                    </StartPracticeBtn>
                </Tooltip>
            </TestsBtnContainer>
        </>
    );
};

export default TestDetails;
