import { useMemo } from "react";
import { useLocation } from "react-router-dom";

export function useGetSearchParams () {
    const location = useLocation();

    return useMemo(() => {
        const search = new URLSearchParams(location.search);
        return Object.fromEntries(search.entries());
    }, [location]);
}