import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../store/store";
import { IUserGoals, GoalKeys } from "p6m-goalsData";
import { selectors as userSelectors } from "../../redux/user/userSlice";

const initialState: {
    value?: IUserGoals;
} = {};

export const goalsSlice = createSlice({
    name: "goals",
    initialState,
    reducers: {
        fetchGoals: () => {},
        setGoals: (state, action: PayloadAction<IUserGoals>) => {
            state.value = action.payload;
        },
        clear: (state) => (state.value = undefined),
        finishFirstPractice: () => {},
    },
});

export const { actions, reducer } = goalsSlice;

export const selectors = {
    goals: (state: RootState) => state.goals.value,
    currentStreak: (state: RootState) => state.goals?.value?.currentDaysInAStreak,
    allMainGoalsReached: getAllMainGoalsReached,
};

export const goalsToShowDerivedSelector = createSelector(
    [
        selectors.goals,
        selectors.allMainGoalsReached,
        userSelectors.userHasPremium,
        userSelectors.isInRole("student"),
        userSelectors.isInRole("over16"), // earlier rule name stating 16 instead of today's 18
        userSelectors.isInRole("beforegdpr"),
    ],
    (goalsState, allMainGoalsReached, hasPremium, isStudent, isOverEighteen, isBeforeGDPR): Array<GoalKeys> => {
        const parentHandlesGDPR = isStudent && !isOverEighteen;
        if (goalsState && goalsState.goals) {
            return (Object.keys(goalsState.goals) as GoalKeys[]).filter((goalKey) => {
                //thx Pascal
                if (goalKey === "friendsReferedCount") return false;
                if (goalKey === "numberOfFinishedGtTopics") return false; // not used, so should not be shown
                if (goalKey === "friendRefered") {
                    return allMainGoalsReached;
                }
                if (goalKey === "dictionaryUsed") {
                    return hasPremium;
                }
                if (goalKey === "gdprConfirmedByParent") {
                    return parentHandlesGDPR && !isBeforeGDPR && goalsState.goals["gdprConfirmedByParent"];
                }
                if (goalKey === "gdprSentToParent") {
                    return parentHandlesGDPR && !goalsState.goals["gdprConfirmedByParent"];
                }
                return true;
            });
        }
        return [];
    }
);
export const goalsToReachDerivedSelector = createSelector(
    [selectors.goals, goalsToShowDerivedSelector],
    (userGoals, goalsToShow) => {
        if (userGoals && userGoals.goals) {
            return goalsToShow.reduce((acc, goalToShow) => {
                if (userGoals.goals[goalToShow]) return acc;
                return ++acc;
            }, 0);
        }
        return undefined;
    }
);

function getAllMainGoalsReached(state: RootState): boolean {
    const userGoalsData = selectors.goals(state);
    const userHasPremium = userSelectors.userHasPremium(state);
    if (!userGoalsData) return false;
    const {
        goals: {
            firstPracticeFinished,
            learned3DaysInRow,
            practiced50Cards,
            learned7DaysIn10DaysPeriod,
            confirmed,
            dictionaryUsed,
            gdprConfirmedByParent,
            gdprSentToParent,
        },
    } = userGoalsData;

    let result = [firstPracticeFinished, learned3DaysInRow, practiced50Cards, learned7DaysIn10DaysPeriod, confirmed];
    if (userHasPremium) result.push(dictionaryUsed);
    if (gdprConfirmedByParent) result.push(gdprSentToParent);

    return !result.map((goal) => !!goal).includes(false);
}
