// LIBRARIES
import React, { memo } from "react";
import { useSelector } from "react-redux";

// REDUX
import { selectors as appStatusSelectors } from "../../../redux/appStatus/appStatusSlice";

// COMPONENTS
import Loading from "./Loading";

const LoadingView: React.FC = (props) => {
    const isLoading = useSelector(appStatusSelectors.isLoading);
    const isBackgroundLoading = useSelector(appStatusSelectors.isBackgroundLoading);

    return isLoading && !isBackgroundLoading ? <Loading {...props} /> : null;
};

export default memo(LoadingView);
