import { useCallback } from "react";
import { useDispatch } from "react-redux";

//REDUX
import { actions as responseActions } from "../../redux/response/responseSlice";

//TYPES
import { ResponseMessageKeys } from "../../components/connected/response";

export enum DROPDOWN_CONTENT_TYPES {
    UNIT = "UNIT",
    SUBJECT = "SUBJECT",
}

export enum DROPDOWN_EDIT_TYPES {
    CREATION = "CREATION",
    UPDATE = "UPDATE",
    DELETION = "DELETION",
}

export enum EDIT_RESPONSE_TYPES {
    SUCCESS = "SUCCESS",
    ERROR = "ERROR",
}

const getResponseMessageKey: Record<
    DROPDOWN_CONTENT_TYPES,
    {
        [key in DROPDOWN_EDIT_TYPES]: { [key in EDIT_RESPONSE_TYPES]: number };
    }
> = {
    SUBJECT: {
        CREATION: { SUCCESS: ResponseMessageKeys.SUBJECT_CREATED, ERROR: ResponseMessageKeys.ERROR_CREATING_SUBJECT },
        UPDATE: { SUCCESS: ResponseMessageKeys.SUBJECT_UPDATED, ERROR: ResponseMessageKeys.ERROR_UPDATING_SUBJECT },
        DELETION: { SUCCESS: ResponseMessageKeys.SUBJECT_DELETED, ERROR: ResponseMessageKeys.ERROR_DELETING_SUBJECT },
    },
    UNIT: {
        CREATION: { SUCCESS: ResponseMessageKeys.UNIT_CREATED, ERROR: ResponseMessageKeys.ERROR_CREATING_UNIT },
        UPDATE: { SUCCESS: ResponseMessageKeys.UNIT_UPDATED, ERROR: ResponseMessageKeys.ERROR_UPDATING_UNIT },
        DELETION: { SUCCESS: ResponseMessageKeys.UNIT_DELETED, ERROR: ResponseMessageKeys.ERROR_DELETING_UNIT },
    },
};

export const useDropdownEditResponsePopup = () => {
    const dispatch = useDispatch();

    const showPopUpMessage = useCallback(
        (
            contentType: DROPDOWN_CONTENT_TYPES,
            editType: DROPDOWN_EDIT_TYPES,
            responseType: EDIT_RESPONSE_TYPES,
            name: string
        ) => {
            dispatch(
                responseActions.showResponse({
                    type: responseType,
                    responseMessage: getResponseMessageKey[contentType][editType][responseType],
                    responseMessageReplacements: [{ name }],
                })
            );
        },
        []
    );

    return showPopUpMessage;
};
