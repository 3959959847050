//DEPENDENCIES
import styled from "styled-components";

export const Background = styled.div`
    height: 100%;
    width: 100%;
    padding: 0;
    border-radius: ${({ theme: { base } }) => base.borderRadius};
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    overflow: hidden;
`;

export const ContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    background: ${(props) => props.theme.colors.backgroundMain};
    padding: ${(props) => props.theme.base.spacing};
    gap: ${(props) => props.theme.base.spacing};
`;

export const Content = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
`;

export const ButtonsContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    gap: ${(props) => props.theme.base.spacingSmall};
`;

export const Description = styled.p`
    color: ${(props) => props.theme.colors.text};
    margin-top: ${(props) => props.theme.base.spacing};
`;
export const DescriptionHighlighted = styled.span`
    color: ${(props) => props.theme.colors.primary};
`;
