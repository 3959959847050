import DictionaryConstants from "../constants/DictionaryConstants";

export const isSupported = (languages: Partial<Record<"primaryLang" | "secondaryLang", string>>) => {
    const { primaryLang, secondaryLang } = languages;
    if (![primaryLang, secondaryLang].includes("de")) return false;
    const searchLang = [primaryLang, secondaryLang].find((lang) => lang !== "de");

    if (!searchLang) return false;

    return DictionaryConstants.targetLanguages.map(({ languageCode }) => languageCode).includes(searchLang as any);
};
