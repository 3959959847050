import styled from "styled-components";
import { StyleTheme } from "p6m-themes";

type Props = {
    theme: StyleTheme;
};

type ScreenKeys = "xs" | "s" | "m" | "l" | "xl";

type ColWidth = "auto" | "none" | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;

type VAlignment = "center" | "start" | "end";
type HAlignment = VAlignment | "around" | "between";

type ColProps = Record<ScreenKeys, ColWidth> & {
    vertical?: VAlignment;
    horizontal?: VAlignment;
};

const getSpacing = ({ theme: { base } }: Props) => base.spacingSmall;

const screens: Record<ScreenKeys, number> = {
    xs: parseInt((process.env.REACT_APP_GRID_SIZE_XS || 0) as string), // <576px
    s: parseInt((process.env.REACT_APP_GRID_SIZE_S || 576) as string), // ≥576px
    m: parseInt((process.env.REACT_APP_GRID_SIZE_M || 768) as string), // ≥768px
    l: parseInt((process.env.REACT_APP_GRID_SIZE_L || 992) as string), // ≥992px
    xl: parseInt((process.env.REACT_APP_GRID_SIZE_XL || 1200) as string), // ≥1200px
};

export const Container = styled.div<{
    fullWidth?: boolean;
}>`
    width: 100%;
    padding-right: ${getSpacing};
    padding-left: ${getSpacing};
    margin-right: auto;
    margin-left: auto;
    box-sizing: border-box;
    ${({ fullWidth = false, ...rest }) => {
        if (fullWidth) return "";
        return Object.entries(screens)
            .map(([, width]: [string, number]) => {
                const spacing: number = parseInt(getSpacing(rest));
                const maxWidth: number = width - spacing * 2;
                return `
                    @media (min-width: ${width}px) {
                        max-width: ${maxWidth}px;
                    }
                `;
            })
            .join("");
    }}
`;

export const Col = styled.div<Partial<ColProps>>`
    position: relative;
    width: 100%;
    max-width: 100%;
    padding: ${getSpacing};
    flex-basis: 0;
    flex-grow: 1;

    ${({ vertical }) => {
        if (!vertical) return "";
        const result = (() => {
            switch (vertical) {
                case "start":
                    return "flex-start";
                case "end":
                    return "flex-end";
                default:
                    return "center";
            }
        })();
        return `align-self: ${result}`;
    }}

    ${({ horizontal }) => {
        if (!horizontal) return "";
        switch (horizontal) {
            case "start":
                return "margin-right: auto;";
            case "end":
                return "margin-left: auto;";
            default:
                return `
                margin-left: auto;
                margin-right: auto;
            `;
        }
    }}

    ${(props) => {
        const cols: Partial<ColProps> = (["xs", "s", "m", "l", "xl"] as ScreenKeys[])
            .filter((val: ScreenKeys) => typeof props[val] !== "undefined")
            .reduce((result, val) => {
                result[val] = props[val];
                return result;
            }, {} as Partial<ColProps>);
        return getColWidth(cols);
    }}
`;

export const Row = styled.div<{
    cols?: ColWidth | Partial<ColProps>;
    vertical?: VAlignment | Partial<Record<ScreenKeys, VAlignment>>;
    horizontal?: HAlignment | Partial<Record<ScreenKeys, HAlignment>>;
    noWrap?: boolean;
}>`
    display: flex;
    flex-wrap: ${({ noWrap = false }) => (noWrap ? "nowrap" : "wrap")};
    margin: 0 -${getSpacing};
    box-sizing: border-box;
    ${({ vertical }) => getAlignment({ vertical })}
    ${({ horizontal }) => getAlignment({ horizontal })}
    ${Col} {
        box-sizing: border-box;
        &:before,
        &:after {
            box-sizing: border-box;
        }
    }
    ${({ cols }) => {
        if (typeof cols === "undefined") return "";

        //@ts-ignore because here is number type check
        if (Number.isInteger(cols) || cols === "auto") cols = { xs: cols as number };
        return `
            > ${Col} {
                ${getColWidth(cols as Partial<ColProps>, true)}
            }
        `;
    }}
    &:first-child {
        margin-top: -${getSpacing};
    }
    &:last-child {
        margin-bottom: -${getSpacing};
    }
`;

function getColWidth(cols: Partial<ColProps>, invert: boolean = false): string {
    return Object.entries(cols)
        .map(([key, value = 0]) => {
            const width: number =
                value === "auto"
                    ? 0
                    : ["none", 12].includes(value)
                    ? 100
                    : Number.parseFloat((invert ? 100 / (value as number) : (100 / 12) * (value as number)).toFixed(3));

            const result: string = `
                width: ${width ? width + "%" : "auto"};
                min-width: ${width && value !== "none" ? width + "%" : "auto"};
                max-width: ${width ? width + "%" : "100%"};
                flex: ${width ? 1 : "0 0 auto"};
            `;
            if (key === "xs") return result;
            return `
                @media (min-width: ${screens[key as ScreenKeys]}px) {
                    ${result}
                }
            `;
        })
        .join("");
}

function getAlignment<T extends VAlignment | HAlignment>(
    { vertical, horizontal }: Partial<Record<"vertical" | "horizontal", T | Partial<Record<ScreenKeys, T>>>> // Who loves TS?))
): string {
    let value = vertical || horizontal;

    if (typeof value === "undefined") return "";
    if (typeof value === "string") value = { xs: value };

    return Object.entries(value)
        .map(([key, value]: [string, T?]) => {
            if (!value) return "";
            let align: string = (() => {
                switch (value) {
                    case "start":
                        return "flex-start";
                    case "end":
                        return "flex-end";
                    case "between":
                        return "space-between";
                    case "around":
                        return "space-around";
                    default:
                        return "center";
                }
            })();
            const result: string = `
                ${vertical ? "align-items" : "justify-content"}: ${align};
            `;
            if (key === "xs") return result;
            return `
                @media (min-width: ${screens[key as ScreenKeys]}px) {
                    ${result}
                }
            `;
        })
        .join("");
}
