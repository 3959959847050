import React, { FunctionComponent } from "react";
import { useSelector } from "react-redux";
import { selectors } from "../../../../redux/goals/goalsSlice";
import Component, { Props } from "./RocketItem";

// TODO: add backend integration here!!!
export const RocketItem: FunctionComponent<Partial<Props>> = (props) => {
    const currentStreak = useSelector(selectors.currentStreak);

    return (
        <Component
            count={currentStreak}
            {...props}
        />
    );
};
