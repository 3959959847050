// REACT
import React, { FunctionComponent, useState, useCallback, useEffect } from "react";

// COMPONENTS
import PhaseSixIcon from "../../../basic/phaseSixIcon/PhaseSixIcon";

// UTILS
import { getPlaylist, PlaylistStatus } from "../../../../helpers/Audio";

type Props = {
    audio: string;
};

export const CardAudio: FunctionComponent<Props> = (props) => {
    const { audio } = props;
    const [isPlaying, setPlaying] = useState(false);

    useEffect(() => {
        const playlist = getPlaylist();
        const onAudioChange = () => {
            const currentAudio = playlist.getCurrentAudio();
            if (currentAudio?.src !== audio) return;
            currentAudio.addEventListener("play", () => setPlaying(true));
            currentAudio.addEventListener("ended", () => setPlaying(false));
            currentAudio.addEventListener("pause", () => setPlaying(false));
        };
        playlist.eventTarget.addEventListener("audioChange", onAudioChange);

        return () => playlist.eventTarget.removeEventListener("audioChange", onAudioChange);
    }, [audio]);

    const handleAudioPlayButtonClick = useCallback(() => {
        const playlist = getPlaylist();
        const isCurrentAudio = playlist.getCurrentAudio()?.src === audio;
        const isPlaying = playlist.getStatus() === PlaylistStatus.playing;
        if (isCurrentAudio && isPlaying) {
            playlist.pause();
        } else if (isCurrentAudio && !isPlaying) {
            playlist.play();
        } else {
            playlist.stop();
            playlist.setAudios([audio], true);
        }
    }, [audio]);
    

    return (
        <PhaseSixIcon
            name={"speaker"}
            highlighted={isPlaying}
            onClick={handleAudioPlayButtonClick}
        />
    );
};
