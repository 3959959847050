import styled from "styled-components";

export const Overlay = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 20;
    background: ${(props) => props.theme.colors.shadow};
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;
export const Header = styled.h2`
    color: ${(props) => props.theme.colors.text};
    font-size: ${(props) => props.theme.base.titleBigSize};
`;
export const Message = styled.p`
    color: ${(props) => props.theme.colors.text};
    font-size: ${(props) => props.theme.base.titleBigSize};
`;
export const ImageWrapper = styled.div`
    width: 50%;
`;
