// LIBRARIES
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SubjectData } from "p6m-subjects";
import { useHistory } from "react-router-dom";

// REDUX
import { actions as subjectsActions, selectors as subjectsSelector } from "../../../redux/subjects/subjectsSlice";

// COMPONENTS
import SubjectCarousel from "./SubjectCarousel";
import { actions as modalActions } from "../../../redux/modal/modalSlice";
import { GlobalModalView } from "../../../helpers/Modal";

interface SubjectCarouselProps {
    userRoles: { isParent: boolean; isTeacher: boolean };
    allSubjects: SubjectData[];
}

const SubjectCarouselWrapper: React.FC<SubjectCarouselProps> = ({ userRoles, allSubjects }) => {
    const dispatch = useDispatch();
    const history = useHistory();

    const activeSubjectId = useSelector(subjectsSelector.activeSubjectId);
    //For now we always only want to insert the own, active subjects of a specific user to the carousel.
    //However there was a specific request, to keep the option to also show all other familySubjects and its depending logic at hand for future use
    //(e.g: when we implement the ‘homework’ functionality where parents can activate cards for children). =>
    const familySubjects: SubjectData[] = []; //useSelector(subjectsSelector.familySubjectsDerived);

    let carouselSubjects: SubjectData[] = [];

    if (userRoles.isParent || userRoles.isTeacher) {
        //refers to comment above: For now the "filteredFamilySubjects"-variable will always stay an empty array.
        //However, legacy logic is kept, in case parents need access to childrens subjects (e.g. "assign homework"-functionality is granted to parents).=>
        const filteredFamilySubjects = familySubjects.filter(
            (familySubject) => !familySubject.isExpired && !familySubject.subjectMetadata.classroomFlag
        );
        const filteredUserSubjects = allSubjects.filter((subject) => !subject.subjectMetadata.classroomFlag);

        carouselSubjects = [...filteredFamilySubjects, ...filteredUserSubjects];
    } else {
        carouselSubjects = [...allSubjects];
    }

    useEffect(() => {
        if (carouselSubjects.length === 0 || activeSubjectId) return;
        dispatch(subjectsActions.setActiveSubjectId(carouselSubjects[0].subjectMetadata.subjectIdToOwner.id));
    }, [carouselSubjects.length, activeSubjectId, dispatch]);

    const onSubjectSelect = (subjectId: string) => {
        dispatch(subjectsActions.setActiveSubjectId(subjectId));
    };
    const onAddSubject = () => {
        dispatch(modalActions.setModalView(GlobalModalView.AddSubject));
    };
    const onCreateSubject = () => {
        history.push("/add");
    };

    return (
        <SubjectCarousel
            subjects={carouselSubjects}
            activeSubjectId={activeSubjectId}
            userRoles={userRoles}
            onSubjectSelect={onSubjectSelect}
            onAddSubject={onAddSubject}
            onCreateSubject={onCreateSubject}
        />
    );
};

export default SubjectCarouselWrapper;
