import styled from "styled-components";
import { Row, Col } from "../../basic/grid";

export const SummaryWrapper = styled.div`
    padding: ${({ theme: { base } }) => base.spacingSmall};
    background-color: ${({ theme: { colors } }) => colors.backgroundContrast};
    border-radius: ${({ theme: { base } }) => base.borderRadius};
`;

export const SummaryImage = styled.img`
    width: 140px;
`;

export const SummaryTitle = styled.h2`
    margin: 0 0 10px 0;
    color: ${({ theme: { colors } }) => colors.text};
    font-size: 24px;
    font-weight: 200;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
`;

export const SummaryTypeTitle = styled.h3`
    margin: 5px 0 0 0;
    color: ${({ theme: { colors } }) => colors.text};
    font-size: 18px;
    font-weight: normal;
    text-transform: uppercase;
`;
export const SummaryTypeDescr = styled.div`
    margin: 0 0 5px 0;
    color: ${({ theme: { colors } }) => colors.text};
    font-size: 14px;
    font-weight: normal;
`;

export const SummaryTypeWrapper = styled.div`
    margin: 10px 0;
`;

export const Radio = styled.div`
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 1px solid ${({ theme: { colors } }) => colors.text};
    cursor: pointer;

    &:after {
        content: " ";
        display: none;
        width: 50%;
        height: 50%;
        margin: 25%;
        border-radius: 50%;
        background-color: ${({ theme: { colors } }) => colors.primary};
    }
`;

export const RadioWrapper = styled.label<{ active?: boolean }>`
    display: block;
    margin: 0;

    input {
        display: none;
    }

    input:checked + ${Radio} {
        border-color: ${({ theme: { colors } }) => colors.primary};
        &:after {
            display: block;
        }
    }

    ${({ active = false, theme: { colors } }) =>
        !active
            ? ""
            : `
        ${SummaryTypeDescr} {
            color: ${colors.primary};
        }
        ${SummaryTypeTitle} {
            color: ${colors.primary};
        }
    `}
`;

export const LanguageWrapper = styled.div`
    max-width: 460px;
    margin: ${({ theme: { base } }) => base.spacing} 0;
    font-size: ${({ theme: { base } }) => base.size};
    color: ${({ theme: { colors } }) => colors.text};

    ${Row} {
        margin: -2px;
    }

    ${Col} {
        padding: 2px;
    }
`;

export const FlagCol = styled(Col)`
    position: relative;
`;

export const Flag = styled.img<{ right?: boolean }>`
    position: absolute;
    top: 50%;
    ${({ right = false }) => (right ? "right: 0px;" : "left: 0px;")}
    z-index: 1;
    width: 36px;
    height: 24px;
    margin: -15px -5px;
    border-radius: ${({ theme: { base } }) => base.borderRadius};
`;

export const LangBox = styled(RadioWrapper)`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 150px;
    height: 100px;
    background-color: ${({ theme: { colors } }) => colors.backgroundMain};
    border-radius: ${({ theme: { base } }) => base.borderRadius};
    color: ${({ theme: { colors } }) => colors.text};
    cursor: pointer;
    font-size: ${({ theme: { base } }) => base.iconSmall};

    input ~ * {
        border-color: ${({ theme: { colors } }) => colors.text};
    }
    input:checked ~ * {
        color: ${({ theme: { colors } }) => colors.primary};
    }
`;

export const NoSwapText = styled.p`
    font-size: ${({ theme: { base } }) => base.size};
    color: ${({ theme: { colors } }) => colors.primary};
`;

export const UnitCardsInfo = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin: ${({ theme: { base } }) => base.spacing} 0;
    color: ${({ theme: { colors } }) => colors.text};
`;

export const UnitCardsTitle = styled.div`
    min-width: 180px;
`;

export const UnitCardsText = styled.p`
    word-break: break-word;
`;
