// REACT
import React, { FunctionComponent, memo, useEffect, useMemo, useState } from "react";
import { useT } from "@transifex/react";

// LIBRARIES
import DOMPurify from "dompurify";

// COMPONENTS
import ActivationLayout from "../../complex/activationLayout/ActivationLayout";
import Collapse from "../../basic/collapse/Collapse";
import { Col } from "../../basic/grid";

import ListCheckbox from "../../basic/listCheckbox/ListCheckbox";
import PhaseSixIcon from "../../basic/phaseSixIcon/PhaseSixIcon";

// STYLED COMPONENTS
import {
    ListWrapper,
    Item,
    Arrow,
    ChildrenItems,
    ListEntry,
    CardAmountSelectedCol,
    TextCol,
    CheckedIds,
} from "./styles";

// TYPES
import { ActivationListProps, TCard, TUnit } from "p6m-activation";

const CardComponent: FunctionComponent<
    TCard & {
        checked: boolean;
        onChange: (cards: string[], unitId: string, isChecked: boolean) => void;
    }
> = memo((props) => {
    const { id, checked, title, onChange, unitId } = props;

    return (
        <Item
            vertical="center"
            onClick={() => onChange([id], unitId, !checked)}
            checked={checked}
        >
            <Col xs="auto">
                <ListCheckbox
                    checked={checked}
                    onChange={(checked: boolean) => {
                        onChange([id], unitId, checked);
                    }}
                />
            </Col>
            <TextCol>
                <ListEntry
                    dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(title, { USE_PROFILES: { html: true } }) }}
                />
            </TextCol>
        </Item>
    );
});

const renderCardAmountSelectedDisplay = (cardsAmount: number, checkedIdsAmount: number) => {
    if (checkedIdsAmount === 0) {
        return cardsAmount;
    }

    return (
        <>
            <CheckedIds highlighted={checkedIdsAmount > 0}>{checkedIdsAmount}</CheckedIds>
            {` / ${cardsAmount}`}
        </>
    );
};

const UnitComponent: FunctionComponent<
    TUnit & {
        checkedIds: string[];
        isInitiallyOpen: boolean;
        searchString?: string;
        cards: TCard[];
        onSelect: (cardIds: string[], unitId: string, isChecked: boolean) => void;
    }
> = memo((props) => {
    const { title, checkedIds = [], cards = [], isInitiallyOpen, onSelect, searchString } = props;
    const [isOpen, setIsOpen] = useState<boolean>(isInitiallyOpen);

    const checkedIdsInCurrentCards = checkedIds.filter((id) => cards.find((card) => id === card.id));

    const checked: boolean = checkedIdsInCurrentCards.length === cards.length;

    useEffect(() => {
        setIsOpen(!!searchString || isInitiallyOpen || !!cards.find(({ id }) => checkedIds.includes(id)));
    }, [searchString, isInitiallyOpen, checkedIds]);

    const header = ({ isOpen, open, close }: { isOpen: boolean; open: () => void; close: () => void }) => {
        const action = isOpen ? close : open;

        return (
            <Item
                vertical="center"
                checked={checked}
                onClick={action}
            >
                <Col xs="auto">
                    <ListCheckbox
                        checked={checked}
                        onChange={(checked: boolean) => {
                            if (!isOpen && checked) open();
                            const cardsId = cards.map((card: TCard) => card.id);
                            onSelect(cardsId, cards[0].unitId, checked);
                        }}
                        stopPropagation={true}
                    />
                </Col>
                <TextCol>
                    <ListEntry
                        isTitle
                        dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize(title, { USE_PROFILES: { html: true } }),
                        }}
                    />
                </TextCol>
                {!!cards.length && (
                    <>
                        <CardAmountSelectedCol xs="auto">
                            {renderCardAmountSelectedDisplay(cards.length, checkedIdsInCurrentCards.length)}
                        </CardAmountSelectedCol>
                        <Col xs="auto">
                            <Arrow>
                                <PhaseSixIcon name={isOpen ? "chevron-up" : "chevron-down"} />
                            </Arrow>
                        </Col>
                    </>
                )}
            </Item>
        );
    };

    return (
        <Collapse
            clickable={false}
            header={header}
            isOpen={isOpen}
            onChangeIsOpen={setIsOpen}
        >
            <ChildrenItems>
                {cards.map((card) => {
                    const props = {
                        ...card,
                        checked: checkedIds.includes(card.id),
                        onChange: onSelect,
                    };
                    return (
                        <CardComponent
                            key={card.id}
                            {...props}
                        />
                    );
                })}
            </ChildrenItems>
        </Collapse>
    );
});

const sortByOrderThenAlphabetically = (firstElement: TCard, secondElement: TCard) => {
    if (typeof firstElement.order !== "undefined") {
        if (typeof secondElement.order !== "undefined") {
            // both element orders are not undefined, they should be sorted based on their existing order
            return firstElement.order > secondElement.order ? 1 : -1;
        } else {
            // the undefined element order is the second - it should go later, as it's a custom unit
            return -1;
        }
    } else {
        if (typeof secondElement.order !== "undefined") {
            // the undefined element order is the first - it should go later, as it's a custom unit
            return 1;
        } else {
            // both element orders are undefined, so they should be ordered alphabetically by title
            return firstElement.title.toLowerCase() > secondElement.title.toLowerCase() ? 1 : -1;
        }
    }
};

const ActivationList = memo(
    ({
        units,
        cards,
        selectedCardIdsByUnitIds,
        isPrepareForTest = false,
        firstPracticeFinished = false,
        searchString,
        onSearch,
        onSelect,
    }: ActivationListProps) => {
        const t = useT();

        const t_title = t("Select the unit(s) and cards which you want to practice cards from.", {});
        const t_prepareForTestTitle = t("Select the unit(s) you would like to practise to prepare for a test", {});

        const title = isPrepareForTest ? t_prepareForTestTitle : t_title;

        const unitsWithCards: (TUnit & {
            cards: TCard[];
        })[] = useMemo(() => {
            return units
                .map((unit: TUnit) => ({
                    ...unit,
                    cards: cards
                        .filter((card: TCard) => unit.id === card.unitId)
                        .sort()
                        .sort(sortByOrderThenAlphabetically),
                }))
                .filter(({ cards = [] }) => !!cards.length)
                .sort()
                .sort(({ order = 0 }, { order: nextOrder = 0 }) => order - nextOrder);
        }, [units, cards]);

        const isBeforeFirstSelection = useMemo(
            () => !Object.keys(selectedCardIdsByUnitIds).length,
            [selectedCardIdsByUnitIds]
        );
        const onlyOneUnitInList = useMemo(() => unitsWithCards.length === 1, [unitsWithCards.length]);

        return (
            <ActivationLayout
                title={title}
                onSearch={onSearch}
            >
                <ListWrapper>
                    {unitsWithCards.map((unit, position) => {
                        return (
                            <UnitComponent
                                key={unit.id}
                                {...unit}
                                isInitiallyOpen={
                                    onlyOneUnitInList ||
                                    (position === 0 && !firstPracticeFinished && isBeforeFirstSelection)
                                }
                                searchString={searchString}
                                onSelect={onSelect}
                                checkedIds={selectedCardIdsByUnitIds[unit.id] || []}
                            />
                        );
                    })}
                </ListWrapper>
            </ActivationLayout>
        );
    }
);

export default ActivationList;
