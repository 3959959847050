import { actions, selectors } from "./themeSlice";
import { takeEvery, put, select } from "redux-saga/effects";

export function* rehydrateThemeSaga() {
    const themeName = yield select(selectors.themeName);
    put(actions.setTheme(themeName));
}

export function* themeSaga() {
    yield takeEvery("persist/REHYDRATE", rehydrateThemeSaga);
}
