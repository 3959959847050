import styled from "styled-components";

export const PopupWrapper = styled.div`
    background: ${(props) => props.theme.colors.backgroundMain};
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    position: absolute;
    top: 250px;
    left: 25%;
    width: 50%;
    padding: 15px;
    border-radius: 10px;
`;

export const PopupContentContainerLight = styled.div`
    background-color: ${(props) => props.theme.colors.backgroundContrast};
    width: 100%;
    border-radius: 10px;
    padding: 8px 16px;
`;

export const SettingsContainer = styled.div`
    background-color: ${(props) => props.theme.colors.backgroundContrast};
    width: 100%;
    border-radius: 10px;
    padding: 15px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`;

export const ContainerLightContent = styled.div`
    padding-top: 15px;
`;

export const Section = styled.div`
    width: 100%;
`;

export const HeaderSection = styled.div`
    padding-left: 15px;
    padding-right: 15px;
    width: 100%;
`;

export const Headline = styled.div`
    color: ${(props) => props.theme.colors.text};
    font-size: ${(props) => props.theme.base.titleSize};
    margin-bottom: 8px;
    margin-top: 16px;
`;

export const HeadingContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
`;

export const Row = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`;

export const DataTextLine = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    padding-top: 8px;
    padding-bottom: 8px;
    justify-content: space-between;
`;

export const Text = styled.div`
    font-size: ${(props) => props.theme.base.bigSize};
    color: ${(props) => props.theme.colors.text};
`;

export const Value = styled.div`
    font-size: ${(props) => props.theme.base.bigSize};
    color: ${(props) => props.theme.colors.primary};
`;

export const Information = styled.p`
    color: ${(props) => props.theme.colors.text};
`;

export const Footer = styled.p`
    padding: ${(props) => props.theme.base.spacing};
    color: ${(props) => props.theme.colors.text};
`;

export const CloseIcon = styled.button`
    font-size: ${(props) => props.theme.base.smallSize};
    background: none;
    border: none;

    &:hover {
        color: ${(props) => props.theme.colors.primary};
        cursor: pointer;
    }
`;

export const ContentWrapper = styled.div`
    padding: 0 15px;
`;
