//LIBRARIES
import React, { FunctionComponent, useMemo, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

//REDUX
import { selectors as userSelectors } from "../../../redux/user/userSlice";
import { actions as modalActions } from "../../../redux/modal/modalSlice";
import { actions as userActions } from "../../../redux/user/userSlice";

//TYPES
import { GlobalModalView } from "../../../helpers/Modal";

//COMPONENTS
import Component from "./GDPRModal";

//UTILS
import { getGDPRWindowUrl } from "../../../helpers/Url";

export const GDPRModal: FunctionComponent = () => {
    const dispatch = useDispatch();
    const jossoId = useSelector(userSelectors.jossoId);

    const url = useMemo(() => getGDPRWindowUrl(jossoId), [jossoId]);

    const close = useCallback(() => {
        dispatch(modalActions.setModalView(GlobalModalView.None));
    }, [dispatch]);

    useEffect(() => {
        dispatch(userActions.setLastTimeGDPRModalChecked(new Date()));
    }, []);

    return (
        <>
            {url && (
                <Component
                    url={url}
                    close={close}
                />
            )}
        </>
    );
};
