// REACT
import React from "react";

// STYLED COMPONENTS
import { ToggleWrapper, ToggleInterior } from "./styles";

export interface CustomToggleProps {
    checkedValue?: boolean;
    className?: string;
    disabled?: boolean;
}

const CustomToggle: React.FC<CustomToggleProps> = (props) => {
    return (
        <ToggleWrapper
            {...props}
            className={props.checkedValue ? "checked" : "unchecked"}
        >
            <ToggleInterior className={props.checkedValue ? "checked" : "unchecked"} />
        </ToggleWrapper>
    );
};

export default CustomToggle;
