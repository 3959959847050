import { useMemo } from "react";

const newPosition = (pos?: number): number | null => {
    if (typeof pos === "number" && pos >= 0) {
        return pos + 1;
    }

    return null;
};

export function useRankingPosition(learnerPosition?: number, schoolPosition?: number, schoolId?: number) {
    return useMemo(() => {
        const position = newPosition(learnerPosition) || newPosition(schoolPosition);

        // -1 means school is not assigned for the user
        if (schoolId === -1 || !position) {
            return "-";
        }

        return position.toLocaleString("de-DE");
    }, [learnerPosition, schoolPosition, schoolId]);
}
