import styled from "styled-components";

export const CardContent = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    padding-top: ${({ theme: { base } }) => base.spacing};
    word-break: break-all;
`;

export const CardTitle = styled.div<{ noSelect?: boolean }>`
    margin: 0 0 ${({ theme: { base } }) => base.spacing} 0;
    font-size: ${({ theme: { base } }) => base.cardPracticeTextSize};
    font-weight: normal;
    text-align: center;
    color: ${({ theme: { colors } }) => colors.text};
    word-break: break-word;
    > * {
        text-align: center;
    }

    ${({ noSelect = false }) =>
        !noSelect
            ? ""
            : `
        user-select: none;
    `}
`;

export const VolumeWrapper = styled.div<{ active?: boolean }>`
    margin: 0 0 ${({ theme: { base } }) => base.spacing} 0;
    font-size: ${({ theme: { base } }) => base.cardPracticeTextSize};
    font-weight: normal;
    text-align: center;

    & > * {
        transform: scale(0.7);
        cursor: pointer;
        transition: transform ${({ theme: { base } }) => base.transition}s;

        &:hover {
            transform: scale(1);
        }
    }
`;

export const ImagesWrapper = styled.div<{ active?: boolean }>`
    margin: 0 0 ${({ theme: { base } }) => base.spacing} 0;
    text-align: center;
`;

export const Image = styled.img<{ active?: boolean }>`
    max-height: 150px;
    max-width: 90%;
    cursor: pointer;
`;

export const ImageSelector = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: ${({ theme: { base } }) => base.spacingSmall};

    > div {
        margin: 0 ${({ theme: { base } }) => base.spacingTiny};
        width: 10px;
        min-width: 10px;
        height: 10px;
        border-radius: 50%;
        border: 1px solid #999;
        transition: ${({ theme: { base } }) => base.transition}s;
        cursor: pointer;

        &:hover {
            opacity: 0.7;
        }

        &.active {
            background-color: #999;
        }
    }
`;

export const AnnotateWrapper = styled.div`
    margin-top: auto;
`;

export const AnswerExample = styled.div`
    margin: 0 ${({ theme: { base } }) => base.spacing};
    text-align: center;
    color: ${({ theme: { colors } }) => colors.text};
    word-break: break-word;
`;
