// REACT
import React, {
    FunctionComponent,
    Children,
    isValidElement,
    cloneElement,
    useState,
    useCallback,
    useRef,
    useLayoutEffect,
    useEffect,
} from "react";

// STYLED COMPONENTS
import { Header, CollapseContent, CollapseWrapper } from "./styles";

// TYPES
import { Props } from "p6m-collapse";

const Collapse: FunctionComponent<Props> = (props) => {
    const {
        children,
        header: HeaderElement,
        headerProps = {},
        collapsedProps = {},
        isOpen = false,
        onChangeIsOpen,
        clickable = true,
        provideActions = false,
        ...restProps
    } = props;

    const [open, setOpen] = useState(isOpen);
    const [maxHeight, setMaxHeight] = useState(0);

    const collapseComponentRef = useRef<HTMLDivElement>(null);

    useLayoutEffect(() => {
        setMaxHeight(collapseComponentRef?.current?.clientHeight || 0);
    }, [children]);

    useEffect(() => {
        setOpen(isOpen);
    }, [isOpen]);

    const handleOpen = useCallback(() => {
        setOpen(true);
        onChangeIsOpen && onChangeIsOpen(true);
    }, [onChangeIsOpen]);

    const handleClose = useCallback(() => {
        setOpen(false);
        onChangeIsOpen && onChangeIsOpen(false);
    }, [onChangeIsOpen]);

    const handleToggle = useCallback(() => {
        setOpen((prevState: boolean) => !prevState);
        onChangeIsOpen && onChangeIsOpen((prevIsOpenValue) => !prevIsOpenValue);
    }, [onChangeIsOpen]);

    const HeaderToRender =
        typeof HeaderElement === "function" ? (
            <HeaderElement
                isOpen={open}
                open={handleOpen}
                close={handleClose}
            />
        ) : (
            HeaderElement
        );

    return (
        <div {...restProps}>
            <Header
                clickable={clickable}
                onClick={clickable ? handleToggle : undefined}
                {...headerProps}
            >
                {HeaderToRender}
            </Header>
            <CollapseWrapper {...{ isOpen: open, maxHeight, ...collapsedProps }}>
                <CollapseContent ref={collapseComponentRef}>
                    {!provideActions
                        ? children
                        : Children.map(children, (child) =>
                              !isValidElement(child)
                                  ? child
                                  : cloneElement(child, {
                                        isOpen: open,
                                        open: handleOpen,
                                        close: handleClose,
                                    })
                          )}
                </CollapseContent>
            </CollapseWrapper>
        </div>
    );
};

export default Collapse;
