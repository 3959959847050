//DEPENDENCIES
import React, { useMemo } from "react";
import { useT } from "@transifex/react";

//STYLE
import { Background, Content, DropdownContainer, Warning, ButtonsContainer } from "./styles";

//TYPE
import { DropdownOption } from "p6m-viewData";
import { EntryChange, SubjectData } from "p6m-subjects";
import { GlobalModalProps } from "../../../helpers/Modal";

//COMPONENT
import DropdownSelection from "../../basic/dropdownSelection/DropdownSelection";
import { DefaultHeader } from "../../basic/modal/DefaultHeader";
import { FilledButton, OutlinedButton } from "../../basic/button/Button";

interface ChangeCardSubjectModalProps extends GlobalModalProps {
    allSubjects: SubjectData[];
    selectedSubjectId: string;
    selectedUnitId: string;
    dropdownSubjectItems: DropdownOption[];
    dropdownUnitItems: DropdownOption[];
    containsProtectedCards?: boolean;
    subjectLabel: string;
    unitLabel: string;
    selectSubject: (subjectIds: string[]) => void;
    selectUnit: (unitIds: string[]) => void;
    saveCardsAssignment: () => void;
    saveSubjectsEdit: (changes: EntryChange[]) => void;
    saveUnitsEdit: (changes: EntryChange[]) => void;
}

const ChangeCardSubjectModal: React.FC<ChangeCardSubjectModalProps> = (props) => {
    const t = useT();

    const assign_t = t("Change assignment of selected cards", {
        _tags: "title, modal",
    });
    const cancel_t = t("Cancel", { _tags: "library,button" });
    const ok_t = t("Ok", { _tags: "library,button" });
    const someCardsCanNotBeMoved_t = t("Some selected cards could not be moved because they are protected", {
        _tags: "library,message,warning",
    });

    const {
        selectedSubjectId,
        selectedUnitId,
        dropdownSubjectItems,
        dropdownUnitItems,
        allSubjects,
        containsProtectedCards,
        subjectLabel,
        unitLabel,
        selectSubject,
        selectUnit,
        saveSubjectsEdit,
        saveUnitsEdit,
        saveCardsAssignment,
        close,
    } = props;

    const firstUnitOptionId = dropdownUnitItems[0]?.value;

    const selectedUnitIds = useMemo(() => [selectedUnitId || firstUnitOptionId!], [selectedUnitId, firstUnitOptionId]);

    const forbiddenSubjectNames = allSubjects
        .filter((subject) =>
            dropdownSubjectItems.every((option) => option.value !== subject.subjectMetadata.subjectIdToOwner.id)
        )
        .map((subject) => subject.subjectContent.name);

    return (
        <Background>
            <DefaultHeader
                title={assign_t}
                close={close}
            />
            <Content>
                <DropdownContainer>
                    <DropdownSelection
                        label={subjectLabel}
                        withBorder={true}
                        options={dropdownSubjectItems}
                        multiSelection={false}
                        selectedIds={[selectedSubjectId]}
                        editable={true}
                        forbiddenNames={forbiddenSubjectNames}
                        onSelect={selectSubject}
                        saveEdit={saveSubjectsEdit}
                        contentType="subjects"
                        displayDeleteWarning
                    />
                    <DropdownSelection
                        label={unitLabel}
                        withBorder={true}
                        options={dropdownUnitItems}
                        multiSelection={false}
                        selectedIds={selectedUnitIds}
                        editable={true}
                        onSelect={selectUnit}
                        saveEdit={saveUnitsEdit}
                        contentType="units"
                        displayDeleteWarning
                    />
                </DropdownContainer>
                {containsProtectedCards && <Warning>{someCardsCanNotBeMoved_t}</Warning>}
            </Content>
            <ButtonsContainer>
                <OutlinedButton
                    onClick={close}
                    upperCase
                    textSize="xl"
                    useModalZIndex
                >
                    {cancel_t}
                </OutlinedButton>
                <FilledButton
                    onClick={saveCardsAssignment}
                    upperCase
                    textSize="xl"
                    useModalZIndex
                >
                    {ok_t}
                </FilledButton>
            </ButtonsContainer>
        </Background>
    );
};

export default ChangeCardSubjectModal;
