// REACT
import React, { FunctionComponent, useEffect, useState } from "react";
import { T } from "@transifex/react";

// UTILS
import { getMaxTimeByScore } from "../../../../helpers/MatchGame";

// STYLED COMPONENTS
import { TimeLineWrapper, TimeLineItem, TimeLineCounter, Title } from "./styles";

export type Props = {
    tick?: number;
    time?: number;
    pause?: boolean;
    stop?: boolean;
    onTimeChange?: (time: number) => void;
    onTimeEnd?: () => void;
    onStop?: (time: number) => void;
};

const maxTime = getMaxTimeByScore(1);
const titles: [number, string][] = [
    [66.66, "Impressive"],
    [50, "Very Good"],
    [33.333, "Good"],
    [16.55, "Quite Good"],
    [0, "Got It"],
];

const TimeLine: FunctionComponent<Props> = (props) => {
    const { time = maxTime, tick = 1000, pause = false, stop = false, onTimeEnd = () => {}, onStop = () => {} } = props;

    const [currentTime, setTime] = useState<number>(0);

    const position: number = 100 - (100 / time) * currentTime;
    const title: string = (titles.find(([value]) => position >= value) || titles[4])[1];

    useEffect(() => {
        if (!stop) return;
        onStop(currentTime);
        setTime(0);
        // eslint-disable-next-line
    }, [stop]);

    useEffect(() => {
        if (pause || stop) return;
        if (currentTime > time) return void onTimeEnd();

        const resultTime: number = 1 + currentTime;

        let timeout = setTimeout(() => {
            setTime(resultTime);
        }, tick);

        return () => clearTimeout(timeout);
    }, [time, currentTime, tick, pause, stop, onTimeEnd]);

    return (
        <TimeLineWrapper>
            <TimeLineItem />
            <TimeLineItem />
            <TimeLineItem />
            <TimeLineItem />
            <TimeLineCounter position={position} />
            <Title position={position}>
                <T _str={title} />
            </Title>
        </TimeLineWrapper>
    );
};

export default TimeLine;
