//DEPENDENCIES
import React from "react";
import { useT } from "@transifex/react";

//STYLES
import { BulletPointSection, BulletPoint } from "../styles";
import PhaseSixIcon from "../../../basic/phaseSixIcon/PhaseSixIcon";

const BulletPoints = () => {
    const t = useT();

    const allTopicsOf5thGrade_t = t("All grammar topics of the 5th grade", {
        _tags: "modal, bulletpoints, grammarTutor",
    });

    const helpfulHints_t = t("Get helpful hints were you get stuck", {
        _tags: "modal, bulletpoints, grammarTutor",
    });

    const knowledgeBased_t = t("Practice using only words you already know", {
        _tags: "modal, bulletpoints, grammarTutor",
    });

    const bulletPoints = [allTopicsOf5thGrade_t, helpfulHints_t, knowledgeBased_t];

    return (
        <BulletPointSection>
            {bulletPoints.map((bulletPoint) => (
                <BulletPoint>
                    <PhaseSixIcon name="checked" />
                    {bulletPoint}
                </BulletPoint>
            ))}
        </BulletPointSection>
    );
};

export default BulletPoints;
