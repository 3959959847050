import { Phase } from "p6m-user";
import { isEqual } from "lodash";

export const calculatePhaseDurations = (phasesNo: number, duration: number): Array<number> => {
    let phases = [],
        i;
    phases.push(0);
    for (let p = 2; p <= phasesNo; p++) {
        i = Math.exp(Math.log(duration) / (phasesNo - 2));
        phases.push(Math.round(Math.pow(i, p - 2)));
    }
    return phases;
};

export const getDefaultPhases = (): Array<number> => {
    // Default phases values
    return calculatePhaseDurations(6, 90);
};

export const arePhasesCustom = (phases: Array<number>): boolean => {
    return !isEqual(phases, getDefaultPhases());
};

export const arrayToPhasesRequestObject = (phases: Array<number>): Array<Phase> => {
    let arrayToReturn: Array<Phase> = [{ durationDays: 0 }];
    phases.forEach((n) => arrayToReturn.push({ durationDays: n }));
    arrayToReturn.push({ durationDays: 2147483647 });
    return arrayToReturn;
};

export const phasesRequestObjToArray = (phases: Array<Phase>): Array<number> => {
    const arrayToReturn: Array<number> = [];

    // Checking integrity of the request obj.
    // The default value for the last phase is 2147483647,
    // but for some reason the iOS app doesn't support so big value and returns 365000 instead.
    // However, any value >= 365000 will be treated as a learned status by the backend.
    if (phases.length > 2 && phases[0].durationDays === 0 && phases[phases.length - 1].durationDays >= 365000) {
        let reducedPhases = [...phases];
        reducedPhases.splice(0, 1);
        reducedPhases.pop();

        reducedPhases.forEach((p) => arrayToReturn.push(!!p.durationDays ? p.durationDays : 0));
    }

    return arrayToReturn;
};

export const calculateLearnPeriod = (phases: Array<number>): number => {
    return phases.reduce((a, b) => a + b, 0);
};
