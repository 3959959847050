export enum ShareType {
    "friend",
    "parent",
}

function encodeSimple(n: string | number) {
    let s: string = n.toString();
    let ii: string = "";
    for (let i: number = 0; i < s.length; i++) {
        let c: number = +s.substring(i, i + 1);
        c += 65;
        if (Math.random() > 0.5) {
            c += 10;
        }
        ii += String.fromCharCode(c);
    }
    return ii;
}

export function getSharePracticeResultUrl(
    type: ShareType,
    userId: string,
    subjectId: string,
    subjectIsTrial: boolean,
    learnedCards: number,
    learnedCorrect: number,
    userStars: number
) {
    const encodedUserDNSId = encodeSimple(userId);
    const subjectUUID = encodeSimple(subjectId);
    const encodedTotal = encodeSimple(learnedCards);
    const encodedCorrect = encodeSimple(learnedCorrect);

    return type === ShareType.friend
        ? "https://www.phase-6.de/pxp/eop/web/friend/?" +
              encodedUserDNSId +
              ";" +
              subjectUUID +
              ";" +
              subjectIsTrial +
              ";" +
              encodedTotal +
              ";" +
              encodedCorrect +
              ";" +
              userStars
        : "https://www.phase-6.de/pxp/eop/web/parent/?" +
              encodedUserDNSId +
              ";" +
              subjectUUID +
              ";" +
              subjectIsTrial +
              ";" +
              encodedTotal +
              ";" +
              encodedCorrect +
              ";" +
              userStars;
}

export function getShareCardsUrl(userId: string, sessionId: string) {
    const link = "https://www.phase-6.de/pxp/content-sharing/?rid=" + userId + "&share_session_id=" + sessionId;
    return (
        "https://r5bf2.app.goo.gl/?link=" +
        encodeURIComponent(link) +
        "&apn=de.phase6.freeversion.beta&isi=441493173&ibi=de.phase-6.free-version"
    );
}
