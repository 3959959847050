import React, { ReactNode } from "react";
import { StyledActions } from "./styles";

interface DropdownActionsProps {
    renderActions(): ReactNode;
}

export const DropdownActions = ({ renderActions }: DropdownActionsProps) => {
    return <StyledActions>{renderActions()}</StyledActions>;
};
