import styled from "styled-components";
import { StyleTheme } from "p6m-themes";
import { PopupContainer } from "../../basic/popup/styles";
import PhaseSixIcon from "../../basic/phaseSixIcon/PhaseSixIcon";
import notificationBackgroundImg from "../../../assets/img/notifications/ma_notification.png";

type TProps = {
    theme: StyleTheme;
};

const topNavigationColor = ({ theme: { colors } }: TProps) => colors.textOnBackground;
const lightColor = ({ theme: { colors } }: TProps) => colors.text;
const primaryColor = ({ theme: { colors } }: TProps) => colors.primary;
const spacingSmall = ({
    theme: {
        base: { spacingSmall = "12px" },
    },
}: TProps) => spacingSmall;
const spacingTiny = ({
    theme: {
        base: { spacingTiny = "4px" },
    },
}: TProps) => spacingTiny;
const borderRadius = ({ theme: { base } }: TProps) => base.borderRadius;
const baseSize = ({ theme: { base } }: TProps) => base.mediumSize;
const baseSmallSize = ({ theme: { base } }: TProps) => base.size;

const flexCenter: string = `
    display: flex;
    align-items: center;
`;

const flexCenterSpcBtw: string = `
    ${flexCenter}
    justify-content: space-between;
`;

export const StyledPhaseSixIcon = styled(PhaseSixIcon)`
    ${flexCenter};
    margin: 0 ${spacingSmall};
    color: ${topNavigationColor};
    cursor: pointer;
`;

export const Popup = styled.div`
    min-width: 450px;
    font-size: ${baseSize};
    color: ${lightColor};
    font-size: ${baseSize};
`;

export const PopupHeader = styled(PopupContainer)`
    ${flexCenterSpcBtw};
    height: 50px;
    font-size: 1.2em;
    color: ${primaryColor};
    text-transform: uppercase;
    line-height: 1;
    border: none !important;
`;

export const Button = styled.button`
    padding: 0;
    background: none;
    border: none;
    font-size: inherit;
    color: ${lightColor};
    text-transform: inherit;
    line-height: inherit;
    cursor: pointer;
    transition: color ${({ theme: { base } }) => base.transition}s;

    &:focus {
        outline: none;
    }

    &:hover:not(:disabled) {
        color: ${primaryColor};
    }

    &:disabled {
        cursor: default;
        opacity: ${({ theme: { base } }) => base.disabledOpacity};
    }
`;

export const PopupBody = styled(PopupContainer)`
    max-height: 325px;
    overflow-y: auto;
    padding-top: 0;
    padding-bottom: 0;
    border-bottom: none !important;
`;

export const PopupItem = styled.div<{ highlighted?: boolean }>`
     {
        ${flexCenter};
        position: relative;
        margin-top: ${spacingTiny};
        padding: ${spacingSmall};
        border-radius: ${borderRadius};
        color: ${lightColor};
        min-height: 80px;

        ${({ highlighted = false }) => (highlighted ? `background-image: url(${notificationBackgroundImg});` : "")}
        ${({ highlighted = false, theme }) =>
            !highlighted
                ? `
                    background: ${theme.colors.backgroundMain};
                    color: ${({ theme: { colors } }: TProps) => colors.text};
                `
                : ""}
        &:last-child {
            margin-bottom: ${({ theme: { base } }) => base.spacingSmall};
        }

        &:first-child {
            margin-top: 0;
        }

        & ${Button} {
            font-size: 1.2em;
            text-transform: uppercase;
            line-height: 1;
        }
    }
`;

export const PopupItemShowMore = styled(PopupItem)`
    ${flexCenter};
    justify-content: center;
    cursor: pointer;
`;

export const PopupImage = styled.div`
     {
        max-height: 80px;
        margin-right: ${spacingSmall};
        text-align: center;

        & > img {
            display: block;
            max-width: 100%;
            max-height: inherit;
            border-radius: ${({ theme: { base } }) => base.borderRadiusSmall};
        }

        label {
            justify-content: center;
        }

        input[type="checkbox"] {
            margin: 0;
        }
    }
`;

export const PopupContent = styled.div`
    width: 100%;
    font-size: ${baseSize};

    & > p {
        color: ${({ theme: { colors } }) => colors.primary};
        font-weight: bold;
        padding-right: ${(props) => props.theme.base.spacingBig};
    }

    p {
        margin: 0;
    }
`;

export const PopupContentFooter = styled.div`
    ${flexCenterSpcBtw};
    align-items: flex-end;
    font-size: ${baseSmallSize};
    color: ${({ theme: { colors } }) => colors.text};

    & a {
        color: ${({ theme: { colors } }) => colors.primary};
    }

    & > div:last-child {
        white-space: nowrap;
    }
`;

export const PullRight = styled.div`
    margin-left: auto;
`;

export const PopupDeleteItem = styled.div`
    position: absolute;
    right: 5px;
    top: 5px;
    cursor: pointer;
`;

export const InheritColorPhaseSixIcon = styled(PhaseSixIcon)`
    color: inherit;
`;

export const EmptyBox = styled.h2`
    text-align: center;
    font-weight: normal;
`;
