//DEPENDENCIES
import styled from "styled-components";

export const Title = styled.span`
    text-transform: uppercase;
`;

export const ContentContainer = styled.div`
    padding: ${(props) => props.theme.base.spacing};
    background-color: #fff;
    min-height: 250px;
`;

export const QATitle = styled.h3`
    margin: 0;
    color: ${(props) => props.theme.colors.text};
    font-size: ${({ theme: { base } }) => base.bigSize};
    font-weight: normal;
    text-transform: uppercase;
`;

export const ModalFooter = styled.div`
    padding: ${({ theme: { base } }) => base.spacing};
`;
