import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../store/store";
import { SettingsView } from "../../helpers/Modal";

interface SubjectsState {
    settingsView: SettingsView;
}

const initialState: SubjectsState = {
    settingsView: SettingsView.Main,
};

export const settingsSlice = createSlice({
    name: "settings",
    initialState,

    reducers: {
        setSettingsView: (state, action: PayloadAction<SettingsView>) => {
            state.settingsView = action.payload;
        },
    },
});

/* EXPORTS */
export const { actions, reducer } = settingsSlice;

export const selectors = {
    settingsView: (state: RootState) => state.settings.settingsView,
};
