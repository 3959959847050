export const getPreferredMimeType = () => {
    //@ts-ignore
    if (MediaRecorder.isTypeSupported("audio/webm")) {
        return "audio/webm; codecs=opus";
        //@ts-ignore
    } else if (MediaRecorder.isTypeSupported("audio/mp4")) {
        return "audio/mp4";
    }
    return "";
};
