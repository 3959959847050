import { importAll } from "./Image";
import others from "../assets/img/smilies/smiley-10.png";

export function getSmileImage(key: string): string {
    try {
        const images = importAll(require.context("../assets/img/smilies/", false, /[a-zA-Z]*\.png$/));
        const name: string = `./${key}`;
        return images[name] || others;
    } catch (e) {
        return others;
    }
}
