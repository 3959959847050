import styled from "styled-components";

export const StyledText = styled.p`
    flex: 1;
    text-align: center;
    font-size: ${(props) => props.theme.base.mediumSize};
    font-family: ${(props) => props.theme.base.fontFamily};
    text-decoration: none;
`;

export const StyledOuterContainer = styled.div`
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 0;
    width: 100%;
    z-index: 9999;
    justify-content: center;
    align-items: center;
`;

const StyledContainer = styled.div`
    position: absolute;
    top: 80px;
    padding: 10px 30px;
    border-width: 2px;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
`;

export const StyledErrorContainer = styled(StyledContainer)`
    background-color: ${(props) => props.theme.colors.red};
    border-color: ${(props) => props.theme.response.error.borderColor};
    color: ${(props) => props.theme.response.error.textColor};
`;

export const StyledWarningContainer = styled(StyledContainer)`
    background-color: #e6ad00;
    border-color: ${(props) => props.theme.response.warning.borderColor};
    color: ${(props) => props.theme.response.warning.textColor};
`;

export const StyledSuccessContainer = styled(StyledContainer)`
    background-color: ${(props) => props.theme.colors.green};
    border-color: ${(props) => props.theme.response.success.borderColor};
    color: ${(props) => props.theme.response.success.textColor};
`;
