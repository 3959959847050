import styled from "styled-components";

export const TimeLineItem = styled.div`
    position: relative;
    z-index: 2;
    width: 100%;
    height: 100%;
    border-left: 3px solid ${({ theme: { colors } }) => colors.backgroundMain};
    &:first-child {
        border: none;
    }
`;

export const TimeLineWrapper = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    position: relative;
    height: 35px;
    border-radius: 17px;
    background-color: ${({ theme: { colors } }) => colors.backgroundContrast};
    overflow: hidden;

    & > ${TimeLineItem} {
        &:first-child,
        &:first-child + ${TimeLineItem} {
            width: 16.55%;
            min-width: 16.55%;
        }
        &:last-of-type {
            min-width: 33.33%;
        }
    }
`;

export const TimeLineCounter = styled.div<{ position: number }>`
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
    width: ${({ position }) => position + "%"};
    height: 100%;
    background-color: ${({ position, theme: { colors } }) => {
        if (position > 33.333) return colors.text + "cc";
        return colors.text + "70";
    }};
    transition: width 0.5s;
`;

export const Title = styled.div<{ position: number }>`
    position: absolute;
    left: 0;
    top: 0;
    z-index: 3;
    width: 100%;
    font-size: ${({ theme: { base } }) => base.bigSize};
    color: ${({ position, theme: { colors } }) => (position > 50 ? "#fff" : colors.text)};
    vertical-align: middle;
    text-align: center;
    line-height: 35px;
`;
