//LIBRARIES
import React, { useCallback, ReactNode } from "react";
import { useT } from "@transifex/react";

//STYLED COMPONENTS
import { DefaultHeader } from "../../../../basic/modal/DefaultHeader";
import { Background, Content, Description, ButtonsContainer, ContentWrapper } from "./styles";

//TYPES
import { GlobalModalProps } from "../../../../../helpers/Modal";
import { FilledButton, OutlinedButton } from "../../../../basic/button/Button";

interface ModifyCardProps extends GlobalModalProps {
    title: string;
    confirmButtonText: string;
    descriptionWarning: ReactNode;
    additionalNotice?: ReactNode;
    onClickMethod: () => void;
}

const ModifyCard: React.FC<ModifyCardProps> = (props) => {
    const t = useT();

    const cancel_t = t("Cancel", { _tags: "modal,button" });

    const { title, confirmButtonText, descriptionWarning, additionalNotice = false, onClickMethod, close } = props;
    const handleClose = useCallback(() => {
        close();
    }, [close]);

    return (
        <Background>
            <DefaultHeader
                variant="error"
                title={title}
                close={handleClose}
            />
            <ContentWrapper>
                <Content>
                    <Description>{descriptionWarning}</Description>
                    {additionalNotice && <Description>{additionalNotice}</Description>}
                </Content>
                <ButtonsContainer>
                    <OutlinedButton
                        onClick={handleClose}
                        useModalZIndex
                        upperCase
                    >
                        {cancel_t}
                    </OutlinedButton>
                    <FilledButton
                        onClick={onClickMethod}
                        highlighted
                        useWarningColor
                        upperCase
                        useModalZIndex
                    >
                        {confirmButtonText}
                    </FilledButton>
                </ButtonsContainer>
            </ContentWrapper>
        </Background>
    );
};

export default ModifyCard;
