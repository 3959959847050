import { takeEvery, put, call, select } from "redux-saga/effects";
import { AxiosResponse } from "axios";
import { IResponse } from "p6m-response";
import { PayloadAction } from "@reduxjs/toolkit";
import { actions, selectors } from "./practiceSlice";
import { actions as appStatusActions } from "../appStatus/appStatusSlice";
import { getSubjectUnitCards } from "../../networking/subjects";
import { SubjectUnitCard } from "p6m-subjects";
import { selectors as userSelectors } from "../user/userSlice";
import { selectors as subjectsSelectors } from "../subjects/subjectsSlice";
import { getCardCountForLimitedTodayTraining } from "../../helpers/Subjects";
import { Item, Slice, ResultActions } from "p6m-practice";
import { compareAnswer, compareAnswerNoTyping, cardActivationFiltered, submitAnswer } from "../../networking/practice";

function* startPracticeWithDueCards({ payload }: PayloadAction<{ subjectId: string }>) {
    yield put(appStatusActions.setLoading(true));
    yield put(actions.stopPractice());
    try {
        const { subjectId } = payload;
        const filterMode = "PRACTICE";
        const subject = yield select(subjectsSelectors.getSubjectById(subjectId));
        const { tthLimit } = yield select(userSelectors.userPreferences);
        const limit = getCardCountForLimitedTodayTraining(subject, tthLimit);
        const response: AxiosResponse<IResponse<{ cards: SubjectUnitCard[] }>> = yield call(getSubjectUnitCards, {
            subjectId,
            filterMode,
        });
        const {
            data: {
                replyContent: { cards: responseCards },
            },
        } = response;
        const cards = responseCards.slice(0, limit); // since backend doesn't account for limit filter, we cut here again. Remove later
        yield put(actions.startPractice({ subjectId, cards, type: 3, direction: 0, unit: [] }));
    } catch (e) {
        console.log(e);
    } finally {
        yield put(appStatusActions.setLoading(false));
    }
}

function* startPracticeWithAdditionalCards({ payload }: PayloadAction<{ subjectId: string }>) {
    yield put(appStatusActions.setLoading(true));
    yield put(actions.stopPractice());
    try {
        const { subjectId } = payload;
        const filterMode = "LEARN_NEW";
        const limit = yield select(subjectsSelectors.additionalCardsCanBePracticedToday);
        const response: AxiosResponse<IResponse<{ cards: SubjectUnitCard[] }>> = yield call(getSubjectUnitCards, {
            subjectId,
            filterMode,
        });
        const {
            data: {
                replyContent: { cards: responseCards },
            },
        } = response;
        const cards = responseCards.slice(0, limit); // since backend doesn't account for limit filter, we cut here again. Remove later
        yield put(actions.startPractice({ subjectId, cards, type: 3, direction: 0, unit: [] }));
    } catch (e) {
        console.log(e);
    } finally {
        yield put(appStatusActions.setLoading(false));
    }
}

function* sendAnswerAsync({
    payload,
}: PayloadAction<{
    slice: Slice;
    item: Item;
    card: SubjectUnitCard;
    actionType: keyof ResultActions;
    value?: string;
    callback: (result: true | string, newData?: SubjectUnitCard) => void;
}>) {
    yield put(appStatusActions.setLoading(true));
    try {
        const { slice, item, actionType, value, card, callback } = payload;
        const cardId = card.cardIdToOwner.id;

        const sessionId: string = yield select(selectors.getPracticeSessionId);

        const {
            normal,
            opposite,
            cardContent: { answer, question },
            cardIdToOwner: { ownerId },
            subjectIdToOwner: { id: subjectIdString },
            unitIdToOwner: { id: unitIdString },
        } = card;

        const { type } = slice;

        const force: boolean | undefined = (
            {
                onMemorize: true,
                onAsCorrect: true,
                onDontKnow: false,
            } as any
        )[actionType];

        const learnDirection = ["NORMAL", "OPPOSITE"][+item.opposite] as "NORMAL" | "OPPOSITE";

        const questionData = [normal, opposite][+item.opposite];
        const correctAnswerText = [answer, question][+item.opposite]
            .replace(/(<([^>]+)>)/gi, " ")
            .replace(/(\[{([^>]+)}\])/gi, " ")
            .trim();

        const phaseBeforeAnswering = questionData.phase;

        const compareData: any = {
            correctAnswerText,
            learnDirection,
            phaseBeforeAnswering,
            userAnswerText: value,
            forceRight: force === true ? true : undefined,
            forceWrong: force === false ? true : undefined,
        };

        const compareAction = [compareData.forceRight, compareData.forceWrong].includes(true)
            ? compareAnswerNoTyping
            : compareAnswer;

        const {
            data: {
                replyContent: { isCorrect },
            },
        } = yield call(compareAction, { ownerId, cardId, data: compareData });

        if (!isCorrect) return callback("Wrong Answer");

        const memoAction = function* () {
            const requestData = {
                activate: true,
                direction: learnDirection,
                cards: [cardId],
                subjectId: subjectIdString,
            };
            const {
                data: {
                    replyContent: {
                        cards: [newCard],
                    },
                },
            } = yield call(cardActivationFiltered, requestData);

            return newCard;
        };

        const practiceAction = function* () {
            const requestData = {
                answerCorrect: isCorrect,
                answerText: value || "",
                learnDirection,
                phaseBeforeAnswering,
                type: "NORMAL" as "NORMAL",
                sessionId,
            };
            const {
                data: { replyContent },
            } = yield call(submitAnswer, { ownerId, cardId, data: requestData });

            if (!replyContent) return;

            const {
                data: {
                    replyContent: {
                        cards: [newCard],
                    },
                },
            } = yield call(getSubjectUnitCards, {
                cards: [cardId],
                subjectId: subjectIdString,
                filterMode: "LIBRARY",
                units: [unitIdString],
            });

            return newCard;
        };

        const action = type === "practice" ? practiceAction : memoAction;

        const updatedCard: SubjectUnitCard | undefined = yield action();

        if (updatedCard) {
            yield put(actions.updateCard(updatedCard));
        }

        callback(true, updatedCard);
    } catch (e) {
        console.log(e);
    } finally {
        yield put(appStatusActions.setLoading(false));
    }
}

function* activateCardsAsync({
    payload,
}: PayloadAction<{
    cardsId: string[];
    subjectId: string;
    direction: "NORMAL" | "OPPOSITE";
}>) {
    yield put(appStatusActions.setLoading(true));
    try {
        const { cardsId, subjectId, direction } = payload;
        const requestData = {
            activate: true,
            direction,
            cards: cardsId,
            subjectId,
        };
        const {
            data: {
                replyContent: { cards },
            },
        } = yield call(cardActivationFiltered, requestData);

        if (!cards) return;

        yield cards.map((newCard: any) => put(actions.updateCard(newCard)));
    } catch (e) {
        console.log(e);
    } finally {
        yield put(appStatusActions.setLoading(false));
    }
}

export function* practiceSaga() {
    yield takeEvery(actions.startPracticeWithDueCards.type, startPracticeWithDueCards);
    yield takeEvery(actions.startPracticeWithAdditionalCards.type, startPracticeWithAdditionalCards);
    yield takeEvery(actions.sendAnswer.type, sendAnswerAsync);
    yield takeEvery(actions.activateAllCards.type, activateCardsAsync);
}
