import React, { useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
    actions as goalsActions,
    goalsToShowDerivedSelector,
    selectors as goalsSelectors,
} from "../../redux/goals/goalsSlice";
import Component from "./Achievements";
import { GoalsContext } from "../../context/goals";

// HOOKS
import { useFirstPracticeResult } from "../../hooks/useFirstPracticeResult";

const AchievementsWrapper: React.FC = () => {
    const userGoals = useSelector(goalsSelectors.goals);
    const goalsToShow = useSelector(goalsToShowDerivedSelector);
    const goalsContext = useContext(GoalsContext);
    const hasFirstPractice = useFirstPracticeResult();

    const history = useHistory();
    const dispatch = useDispatch();

    // refresh achievements on render once, to ensure the data is always up to date for the user
    useEffect(() => {
        dispatch(goalsActions.fetchGoals());
    }, []);

    useEffect(() => {
        if (hasFirstPractice || !userGoals) return;

        history.push("/home");
    }, [dispatch, hasFirstPractice, history, userGoals]);

    // do not show if user goals aren't loaded yet
    if (userGoals) {
        return (
            <Component
                userGoals={userGoals}
                goalsToShow={goalsToShow}
                goals={goalsContext}
            />
        );
    }
    return null;
};

export default AchievementsWrapper;
