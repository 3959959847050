//LIBRARIES
import React from "react";
import styled from "styled-components";
import { T, useT } from "@transifex/react";

//COMPONENTS
import { DefaultHeader } from "../../basic/modal/DefaultHeader";

//TYPES
import { GlobalModalProps } from "../../../helpers/Modal";

//STYLES
import { BlankButton, FilledButton } from "../../basic/button/Button";

interface DeleteMediaModalProps extends GlobalModalProps {
    onDeleteMedia: () => void;
}

const Background = styled.div`
    height: 100%;
    width: 100%;
    padding: 0;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    overflow: hidden;
`;
const Content = styled.div`
    display: flex;
    width: 100%;
    padding: ${(props) => props.theme.base.spacing};
    background: ${(props) => props.theme.colors.backgroundMain};
    border-bottom: 1px solid ${(props) => props.theme.colors.textLight};
`;
const ButtonsContainer = styled.div`
    width: 100%;
    padding: ${(props) => props.theme.base.spacing};
    background: ${(props) => props.theme.colors.backgroundMain};
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: ${(props) => props.theme.base.spacing};
`;

const Description = styled.p``;

const DeleteMediaModal: React.FC<DeleteMediaModalProps> = (props) => {
    const t = useT();

    const delete_title_t = t("Delete Media", { _tags: "media,modal,title" });
    const cancel_t = t("Cancel", { _tags: "media,modal,button" });
    const delete_t = t("Delete", { _tags: "media,modal,button" });

    return (
        <Background>
            <DefaultHeader
                variant="error"
                title={delete_title_t}
                close={props.close}
            />
            <Content>
                <Description>
                    <T _str="Are you sure you want to delete this media item?" />
                </Description>
            </Content>
            <ButtonsContainer>
                <BlankButton
                    onClick={props.close}
                    useWarningColor
                    upperCase
                    useModalZIndex
                    textSize="xl"
                >
                    {cancel_t}
                </BlankButton>
                <FilledButton
                    onClick={props.onDeleteMedia}
                    useWarningColor
                    upperCase
                    useModalZIndex
                    textSize="xl"
                >
                    {delete_t}
                </FilledButton>
            </ButtonsContainer>
        </Background>
    );
};

export default DeleteMediaModal;
