// REACT
import React from "react";

// STYLED COMPONENTS
import { InputContainer, StyledLabel, StyledInput, StyledLabelText } from "./styles";

export interface CheckboxInputProps {
    checked: boolean;
    onChange: (checked: boolean) => void;
    size?: number;
    label: string;
    restProps?: any;
}

const CheckboxInput: React.FC<CheckboxInputProps> = (props) => {
    const { onChange, checked, label, restProps = {} } = props;

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        onChange(event.target.checked);
    };

    return (
        <InputContainer {...restProps}>
            <StyledLabel>
                <StyledInput
                    type={"checkbox"}
                    onChange={handleChange}
                    checked={checked}
                    size={props.size}
                />
                <StyledLabelText>{label}</StyledLabelText>
            </StyledLabel>
        </InputContainer>
    );
};

export default CheckboxInput;
