// REACT
import React, { useCallback, useState } from "react";

// REDUX
import { useDispatch, useSelector } from "react-redux";
import { actions as userActions, selectors as userSelectors } from "../../../redux/user/userSlice";

// NETWORKING
import { SaveUserPreferencesParams } from "p6m-networking";

// COMPONENTS
import SettingsPhases from "./SettingsPhases";

// STYLED COMPONENTS
import {
    arrayToPhasesRequestObject,
    calculateLearnPeriod,
    calculatePhaseDurations,
    getDefaultPhases,
    phasesRequestObjToArray,
} from "../../../helpers/Settings";

// LIBRARIES
import { debounce } from "lodash";

const updatePhasesInBackendDebounced = debounce(
    (newPhases, functionToCall) => {
        functionToCall(newPhases);
    },
    1000,
    {
        leading: false,
        trailing: true,
    }
);

const SettingsPhasesView: React.FC = () => {
    const userPreferences = useSelector(userSelectors.userPreferences);
    let backendPhases: Array<number>;

    if (userPreferences.phases?.phaseList && userPreferences.phases.phaseList.length > 2) {
        backendPhases = phasesRequestObjToArray(userPreferences.phases.phaseList);
    } else {
        backendPhases = getDefaultPhases();
    }

    const [userPhases, setUserPhases] = useState<Array<number>>(backendPhases);
    const [learnedPeriod, setLearnedPeriod] = useState<number>(calculateLearnPeriod(backendPhases));
    const [selectedNumberOfPhases, setSelectedNumberOfPhases] = useState<number>(backendPhases.length);
    const [phasesDuration, setPhasesDuration] = useState<number>(backendPhases[backendPhases.length - 1]);

    const dispatch = useDispatch();

    const updateUserPreferences = useCallback(
        async (newPhases: Array<number>) => {
            const newPreferences = { ...userPreferences };
            newPreferences.phases = {
                phaseList: arrayToPhasesRequestObject(newPhases),
            };

            const request: SaveUserPreferencesParams = {
                newPreferences: newPreferences,
                oldPreferences: { ...userPreferences },
            };
            dispatch(userActions.saveUserPreferences(request));
        },
        [userPreferences, dispatch]
    );

    const updateCustomPhases = (phasesNo: number, retention: number) => {
        const newPhases = calculatePhaseDurations(phasesNo, retention);
        //TO DO: Find out why sometimes phases are messed up and too short and prevent us from saving too short list to backend
        if (newPhases.length >= 4) {
            updateUserPhases(newPhases);
            updatePhasesInBackendDebounced(newPhases, updateUserPreferences);
        }
    };

    const updateUserPhases = (phases: Array<number>) => {
        //TO DO: Find out why sometimes phases are messed up and too short and prevent us from saving too short list to backend
        //Quick fix for now: Reset to default-values
        const phasesToUse = phases?.length >= 4 ? phases : getDefaultPhases();

        setUserPhases(phasesToUse);
        setLearnedPeriod(calculateLearnPeriod(phasesToUse));
        setSelectedNumberOfPhases(phasesToUse.length);
        setPhasesDuration(phasesToUse[phasesToUse.length - 1]);
    };

    const generalProps = {
        updatePhases: updateCustomPhases,
        learnedPeriod,
        selectedNumberOfPhases,
        phasesDuration,
        userPhases,
    };

    return <SettingsPhases {...generalProps} />;
};

export default SettingsPhasesView;
