import styled from "styled-components";

export const FixedWrapper = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: ${({ theme: { base } }) => base.modalZIndex};
    opacity: 1;
    transition: opacity 0.3s, height 0s 0.3s;
`;

export const ModalWrapper = styled.div`
    display: flex;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
    box-sizing: border-box;
    min-height: 100%;
    height: 100%;
    width: 100%;
    padding: 20px 0;
`;

export const Background = styled.div`
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 1;
    background-color: rgba(0, 0, 0, 0.5);
`;

export const ModalBody = styled.div<{ width?: number }>`
    display: flex;
    position: relative;
    z-index: 2;
    box-sizing: border-box;
    width: ${({ width = 768 }) => width}px;
    max-width: 90%;
    max-height: 90vh;
    margin: auto;
    border-radius: 5px;
    background-color: rgba(0, 0, 0, 0);
`;

export const ModalContent = styled.div`
    display: flex;
    flex: 1;
    padding: ${({ theme: { base } }) => base.spacing};
    width: inherit;
`;

export const CloseIcon = styled.div`
    float: right;
    position: relative;
    width: 1em;
    height: 1em;
    margin-left: 0.5em;
    cursor: pointer;
    transform: rotate(45deg);

    &:before,
    &:after {
        content: " ";
        position: absolute;
        left: 0;
        top: 0;
        width: 0.5em;
        height: 0.5em;
        border: solid;
        border-color: #fff;
    }

    &:before {
        border-width: 0 2px 2px 0;
    }

    &:after {
        left: 0.5em;
        top: 0.5em;
        border-width: 2px 0 0 2px;
    }
`;

export const ModalHeader = styled.div`
    padding: ${({ theme: { base } }) => base.spacingSmall};
    background-color: ${({ theme: { colors } }) => colors.primary};
    color: #fff;
    font-size: ${({ theme: { base } }) => base.size};
    text-transform: uppercase;
`;
