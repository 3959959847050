//LIBRARIES
import React from "react";
import { T, useT } from "@transifex/react";

//STYLED COMPONENTS
import { DescriptionHighlighted } from "../../../complex/cardActionButtons/Modals/ModifyCard/styles";

// COMPONENTS
import ModifyCard from "../../../complex/cardActionButtons/Modals/ModifyCard";

//TYPES
import { GlobalModalProps } from "../../../../helpers/Modal";

interface ChangeCardDirectionProps extends GlobalModalProps {
    changeableCardCount: number;
    selectedCardCount?: number;
    onClick: () => void;
}

const ChangeCardDirectionModal: React.FC<ChangeCardDirectionProps> = (props) => {
    const t = useT();

    const change_direction_title_t = t("Change language direction", { _tags: "changeDirection,modal,title" });
    const ok_t = t("Ok", { _tags: "changeDirection,modal,button" });

    const { changeableCardCount, selectedCardCount = 0, onClick } = props;
    const nonDeletableCardCount = selectedCardCount - changeableCardCount;

    const cardAmount = <DescriptionHighlighted>{changeableCardCount}</DescriptionHighlighted>;
    const cardAmountNonDeletable = <DescriptionHighlighted>{nonDeletableCardCount}</DescriptionHighlighted>;
    const descriptionWarning = (
        <T
            _str="You are about to change the language direction for {cardAmount} card(s)."
            cardAmount={cardAmount}
        />
    );
    const additionalNotice = changeableCardCount > 0 && nonDeletableCardCount > 0 && (
        <T
            _str="Please note that {cardAmount} card(s) do not have an active learning direction. Their direction cannot be changed."
            cardAmount={cardAmountNonDeletable}
            _tags="delete,modal,warning"
        />
    );

    return (
        <ModifyCard
            confirmButtonText={ok_t}
            title={change_direction_title_t}
            descriptionWarning={descriptionWarning}
            additionalNotice={additionalNotice}
            onClickMethod={onClick}
        />
    );
};

export default ChangeCardDirectionModal;
