//LIBRARIES
import React, { FunctionComponent, useMemo, useCallback } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

// REDUX
import { actions } from "../../../redux/activation/activationSlice";
import { actions as learningActions } from "../../../redux/learning/learningSlice";
import { selectors as subjectsSelector } from "../../../redux/subjects/subjectsSlice";
import { selectors as testsSelectors, actions as testsActions } from "../../../redux/tests/testsSlice";

//NETWORK
import { saveTest, updateTest } from "../../../networking/tests";

//HOOKS
import { useUnitsInfoFromCards } from "../../../hooks/useUnitsInfoFromCards";

//COMPONENTS
import Component from "./ActivationNavigationButtons";
import { TResult, activationDirectionList, activationSteps } from "../../../views/activation";

//TYPES
import { SubjectData } from "p6m-subjects";

export type ActivationNavigationButtonsProps = {
    isPrepareForTest: boolean;
    activationStep: activationSteps;
    result: TResult;
    onGoToActivationList: () => void;
    onGoToActivationSummary: () => void;
};

export const ActivationNavigationButtons: FunctionComponent<ActivationNavigationButtonsProps> = ({
    isPrepareForTest,
    activationStep,
    result,
    onGoToActivationList,
    onGoToActivationSummary,
}) => {
    const { subjectId } = useParams<{ subjectId: string }>();
    const { unitNamesString } = useUnitsInfoFromCards({
        cards: result.cards || [],
        subjectId: subjectId,
        displayedUnitsLimit: -1,
    });
    const dispatch = useDispatch();
    const history = useHistory();
    const sharedTestMetadata = useSelector(testsSelectors.sharedTestMetaData);
    const activationMetadata = useSelector(testsSelectors.activationMetaData);
    const shouldSkipSummaryPage = !!activationMetadata;

    const { subjectMetadata } = useSelector(subjectsSelector.getSubjectById(subjectId)) || ({} as SubjectData);

    const cardsAmount = useMemo(() => result.cards?.length || 0, [result]);

    const handleGoBack = useCallback(() => {
        if (shouldSkipSummaryPage) {
            history.goBack();
        } else {
            onGoToActivationList();
        }
    }, [shouldSkipSummaryPage, history, onGoToActivationList]);

    const goToPractice = useCallback(async () => {
        const { cards = [], type = 0, direction = 0 } = result;
        const noCards = !cards.length;

        const testWithFalsyMetaData =
            unitNamesString &&
            isPrepareForTest &&
            (!subjectMetadata?.subjectIdToOwner || !activationDirectionList[direction]);

        if (noCards || testWithFalsyMetaData) return;

        //Test shall not be saved for every practice, but only when practice is test-preparation
        let testId = "";
        if (isPrepareForTest) {
            const cardIds = cards.map((card) => card.cardIdToOwner);
            const testData = {
                testName: unitNamesString,
                cardIds,
                subjectContentId: subjectMetadata.subjectIdToOwner,
                direction: activationDirectionList[direction],
            };

            if (shouldSkipSummaryPage) {
                // user is currently doing a test -> update should only affect direction change here
                testId = activationMetadata!.testId;
                await updateTest(activationMetadata!.testId, testData);

                dispatch(testsActions.setSharedTestMetaData(null));
            } else {
                testId = await saveTest(testData);
            }
        }

        const startData = {
            subjectId,
            type: isPrepareForTest ? 3 : type,
            direction,
            cards,
            history,
            filter: "LIBRARY",
            isPrepareForTest,
            testId,
            prepareForTestType: isPrepareForTest ? type : undefined,
        };

        if ((isPrepareForTest && type === 0) || (!isPrepareForTest && type === 3)) {
            return dispatch(actions.activateAndStart(startData));
        }

        dispatch(learningActions.start(startData));
    }, [
        result,
        subjectId,
        isPrepareForTest,
        dispatch,
        history,
        subjectMetadata,
        unitNamesString,
        shouldSkipSummaryPage,
        sharedTestMetadata,
    ]);

    const handleContinueActivation = useCallback(() => {
        if (activationStep === activationSteps.LIST) {
            if (!cardsAmount) return;
            onGoToActivationSummary();
        } else if (activationStep === activationSteps.SUMMARY) {
            goToPractice();
        }
    }, [activationStep, cardsAmount, onGoToActivationList, goToPractice]);

    return (
        <Component
            activationStep={activationStep}
            isSharedTestActivation={shouldSkipSummaryPage}
            cardsAmount={cardsAmount}
            onGoBack={handleGoBack}
            onCancel={history.goBack}
            onContinue={handleContinueActivation}
        />
    );
};
