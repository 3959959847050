export enum GlobalModalView {
    None,
    Settings,
    MailAddressConfirmation,
    Share,
    AddSubject,
    ChangeCardSubject,
    ResetSubjectCards,
    ConfirmEdit,
    GDPRModal,
    DeleteContent,
    DeleteMedia,
    Agreement,
    ChooseIncludedContent,
    ChangeCardDirection,
    ChangeCardActivationStatus,
    ChangeCardPhaseStatus,
    ModifyCardAttributes,
    DataProtectionIframe,
    StrictUserImpressumIframe,
    Welcome,
    PromoteGrammarTutor,
    StrictPremium,
    SwitchInfo,
}

export enum SettingsView {
    Main,
    Family,
    Learning,
    Phases,
    General,
    Visual,
    Language,
    ChildrenSettings,
    Avatars,
}

export interface GlobalModalProps {
    close: () => any;
}

// Returns a Date object representing the start of the given date
export const getStartDateTimeOfDate = (date: Date) => {
    date.setHours(0, 0, 0, 0);
    return date;
};

// Returns a Date object representing the start of the user's registration date
export const getStartDateTimeOfRegistrationDate = (userDataRegistrationDate: string) => {
    //make sure the string that needs to be transformed is  ISO 8601 compliant (e.g. only accepted new Date()-format on firefox)
    return getStartDateTimeOfDate(new Date(`${userDataRegistrationDate.split(".").reverse().join("-")}T00:00:00.000Z`));
};

export const isModalOpen = () => !!document.querySelector("[role='dialog']");
