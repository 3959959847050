// REACT
import React, { FunctionComponent, memo, useState, useEffect, useRef } from "react";
import { useT } from "@transifex/react";

// COMPONENTS
import { Row, Col } from "../../../../basic/grid";
import PhaseSixIcon from "../../../../basic/phaseSixIcon/PhaseSixIcon";

// TYPES
import { UnitsList } from "p6m-subjects";

import {
    Wrapper,
    Title,
    Input,
    EditIcon,
    ActionsWrapper,
    ActionClose,
    ActionSave,
    DeleteWrapper,
    DeleteIcon,
    ItemInformationCol,
    ItemRow,
} from "./styles";

import { InputError } from "../ModalCreation/styles";

type Result = string;

export type Props = {
    unit: UnitsList[0];
    userDnsId?: string;
    edit?: boolean;
    setEdit?: (state: boolean) => void;
    onEdit: (unit: UnitsList[0], newData: Result) => Promise<void>;
    onDelete: (unit: UnitsList[0]) => void;
    onSelect: (unit: UnitsList[0]) => void;
};

const UnitItem: FunctionComponent<Props> = (props) => {
    const { unit, userDnsId, edit = false, setEdit = () => {}, onEdit, onDelete, onSelect } = props;
    const t = useT();
    const {
        cardCount = 0,
        unitContent: {
            name,
            order,
            subjectIdToOwner: { ownerId },
        },
    } = unit;
    const [deleteState, setDelete] = useState<boolean>(false);
    const [error, setError] = useState<string>("");

    const value = useRef(name);

    useEffect(() => {
        value.current = name;
        if (!edit) return;
        setDelete(false);
        setError("");
    }, [edit, name]);

    const t_placeholder = t("e.g. animals", {});
    const t_delete = t("Are you sure about deleting this unit?", {});
    const t_delete_alt = t(
        "{cardAmount} card(s) will be deleted. This action cannot be undone. Do you want to delete this unit now?",
        { cardAmount: cardCount }
    );

    const deleteText = cardCount ? t_delete_alt : t_delete;

    const isMyUnit: boolean = userDnsId === ownerId;

    const isGeneral = order === -1;
    const isNotAssigned = order === 999;

    const onChange = ({ target: { value: newValue } }: any) => {
        value.current = newValue;
        setError("");
    };

    return deleteState ? (
        <DeleteWrapper $edit={true}>
            <Row vertical="center">
                <Col>
                    <p>{deleteText}</p>
                </Col>
                <Col xs="auto">
                    <ActionsWrapper>
                        <ActionSave
                            $disabled={false}
                            onClick={() => {
                                onDelete(unit);
                                setDelete(false);
                            }}
                        >
                            <PhaseSixIcon name="exercise-ok" />
                        </ActionSave>
                        <ActionClose
                            onClick={() => {
                                setDelete(false);
                            }}
                        >
                            <PhaseSixIcon name="close" />
                        </ActionClose>
                    </ActionsWrapper>
                </Col>
            </Row>
        </DeleteWrapper>
    ) : (
        <Wrapper
            $edit={edit}
            onClick={() => {
                if (edit) return;
                onSelect(unit);
            }}
        >
            <ItemRow vertical="center">
                <ItemInformationCol>
                    <Row>
                        <Col xs={12}>
                            {edit ? (
                                <>
                                    <Input
                                        defaultValue={value.current}
                                        onChange={onChange}
                                        placeholder={t_placeholder}
                                        minLength={
                                            process.env.REACT_APP_INPUT_CHARS_MIN
                                                ? +process.env.REACT_APP_INPUT_CHARS_MIN
                                                : 1
                                        }
                                        maxLength={
                                            process.env.REACT_APP_INPUT_CHARS_MAX
                                                ? +process.env.REACT_APP_INPUT_CHARS_MAX
                                                : 254
                                        }
                                    />
                                    {!!error && <InputError>{error}</InputError>}
                                </>
                            ) : (
                                <Title>{name}</Title>
                            )}
                        </Col>
                    </Row>
                </ItemInformationCol>
                {edit ? (
                    <Col xs="auto">
                        <ActionsWrapper>
                            <ActionSave
                                $disabled={!value.current}
                                onClick={() => {
                                    onEdit(unit, value.current)
                                        .then(() => {
                                            setEdit(false);
                                        })
                                        .catch((e: any) => {
                                            if (e) setError(e);
                                        });
                                }}
                            >
                                <PhaseSixIcon name="exercise-ok" />
                            </ActionSave>
                            <ActionClose
                                onClick={() => {
                                    setEdit(false);
                                }}
                            >
                                <PhaseSixIcon name="close" />
                            </ActionClose>
                        </ActionsWrapper>
                    </Col>
                ) : (
                    isMyUnit &&
                    !isGeneral &&
                    !isNotAssigned && (
                        <Col
                            xs="auto"
                            onClick={(e: any) => e.stopPropagation()}
                        >
                            <ActionsWrapper>
                                <EditIcon
                                    onClick={() => {
                                        setEdit(true);
                                    }}
                                >
                                    <PhaseSixIcon name="annotation" />
                                </EditIcon>
                                <DeleteIcon
                                    onClick={() => {
                                        setDelete(true);
                                    }}
                                >
                                    <PhaseSixIcon name="trash" />
                                </DeleteIcon>
                            </ActionsWrapper>
                        </Col>
                    )
                )}
            </ItemRow>
        </Wrapper>
    );
};

export default memo(UnitItem);
