import React, { FunctionComponent, ReactNode } from "react";
import { ModalHeader, Title, CloseBtn, CloseIcon } from "./styles";
import { DefaultFocus } from "../defaultFocus/DefaultFocus";

interface DefaultHeaderProps {
    title: ReactNode;
    close: () => void;
    variant?: "default" | "error";
}

export const DefaultHeader: FunctionComponent<DefaultHeaderProps> = ({ title, close, variant = "default" }) => (
    <>
        <DefaultFocus />
        <ModalHeader error={variant === "error"}>
            <Title>{typeof title === "string" ? title.toUpperCase() : title}</Title>
            <CloseBtn
                aria-label="Close"
                onClick={close}
            >
                <CloseIcon name="close" />
            </CloseBtn>
        </ModalHeader>
    </>
);
