// REACT
import React, { FunctionComponent, useCallback, useMemo } from "react";

// REDUX
import { useDispatch, useSelector } from "react-redux";
import { selectors, actions } from "../../../../redux/learning/learningSlice";
import { actions as userActions, selectors as userSelectors } from "../../../../redux/user/userSlice";

// TYPES
import { State } from "p6m-learning";
import { SaveUserPreferencesParams } from "p6m-networking";

// COMPONENT
import Component, { Props as ComponentProps } from "./CardMenu";

export const CardMenu: FunctionComponent = () => {
    const { direction: itemDirection, type } = useSelector(selectors.currentItem);
    const { resolved: oppositeIsResolved = false } = useSelector(selectors.oppositeItem) || {};
    const {
        cardContent: { swappable },
        cardIdToOwner: { ownerId },
    } = useSelector(selectors.currentCard);
    const state = useSelector(selectors.state);
    const subject = useSelector(selectors.subject);
    const unit = useSelector(selectors.unit);
    const myId = useSelector(userSelectors.userId);
    const isPrepareForTest = useSelector(selectors.isPrepareForTest);

    const dispatch = useDispatch();

    const isMyCard = useMemo(() => myId === ownerId, [myId, ownerId]);

    const direction = useMemo(() => {
        if (isPrepareForTest) return undefined;
        // if not swappable
        if (!swappable) return undefined;
        // if not memorize
        if (type !== "memorize") return undefined;
        // if state isn't default
        if (state !== "default") return undefined;
        // can't change direction if opposite card is resolved
        if (oppositeIsResolved) return undefined;
        return itemDirection;
    }, [itemDirection, swappable, type, oppositeIsResolved, state, isPrepareForTest]);

    const showEditItem = useMemo(() => {
        const isMemo = type === "memorize";
        return isMyCard && (isPrepareForTest || (!isMemo && (["show", "wrong", "success"] as State[]).includes(state)));
    }, [type, isMyCard, state, isPrepareForTest]);

    const showFeedback = useMemo(() => {
        const isMemo = type === "memorize";
        return !isMyCard && !isMemo && (["show", "wrong", "success"] as State[]).includes(state);
    }, [type, isMyCard, state]);

    const onEdit = useCallback(() => {
        dispatch(actions.pushModal("update"));
    }, [dispatch]);

    const userPreferences = useSelector(userSelectors.userPreferences);
    const onToggleAudioSetting = useCallback(async () => {
        const newPreferences = { ...userPreferences };
        newPreferences.audioPlaybackSetting = !newPreferences.audioPlaybackSetting;

        let request: SaveUserPreferencesParams = {
            newPreferences: newPreferences,
            oldPreferences: { ...userPreferences },
        };
        dispatch(userActions.saveUserPreferences(request));
    }, [userPreferences, dispatch]);

    const isAutoplayEnabled = userPreferences.audioPlaybackSetting === true;

    const onChangeDirection = useCallback(
        (direction: ComponentProps["direction"]) => {
            if (!direction) return;
            dispatch(actions.changeDirection(direction));
        },
        [dispatch]
    );

    const onFeedback = useCallback(() => {
        dispatch(actions.pushModal("feedback"));
    }, [dispatch]);

    return (
        <Component
            subject={subject}
            unit={unit}
            direction={direction}
            showEditItem={showEditItem}
            showFeedback={showFeedback}
            isAutoplayEnabled={isAutoplayEnabled}
            onEdit={onEdit}
            onToggleAudioSetting={onToggleAudioSetting}
            onChangeDirection={onChangeDirection}
            onFeedback={onFeedback}
        />
    );
};
