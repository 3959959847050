import styled from "styled-components";

export const MainWrapper = styled.div`
    background: ${(props) => props.theme.colors.backgroundContrast};
    min-height: 50px;
    padding: ${(props) => props.theme.base.spacingSmall};

    &.invalid {
        border: solid 1px red;
    }
`;

export const TextAndInputWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

export const RangeSliderWrapper = styled.div`
    margin: ${(props) => props.theme.base.spacing} 0 ${(props) => props.theme.base.spacingSmall};
`;

export const SelectedItemInfo = styled.span`
    color: ${(props) => props.theme.colors.text};
    border: solid 1px ${(props) => props.theme.colors.backgroundMain};
    border-radius: ${(props) => props.theme.base.borderRadius};
    padding: 5px;
    margin: 0 0 0 5px;
    text-align: center;
`;

export const SliderDescriptionText = styled.p`
    color: ${(props) => props.theme.colors.text};
    max-width: 75%;
`;
