export const normalizeStr = (str: string) =>
    str
        .toLowerCase()
        /* german alphabet */
        .replace(/Ã¨|Ã©|Ãª|Ã«/, "e")
        .replace(/ö|Ö|Ã²|Ã³|Ã´|Ãµ|Ã¶/, "o")
        .replace(/Ã¬|Ã­|Ã®|Ã¯/, "i")
        .replace(/ä|Ä|Ã|Ã¡|Ã¢|Ã£|Ã¤|Ã¥|Ã¦/, "a")
        .replace(/ü|Ü|Ã¹|Ãº|Ã»|Ã¼/, "u")
        .replace(/ß|β/, "s")
        /* french alphabet */
        .replace(/À|à|Â|â|Ä|ä/, "a")
        .replace(/Ç|ç/, "c")
        .replace(/É|é|È|è|Ê|ê|Ë|ë/, "e")
        .replace(/Î|î|Ï|ï/, "i")
        .replace(/Ô|ô|Œ|œ|Ö|ö/, "o")
        .replace(/Ù|ù|Û|û|Ü|ü/, "u")
        .replace(/Ÿ|ÿ/, "y")
        /* spanish alphabet */
        .replace(/Ñ/, "n")
        /* European */
        .replace(/á|Á|ã|Ã|å|Å/, "a")
        .replace(/ì|Ì|í|Í/, "i")
        .replace(/ò|Ò|ó|Ó|õ|Õ/, "o")
        .replace(/ù|Ù|ú|Ú/, "u");
