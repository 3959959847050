// DEPENDENCIES
import React from "react";
import { T } from "@transifex/react";
import { useDispatch } from "react-redux";

// COMPONENTS
import Modal from "../../basic/modal/Modal";
import { GlobalModalView } from "../../../helpers/Modal";

// STATE
import { actions as modalActions } from "../../../redux/modal/modalSlice";

// STYLES
import { Actions, Description, ModalTextContainer, Title } from "./styles";
import { FilledButton } from "../../basic/button/Button";

export const StrictPremiumModal = () => {
    const dispatch = useDispatch();

    const closeModal = () => {
        dispatch(modalActions.setModalView(GlobalModalView.None));
    };

    return (
        <Modal
            active={true}
            preventCloseOnBg={true}
            title={
                <T
                    _str="PLUS"
                    _tags="premium,strict_mode"
                />
            }
            beforeClose={closeModal}
        >
            <ModalTextContainer>
                <Title>
                    <T
                        _str="Free use ended"
                        _tags="premium,strict_mode"
                    />
                </Title>
                <Description>
                    <T
                        _str="Please contact your school / institution"
                        _tags="premium,strict_mode"
                    />
                </Description>
            </ModalTextContainer>
            <Actions>
                <FilledButton
                    onClick={closeModal}
                    textSize="xl"
                    upperCase
                    useVerticalPadding
                    useModalZIndex
                >
                    <T
                        _str="OK"
                        _tags="premium,strict_mode"
                    />
                </FilledButton>
            </Actions>
        </Modal>
    );
};
