//DEPENDENCIES
import styled from "styled-components";

//STYLES
import PhaseSixIcon from "../../basic/phaseSixIcon/PhaseSixIcon";

export const ContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${({ theme: { base } }) => base.spacing};
    justify-content: center;
    align-items: center;
    padding: ${({ theme: { base } }) => base.spacingExtraBig};
    width: 100%;
`;

export const InfoWrapper = styled.div<{ isStrict?: boolean }>`
    display: flex;
    flex-direction: column;
    gap: ${({ theme: { base } }) => base.spacing};
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: ${({ isStrict, theme: { base } }) =>
        isStrict ? `${base.spacing} ${base.spacingInContent}` : `calc(${base.spacingInContent} * 4) 0`};

    & img {
        width: 120px !important;
        height: 160px !important;
    }
`;

export const Description = styled.p`
    text-align: center;
    font-size: ${(props) => props.theme.base.titleSize};
    display: flex;
    flex-direction: column;

    & span {
        text-align: center;
        color: ${(props) => props.theme.colors.primary};
        font-weight: ${(props) => props.theme.base.bigWeight};
    }
`;

export const WarningContainer = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    gap: ${({ theme: { base } }) => base.spacingTiny};
    padding: ${({ theme: { base } }) => base.spacingInContent};
    padding-bottom: ${({ theme: { base } }) => base.spacingBig};
    font-size: ${(props) => props.theme.base.size};
    color: ${(props) => props.theme.colors.text};
`;

export const SubjectTitle = styled.p`
    font-weight: ${(props) => props.theme.base.bigWeight};
`;

export const StrictModeInfo = styled.div`
    padding: ${({ theme: { base } }) => base.spacingInContent};
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: ${({ theme: { base } }) => base.spacingTiny};
    font-size: ${({ theme: { base } }) => base.size};
`;

export const ButtonWrapper = styled.div`
    display: flex;
    justify-content: center;
    gap: ${({ theme: { base } }) => base.spacingInContent};
    width: 100%;
    > button {
        width: 100%;
    }
`;

export const Overlay = styled.div`
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 900;
    background-color: rgba(0, 0, 0, 0.5);
`;

export const CartIcon = styled(PhaseSixIcon)`
    color: inherit;
    margin-right: ${(props) => props.theme.base.spacingTiny};
`;
