// LIBRARIES
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";

import * as serviceWorker from "./serviceWorker";

// REDUX
import { store, persistor } from "./store/store";

// Axios
import "./networking/axiosBootstrap";

// DaysJS
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import duration from "dayjs/plugin/duration";

// i18n
import { tx } from "@transifex/native";

// COMPONENTS
import "./index.css";
import "./assets/proximaNovaFont/proximaNova.css";
import "./assets/phase6-icon-font/phase6icons.css";
import "./assets/phase6-icon-font/p6-icon-font.css";
import Layout from "./layout";
import Welcome from "./components/basic/welcome/Welcome";

// Setup Fonts
import { library } from "@fortawesome/fontawesome-svg-core";

import {
    faChevronUp,
    faChevronDown,
    faChevronLeft,
    faChevronRight,
    faGlobe,
    faPaintRoller,
    faSlidersH,
    faUserFriends,
    faUsers,
    faBars,
    faPenAlt,
    faChalkboardTeacher,
    faFileAlt,
    faChartBar,
    faTrashAlt,
    faTools,
    faAngleDown,
    faAngleLeft,
    faAngleRight,
    faAngleUp,
    faBell,
    faBook,
    faBookOpen,
    faClipboard,
    faCoffee,
    faCog,
    faEdit,
    faEnvelope,
    faHeart,
    faHome,
    faLightbulb,
    faLock,
    faLongArrowAltLeft,
    faLongArrowAltRight,
    faPlus,
    faQuestion,
    faRedoAlt,
    faSearch,
    faShare,
    faShoppingBag,
    faShoppingCart,
    faSignal,
    faSignOutAlt,
    faStar,
    faTimes,
    faUser,
    faEllipsisV,
    faVolumeUp,
    faImage,
    faCircleNotch,
    faCheck,
    faExchangeAlt,
    faPen,
    faArrowDown,
    faArrowUp,
    faArrowsAltV,
    faTrash,
    faThumbsUp,
    faBed,
    faVolumeDown,
    faMicrophone,
    faSchool,
    faCity,
    faGraduationCap,
    faCalendar,
    faShareAlt,
} from "@fortawesome/free-solid-svg-icons";
import {
    faSquare,
    faCheckCircle,
    faCheckSquare,
    faClock,
    faStar as farStar,
    faPlayCircle,
    faKeyboard,
    faComment,
    faStopCircle,
} from "@fortawesome/free-regular-svg-icons";
// add all used FA Icons to library here
library.add(
    faChevronUp,
    faChevronDown,
    faChevronLeft,
    faChevronRight,
    faGlobe,
    faPaintRoller,
    faSlidersH,
    faUserFriends,
    faUsers,
    faBars,
    faPenAlt,
    faChalkboardTeacher,
    faFileAlt,
    faChartBar,
    faTrashAlt,
    faTools,
    faAngleDown,
    faAngleLeft,
    faAngleRight,
    faAngleUp,
    faBell,
    faBook,
    faBookOpen,
    faClipboard,
    faCoffee,
    faCog,
    faEdit,
    faEnvelope,
    faHeart,
    faHome,
    faLightbulb,
    faLock,
    faLongArrowAltLeft,
    faLongArrowAltRight,
    faPlus,
    faQuestion,
    faRedoAlt,
    faSearch,
    faShare,
    faShoppingBag,
    faShoppingCart,
    faSignal,
    faSignOutAlt,
    faStar,
    faTimes,
    faUser,
    faEllipsisV,
    faVolumeUp,
    faImage,
    faCircleNotch,
    faCheck,
    faExchangeAlt,
    faPen,
    faArrowDown,
    faArrowUp,
    faArrowsAltV,
    faTrash,
    faThumbsUp,
    faBed,
    faVolumeDown,
    faMicrophone,
    faSchool,
    faCity,
    faGraduationCap,
    faCalendar,
    faShareAlt
);
library.add(
    faClock,
    farStar,
    faPlayCircle,
    faCheckSquare,
    faSquare,
    faCheckCircle,
    faKeyboard,
    faComment,
    faStopCircle
);
// Setup DaysJs
dayjs.extend(relativeTime);
dayjs.extend(duration);

localStorage.setItem("item", "value");

tx.init({
    // Public project token, defaults to empty string
    token: process.env.REACT_APP_TRANSIFEX_PUBLIC_TOKEN,

    // proxy host
    cdsHost: process.env.REACT_APP_TRANSIFEX_CDS_HOST,
});

ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <PersistGate
                loading={<Welcome />}
                persistor={persistor}
            >
                <Layout />
            </PersistGate>
        </Provider>
    </React.StrictMode>,
    document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
