import styled from "styled-components";

export const Background = styled.div`
    background-image: url(${require("../../../assets/img/p6o_background.jpg")});
    background-size: cover;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const Container = styled.div`
    padding: 30px;
    background: rgba(255, 255, 255, 0.9);
    border-radius: 0.5rem;
    border-spacing: 0;
`;
