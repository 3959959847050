// LIBRARIES
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { useT } from "@transifex/react";

import AgreementModal from "./AgreementModal";
import { getAgreementUrl } from "../../../helpers/Url";
import { actions as responseActions } from "../../../redux/response/responseSlice";
import { actions as userActions } from "../../../redux/user/userSlice";
import { actions as modalActions } from "../../../redux/modal/modalSlice";
import { actions as loginActions, LoginWarnings, selectors as loginSelector } from "../../../redux/login/loginSlice";
import { assignRole, getJossoInfoLoginData } from "../../../networking/user";
import { GlobalModalView } from "../../../helpers/Modal";

const AgreementModalWrapper: React.FC = () => {
    const history = useHistory();
    const location = useLocation();
    const dispatch = useDispatch();
    const t = useT();

    const agreementUrl = getAgreementUrl();
    const credentials = useSelector(loginSelector.credentials);

    const incompleteDataWarning = t("Your login data is incomplete.", { _tags: "response,warning,login" });
    const pleaseLogInAgain = t("Please log in again.", { _tags: "response,warning,login" });
    const acceptFailedWarning = t("We could not save your consent.", { _tags: "response,error,login" });
    const pleaseTryAgain = t("Please try again.", { _tags: "response,error,login" });

    const accept = async () => {
        const assignResponse = await assignRole();
        if (assignResponse.data.httpCode === 200) {
            if (credentials.jossoSessionId && credentials.jossoSessionId !== "") {
                const loginData = await getJossoInfoLoginData(credentials.jossoSessionId);
                dispatch(loginActions.loginWithSSOData({ data: loginData.data, history: history }));
                dispatch(modalActions.setModalView(GlobalModalView.None));
            } else {
                dispatch(
                    responseActions.showResponse({ type: "ERROR", text: [incompleteDataWarning, pleaseLogInAgain] })
                );
                dispatch(modalActions.setModalView(GlobalModalView.None));
            }
            dispatch(loginActions.setCredentials({ username: "", password: "", jossoSessionId: "", remember: false }));
        } else {
            responseActions.showResponse({ type: "WARNING", text: [acceptFailedWarning, pleaseTryAgain] });
        }
    };

    const decline = () => {
        dispatch(userActions.logout());
        dispatch(loginActions.setWarning(LoginWarnings.Agreement));
        dispatch(modalActions.setModalView(GlobalModalView.None));
        if (location.pathname.indexOf("login/sso") > -1) {
            // user came from redirect and isn't on the login page now
            history.push("/login");
        }
    };

    return (
        <AgreementModal
            url={agreementUrl}
            accept={accept}
            decline={decline}
        />
    );
};

export default AgreementModalWrapper;
