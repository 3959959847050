// REACT
import React, { FunctionComponent, memo } from "react";

// COMPONENTS
import PhaseSixIcon from "../../phaseSixIcon/PhaseSixIcon";
import { LanguageSelect, Props as LangComponentProps } from "../LanguageSelect";

// STYLED COMPONENTS
import { Wrapper, CharsContainer, Char, FooterContainer, CaseToggle, CloseIcon } from "./styles";

type Props = {
    chars: string[];
    isUppercase: boolean;
    onClose?: () => void;
    onCaseToggle: () => void;
    onCharSelect: (selectedChar: string) => void;
} & LangComponentProps;

const SpecialCharMenu: FunctionComponent<Props> = (props) => {
    const { chars, lang, langList, isUppercase, onLangSelect, onClose, onCaseToggle, onCharSelect } = props;

    return (
        <Wrapper>
            <CharsContainer>
                {chars.map((char: string, i: number) => (
                    <Char
                        key={char + i}
                        onClick={() => onCharSelect(char)}
                    >
                        {char}
                    </Char>
                ))}
            </CharsContainer>
            <FooterContainer>
                <CaseToggle
                    onClick={() => onCaseToggle()}
                    active={isUppercase}
                >
                    <PhaseSixIcon name="uppercase" />
                </CaseToggle>
                {!!langList && (
                    <LanguageSelect
                        lang={lang}
                        langList={langList}
                        onLangSelect={onLangSelect}
                    />
                )}
                <CloseIcon onClick={onClose}>
                    <PhaseSixIcon name="close" />
                </CloseIcon>
            </FooterContainer>
        </Wrapper>
    );
};

export default memo(SpecialCharMenu);
