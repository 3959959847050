import { useContext } from "react";
import styled, { ThemeContext } from "styled-components";
import { getScoreColor } from "../../../../helpers/MatchGame";
import PhaseSixIcon from "../../../basic/phaseSixIcon/PhaseSixIcon";

export const HoverButton = styled.button<{ inactive?: boolean; position?: string }>`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100px;
    height: 100px;
    padding: 0;
    margin: 1px;
    border: 0;
    cursor: pointer;
    background-color: ${({ theme: { colors }, inactive = false }) =>
        inactive ? colors.backgroundMain : colors.primary};
    font-size: ${({ theme: { base } }) => base.size};
    color: ${({ theme: { colors }, inactive = false }) => (inactive ? colors.text : "#fff")};
    line-height: 1.5;

    border-top-left-radius: ${({ position }) => (position === "left" ? "25px" : "")};
    border-bottom-left-radius: ${({ position }) => (position === "left" ? "25px" : "")};
    border-top-right-radius: ${({ position }) => (position === "right" ? "25px" : "")};
    border-bottom-right-radius: ${({ position }) => (position === "right" ? "25px" : "")};

    &:hover {
        opacity: 0.9;
    }

    > * {
        font-size: 3em;
        margin-bottom: 5px;
    }
`;

export const HoverBlock = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    background-color: ${({ theme: { colors } }) => colors.backgroundMain + "50"};
    opacity: 0;
    transition: opacity ${({ theme: { base } }) => base.transition}s;
`;

export const ItemBox = styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
    box-sizing: border-box;
    min-height: 100%;
    min-width: 320px;
    padding: ${({ theme: { base } }) => base.spacingSmall};
    background-color: ${({ theme: { colors } }) => colors.backgroundContrast};
    border-radius: ${({ theme: { base } }) => base.borderRadius};
    color: ${({ theme: { colors } }) => colors.text};
    font-size: ${({ theme: { base } }) => base.bigSize};
    line-height: 1;
    overflow: hidden;

    &:hover > ${HoverBlock} {
        opacity: 1;
    }
`;

export const ItemImage = styled.img`
    width: 120px;
    height: 170px;
    margin-right: ${({ theme: { base } }) => base.spacingSmall};
    border-radius: ${({ theme: { base } }) => base.borderRadius};
`;

export const ItemInfo = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;

export const ItemName = styled.h3`
    margin-top: 0;
    margin-bottom: ${({ theme: { base } }) => base.spacingSmall};
    font-size: ${({ theme: { base } }) => base.bigSize};
    color: ${({ theme: { colors } }) => colors.text};
    font-weight: normal;
`;

export const ItemScoreBlock = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: ${({ theme: { base } }) => base.spacingSmall};
`;

export const ItemScoreName = styled.p<{ score: number }>`
    margin: 3px 0 0;
    font-weight: bold;
    font-size: ${({ theme: { base } }) => base.size};
    color: ${({ score }) => getScoreColor(score, useContext(ThemeContext))};
`;

export const ScoreTime = styled.div`
    display: flex;
    align-items: center;
    font-size: 28px;
    color: ${({ theme: { colors } }) => colors.text};
    > i {
        font-size: 0.8em;
    }
`;

export const BestScoreWrapper = styled.div<{ inModal?: boolean }>`
    display: inline-block;
    position: relative;
    line-height: 1;
    > *:first-child {
        position: absolute;
        left: ${(props) => (props.inModal ? "60%" : "35%")};
        bottom: ${(props) => (props.inModal ? "40%" : "50%")};
    }
`;

export const CustomColorPhaseSixIcon = styled(PhaseSixIcon)<{ color: string }>`
    color: ${(props) => props.color};
`;

export const StyledPhaseSixIcon = styled(PhaseSixIcon)`
    color: inherit;
`;
