import styled from "styled-components";

export const Background = styled.div`
    height: auto;
    border: none;
    background-color: ${(props) => props.color || props.theme.buttons.primary.backgroundColor};
    padding: 7px 10px;
    border-radius: 5px;
`;

export const DateText = styled.span`
    color: ${(props) => props.theme.buttons.primary.textColor};
    font-size: ${(props) => props.theme.base.bigSize};
    font-family: ${(props) => props.theme.base.fontFamily};
    font-weight: 500;
    text-align: center;
`;
