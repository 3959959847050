import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const Img = styled.img`
    max-width: 100%;
    max-height: 100%;
`;

export const Video = styled.video`
    max-width: 100%;
    max-height: 100%;
`;

export const Audio = styled.audio`
    max-width: 100%;
    max-height: 100%;
`;
