// REACT
import React from "react";

// STYLED COMPONENTS
import { Flag } from "./styles";

const PlusFlag: React.FC = () => {
    const t_premiumName = "PLUS"; // TODO check if translation can be used here: t("Premium", {_tags: "premiumInfo"});
    return <Flag>{t_premiumName}</Flag>;
};

export default PlusFlag;
