// REACT
import React, { FunctionComponent, memo, useRef, useEffect, PropsWithChildren } from "react";

// LIBRARIES
import DOMPurify from "dompurify";

// TYPES
import { Item, AnimationType } from "p6m-learning";

// STYLED COMPONENTS
import { CardsAnimationWrapper, AnimatedContent } from "./styles";

type Props = {
    index: number;
    type?: Item["type"];
    time?: number;
};
const AnimatedComponent: FunctionComponent<PropsWithChildren<Props>> = (props) => {
    const { time = 500, index, type, children } = props;

    const element = useRef<any>();
    const prevContent = useRef<string>();
    const prevIndex = useRef<number>();

    useEffect(() => {
        prevContent.current = element.current?.innerHTML;
    }, [children]);

    useEffect(() => {
        prevIndex.current = index;
    }, [index]);

    const animate = prevIndex.current !== index;

    const animation = getAnimation({
        index,
        prevIndex: prevIndex.current,
        type,
    });

    return (
        <CardsAnimationWrapper
            key={index + "1"}
            $time={time}
            $animation={animation}
        >
            <AnimatedContent ref={element}>{children}</AnimatedContent>
            {animate && prevContent?.current && (
                <AnimatedContent
                    key={index + "2"}
                    dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(prevContent?.current, {
                            USE_PROFILES: {
                                html: true,
                                svg: true,
                            },
                            FORBID_ATTR: ["autofocus", "tabindex"],
                            ALLOW_DATA_ATTR: false,
                        }),
                    }}
                />
            )}
        </CardsAnimationWrapper>
    );
};

export default memo(AnimatedComponent);

function getAnimation(props: { index: number; prevIndex?: number; type?: Item["type"] }) {
    const { index, prevIndex, type } = props;
    const direction: "next" | "prev" | undefined = (() => {
        if (prevIndex === undefined) return undefined;
        if (index > prevIndex) return "next";
        if (index < prevIndex) return "prev";
        return undefined;
    })();

    if (!direction) return undefined;
    const relation: Record<typeof direction, [AnimationType, AnimationType]> = {
        next: ["slideIn", "slideOut"],
        prev: ["slideOutBack", "slideInBack"],
    };
    let result = relation[direction];
    const typeRelations: Partial<Record<Item["type"], Partial<Record<AnimationType, AnimationType>>>> = {
        drag: {
            slideOut: "fadeOut",
        },
    };
    if (!!type) {
        const animationsByType = typeRelations[type];
        return result.map((animation) => animationsByType?.[animation] || animation) as [AnimationType, AnimationType];
    }
    return result;
}
