// REACT
import React, { FunctionComponent, useCallback, useState } from "react";

// REDUX
import { useSelector, useDispatch } from "react-redux";
import { selectors, actions } from "../../../../redux/learning/learningSlice";

// COMPONENTS
import Component from "./Modals";

// HOOKS
import { useFirstPracticeResult } from "../../../../hooks/useFirstPracticeResult";

export const Modals: FunctionComponent = () => {
    const modals = useSelector(selectors.modals);

    // "cache" this value since it updates before the done modal renders
    const [hasFirstPractice] = useState(useFirstPracticeResult());

    const dispatch = useDispatch();

    const afterClose = useCallback(() => {
        dispatch(actions.removeModal(modals[0]));
    }, [dispatch, modals]);

    return (
        <Component
            modal={modals[0]}
            afterClose={afterClose}
            hasFirstPractice={hasFirstPractice}
        />
    );
};
