import styled from "styled-components";
import { Row } from "../../basic/grid";
import TextInput from "../../basic/textInput/TextInput";

export const Title = styled.h2`
    margin: 0;
    font-size: 24px;
    color: ${({ theme: { colors } }) => colors.textOnBackground};
    font-weight: normal;
`;

export const FullHeightRow = styled(Row)`
    flex-grow: 1;
    flex-shrink: 1;
    overflow-y: auto;
`;

export const Search = styled(TextInput)`
    width: 100%;
    margin: 0 !important;
    height: 50px;
    border-radius: 25px;
    background: ${(props) => props.theme.colors.backgroundContrast};
    color: ${(props) => props.theme.colors.textLight};
    transition: all 0.15s ease-in-out 0s;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    box-shadow: inset 0 2px 8px ${(props) => props.theme.colors.shadow};
    padding: 8px 16px;
`;
