import styled from "styled-components";
import { Col, Row } from "../../../components/basic/grid";

export const Wrapper = styled.div`
    padding: ${({ theme: { base } }) => base.spacingSmall};
    background-color: ${({ theme: { colors } }) => colors.backgroundContrast};
    border-radius: ${({ theme: { base } }) => base.borderRadius};
    font-size: ${({ theme: { base } }) => base.cardPracticeTextSize};
    color: ${({ theme: { colors } }) => colors.text};
`;

export const Title = styled.div`
    text-align: center;
    font-size: ${({ theme: { base } }) => base.cardPracticeTextSize};
    padding-left: ${({ theme: { base } }) => base.spacing};
    color: ${({ theme: { colors } }) => colors.text};
`;

export const TitleWrapper = styled(Col)`
    padding: ${({ theme: { base } }) => base.spacing};
`;

export const Item = styled(Col)`
    text-align: center;
    padding: 0 ${({ theme: { base } }) => base.spacingSmall};
    &:last-child {
        color: ${({ theme: { colors } }) => colors.primary};
        padding-right: ${({ theme: { base } }) => base.spacing};
    }
    > div {
        font-size: ${({ theme: { base } }) => base.size};
    }
`;

export const ContentWrapper = styled(Col)`
    padding: ${({ theme: { base } }) => base.spacing} 0;
`;

export const ContentRow = styled(Row)`
    margin: 0 !important;
`;
