// REACT
import React, { FunctionComponent, useState, useCallback } from "react";

// LIBRARIES
import { T } from "@transifex/react";

// COMPONENTS
import Component from "./LanguageSelect";

// STYLED COMPONENTS
import { PopupContainer } from "../../popup/styles";
import { MenuWrapper, MenuTitle, MenuItem, StyledPhaseSixIcon } from "./styles";

export type Props = {
    lang: string;
    langList: { [key: string]: string };
    onLangSelect: (selectedLang: string) => void;
};

export const LanguageSelect: FunctionComponent<Props> = (props) => {
    const { lang, langList, onLangSelect } = props;
    const [isOpen, setOpen] = useState<boolean>(false);

    const onPopupToggle = useCallback((isOpen: boolean) => {
        setOpen(isOpen);
    }, []);

    const menu = useCallback(
        ({ closePopup }: any) => {
            return (
                <>
                    <PopupContainer>
                        <MenuTitle>
                            <T _str="Select language:" />
                        </MenuTitle>
                    </PopupContainer>

                    <MenuWrapper>
                        {Object.entries(langList).map(([key, value]) => {
                            const isSelected: boolean = value === lang;
                            return (
                                <MenuItem
                                    key={key}
                                    selected={isSelected}
                                    onClick={() => {
                                        onLangSelect(key);
                                        closePopup();
                                    }}
                                >
                                    {isSelected && <StyledPhaseSixIcon name="exercise-ok" />}&nbsp;
                                    <T _str={value} />
                                </MenuItem>
                            );
                        })}
                    </MenuWrapper>
                </>
            );
        },
        [langList, onLangSelect, lang]
    );

    return (
        <Component
            isOpen={isOpen}
            menu={menu}
            selectedLang={lang}
            onPopupToggle={onPopupToggle}
        />
    );
};
