import styled from "styled-components";
import { ReactComponent as Phase6LogoWhite } from "../../../assets/img/p6_logo_white.svg";
import { ReactComponent as Phase6Logo } from "../../../assets/img/p6_logo.svg";

export const StyledPhase6LogoWhite = styled(Phase6LogoWhite)`
    width: 170px;
    margin: 10px 0 0;
    display: inline-block;
    height: 50px;
    background-size: 100%;
`;
export const StyledPhase6Logo = styled(Phase6Logo)`
    width: 170px;
    margin: 10px 0 0;
    display: inline-block;
    height: 50px;
    background-size: 100%;
`;

export const Item = styled.div`
    display: flex;
    width: 100%;
`;

export const ItemJustifyEnd = styled(Item)`
    justify-content: flex-end;
    align-items: center;

    > span > div {
        width: unset;
    }
`;

export const SpacingRow = styled(Item)`
    min-height: 28px;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
`;
export const SmallDateOnBackground = styled.span`
    text-align: right;
    padding-bottom: ${(props) => props.theme.base.spacingSmall};
    padding-right: ${(props) => props.theme.base.spacing};
    color: ${(props) => props.theme.colors.textOnBackground};
`;

export const TopBarIconsContainer = styled.div`
    width: 20%;
    display: flex;

    svg {
        flex: 1;
        height: 30px;
        width: unset;
    }

    & > a {
        flex: 1;
        height: 30px;
        width: unset;
    }

    & > i {
        flex: 1;
        width: unset;
        height: 30px;
    }
`;

export const StyledContainer = styled.div`
    height: 100px;
    padding: 0 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
`;

export const PartnerLogo = styled.img`
    max-height: 60px;
    max-width: 220px;
`;
