import styled from "styled-components";

export const WordDetailsContainer = styled.div``;

export const SortedClassContainer = styled.div`
    padding-bottom: ${(props) => props.theme.base.spacingInContent};
    display: flex;
    outline: inherit;
    border: none;
    flex-direction: column;
    cursor: pointer;
    border-radius: ${(props) => props.theme.base.borderRadiusSmall};
    margin-bottom: 5px;
    justify-content: flex-start;
`;

export const TitleContainer = styled.div`
    display: flex;
    background-color: ${(props) => props.theme.colors.backgroundContrast};
    padding: 10px 0 10px 20px;
    border-top-right-radius: ${(props) => props.theme.base.borderRadius};
    border-top-left-radius: ${(props) => props.theme.base.borderRadius};
    align-items: baseline;
`;

export const HeadWord = styled.span`
    color: ${(props) => props.theme.colors.primary};
    font-size: ${(props) => props.theme.base.titleBigSize};
    font-weight: ${(props) => props.theme.base.bigWeight};
`;

export const SortedClass = styled.span`
    font-size: ${(props) => props.theme.base.titleSize};
    font-family: ${(props) => props.theme.base.fontFamily};
    color: ${(props) => props.theme.colors.text};
    margin-left: ${(props) => props.theme.base.spacing};
`;

export const WordsContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

export const CollapsableWordDetailsContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 2px;
    border-radius: ${(props) => props.theme.base.borderRadius};
`;

export const Credits = styled.p`
    display: flex;
    flex: 1;
    text-align: center;
    justify-content: center;
    padding: 10px 0px 10px 0px;
    padding-top: ${(props) => props.theme.base.spacingSmall};
    padding-bottom: ${(props) => props.theme.base.spacingSmall};
    background: ${(props) => props.theme.colors.backgroundContrast};
    color: ${(props) => props.theme.colors.text};
`;
