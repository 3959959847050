// LIBRARIES
import React, { useCallback, useEffect, useState, useMemo } from "react";
import styled from "styled-components";
import { useT } from "@transifex/react";
import { actions as userActions, selectors as userSelectors } from "../../redux/user/userSlice";
import { useDispatch, useSelector } from "react-redux";
import { actions as avatarsActions, selectors as avatarsSelectors } from "../../redux/avatars/avatarsSlice";

// COMPONENTS
import SettingsMainBody from "../../components/connected/settingsMainBody";
import SettingsHeader from "../../components/complex/setttingsHeader/SettingsHeader";
import SettingsFamilyView from "../../components/complex/settingsFamilyView/SettingsFamilyView";
import SettingsChildren from "../../components/complex/settingsChildren";
import SettingsPractice from "../../components/complex/settingsPractice";
import SettingsGeneral from "../../components/complex/settingsGeneral";
import SettingsAppearance from "../../components/complex/settingsAppearance";
import SettingsLanguage from "../../components/complex/settingsLanguage";
import SettingsPhases from "../../components/complex/settingsPhases";
import SettingsAvatars from "../../components/complex/settingsAvatars";
import { GlobalModalView, SettingsView } from "../../helpers/Modal";
import { actions as settingsActions, selectors as settingsSelectors } from "../../redux/settings/settingsSlice";
import { actions as modalActions } from "../../redux/modal/modalSlice";
import Loading from "../../components/connected/loading/Loading";
import { FamilyMember } from "p6m-user";

const Background = styled.div`
    width: 100%;
    padding: 0;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    background: ${(props) => props.theme.colors.backgroundMain};
`;

const MainBodyContainer = styled.div`
    padding: 0 ${(props) => props.theme.base.spacing} ${(props) => props.theme.base.spacing};
    overflow: auto;
`;

export interface SettingsWrapperProps {
    modalActions?: {
        hide?: () => any;
    };
}

const SettingsWrapper: React.FC<SettingsWrapperProps> = () => {
    const t = useT();

    const settingsView: SettingsView = useSelector(settingsSelectors.settingsView);

    const user = useSelector(userSelectors.user);
    const userPreferences = useSelector(userSelectors.userPreferences);
    const childrenList = useSelector(userSelectors.userFamilyMembers);

    const [selectedChildrenIndex, setSelectedChildrenIndex] = useState<number>(-1);

    const dispatch = useDispatch();

    const t_views = {
        [SettingsView.Main]: t("Settings", { _tags: "settings" }),
        [SettingsView.Family]: t("Family", { _tags: "settings" }),
        [SettingsView.Learning]: t("Learn settings", { _tags: "settings" }),
        [SettingsView.Phases]: t("Phases", { _tags: "settings" }),
        [SettingsView.General]: t("General", { _tags: "settings" }),
        [SettingsView.Visual]: t("Appearance", { _tags: "settings" }),
        [SettingsView.Language]: t("Language", { _tags: "settings" }),
        [SettingsView.ChildrenSettings]: childrenList[selectedChildrenIndex]?.name || "",
        [SettingsView.Avatars]: useGetAvatarsTitle(),
    };
    const childDataNotFound_t = t("Child data not found", { _tags: "settings, message" });

    const refreshData = useCallback(
        (userId: string | undefined) => {
            if (userId) {
                dispatch(userActions.refreshPreferencesData(userId));
            }
        },
        [dispatch]
    );

    useEffect(() => {
        refreshData(user.userDnsId);
    }, [user.userDnsId, refreshData]);

    const logoutAction = () => {
        close();
        dispatch(userActions.logout());
    };

    const goToView = (view: SettingsView) => {
        dispatch(settingsActions.setSettingsView(view));
    };

    const openChildrenSettings = (childIndex: number) => {
        setSelectedChildrenIndex(childIndex);
        goToView(SettingsView.ChildrenSettings);
    };

    function renderSwitch() {
        if (!userPreferences) return <Loading />;
        switch (settingsView) {
            case SettingsView.Main:
                return (
                    <SettingsMainBody
                        changeViewCallback={goToView}
                        contentTitles={t_views}
                        logoutAction={logoutAction}
                    />
                );
            case SettingsView.Family:
                return (
                    <SettingsFamilyView
                        openChildrenSettings={openChildrenSettings}
                        childrenList={childrenList}
                    />
                );
            case SettingsView.Learning:
                return <SettingsPractice />;
            case SettingsView.Phases:
                return <SettingsPhases />;
            case SettingsView.General:
                return <SettingsGeneral />;
            case SettingsView.Visual:
                return <SettingsAppearance />;
            case SettingsView.Language:
                return <SettingsLanguage />;
            case SettingsView.ChildrenSettings:
                const childMemberData: FamilyMember = childrenList[selectedChildrenIndex];
                if (childMemberData.userId && childMemberData.preferences) {
                    return (
                        <SettingsChildren
                            userId={childMemberData.userId}
                            preferences={childMemberData.preferences}
                        />
                    );
                } else {
                    return <p>{childDataNotFound_t}</p>;
                }
            case SettingsView.Avatars:
                return <SettingsAvatars />;
            default:
                return null;
        }
    }

    const goBackAction = () => {
        const view = settingsView === SettingsView.ChildrenSettings ? SettingsView.Family : SettingsView.Main;
        goToView(view);
    };

    const close = () => {
        dispatch(modalActions.setModalView(GlobalModalView.None));
        dispatch(settingsActions.setSettingsView(SettingsView.Main));
    };

    return (
        <Background>
            <SettingsHeader
                showBackButton={settingsView !== SettingsView.Main}
                currentSettingsName={t_views[settingsView]}
                backAction={() => goBackAction()}
                closeAction={close}
            />
            <MainBodyContainer data-qa="user-settings-modal_content">{renderSwitch()}</MainBodyContainer>
        </Background>
    );
};

export default SettingsWrapper;

function useGetAvatarsTitle() {
    const t = useT();
    const avatars = useSelector(avatarsSelectors.getFilteredAvatars);
    const dispatch = useDispatch();
    const defaultTitle = t("Avatars", { _tags: "settings" });

    useEffect(() => {
        if (avatars !== false) return;
        dispatch(avatarsActions.fetchAvatars());
    }, [avatars, dispatch]);

    return useMemo(() => {
        if (avatars === false) return defaultTitle;
        const avatarsArray = Object.values(avatars);
        const resolvedAvatars = avatarsArray.filter(({ isAvailable }) => isAvailable);

        return `${defaultTitle} (${resolvedAvatars.length} / ${avatarsArray.length})`;
    }, [avatars, defaultTitle]);
}
