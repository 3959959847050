//LIBRARIES
import React from "react";
import { T } from "@transifex/react";

//COMPONENTS
import Modal from "../../basic/modal/Modal";
import Heading from "./subComponents/Heading";
import PhaseSixIcon from "../../basic/phaseSixIcon/PhaseSixIcon";
import BulletPoints from "./subComponents/BulletPoints";

// STYLES
import {
    ContentWrapper,
    ConsumeButton,
    DontShowAgainButton,
    ActionWrapper,
    InfoWrapper,
    Sidney,
    SubHeader,
    CloseBtn,
} from "./styles";

interface GrammarTutorPromotionModalProps {
    onClose: () => void;
    onDisable: () => void;
    onGoToGrammarTutor: () => void;
}

const GrammarTutorPromotionModal = ({ onClose, onDisable, onGoToGrammarTutor }: GrammarTutorPromotionModalProps) => {
    return (
        <Modal
            active
            headerComponent={false}
            //only needed if modal closes automatically after user clicks outside of modal
            afterClose={onClose}
        >
            <ContentWrapper>
                <CloseBtn onClick={onClose}>
                    <PhaseSixIcon name="close" />
                </CloseBtn>
                <InfoWrapper>
                    <Heading />
                    <Sidney />
                    <SubHeader>
                        <T
                            _str="Repeat and practice English grammar:"
                            _tags="modal, header, grammarTutor"
                        />
                    </SubHeader>
                    <BulletPoints />
                </InfoWrapper>
                <ActionWrapper>
                    <ConsumeButton
                        tabIndex={1}
                        onClick={onGoToGrammarTutor}
                        borderRadius="big"
                    >
                        <T
                            _str="Learn now"
                            _tags="Learn"
                        />
                    </ConsumeButton>
                    <DontShowAgainButton
                        tabIndex={1}
                        onClick={onDisable}
                        borderRadius="big"
                    >
                        <T
                            _str="Don't show this again"
                            _tags="Learn"
                        />
                    </DontShowAgainButton>
                </ActionWrapper>
            </ContentWrapper>
        </Modal>
    );
};

export default GrammarTutorPromotionModal;
