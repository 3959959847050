const isTabActive = (function () {
    let result: boolean = document.visibilityState === "visible";
    let eventsCreated: boolean = false;
    let createEvents = function () {
        document.addEventListener("visibilitychange", function () {
            result = document.visibilityState === "visible";
        }); // check tab visibility
        window.addEventListener("focus", function () {
            result = document.visibilityState === "visible";
        }); // check tab visibility on window focus
        window.addEventListener("blur", function () {
            result = false;
        }); // set false if window isn't in focus
        eventsCreated = true; // set all callbacks created variable to true
    };

    return function () {
        if (!eventsCreated) createEvents();
        return result;
    };
})();

export default isTabActive;
