import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { AxiosPromise } from "axios";
import { IResponse } from "p6m-response";
import { actions as appStatusActions } from "../redux/appStatus/appStatusSlice";

type ProvidedFunction = (...props: any) => AxiosPromise<IResponse<any>>;

export function useRequest<T extends ProvidedFunction>(action: T, disableLoading: boolean = false) {
    const dispatch = useDispatch();

    return useCallback(
        (...props: Parameters<typeof action>) => {
            if (!disableLoading) dispatch(appStatusActions.setLoading(true));

            return action(...props).finally(() => {
                if (!disableLoading) dispatch(appStatusActions.setLoading(false));
            });
        },
        [dispatch, action, disableLoading]
    );
}

export default useRequest;
