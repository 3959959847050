import dayjs from "dayjs";

export default function timeFormat(time: number): string {
    const duration = dayjs.duration(time, "s");
    return [duration.minutes(), duration.seconds()]
        .map((time: number) => time.toString())
        .map((time: string) => (time.length <= 1 ? "0" + time : time))
        .join(":");
}

/**
 * outputs ISO time string as a string in the format of "dd.mm.yy"
 *
 * @param dateString - ISO time string
 * @returns date in the "dd.mm.yy" format
 */
export function toDDMMYY(dateString: string, options?: { isFullYear?: boolean }): string {
    const date = new Date(dateString);
    const day = ("00" + date.getDate()).substr(-2);
    const month = ("00" + (date.getMonth() + 1)).substr(-2);
    const year = date.getFullYear().toString();
    const shortenedYear = date.getFullYear().toString().substr(-2);
    return `${day}.${month}.${options?.isFullYear ? year : shortenedYear}`;
}
