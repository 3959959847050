import { InternalAxiosRequestConfig } from "axios";
import { store, RootState } from "../../store/store";
import { actions } from "../../redux/user/userSlice";
import { generateUuid } from "../../helpers/Id";

const manualHeadersRequests = ["jossoInfo"];

export default function authInterceptor(request: InternalAxiosRequestConfig): InternalAxiosRequestConfig {
    const {
        user: {
            user: { email, p6pSessionToken },
            clientId,
        },
    }: RootState = store.getState();

    if (!request.url || manualHeadersRequests.indexOf(request.url) === -1) {
        request.headers["X-JAUTHTOKEN"] = p6pSessionToken;
        request.headers["X-CLIENTID"] = clientId || generateClientId();
    }

    if (email) request.headers["X-LBTOKEN"] = email.toLowerCase();

    return request;
}

// generateClientId. Got this code from old site version.
function generateClientId() {
    const uuid = generateUuid();
    return saveClientId(uuid), uuid;
}

function saveClientId(clientId: string): void {
    const { setClientId } = actions;
    store.dispatch(setClientId(clientId));
}
