// REACT
import React, { FunctionComponent, useState } from "react";

// UTILS
import { getCardData } from "../../../../helpers/Cards";

// STYLES COMPONENTS
import { ImagesWrapper, Image, ImageSelector } from "./styles";
import FilePreview from "../../../basic/filePreview/FilePreview";

type Props = {
    images: ReturnType<typeof getCardData>["images"];
};

export const CardImages: FunctionComponent<Props> = (props) => {
    const { images } = props;
    const [current, setCurrent] = useState(0);
    const [modal, setModal] = useState<boolean>(false);

    const onClickImage = () => {
        setModal(true);
    };

    return (
        <ImagesWrapper>
            <Image
                src={images[current]}
                onClick={onClickImage}
                alt="practice"
            />
            <ImageSelector>
                {images.map((image, index) => (
                    <div
                        key={index}
                        onClick={() => setCurrent(index)}
                        className={index === current ? "active" : undefined}
                    />
                ))}

                {!!modal && (
                    <FilePreview
                        url={images[current]}
                        type="image"
                        onClose={() => setModal(false)}
                    />
                )}
            </ImageSelector>
        </ImagesWrapper>
    );
};
