import styled from "styled-components";

export const IconWrapper = styled.div<{ disabled: boolean }>`
    position: relative;
    margin-top: 36px;
    cursor: pointer;
    opacity: ${({ disabled = false }) => (disabled ? 0.5 : 1)};

    img {
        width: 36px;
    }
`;

export const LanguageWrapper = styled.div`
    position: absolute;
    right: 4px;
    top: 4px;
    z-index: 1;
    background-color: ${({ theme: { colors } }) => colors.backgroundContrast};
    color: ${({ theme: { colors } }) => colors.primary};
    font-size: ${({ theme: { base } }) => base.mediumSize};
    text-transform: uppercase;
    line-height: 1;
`;
