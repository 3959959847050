import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../store/store";
import mainTheme from "../../themes/main.json";
import darkTheme from "../../themes/dark.json";
import orangeTheme from "../../themes/orange.json";
import pinkTheme from "../../themes/pink.json";
import blueTheme from "../../themes/blue.json";
import { ReactComponent as mainDefaultIcon } from "../../assets/grayTheme/ach_default.svg";
import { ReactComponent as darkDefaultIcon } from "../../assets/darkTheme/ach_default.svg";
import { Theme } from "../../themes/constants";
import MainAssets from "../../themes/assetMaps/mainAssets";
import DarkAssets from "../../themes/assetMaps/darkAssets";
import { StyleTheme } from "p6m-themes";
import { FunctionComponent } from "react";

export interface ThemeState {
    theme: StyleTheme;
    themeName: Theme;
    userThemes: { [userId: string]: Theme };
}

const initialState: ThemeState = {
    theme: mainTheme,
    themeName: Theme.MAIN,
    userThemes: {},
};

export const themeSlice = createSlice({
    name: "theme",
    initialState,
    reducers: {
        saveUserTheme: (state: ThemeState, action: PayloadAction<string>) => {
            state.userThemes[action.payload] = state.themeName;
        },
        setThemeName: (state: ThemeState, action: PayloadAction<Theme>) => {
            state.themeName = action.payload;
        },
        setTheme: (state: ThemeState, action: PayloadAction<Theme>) => {
            switch (action.payload) {
                case Theme.DARK:
                    state.theme = darkTheme;
                    break;
                case Theme.ORANGE:
                    state.theme = orangeTheme;
                    break;
                case Theme.PINK:
                    state.theme = pinkTheme;
                    break;
                case Theme.BLUE:
                    state.theme = blueTheme;
                    break;
                case Theme.MAIN:
                default:
                    state.theme = mainTheme;
                    break;
            }
        },
    },
});

/* EXPORTS */
export const { actions, reducer } = themeSlice;

export const selectors = {
    theme: (state: RootState) => {
        let theme = Object.assign({}, state.theme.theme);
        let themeName = state.theme.themeName;
        let iconReferences: { [key: string]: FunctionComponent } = {};
        for (let iconKey in theme.icons) {
            // map up to date icon references from map
            switch (themeName) {
                case Theme.DARK:
                    if (DarkAssets.icons[iconKey]) iconReferences[iconKey] = DarkAssets.icons[iconKey];
                    else iconReferences[iconKey] = darkDefaultIcon;
                    break;
                case Theme.MAIN:
                case Theme.ORANGE:
                case Theme.PINK:
                case Theme.BLUE:
                default:
                    if (MainAssets.icons[iconKey]) iconReferences[iconKey] = MainAssets.icons[iconKey];
                    else iconReferences[iconKey] = mainDefaultIcon;
                    break;
            }
        }
        // set refs in theme
        theme.icons = iconReferences;
        return theme;
    },
    themeName: (state: RootState) => state.theme.themeName,
    userThemes: (state: RootState) => state.theme.userThemes,
};
