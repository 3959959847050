// LIBRARIES
import React, { useContext, useEffect, useState, useCallback, useRef } from "react";
import { ThemeContext } from "styled-components";
import { useSelector } from "react-redux";
import { ampli } from "../../ampli";

// REDUX
import { selectors as userSelectors } from "../../redux/user/userSlice";

// COMPONENTS
import LanguageCombo from "../../components/complex/languageCombo";
import WordDetails from "../../components/complex/wordDetails/WordDetails";
import {
    DictionaryPageContainer,
    DictionaryHeaderContainer,
    DictionaryContentContainer,
    SearchContainer,
    InputContainer,
    StyledInput,
    LanguageFilter,
    FlagCombinationContainer,
    FlagImage,
    SoundContainer,
    SoundIcon,
    PonsImageContainer,
    PonsImage,
    SelectYourLanguageTextBox,
    CloseIconContainer,
    WordDetailsContainer,
    AddToCardsButtonContainer,
    AddToCardsButton,
    SearchResultContainer,
    SearchResultImage,
    SearchResultText,
    NoResultsContainer,
    NoResultsText,
    LoadingContainer,
    LoadingText,
} from "./styles";

// MODELS
import { User } from "p6m-user";
import {
    AvailableLanguageCodes,
    DictionarySearchResult,
    DictionaryWordResults,
    LanguageFilterTypes,
} from "p6m-dictionary";
import { useT } from "@transifex/react";
import DictionaryConstants from "../../constants/DictionaryConstants";
import Loading from "../../components/connected/loading/Loading";
import GoToShopPage from "../../components/basic/goToShopPage/goToShopPage";
import PhaseSixIcon from "../../components/basic/phaseSixIcon/PhaseSixIcon";
import { LanguagesContext } from "../../context/languages";

export interface DictionaryProps {
    user: User;
    hasPremium: boolean;
    onGoToShop: () => void;
    dictionarySearchLanguage: AvailableLanguageCodes;
    searchValue: string;
    handleChange: (changeEvent: React.ChangeEvent<HTMLInputElement>) => any;
    handleKeyPressed: (keyPressedEvent: React.KeyboardEvent<HTMLInputElement>) => any;
    onResultClicked: (word: string) => any;
    clearSearch: () => any;
    searchResults: Array<DictionarySearchResult>;
    loading: boolean;
    activeIndex: number;
    activeWordClassIndex: number;
    activeWordIndex: number;
    activeExampleIndex: number;
    wordDetails?: DictionaryWordResults;
    exampleClicked: (wordClassIndex: number, wordIndex: number, exampleIndex: number) => void;
    setActiveIndexLanguageFilter: (languageFilterType: LanguageFilterTypes) => void;
    activeIndexLanguageFilter: LanguageFilterTypes;
    addWordToCards: () => void;
}

const Dictionary: React.FC<DictionaryProps> = (props) => {
    const theme = useContext(ThemeContext);
    const { getFlagSource } = useContext(LanguagesContext);
    const t = useT();

    const [audio, setAudio] = useState<HTMLAudioElement | null>(null);
    const [isAudioPlaying, setIsAudioPlaying] = useState(false);

    let reference: any = null;
    let scrollRef = useRef<HTMLButtonElement | null>(null);

    const searchPlaceholderTranslation = t("Search Dictionary", { _tags: "dictionary,search" });

    const t_selectYourLanguageText = t("Select the language combination you want to search for", {
        _tags: "selectYourLanguageText",
    });
    const t_addToCardsButtonText = t("SAVE TO CONTENT", { _tags: "addToCardsButtonText" });
    const t_noResultsText = t("No results were found.", { _tags: "noResultsText" });
    const t_loadingText = t("Loading...", { _tags: "loadingText" });

    let languageFilterButtonText = "DE";

    const autoplayAudio = useSelector(userSelectors.userPreferences).audioPlaybackSetting;

    const createAudio = (audioId: string): HTMLAudioElement | null => {
        const baseURL = process.env.REACT_APP_BACKEND_API_URL;
        const url = `${baseURL}media/${audioId}`;
        if (audioId === "") {
            return null;
        }
        return new Audio(url);
    };

    const searchResultClicked = (searchResult: DictionarySearchResult) => {
        props.onResultClicked(searchResult.word);
        if (props.hasPremium) {
            const ampliProps = {
                app_screen: "dictionary" as "dictionary",
                dictionary_language: ("de-" + props.dictionarySearchLanguage) as
                    | "de-fr"
                    | "de-en"
                    | "de-es"
                    | "de-la"
                    | "de-it",
            };
            ampli.dictionaryNormalSuccessA(ampliProps);
        }
        setAudio(createAudio(searchResult.mediaId));
    };

    const eventEndListener = () => {
        setIsAudioPlaying(false);
    };

    const executeScroll = () => {
        if (scrollRef.current != null) {
            scrollRef.current.scrollIntoView({ block: "end" });
        }
    };

    useEffect(() => {
        if (reference) {
            reference.focus();
        }
    }, [reference]);

    useEffect(() => {
        if (props.searchResults && props.searchResults[0]) {
            setAudio(createAudio(props.searchResults[0].mediaId));
        }
    }, [props.searchResults]);

    useEffect(() => {
        if (audio) {
            audio.addEventListener("ended", eventEndListener);
        }
        return () => {
            if (audio) {
                audio.removeEventListener("ended", eventEndListener);
            }
        };
    }, [audio]);

    const playAudio = useCallback(async () => {
        if (audio) {
            setIsAudioPlaying(true);
            await audio.play();
        }
    }, [audio]);

    useEffect(() => {
        if (props.wordDetails && autoplayAudio) {
            playAudio();
        }
    }, [props.wordDetails, playAudio, autoplayAudio]);

    // scroll to selected element via key down/up press
    useEffect(() => {
        executeScroll();
    });

    const languageFilter = () => {
        if (props.activeIndexLanguageFilter === DictionaryConstants.LanguageFilterTypes.GermanAndForeignLang) {
            props.setActiveIndexLanguageFilter(DictionaryConstants.LanguageFilterTypes.ForeignLang);
        } else if (props.activeIndexLanguageFilter === DictionaryConstants.LanguageFilterTypes.ForeignLang) {
            props.setActiveIndexLanguageFilter(DictionaryConstants.LanguageFilterTypes.GermanLang);
        } else if (props.activeIndexLanguageFilter === DictionaryConstants.LanguageFilterTypes.GermanLang) {
            props.setActiveIndexLanguageFilter(DictionaryConstants.LanguageFilterTypes.GermanAndForeignLang);
        }
    };

    if (props.activeIndexLanguageFilter === DictionaryConstants.LanguageFilterTypes.GermanAndForeignLang) {
        languageFilterButtonText = "DE/" + props.dictionarySearchLanguage.toUpperCase();
    } else if (props.activeIndexLanguageFilter === DictionaryConstants.LanguageFilterTypes.ForeignLang) {
        languageFilterButtonText = props.dictionarySearchLanguage.toUpperCase();
    }

    return (
        <DictionaryPageContainer>
            {!props.loading && props.wordDetails && !props.hasPremium ? (
                <GoToShopPage onClick={props.onGoToShop} />
            ) : (
                <>
                    <DictionaryHeaderContainer>
                        <SearchContainer>
                            <InputContainer style={{ borderColor: theme.colors.primary }}>
                                <PhaseSixIcon name={"search"} />
                                <StyledInput
                                    ref={(r) => (reference = r)}
                                    onKeyDown={props.handleKeyPressed}
                                    onChange={props.handleChange}
                                    value={props.searchValue}
                                    placeholder={searchPlaceholderTranslation}
                                />
                                {props.searchValue.length > 0 && (
                                    <CloseIconContainer onClick={props.clearSearch}>
                                        <PhaseSixIcon name={"close"} />
                                    </CloseIconContainer>
                                )}
                                <FlagCombinationContainer>
                                    <FlagImage src={getFlagSource("de")} />
                                    <FlagImage src={getFlagSource(props.dictionarySearchLanguage)} />
                                </FlagCombinationContainer>
                            </InputContainer>
                            {props.searchValue.length > 0 && !props.wordDetails && (
                                <LanguageFilter onClick={languageFilter}>{languageFilterButtonText}</LanguageFilter>
                            )}
                            {props.wordDetails && (
                                <SoundContainer onClick={playAudio}>
                                    <SoundIcon
                                        name={"speaker"}
                                        size={"medium"}
                                        color={isAudioPlaying ? theme.colors.primary : theme.colors.textOnBackground}
                                    />
                                </SoundContainer>
                            )}
                        </SearchContainer>
                        <PonsImageContainer>
                            <PonsImage src={require("../../assets/img/dict/logo-pons.png")} />
                        </PonsImageContainer>
                    </DictionaryHeaderContainer>

                    <DictionaryContentContainer>
                        {props.searchValue.length === 0 && (
                            <SelectYourLanguageTextBox>{t_selectYourLanguageText}</SelectYourLanguageTextBox>
                        )}

                        {props.loading ? (
                            <LoadingContainer>
                                <Loading />
                                <LoadingText>{t_loadingText}</LoadingText>
                            </LoadingContainer>
                        ) : props.wordDetails ? (
                            props.hasPremium ? (
                                <WordDetailsContainer>
                                    <WordDetails
                                        activeWordClassIndex={props.activeWordClassIndex}
                                        activeWordIndex={props.activeWordIndex}
                                        activeExampleIndex={props.activeExampleIndex}
                                        wordDetails={props.wordDetails}
                                        exampleClicked={props.exampleClicked}
                                    />
                                </WordDetailsContainer>
                            ) : (
                                <GoToShopPage onClick={props.onGoToShop} />
                            )
                        ) : props.searchResults.length > 0 ? (
                            <>
                                {props.searchResults.map((searchResult, index) => {
                                    const active = props.activeIndex === index;
                                    return (
                                        <SearchResultContainer
                                            active={active}
                                            key={index}
                                            onClick={() => searchResultClicked(searchResult)}
                                            ref={active ? scrollRef : null}
                                        >
                                            <SearchResultImage src={getFlagSource(searchResult.lang)} />
                                            <SearchResultText active={active}>{searchResult.word}</SearchResultText>
                                        </SearchResultContainer>
                                    );
                                })}
                            </>
                        ) : props.searchValue !== "" ? (
                            <NoResultsContainer>
                                <PhaseSixIcon name={"search"} />
                                <NoResultsText>{t_noResultsText}</NoResultsText>
                            </NoResultsContainer>
                        ) : (
                            <LanguageCombo />
                        )}
                    </DictionaryContentContainer>
                    {props.wordDetails && props.hasPremium ? (
                        <AddToCardsButtonContainer>
                            <AddToCardsButton onClick={() => props.addWordToCards()}>
                                {t_addToCardsButtonText}
                            </AddToCardsButton>
                        </AddToCardsButtonContainer>
                    ) : null}
                </>
            )}
        </DictionaryPageContainer>
    );
};

export default Dictionary;
