import styled from "styled-components";

export const StyledTooltip = styled.div`
    position: absolute;
    z-index: 3;
    padding: ${({ theme: { base } }) => `${base.spacingInContent} ${base.spacingSmall}`};
    border-radius: ${({ theme: { base } }) => base.borderRadiusSmall};
    font-size: ${({ theme: { base } }) => base.smallSize};
    font-weight: bold;
    color: #fff;
    background-color: #666666;
    white-space: nowrap;
    transition: opacity ${({ theme: { base } }) => base.transition}s;
    &:after {
        content: " ";
        position: absolute;
        z-index: 1;
    }
    &:before {
        content: " ";
        position: absolute;
        z-index: 2;
        left: 0;
        top: 0;
        border: solid transparent;
        height: 0;
        width: 0;
        border-width: ${({ theme: { base } }) => base.borderRadiusSmall};
    }
`;

export const TooltipTop = styled(StyledTooltip)`
    bottom: 100%;
    left: 35%; // felt cute, might need to be changed later
    transform: translateX(-50%);
    margin-bottom: 10px;
    &:after {
        left: 0;
        top: 100%;
        width: 100%;
        height: 15px;
    }
    &:before {
        top: 100%;
        left: 50%;
        transform: translateX(-50%);
        border-top-color: #666666;
    }
`;

export const TooltipBottom = styled(TooltipTop)`
    left: 50%;
    bottom: auto;
    top: 100%;
    margin-top: 10px;
    &:after {
        top: auto;
        bottom: 100%;
    }
    &:before {
        top: auto;
        bottom: 100%;
        border-top-color: transparent;
        border-bottom-color: #666666;
    }
`;

export const TooltipLeft = styled(StyledTooltip)`
    top: 50%;
    right: 100%;
    transform: translateY(-50%);
    margin-right: 10px;
    &:after {
        top: 0;
        left: 100%;
        height: 100%;
        width: 15px;
    }
    &:before {
        top: 50%;
        left: 100%;
        transform: translateY(-50%);
        border-left-color: #666666;
    }
`;

export const TooltipRight = styled(TooltipLeft)`
    right: auto;
    left: 100%;
    margin-left: 10px;
    &:after {
        left: auto;
        right: 100%;
    }
    &:before {
        left: auto;
        right: 100%;
        border-left-color: transparent;
        border-right-color: #666666;
    }
`;

export const TooltipWrapper = styled.span<{$isBlock?: boolean}>`
    display: ${({$isBlock = false}) => $isBlock ? 'block' : 'inline-block'};
    position: relative;
    text-align: center;
    & > ${StyledTooltip} {
        display: none;
        opacity: 0;
    }
    &:hover > ${StyledTooltip} {
        display: block;
        opacity: 1;
    }
`;
