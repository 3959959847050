import styled from "styled-components";

export const StyledInput = styled.input`
    background: none repeat scroll 0 0 rgba(0, 0, 0, 0);
    border: medium none;
    box-shadow: none;
    display: inline;
    height: auto;
    flex: 1;
    padding: 3px 0 2px 10px;
    font-size: 16px;
    font-family: ${(props) => props.theme.base.fontFamily};
    transition: all 0.15s ease-in-out 0s;
    color: ${(props) => props.theme.colors.text};

    &:focus {
        outline: none;
    }
`;

export const InputContainer = styled.div`
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2) inset;
    background: ${(props) => props.theme.colors.backgroundContrast};
    width: 100%;
    margin: 10px 0;
    padding: 10px;
    border: none;
    border-radius: 0.5rem;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    transition: all 0.15s ease-in-out 0s;

    &:hover {
        background: ${(props) => props.theme.colors.backgroundMain};
    }
`;

export const StyledIcon = styled.div`
    color: ${(props) => props.theme.colors.text};
    line-height: 1;
`;
