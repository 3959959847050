import styled from "styled-components";

export const ProgressLine = styled.div<{ value: number }>`
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: ${({ value }) => value}%;
    border-radius: 7px;
    transition: width ${({ theme: { base } }) => base.transition};

    &:first-child {
        z-index: 1;
        background-color: ${({ theme: { colors } }) => colors.textOnBackground};
    }
    &:last-child {
        z-index: 2;
        background-color: ${({ theme: { colors } }) => colors.primary};
    }
`;

export const ProgressWrapper = styled.div`
    position: relative;
    width: 100%;
    height: 15px;
    background-color: ${({ theme: { colors } }) => colors.backgroundContrast};
    border-radius: 7px;
    overflow: hidden;
`;
