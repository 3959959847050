import axios, { AxiosPromise } from "axios";
import { IResponse } from "p6m-response";
import { LeaderboardData, LeaderboardSchoolsData, LeaderboardTabs, SchoolData, LeaderboardDataTimeParam } from "p6m-user";

export function getLeaderboardSchools(
    type: Exclude<LeaderboardTabs, "all" | "mySchool" | "family">,
    week: LeaderboardDataTimeParam,
    start: number,
    amount: number,
    userId: string
): AxiosPromise<IResponse<LeaderboardSchoolsData>> {
    let schoolRequestType = "all";
    if (type === "favourites") {
        schoolRequestType = "favorit";
    }
    return axios.get(
        "leaderboard/PageAndSchools/" + week + "/" + start + "/" + amount + "/" + schoolRequestType + "/" + userId
    );
}

export function getLeaderboard(
    type: Exclude<LeaderboardTabs, "allSchools" | "favourites">,
    week: LeaderboardDataTimeParam,
    start: number,
    amount: number,
    userId: string
): AxiosPromise<IResponse<LeaderboardData>> {
    let url = "leaderboardPageAndUser2/" + week + "/" + start + "/" + amount + "/" + userId;
    switch (type) {
        case "mySchool":
            url = "leaderboard/PageAndUserBySchool/" + week + "/" + start + "/" + amount + "/main/" + userId;
            break;
        case "family":
            url = "leaderboard/UsersFamily/" + week + "/" + start + "/" + amount + "/" + userId;
            break;
        default:
            break;
    }
    return axios.get(url);
}

export function getSchoolsByString(
    searchText: string,
    userId: string
): AxiosPromise<IResponse<{ schools: Array<SchoolData> }>> {
    const data = {
        limit: 20,
        searchString: searchText,
        userId: userId,
    };
    return axios.post("schoolsearch", data);
}

export function addOwnSchool(schoolId: number, userId: string): AxiosPromise<IResponse<void>> {
    return axios.get("usermainfavoriteschools/main/" + schoolId + "/" + userId + "/add");
}

export function deleteOwnSchool(schoolId: number, userId: string): AxiosPromise<IResponse<void>> {
    return axios.get("usermainfavoriteschools/main/" + schoolId + "/" + userId + "/delete");
}

export function addFavouriteSchool(schoolId: number, userId: string): AxiosPromise<IResponse<void>> {
    return axios.get("usermainfavoriteschools/favorit/" + schoolId + "/" + userId + "/add");
}

export function deleteFavouriteSchool(schoolId: number, userId: string): AxiosPromise<IResponse<void>> {
    return axios.get("usermainfavoriteschools/favorit/" + schoolId + "/" + userId + "/delete");
}
