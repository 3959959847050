//DEPENDENCIES
import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useT } from "@transifex/react";
import _ from "lodash";

//REDUX
import { selectors as subjectSelectors, actions as subjectActions } from "../../redux/subjects/subjectsSlice";
import { selectors as userSelectors } from "../../redux/user/userSlice";

//UTILS
import { getUnitOptionsFromSubject, getValidSubjectOptionsFromSubjects } from "../../helpers/Subjects";
import { isDefaultUnitId } from "../../helpers/Units";

//HOOKS
import { useUnitsEdit } from "./useDropdownUnitsEdit";
import { useSubjectsEdit } from "./useDropdownSubjectsEdit";

//TYPES
import { DropdownOption } from "p6m-viewData";
import { EntryChange, SubjectContent, SubjectData, SubjectUnitContent, SubjectUnitData } from "p6m-subjects";

// Utility function for handling network-requests for subject and unit creations, updates and deletions
export const processChanges = async <T extends SubjectData | SubjectUnitData>({
    subjectId,
    changes,
    findItemFn,
    handleCreationFn,
    handleUpdateFn,
    handleDeletionsFn,
}: {
    subjectId: string;
    changes: EntryChange[];
    findItemFn: (props: { itemId: string }) => T | undefined; // find a unit/subject
    handleCreationFn: (props: { subjectId: string; itemName: string; index: number }) => Promise<any>;
    handleUpdateFn: (props: { itemId: string; itemData: T }) => Promise<any>;
    handleDeletionsFn: (itemsToDelete: T[]) => Promise<void>;
}): Promise<{ itemsToCreate: any[]; itemsToUpdate: any[] }> => {
    let itemsToCreate: Promise<void>[] = [];
    let itemsToUpdate: Promise<any>[] = [];
    let itemsToDelete: T[] = [];

    changes.forEach(async (change) => {
        const originalItem = findItemFn({ itemId: change.id });
        if (originalItem) {
            const itemContent: SubjectContent | SubjectUnitContent =
                (originalItem as SubjectData).subjectContent || (originalItem as SubjectUnitData).unitContent;

            if (change.deleted) {
                itemsToDelete.push(originalItem);
            } else if (itemContent.name !== change.name) {
                const copiedItem = _.cloneDeep(originalItem);
                const copiedItemContent: SubjectContent | SubjectUnitContent =
                    (copiedItem as SubjectData).subjectContent || (copiedItem as SubjectUnitData).unitContent;
                copiedItemContent.name = change.name;
                itemsToUpdate.push(handleUpdateFn({ itemId: change.id, itemData: copiedItem }));
            }
        } else if (change.name && change.id !== "default") {
            itemsToCreate.push(handleCreationFn({ subjectId, itemName: change.name, index: itemsToCreate.length }));
        }
    });

    if (itemsToDelete.length > 0) {
        await handleDeletionsFn(itemsToDelete);
    }

    return { itemsToCreate, itemsToUpdate };
};

export const useEditDropdown = ({
    subjectId,
    unitId,
    confirmDeletions = true,
    onAutoSelectSubject,
    onAutoSelectUnit,
}: {
    subjectId?: string;
    unitId?: string;
    confirmDeletions?: boolean;
    onAutoSelectSubject?: (id: string) => void;
    onAutoSelectUnit?: (id: string) => void;
}) => {
    const dispatch = useDispatch();
    const t = useT();
    const userDnsId = useSelector(userSelectors.userId);

    const dropdownSubjectLabel = t("Subject", { _tags: "library,label" });
    const dropdownUnitLabel = t("Unit", { _tags: "library,label" });
    const defaultLabel = t("General", {});

    const templateDefaultOption: DropdownOption = useMemo(() => {
        return {
            title: defaultLabel,
            value: "default",
            editable: false,
            deletable: false,
        };
    }, [defaultLabel]);

    const allSubjects = useSelector(subjectSelectors.librarySubjects);
    const selectedSubject = allSubjects.find((subject) => subject.subjectMetadata.subjectIdToOwner.id === subjectId);
    const allUnits = useSelector(subjectSelectors.getUnitsBySubjectId(subjectId || "", "librarySubjects"));

    const saveUnitsEdit = useUnitsEdit({ subject: selectedSubject, subjectId, confirmDeletions, defaultLabel });
    const saveSubjectsEdit = useSubjectsEdit({ subjects: allSubjects, subjectId, confirmDeletions, defaultLabel });

    const validSubjectItems = useMemo(
        () => getValidSubjectOptionsFromSubjects(allSubjects, "" + userDnsId),
        [allSubjects, userDnsId]
    );

    const allSubjectItemsToUse = useMemo(() => {
        if (!!onAutoSelectSubject) {
            return validSubjectItems.length ? validSubjectItems : [templateDefaultOption];
        } else {
            return validSubjectItems;
        }
    }, [onAutoSelectSubject, validSubjectItems, templateDefaultOption]);

    const validUnitItems = useMemo(
        () =>
            selectedSubject
                ? getUnitOptionsFromSubject({
                      subject: selectedSubject,
                      units: allUnits,
                      userId: "" + userDnsId,
                      userIsTeacher: false,
                  })
                : [],
        [selectedSubject, allUnits, userDnsId]
    );

    const validDefaultUnit = useMemo(
        () => validUnitItems.find((option) => isDefaultUnitId(option.value || "")),
        [validUnitItems]
    );
    const defaultUnitIdToUse = useMemo(
        () => validDefaultUnit?.value || templateDefaultOption.value!,
        [validDefaultUnit, templateDefaultOption]
    );

    const allUnitItemsToUse: DropdownOption[] = useMemo(() => {
        if (!!onAutoSelectUnit) {
            if (validUnitItems.length) {
                return !!validDefaultUnit ? validUnitItems : [...validUnitItems, templateDefaultOption];
            } else {
                return [templateDefaultOption];
            }
        } else {
            return validUnitItems;
        }
    }, [validSubjectItems.length, validDefaultUnit, validUnitItems, templateDefaultOption, onAutoSelectUnit]);

    useEffect(() => {
        if (!allUnits && subjectId && subjectId !== "default") {
            dispatch(subjectActions.loadSubjectUnits({ subjectId, target: "librarySubjects" }));
        }
    }, [dispatch, allUnits, subjectId]);

    useEffect(() => {
        if (!!onAutoSelectSubject) {
            if (validSubjectItems.length && validSubjectItems[0]?.value) {
                if (
                    !subjectId ||
                    (subjectId === "default" && !validSubjectItems.find((item) => item.value === "default"))
                ) {
                    onAutoSelectSubject(validSubjectItems[0].value);
                } else if (
                    subjectId &&
                    subjectId !== "default" &&
                    !validSubjectItems.find((item) => item.value === subjectId)
                ) {
                    onAutoSelectSubject(validSubjectItems[0].value);
                }
            } else if (!validSubjectItems.length && subjectId && subjectId !== "default") {
                onAutoSelectSubject("default");
            }
        }
    }, [onAutoSelectSubject, subjectId, validSubjectItems, validSubjectItems[0]?.value]);

    useEffect(() => {
        if (!!onAutoSelectUnit) {
            if (unitId !== defaultUnitIdToUse) {
                //if selectedUnitId doesnt exist in list
                if (
                    validUnitItems.length &&
                    !validUnitItems.map((item) => item.value).includes(unitId) &&
                    allUnitItemsToUse[0].value
                ) {
                    onAutoSelectUnit(allUnitItemsToUse[0].value);
                }
            }
        }
    }, [onAutoSelectUnit, unitId, defaultUnitIdToUse, validUnitItems, allUnitItemsToUse[0]?.value]);

    return {
        subjects: {
            dropdownSubjectLabel,
            dropdownSubjectItems: allSubjectItemsToUse,
            saveSubjectsEdit,
            selectedSubject,
            allSubjects,
        },
        units: {
            dropdownUnitLabel,
            dropdownUnitItems: allUnitItemsToUse,
            saveUnitsEdit,
            allUnits,
        },
    };
};
