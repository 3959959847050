// REACT
import React from "react";

// LIBRARIES
import { useT } from "@transifex/react";
import { range } from "lodash";

// TYPES
import { UserPreferences, ParentSettings } from "p6m-user";

// COMPONENTS
import SettingsRangeSlider from "../../basic/settingsRangeSlider/SettingsRangeSlider";
import SettingsToggleInput, { ToggleTypes } from "../../basic/settingsToggleInput/SettingsToggleInput";
import SettingsItemWrapper from "../settingsItemWrapper/SettingsItemWrapper";
import Tooltip from "../../basic/tooltip/Tooltip";

// STYLED COMPONENTS
import { ChildrenAnswerLockWrapper, MainWrapper, StyledSvgIcon } from "./styles";

export interface SettingsChildrenProps {
    childrenPreferences: UserPreferences;
    parentSettings: ParentSettings;
    updateParentPreferenceItem: (entry: string, value?: boolean) => any;
    updateWrongAnswerBehaviour: (web: boolean, enforce: boolean, retype: boolean) => any;
    updateResetPhaseOnBadAnswer: (value: boolean) => any;
    updateTthLimit: (value: number) => any;
}

const SettingsChildren: React.FC<SettingsChildrenProps> = (props) => {
    const t = useT();

    const t_dailyGoal = t("daily_goal_family_label", {
        _tags: "childrenSettings",
    });
    const t_dailyGoalInput = t("daily_goal_family_name", {
        _tags: "childrenSettings",
    });
    const t_dailyGoalInfo = t("daily_goal_family_information", {
        _tags: "childrenSettings",
    });

    const t_learningModeWeb = t("learning_settings_web_family_lock_label", {
        _tags: "childrenSettings",
    });
    const t_learningModeWebInput = t("learning_mode_family_web_name", {
        _tags: "childrenSettings",
    });
    const t_learningModeWebInfo = t("not_enforced_input_web_family_information", { _tags: "childrenSettings" });
    const t_learningModeWebInfoLocked = t("enforced_input_web_family_information", { _tags: "childrenSettings" });

    const t_wrongInputBehaviour = t("wrong_input_family_web_label", {
        _tags: "childrenSettings",
    });
    const t_wrongInputBehaviourInput_1 = t("wrong_input_option1_family_web_name", { _tags: "childrenSettings" });
    const t_wrongInputBehaviourInput_2 = t("wrong_input_option2_family_web_name", { _tags: "childrenSettings" });
    const t_wrongInputBehaviourInput_3 = t("wrong_input_option3_family_web_name", { _tags: "childrenSettings" });
    const t_wrongInputBehaviourInfo_1 = t("wrong_input_option1_family_web_information", { _tags: "childrenSettings" });
    const t_wrongInputBehaviourInfo_2 = t("wrong_input_option2_family_web_information", { _tags: "childrenSettings" });
    const t_wrongInputBehaviourInfo_3 = t("wrong_input_option3_family_web_information", { _tags: "childrenSettings" });

    const t_backButtonInput = t("deactivate_back_button_mobile_name", {
        _tags: "childrenSettings",
    });
    const t_backButtonInfo = t("deactivate_back_button_mobile_information", {
        _tags: "childrenSettings",
    });

    const t_learningModeMob = t("learning_settings_mobile_family_lock_label", {
        _tags: "childrenSettings",
    });
    const t_learningModeMobInput = t("learning_mode_family_mobile_name", {
        _tags: "childrenSettings",
    });
    const t_learningModeMobInfo = t("not_enforced_input_mobile_family_information", { _tags: "childrenSettings" });
    const t_learningModeMobInfoLocked = t("enforced_input_mobile_family_information", { _tags: "childrenSettings" });

    const t_wrongInputBehaviourMob = t("wrong_input_family_mobile_label", {
        _tags: "childrenSettings",
    });
    const t_wrongInputBehaviourInputMob_1 = t("wrong_input_option1_family_mobile_name", { _tags: "childrenSettings" });
    const t_wrongInputBehaviourInputMob_2 = t("wrong_input_option2_family_mobile_name", { _tags: "childrenSettings" });
    const t_wrongInputBehaviourInputMob_3 = t("wrong_input_option3_family_mobile_name", { _tags: "childrenSettings" });
    const t_wrongInputBehaviourInfoMob_1 = t("wrong_input_option1_family_mobile_information", {
        _tags: "childrenSettings",
    });
    const t_wrongInputBehaviourInfoMob_2 = t("wrong_input_option2_family_mobile_information", {
        _tags: "childrenSettings",
    });
    const t_wrongInputBehaviourInfoMob_3 = t("wrong_input_option3_family_mobile_information", {
        _tags: "childrenSettings",
    });

    const t_wrongAnswerBehaviour = t("on_wrong_answer_web_family_lock_label", {
        _tags: "childrenSettings",
    });
    const t_wrongAnswerBehaviourInput_1 = t("wrong_answer_action_option1_family_name", { _tags: "childrenSettings" });
    const t_wrongAnswerBehaviourInput_2 = t("wrong_answer_action_option2_family_name", { _tags: "childrenSettings" });
    const t_wrongAnswerBehaviourInfo_1 = t("wrong_answer_action_option1_family_information", {
        _tags: "childrenSettings",
    });
    const t_wrongAnswerBehaviourInfo_2 = t("wrong_answer_action_option2_family_information", {
        _tags: "childrenSettings",
    });
    const t_wrongAnswerBehaviourInfo_1_lock = t("wrong_answer_action_option1_information", {
        _tags: "childrenSettings",
    });
    const t_wrongAnswerBehaviourInfo_2_lock = t("wrong_answer_action_option2_information", {
        _tags: "childrenSettings",
    });
    const t_enableChildrenToChangeSettingsTooltip = t(
        "Here you can determine whether or not your child can change the following settings",
        { _tags: "childrenSettings" }
    );

    const t_allowAccess = t("allow_access_to_family_label", {
        _tags: "childrenSettings",
    });
    const t_allowAccessInput_1 = t("view_cards_library_access_family_name", {
        _tags: "childrenSettings",
    });
    const t_allowAccessInput_2 = t("modify_cards_library_access_family_name", {
        _tags: "childrenSettings",
    });
    const t_allowAccessInfo = t("modify_cards_library_access_family_information", { _tags: "childrenSettings" });

    const t_phaseSettingsInput = t("phase_settings_access_family_name", {
        _tags: "childrenSettings",
    });
    const t_phaseSettingsInfo = t("phase_settings_access_family_information", {
        _tags: "childrenSettings",
    });

    const t_leaderboards = t("leaderboard_family_label", {
        _tags: "childrenSettings",
    });
    const t_leaderboardsInput = t("replace_leaderboard_name_family_name", {
        _tags: "childrenSettings",
    });
    const t_leaderboardsInfo = t("replace_leaderboard_name_family_information", { _tags: "childrenSettings" });

    const {
        childrenPreferences,
        parentSettings,
        updateParentPreferenceItem,
        updateWrongAnswerBehaviour,
        updateResetPhaseOnBadAnswer,
        updateTthLimit,
    } = props;

    function getWrongBehaviourWebDescription() {
        if (
            childrenPreferences.enforceCorrectAnswerWeb === false &&
            childrenPreferences.retypeCorrectAnswerWeb === false
        ) {
            return t_wrongInputBehaviourInfo_1;
        } else if (
            childrenPreferences.enforceCorrectAnswerWeb === false &&
            childrenPreferences.retypeCorrectAnswerWeb === true
        ) {
            return t_wrongInputBehaviourInfo_2;
        } else {
            return t_wrongInputBehaviourInfo_3;
        }
    }

    function getWrongBehaviourMobDescription() {
        if (childrenPreferences.enforceCorrectAnswer === false && childrenPreferences.retypeCorrectAnswer === false) {
            return t_wrongInputBehaviourInfoMob_1;
        } else if (
            childrenPreferences.enforceCorrectAnswer === false &&
            childrenPreferences.retypeCorrectAnswer === true
        ) {
            return t_wrongInputBehaviourInfoMob_2;
        } else {
            return t_wrongInputBehaviourInfoMob_3;
        }
    }

    function getWrongAnswerBehaviourDescription() {
        if (childrenPreferences.resetPhaseOnBadAnswer) {
            if (parentSettings.resetPhaseOnBadAnswerLock) {
                return t_wrongAnswerBehaviourInfo_2;
            } else {
                return t_wrongAnswerBehaviourInfo_2_lock;
            }
        } else {
            if (parentSettings.resetPhaseOnBadAnswerLock) {
                return t_wrongAnswerBehaviourInfo_1;
            } else {
                return t_wrongAnswerBehaviourInfo_1_lock;
            }
        }
    }

    const childrenWrongAnswerLock = (
        <ChildrenAnswerLockWrapper
            className={parentSettings.resetPhaseOnBadAnswerLock ? "highlighted" : ""}
            onClick={() =>
                updateParentPreferenceItem("resetPhaseOnBadAnswerLock", !parentSettings.resetPhaseOnBadAnswerLock)
            }
        >
            <Tooltip
                content={t_enableChildrenToChangeSettingsTooltip}
                position={"left"}
            >
                <StyledSvgIcon
                    icon={parentSettings.resetPhaseOnBadAnswerLock ? "child-lock" : "child-unlock"}
                    isActive={false}
                    userDefinedIconColor={parentSettings.resetPhaseOnBadAnswerLock ? "white" : ""}
                />
            </Tooltip>
        </ChildrenAnswerLockWrapper>
    );

    return (
        <MainWrapper>
            <SettingsItemWrapper
                title={t_dailyGoal}
                description={t_dailyGoalInfo}
            >
                <SettingsRangeSlider
                    label={t_dailyGoalInput}
                    optionsValueList={range(5, 201)}
                    defaultValue={childrenPreferences.tthLimit}
                    onChange={(value) => updateTthLimit(value)}
                />
            </SettingsItemWrapper>

            <SettingsItemWrapper
                title={t_learningModeWeb}
                description={
                    parentSettings.practiceSettingsLockWeb ? t_learningModeWebInfoLocked : t_learningModeWebInfo
                }
            >
                <SettingsToggleInput
                    labelText={t_learningModeWebInput}
                    checkedValue={parentSettings.practiceSettingsLockWeb}
                    onValueChanged={(checked) => updateParentPreferenceItem("practiceSettingsLockWeb", checked)}
                />
            </SettingsItemWrapper>

            <SettingsItemWrapper
                title={t_wrongInputBehaviour}
                description={getWrongBehaviourWebDescription()}
            >
                <SettingsToggleInput
                    labelText={t_wrongInputBehaviourInput_1}
                    toggleType={ToggleTypes.CheckIcon}
                    checkedValue={
                        childrenPreferences.enforceCorrectAnswerWeb === false &&
                        childrenPreferences.retypeCorrectAnswerWeb === false
                    }
                    onValueChanged={(checked) => {
                        if (!!checked) updateWrongAnswerBehaviour(true, false, false);
                    }}
                />

                <SettingsToggleInput
                    labelText={t_wrongInputBehaviourInput_2}
                    toggleType={ToggleTypes.CheckIcon}
                    checkedValue={
                        childrenPreferences.enforceCorrectAnswerWeb === false &&
                        childrenPreferences.retypeCorrectAnswerWeb === true
                    }
                    onValueChanged={(checked) => {
                        if (!!checked) updateWrongAnswerBehaviour(true, false, true);
                    }}
                />

                <SettingsToggleInput
                    labelText={t_wrongInputBehaviourInput_3}
                    toggleType={ToggleTypes.CheckIcon}
                    checkedValue={
                        childrenPreferences.enforceCorrectAnswerWeb === true &&
                        childrenPreferences.retypeCorrectAnswerWeb === true
                    }
                    onValueChanged={(checked) => {
                        if (!!checked) updateWrongAnswerBehaviour(true, true, true);
                    }}
                />
            </SettingsItemWrapper>

            <SettingsItemWrapper
                title={t_learningModeMob}
                description={
                    parentSettings.practiceSettingsLockMobile ? t_learningModeMobInfoLocked : t_learningModeMobInfo
                }
            >
                <SettingsToggleInput
                    labelText={t_learningModeMobInput}
                    checkedValue={parentSettings.practiceSettingsLockMobile}
                    onValueChanged={(checked) => updateParentPreferenceItem("practiceSettingsLockMobile", checked)}
                />
            </SettingsItemWrapper>

            <SettingsItemWrapper
                title={t_wrongInputBehaviourMob}
                description={getWrongBehaviourMobDescription()}
            >
                <SettingsToggleInput
                    labelText={t_wrongInputBehaviourInputMob_1}
                    toggleType={ToggleTypes.CheckIcon}
                    checkedValue={
                        childrenPreferences.enforceCorrectAnswer === false &&
                        childrenPreferences.retypeCorrectAnswer === false
                    }
                    onValueChanged={(checked) => {
                        if (!!checked) updateWrongAnswerBehaviour(false, false, false);
                    }}
                />

                <SettingsToggleInput
                    labelText={t_wrongInputBehaviourInputMob_2}
                    toggleType={ToggleTypes.CheckIcon}
                    checkedValue={
                        childrenPreferences.enforceCorrectAnswer === false &&
                        childrenPreferences.retypeCorrectAnswer === true
                    }
                    onValueChanged={(checked) => {
                        if (!!checked) updateWrongAnswerBehaviour(false, false, true);
                    }}
                />

                <SettingsToggleInput
                    labelText={t_wrongInputBehaviourInputMob_3}
                    toggleType={ToggleTypes.CheckIcon}
                    checkedValue={
                        childrenPreferences.enforceCorrectAnswer === true &&
                        childrenPreferences.retypeCorrectAnswer === true
                    }
                    onValueChanged={(checked) => {
                        if (!!checked) updateWrongAnswerBehaviour(false, true, true);
                    }}
                />
            </SettingsItemWrapper>

            <SettingsItemWrapper description={t_backButtonInfo}>
                <SettingsToggleInput
                    labelText={t_backButtonInput}
                    checkedValue={parentSettings.backButtonLockMobile}
                    onValueChanged={(checked) => updateParentPreferenceItem("backButtonLockMobile", checked)}
                />
            </SettingsItemWrapper>

            <SettingsItemWrapper
                title={t_wrongAnswerBehaviour}
                description={getWrongAnswerBehaviourDescription()}
                lockComponent={childrenWrongAnswerLock}
            >
                <SettingsToggleInput
                    labelText={t_wrongAnswerBehaviourInput_1}
                    toggleType={ToggleTypes.CheckIcon}
                    checkedValue={childrenPreferences.resetPhaseOnBadAnswer === false}
                    onValueChanged={(checked) => {
                        if (checked) updateResetPhaseOnBadAnswer(false);
                    }}
                />
                <SettingsToggleInput
                    labelText={t_wrongAnswerBehaviourInput_2}
                    toggleType={ToggleTypes.CheckIcon}
                    checkedValue={childrenPreferences.resetPhaseOnBadAnswer === true}
                    onValueChanged={(checked) => {
                        if (checked) updateResetPhaseOnBadAnswer(true);
                    }}
                />
            </SettingsItemWrapper>

            <SettingsItemWrapper
                title={t_allowAccess}
                description={t_allowAccessInfo}
            >
                <SettingsToggleInput
                    labelText={t_allowAccessInput_1}
                    checkedValue={parentSettings.libraryAccess}
                    onValueChanged={(checked) => updateParentPreferenceItem("libraryAccess", checked)}
                />
                {parentSettings.libraryAccess && (
                    <SettingsToggleInput
                        labelText={t_allowAccessInput_2}
                        onValueChanged={(checked) => {
                            updateParentPreferenceItem("learnedRelearnedAccess", checked);
                        }}
                        checkedValue={parentSettings.learnedRelearnedAccess}
                    />
                )}
            </SettingsItemWrapper>

            <SettingsItemWrapper description={t_phaseSettingsInfo}>
                <SettingsToggleInput
                    labelText={t_phaseSettingsInput}
                    checkedValue={parentSettings.phasePrefAccess}
                    onValueChanged={(checked) => updateParentPreferenceItem("phasePrefAccess", checked)}
                />
            </SettingsItemWrapper>

            <SettingsItemWrapper
                title={t_leaderboards}
                description={t_leaderboardsInfo}
            >
                <SettingsToggleInput
                    labelText={t_leaderboardsInput}
                    checkedValue={parentSettings.useFirstNameLock}
                    onValueChanged={(checked) => updateParentPreferenceItem("useFirstNameLock", checked)}
                />
            </SettingsItemWrapper>
        </MainWrapper>
    );
};

export default SettingsChildren;
