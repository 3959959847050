// LIBRARIES
import React from "react";
import styled from "styled-components";

// MODELS
import { User, UserPreferences, IUserMetadata } from "p6m-user";

// COMPONENTS
import SideNavigationEntry from "../../complex/sideNavigationEntry/SideNavigationEntry";
import { useT } from "@transifex/react";

export interface SideNavigationProps {
    user: User;
    userPreferences: UserPreferences;
    userMetadata: IUserMetadata;
    isTeacher: boolean;
    isUserAnonymous: boolean;
    practiced50Cards: boolean;
    hasFirstPractice: boolean;
    isLibraryAccessDisabled: boolean;
    recommendUrl: string;
    homeName: string;
    starsCount: number;
    openContentModal: () => void;
    clickMenuButton: (args?: { showAddCardModal?: boolean }) => void;
}

const Sidebar = styled.div`
    height: 100%;
    margin: 0 0 0 10px;
    width: 100px;

    @media (max-width: ${process.env.REACT_APP_LOWER_GRID_STEP_BORDER}px) {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        margin: 0;
    }
`;
const StyledContainer = styled.div`
    width: 120px;
    min-width: 120px;
    height: 100%;

    @media (max-width: ${process.env.REACT_APP_LOWER_GRID_STEP_BORDER}px) {
        width: 100%;
    }
`;

const SideNavigation: React.FC<SideNavigationProps> = (props) => {
    const t = useT();

    const t_games = t("Spiele", { _tags: "sidebar" });
    const t_library = t("Library", { _tags: "sidebar" });
    const t_add = t("Create cards", { _tags: "sidebar" });
    const t_dictionary = t("Dictionary", { _tags: "sidebar" });
    const t_reports = t("Reports", { _tags: "sidebar" });
    const t_shop = t("Shop", { _tags: "sidebar" });
    const t_libraryTooltipParentRestricted = t("Restricted by your Parents", { _tags: "sidebar,tooltip" });
    const t_libraryTooltipNoPractice = t("Have a first practice!", { _tags: "sidebar,tooltip" });
    const t_gamesTooltip = t("Practice 50 cards", { _tags: "sidebar,tooltip" });

    const libraryTooltip = props.hasFirstPractice ? t_libraryTooltipParentRestricted : t_libraryTooltipNoPractice;

    let gamesIcon: "no-star" | "one-star" | "two-stars" | "three-stars" = "no-star";
    switch (props.starsCount) {
        case 1:
            gamesIcon = "one-star";
            break;
        case 2:
            gamesIcon = "two-stars";
            break;
        case 3:
            gamesIcon = "three-stars";
            break;
        default:
            break;
    }

    const handleClickAddCardMenuButton = () => {
        props.clickMenuButton({ showAddCardModal: true });
    };

    const handleClickGetContentMenuButton = () => {
        props.clickMenuButton();
        props.openContentModal();
    };

    return (
        <StyledContainer {...props}>
            <Sidebar>
                <SideNavigationEntry
                    title={props.homeName}
                    icon={"house"}
                    navTarget={"/home"}
                    key={"home"}
                    {...(props.isTeacher ? null : { onClick: props.clickMenuButton })}
                />
                <SideNavigationEntry
                    title={t_games}
                    icon={gamesIcon}
                    navTarget={"/games"}
                    onClick={props.clickMenuButton}
                    disabledTooltipText={t_gamesTooltip}
                    disabled={!props.practiced50Cards}
                    key={"games"}
                />
                <SideNavigationEntry
                    title={t_library}
                    icon={"library"}
                    navTarget={"/manage"}
                    disabled={!props.hasFirstPractice || props.isLibraryAccessDisabled}
                    disabledTooltipText={libraryTooltip}
                    onClick={props.clickMenuButton}
                    key={"library"}
                />
                <SideNavigationEntry
                    title={t_add}
                    icon={"add"}
                    navTarget={"/add"}
                    onClick={handleClickAddCardMenuButton}
                    key={"add"}
                />
                <SideNavigationEntry
                    title={t_dictionary}
                    navTarget={"/dictionary"}
                    icon={"dictionary"}
                    onClick={props.clickMenuButton}
                    key={"dictionary"}
                />
                <SideNavigationEntry
                    title={t_reports}
                    icon={"reports"}
                    navTarget={"/reports"}
                    onClick={props.clickMenuButton}
                    key={"Reports"}
                />
                <SideNavigationEntry
                    title={t_shop}
                    icon={"cart"}
                    onClick={handleClickGetContentMenuButton}
                    key={"Shop"}
                    navTarget={"#"}
                />
            </Sidebar>
        </StyledContainer>
    );
};

export default SideNavigation;
