// REACT
import React, { FunctionComponent, useState, useEffect, memo, useRef } from "react";

// STYLED COMPONENTS
import { GameItemWrapper, GameCard, CardText } from "./styles";

type Animations = "dragstart" | "error" | "done" | "selected";

export type Props = {
    id: string;
    title: string;
    question: boolean;
    resolved: boolean;
    selected: boolean;
    onClick?: (index: string) => boolean; // true if error
    onStart?: (index: string) => void;
    onDrop?: (index: string) => boolean; // true if error
};

const GameItem: FunctionComponent<Props> = (props) => {
    const {
        id,
        title,
        question = false,
        resolved = false,
        selected = false,
        onDrop = () => false,
        onStart = () => {},
    } = props;

    const cardTextRef = useRef<null | HTMLDivElement>(null);
    const [fontSize, setFontSize] = useState(18);
    const [fontResizeCompleted, setFontResizeCompleted] = useState(false);

    const [dragStart, setDragStart] = useState(false);
    const [dragError, setDragError] = useState(false);

    const animations: Animations[] = (() => {
        if (resolved) return ["done"] as Animations[];
        const result: Animations[] = [];
        if (selected) result.push("selected");
        if (dragStart) result.push("dragstart");
        if (dragError) result.push("error");
        return result;
    })();

    useEffect(() => {
        if (!dragError) return;
        setTimeout(() => setDragError(false), 300);
    }, [dragError]);

    useEffect(() => {
        const resizeFont = ({ offsetHeight, scrollHeight, offsetWidth, scrollWidth }: HTMLElement) => {
            const largerThanContainerHeight = scrollHeight > offsetHeight;
            const largerThanContainerWidth = scrollWidth > offsetWidth;
            if (largerThanContainerHeight || largerThanContainerWidth) {
                setTimeout(function () {
                    setFontSize((currentSize) => currentSize - 1);
                }, 10);
            } else {
                setFontResizeCompleted(true);
            }
        };
        const element = cardTextRef.current;
        !fontResizeCompleted && element && resizeFont(element);
    }, [
        fontResizeCompleted,
        cardTextRef.current?.offsetHeight,
        cardTextRef.current?.scrollHeight,
        cardTextRef.current?.offsetWidth,
        cardTextRef.current?.scrollWidth,
    ]);

    return (
        <GameItemWrapper
            question={question}
            animations={animations}
        >
            {!resolved && (
                <GameCard
                    draggable={true}
                    onClick={() => {
                        onStart(id);
                        setDragError(onDrop(id));
                    }}
                    onDragOver={(e) => e.preventDefault()}
                    onDragStart={() => {
                        onStart(id);
                        setDragStart(true);
                    }}
                    onDrop={() => {
                        setDragError(onDrop(id));
                        setDragStart(false);
                    }}
                    onDragEnd={() => setDragStart(false)}
                >
                    <CardText
                        ref={cardTextRef}
                        style={{
                            opacity: fontResizeCompleted ? 1 : 0,
                            fontSize,
                        }}
                    >
                        {title}
                    </CardText>
                </GameCard>
            )}
        </GameItemWrapper>
    );
};

export default memo(GameItem);
