import { ReactComponent as DefaultAvatar } from "../../assets/grayTheme/icon_avatar_default.svg";
import { ReactComponent as SidebarDictionary } from "../../assets/grayTheme/icon_dictionary_add.svg";
import { ReactComponent as SidebarSchool } from "../../assets/grayTheme/icon_school_simple.svg";
import { ReactComponent as SidebarReports } from "../../assets/grayTheme/icon_reports_sidebar_simple.svg";
import { ReactComponent as UnconfirmedProfile } from "../../assets/grayTheme/icon_unconfirmed_profile_simple.svg";
import { ReactComponent as Impressum } from "../../assets/grayTheme/icon_impressum_simple.svg";
import { ReactComponent as ParentSettingLocked } from "../../assets/grayTheme/icon_parent_setting_locked.svg";
import { ReactComponent as ParentSettingUnlocked } from "../../assets/grayTheme/icon_parent_setting_unlocked.svg";
import { FunctionComponent } from "react";

const Assets: { icons: { [key: string]: FunctionComponent } } = {
    icons: {
        defaultAvatar: DefaultAvatar,
        dictionary: SidebarDictionary,
        school: SidebarSchool,
        reports: SidebarReports,
        unconfirmedProfile: UnconfirmedProfile,
        impressum: Impressum,
        parentSettingLocked: ParentSettingLocked,
        parentSettingUnlocked: ParentSettingUnlocked,
    },
};
export default Assets;
