//DEPS
import React from "react";
import styled from "styled-components";
import { useT } from "@transifex/react";

//COMPONENTS
import { DefaultHeader } from "../../basic/modal/DefaultHeader";

export interface ShareModalProps {
    url: string;
    close: () => any;
}

const Background = styled.div`
    height: 100%;
    width: 100%;
    padding: 0;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    overflow: hidden;
`;

const StyledIframe = styled.iframe`
    display: block;
    box-sizing: border-box;
    width: 100%;
    height: 80vh;
    border: none;
`;

const ShareModal: React.FC<ShareModalProps> = (props) => {
    const t = useT();

    const share_t = t("Share", { _tags: "title, modal" });

    return (
        <Background>
            <DefaultHeader
                title={share_t}
                close={props.close}
            />
            <StyledIframe src={props.url} />
        </Background>
    );
};

export default ShareModal;
