import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../store/store";
import { GlobalModalView } from "../../helpers/Modal";

interface SubjectsState {
    modalView: GlobalModalView;
    data: any;
}

const initialState: SubjectsState = {
    modalView: GlobalModalView.None,
    data: null,
};

export const modalSlice = createSlice({
    name: "modal",
    initialState,

    reducers: {
        setModalView: (state, action: PayloadAction<GlobalModalView>) => {
            state.modalView = action.payload;
        },
        setData: (state, action: PayloadAction<any>) => {
            state.data = action.payload;
        },
    },
});

/* EXPORTS */
export const { actions, reducer } = modalSlice;

export const selectors = {
    modalView: (state: RootState) => state.modal.modalView,
    data: (state: RootState) => state.modal.data,
};
