// REACT
import React, { FunctionComponent, useContext, useState, useCallback } from "react";

// COMPONENTS
import Popup from "../../../basic/popup/Popup";
import PhaseSixIcon from "../../../basic/phaseSixIcon/PhaseSixIcon";

// UTILS
import { LanguageCodes, LanguagesContext } from "../../../../context/languages";

//TYPES
import { SubjectData } from "p6m-subjects";

// STYLED COMPONENTS
import { FlagImage, FlagsContainer, FlagItem, FlagImageWrapper, Arrow } from "./styles";

type Props = {
    subject?: SubjectData;
    language?: string;
    withArrow?: boolean;
    position?: any;
    onChange?: (langKey: string) => void;
};

const LanguageSelect: FunctionComponent<Props> = ({
    subject,
    language = "others",
    onChange,
    withArrow = false,
    position = ["bottom", "center"],
}) => {
    const isPublisherContent = !!subject?.subjectContent.inAppId.length;

    const { languageTranslations, getFlagSource } = useContext(LanguagesContext);
    const [popupOpen, setPopupOpen] = useState(false);

    const onToggle = useCallback((isOpen: boolean) => {
        setPopupOpen(isOpen);
    }, []);

    const contentAsCallback = useCallback(
        ({ closePopup }) => {
            return (
                <FlagsContainer>
                    {Object.entries(languageTranslations).map(([languageCode, languageNameTranslation]) => {
                        return (
                            <FlagItem
                                key={languageCode}
                                onClick={() => {
                                    if (!!onChange) onChange(languageCode);
                                    closePopup();
                                }}
                            >
                                <span>{languageNameTranslation}</span>
                                <img
                                    src={getFlagSource(languageCode as LanguageCodes)}
                                    alt=""
                                />
                            </FlagItem>
                        );
                    })}
                </FlagsContainer>
            );
        },
        [languageTranslations, onChange]
    );

    return (
        <Popup
            autoPosition
            position={position}
            content={contentAsCallback}
            minChildWidth
            arrow
            onToggle={onToggle}
            isDisabled={isPublisherContent}
        >
            <FlagImageWrapper $isPublisherContent={isPublisherContent}>
                <FlagImage src={getFlagSource(language as LanguageCodes)} />
                {withArrow && (
                    <Arrow>
                        <PhaseSixIcon name={popupOpen ? "chevron-up" : "chevron-down"} />
                    </Arrow>
                )}
            </FlagImageWrapper>
        </Popup>
    );
};

export default LanguageSelect;
