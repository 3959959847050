// LIBRARIES
import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useT } from "@transifex/react";

import { ampli } from "../../../ampli";

//REDUX
import { selectors as userSelectors, actions as userActions } from "../../../redux/user/userSlice";
import { selectors as avatarsSelectors, actions as avatarsActions } from "../../../redux/avatars/avatarsSlice";
import { actions as modalActions } from "../../../redux/modal/modalSlice";
import { actions as responseActions } from "../../../redux/response/responseSlice";

//NETWORK
import { deactivateReactUserRole } from "../../../networking/user";

// COMPONENTS
import UserMenu from "./UserMenu";

//UTILS
import { ResponseType } from "../../../constants/ResponseConstants";
import { GlobalModalView } from "../../../helpers/Modal";
import { goToOldAppVersion } from "../../../helpers/User";

export interface UserMenuProps {
    isMobileView?: boolean;
    closeMobileMenu?: () => any;
}

const UserMenuView: React.FC<UserMenuProps> = (props) => {
    const user = useSelector(userSelectors.user);
    const userPremiumExpiration = useSelector(userSelectors.userPremiumExpiration);
    const userHasPremium = useSelector(userSelectors.userHasPremium);
    const avatars = useSelector(avatarsSelectors.getAvatars);
    const userAvatarId = useSelector(userSelectors.getUserAvatarId);
    const userAvatar = useSelector(avatarsSelectors.getAvatarById(userAvatarId));
    const isUserStrict = useSelector(userSelectors.isStrict);
    const hasBetaInvite = useSelector(userSelectors.isBetaInvited);
    const dispatch = useDispatch();
    const t = useT();

    const { isMobileView, closeMobileMenu } = props;

    const t_versionSwitchFailed = t("Failed switching to previous version", { _tags: "Usermenu,alert,message" });
    const t_versionSwitchFailed_InvalidUser = t("Your user was not recognized. Please login again and retry!", {
        _tags: "Usermenu,alert,message",
    });
    const t_versionSwitchFailed_NoNetwork = t("This function is not available right now. Please try again later!", {
        _tags: "Usermenu,alert,message",
    });

    const userMenuProps = {
        user,
        userHasPremium,
        userPremiumExpiration,
        isMobileView,
        closeMobileMenu,
    };

    useEffect(() => {
        if (avatars !== false) return;
        dispatch(avatarsActions.fetchAvatars());
    }, [avatars, dispatch]);

    const setModalView = (view: GlobalModalView) => {
        dispatch(modalActions.setModalView(view));
        if (isMobileView && closeMobileMenu) closeMobileMenu();
    };

    const showResponse = (message: string, type: ResponseType) => {
        dispatch(responseActions.showResponse({ type: type, text: [message] }));
    };

    const switchVersion = async () => {
        const userId = user.userDnsId;
        const jossoSessionId = user.jossoSessionId;
        try {
            if (userId && jossoSessionId) {
                const response = await deactivateReactUserRole();
                if (response.data.replyContent === "Success") {
                    ampli.clickedSwitchVersion();
                    goToOldAppVersion(userId, jossoSessionId);
                } else {
                    showResponse(t_versionSwitchFailed, "ERROR");
                }
            } else {
                showResponse(t_versionSwitchFailed_InvalidUser, "ERROR");
            }
        } catch (e) {
            if (e.message === "Network Error") {
                showResponse(t_versionSwitchFailed_NoNetwork, "WARNING");
            } else {
                showResponse(t_versionSwitchFailed, "ERROR");
            }
        }
    };

    const userAvatarLink = useMemo(() => {
        if (!userAvatar) return;

        return userAvatar.link;
    }, [userAvatar]);

    return (
        <UserMenu
            {...userMenuProps}
            logoutAction={() => dispatch(userActions.logout())}
            setModalView={setModalView}
            userAvatar={userAvatarLink}
            isUserStrict={isUserStrict}
            hasBetaInvite={hasBetaInvite}
            switchVersion={switchVersion}
        />
    );
};

UserMenuView.defaultProps = {
    isMobileView: false,
};

export default UserMenuView;
