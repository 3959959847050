// REACT
import React from "react";

// STYLED COMPONENTS
import { HighlightedLabel, Label, UserData } from "./styles";

export interface leaderBoardUserItemProps {
    lineOne: string;
    lineTwo: string;
    lineThree: string;
    lineFour: string;
    highlighted?: boolean;
}

const LeaderBoardUserStatistic: React.FC<leaderBoardUserItemProps> = (props) => {
    return (
        <UserData>
            {props.highlighted ? (
                <>
                    <HighlightedLabel>{props.lineOne}</HighlightedLabel>
                    <HighlightedLabel>{props.lineTwo}</HighlightedLabel>
                    <HighlightedLabel>{props.lineThree}</HighlightedLabel>
                    <HighlightedLabel>{props.lineFour}</HighlightedLabel>
                </>
            ) : (
                <>
                    <Label>{props.lineOne}</Label>
                    <Label>{props.lineTwo}</Label>
                    <Label>{props.lineThree}</Label>
                    <Label>{props.lineFour}</Label>
                </>
            )}
        </UserData>
    );
};

export default LeaderBoardUserStatistic;
