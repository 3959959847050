import { useCallback } from "react";

//HOOKS
import { useDropdownEditResponsePopup } from "./useDropdownEditResponsePopup";

//TYPES
import { DROPDOWN_CONTENT_TYPES, DROPDOWN_EDIT_TYPES, EDIT_RESPONSE_TYPES } from "./useDropdownEditResponsePopup";

export const useMultiCreations = () => {
    const showPopUpMessage = useDropdownEditResponsePopup();
    const handleMultiCreations = useCallback(
        async (itemsToCreate: Promise<{ httpCode?: number; name: string }>[], contentType: DROPDOWN_CONTENT_TYPES) => {
            if (itemsToCreate?.length > 0) {
                try {
                    const itemsCreatedResponse = await Promise.all(itemsToCreate);
                    const failedCreations: string[] =
                        itemsCreatedResponse?.filter((item) => item?.httpCode !== 200).map((item) => item?.name) || [];
                    if (failedCreations.length) {
                        showPopUpMessage(
                            contentType,
                            DROPDOWN_EDIT_TYPES.CREATION,
                            EDIT_RESPONSE_TYPES.ERROR,
                            failedCreations.join(", ")
                        );
                    } else {
                        showPopUpMessage(
                            contentType,
                            DROPDOWN_EDIT_TYPES.CREATION,
                            EDIT_RESPONSE_TYPES.SUCCESS,
                            itemsCreatedResponse.map((item) => item.name).join(", ")
                        );
                    }
                } catch (e) {
                    console.log(e);
                }
            }
        },
        [showPopUpMessage]
    );

    return handleMultiCreations;
};
