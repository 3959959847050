// REACT
import React, { FunctionComponent, useState, useEffect, useCallback } from "react";

// REDUX
import { useSelector, useDispatch } from "react-redux";
import { selectors as userSelectors } from "../../../../../redux/user/userSlice";
import { actions } from "../../../../../redux/learning/learningSlice";

// TYPES
import { Props as ModalProps } from "../../../../basic/modal/Modal";

// COMPONENTS
import Component, { Props as ComponentProps } from "./ExitFirstPractice";

type Props = Partial<ModalProps>;

export const ExitFirstPractice: FunctionComponent<Props> = (props) => {
    const { afterClose: afterCloseFromProps, ...restProps } = props;
    const jossoId = useSelector(userSelectors.jossoId) || "";
    const [permission, setPermission] = useState<ComponentProps["permission"]>("unsupported");

    const dispatch = useDispatch();

    useEffect(() => {
        if (!("Notification" in window)) {
            setPermission("unsupported");
            return;
        }
        setPermission(Notification.permission);
    }, []);

    const askPermission = useCallback(() => {
        Notification?.requestPermission().then((newPermission) => {
            setPermission(newPermission);
        });
    }, []);

    const afterClose = useCallback(
        function () {
            dispatch(actions.setShowExitFirstPracticeModal());
            afterCloseFromProps?.();
        },
        [afterCloseFromProps, dispatch]
    );

    return (
        <Component
            jossoId={jossoId}
            permission={permission}
            askPermission={askPermission}
            afterClose={afterClose}
            {...restProps}
        />
    );
};
