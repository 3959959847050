//LIBRARIES
import React from "react";
import styled from "styled-components";
import { T, useT } from "@transifex/react";

//COMPONENTS
import CheckboxInput from "../../basic/checkboxInput/CheckboxInput";
import { DefaultHeader } from "../../basic/modal/DefaultHeader";

//TYPES
import { GlobalModalProps } from "../../../helpers/Modal";

//STYLES
import { FilledButton, OutlinedButton } from "../../basic/button/Button";

interface ConfirmEditModalProps extends GlobalModalProps {
    save: () => void;
    dontShowWarning: boolean;
    setDontShowWarning: (dontShow: boolean) => void;
}

const Background = styled.div`
    height: 100%;
    width: 100%;
    padding: 0;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    overflow: hidden;
`;
const Content = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: ${(props) => props.theme.base.spacing};
    background: ${(props) => props.theme.colors.backgroundMain};
`;
const ButtonsContainer = styled.div`
    width: 100%;
    padding: ${(props) => props.theme.base.spacing};
    background: ${(props) => props.theme.colors.backgroundMain};
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    gap: ${(props) => props.theme.base.spacing};
`;

const Description = styled.p`
    color: ${(props) => props.theme.colors.text};
`;

const ConfirmEditModal: React.FC<ConfirmEditModalProps> = (props) => {
    const t = useT();

    const assign_t = t("Save changes", { _tags: "subject,modal,title" });
    const dontShowAgain_t = t("Don't show this message again", { _tags: "subject,modal,title" });
    const cancel_t = t("Cancel", { _tags: "subject,modal,button" });
    const saveContent_t = t("Save", { _tags: "subject,modal,button" });

    return (
        <Background>
            <DefaultHeader
                title={assign_t}
                close={props.close}
            />
            <Content>
                <Description>
                    <T _str="Please note that previous entries for this card will be deleted from the report 'Difficult cards'." />
                </Description>
                <CheckboxInput
                    label={dontShowAgain_t}
                    checked={props.dontShowWarning}
                    onChange={(checked) => props.setDontShowWarning(checked)}
                />
            </Content>
            <ButtonsContainer>
                <OutlinedButton
                    onClick={props.close}
                    useVerticalPadding
                    upperCase
                    textSize="xl"
                    useModalZIndex
                >
                    {cancel_t}
                </OutlinedButton>
                <FilledButton
                    onClick={props.save}
                    useVerticalPadding
                    upperCase
                    textSize="xl"
                    useModalZIndex
                >
                    {saveContent_t}
                </FilledButton>
            </ButtonsContainer>
        </Background>
    );
};

export default ConfirmEditModal;
