import React, { FunctionComponent, useState } from "react";
import { useSelector } from "react-redux";
import { selectors } from "../../../../redux/user/userSlice";
import Component from "./SurveyItem";

export const SurveyItem: FunctionComponent = () => {
    const [showModal, setModal] = useState(false);
    const { userDnsId } = useSelector(selectors.user);

    const toggleModal = () => setModal(!showModal);

    return (
        <Component
            link={`https://www.phase-6.de/pxp/p6m/survey/?UserDnsID=${userDnsId}&OS=Web&Version=${process.env.REACT_APP_VERSION}`}
            toggleModal={toggleModal}
            showModal={showModal}
        />
    );
};
