/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
/**
 * Ampli - A strong typed wrapper for your Analytics
 *
 * This file is generated by Amplitude.
 * To update run 'ampli pull p6webapp'
 *
 * Required dependencies: @amplitude/analytics-browser@^1.3.0
 * Tracking Plan Version: 71
 * Build: 1.0.0
 * Runtime: browser:typescript-ampli-v2
 *
 * [View Tracking Plan](https://data.eu.amplitude.com/phase-6-gmbh/phase6/events/main/latest)
 *
 * [Full Setup Instructions](https://data.eu.amplitude.com/phase-6-gmbh/phase6/implementation/p6webapp)
 */

import * as amplitude from '@amplitude/analytics-browser';

export type Environment = 'production' | 'development';

export const ApiKey: Record<Environment, string> = {
  production: '48e96f0130ed92ac0ecbfb0934073867',
  development: 'c987ae22fd6027b50a5f8afec8cca94d'
};

/**
 * Default Amplitude configuration options. Contains tracking plan information.
 */
export const DefaultConfiguration: BrowserOptions = {
  plan: {
    version: '71',
    branch: 'main',
    source: 'p6webapp',
    versionId: 'be1b1543-bb33-4bbb-bb17-5fcad2bef436'
  },
  ...{
    ingestionMetadata: {
      sourceName: 'browser-typescript-ampli',
      sourceVersion: '2.0.0'
    }
  },
  serverZone: amplitude.Types.ServerZone.EU
};

export interface LoadOptionsBase { disabled?: boolean }

export type LoadOptionsWithEnvironment = LoadOptionsBase & { environment: Environment; client?: { configuration?: BrowserOptions; }; };
export type LoadOptionsWithApiKey = LoadOptionsBase & { client: { apiKey: string; configuration?: BrowserOptions; } };
export type LoadOptionsWithClientInstance = LoadOptionsBase & { client: { instance: BrowserClient; } };

export type LoadOptions = LoadOptionsWithEnvironment | LoadOptionsWithApiKey | LoadOptionsWithClientInstance;

export interface IdentifyProperties {
  "[adjust] adgroup"?: any;
  "[adjust] app_version"?: any;
  "[adjust] campaign"?: any;
  "[adjust] city"?: any;
  "[adjust] country"?: any;
  "[adjust] creative"?: any;
  "[adjust] impression_based"?: any;
  "[adjust] installed_at"?: any;
  "[adjust] is_organic"?: any;
  "[adjust] network"?: any;
  "[adjust] reattributed_at"?: any;
  "[adjust] search_term"?: any;
  "[adjust] tracking_enabled"?: any;
  "[adjust] tracking_limited"?: any;
  ab_achievements_reword_test?: any;
  ab_anonymous?: any;
  ab_anonymous_accaunt?: any;
  ab_anonymous_account?: any;
  ab_banner_first_practice?: any;
  ab_firs_practice_banner_test?: any;
  ab_new_eop_scren?: any;
  ab_orange_default_theme?: any;
  ab_reminder_with_buttons?: any;
  ab_share_stat_eop?: any;
  ab_show_intro_screen?: any;
  abtest_ios_survey_from_where?: any;
  achievements_hidden?: any;
  /**
   * Role from user in our DNS
   */
  allRoles?: any;
  allUserRoles?: any;
  /**
   * Tracks language of the app UI. Mobile apps have only German or English language based on default device setting. Web-App has also Spanish and Polish.
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | english, spanish, polish, german |
   */
  app_language?: "english" | "spanish" | "polish" | "german";
  assesment_test_2?: any;
  assessment_enabled?: any;
  assessment_enabled_v2?: any;
  assessment_enabled_v3?: any;
  avatar?: any;
  basket_additional_info?: any;
  /**
   * Indicates whether user accepts browser notifications
   */
  browserNotification?: any;
  collapsable_selection?: any;
  Dashboard?: any;
  experimentIds?: any;
  favorite_schools?: any;
  first_practice_parent_5_cards?: any;
  game_test?: any;
  gclid?: any;
  grouped_settings?: any;
  hasClass?: any;
  hasFamily?: any;
  hasNewsletter?: any;
  hide_tooltips?: any;
  home_school?: any;
  initial_gclid?: any;
  initial_referrer?: any;
  initial_referring_domain?: any;
  initial_utm_campaign?: any;
  initial_utm_content?: any;
  initial_utm_medium?: any;
  initial_utm_source?: any;
  initial_utm_term?: any;
  isInternalUser?: any;
  learn_reminder_set?: any;
  "learning goal"?: any;
  more_cards_end_of_practice?: any;
  notification_permission_status?: any;
  practise_more_count?: any;
  premim_additional_info?: any;
  "push notification enabled"?: any;
  PXProle?: any;
  referrer?: any;
  referring_domain?: any;
  role?: any;
  schoolId?: any;
  schoolID?: any;
  settings_notification_enabled?: any;
  settings_notifications_enabled?: any;
  shop_cover_style?: any;
  skin?: any;
  /**
   * Survey shown to every new user after registration
   */
  survey_from_where_1?: any;
  /**
   * Survey shown to every new user after registration
   */
  survey_from_where_2?: any;
  utm_campaign?: any;
  utm_content?: any;
  utm_medium?: any;
  utm_source?: any;
  utm_term?: any;
  Version?: any;
}

export interface AcceptedExerciseProperties {
  /**
   * A property that should be added to each event to show which product or app the event was sent from. This way we can easily distinguish if the event came from a different product (e.g. vocabulary\_*trainer vs. grammartutor) or from a different tech stack (open*\_cms marketing pages vs. wordpress help pages).
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | grammar_tutor, open_cms_marketing_pages, vocabulary_trainer_react_web, vocabulary_trainer_mobile_kmp |
   */
  app_source?:
    | "grammar_tutor"
    | "open_cms_marketing_pages"
    | "vocabulary_trainer_react_web"
    | "vocabulary_trainer_mobile_kmp";
  /**
   * Hashed unique ID of an exercise/test created or accepted by user through sharing. ID is unique per exercise per user.
   */
  exercise_id?: any;
  /**
   * Is set if exercise was shared by a teacher or created by a parent. Is named test_origin for historical reasons (since exercises are essentially tests).
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | teacher, parent |
   */
  test_origin?: "teacher" | "parent";
}

export interface ActivationSummaryPage34Properties {
  /**
   * A property that should be added to each event to show which product or app the event was sent from. This way we can easily distinguish if the event came from a different product (e.g. vocabulary\_*trainer vs. grammartutor) or from a different tech stack (open*\_cms marketing pages vs. wordpress help pages).
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | grammar_tutor, open_cms_marketing_pages, vocabulary_trainer_react_web, vocabulary_trainer_mobile_kmp |
   */
  app_source?:
    | "grammar_tutor"
    | "open_cms_marketing_pages"
    | "vocabulary_trainer_react_web"
    | "vocabulary_trainer_mobile_kmp";
}

export interface ActivationUnitsPage14Properties {
  /**
   * A property that should be added to each event to show which product or app the event was sent from. This way we can easily distinguish if the event came from a different product (e.g. vocabulary\_*trainer vs. grammartutor) or from a different tech stack (open*\_cms marketing pages vs. wordpress help pages).
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | grammar_tutor, open_cms_marketing_pages, vocabulary_trainer_react_web, vocabulary_trainer_mobile_kmp |
   */
  app_source?:
    | "grammar_tutor"
    | "open_cms_marketing_pages"
    | "vocabulary_trainer_react_web"
    | "vocabulary_trainer_mobile_kmp";
}

export interface AppliedFilterProperties {
  /**
   * A property that should be added to each event to show which product or app the event was sent from. This way we can easily distinguish if the event came from a different product (e.g. vocabulary\_*trainer vs. grammartutor) or from a different tech stack (open*\_cms marketing pages vs. wordpress help pages).
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | grammar_tutor, open_cms_marketing_pages, vocabulary_trainer_react_web, vocabulary_trainer_mobile_kmp |
   */
  app_source?:
    | "grammar_tutor"
    | "open_cms_marketing_pages"
    | "vocabulary_trainer_react_web"
    | "vocabulary_trainer_mobile_kmp";
  /**
   * Library filter categories
   *
   * | Rule | Value |
   * |---|---|
   * | Unique Items | true |
   * | Item Type | string |
   */
  filter_categories: (
    | "activation_phase"
    | "phase_1"
    | "phase_2"
    | "phase_3"
    | "phase_4"
    | "phase_5"
    | "phase_6"
    | "phase_7"
    | "phase_8"
    | "phase_9"
    | "phase_10"
    | "long_term_memory"
    | "cards_learned_today"
    | "difficult_cards"
  )[];
}

export interface CancelPracticePxpProperties {
  /**
   * Modes: manual, match, mixed, auto.
   *
   * 1. Manual: chose to activate cards with "zuerst einprägen"
   *
   * 2. Match: chose to activate cards per drag and drop
   *
   * 3. Mixed: chose to activate cards in a mixed way
   *
   * 4. Auto: chose to skip activating cards
   *
   * 5. None: did not activate new cards
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | manual, match, mixed, auto, none |
   */
  activation_mode?: "manual" | "match" | "mixed" | "auto" | "none";
  /**
   * A property that should be added to each event to show which product or app the event was sent from. This way we can easily distinguish if the event came from a different product (e.g. vocabulary\_*trainer vs. grammartutor) or from a different tech stack (open*\_cms marketing pages vs. wordpress help pages).
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | grammar_tutor, open_cms_marketing_pages, vocabulary_trainer_react_web, vocabulary_trainer_mobile_kmp |
   */
  app_source?:
    | "grammar_tutor"
    | "open_cms_marketing_pages"
    | "vocabulary_trainer_react_web"
    | "vocabulary_trainer_mobile_kmp";
  /**
   * Indicates whether content is for adults (such contents have assessment mode on mobiles) or students (no assessment mode, activation instead)
   */
  book_has_accessment_mode: boolean;
  /**
   * Number of cards user selected for practice session.
   *
   *
   * If user selects both learning directions, the amount of cards selected for practice is doubled. E.g. user selects 5 cards during activation, then select 'practice both directions' -> cards_for_practice will be set to 10.
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  cards_for_practice?: number;
  /**
   * Number of cards user learned during practice session
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  cards_learned?: number;
  /**
   * Hashed unique ID of an exercise/test created or accepted by user through sharing. ID is unique per exercise per user.
   */
  exercise_id?: any;
  /**
   * Indicates whether user decided to activate/assess cards first (true) or skip activation (false). This property should be replaced by activation_mode and removed once old WebApp is deprecated.
   */
  memorize_first?: boolean;
  /**
   * Indicates whether user is doing a regular practice (regular_practice), preparing for a test (test_only) or started a test and moved it to regular practice (test_moved_to_practice). For the case of exercises shared by teachers we have extra values to differentiate those from tests.
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | regular_practice, test_moved_to_practice, test_only, exercise_moved_to_practice, exercise_only |
   */
  mode?: "regular_practice" | "test_moved_to_practice" | "test_only" | "exercise_moved_to_practice" | "exercise_only";
  /**
   * Is set if exercise was shared by a teacher or created by a parent. Is named test_origin for historical reasons (since exercises are essentially tests).
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | teacher, parent |
   */
  test_origin?: "teacher" | "parent";
}

export interface ClickCreateSubjectAProperties {
  /**
   * Universal property that indicates the screen on which user action took place
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | practice, input, dictionary, library, learncenter, input_select_subject |
   */
  app_screen: "practice" | "input" | "dictionary" | "library" | "learncenter" | "input_select_subject";
  /**
   * A property that should be added to each event to show which product or app the event was sent from. This way we can easily distinguish if the event came from a different product (e.g. vocabulary\_*trainer vs. grammartutor) or from a different tech stack (open*\_cms marketing pages vs. wordpress help pages).
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | grammar_tutor, open_cms_marketing_pages, vocabulary_trainer_react_web, vocabulary_trainer_mobile_kmp |
   */
  app_source?:
    | "grammar_tutor"
    | "open_cms_marketing_pages"
    | "vocabulary_trainer_react_web"
    | "vocabulary_trainer_mobile_kmp";
}

export interface ClickedAddCardFromDictionaryProperties {
  /**
   * A property that should be added to each event to show which product or app the event was sent from. This way we can easily distinguish if the event came from a different product (e.g. vocabulary\_*trainer vs. grammartutor) or from a different tech stack (open*\_cms marketing pages vs. wordpress help pages).
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | grammar_tutor, open_cms_marketing_pages, vocabulary_trainer_react_web, vocabulary_trainer_mobile_kmp |
   */
  app_source?:
    | "grammar_tutor"
    | "open_cms_marketing_pages"
    | "vocabulary_trainer_react_web"
    | "vocabulary_trainer_mobile_kmp";
  /**
   * Indicates language direction in which dictionary search was performed
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | de-fr, de-en, de-es, de-la, de-it |
   */
  dictionary_language: "de-fr" | "de-en" | "de-es" | "de-la" | "de-it";
}

export interface ClickedWebAppToGrammarLinkProperties {
  /**
   * Passes meta information on where a button click occured.
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | learncenter_web, learncenter_mobile, grammar_trainer_popup_mobile, grammar_trainer_popup_web, menu_mobile |
   */
  click_source:
    | "learncenter_web"
    | "learncenter_mobile"
    | "grammar_trainer_popup_mobile"
    | "grammar_trainer_popup_web"
    | "menu_mobile";
}

export interface ClickedEditCardsButtonProperties {
  /**
   * A property that should be added to each event to show which product or app the event was sent from. This way we can easily distinguish if the event came from a different product (e.g. vocabulary\_*trainer vs. grammartutor) or from a different tech stack (open*\_cms marketing pages vs. wordpress help pages).
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | grammar_tutor, open_cms_marketing_pages, vocabulary_trainer_react_web, vocabulary_trainer_mobile_kmp |
   */
  app_source?:
    | "grammar_tutor"
    | "open_cms_marketing_pages"
    | "vocabulary_trainer_react_web"
    | "vocabulary_trainer_mobile_kmp";
  /**
   * Number of cards edited with user action: 1 or more (integer)
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 1 |
   */
  cards_number: number;
  /**
   * Performed action on edit cards screen. To be removed from basket screen action event and replaced with basket_action.
   *
   * * resetPhase: reset card to the first phase
   *
   * * moveToLongTerm: moved card to long-term memory (last phase)
   *
   * * changeDirection: changed card learning direction
   *
   * * deactivateCards: set card activation status to inactive
   *
   * * reactiveCards: reactivated card
   *
   * * deleteCard: deleted card
   *
   * * editCard: clicked to edit card and saved it
   *
   * * changeUnit: moved card to a different unit
   *
   * * changeSubject: moved card to a different subject
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | resetPhase, changeDirection, changeUnit, moveToLongTerm, deactivateCards, reactiveCards, changeSubject, deleteCard, editCard |
   */
  user_action:
    | "resetPhase"
    | "changeDirection"
    | "changeUnit"
    | "moveToLongTerm"
    | "deactivateCards"
    | "reactiveCards"
    | "changeSubject"
    | "deleteCard"
    | "editCard";
}

export interface ClickedNotificationProperties {
  emailProcessName?: any;
}

export interface CreateCardAProperties {
  /**
   * Universal property that indicates the screen on which user action took place
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | practice, input, dictionary, library, learncenter, input_select_subject |
   */
  app_screen: "practice" | "input" | "dictionary" | "library" | "learncenter" | "input_select_subject";
  /**
   * A property that should be added to each event to show which product or app the event was sent from. This way we can easily distinguish if the event came from a different product (e.g. vocabulary\_*trainer vs. grammartutor) or from a different tech stack (open*\_cms marketing pages vs. wordpress help pages).
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | grammar_tutor, open_cms_marketing_pages, vocabulary_trainer_react_web, vocabulary_trainer_mobile_kmp |
   */
  app_source?:
    | "grammar_tutor"
    | "open_cms_marketing_pages"
    | "vocabulary_trainer_react_web"
    | "vocabulary_trainer_mobile_kmp";
  is_publisher_content: boolean;
}

export interface DictionaryNormalSuccessAProperties {
  /**
   * Universal property that indicates the screen on which user action took place
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | practice, input, dictionary, library, learncenter, input_select_subject |
   */
  app_screen: "practice" | "input" | "dictionary" | "library" | "learncenter" | "input_select_subject";
  /**
   * A property that should be added to each event to show which product or app the event was sent from. This way we can easily distinguish if the event came from a different product (e.g. vocabulary\_*trainer vs. grammartutor) or from a different tech stack (open*\_cms marketing pages vs. wordpress help pages).
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | grammar_tutor, open_cms_marketing_pages, vocabulary_trainer_react_web, vocabulary_trainer_mobile_kmp |
   */
  app_source?:
    | "grammar_tutor"
    | "open_cms_marketing_pages"
    | "vocabulary_trainer_react_web"
    | "vocabulary_trainer_mobile_kmp";
  /**
   * Indicates language direction in which dictionary search was performed
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | de-fr, de-en, de-es, de-la, de-it |
   */
  dictionary_language: "de-fr" | "de-en" | "de-es" | "de-la" | "de-it";
}

export interface GamePlayedPxpProperties {
  /**
   * A property that should be added to each event to show which product or app the event was sent from. This way we can easily distinguish if the event came from a different product (e.g. vocabulary\_*trainer vs. grammartutor) or from a different tech stack (open*\_cms marketing pages vs. wordpress help pages).
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | grammar_tutor, open_cms_marketing_pages, vocabulary_trainer_react_web, vocabulary_trainer_mobile_kmp |
   */
  app_source?:
    | "grammar_tutor"
    | "open_cms_marketing_pages"
    | "vocabulary_trainer_react_web"
    | "vocabulary_trainer_mobile_kmp";
  /**
   * Indicates game type: 2048, match (WordMatch), or true_false (Richtig-Falsch)
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | 2048, match, true_false |
   */
  game?: "2048" | "match" | "true_false";
  /**
   * Tracks which mode was used when playig the word match game: normal mode where only activated cards are used and 'explore' mode where all cards of the learning content are used as part of the game.
   *
   *
   *
   *
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | explore, normal |
   */
  match_game_mode?: "explore" | "normal";
}

export interface MediaUploadedProperties {
  /**
   * A property that should be added to each event to show which product or app the event was sent from. This way we can easily distinguish if the event came from a different product (e.g. vocabulary\_*trainer vs. grammartutor) or from a different tech stack (open*\_cms marketing pages vs. wordpress help pages).
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | grammar_tutor, open_cms_marketing_pages, vocabulary_trainer_react_web, vocabulary_trainer_mobile_kmp |
   */
  app_source?:
    | "grammar_tutor"
    | "open_cms_marketing_pages"
    | "vocabulary_trainer_react_web"
    | "vocabulary_trainer_mobile_kmp";
  /**
   * Type of attachment uploaded: audio file, audio recording or image file
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | audio, recording, image_uploaded, image_camera |
   */
  attachment_type: "audio" | "recording" | "image_uploaded" | "image_camera";
}

export interface NoteAddedProperties {
  /**
   * Universal property that indicates the screen on which user action took place
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | practice, input, dictionary, library, learncenter, input_select_subject |
   */
  app_screen: "practice" | "input" | "dictionary" | "library" | "learncenter" | "input_select_subject";
  /**
   * A property that should be added to each event to show which product or app the event was sent from. This way we can easily distinguish if the event came from a different product (e.g. vocabulary\_*trainer vs. grammartutor) or from a different tech stack (open*\_cms marketing pages vs. wordpress help pages).
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | grammar_tutor, open_cms_marketing_pages, vocabulary_trainer_react_web, vocabulary_trainer_mobile_kmp |
   */
  app_source?:
    | "grammar_tutor"
    | "open_cms_marketing_pages"
    | "vocabulary_trainer_react_web"
    | "vocabulary_trainer_mobile_kmp";
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | question, answer |
   */
  location: "question" | "answer";
}

export interface OpenLeaderboardProperties {
  /**
   * A property that should be added to each event to show which product or app the event was sent from. This way we can easily distinguish if the event came from a different product (e.g. vocabulary\_*trainer vs. grammartutor) or from a different tech stack (open*\_cms marketing pages vs. wordpress help pages).
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | grammar_tutor, open_cms_marketing_pages, vocabulary_trainer_react_web, vocabulary_trainer_mobile_kmp |
   */
  app_source?:
    | "grammar_tutor"
    | "open_cms_marketing_pages"
    | "vocabulary_trainer_react_web"
    | "vocabulary_trainer_mobile_kmp";
}

export interface OpenReportsTabProperties {
  /**
   * Specifies opened reports tab
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | phases, activity, 7day_preview, difficult_cards, input_control |
   */
  reports_tab: "phases" | "activity" | "7day_preview" | "difficult_cards" | "input_control";
}

export interface OpenedExerciseProperties {
  /**
   * A property that should be added to each event to show which product or app the event was sent from. This way we can easily distinguish if the event came from a different product (e.g. vocabulary\_*trainer vs. grammartutor) or from a different tech stack (open*\_cms marketing pages vs. wordpress help pages).
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | grammar_tutor, open_cms_marketing_pages, vocabulary_trainer_react_web, vocabulary_trainer_mobile_kmp |
   */
  app_source?:
    | "grammar_tutor"
    | "open_cms_marketing_pages"
    | "vocabulary_trainer_react_web"
    | "vocabulary_trainer_mobile_kmp";
  /**
   * Hashed unique ID of an exercise/test created or accepted by user through sharing. ID is unique per exercise per user.
   */
  exercise_id?: any;
  /**
   * Is set if exercise was shared by a teacher or created by a parent. Is named test_origin for historical reasons (since exercises are essentially tests).
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | teacher, parent |
   */
  test_origin?: "teacher" | "parent";
}

export interface OpenedAchievementsProperties {
  /**
   * A property that should be added to each event to show which product or app the event was sent from. This way we can easily distinguish if the event came from a different product (e.g. vocabulary\_*trainer vs. grammartutor) or from a different tech stack (open*\_cms marketing pages vs. wordpress help pages).
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | grammar_tutor, open_cms_marketing_pages, vocabulary_trainer_react_web, vocabulary_trainer_mobile_kmp |
   */
  app_source?:
    | "grammar_tutor"
    | "open_cms_marketing_pages"
    | "vocabulary_trainer_react_web"
    | "vocabulary_trainer_mobile_kmp";
}

export interface PracticeFinishedPxpProperties {
  /**
   * Modes: manual, match, mixed, auto.
   *
   * 1. Manual: chose to activate cards with "zuerst einprägen"
   *
   * 2. Match: chose to activate cards per drag and drop
   *
   * 3. Mixed: chose to activate cards in a mixed way
   *
   * 4. Auto: chose to skip activating cards
   *
   * 5. None: did not activate new cards
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | manual, match, mixed, auto, none |
   */
  activation_mode?: "manual" | "match" | "mixed" | "auto" | "none";
  /**
   * A property that should be added to each event to show which product or app the event was sent from. This way we can easily distinguish if the event came from a different product (e.g. vocabulary\_*trainer vs. grammartutor) or from a different tech stack (open*\_cms marketing pages vs. wordpress help pages).
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | grammar_tutor, open_cms_marketing_pages, vocabulary_trainer_react_web, vocabulary_trainer_mobile_kmp |
   */
  app_source?:
    | "grammar_tutor"
    | "open_cms_marketing_pages"
    | "vocabulary_trainer_react_web"
    | "vocabulary_trainer_mobile_kmp";
  /**
   * Indicates whether content is for adults (such contents have assessment mode on mobiles) or students (no assessment mode, activation instead)
   */
  book_has_accessment_mode: boolean;
  /**
   * Number of cards user selected for practice session.
   *
   *
   * If user selects both learning directions, the amount of cards selected for practice is doubled. E.g. user selects 5 cards during activation, then select 'practice both directions' -> cards_for_practice will be set to 10.
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  cards_for_practice?: number;
  /**
   * Number of cards user learned during practice session
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  cards_learned?: number;
  /**
   * Hashed unique ID of an exercise/test created or accepted by user through sharing. ID is unique per exercise per user.
   */
  exercise_id?: any;
  /**
   * Indicates whether user decided to activate/assess cards first (true) or skip activation (false). This property should be replaced by activation_mode and removed once old WebApp is deprecated.
   */
  memorize_first?: boolean;
  /**
   * Indicates whether user is doing a regular practice (regular_practice), preparing for a test (test_only) or started a test and moved it to regular practice (test_moved_to_practice). For the case of exercises shared by teachers we have extra values to differentiate those from tests.
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | regular_practice, test_moved_to_practice, test_only, exercise_moved_to_practice, exercise_only |
   */
  mode?: "regular_practice" | "test_moved_to_practice" | "test_only" | "exercise_moved_to_practice" | "exercise_only";
  /**
   * Which revision of a test or grammar exercise is the current practice.
   *
   *
   * E.g. if I already have practiced my test twice and I click 'practice now', then revision_no would be 3.
   *
   *
   *
   *
   *
   * If I've completely finished my grammar topic once before and I repeat the first exercise in the first exercise set, then revision_no would be 2.
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  revision_no?: number;
  /**
   * Is set if exercise was shared by a teacher or created by a parent. Is named test_origin for historical reasons (since exercises are essentially tests).
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | teacher, parent |
   */
  test_origin?: "teacher" | "parent";
}

export interface PracticeStartedPxpProperties {
  /**
   * Modes: manual, match, mixed, auto.
   *
   * 1. Manual: chose to activate cards with "zuerst einprägen"
   *
   * 2. Match: chose to activate cards per drag and drop
   *
   * 3. Mixed: chose to activate cards in a mixed way
   *
   * 4. Auto: chose to skip activating cards
   *
   * 5. None: did not activate new cards
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | manual, match, mixed, auto, none |
   */
  activation_mode?: "manual" | "match" | "mixed" | "auto" | "none";
  /**
   * A property that should be added to each event to show which product or app the event was sent from. This way we can easily distinguish if the event came from a different product (e.g. vocabulary\_*trainer vs. grammartutor) or from a different tech stack (open*\_cms marketing pages vs. wordpress help pages).
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | grammar_tutor, open_cms_marketing_pages, vocabulary_trainer_react_web, vocabulary_trainer_mobile_kmp |
   */
  app_source?:
    | "grammar_tutor"
    | "open_cms_marketing_pages"
    | "vocabulary_trainer_react_web"
    | "vocabulary_trainer_mobile_kmp";
  /**
   * Indicates whether content is for adults (such contents have assessment mode on mobiles) or students (no assessment mode, activation instead)
   */
  book_has_accessment_mode: boolean;
  /**
   * Number of cards user selected for practice session.
   *
   *
   * If user selects both learning directions, the amount of cards selected for practice is doubled. E.g. user selects 5 cards during activation, then select 'practice both directions' -> cards_for_practice will be set to 10.
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  cards_for_practice?: number;
  /**
   * Hashed unique ID of an exercise/test created or accepted by user through sharing. ID is unique per exercise per user.
   */
  exercise_id?: any;
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | both, source_to_target, target_to_source |
   */
  learning_direction?: "both" | "source_to_target" | "target_to_source";
  /**
   * Indicates whether user is doing a regular practice (regular_practice), preparing for a test (test_only) or started a test and moved it to regular practice (test_moved_to_practice). For the case of exercises shared by teachers we have extra values to differentiate those from tests.
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | regular_practice, test_moved_to_practice, test_only, exercise_moved_to_practice, exercise_only |
   */
  mode?: "regular_practice" | "test_moved_to_practice" | "test_only" | "exercise_moved_to_practice" | "exercise_only";
  /**
   * Which revision of a test or grammar exercise is the current practice.
   *
   *
   * E.g. if I already have practiced my test twice and I click 'practice now', then revision_no would be 3.
   *
   *
   *
   *
   *
   * If I've completely finished my grammar topic once before and I repeat the first exercise in the first exercise set, then revision_no would be 2.
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  revision_no?: number;
  /**
   * Is set if exercise was shared by a teacher or created by a parent. Is named test_origin for historical reasons (since exercises are essentially tests).
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | teacher, parent |
   */
  test_origin?: "teacher" | "parent";
}

export interface RejectedExerciseProperties {
  /**
   * A property that should be added to each event to show which product or app the event was sent from. This way we can easily distinguish if the event came from a different product (e.g. vocabulary\_*trainer vs. grammartutor) or from a different tech stack (open*\_cms marketing pages vs. wordpress help pages).
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | grammar_tutor, open_cms_marketing_pages, vocabulary_trainer_react_web, vocabulary_trainer_mobile_kmp |
   */
  app_source?:
    | "grammar_tutor"
    | "open_cms_marketing_pages"
    | "vocabulary_trainer_react_web"
    | "vocabulary_trainer_mobile_kmp";
  /**
   * Hashed unique ID of an exercise/test created or accepted by user through sharing. ID is unique per exercise per user.
   */
  exercise_id?: any;
  /**
   * Is set if exercise was shared by a teacher or created by a parent. Is named test_origin for historical reasons (since exercises are essentially tests).
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | teacher, parent |
   */
  test_origin?: "teacher" | "parent";
}

export interface RemovesFavoriteSchoolProperties {
  /**
   * Indicates school ID. Seen on Android
   */
  school_id?: any;
}

export interface SelectedAvatarProperties {
  /**
   * A property that should be added to each event to show which product or app the event was sent from. This way we can easily distinguish if the event came from a different product (e.g. vocabulary\_*trainer vs. grammartutor) or from a different tech stack (open*\_cms marketing pages vs. wordpress help pages).
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | grammar_tutor, open_cms_marketing_pages, vocabulary_trainer_react_web, vocabulary_trainer_mobile_kmp |
   */
  app_source?:
    | "grammar_tutor"
    | "open_cms_marketing_pages"
    | "vocabulary_trainer_react_web"
    | "vocabulary_trainer_mobile_kmp";
  avatar_id?: any;
}

export interface SelectedFavoriteSchoolProperties {
  /**
   * Indicates school ID. Seen on Android
   */
  school_id?: any;
}

export interface SelectedHomeSchoolProperties {
  /**
   * Indicates school ID. Seen on Android
   */
  school_id?: any;
  /**
   * Indicates school ID. Seen on iOS, needs to be removed and replaced with school_id
   */
  schoolID?: any;
}

export interface SelectedSkinProperties {
  /**
   * A property that should be added to each event to show which product or app the event was sent from. This way we can easily distinguish if the event came from a different product (e.g. vocabulary\_*trainer vs. grammartutor) or from a different tech stack (open*\_cms marketing pages vs. wordpress help pages).
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | grammar_tutor, open_cms_marketing_pages, vocabulary_trainer_react_web, vocabulary_trainer_mobile_kmp |
   */
  app_source?:
    | "grammar_tutor"
    | "open_cms_marketing_pages"
    | "vocabulary_trainer_react_web"
    | "vocabulary_trainer_mobile_kmp";
  /**
   * Skin selected by the user:
   *
   * * grayTheme = Grumpy Grey (currently sent for Dark Mode on Android by error)
   *
   * * blueTheme = Blended Blue
   *
   * * pinkTheme = Perfect Pink
   *
   * * orangeTheme = Original Orange
   *
   * * darkMode = Dark Mode
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | grayTheme, blueTheme, pinkTheme, orangeTheme, darkMode |
   */
  skin?: "grayTheme" | "blueTheme" | "pinkTheme" | "orangeTheme" | "darkMode";
}

export interface ShareAppProperties {
  /**
   * Universal property that indicates the screen on which user action took place
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | practice, input, dictionary, library, learncenter, input_select_subject |
   */
  app_screen: "practice" | "input" | "dictionary" | "library" | "learncenter" | "input_select_subject";
  /**
   * A property that should be added to each event to show which product or app the event was sent from. This way we can easily distinguish if the event came from a different product (e.g. vocabulary\_*trainer vs. grammartutor) or from a different tech stack (open*\_cms marketing pages vs. wordpress help pages).
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | grammar_tutor, open_cms_marketing_pages, vocabulary_trainer_react_web, vocabulary_trainer_mobile_kmp |
   */
  app_source?:
    | "grammar_tutor"
    | "open_cms_marketing_pages"
    | "vocabulary_trainer_react_web"
    | "vocabulary_trainer_mobile_kmp";
  from_screen?: any;
}

export interface ShownWarningProperties {
  /**
   * Reasons for triggering a warning:
   *
   * * too_many_cards: 200 cards learned in less than 10 minutes within the same session (formerly 100_cards)
   *
   * * iwr_no_typing: 50 times i-was-right in a row in normal learning mode (formerly 20_iwr)
   *
   * * iwr_with_typing: 50 times i-was-right in a row in typing mode (formerly 10_iwr)
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | too_many_cards, iwr_no_typing, iwr_with_typing |
   */
  warning_type?: "too_many_cards" | "iwr_no_typing" | "iwr_with_typing";
}

export class Identify implements BaseEvent {
  event_type = amplitude.Types.SpecialEventType.IDENTIFY;

  constructor(
    public event_properties?: IdentifyProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class AcceptedExercise implements BaseEvent {
  event_type = 'accepted exercise';

  constructor(
    public event_properties?: AcceptedExerciseProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ActivationSummaryPage34 implements BaseEvent {
  event_type = 'Activation Summary Page 3/4';

  constructor(
    public event_properties?: ActivationSummaryPage34Properties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ActivationUnitsPage14 implements BaseEvent {
  event_type = 'Activation Units Page 1/4';

  constructor(
    public event_properties?: ActivationUnitsPage14Properties,
  ) {
    this.event_properties = event_properties;
  }
}

export class AppliedFilter implements BaseEvent {
  event_type = 'applied_filter';

  constructor(
    public event_properties: AppliedFilterProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class BlockedLeaderboard implements BaseEvent {
  event_type = 'blocked_leaderboard';
}

export class CancelPracticePxp implements BaseEvent {
  event_type = 'cancel_practice_PXP';

  constructor(
    public event_properties: CancelPracticePxpProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ClickCreateSubjectA implements BaseEvent {
  event_type = 'click_create_subject_A';

  constructor(
    public event_properties: ClickCreateSubjectAProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ClickDictionaryA implements BaseEvent {
  event_type = 'click_dictionary_A';
}

export class ClickedAddCardFromDictionary implements BaseEvent {
  event_type = 'clicked add card from dictionary';

  constructor(
    public event_properties: ClickedAddCardFromDictionaryProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ClickedWebAppToGrammarLink implements BaseEvent {
  event_type = 'Clicked web-app to grammar link';

  constructor(
    public event_properties: ClickedWebAppToGrammarLinkProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ClickedEditCardsButton implements BaseEvent {
  event_type = 'clicked_edit_cards_button';

  constructor(
    public event_properties: ClickedEditCardsButtonProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ClickedNotification implements BaseEvent {
  event_type = 'clicked_notification';

  constructor(
    public event_properties?: ClickedNotificationProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ClickedSchulmeisterschaft implements BaseEvent {
  event_type = 'clicked_schulmeisterschaft';
}

export class ClickedSwitchVersion implements BaseEvent {
  event_type = 'clicked_switch_version';
}

export class CreateCardA implements BaseEvent {
  event_type = 'create_card_A';

  constructor(
    public event_properties: CreateCardAProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class DictionaryNormalSuccessA implements BaseEvent {
  event_type = 'dictionary_normal_success_A';

  constructor(
    public event_properties: DictionaryNormalSuccessAProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class FirstLoginToPxp implements BaseEvent {
  event_type = 'First login to PXP';
}

export class GamePlayedPxp implements BaseEvent {
  event_type = 'game_played_PXP';

  constructor(
    public event_properties?: GamePlayedPxpProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class MediaUploaded implements BaseEvent {
  event_type = 'media_uploaded';

  constructor(
    public event_properties: MediaUploadedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class NoteAdded implements BaseEvent {
  event_type = 'note_added';

  constructor(
    public event_properties: NoteAddedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class OpenLeaderboard implements BaseEvent {
  event_type = 'open_leaderboard';

  constructor(
    public event_properties?: OpenLeaderboardProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class OpenReportsGeneralA implements BaseEvent {
  event_type = 'open_reports_general_A';
}

export class OpenReportsTab implements BaseEvent {
  event_type = 'open_reports_tab';

  constructor(
    public event_properties: OpenReportsTabProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class OpenedExercise implements BaseEvent {
  event_type = 'opened exercise';

  constructor(
    public event_properties?: OpenedExerciseProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class OpenedAchievements implements BaseEvent {
  event_type = 'opened_achievements';

  constructor(
    public event_properties?: OpenedAchievementsProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class PracticeFinishedPxp implements BaseEvent {
  event_type = 'practice_finished_PXP';

  constructor(
    public event_properties: PracticeFinishedPxpProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class PracticeStartedPxp implements BaseEvent {
  event_type = 'practice_started_PXP';

  constructor(
    public event_properties: PracticeStartedPxpProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class RejectedExercise implements BaseEvent {
  event_type = 'rejected exercise';

  constructor(
    public event_properties?: RejectedExerciseProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class RemovesFavoriteSchool implements BaseEvent {
  event_type = 'removes_favorite_school';

  constructor(
    public event_properties?: RemovesFavoriteSchoolProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SelectedAvatar implements BaseEvent {
  event_type = 'selected_avatar';

  constructor(
    public event_properties?: SelectedAvatarProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SelectedFavoriteSchool implements BaseEvent {
  event_type = 'selected_favorite_school';

  constructor(
    public event_properties?: SelectedFavoriteSchoolProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SelectedHomeSchool implements BaseEvent {
  event_type = 'selected_home_school';

  constructor(
    public event_properties?: SelectedHomeSchoolProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SelectedSkin implements BaseEvent {
  event_type = 'selected_skin';

  constructor(
    public event_properties?: SelectedSkinProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ShareApp implements BaseEvent {
  event_type = 'share_app';

  constructor(
    public event_properties: ShareAppProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ShownWarning implements BaseEvent {
  event_type = 'shown_warning';

  constructor(
    public event_properties?: ShownWarningProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ViewedNotificationBar implements BaseEvent {
  event_type = 'viewed_notification_bar';
}

export type PromiseResult<T> = { promise: Promise<T | void> };

const getVoidPromiseResult = () => ({ promise: Promise.resolve() });

// prettier-ignore
export class Ampli {
  private disabled: boolean = false;
  private amplitude?: BrowserClient;

  get client(): BrowserClient {
    this.isInitializedAndEnabled();
    return this.amplitude!;
  }

  get isLoaded(): boolean {
    return this.amplitude != null;
  }

  private isInitializedAndEnabled(): boolean {
    if (!this.amplitude) {
      console.error('ERROR: Ampli is not yet initialized. Have you called ampli.load() on app start?');
      return false;
    }
    return !this.disabled;
  }

  /**
   * Initialize the Ampli SDK. Call once when your application starts.
   *
   * @param options Configuration options to initialize the Ampli SDK with.
   */
  load(options: LoadOptions): PromiseResult<void> {
    this.disabled = options.disabled ?? false;

    if (this.amplitude) {
      console.warn('WARNING: Ampli is already intialized. Ampli.load() should be called once at application startup.');
      return getVoidPromiseResult();
    }

    let apiKey: string | null = null;
    if (options.client && 'apiKey' in options.client) {
      apiKey = options.client.apiKey;
    } else if ('environment' in options) {
      apiKey = ApiKey[options.environment];
    }

    if (options.client && 'instance' in options.client) {
      this.amplitude = options.client.instance;
    } else if (apiKey) {
      this.amplitude = amplitude.createInstance();
      const configuration = (options.client && 'configuration' in options.client) ? options.client.configuration : {};
      return this.amplitude.init(apiKey, undefined, { ...DefaultConfiguration, ...configuration });
    } else {
      console.error("ERROR: ampli.load() requires 'environment', 'client.apiKey', or 'client.instance'");
    }

    return getVoidPromiseResult();
  }

  /**
   * Identify a user and set user properties.
   *
   * @param userId The user's id.
   * @param properties The user properties.
   * @param options Optional event options.
   */
  identify(
    userId: string | undefined,
    properties?: IdentifyProperties,
    options?: EventOptions,
  ): PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    if (userId) {
      options = {...options,  user_id: userId};
    }

    const amplitudeIdentify = new amplitude.Identify();
    const eventProperties = properties;
    if (eventProperties != null) {
      for (const [key, value] of Object.entries(eventProperties)) {
        amplitudeIdentify.set(key, value);
      }
    }
    return this.amplitude!.identify(
      amplitudeIdentify,
      options,
    );
  }

 /**
  * Flush the event.
  */
  flush() : PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    return this.amplitude!.flush();
  }

  /**
   * Track event
   *
   * @param event The event to track.
   * @param options Optional event options.
   */
  track(event: Event, options?: EventOptions): PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    return this.amplitude!.track(event, undefined, options);
  }

  /**
   * accepted exercise
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/phase-6-gmbh/phase6/events/main/latest/accepted%20exercise)
   *
   * Is fired when a user clicks 'learn now' after opening an exercise link.
   *
   *
   *
   *
   *
   * @param properties The event's properties (e.g. app_source)
   * @param options Amplitude event options.
   */
  acceptedExercise(
    properties?: AcceptedExerciseProperties,
    options?: EventOptions,
  ) {
    return this.track(new AcceptedExercise(properties), options);
  }

  /**
   * Activation Summary Page 3/4
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/phase-6-gmbh/phase6/events/main/latest/Activation%20Summary%20Page%203%2F4)
   *
   * After selecting cards for practice, user views a summary screen with multiple options for activation.
   *
   *
   *
   *
   * Owner: Pavel K.
   *
   * @param properties The event's properties (e.g. app_source)
   * @param options Amplitude event options.
   */
  activationSummaryPage34(
    properties?: ActivationSummaryPage34Properties,
    options?: EventOptions,
  ) {
    return this.track(new ActivationSummaryPage34(properties), options);
  }

  /**
   * Activation Units Page 1/4
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/phase-6-gmbh/phase6/events/main/latest/Activation%20Units%20Page%201%2F4)
   *
   * When user selects cards for practice in a book, show unit screen of a selected book. New WebApp: screen also contains cards selection  in a toggle selection (step 2).
   *
   *
   *
   *
   * Owner: Pavel K.
   *
   * @param properties The event's properties (e.g. app_source)
   * @param options Amplitude event options.
   */
  activationUnitsPage14(
    properties?: ActivationUnitsPage14Properties,
    options?: EventOptions,
  ) {
    return this.track(new ActivationUnitsPage14(properties), options);
  }

  /**
   * applied_filter
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/phase-6-gmbh/phase6/events/main/latest/applied_filter)
   *
   * Event to track when a filter is applied by a user in the library. Is fired when user clicks 'Show cards' to apply a filter selection:
   *
   *
   *
   *
   *
   * @param properties The event's properties (e.g. app_source)
   * @param options Amplitude event options.
   */
  appliedFilter(
    properties: AppliedFilterProperties,
    options?: EventOptions,
  ) {
    return this.track(new AppliedFilter(properties), options);
  }

  /**
   * blocked_leaderboard
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/phase-6-gmbh/phase6/events/main/latest/blocked_leaderboard)
   *
   * After receiving a certain number of warnings, no more points are reported to leaderboards.
   *
   * Owner: Pavel K.
   *
   * @param options Amplitude event options.
   */
  blockedLeaderboard(
    options?: EventOptions,
  ) {
    return this.track(new BlockedLeaderboard(), options);
  }

  /**
   * cancel_practice_PXP
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/phase-6-gmbh/phase6/events/main/latest/cancel_practice_PXP)
   *
   * Canceled ongoing practice before finish (also sent if canceled with 0 cards learned).
   *
   * Owner: Pavel K.
   *
   * @param properties The event's properties (e.g. activation_mode)
   * @param options Amplitude event options.
   */
  cancelPracticePxp(
    properties: CancelPracticePxpProperties,
    options?: EventOptions,
  ) {
    return this.track(new CancelPracticePxp(properties), options);
  }

  /**
   * click_create_subject_A
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/phase-6-gmbh/phase6/events/main/latest/click_create_subject_A)
   *
   * Web-App: clicked to create a subject (app_screen = library or input). Note that libary screen is blocked until user completes first practice. https://phase6.atlassian.net/browse/PFE-1189 Android: Fired with "clicked add card", because they occur simultaneously. Not fired after user has FPF, apparently.
   *
   *
   *
   *
   * Owner: Pavel K.
   *
   * @param properties The event's properties (e.g. app_screen)
   * @param options Amplitude event options.
   */
  clickCreateSubjectA(
    properties: ClickCreateSubjectAProperties,
    options?: EventOptions,
  ) {
    return this.track(new ClickCreateSubjectA(properties), options);
  }

  /**
   * click_dictionary_A
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/phase-6-gmbh/phase6/events/main/latest/click_dictionary_A)
   *
   * Clicked to open dictionary from side menu
   *
   *
   *
   *
   * Owner: Pavel K.
   *
   * @param options Amplitude event options.
   */
  clickDictionaryA(
    options?: EventOptions,
  ) {
    return this.track(new ClickDictionaryA(), options);
  }

  /**
   * clicked add card from dictionary
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/phase-6-gmbh/phase6/events/main/latest/clicked%20add%20card%20from%20dictionary)
   *
   *
   *
   *
   *
   * This event tracks when a user clicks the "add to practice" button within the dictionary.
   *
   *
   *
   *
   *
   * @param properties The event's properties (e.g. app_source)
   * @param options Amplitude event options.
   */
  clickedAddCardFromDictionary(
    properties: ClickedAddCardFromDictionaryProperties,
    options?: EventOptions,
  ) {
    return this.track(new ClickedAddCardFromDictionary(properties), options);
  }

  /**
   * Clicked web-app to grammar link
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/phase-6-gmbh/phase6/events/main/latest/Clicked%20web-app%20to%20grammar%20link)
   *
   * Fired when when the grammar tutor is opened from within the vocabulary trainer.
   *
   * @param properties The event's properties (e.g. click_source)
   * @param options Amplitude event options.
   */
  clickedWebAppToGrammarLink(
    properties: ClickedWebAppToGrammarLinkProperties,
    options?: EventOptions,
  ) {
    return this.track(new ClickedWebAppToGrammarLink(properties), options);
  }

  /**
   * clicked_edit_cards_button
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/phase-6-gmbh/phase6/events/main/latest/clicked_edit_cards_button)
   *
   * User edited settings of a card. All actions on this screen are tracked inside the corresponding property. Opening this screen is not tracked.
   *
   *
   *
   *
   * Owner: Pavel K.
   *
   * @param properties The event's properties (e.g. app_source)
   * @param options Amplitude event options.
   */
  clickedEditCardsButton(
    properties: ClickedEditCardsButtonProperties,
    options?: EventOptions,
  ) {
    return this.track(new ClickedEditCardsButton(properties), options);
  }

  /**
   * clicked_notification
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/phase-6-gmbh/phase6/events/main/latest/clicked_notification)
   *
   * User clicked on a link inside an individual notification
   *
   * @param properties The event's properties (e.g. emailProcessName)
   * @param options Amplitude event options.
   */
  clickedNotification(
    properties?: ClickedNotificationProperties,
    options?: EventOptions,
  ) {
    return this.track(new ClickedNotification(properties), options);
  }

  /**
   * clicked_schulmeisterschaft
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/phase-6-gmbh/phase6/events/main/latest/clicked_schulmeisterschaft)
   *
   * Clicked "Schulmeisterschaft" button on a schools tab in leaderboards
   *
   * Owner: Pavel K.
   *
   * @param options Amplitude event options.
   */
  clickedSchulmeisterschaft(
    options?: EventOptions,
  ) {
    return this.track(new ClickedSchulmeisterschaft(), options);
  }

  /**
   * clicked_switch_version
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/phase-6-gmbh/phase6/events/main/latest/clicked_switch_version)
   *
   * Clicked on the temporary 'switch version' option in the drop-down.
   *
   * Owner: Pavel K.
   *
   * @param options Amplitude event options.
   */
  clickedSwitchVersion(
    options?: EventOptions,
  ) {
    return this.track(new ClickedSwitchVersion(), options);
  }

  /**
   * create_card_A
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/phase-6-gmbh/phase6/events/main/latest/create_card_A)
   *
   * Added a word, its translation and clicked to save a card ("Kärtchen speichern"). For Web-App, property app_screen
   *
   * indicates the place in which the card was created: manually through **input** or automatically through **dictionary**. Property is_publisher_content indicates whether the card is created in user's own content or publisher content. https://phase6.atlassian.net/browse/PFE-990
   *
   *
   *
   *
   * Owner: Pavel K.
   *
   * @param properties The event's properties (e.g. app_screen)
   * @param options Amplitude event options.
   */
  createCardA(
    properties: CreateCardAProperties,
    options?: EventOptions,
  ) {
    return this.track(new CreateCardA(properties), options);
  }

  /**
   * dictionary_normal_success_A
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/phase-6-gmbh/phase6/events/main/latest/dictionary_normal_success_A)
   *
   * Opened an article about a word searched in a dictionary. Screen property tracks the screen, from which dictionary was started: learncenter or dictionary tab:
   *
   *
   *
   *
   * For the dictionary functionality during input for PLUS users in the new mobile app, the event is triggered when the user clicks on one of the suggestions that automatically appear in the grey box:
   *
   *
   *
   *
   *
   * In general, this event is only triggered for PLUS users, as free users don't have access to the dictionary.
   *
   * Owner: Pavel K.
   *
   * @param properties The event's properties (e.g. app_screen)
   * @param options Amplitude event options.
   */
  dictionaryNormalSuccessA(
    properties: DictionaryNormalSuccessAProperties,
    options?: EventOptions,
  ) {
    return this.track(new DictionaryNormalSuccessA(properties), options);
  }

  /**
   * First login to PXP
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/phase-6-gmbh/phase6/events/main/latest/First%20login%20to%20PXP)
   *
   * First time user logs into Web-App.
   *
   * Owner: Pavel K.
   *
   * @param options Amplitude event options.
   */
  firstLoginToPxp(
    options?: EventOptions,
  ) {
    return this.track(new FirstLoginToPxp(), options);
  }

  /**
   * game_played_PXP
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/phase-6-gmbh/phase6/events/main/latest/game_played_PXP)
   *
   * Started playing a game, see the game type in property
   *
   * Owner: Pavel K.
   *
   * @param properties The event's properties (e.g. app_source)
   * @param options Amplitude event options.
   */
  gamePlayedPxp(
    properties?: GamePlayedPxpProperties,
    options?: EventOptions,
  ) {
    return this.track(new GamePlayedPxp(properties), options);
  }

  /**
   * media_uploaded
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/phase-6-gmbh/phase6/events/main/latest/media_uploaded)
   *
   * PLUS feature. When creating a card, user clicked on image/microphone/sound icon and started uploading a picture or recording.
   *
   * Owner: Pavel K.
   *
   * @param properties The event's properties (e.g. app_source)
   * @param options Amplitude event options.
   */
  mediaUploaded(
    properties: MediaUploadedProperties,
    options?: EventOptions,
  ) {
    return this.track(new MediaUploaded(properties), options);
  }

  /**
   * note_added
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/phase-6-gmbh/phase6/events/main/latest/note_added)
   *
   * User added a note during regular practice or input phase (app_screen = practice or input). https://phase6.atlassian.net/browse/PFE-990
   *
   * Owner: Pavel K.
   *
   * @param properties The event's properties (e.g. app_screen)
   * @param options Amplitude event options.
   */
  noteAdded(
    properties: NoteAddedProperties,
    options?: EventOptions,
  ) {
    return this.track(new NoteAdded(properties), options);
  }

  /**
   * open_leaderboard
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/phase-6-gmbh/phase6/events/main/latest/open_leaderboard)
   *
   * Clicked to open leaderboards through a star icon in learncenter or using side menu
   *
   *
   *
   *
   * Owner: Pavel K.
   *
   * @param properties The event's properties (e.g. app_source)
   * @param options Amplitude event options.
   */
  openLeaderboard(
    properties?: OpenLeaderboardProperties,
    options?: EventOptions,
  ) {
    return this.track(new OpenLeaderboard(properties), options);
  }

  /**
   * open_reports_general_A
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/phase-6-gmbh/phase6/events/main/latest/open_reports_general_A)
   *
   * Opened reports screen.
   *
   *
   *
   * Owner: Pavel K.
   *
   * @param options Amplitude event options.
   */
  openReportsGeneralA(
    options?: EventOptions,
  ) {
    return this.track(new OpenReportsGeneralA(), options);
  }

  /**
   * open_reports_tab
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/phase-6-gmbh/phase6/events/main/latest/open_reports_tab)
   *
   * User opened a specific tab on the reports screen. By default the reports screen shows phases tab when opened
   *
   *
   *
   *
   * Owner: Pavel K.
   *
   * @param properties The event's properties (e.g. reports_tab)
   * @param options Amplitude event options.
   */
  openReportsTab(
    properties: OpenReportsTabProperties,
    options?: EventOptions,
  ) {
    return this.track(new OpenReportsTab(properties), options);
  }

  /**
   * opened exercise
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/phase-6-gmbh/phase6/events/main/latest/opened%20exercise)
   *
   * Is fired when a user opens an exercise link.
   *
   *
   *
   *
   *
   * @param properties The event's properties (e.g. app_source)
   * @param options Amplitude event options.
   */
  openedExercise(
    properties?: OpenedExerciseProperties,
    options?: EventOptions,
  ) {
    return this.track(new OpenedExercise(properties), options);
  }

  /**
   * opened_achievements
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/phase-6-gmbh/phase6/events/main/latest/opened_achievements)
   *
   * Opened a screen with achievements (Erfolge) - by clicking on the Pokal-button in learncenter.
   *
   *
   *
   * Owner: Pavel K.
   *
   * @param properties The event's properties (e.g. app_source)
   * @param options Amplitude event options.
   */
  openedAchievements(
    properties?: OpenedAchievementsProperties,
    options?: EventOptions,
  ) {
    return this.track(new OpenedAchievements(properties), options);
  }

  /**
   * practice_finished_PXP
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/phase-6-gmbh/phase6/events/main/latest/practice_finished_PXP)
   *
   * Finished practice successfully (sent when end of practice screen is opened).
   *
   *
   *
   *
   * Owner: Pavel K.
   *
   * @param properties The event's properties (e.g. activation_mode)
   * @param options Amplitude event options.
   */
  practiceFinishedPxp(
    properties: PracticeFinishedPxpProperties,
    options?: EventOptions,
  ) {
    return this.track(new PracticeFinishedPxp(properties), options);
  }

  /**
   * practice_started_PXP
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/phase-6-gmbh/phase6/events/main/latest/practice_started_PXP)
   *
   * Sent when user sees first card in a practice session. Currently also sent when user startes activation - in that case event is not sent second time after activation changes into practice.
   *
   * Owner: Pavel K.
   *
   * @param properties The event's properties (e.g. activation_mode)
   * @param options Amplitude event options.
   */
  practiceStartedPxp(
    properties: PracticeStartedPxpProperties,
    options?: EventOptions,
  ) {
    return this.track(new PracticeStartedPxp(properties), options);
  }

  /**
   * rejected exercise
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/phase-6-gmbh/phase6/events/main/latest/rejected%20exercise)
   *
   * Is fired when a user clicks 'later' after opening an exercise link.
   *
   *
   *
   *
   *
   * @param properties The event's properties (e.g. app_source)
   * @param options Amplitude event options.
   */
  rejectedExercise(
    properties?: RejectedExerciseProperties,
    options?: EventOptions,
  ) {
    return this.track(new RejectedExercise(properties), options);
  }

  /**
   * removes_favorite_school
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/phase-6-gmbh/phase6/events/main/latest/removes_favorite_school)
   *
   * Clicked on saved favorite school's "star" again to remove it from favorites.
   *
   * Owner: Pavel K.
   *
   * @param properties The event's properties (e.g. school_id)
   * @param options Amplitude event options.
   */
  removesFavoriteSchool(
    properties?: RemovesFavoriteSchoolProperties,
    options?: EventOptions,
  ) {
    return this.track(new RemovesFavoriteSchool(properties), options);
  }

  /**
   * selected_avatar
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/phase-6-gmbh/phase6/events/main/latest/selected_avatar)
   *
   * Selected new avatar in settings.
   *
   * Owner: Pavel K.
   *
   * @param properties The event's properties (e.g. app_source)
   * @param options Amplitude event options.
   */
  selectedAvatar(
    properties?: SelectedAvatarProperties,
    options?: EventOptions,
  ) {
    return this.track(new SelectedAvatar(properties), options);
  }

  /**
   * selected_favorite_school
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/phase-6-gmbh/phase6/events/main/latest/selected_favorite_school)
   *
   * Searched for a school in the star-tab after clicking on "Favoriten auswählen", clicked on a star next to a needed school.
   *
   *
   *
   * Owner: Pavel K.
   *
   * @param properties The event's properties (e.g. school_id)
   * @param options Amplitude event options.
   */
  selectedFavoriteSchool(
    properties?: SelectedFavoriteSchoolProperties,
    options?: EventOptions,
  ) {
    return this.track(new SelectedFavoriteSchool(properties), options);
  }

  /**
   * selected_home_school
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/phase-6-gmbh/phase6/events/main/latest/selected_home_school)
   *
   * When user first navigates to leaderboards, he gets a popup to select a school. He can do that immediately and then this event will fire. Otherwise, if user skips the popup, he can later go to schools in leaderboards, click to select school in bottom, then find his school, then confirm the warning popup (see screenshot).
   *
   *
   *
   * Owner: Pavel K.
   *
   * @param properties The event's properties (e.g. school_id)
   * @param options Amplitude event options.
   */
  selectedHomeSchool(
    properties?: SelectedHomeSchoolProperties,
    options?: EventOptions,
  ) {
    return this.track(new SelectedHomeSchool(properties), options);
  }

  /**
   * selected_skin
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/phase-6-gmbh/phase6/events/main/latest/selected_skin)
   *
   * User selected a different app skin (theme) in settings
   *
   * Owner: Pavel K.
   *
   * @param properties The event's properties (e.g. app_source)
   * @param options Amplitude event options.
   */
  selectedSkin(
    properties?: SelectedSkinProperties,
    options?: EventOptions,
  ) {
    return this.track(new SelectedSkin(properties), options);
  }

  /**
   * share_app
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/phase-6-gmbh/phase6/events/main/latest/share_app)
   *
   * Clicked to share app. Property app_screen allow following values: practice (share from end of practice screen), learncenter (share from hamburger menu)
   *
   * Owner: Pavel K.
   *
   * @param properties The event's properties (e.g. app_screen)
   * @param options Amplitude event options.
   */
  shareApp(
    properties: ShareAppProperties,
    options?: EventOptions,
  ) {
    return this.track(new ShareApp(properties), options);
  }

  /**
   * shown_warning
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/phase-6-gmbh/phase6/events/main/latest/shown_warning)
   *
   * A banner is shown to the user if we suspect he is cheating.
   *
   * Owner: Pavel K.
   *
   * @param properties The event's properties (e.g. warning_type)
   * @param options Amplitude event options.
   */
  shownWarning(
    properties?: ShownWarningProperties,
    options?: EventOptions,
  ) {
    return this.track(new ShownWarning(properties), options);
  }

  /**
   * viewed_notification_bar
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/phase-6-gmbh/phase6/events/main/latest/viewed_notification_bar)
   *
   * User clicked on a notification bell in the web-app: https://phase6.atlassian.net/browse/PFE-1506
   *
   * @param options Amplitude event options.
   */
  viewedNotificationBar(
    options?: EventOptions,
  ) {
    return this.track(new ViewedNotificationBar(), options);
  }
}

export const ampli = new Ampli();

// BASE TYPES
type BrowserOptions = amplitude.Types.BrowserOptions;

export type BrowserClient = amplitude.Types.BrowserClient;
export type BaseEvent = amplitude.Types.BaseEvent;
export type IdentifyEvent = amplitude.Types.IdentifyEvent;
export type GroupEvent = amplitude.Types.GroupIdentifyEvent;
export type Event = amplitude.Types.Event;
export type EventOptions = amplitude.Types.EventOptions;
export type Result = amplitude.Types.Result;
