// REACT
import React from "react";

// LIBRARIES
import { range } from "lodash";
import styled from "styled-components";
import { useT } from "@transifex/react";

// TYPES
import { UserPreferences, ParentSettings, PracticeSettingsBooleanPreferences } from "p6m-user";

// COMPONENTS
import SettingsToggleInput, { ToggleTypes } from "../../basic/settingsToggleInput/SettingsToggleInput";
import SettingsRangeSlider from "../../basic/settingsRangeSlider/SettingsRangeSlider";
import SettingsItemWrapper from "../settingsItemWrapper/SettingsItemWrapper";

export interface SettingsPracticeProps {
    userPreferences: UserPreferences;
    parentSettings?: ParentSettings;
    updateWrongAnswerBehaviour: (enforce: boolean, retype: boolean) => any;
    updatePreferenceItem: (entry: PracticeSettingsBooleanPreferences, value?: boolean) => any;
    updateTthLimit: (value: number) => any;
}

const MainWrapper = styled.div`
    margin-top: ${(props) => props.theme.base.spacing};
`;

const SettingsPractice: React.FC<SettingsPracticeProps> = (props) => {
    const t = useT();

    const t_deactivated = t("Deactivated by your parents", {
        _tags: "practiceSettings,learnSettings",
    });

    const t_dailyGoal = t("daily_goal_label", {
        _tags: "practiceSettings,learnSettings",
    });
    const t_dailyGoalInput = t("daily_goal_name", {
        _tags: "practiceSettings,learnSettings",
    });
    const t_dailyGoalInfo = t("daily_goal_information", {
        _tags: "practiceSettings,learnSettings",
    });

    const t_learningMode = t("learning_mode_label", {
        _tags: "practiceSettings,learnSettings",
    });
    const t_learningModeInput = t("learning_mode_name", {
        _tags: "practiceSettings,learnSettings",
    });
    const t_learningModeInfo = t("learning_mode_information", {
        _tags: "practiceSettings,learnSettings",
    });

    const t_wrongInputBehaviour = t("wrong_input_label", {
        _tags: "practiceSettings,learnSettings",
    });
    const t_wrongInputBehaviourInput_1 = t("wrong_input_option1_name", {
        _tags: "practiceSettings,learnSettings",
    });
    const t_wrongInputBehaviourInput_2 = t("wrong_input_option2_name", {
        _tags: "practiceSettings,learnSettings",
    });
    const t_wrongInputBehaviourInput_3 = t("wrong_input_option3_name", {
        _tags: "practiceSettings,learnSettings",
    });
    const t_wrongInputBehaviourInfo_1 = t("wrong_input_option1_information", {
        _tags: "practiceSettings,learnSettings",
    });
    const t_wrongInputBehaviourInfo_2 = t("wrong_input_option2_information", {
        _tags: "practiceSettings,learnSettings",
    });
    const t_wrongInputBehaviourInfo_3 = t("wrong_input_option3_information", {
        _tags: "practiceSettings,learnSettings",
    });

    const t_ignoreCaseInput = t("ignore_case_name", {
        _tags: "practiceSettings,learnSettings",
    });

    const t_acceleratedPracticeInput = t("accelerated_practice_name", {
        _tags: "practiceSettings,learnSettings",
    });
    const t_acceleratedPracticeInfo = t("accelerated_practice_information", {
        _tags: "practiceSettings,learnSettings",
    });

    const t_wrongAnswerBehaviour = t("wrong_answer_action_label", {
        _tags: "practiceSettings,learnSettings",
    });
    const t_wrongAnswerBehaviourInput_1 = t("wrong_answer_action_option1_name", {
        _tags: "practiceSettings,learnSettings",
    });
    const t_wrongAnswerBehaviourInput_2 = t("wrong_answer_action_option2_name", {
        _tags: "practiceSettings,learnSettings",
    });
    const t_wrongAnswerBehaviourInfo_1 = t("wrong_answer_action_option1_information", {
        _tags: "practiceSettings,learnSettings",
    });
    const t_wrongAnswerBehaviourInfo_2 = t("wrong_answer_action_option2_information", {
        _tags: "practiceSettings,learnSettings",
    });

    const t_others = t("other_settings_label", {
        _tags: "practiceSettings,learnSettings",
    });
    const t_autoplayInput = t("autoplay_audio_name", {
        _tags: "practiceSettings,learnSettings",
    });

    const t_hintsTitle = t("Hints about learning behaviour", {
        _tags: "practiceSettings,learnSettings",
    });

    const t_hintsDescription = t(
        "We will show you hints during practice if we detect any unusual learning behavior. These hints inform you that at some point, no more points will be counted for this week for comparability purposes.",
        {
            _tags: "practiceSettings,learnSettings",
        }
    );

    const t_hintsLabel = t("Show hints", {
        _tags: "practiceSettings,learnSettings",
    });

    const { userPreferences, parentSettings, updateWrongAnswerBehaviour, updatePreferenceItem, updateTthLimit } = props;

    const {
        enforceCorrectAnswerWeb,
        retypeCorrectAnswerWeb,
        tthLimit,
        inputEnabledForPracticeWeb,
        acceleratePractice,
        ignoreCase,
        resetPhaseOnBadAnswer,
        audioPlaybackSetting,
        showLearningHints,
    } = userPreferences;

    const getDescriptionOfWrongAnswerBehaviour = (): string => {
        if (!enforceCorrectAnswerWeb && !retypeCorrectAnswerWeb) {
            return t_wrongInputBehaviourInfo_1;
        } else if (!enforceCorrectAnswerWeb && retypeCorrectAnswerWeb) {
            return t_wrongInputBehaviourInfo_2;
        } else {
            return t_wrongInputBehaviourInfo_3;
        }
    };

    return (
        <MainWrapper>
            <SettingsItemWrapper
                title={t_dailyGoal}
                description={t_dailyGoalInfo}
            >
                <SettingsRangeSlider
                    label={t_dailyGoalInput}
                    optionsValueList={range(5, 201)}
                    defaultValue={tthLimit}
                    onChange={(value) => updateTthLimit(value)}
                />
            </SettingsItemWrapper>

            <SettingsItemWrapper
                title={t_hintsTitle}
                description={t_hintsDescription}
                disabledDescription={t_deactivated}
                disabled={parentSettings?.practiceSettingsLockWeb}
            >
                <SettingsToggleInput
                    labelText={t_hintsLabel}
                    checkedValue={showLearningHints}
                    onValueChanged={(checked) => updatePreferenceItem("showLearningHints", checked)}
                />
            </SettingsItemWrapper>

            <SettingsItemWrapper
                title={t_learningMode}
                description={t_learningModeInfo}
                disabledDescription={t_deactivated}
                disabled={parentSettings?.practiceSettingsLockWeb}
            >
                <SettingsToggleInput
                    labelText={t_learningModeInput}
                    checkedValue={inputEnabledForPracticeWeb}
                    onValueChanged={(checked) => updatePreferenceItem("inputEnabledForPracticeWeb", checked)}
                />
            </SettingsItemWrapper>

            <SettingsItemWrapper
                title={t_wrongInputBehaviour}
                description={getDescriptionOfWrongAnswerBehaviour()}
                disabledDescription={t_deactivated}
                disabled={parentSettings?.practiceSettingsLockWeb}
            >
                <SettingsToggleInput
                    labelText={t_wrongInputBehaviourInput_1}
                    toggleType={ToggleTypes.CheckIcon}
                    checkedValue={!enforceCorrectAnswerWeb && !retypeCorrectAnswerWeb}
                    onValueChanged={(checked) => {
                        !!checked && updateWrongAnswerBehaviour(false, false);
                    }}
                />
                <SettingsToggleInput
                    labelText={t_wrongInputBehaviourInput_2}
                    toggleType={ToggleTypes.CheckIcon}
                    checkedValue={!enforceCorrectAnswerWeb && retypeCorrectAnswerWeb}
                    onValueChanged={(checked) => {
                        !!checked && updateWrongAnswerBehaviour(false, true);
                    }}
                />
                <SettingsToggleInput
                    labelText={t_wrongInputBehaviourInput_3}
                    toggleType={ToggleTypes.CheckIcon}
                    checkedValue={enforceCorrectAnswerWeb && retypeCorrectAnswerWeb}
                    onValueChanged={(checked) => {
                        !!checked && updateWrongAnswerBehaviour(true, true);
                    }}
                />
            </SettingsItemWrapper>

            <SettingsItemWrapper>
                <SettingsToggleInput
                    labelText={t_ignoreCaseInput}
                    checkedValue={ignoreCase}
                    onValueChanged={(checked) => updatePreferenceItem("ignoreCase", checked)}
                />
            </SettingsItemWrapper>

            <SettingsItemWrapper description={t_acceleratedPracticeInfo}>
                <SettingsToggleInput
                    labelText={t_acceleratedPracticeInput}
                    checkedValue={acceleratePractice}
                    onValueChanged={(checked) => updatePreferenceItem("acceleratePractice", checked)}
                />
            </SettingsItemWrapper>

            <SettingsItemWrapper
                title={t_wrongAnswerBehaviour}
                disabledDescription={t_deactivated}
                disabled={parentSettings?.resetPhaseOnBadAnswerLock}
                description={resetPhaseOnBadAnswer ? t_wrongAnswerBehaviourInfo_2 : t_wrongAnswerBehaviourInfo_1}
            >
                <SettingsToggleInput
                    labelText={t_wrongAnswerBehaviourInput_1}
                    checkedValue={!resetPhaseOnBadAnswer}
                    toggleType={ToggleTypes.CheckIcon}
                    onValueChanged={(checked) => {
                        !!checked && updatePreferenceItem("resetPhaseOnBadAnswer", false);
                    }}
                />
                <SettingsToggleInput
                    labelText={t_wrongAnswerBehaviourInput_2}
                    checkedValue={resetPhaseOnBadAnswer}
                    toggleType={ToggleTypes.CheckIcon}
                    onValueChanged={(checked) => {
                        !!checked && updatePreferenceItem("resetPhaseOnBadAnswer", true);
                    }}
                />
            </SettingsItemWrapper>

            <SettingsItemWrapper title={t_others}>
                <SettingsToggleInput
                    labelText={t_autoplayInput}
                    checkedValue={audioPlaybackSetting}
                    onValueChanged={(checked) => updatePreferenceItem("audioPlaybackSetting", checked)}
                />
            </SettingsItemWrapper>
        </MainWrapper>
    );
};

export default SettingsPractice;
