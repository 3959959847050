import styled from "styled-components";

export const Container = styled.div`
    background-color: ${(props) => props.theme.colors.backgroundContrast};
    position: absolute;
    bottom: 0;
    left: 15px;
    border-radius: 10px;
    -webkit-box-shadow: 3px 3px 5px 0 rgba(50, 50, 50, 0.5);
    -moz-box-shadow: 3px 3px 5px 0 rgba(50, 50, 50, 0.5);
    box-shadow: 3px 3px 5px 0 rgba(50, 50, 50, 0.5);
    margin: 15px 0;
    padding: 4px 0;
    width: calc(100% - 30px);
    overflow-y: scroll;

    ::-webkit-scrollbar {
        display: none;
    }
`;

export const Row = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`;

export const Column = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    height: 50px;

    &:last-child {
        margin-right: 17px;
    }
`;

export const ColumnWide = styled.div`
    flex: 8;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
`;

export const ColumnNarrow = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
`;

export const Block = styled.div`
    flex: 8;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
`;

export const HighlightedLabel = styled.span`
    line-height: 1.1;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: ${(props) => props.theme.colors.primary};
`;

export const HighlightedValue = styled.span<{$pointer?: boolean}>`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    color: ${(props) => props.theme.colors.primary};
    ${({$pointer = false}) => $pointer && `cursor: pointer;`}
`;

export const More = styled.div`
    display: flex;
    width: 10%;

    cursor: pointer;
`;

export const UserAvatarWrapper = styled.div`
    margin-top: ${(props) => props.theme.base.spacingInContent};
`;
