import styled from "styled-components";
import PhaseSixIcon from "../../../basic/phaseSixIcon/PhaseSixIcon";

export const Container = styled.div`
    padding: 8px 0;
    background-color: ${(props) => props.theme.colors.backgroundContrast};
    border-bottom: 2px solid ${(props) => props.theme.colors.backgroundMain};
`;

export const HoverColorIcon = styled(PhaseSixIcon)`
    &:hover {
        color: ${(props) => props.theme.colors.primary};
    }
`;

export const HoverColorAndVisibilityIcon = styled(HoverColorIcon)`
    visibility: hidden;
`;

export const Row = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    &:hover ${HoverColorAndVisibilityIcon} {
        visibility: visible;
    }
`;

export const Column = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
`;

export const ColumnWide = styled.div`
    flex: 8;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
`;

export const Block = styled.div`
    flex: 8;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
`;

export const Label = styled.span`
    line-height: 1.1;
    color: ${(props) => props.theme.colors.text};
`;

export const Value = styled.span`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    color: ${(props) => props.theme.colors.text};
`;

export const HighlightedLabel = styled.span`
    line-height: 1.1;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: ${(props) => props.theme.colors.primary};
`;

export const HighlightedValue = styled.span`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    color: ${(props) => props.theme.colors.primary};
`;

export const More = styled.div`
    display: flex;
    width: 10%;

    cursor: pointer;
`;

export const UserAvatarWrapper = styled.div`
    margin-top: ${(props) => props.theme.base.spacingSmall};
`;
