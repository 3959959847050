// REACT
import React, { useCallback } from "react";

// COMPONENTS
import SettingsChildren from "./SettingsChildren";

// TYPES
import { ParentSettings, UserPreferences } from "p6m-user";

// REDUX
import { useDispatch } from "react-redux";
import { actions as userActions } from "../../../redux/user/userSlice";

// NETWORKING
import { ChildrenSettingsParams } from "p6m-networking";

export interface SettingsChildrenViewProps {
    userId: string;
    preferences: UserPreferences;
}

const SettingsChildrenView: React.FC<SettingsChildrenViewProps> = (props) => {
    const childrenPreferences: UserPreferences = { ...props.preferences };
    //childrenPreferences is based on UserPreferences-Type, where ParentSettings is only optional.
    //in order to align with typescript conditions AND simplify code in SettingsChildren-Component, we reassign parentSettings props to new object, that technically evaluates to {} if parentSettings is undefined (however, in real life this should never happen, since ParentSettings must be set for children -> in the future we might want to extend the familyPreferences and make parentSettings for familyMembers obligatory)
    const childrenParentSettings: ParentSettings = { ...childrenPreferences.parentSettings };

    function updateParentPreferenceItem(entry: string, value?: boolean) {
        const updatedParentSettings: { [key: string]: any } = childrenParentSettings;
        updatedParentSettings[entry] = !!value;
        updateChildrenPreferences({
            ...childrenPreferences,
            parentSettings: updatedParentSettings,
            parentSettings2: updatedParentSettings,
        });
    }

    function updateWrongAnswerBehaviour(web: boolean, enforce: boolean, retype: boolean) {
        const updatedChildrenPrefs = { ...childrenPreferences };
        updatedChildrenPrefs[web ? "enforceCorrectAnswerWeb" : "enforceCorrectAnswer"] = enforce;
        updatedChildrenPrefs[web ? "retypeCorrectAnswerWeb" : "retypeCorrectAnswer"] = retype;
        updateChildrenPreferences(updatedChildrenPrefs);
    }

    function updateResetPhaseOnBadAnswer(value: boolean) {
        const updatedPreference: UserPreferences = { ...childrenPreferences };
        updatedPreference["resetPhaseOnBadAnswer"] = value;
        updateChildrenPreferences(updatedPreference);
    }

    function updateTthLimit(value: number) {
        const updatedPreference = { ...childrenPreferences };
        updatedPreference.tthLimit = value;
        updateChildrenPreferences(updatedPreference);
    }

    const dispatch = useDispatch();

    const updateChildrenPreferences = useCallback(
        async (newPreferences: UserPreferences) => {
            const childId = props.userId!;
            let request: ChildrenSettingsParams = {
                userId: childId,
                newProperties: newPreferences,
                oldProperties: { ...childrenPreferences },
            };
            dispatch(userActions.saveChildrenPreferences(request));
        },
        [childrenPreferences, dispatch, props.userId]
    );

    const childrenProps = {
        childrenPreferences,
        parentSettings: childrenParentSettings,
        updateParentPreferenceItem,
        updateResetPhaseOnBadAnswer,
        updateWrongAnswerBehaviour,
        updateTthLimit,
    };

    return <SettingsChildren {...childrenProps} />;
};

export default SettingsChildrenView;
