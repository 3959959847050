//LIBRARIES
import React, { FunctionComponent, memo } from "react";

//TYPES
import { GlobalModalProps } from "../../../helpers/Modal";

//STYLES
import { Background, StyledIframe } from "./styles";

//COMPONENTS
import { DefaultHeader } from "../../basic/modal/DefaultHeader";

interface GDPRModalProps extends GlobalModalProps {
    url: string;
}

const GDPRModal: FunctionComponent<GDPRModalProps> = (props) => {
    const { url, close } = props;

    return (
        <Background>
            <DefaultHeader
                title=""
                close={close}
            />
            <StyledIframe src={url} />
        </Background>
    );
};

export default memo(GDPRModal);
