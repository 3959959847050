import styled, { css, keyframes } from "styled-components";
import { AnimationType } from "p6m-learning";
import PhaseSixIcon from "../../../basic/phaseSixIcon/PhaseSixIcon";

export const CardsWrapper = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding: 16px 8% 32px 8%;
    overflow: hidden;
`;

export const AnimatedContent = styled.div`
    position: relative;
    z-index: 2;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;

    & + div {
        z-index: 1;
    }
`;

export const CardsAnimationWrapper = styled.div<{
    $time: number;
    $animation?: [AnimationType, AnimationType];
}>`
    position: relative;
    width: 100%;
    height: 100%;

    > ${AnimatedContent}:first-child {
        ${({ $animation, $time = 500 }) => (!$animation ? "" : getAnimationStyles($animation[0], $time))}
    }
    > ${AnimatedContent} + ${AnimatedContent} {
        ${({ $animation, $time = 500 }) => (!$animation ? "" : getAnimationStyles($animation[1], $time))}
    }
`;

function getAnimationStyles(animationType: AnimationType, time: number) {
    const currentKeyframe = keyframesGenerator(animationType);

    return css`
        position: absolute;
        animation-name: ${currentKeyframe};
        animation-duration: ${time}ms;
        animation-timing-function: ease;
        animation-fill-mode: forwards;
    `;
}

export function keyframesGenerator(animationType: AnimationType) {
    const animations = {
        slide: {
            in: [
                `
                    opacity: 0;
                    transform: translate(100%);
                `,
                `
                    opcity: 1;
                    transform: translate(0);
                `,
            ],
            out: [
                `
                    opcity: 1;
                    transform: translate(0);
                `,
                `
                    opacity: 0;
                    transform: translate(-100%);
                `,
            ],
        },
        fade: [
            `
                opacity: 0;
                transform: scale(0.5);
            `,
            `
                opacity: 1;
                transform: scale(1);
            `,
        ],
    };

    const relations: Record<AnimationType, string[]> = {
        slideIn: [animations.slide.in[0], animations.slide.in[1]],
        slideInBack: [animations.slide.in[1], animations.slide.in[0]],
        slideOut: [animations.slide.out[0], animations.slide.out[1]],
        slideOutBack: [animations.slide.out[1], animations.slide.out[0]],
        fadeIn: [animations.fade[0], animations.fade[1]],
        fadeOut: [animations.fade[1], animations.fade[0]],
    };

    const currentAnimationStr: string = relations[animationType]
        .map(
            (style: string, i: number) => `
            ${i * 100}% {
                ${style}
            }
        `
        )
        .join("\n");

    return keyframes`
        ${currentAnimationStr}
    `;
}

export const GoNextIconWrapper = styled.div`
    position: absolute;
    top: 45%;
    right: 10px;
    z-index: 2;
    font-size: ${({ theme: { base } }) => base.iconSmall};
    cursor: pointer;

    &:hover {
        opacity: ${({ theme: { base } }) => base.hoverOpacity};
    }
`;

export const GoBackIconWrapper = styled(GoNextIconWrapper)`
    right: auto;
    left: 10px;
`;

export const CardsNavigationIcon = styled(PhaseSixIcon)`
    color: ${({ theme: { colors } }) => colors.textOnBackground};
`;
