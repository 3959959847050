import styled from "styled-components";
import PhaseSixIcon from "../../components/basic/phaseSixIcon/PhaseSixIcon";

export const DictionaryPageContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding-right: ${(props) => props.theme.base.spacingSmall};
    overflow: hidden;
    max-height: 100%;
`;

export const DictionaryHeaderContainer = styled.div`
    display: flex;
    flex-direction: row;
`;

export const DictionaryContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: auto;
    margin-top: ${({theme: {base}}) => base.spacing};
`;

export const SearchContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

export const InputContainer = styled.div`
    background: ${(props) => props.theme.colors.backgroundContrast};
    display: flex;
    flex-grow: 1;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    transition: all 0.15s ease-in-out 0s;
    width: 500px;
    color: ${(props) => props.theme.colors.textLight};
    height: 50px;
    position: relative;
    box-shadow: inset 0 2px 8px ${(props) => props.theme.colors.shadow};
    padding: ${(props) => props.theme.base.spacingInContent} ${(props) => props.theme.base.spacing};
    border-radius: 25px;
`;

export const StyledInput = styled.input`
    background: none repeat scroll 0 0 rgba(0, 0, 0, 0);
    border: medium none;
    box-shadow: none;
    display: inline;
    height: auto;
    flex: 1;
    padding: 5px 5px 5px 10px;
    font-size: ${(props) => props.theme.base.bigSize};
    font-family: ${(props) => props.theme.base.fontFamily};
    color: ${(props) => props.theme.colors.text};
    transition: all 0.15s ease-in-out 0s;
    &:focus {
        outline: none;
    }
`;

export const LanguageFilter = styled.button`
    cursor: pointer;
    background: none;
    border: none;
    font-family: ${(props) => props.theme.base.fontFamily};
    font-size: ${(props) => props.theme.base.titleSize};
    color: ${(props) => props.theme.colors.textOnBackground};
    margin-left: 15px;
    width: 85px;
`;

export const FlagCombinationContainer = styled.div`
    display: flex;
    border: none;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
`;

export const FlagImage = styled.img`
    height: 24px;
    width: 36px;
    border-radius: ${(props) => props.theme.base.borderRadius};
    margin-left: 12px;
`;

export const SoundContainer = styled.div`
    display: flex;
    justify-content: center;
    font-size: 25px;
    margin-right: 20px;
    cursor: pointer;
`;

export const SoundIcon = styled(PhaseSixIcon)<{ color: string }>`
    color: ${(props) => props.color};
`;

export const PonsImageContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex: 1;
    justify-content: flex-end;
`;

export const PonsImage = styled.img`
    height: 50px;
    width: 140px;
    margin-left: ${(props) => props.theme.base.spacingSmall};
    border-radius: ${(props) => props.theme.base.borderRadiusSmall};
`;

export const SelectYourLanguageTextBox = styled.div`
    font-size: ${(props) => props.theme.base.titleSize};
    font-family: ${(props) => props.theme.base.fontFamily};
    color: ${(props) => props.theme.colors.textOnBackground};
    margin-top: ${(props) => props.theme.base.spacingBig};
    margin-bottom: ${(props) => props.theme.base.spacing};
`;

export const CloseIconContainer = styled.div`
    color: ${(props) => props.theme.colors.primary};
`;
export const WordDetailsContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

export const AddToCardsButtonContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    padding-bottom: 10px;
    padding-top: 10px;
    position: sticky;
    bottom: 0;
`;

export const AddToCardsButton = styled.button`
    display: flex;
    color: white;
    font-size: ${(props) => props.theme.base.bigSize};
    font-family: ${(props) => props.theme.base.fontFamily};
    background: ${(props) => props.theme.colors.primary};
    border-radius: ${(props) => props.theme.base.borderRadiusSmall};
    cursor: pointer;
    border: none;
    width: 200px;
    height: 35px;
    align-items: center;
    justify-content: center;
`;

export const SearchResultContainer = styled.button<{ active: boolean }>`
    padding: ${(props) => props.theme.base.spacingSmall};
    background: ${(props) =>
        props.active ? props.theme.colors.backgroundContrast : `${props.theme.colors.backgroundContrast}cc`};
    display: flex;
    border: none;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    border-radius: ${(props) => props.theme.base.borderRadiusSmall};
    margin-bottom: ${(props) => props.theme.base.spacingTiny};
    &:first-of-type {
        margin-top: ${(props) => props.theme.base.spacingInContent};
    }
    &:hover {
        background-color: ${(props) => props.theme.colors.backgroundContrast};
    }
    &:hover p {
        color: ${(props) => props.theme.colors.primary};
    }
`;
export const SearchResultImage = styled.img`
    height: 24px;
    width: 36px;
    margin-right: ${(props) => props.theme.base.spacingSmall};
    border-radius: ${(props) => props.theme.base.borderRadiusSmall};
`;
export const SearchResultText = styled.p<{ active: boolean }>`
    font-family: ${(props) => props.theme.base.fontFamily};
    color: ${(props) => (props.active ? props.theme.colors.primary : props.theme.colors.text)};
    font-size: ${(props) => props.theme.base.bigSize};
`;
export const NoResultsContainer = styled.div`
    display: flex;
    flex-direction: row;
    padding: ${(props) => props.theme.base.spacing};
    margin-top: ${(props) => props.theme.base.spacingInContent};
    border-radius: ${(props) => props.theme.base.borderRadiusSmall};
    background-color: ${(props) => props.theme.colors.backgroundContrast};
`;
export const NoResultsText = styled.p`
    font-family: ${(props) => props.theme.base.fontFamily};
    color: ${(props) => props.theme.colors.text};
    font-size: ${(props) => props.theme.base.bigSize};
    margin-left: ${(props) => props.theme.base.spacing};
    align-self: center;
`;
export const LoadingContainer = styled.div`
    position: relative;
    border-radius: ${(props) => props.theme.base.borderRadiusSmall};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 70vh;
`;
export const LoadingText = styled.p`
    font-family: ${(props) => props.theme.base.fontFamily};
    color: ${(props) => props.theme.colors.textOnBackground};
    font-size: ${(props) => props.theme.base.bigSize};
    margin-left: ${(props) => props.theme.base.spacing};
`;
