// LIBRARIES
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import { useT } from "@transifex/react/dist";

// REDUX
import { actions as userActions, selectors as userSelectors } from "../../redux/user/userSlice";
import { actions as subjectsActions, derived as subjectDerived } from "../../redux/subjects/subjectsSlice";
import { selectors as appStatusSelectors } from "../../redux/appStatus/appStatusSlice";
import { actions as responseActions } from "../../redux/response/responseSlice";

// NETWORKING
import { getSubjectsByUserId } from "../../networking/subjects";

// HELPERS
import { isDefaultSubject, sortSubjectsByName } from "../../helpers/Subjects";

// COMPONENTS
import Reports from "./Reports";
import ResponseConstants, { ResponseType } from "../../constants/ResponseConstants";

// TYPES
import { ReportScreenType } from "p6m-report";
import { SubjectData } from "p6m-subjects";
import { FamilyMember, User } from "p6m-user";

// CUSTOM HOOKS
import { useGetSearchParams } from "../../hooks/useGetSearchParams";
import { useAppVersionCheck } from "../../hooks/useAppVersionCheck";

enum UserSubjectsLoadingStatus {
    Not_Loaded,
    Loading_Initialised,
    Loaded,
}

type SubjectDataMap = { [key: string]: SubjectData };

const convertUserIntoMember = (user: User): FamilyMember => ({ userId: user.userDnsId, name: user.firstName });
const turnSubjectListIntoKeyMap = (list: SubjectData[]): SubjectDataMap => {
    return list.sort(sortSubjectsByName).reduce((subjectsById, subject) => {
        subjectsById[subject.subjectMetadata.subjectIdToOwner.id] = subject;
        return subjectsById;
    }, {} as SubjectDataMap);
};

const ReportsWrapper: React.FC = () => {
    const t = useT();
    const { userId: urlUserId } = useParams<{ userId?: string }>();
    const { subjectId: urlSubjectId } = useGetSearchParams();

    const user = useSelector(userSelectors.user);
    const isTeacher = useSelector(userSelectors.isTeacher);
    const isParent = useSelector(userSelectors.isParent);
    const hasPremium = useSelector(userSelectors.userHasPremium);
    const isStrictMode = useSelector(userSelectors.isStrict);
    const ownPreferences = useSelector(userSelectors.userPreferences);
    const userGroups = useSelector(userSelectors.userGroups);
    const familyMembers = useSelector(userSelectors.userFamilyMembers);

    const ownSubjects = useSelector(subjectDerived.nonExpiredSubjects);
    const appStatusIsLoading = useSelector(appStatusSelectors.isLoading);

    const isParentOrTeacher = isParent || isTeacher;
    const parent = isParentOrTeacher ? convertUserIntoMember(user) : undefined;
    const parentAndFamilyMembers = isParentOrTeacher && parent ? [parent, ...familyMembers] : undefined;

    const [loadingStatus, setLoadingStatus] = useState<UserSubjectsLoadingStatus>(UserSubjectsLoadingStatus.Not_Loaded);
    const [showInviteChildScreen, setShowInviteChildScreen] = useState<boolean | undefined>(
        isParentOrTeacher ? false : undefined
    );
    const [screenType, setScreenType] = useState<ReportScreenType>(urlSubjectId ? "PHASES" : "TOPICS");
    const [selectedFamilyMember, setSelectedFamilyMember] = useState<FamilyMember | undefined>(parent);
    const [familyMemberHasPremium, setFamilyMemberHasPremium] = useState<boolean | undefined>(
        isParentOrTeacher ? false : undefined
    );

    const [subjectsByIds, setSubjectsByIds] = useState<SubjectDataMap | null>(turnSubjectListIntoKeyMap(ownSubjects));
    const [selectedSubjectId, setSelectedSubjectId] = useState<string | null>(
        subjectsByIds && urlSubjectId && subjectsByIds[urlSubjectId] ? urlSubjectId : null
    );

    const t_versionSwitchFailed_NoNetwork = t("This function is not available right now. Please try again later!", {
        _tags: "Usermenu,alert,message",
    });
    const t_wrongFamilyMember = t("You requested the report of a user that is not part of your phase6 family account.");

    const dispatch = useDispatch();
    const history = useHistory();

    useAppVersionCheck();

    useEffect(() => {
        dispatch(userActions.refreshPreferencesData(user.userDnsId || ""));
    }, [dispatch, user.userDnsId]);

    useEffect(() => {
        if (appStatusIsLoading) return;
        if (!ownSubjects.length && loadingStatus === UserSubjectsLoadingStatus.Not_Loaded) {
            dispatch(subjectsActions.loadUserSubjects());
            setLoadingStatus(UserSubjectsLoadingStatus.Loading_Initialised);
        } else {
            const isUserReports = !urlUserId || urlUserId === user.userDnsId;
            setLoadingStatus(UserSubjectsLoadingStatus.Loaded);
            if (isUserReports) {
                setSubjectsByIds(turnSubjectListIntoKeyMap(ownSubjects));
            }
        }
    }, [appStatusIsLoading, dispatch, ownSubjects, loadingStatus, urlUserId, user.userDnsId]);

    useEffect(() => {
        if (isParentOrTeacher && selectedFamilyMember?.userId === user.userDnsId) {
            setFamilyMemberHasPremium(hasPremium);
        }
    }, [isParentOrTeacher, selectedFamilyMember?.userId, user.userDnsId, hasPremium]);

    const showResponse = useCallback(
        (message: string, type: ResponseType) => {
            dispatch(responseActions.showResponse({ type: type, text: [message] }));
        },
        [dispatch]
    );

    const getFamilyMemberSubjects = useCallback(
        async (member: FamilyMember) => {
            if (member?.userId) {
                try {
                    const response = await getSubjectsByUserId(member.userId);
                    if (response.data.httpCode === 200) {
                        const {
                            data: {
                                replyContent: { subjects = [] },
                            },
                        } = response;
                        const nonExpiredSubjects = subjects!
                            .map((subject) => subject[1])
                            .filter((subject: SubjectData) => !subject.isExpired);
                        if (nonExpiredSubjects.length === 1) {
                            // hide single empty default children subjects -> show purchase content advertisement instead
                            const subject = nonExpiredSubjects[0];
                            const isChildSubject: boolean = !(
                                subject.subjectMetadata.subjectIdToOwner.ownerId === user.userDnsId
                            );
                            const isDefault: boolean = isDefaultSubject(subject);
                            const isEmpty: boolean = subject.groupedCardCount?.cardCounts.LIBRARY.cardCount === 0;
                            setSubjectsByIds(
                                !(isChildSubject && isDefault && isEmpty)
                                    ? turnSubjectListIntoKeyMap(nonExpiredSubjects)
                                    : {}
                            );
                        } else {
                            setSubjectsByIds(turnSubjectListIntoKeyMap(nonExpiredSubjects));
                        }
                    }
                } catch (e) {
                    setSubjectsByIds({});
                    if (e.message === "Network Error") {
                        showResponse(t_versionSwitchFailed_NoNetwork, "WARNING");
                    }
                }
            }
        },
        [showResponse, t_versionSwitchFailed_NoNetwork, user.userDnsId]
    );

    const resetScreenType = () => {
        setSelectedSubjectId(null);
        setScreenType("TOPICS");
    };

    const onSubjectSelect = useCallback(
        (subjectId: string | null) => {
            if (!subjectId) {
                resetScreenType();
                setShowInviteChildScreen(false);
            } else if (subjectsByIds && Object.keys(subjectsByIds).length) {
                const nextSubject = subjectsByIds[subjectId];
                if (nextSubject) {
                    setSelectedSubjectId(subjectId);
                    setScreenType("PHASES");
                    if (isParentOrTeacher && familyMembers.length) {
                        setShowInviteChildScreen(false);
                    }
                }
            }
        },
        [subjectsByIds, ownSubjects]
    );

    const getMemberPremiumInfo = (memberId: string) => {
        if (memberId === user.userDnsId) {
            setFamilyMemberHasPremium(hasPremium);
            return;
        }
        setFamilyMemberHasPremium(
            userGroups.members?.find((m) => m.id === memberId)?.roles?.includes("p6o.p6o2.sync") || false
        );
    };

    useEffect(() => {
        const isDataLoading: boolean = appStatusIsLoading || loadingStatus !== UserSubjectsLoadingStatus.Loaded;
        if (isDataLoading) return;

        const memberIdToFind = urlUserId || user.userDnsId;
        const redirectToReportsRoot = (showResponse: boolean = false) => {
            history.replace("/reports");
            if (!showResponse) return;
            dispatch(
                responseActions.showResponse({
                    type: ResponseConstants.ERROR,
                    text: [t_wrongFamilyMember],
                })
            );
        };

        if (urlUserId === user.userDnsId) {
            return redirectToReportsRoot();
        }
        if (urlUserId && !parentAndFamilyMembers?.length) {
            return redirectToReportsRoot();
        }
        if (!selectedFamilyMember || memberIdToFind === selectedFamilyMember?.userId) return;

        const member = parentAndFamilyMembers?.find((member) => member.userId === memberIdToFind);

        if (!member) {
            return redirectToReportsRoot(true);
        }

        if (memberIdToFind === user.userDnsId) {
            setSubjectsByIds(turnSubjectListIntoKeyMap(ownSubjects));
        } else getFamilyMemberSubjects(member);

        setSelectedFamilyMember(member);
        getMemberPremiumInfo(memberIdToFind as string);
        resetScreenType();
        setShowInviteChildScreen(false);
    }, [
        dispatch,
        setSubjectsByIds,
        setSelectedFamilyMember,
        getMemberPremiumInfo,
        resetScreenType,
        setShowInviteChildScreen,
        appStatusIsLoading,
        loadingStatus,
        history,
        user.userDnsId,
        urlUserId,
        ownSubjects,
        parentAndFamilyMembers,
        selectedFamilyMember,
        t_wrongFamilyMember,
    ]);

    const onFamilyMemberSelect = (memberId: string | undefined) => {
        setShowInviteChildScreen(false);
        if (!memberId || !isParentOrTeacher || memberId === user.userDnsId) {
            history.push(`/reports`);
            return;
        }
        const member = parentAndFamilyMembers?.find((member) => member.userId === memberId);
        if (member) history.push(`/reports/${memberId}`);
    };

    const changeScreen = (type: ReportScreenType) => {
        setScreenType(type);
    };

    return (
        <Reports
            user={user}
            isFamilyView={isParentOrTeacher}
            isStrictMode={isStrictMode}
            userHasPremium={hasPremium}
            userPreferences={ownPreferences}
            screenType={screenType}
            changeScreen={changeScreen}
            subjects={subjectsByIds ? Object.values(subjectsByIds) : null}
            selectedSubject={selectedSubjectId && subjectsByIds ? subjectsByIds[selectedSubjectId] : null}
            onSubjectSelect={onSubjectSelect}
            familyMembers={parentAndFamilyMembers}
            selectedFamilyMember={selectedFamilyMember}
            memberHasPremium={familyMemberHasPremium}
            onFamilyMemberSelect={onFamilyMemberSelect}
            showInviteChildScreen={showInviteChildScreen}
            onInviteChild={isParentOrTeacher ? () => setShowInviteChildScreen(true) : undefined}
        />
    );
};

export default ReportsWrapper;
