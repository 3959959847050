// REACT
import React from "react";
import { useT } from "@transifex/react";

// TYPES
import type { AvailableLanguages } from "p6m-dictionary";

export interface TranslatedLanguageProps {
    languageToTranslate: AvailableLanguages;
}

const TranslatedLanguage: React.FC<TranslatedLanguageProps> = (props) => {
    const t = useT();

    const t_english = t("English", { _tags: "translatedLanguages,english" });
    const t_german = t("German", { _tags: "translatedLanguages,german" });
    const t_latin = t("Latin", { _tags: "translatedLanguages,latin" });
    const t_french = t("French", { _tags: "translatedLanguages,french" });
    const t_spanish = t("Spanish", { _tags: "translatedLanguages,spanish" });
    const t_italian = t("Italian", { _tags: "translatedLanguages,italian" });

    let translatedString;

    switch (props.languageToTranslate) {
        case "English":
            translatedString = t_english;
            break;
        case "German":
            translatedString = t_german;
            break;
        case "Latin":
            translatedString = t_latin;
            break;
        case "French":
            translatedString = t_french;
            break;
        case "Spanish":
            translatedString = t_spanish;
            break;
        case "Italian":
            translatedString = t_italian;
            break;
        default:
            translatedString = props.languageToTranslate;
            break;
    }
    return <>{translatedString}</>;
};

export default TranslatedLanguage;
