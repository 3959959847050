import styled from "styled-components";

export const UserMenuContainer = styled.div`
    display: flex;
    height: 100%;
    width: 100%;
    align-items: center;
    cursor: pointer;

    &.reverse-direction {
        flex-direction: row-reverse;
        height: unset;
        justify-content: flex-end;
    }
`;

export const UserNameContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    margin-left: 3em;
    margin-right: ${(props) => props.theme.base.spacingSmall};

    &.push-to-left {
        align-items: flex-start;
        margin-left: 0;
    }
`;

export const UserNameAndEmailWrapper = styled.div`
    border-bottom: solid 1px ${(props) => props.theme.colors.backgroundMain};
    padding: 1em 1.5em;

    &.no-padding-bottom {
        padding-bottom: 0;
    }
`;

export const UserMenuEntriesWrapper = styled.div`
    margin-top: 0.5em;
    padding: 0.5em;
`;

export const FirstNameContainer = styled.p`
    font-size: ${(props) => props.theme.base.bigSize};
    margin: 0;
    color: ${(props) => props.theme.colors.textOnBackground};

    @media (max-width: ${process.env.REACT_APP_LOWER_GRID_STEP_BORDER}px) {
        color: ${(props) => props.theme.colors.text};
    }
`;

export const FocusedMenuEntry = styled.div`
    background: ${(props) => props.theme.colors.primary};

    p {
        color: white;
    }
`;

export const EmailNotConfirmedWrapper = styled.div`
    p {
        color: ${(props) => props.theme.colors.primary};
        text-align: center !important;
        font-weight: bold;
    }
`;

export const UserNameWrapper = styled.p`
    font-size: ${(props) => props.theme.base.bigSize};
    text-align: left;
    color: ${(props) => props.theme.colors.text};
`;

export const UserEmailWrapper = styled.p`
    font-size: ${(props) => props.theme.base.smallSize};
    text-align: left;
    color: ${(props) => props.theme.colors.text};
`;

export const LogoutSeparator = styled.div`
    width: 100%;
    height: 1px;
    border-bottom: solid 1px ${(props) => props.theme.colors.backgroundMain};
`;

export const MobileContentWrapper = styled.div``;

export const popupStyles = {
    marginRight: "30px",
    marginTop: "-15px",
    padding: "0",
    minWidth: "250px",
};
