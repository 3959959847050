// LIBRARIES
import React, { ChangeEvent, useState } from "react";
import styled from "styled-components";

// CONSTANTS
import { UserPreferences } from "p6m-user";
import RangeSlider from "../../basic/rangeSlider/RangeSlider";
import { debounce } from "lodash";

export interface ReportTTHSettingProps {
    userPreferences: UserPreferences;
    updateTTH: (value: number) => void;
}

const Container = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
`;
const SelectedItemInfo = styled.span`
    color: ${(props) => props.theme.colors.text};
    border: solid 1px ${(props) => props.theme.colors.backgroundMain};
    border-radius: ${(props) => props.theme.base.borderRadius};
    padding: 5px;
    margin: 0 0 0 5px;
    text-align: center;
`;

const updateLimitInBackendDebounced = debounce(
    (newLimit, functionToCall) => {
        functionToCall(newLimit);
    },
    500,
    {
        leading: false,
        trailing: true,
    }
);

const ReportTTHSetting: React.FC<ReportTTHSettingProps> = (props) => {
    const [value, setValue] = useState<number>(props.userPreferences.tthLimit || 30);

    const change = (event: ChangeEvent<HTMLInputElement>) => {
        setValue(+event.target.value);
        updateLimitInBackendDebounced(+event.target.value, props.updateTTH);
    };

    return (
        <Container>
            <RangeSlider
                value={value}
                min={5}
                max={200}
                onChange={change}
            />
            <SelectedItemInfo>{value}</SelectedItemInfo>
        </Container>
    );
};

export default ReportTTHSetting;
