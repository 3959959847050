// REACT
import React, { useEffect, useState } from "react";
import { useT } from "@transifex/react";

// COMPONENTS
import RangeSlider from "../rangeSlider/RangeSlider";

// LIBRARIES
import { debounce, isNumber } from "lodash";

// STYLED COMPONENTS
import {
    MainWrapper,
    RangeSliderWrapper,
    SelectedItemInfo,
    SliderDescriptionText,
    TextAndInputWrapper,
} from "./styles";

export interface SettingsRangeSliderProps {
    label: string;
    optionsValueList: number[];
    onChange?: (newVal: number) => any;
    defaultValue?: number;
    className?: string;
    optionsLabelList?: Array<any>;
    debounced?: boolean;
    standardOptionIndex?: number;
}

const updateModelValue = debounce(
    (value, onValueChange) => {
        if (onValueChange) {
            onValueChange(value);
        }
    },
    1000,
    {
        leading: false,
        trailing: true,
    }
);

const SettingsRangeSlider: React.FC<SettingsRangeSliderProps> = (props) => {
    const t = useT();
    const t_default = t("(default)", { _tags: "phaseSettings" });

    const { optionsValueList, optionsLabelList, defaultValue, onChange, debounced, standardOptionIndex } = props;

    const [valueToShow, setValueToShow] = useState<number | undefined>(defaultValue);

    const handleValueChange = (newValue: string) => {
        const value = Number(newValue);

        if (value !== valueToShow) {
            setValueToShow(value);

            if (onChange) {
                if (debounced) {
                    updateModelValue(value, onChange);
                } else {
                    onChange(value);
                }
            }
        }
    };

    useEffect(() => {
        if ((!isNumber(valueToShow) || !optionsValueList.includes(valueToShow)) && optionsValueList.length) {
            setValueToShow(optionsValueList[0]);
        }
    }, [optionsValueList, valueToShow]);

    return isNumber(valueToShow) && optionsValueList.includes(valueToShow) ? (
        <MainWrapper className={`${props.className} range-slider`}>
            <TextAndInputWrapper>
                <SliderDescriptionText>{props.label}</SliderDescriptionText>
                <SelectedItemInfo>
                    {optionsLabelList?.length ? optionsLabelList[valueToShow] : valueToShow}
                    {optionsValueList.indexOf(valueToShow) === standardOptionIndex ? ` ${t_default}` : undefined}
                </SelectedItemInfo>
            </TextAndInputWrapper>
            <RangeSliderWrapper>
                <RangeSlider
                    value={valueToShow}
                    max={optionsValueList[optionsValueList.length - 1]}
                    min={optionsValueList[0]}
                    onChange={(event) => handleValueChange(event.target.value)}
                />
            </RangeSliderWrapper>
        </MainWrapper>
    ) : (
        <></>
    );
};

SettingsRangeSlider.defaultProps = {
    debounced: true,
};

export default SettingsRangeSlider;
