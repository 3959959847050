// REACT
import React, { FunctionComponent, useCallback } from "react";

// REDUX
import { useSelector, useDispatch } from "react-redux";
import { selectors, actions } from "../../../../../redux/learning/learningSlice";

// TYPES
import { Props as ModalProps } from "../../../../basic/modal/Modal";

// COMPONENTS
import { UpdateCardModal } from "../../../../connected/addCards/updateCardModal";

export const Update: FunctionComponent<Partial<ModalProps>> = (props) => {
    const card = useSelector(selectors.currentCard);

    const dispatch = useDispatch();

    const afterSave = useCallback(
        (newCard) => {
            dispatch(actions.updateCard(newCard));
        },
        [dispatch]
    );

    return (
        <>
            {!!card && (
                <UpdateCardModal
                    card={card}
                    afterSave={afterSave}
                    {...props}
                />
            )}
        </>
    );
};
