// REACT
import React, { useCallback } from "react";
import { useMediaQuery } from "react-responsive";

// TYPE
import { useLocation } from "react-router-dom";
import { TIconNames } from "../../basic/phaseSixIcon/PhaseSixIcon";

// LIBRARIES
import ReactTooltip from "react-tooltip";

// COMPONENTS
import MouseEventIcon from "../../basic/mouseEventIcon/MouseEventIcon";

// STYLED COMPONENTS
import {
    StyledDisabledLinkContainer,
    StyledExternalLinkContainer,
    StyledLinkContainer,
    StyledLinkWrapper,
    Text,
} from "./styles";

export interface SideNavigationEntryProps {
    title: string;
    icon?: TIconNames;
    svgIcon?: React.FC;
    navTarget: string;
    external?: boolean;
    onClick?: () => any;
    disabled?: boolean;
    disabledTooltipText?: string;
}

const SideNavigationEntry: React.FC<SideNavigationEntryProps> = (props) => {
    let isCurrentRoute = useLocation().pathname.includes(props.navTarget);
    let icon = null;

    let isP6Icon = !!props.icon;
    let isSvgIcon = !!props.svgIcon;

    if (isP6Icon || isSvgIcon) {
        icon = (
            <MouseEventIcon
                {...props}
                isActive={isCurrentRoute}
                isDisabled={props.disabled}
                useSidebarColors={true}
            />
        );
    }

    // props for the Tooltip to work
    let tooltip = null;
    let linkProps: { [key: string]: any } = {};
    if (props.disabled && typeof props.disabledTooltipText != "undefined" && props.disabledTooltipText.length > 0) {
        linkProps = {
            "data-tip": "",
            "data-for": props.title + "_tooltip",
        };
        tooltip = props.disabledTooltipText;
    }

    // Media query for the mobile rendering
    const lowerBorderQuery = "(max-width: " + process.env.REACT_APP_LOWER_GRID_STEP_BORDER + "px)";
    let isMobileView: Boolean = useMediaQuery({
        query: lowerBorderQuery,
    });

    if (props.external) {
        return (
            <StyledLinkWrapper>
                <StyledExternalLinkContainer
                    href={props.navTarget}
                    target={"_blank"}
                    onClick={props.onClick}
                >
                    {icon}
                    <Text>{props.title}</Text>
                </StyledExternalLinkContainer>
            </StyledLinkWrapper>
        );
    } else {
        if (props.disabled) {
            if (isMobileView) {
                return (
                    <StyledLinkWrapper className={`disabled`}>
                        <StyledDisabledLinkContainer>
                            {icon}
                            <div>
                                <Text>{props.title}</Text>
                                <Text>{tooltip}</Text>
                            </div>
                        </StyledDisabledLinkContainer>
                    </StyledLinkWrapper>
                );
            } else {
                return (
                    <StyledLinkWrapper className={`disabled`}>
                        <StyledDisabledLinkContainer {...linkProps}>
                            {icon}
                            <Text>{props.title}</Text>
                        </StyledDisabledLinkContainer>
                        <ReactTooltip id={props.title + "_tooltip"}>{tooltip}</ReactTooltip>
                    </StyledLinkWrapper>
                );
            }
        } else {
            return (
                <StyledLinkWrapper className={`${isCurrentRoute ? "active" : ""}`}>
                    <StyledLinkContainer
                        to={props.navTarget}
                        onClick={props.onClick}
                    >
                        {icon}
                        <Text>{props.title}</Text>
                    </StyledLinkContainer>
                </StyledLinkWrapper>
            );
        }
    }
};

SideNavigationEntry.defaultProps = {
    disabled: false,
    disabledTooltipText: "",
};

export default SideNavigationEntry;
