import styled from "styled-components";

export const Container = styled.div`
    position: relative;
    cursor: pointer;
    margin-right: 10px;
`;
export const ActiveSubjectOverlay = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: ${(props) => props.theme.base.borderRadiusSmall};
    background-color: ${(props) => props.theme.colors.backgroundMain};
    opacity: 0.7;
    max-height: 241px;
`;
