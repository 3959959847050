export function playAudio (audioSrc: string, onEnded?: (e?: Event) => void) {
    let currentAudio: HTMLAudioElement | undefined = new Audio(audioSrc);
    currentAudio.addEventListener("ended", (e: Event) => {
        onEnded?.(e);
        currentAudio = undefined;
    });

    return currentAudio;
}

export enum PlaylistStatus {
    stopped,
    playing,
    paused,
};

type PlaylistData = {
    audios: string[];
    currentIndex: number;
    currentAudio: HTMLAudioElement | undefined;
    status: PlaylistStatus;
};

export const getPlaylist = (() => {
    const eventTarget = new EventTarget();

    const data: PlaylistData = getPlaylistDataProxy({
        audios: [],
        currentIndex: 0,
        currentAudio: undefined,
        status: PlaylistStatus.stopped,
    }, eventTarget);

    const {
        setAudios,
        getList,
        play,
        pause,
        stop,
        goNext,
        goPrev,
        getCurrentAudio,
        getStatus,
    } = getActions(data);

    return () => {

        return {
            setAudios,
            getList,
            play,
            pause,
            stop,
            goNext,
            goPrev,
            getCurrentAudio,
            getStatus,
            eventTarget,
        }
    }
})();

function getActions (data: PlaylistData) {
    function setAudios (audiosSrc: string[], autoplay: boolean = false) {
        data.audios.push(...audiosSrc);
        if (!autoplay) return;
        play();
    }
    function getList () {
        return data.audios;
    }
    function play () {
        data.status = PlaylistStatus.playing;
    }
    function pause () {
        data.status = PlaylistStatus.paused;
    }
    function stop () {
        data.status = PlaylistStatus.stopped;
    }
    function goNext () {
        const {currentIndex, audios} = data;
        if (currentIndex >= audios.length) {
            stop();
            return;
        }
        ++data.currentIndex;
    }
    function goPrev () {
        const {currentIndex} = data;
        if (currentIndex <= 0) return;
        --data.currentIndex;
    }
    function getCurrentAudio () {
        return data.currentAudio;
    }
    function getStatus () {
        return data.status;
    }

    return {
        setAudios,
        getList,
        play,
        pause,
        stop,
        goNext,
        goPrev,
        getCurrentAudio,
        getStatus,
    };
}

function getPlaylistDataProxy (data: PlaylistData, eventTarget: EventTarget) {
    return new Proxy(data, {
        set (target, key: keyof PlaylistData, value: any) {
            const setterRelations: Partial<Record<keyof PlaylistData, () => boolean>> = {
                status: () => onStatusChange(target, value, eventTarget),
                currentIndex: () => onIndexChange(target, value, eventTarget),
            };

            const action = setterRelations[key];            
            if (action) return action();

            target[key] = value;
            return true;
        }
    });
}

function onStatusChange (target: PlaylistData, value: PlaylistStatus, eventTarget: EventTarget) {
    const {currentIndex, status} = target;

    if (status === value) return true;

    target.status = value;

    if (value === PlaylistStatus.playing) {
        onIndexChange(target, currentIndex, eventTarget);
    }
    else if (value === PlaylistStatus.paused) {
        target.currentAudio?.pause();
    }
    else if (value === PlaylistStatus.stopped) {
        target.currentAudio?.pause();
        target.currentAudio = undefined;
        target.audios = [];
        target.currentIndex = 0;
    }

    eventTarget.dispatchEvent(new CustomEvent("statusChange"));
    return true;
}

function onIndexChange (target: PlaylistData, value: number, eventTarget: EventTarget) {
    const {audios, status, currentAudio} = target;
    const audioSrc = audios[value];
    if (
        status === PlaylistStatus.playing &&
        audioSrc
    ) {
        if (currentAudio) currentAudio.pause();
        const newAudio = playAudio(audioSrc, () => {
            const nextIndex = value + 1;
            const nextAudioSrc = target.audios[nextIndex];
            target.currentAudio = undefined;
            eventTarget.dispatchEvent(new CustomEvent("audioChange"));
            if (!nextAudioSrc) onStatusChange(target, PlaylistStatus.stopped, eventTarget);
            else onIndexChange(target, nextIndex, eventTarget);
        });

        
        newAudio.play();
        target.currentAudio = newAudio;
        eventTarget.dispatchEvent(new CustomEvent("audioChange"));
    };
    target.currentIndex = value;
    return true;
}
