import { useCallback } from "react";

//HOOKS
import { useDropdownEditResponsePopup } from "./useDropdownEditResponsePopup";

//TYPES
import { DROPDOWN_CONTENT_TYPES, DROPDOWN_EDIT_TYPES, EDIT_RESPONSE_TYPES } from "./useDropdownEditResponsePopup";

export const useMultiUpdates = () => {
    const showPopUpMessage = useDropdownEditResponsePopup();
    const handleMultiUpdates = useCallback(
        async (itemsToUpdate: Promise<{ httpCode?: number; name: string }>[], contentType: DROPDOWN_CONTENT_TYPES) => {
            if (itemsToUpdate?.length > 0) {
                const itemsUpdatedResponse = await Promise.all(itemsToUpdate);
                const failedUpdates: string[] =
                    itemsUpdatedResponse?.filter((item) => item?.httpCode !== 200).map((item) => item?.name) || [];
                if (failedUpdates.length) {
                    showPopUpMessage(
                        contentType,
                        DROPDOWN_EDIT_TYPES.UPDATE,
                        EDIT_RESPONSE_TYPES.ERROR,
                        failedUpdates.join(", ")
                    );
                } else {
                    showPopUpMessage(
                        contentType,
                        DROPDOWN_EDIT_TYPES.UPDATE,
                        EDIT_RESPONSE_TYPES.SUCCESS,
                        itemsUpdatedResponse.map((item) => item.name).join(", ")
                    );
                }
            }
        },
        [showPopUpMessage]
    );

    return handleMultiUpdates;
};
