// REACT
import React, { FunctionComponent, useCallback, useState, useMemo, ChangeEventHandler } from "react";

// REDUX
import { useDispatch, useSelector } from "react-redux";
import { selectors, actions as learningActions } from "../../../../../redux/learning/learningSlice";
import { selectors as userSelectors } from "../../../../../redux/user/userSlice";

// TYPES
import { Props as ModalProps } from "../../../../basic/modal/Modal";

// UTILS
import { getCardContentInfo } from "../../../../../helpers/Cards";

// COMPONENTS
import Component from "./Feedback";

export const Feedback: FunctionComponent<Partial<ModalProps>> = (props) => {
    const userValue = useSelector(selectors.userValue);
    const { text: questionText } = useSelector(selectors.cardTexts(false));
    const { text: answerText } = useSelector(selectors.cardTexts(true));
    const user = useSelector(userSelectors.user);

    const [feedback, setFeedback] = useState("");

    const dispatch = useDispatch();

    const question = useMemo(() => getCardContentInfo(questionText || "", "title"), [questionText]);
    const answer = useMemo(() => getCardContentInfo(answerText || "", "title"), [answerText]);

    const [userName, email] = useMemo(() => {
        if (!user) return [];
        return [user.displayName, user.email];
    }, [user]);

    const onSubmit = useCallback(
        () => () => {
            dispatch(learningActions.sendFeedback(feedback));
        },
        [dispatch, feedback]
    );

    const onDecline = useCallback(
        (actions) => () => {
            const { hide } = actions || { hide: () => {} };
            hide();
        },
        []
    );

    const onFedbackChange: ChangeEventHandler<HTMLTextAreaElement> = useCallback((e) => {
        const {
            target: { value },
        } = e;
        setFeedback(value);
    }, []);

    return (
        <Component
            question={question}
            answer={answer}
            userAnswer={userValue || ""}
            userName={userName || ""}
            email={email || ""}
            setFeedback={onFedbackChange}
            onSubmit={onSubmit}
            onDecline={onDecline}
            {...props}
        />
    );
};
