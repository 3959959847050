// REACT
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

// COMPONENTS
import LanguageCombo from "./LanguageCombo";

// REDUX
import { actions, selectors } from "../../../redux/user/userSlice";

// TYPES
import { AvailableLanguageCodes } from "p6m-dictionary";

const LanguageComboContainer: React.FC = () => {
    const dictionarySearchLanguage: AvailableLanguageCodes = useSelector(selectors.dictionarySearchLanguage);
    const dispatch = useDispatch();
    const history = useHistory();

    const setLanguage = (searchLanguage: AvailableLanguageCodes) => {
        dispatch(actions.setDictionaryLanguage(searchLanguage));
        history.push("/dictionary/" + searchLanguage);
    };

    return (
        <LanguageCombo
            dictionarySearchLanguage={dictionarySearchLanguage}
            setLanguage={setLanguage}
        />
    );
};

export default LanguageComboContainer;
