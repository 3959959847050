import { Language, LanguageFilterTypes } from "p6m-dictionary";

const Languages: { [lang: string]: Language } = {
    ENGLISH: {
        name: "English",
        languageCode: "en",
    },
    FRENCH: {
        name: "French",
        languageCode: "fr",
    },
    LATIN: {
        name: "Latin",
        languageCode: "la",
    },
    SPANISH: {
        name: "Spanish",
        languageCode: "es",
    },
    ITALIAN: {
        name: "Italian",
        languageCode: "it",
    },
};

const DictionaryConstants: {
    targetLanguages: Array<Language>;
    LanguageFilterTypes: { [key: string]: LanguageFilterTypes };
} = {
    targetLanguages: [Languages.ENGLISH, Languages.FRENCH, Languages.LATIN, Languages.SPANISH, Languages.ITALIAN],
    LanguageFilterTypes: {
        GermanAndForeignLang: 0,
        ForeignLang: 1,
        GermanLang: 2,
    },
};

export default DictionaryConstants;
