import React, { FunctionComponent } from "react";
import Component, { Props } from "./TrophyItem";
import { useSelector } from "react-redux";
import { goalsToReachDerivedSelector } from "../../../../redux/goals/goalsSlice";

export const TrophyItem: FunctionComponent<Partial<Props>> = (props) => {
    const leftAchievementsCount = useSelector(goalsToReachDerivedSelector);

    return (
        <Component
            count={leftAchievementsCount}
            {...props}
        />
    );
};
