//DEPENDENCIES
import React from "react";
import styled from "styled-components";

//HOOKS
import { T } from "@transifex/react";

//COMPONENTS
import { Col } from "../../basic/grid";
import PhaseSixIcon from "../../../components/basic/phaseSixIcon/PhaseSixIcon";

//TYPES
import { activationSteps } from "../../../views/activation";

//STYLES
import { FooterRow, Button, MarginLeftPhaseSixIcon, MarginRightPhaseSixIcon } from "./styles";

type ActivationNavigationButtonsProps = {
    activationStep: activationSteps;
    isSharedTestActivation: boolean;
    cardsAmount: number;
    onGoBack: () => void;
    onCancel: () => void;
    onContinue: () => void;
};

const StyledPhaseSixIcon = styled(PhaseSixIcon)`
    margin-right: -5px;
    color: inherit;
`;

const ActivationNavigationButtons = ({
    activationStep,
    isSharedTestActivation,
    cardsAmount,
    onCancel,
    onGoBack,
    onContinue,
}: ActivationNavigationButtonsProps) => {
    return (
        <>
            <FooterRow>
                <Col xs="auto">
                    <Button
                        onClick={onGoBack}
                        disabled={activationStep === activationSteps.LIST}
                    >
                        <MarginLeftPhaseSixIcon name="chevron-left" /> <T _str="Back" />
                    </Button>
                </Col>
                <Col xs="auto">
                    {!isSharedTestActivation && (
                        <Button onClick={onCancel}>
                            <T
                                _str="Cancel"
                                _tags="button,activation"
                            />
                            <StyledPhaseSixIcon name="close" />
                        </Button>
                    )}
                </Col>

                <Col
                    xs="auto"
                    horizontal="end"
                >
                    <Button
                        onClick={onContinue}
                        disabled={!cardsAmount}
                    >
                        {activationStep === activationSteps.LIST ? (
                            <>
                                <T _str="Next" />
                                <MarginRightPhaseSixIcon name="chevron-right" />
                            </>
                        ) : (
                            <>
                                <T
                                    _str="Start Learning {cards}"
                                    cards={cardsAmount.toLocaleString("de-DE")}
                                />
                                <StyledPhaseSixIcon name="annotation" />
                            </>
                        )}
                    </Button>
                </Col>
            </FooterRow>
        </>
    );
};

export default ActivationNavigationButtons;
