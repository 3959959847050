import { takeEvery, call, put, select } from "redux-saga/effects";
import { actions, selectors } from "./goalsSlice";
import { getUserGoals, setFirstPracticeFinished } from "../../networking/user";
import { actions as appStatusActions } from "../appStatus/appStatusSlice";
import { actions as userActions } from "../user/userSlice";
import { IUserGoals } from "p6m-goalsData";
import { IResponse } from "p6m-response";
import { AxiosResponse } from "axios";

function* getUserGoalsAsync() {
    yield put(appStatusActions.setLoading(true));
    try {
        const response: AxiosResponse<IResponse<IUserGoals>> = yield call(getUserGoals);
        const {
            data: { replyContent },
        } = response;
        yield put(actions.setGoals(replyContent));
    } catch (e) {
        console.log(e);
    } finally {
        yield put(appStatusActions.setLoading(false));
    }
}

function* finishFirstPractice() {
    const userGoals: IUserGoals = yield select(selectors.goals);
    if (!userGoals) return;

    yield put(appStatusActions.setLoading(true));
    try {
        yield call(setFirstPracticeFinished);
        const newData: IUserGoals = {
            ...userGoals,
            goals: {
                ...userGoals.goals,
                firstPracticeFinished: true,
            },
        };
        yield put(actions.setGoals(newData));
        yield put(userActions.loadUserMetadata());
    } catch (e) {
        console.log(e);
    } finally {
        yield put(appStatusActions.setLoading(false));
    }
}

export function* goalsSaga() {
    yield takeEvery(actions.fetchGoals, getUserGoalsAsync);
    yield takeEvery(actions.finishFirstPractice, finishFirstPractice);
}
