// REACT
import React from "react";
import { useT } from "@transifex/react";

// COMPONENTS
import PhaseSixIcon from "../phaseSixIcon/PhaseSixIcon";

// STYLED COMPONENTS
import {
    GoToShopContainer,
    GoToShopContent,
    GoToShopCTA,
    GoToShopCTAIcon,
    GoToShopCTAText,
    GoToShopDescriptionContainer,
    GoToShopDescriptionEntry,
    GoToShopDescriptionEntryText,
    GoToShopDescriptionList,
    GoToShopDescriptionTitle,
    GoToShopGradient,
    GoToShopSubtitle,
    GoToShopTitle,
    GoToShopTitleContainer,
    GoToShopTitleTextContainer,
    PremiumDictionaryImage,
} from "./styles";

interface GoToShopPageProps {
    onClick: () => void;
}

const GoToShopPage: React.FC<GoToShopPageProps> = ({ onClick }) => {
    const t = useT();

    const t_premium_function = t("Premium function", { _tags: "dictionary,advertisement,shop,title" });
    const t_pons_dictionary = t("PONS dictionary", { _tags: "dictionary,advertisement,shop,subtitle" });
    const t_look_up_take_over = t("Look up & take over", { _tags: "dictionary,advertisement,shop,description,title" });
    const t_for_languages = t("For English, French, Spanish and Latin", {
        _tags: "dictionary,advertisement,shop,list,entry",
    });
    const t_words_per_language = t("Up to 120,000 words per language", {
        _tags: "dictionary,advertisement,shop,list,entry",
    });
    const t_search_german_or_target_language = t("Search in German or target language", {
        _tags: "dictionary,advertisement,shop,list,entry",
    });
    const t_pickup_in_query = t("Direct pickup in query", { _tags: "dictionary,advertisement,shop,list,entry" });
    const t_unlock_premium = t("Info about Premium", { _tags: "dictionary,advertisement,shop,action,button" });

    return (
        <GoToShopContainer>
            <PremiumDictionaryImage src={require("../../../assets/img/dict/premium-dictionary.jpeg")} />
            <GoToShopGradient>
                <GoToShopContent>
                    <GoToShopTitleContainer>
                        <GoToShopTitleTextContainer>
                            <GoToShopTitle>{t_premium_function}</GoToShopTitle>
                            <GoToShopSubtitle>{t_pons_dictionary}</GoToShopSubtitle>
                        </GoToShopTitleTextContainer>
                    </GoToShopTitleContainer>
                    <GoToShopDescriptionContainer>
                        <GoToShopDescriptionTitle>{t_look_up_take_over}</GoToShopDescriptionTitle>
                    </GoToShopDescriptionContainer>
                    <GoToShopDescriptionList>
                        <GoToShopDescriptionEntry>
                            <PhaseSixIcon name={"exercise-ok"} />
                            <GoToShopDescriptionEntryText>{t_for_languages}</GoToShopDescriptionEntryText>
                        </GoToShopDescriptionEntry>
                        <GoToShopDescriptionEntry>
                            <PhaseSixIcon name={"exercise-ok"} />
                            <GoToShopDescriptionEntryText>{t_words_per_language}</GoToShopDescriptionEntryText>
                        </GoToShopDescriptionEntry>
                        <GoToShopDescriptionEntry>
                            <PhaseSixIcon name={"exercise-ok"} />
                            <GoToShopDescriptionEntryText>
                                {t_search_german_or_target_language}
                            </GoToShopDescriptionEntryText>
                        </GoToShopDescriptionEntry>
                        <GoToShopDescriptionEntry>
                            <PhaseSixIcon name={"exercise-ok"} />
                            <GoToShopDescriptionEntryText>{t_pickup_in_query}</GoToShopDescriptionEntryText>
                        </GoToShopDescriptionEntry>
                    </GoToShopDescriptionList>
                    <GoToShopCTA onClick={onClick}>
                        <GoToShopCTAIcon
                            name="plus"
                            size="medium"
                        />
                        <GoToShopCTAText>{t_unlock_premium}</GoToShopCTAText>
                    </GoToShopCTA>
                </GoToShopContent>
            </GoToShopGradient>
        </GoToShopContainer>
    );
};

export default GoToShopPage;
