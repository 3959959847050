// LIBRARIES
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

//REDUX
import { selectors as userSelectors } from "../redux/user/userSlice";

//UTILS
import { getStartDateTimeOfDate, getStartDateTimeOfRegistrationDate } from "../helpers/Modal";

// HOOKS
import { useFirstPracticeResult } from "./useFirstPracticeResult";

// Checks if the given string is a valid date
const isDate = (date?: string): boolean => !isNaN(new Date(date || "").getTime());

// Returns a boolean indicating wether GDPRModal shall be shown or not
export const useShowGDPRModal = () => {
    const userData = useSelector(userSelectors.user);
    const userMetaData = useSelector(userSelectors.userMetadata);
    const hasFirstPractice = useFirstPracticeResult();
    const [isFirstRenderAsLoggedInUser, setIsFirstRenderAsLoggedInUser] = useState<boolean>(false);

    useEffect(() => {
        if (!!Object.keys(userData).length && !!Object.keys(userMetaData).length && userData.registrationDate) {
            setIsFirstRenderAsLoggedInUser(true);
        }
    }, [userData, userMetaData]);

    if (isFirstRenderAsLoggedInUser) {
        setIsFirstRenderAsLoggedInUser(false);

        const allRolesThatNeedToBeIncluded = ["student", "unconfirmed"];
        const allRolesThatNeedToBeExcluded = ["over16", "parentinformed", "beforegdpr"];
        const { lastTimeGDPRChecked } = userMetaData;

        const userBelongsToTargetGroup =
            hasFirstPractice &&
            allRolesThatNeedToBeIncluded.every((role) => userData.roles?.includes(role)) &&
            allRolesThatNeedToBeExcluded.every((role) => !userData.roles?.includes(role));

        if (userBelongsToTargetGroup) {
            const today = getStartDateTimeOfDate(new Date());
            const lastCheckedDate = isDate(lastTimeGDPRChecked)
                ? getStartDateTimeOfDate(new Date(lastTimeGDPRChecked))
                : getStartDateTimeOfRegistrationDate(userData.registrationDate!);

            return today > lastCheckedDate;
        }
    }
    return false;
};
