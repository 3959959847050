import styled from "styled-components";
import { ActionsWrapper as ParentAW, ActionSave as ParentAS, Wrapper, ActionClose } from "../SubjectItem/styles";

export { Input, Title, Wrapper, ActionClose, ItemRow, ItemInformationCol } from "../SubjectItem/styles";

export const ActionsWrapper = styled(ParentAW)`
    flex-direction: row;
`;

export const ActionSave = styled(ParentAS)`
    margin: 0;
    margin-right: 5px;
`;

export const DeleteWrapper = styled(Wrapper)`
    background-color: #fae5ea;
    > p {
        color: #cd002b;
    }
`;

export const DeleteIcon = styled(ActionClose)`
    margin-left: 5px;
`;

export const EditIcon = styled(ActionClose)``;
