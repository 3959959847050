//LIBRARIES
import React, { FunctionComponent, useCallback, memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { t } from "@transifex/native";
import { useHistory } from "react-router-dom";

//TYPES
import { IdToOwnerId } from "p6m-subjects";
import {
    LEARNING_DIRECTION,
    PHASE_OPTION,
    CardInfoForActionButtons,
    ACTIVATION_STATUS,
    ModalTypeKeymap,
} from "p6m-cards";
import { EditableContentType, XPositions, YPositions } from "p6m-viewData";

//REDUX
import { actions as modalActions } from "../../../redux/modal/modalSlice";
import { actions as responseActions } from "../../../redux/response/responseSlice";
import { selectors as userSelectors } from "../../../redux/user/userSlice";
import { actions as addCardsActions } from "../../../redux/add/addSlice";

//COMPONENTS
import CardActionButtons from "./CardActionButtons";

//UTILS
import { GlobalModalView } from "../../../helpers/Modal";

//NETWORKING
import { ResponseType } from "../../../constants/ResponseConstants";

export interface CardActionButtonsProps {
    isSingleEntry?: boolean;
    selectedCardsIds?: string[];
    canSwap: boolean;
    subjectId: string;
    unitId?: string;
    onPopUpClicked?: (willBeOpen: boolean) => void;
    popUpsPosition?: [YPositions, XPositions];
    refreshCardList?: (initialValue?: number) => void;
    cardDataById: { [key: string]: CardInfoForActionButtons };
}

const CardActionButtonsWrapper: FunctionComponent<CardActionButtonsProps> = (props) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const userId = useSelector(userSelectors.userId);

    const {
        isSingleEntry = false,
        selectedCardsIds = props.isSingleEntry ? [Object.keys(props.cardDataById)[0]] : [],
        canSwap,
        onPopUpClicked,
        subjectId,
        unitId,
        popUpsPosition,
        refreshCardList,
        cardDataById,
    } = props;

    const t_cardsCantBeDeleted = t("You can only delete your own cards.", {
        _tags: "response,warning,library",
    });
    const t_cardsCantBeEdited = t("You can only edit your own cards.", {
        _tags: "response,warning,library",
    });
    const t_cardsCantBeMoved = t("You can only move your own cards.", {
        _tags: "response,warning,library",
    });
    const t_ErrorMessage = t("An error occurred");

    const allSelectedIds: IdToOwnerId[] = [];
    // phase-0 cards or duplicate cards have the properties active and wasActive being false for both directions.
    // They shall be disabled for phase-change, direction-change, reactivation and deactivation backend requests,
    // so we need to have a list with only enabled cardIds (active === true or wasActive === true for either one of the directions).
    const selectedIdsEnabled: IdToOwnerId[] = [];
    const selectedIdsActiveDirections: IdToOwnerId[] = [];
    const selectedIdsDeactivatedDirections: IdToOwnerId[] = [];
    // cards that can be edited or deleted by user
    const selectedIdsModifiable: IdToOwnerId[] = [];

    selectedCardsIds
        .filter((id) => cardDataById[id] !== undefined) // skip selected cards that are currently not visible
        .map((id) => cardDataById[id])
        .forEach((card) => {
            const cardIdToOwner = card.idToOwnerId;
            allSelectedIds.push(cardIdToOwner);
            if (card.normal.wasActive || card.normal.active || card.opposite.wasActive || card.opposite.active) {
                selectedIdsEnabled.push(cardIdToOwner);
            }
            if (card.normal.active || card.opposite.active) {
                selectedIdsActiveDirections.push(cardIdToOwner);
            }
            if ((card.normal.wasActive && !card.normal.active) || (card.opposite.wasActive && !card.opposite.active)) {
                selectedIdsDeactivatedDirections.push(cardIdToOwner);
            }
            if (cardIdToOwner.ownerId === userId) {
                selectedIdsModifiable.push(cardIdToOwner);
            }
        });

    const allSelectedIdsLength = allSelectedIds.length;
    const isActive = !!selectedIdsActiveDirections.length;
    const isDeactivated = !!selectedIdsDeactivatedDirections.length;
    const canBeModified = !!selectedIdsModifiable.length;

    const showResponsePopUp = useCallback(
        (type: ResponseType, message: string) => {
            dispatch(
                responseActions.showResponse({
                    type,
                    text: [message],
                })
            );
        },
        [dispatch]
    );
    const showError = useCallback(() => {
        dispatch(
            responseActions.showResponse({
                type: "ERROR",
                text: [t_ErrorMessage],
            })
        );
    }, [dispatch, t_ErrorMessage]);

    const openMoveCardModal = useCallback(
        (moveCardData: {
            cardIds: IdToOwnerId[];
            initialSubjectId: string;
            containsProtectedCardIds: boolean;
            refreshCardList?: () => void;
        }) => {
            dispatch(modalActions.setData(moveCardData));
            dispatch(modalActions.setModalView(GlobalModalView.ChangeCardSubject));
        },
        [dispatch]
    );

    const openDeleteModal = useCallback(
        (deleteData: {
            contentType: EditableContentType;
            itemIds: IdToOwnerId[];
            deletableCardCount: number;
            selectedSubjectId?: string;
            selectedCardCount?: number;
            refreshCardList?: () => void;
        }) => {
            dispatch(modalActions.setData(deleteData));
            dispatch(modalActions.setModalView(GlobalModalView.DeleteContent));
        },
        [dispatch]
    );

    interface ModifyCardAttributes {
        modalType: keyof ModalTypeKeymap;
        changeOption: ModalTypeKeymap[keyof ModalTypeKeymap];
        itemIds: IdToOwnerId[];
        changeableCardCount: number;
        selectedCardCount?: number;
        refreshCardList?: (initialValue?: number) => void;
        cardDataById: { [key: string]: CardInfoForActionButtons };
    }

    const openChangeCardDirectionModal = useCallback(
        (changeDirectionsData: ModifyCardAttributes) => {
            dispatch(modalActions.setData(changeDirectionsData));
            dispatch(modalActions.setModalView(GlobalModalView.ModifyCardAttributes));
        },
        [dispatch]
    );

    const openChangeCardPhaseStatusModal = useCallback(
        (changeDirectionsData: ModifyCardAttributes) => {
            dispatch(modalActions.setData(changeDirectionsData));
            dispatch(modalActions.setModalView(GlobalModalView.ModifyCardAttributes));
        },
        [dispatch]
    );

    const openChangeActivationModal = useCallback(
        (changeDirectionsData: ModifyCardAttributes) => {
            dispatch(modalActions.setData(changeDirectionsData));
            dispatch(modalActions.setModalView(GlobalModalView.ModifyCardAttributes));
        },
        [dispatch]
    );

    const changePhaseStatusOfCards = useCallback(
        (phaseOption: PHASE_OPTION) => {
            openChangeCardPhaseStatusModal({
                modalType: "PHASE_OPTION",
                changeOption: phaseOption,
                itemIds: selectedIdsEnabled,
                changeableCardCount: selectedIdsEnabled.length,
                selectedCardCount: allSelectedIdsLength,
                refreshCardList,
                cardDataById,
            });
        },
        [dispatch, showError, allSelectedIdsLength, selectedIdsEnabled, refreshCardList]
    );

    const changeActivationStatusOfCards = useCallback(
        (status: ACTIVATION_STATUS) => {
            openChangeActivationModal({
                modalType: "ACTIVATION_STATUS",
                changeOption: status,
                itemIds: selectedIdsEnabled,
                changeableCardCount: selectedIdsEnabled.length,
                selectedCardCount: allSelectedIdsLength,
                refreshCardList,
                cardDataById,
            });
        },
        [dispatch, showError, allSelectedIdsLength, selectedIdsEnabled, refreshCardList]
    );

    const changeDirectionsOfCards = useCallback(
        (direction: LEARNING_DIRECTION) => {
            openChangeCardDirectionModal({
                modalType: "LEARNING_DIRECTION",
                changeOption: direction,
                itemIds: selectedIdsActiveDirections,
                changeableCardCount: selectedIdsActiveDirections.length,
                selectedCardCount: allSelectedIdsLength,
                refreshCardList,
                cardDataById,
            });
        },
        [dispatch, showError, allSelectedIdsLength, selectedIdsActiveDirections, refreshCardList]
    );

    const moveCards = useCallback(async (): Promise<void> => {
        if (allSelectedIdsLength && selectedIdsModifiable.length) {
            openMoveCardModal({
                cardIds: selectedIdsModifiable,
                initialSubjectId: subjectId,
                containsProtectedCardIds: allSelectedIdsLength > selectedIdsModifiable.length,
                refreshCardList,
            });
        } else {
            showResponsePopUp("WARNING", t_cardsCantBeMoved);
        }
    }, [selectedIdsModifiable, subjectId, allSelectedIds, refreshCardList]);

    const editCard = useCallback(async (): Promise<void> => {
        if (!selectedIdsModifiable.length || selectedIdsModifiable.length > 1) {
            showResponsePopUp("WARNING", t_cardsCantBeEdited);
            return;
        }
        dispatch(addCardsActions.setCardSubjectId(subjectId));
        unitId && dispatch(addCardsActions.setCardUnitId(unitId));
        //only one card should be editable (first of list)
        const { ownerId, id } = selectedIdsModifiable[0];
        history.push(`/add/${ownerId}/${id}`);
    }, [dispatch, selectedIdsModifiable, unitId, subjectId, allSelectedIdsLength]);

    const deleteCards = useCallback(async (): Promise<void> => {
        if (!selectedIdsModifiable.length) {
            showResponsePopUp("WARNING", t_cardsCantBeDeleted);
            return;
        }
        if (selectedIdsModifiable) {
            openDeleteModal({
                contentType: "cards",
                itemIds: selectedIdsModifiable,
                deletableCardCount: selectedIdsModifiable.length,
                selectedSubjectId: subjectId,
                selectedCardCount: allSelectedIdsLength,
                refreshCardList,
            });
        }
    }, [selectedIdsModifiable, subjectId, allSelectedIdsLength, refreshCardList]);

    return (
        <CardActionButtons
            isSingleEntry={isSingleEntry}
            popUpsPosition={popUpsPosition}
            selectedCardIds={allSelectedIds}
            canSwap={canSwap}
            isActive={isActive}
            subjectId={subjectId}
            isDeactivated={isDeactivated}
            canBeModified={canBeModified}
            onPopUpClicked={onPopUpClicked}
            changePhaseOfCards={changePhaseStatusOfCards}
            changeActivationOfCards={changeActivationStatusOfCards}
            changeDirectionsOfCards={changeDirectionsOfCards}
            deleteCards={deleteCards}
            moveCards={moveCards}
            editCard={editCard}
            cardDataById={cardDataById}
        />
    );
};

export default memo(CardActionButtonsWrapper);
