// REACT
import * as React from "react";
import { useContext } from "react";

// STYLED COMPONENTS
import { IconContainer, StyledPhaseSixIcon } from "./styles";

// COMPONENTS
import SvgIcon from "../svgIcon/SvgIcon";
import { TIconNames } from "../phaseSixIcon/PhaseSixIcon";

// UTILS
import { ThemeContext } from "styled-components";

export interface MouseEventProps {
    icon?: TIconNames;
    svgIcon?: React.FC;
    isDisabled?: boolean;
    userDefinedIconColor?: string;
    isActive: boolean;
    className?: string;
    useSidebarColors?: boolean;
}

const MouseEventIcon: React.FC<MouseEventProps> = (props) => {
    const themeContext = useContext(ThemeContext);

    const baseColor = props.useSidebarColors ? themeContext.colors.textOnBackground : themeContext.colors.text;
    const activeColor = props.useSidebarColors ? themeContext.colors.textOnThemeImage : themeContext.colors.primary;
    const disabledColor = themeContext.colors.text + "50";

    let colorToUse = props.userDefinedIconColor
        ? props.userDefinedIconColor
        : props.isDisabled
        ? disabledColor
        : props.isActive
        ? activeColor
        : baseColor;
    let mainIcon = null;

    if (typeof props.icon != "undefined") {
        mainIcon = (
            <StyledPhaseSixIcon
                name={props.icon}
                color={colorToUse}
            />
        );
    } else if (typeof props.svgIcon != "undefined") {
        mainIcon = (
            <SvgIcon
                svgComponent={props.svgIcon}
                color={colorToUse}
            />
        );
    }

    return <IconContainer className={props.className}>{!!mainIcon ? mainIcon : "No icon passed"}</IconContainer>;
};

MouseEventIcon.defaultProps = {
    isActive: false,
    isDisabled: false,
};

export default MouseEventIcon; // TODO change props for icons in other usages then sidebar
