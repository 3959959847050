// REACT
import React, { ChangeEventHandler, FunctionComponent, memo } from "react";

// COMPONENTS
import Popup, { Props as PopupProps } from "../../../basic/popup/Popup";
import { Badge } from "../../../basic/badge";
import PhaseSixIcon from "../../../basic/phaseSixIcon/PhaseSixIcon";

// STYLED COMPONENTS
import {
    FilesP6Icon,
    FilesList,
    FileItem,
    DeleteIcon,
    Image,
    UploadWrapper,
    UploadButton,
    UploadInput,
} from "./styles";

const baseURL = process.env.REACT_APP_BACKEND_API_URL;

type TIdTypeDivider = ":";
export const IdTypeDivider: TIdTypeDivider = ":";

const FilesP6IconGen: (name: any) => FunctionComponent<Parameters<typeof FilesP6Icon>["0"]> = (name) => (props) =>
    (
        <FilesP6Icon
            name={name}
            extraStyle={{ fontSize: "30px" }}
            {...props}
        />
    );

const ImageIcon = FilesP6IconGen("pic");
const AudioIcon = FilesP6IconGen("speaker");
const GeneralIcon = FilesP6IconGen("file");

export type Props = {
    type?: "image" | "audio" | "all";
    files: string[];
    limit?: number;
    position?: PopupProps["position"];
    onDelete: (file: string) => void;
    onPreview: (file: string) => void;
    onUpload: ChangeEventHandler<HTMLInputElement>;
};

const Files: FunctionComponent<Props> = (props) => {
    const {
        type = "all",
        files = [],
        limit = Infinity,
        position = ["top", "center"],
        onDelete,
        onUpload,
        onPreview,
    } = props;

    const iconsRelation = {
        image: ImageIcon,
        audio: AudioIcon,
        all: GeneralIcon,
    };

    const Icon = iconsRelation[type];

    return (
        <Popup
            position={position}
            arrow
            content={
                <FilesList>
                    {files.length < limit && (
                        <FileItem>
                            <UploadWrapper>
                                <UploadInput
                                    id="upload-input"
                                    onChange={onUpload}
                                    type="file"
                                    accept={type === "all" ? undefined : `${type}/*`}
                                />
                                <UploadButton onClick={() => document.getElementById("upload-input")?.click()}>
                                    <PhaseSixIcon name="add" />
                                </UploadButton>
                            </UploadWrapper>
                        </FileItem>
                    )}
                    {files.map((file) => {
                        const [id, type] = file.split(IdTypeDivider);

                        const displayedIcon =
                            type === "image" ? (
                                <Image
                                    src={`${baseURL}media/${id}`}
                                    alt=""
                                />
                            ) : (
                                <PhaseSixIcon name={"speaker"} />
                            );

                        return (
                            <FileItem
                                key={id}
                                onClick={() => !!onPreview && onPreview(file)}
                            >
                                <DeleteIcon
                                    onClick={(e: any) => {
                                        e.stopPropagation();
                                        onDelete(file);
                                    }}
                                >
                                    <PhaseSixIcon name="close" />
                                </DeleteIcon>
                                {displayedIcon}
                            </FileItem>
                        );
                    })}
                </FilesList>
            }
        >
            {files.length ? (
                <Badge count={files.length}>
                    <Icon />
                </Badge>
            ) : (
                <Icon />
            )}
        </Popup>
    );
};

export default memo(Files);
