import Cookie from "universal-cookie";

export const getShowWelcomeScreenData = () => {
    const cookie = new Cookie();

    const showWelcomeScreen = cookie.get("showWelcomeScreen");

    const deleteWelcomeScreenCookie = () => {
        cookie.remove("showWelcomeScreen");
    };

    const addWelcomeScreenCookie = () => {
        cookie.set("showWelcomeScreen", true);
    };

    return { showWelcomeScreen, deleteWelcomeScreenCookie, addWelcomeScreenCookie };
};
