import styled from "styled-components";

export const InputContainer = styled.div<{ fixedWidth?: boolean }>`
    background: ${(props) => props.theme.colors.backgroundContrast};
    color: ${(props) => props.theme.colors.textLight};
    width: ${(props) => (props.fixedWidth ? "300px" : "100%")};
    height: 50px;
    transition: all 0.15s ease-in-out 0s;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    box-shadow: inset 0 2px 8px ${(props) => props.theme.colors.shadow};
    padding: 8px 16px;
    border-radius: 25px;

    &:hover {
        background: ${(props) => props.theme.colors.backgroundMain};
    }
`;

export const StyledInput = styled.input`
    background: none repeat scroll 0 0 rgba(0, 0, 0, 0);
    border: medium none;
    box-shadow: none;
    display: inline;
    flex: 1;
    padding: 3px 0 2px 10px;
    font-size: 16px;
    font-family: ${(props) => props.theme.base.fontFamily};
    color: ${(props) => props.theme.colors.textLight};
    transition: all 0.15s ease-in-out 0s;

    &:focus {
        outline: none;
    }
`;
