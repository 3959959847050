// LIBRARIES
import React, { useContext, useState } from "react";
import { ThemeContext } from "styled-components";
import { useT } from "@transifex/react";
import { useMediaQuery } from "react-responsive";
import { ampli } from "../../../ampli";

// MODELS
import { User, UserPremiumExpiration } from "p6m-user";

// COMPONENTS
import UserPremiumInformation from "../../basic/userPremiumInformation/UserPremiumInformation";
import UserMenuEntry, { UserMenuEntryProps } from "../../complex/userMenuEntry/UserMenuEntry";
import Popup from "../../basic/popup/Popup";
import {
    getBuyPremiumUrl,
    getImpressumUrl,
    getMeinKontoUrl,
    getSupportChatUrl,
    getSupportUrl,
} from "../../../helpers/Url";
import { GlobalModalView } from "../../../helpers/Modal";
import UserAvatar from "../../basic/userAvatar/UserAvatar";
import Tooltip from "../../basic/tooltip/Tooltip";

// STYLES
import {
    EmailNotConfirmedWrapper,
    FirstNameContainer,
    FocusedMenuEntry,
    LogoutSeparator,
    MobileContentWrapper,
    popupStyles,
    UserEmailWrapper,
    UserMenuContainer,
    UserMenuEntriesWrapper,
    UserNameAndEmailWrapper,
    UserNameContainer,
    UserNameWrapper,
} from "./styles";

export interface UserMenuProps {
    user: User;
    userPremiumExpiration: UserPremiumExpiration;
    userHasPremium: boolean;
    isMobileView?: boolean;
    userAvatar?: string;
    isUserStrict: boolean;
    hasBetaInvite: boolean;
    setModalView: (view: GlobalModalView) => any;
    switchVersion: () => any;
    logoutAction?: () => any;
    closeMobileMenu?: () => any;
}

type UserMenuWithClosePopupProps = UserMenuEntryProps & {
    onClickAction?: (showModal?: boolean) => void;
    disabled?: boolean;
    tooltipText?: string;
};

const EmailNotConfirmed: React.FC<{ onClick: () => any }> = (props) => {
    const t = useT();
    const t_emailConfirm = t("Confirm my email", { _tags: "userMenu,header" });
    return (
        <EmailNotConfirmedWrapper>
            <UserMenuEntry
                title={t_emailConfirm}
                key={"confirm"}
                modalTitle={"Confirm Email"}
                onClickAction={props.onClick}
            />
        </EmailNotConfirmedWrapper>
    );
};
const UserNameAndEmail: React.FC<{ user: User; onClick: () => any; isUserStrict: boolean }> = (props) => {
    const { isUserStrict } = props;
    const t = useT();

    const isUserUnconfirmed = props.user.roles && props.user.roles.includes("unconfirmed");
    const anonymousUser = t("Anonymous User", { _tags: "name, placeholder" });
    let userName = props.user.firstName + " " + props.user.lastName;
    if (!props.user.email || props.user.email.indexOf("@phase-6.org") > -1) {
        // is anonymous user
        userName = anonymousUser;
    }

    return (
        <UserNameAndEmailWrapper className={`${isUserUnconfirmed && !isUserStrict ? "no-padding-bottom" : ""}`}>
            <UserNameWrapper data-qa="user-menu-dropdown_user-name">{userName}</UserNameWrapper>
            <UserEmailWrapper data-qa="user-menu-dropdown_user-email">{props.user.email}</UserEmailWrapper>
            {isUserUnconfirmed && !isUserStrict && <EmailNotConfirmed onClick={props.onClick} />}
        </UserNameAndEmailWrapper>
    );
};

const MenuWrapper: React.FC<{ tooltipText?: string }> = ({ tooltipText, children }) => {
    return (
        <>
            {!tooltipText ? (
                children
            ) : (
                <Tooltip
                    content={tooltipText}
                    isBlock
                    position="left"
                >
                    {children}
                </Tooltip>
            )}
        </>
    );
};

const UserMenu: React.FC<UserMenuProps> = (props) => {
    const { closeMobileMenu, setModalView, userAvatar, isUserStrict, hasBetaInvite } = props;
    const t = useT();

    const t_accountManage = t("Manage my account", { _tags: "userMenu,header" });
    const t_settings = t("Preferences", { _tags: "userMenu,header" });
    const t_support = t("FAQ/Support", { _tags: "userMenu,header" });
    const t_supportChat = t("Support Chat", { _tags: "userMenu,header" });
    const t_imprint = t("Impressum", { _tags: "userMenu,header" });
    const t_recommend = t("Recommend to friend", { _tags: "userMenu,header" });
    const t_switchVersion = t("Switch version", { _tags: "userMenu,header" });
    const t_unlockPremium = t("Unlock Premium", { _tags: "userMenu,header" });
    const t_logout = t("Sign out", { _tags: "userMenu,header" });
    const t_dataProtection = t("Data protection", { _tags: "userMenu,header" });
    const t_strictUserDisabledOptionsText = t(
        "This functionality is locked because this account is connected to an institution",
        { _tags: "userMenu,header" }
    );

    const theme = useContext(ThemeContext);

    const isUserUnconfirmed = props.user.roles && props.user.roles.includes("unconfirmed");

    // Media query for the mobile rendering
    const lowerBorderQuery = "(max-width: " + process.env.REACT_APP_LOWER_GRID_STEP_BORDER + "px)";
    const isMobileView: Boolean = useMediaQuery({
        query: lowerBorderQuery,
    });

    const accountProps: UserMenuEntryProps = {
        title: t_accountManage,
        navTarget: getMeinKontoUrl(props.user.jossoSessionId),
        external: true,
    };
    if (isUserUnconfirmed && !isUserStrict) {
        accountProps.icon = "user-unconfirmed";
    } else {
        accountProps.icon = "user";
    }

    const userDoesntHavePremium = !(props.userHasPremium || props.userPremiumExpiration.isTrial);

    const logShareApp = () => {
        ampli.shareApp({
            app_screen: "learncenter",
        });
    };

    const PopupContent = (PopupActions?: any) => {
        const closePopup = !!PopupActions && !!PopupActions.closePopup ? PopupActions.closePopup : () => {};
        const isMobileView = !!PopupActions && !!PopupActions.isMobileView ? PopupActions.isMobileView : false;
        const UserMenuWithClosePopup = (props: UserMenuWithClosePopupProps) => {
            const { onClickAction, disabled = false, tooltipText } = props;

            return (
                <MenuWrapper tooltipText={tooltipText}>
                    <UserMenuEntry
                        {...props}
                        disabled={disabled}
                        onClickAction={(showModal?: boolean) => {
                            if (disabled) return;
                            if (!!showModal && isMobileView) {
                                closePopup();
                            }
                            if ((!props.modal || !showModal) && !!closePopup) closePopup();
                            onClickAction?.(showModal);
                        }}
                    />
                </MenuWrapper>
            );
        };
        return (
            <div data-qa="user-menu-drowpdown">
                {!isMobileView && (
                    <UserNameAndEmail
                        user={props.user}
                        isUserStrict={isUserStrict}
                        onClick={() => {
                            setModalView(GlobalModalView.MailAddressConfirmation);
                            closePopup();
                        }}
                    />
                )}
                <UserMenuEntriesWrapper data-qa="user-menu-dropdown_menu-list">
                    <UserMenuWithClosePopup
                        key={"account"}
                        tooltipText={isUserStrict ? t_strictUserDisabledOptionsText : undefined}
                        disabled={isUserStrict}
                        {...accountProps}
                    />
                    <UserMenuWithClosePopup
                        title={t_settings}
                        key={"settings"}
                        icon={"settings"}
                        headerComponent={false}
                        onClickAction={() => {
                            setModalView(GlobalModalView.Settings);
                            closePopup();
                        }}
                    />

                    {!!isUserStrict ? (
                        <>
                            <UserMenuWithClosePopup
                                title={t_supportChat}
                                key={"supportChat"}
                                navTarget={getSupportChatUrl()}
                                external
                                icon={"help"}
                            />
                            <UserMenuWithClosePopup
                                title={t_imprint}
                                key={"impressum"}
                                svgIcon={theme.icons.impressum}
                                onClickAction={() => {
                                    setModalView(GlobalModalView.StrictUserImpressumIframe);
                                    closePopup();
                                }}
                            />
                            <UserMenuWithClosePopup
                                title={t_dataProtection}
                                key={"dataProtection"}
                                icon={"data-protection-on"}
                                onClickAction={() => {
                                    setModalView(GlobalModalView.DataProtectionIframe);
                                    closePopup();
                                }}
                            />
                        </>
                    ) : (
                        <>
                            <UserMenuWithClosePopup
                                title={t_support}
                                key={"support"}
                                navTarget={getSupportUrl()}
                                external
                                icon={"help"}
                            />
                            <UserMenuWithClosePopup
                                title={t_imprint}
                                key={"impressum"}
                                navTarget={getImpressumUrl()}
                                external
                                svgIcon={theme.icons.impressum}
                            />
                        </>
                    )}

                    <UserMenuWithClosePopup
                        title={t_recommend}
                        key={"share"}
                        icon={"share"}
                        tooltipText={isUserStrict ? t_strictUserDisabledOptionsText : undefined}
                        disabled={isUserStrict}
                        onClickAction={() => {
                            setModalView(GlobalModalView.Share);
                            closePopup();
                            logShareApp();
                        }}
                    />
                    {userDoesntHavePremium && (
                        <FocusedMenuEntry key={"no-premium-container"}>
                            <UserMenuWithClosePopup
                                title={t_unlockPremium}
                                key="premium"
                                external
                                icon="plus"
                                userDefinedIconColor="white"
                                disableHover={true}
                                {...(isUserStrict
                                    ? {
                                          onClickAction: () => setModalView(GlobalModalView.StrictPremium),
                                      }
                                    : { navTarget: getBuyPremiumUrl() })}
                            />
                        </FocusedMenuEntry>
                    )}
                    {!isUserStrict && hasBetaInvite && (
                        <UserMenuWithClosePopup
                            title={t_switchVersion}
                            key={"switch"}
                            icon={"arrows-right-left"}
                            onClickAction={props.switchVersion}
                        />
                    )}
                </UserMenuEntriesWrapper>
                <LogoutSeparator />
                <UserMenuEntriesWrapper>
                    <UserMenuWithClosePopup
                        title={t_logout}
                        key={"logout"}
                        icon={"logout"}
                        onClickAction={props.logoutAction}
                    />
                </UserMenuEntriesWrapper>
            </div>
        );
    };

    const anonymousUser = t("Anonymous User", { _tags: "name, placeholder" });
    let userName = props.user.firstName;
    if (!props.user.email || props.user.email.indexOf("@phase-6.org") > -1 || userName === "") {
        // is anonymous user or doesn't have first or last name
        userName = anonymousUser;
    }

    const [isMobileViewOpen, setIsMobileViewOpen] = useState(false);
    if (isMobileView) {
        return (
            <MobileContentWrapper>
                <UserMenuContainer
                    className={`${isMobileView ? "reverse-direction" : ""}`}
                    onClick={() => setIsMobileViewOpen(!isMobileViewOpen)}
                >
                    <UserNameContainer className={`${isMobileView ? "push-to-left" : ""}`}>
                        <FirstNameContainer>{userName}</FirstNameContainer>
                        <UserPremiumInformation
                            userHasPremium={props.userHasPremium}
                            isTrialAccount={props.userPremiumExpiration.isTrial}
                            premiumExpirationTimestamp={props.userPremiumExpiration.expirationDate}
                        />
                    </UserNameContainer>
                    <UserAvatar
                        userAvatar={userAvatar}
                        size={"medium"}
                        isOnBackground
                    />
                </UserMenuContainer>
                {isMobileViewOpen && isUserUnconfirmed && !isUserStrict && (
                    <EmailNotConfirmed
                        onClick={() => {
                            setModalView(GlobalModalView.MailAddressConfirmation);
                            closeMobileMenu && closeMobileMenu();
                        }}
                    />
                )}
                {isMobileViewOpen && (
                    <PopupContent
                        closePopup={closeMobileMenu}
                        isMobileView={true}
                    />
                )}
            </MobileContentWrapper>
        );
    }

    return (
        <Popup
            content={PopupContent}
            position={["bottom", "right"]}
            style={popupStyles}
        >
            <UserMenuContainer
                data-qa="user-menu-button"
                className={`${isMobileView ? "reverse-direction" : ""}`}
            >
                <UserNameContainer className={`${isMobileView ? "push-to-left" : ""}`}>
                    <FirstNameContainer>{userName}</FirstNameContainer>
                    <UserPremiumInformation
                        userHasPremium={props.userHasPremium}
                        isTrialAccount={props.userPremiumExpiration.isTrial}
                        premiumExpirationTimestamp={props.userPremiumExpiration.expirationDate}
                    />
                </UserNameContainer>
                <UserAvatar
                    userAvatar={userAvatar}
                    size={"medium"}
                    isOnBackground
                />
            </UserMenuContainer>
        </Popup>
    );
};

UserMenu.defaultProps = {
    isMobileView: false,
};

export default UserMenu;
