import styled from "styled-components";

export const InviteChildContainer = styled.div`
    width: 100%;
    height: 100%;
    padding: ${(props) => props.theme.base.spacing};
    padding: ${(props) => props.theme.base.spacing};
    display: flex;
    justify-content: center;
    background: ${(props) => props.theme.colors.backgroundContrast};
`;
export const InviteChildIcon = styled.div`
    display: flex;
    height: fit-content;
    justify-content: center;
    padding: ${(props) => props.theme.base.spacing};
    margin: ${(props) => props.theme.base.spacing};
    background: ${(props) => props.theme.colors.backgroundMain};
    border-radius: 50%;
`;
export const InviteChildText = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: ${(props) => props.theme.base.spacing};
    color: ${(props) => props.theme.colors.text};
`;
export const InviteChildHeader = styled.p`
    margin-bottom: ${(props) => props.theme.base.spacing};
    color: ${(props) => props.theme.colors.primary};
    font-size: ${(props) => props.theme.base.titleBigSize};
    font-weight: ${(props) => props.theme.base.bigWeight};
`;
export const InviteChildBody = styled.div``;
export const BenefitsList = styled.ul`
    list-style-type: none;
    padding: 0;
`;
export const BenefitItem = styled.li`
    align-items: center;
    display: flex;
    flex-direction: row;
`;
export const Benefit = styled.span``;
export const InstructionsContainer = styled.div`
    width: 50%;
    padding: ${(props) => props.theme.base.spacing};
    border-radius: ${(props) => props.theme.base.borderRadius};
    background: ${(props) => props.theme.colors.backgroundMain};
`;
export const InstructionsHeader = styled.p`
    margin-bottom: ${(props) => props.theme.base.spacing};
    font-size: ${(props) => props.theme.base.titleBigSize};
    font-weight: ${(props) => props.theme.base.bigWeight};
`;
export const InstructionsBody = styled.div``;
export const InstructionsList = styled.ol``;
export const InstructionItem = styled.li``;
export const InviteChildButton = styled.a`
    border-radius: ${(props) => props.theme.base.borderRadiusSmall};
    background-color: ${(props) => props.theme.colors.primary};
    margin-top: ${(props) => props.theme.base.spacing};
    padding: ${(props) => props.theme.base.spacingSmall};
    align-self: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: ${(props) => props.theme.colors.backgroundContrast};
    font-size: ${(props) => props.theme.base.bigSize};
    cursor: pointer;
    text-decoration: none;
    transition: all 0.25s;

    &:hover {
        opacity: 0.7;
    }
`;
