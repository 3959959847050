// Dependencies
import React from "react";

//UTILS
import { toDDMMYY } from "../../../helpers/timeFormat";
import { calculatePercentage } from "../../../helpers/calculatePercentage";

// Components
import PhaseSixIcon from "../../../components/basic/phaseSixIcon/PhaseSixIcon";

//TYPES
import { TestResultDetails } from "../../../networking/tests";

// Styles
import { TestListItemContainer, LastElementOfListItem } from "../styles";

interface TestResultListItemProps extends React.HTMLAttributes<HTMLDivElement> {
    resultDetails: TestResultDetails;
}

export const TestResultListItem = ({ resultDetails, ...rest }: TestResultListItemProps) => {
    const { modificationDate, cardsAnsweredRight, totalCardsInTest } = resultDetails;

    return (
        <TestListItemContainer
            {...rest}
            style={{ paddingRight: 8, paddingLeft: 8 }}
        >
            <PhaseSixIcon
                size="standard"
                name="checked"
            />
            <span>{toDDMMYY(modificationDate, { isFullYear: true })}</span>
            <LastElementOfListItem>
                <span>{`${calculatePercentage({
                    totalAmount: totalCardsInTest,
                    fractionAmount: cardsAnsweredRight,
                })}%`}</span>
            </LastElementOfListItem>
        </TestListItemContainer>
    );
};
