import { all, fork } from "redux-saga/effects";
import { userSaga } from "./user/userSaga";
import { loginSaga } from "./login/loginSaga";
import { goalsSaga } from "./goals/goalsSaga";
import { subjectsSaga } from "./subjects/subjectsSaga";
import { practiceSaga } from "./practice/practiceSaga";
import { activationSaga } from "./activation/activationSaga";
import { manageSaga } from "./manage/manageSaga";
import { approveSaga } from "./approve/approveSaga";
import { gamesSaga } from "./games/gamesSaga";
import { avatarsSaga } from "./avatars/avatarsSaga";
import { warningsSaga } from "./warnings/warningsSaga";
import { addSaga } from "./add/addSaga";
import { learningSaga } from "./learning/learningSaga";
import { themeSaga } from "./theme/themeSaga";
import { notificationsSaga } from "./notifications/notificationsSaga";
import { testsSaga } from "./tests/testsSaga";
import { librarySaga } from "./library/librarySaga";

export default function* rootSaga() {
    yield all([
        fork(userSaga),
        fork(loginSaga),
        fork(notificationsSaga),
        fork(goalsSaga),
        fork(subjectsSaga),
        fork(practiceSaga),
        fork(activationSaga),
        fork(manageSaga),
        fork(approveSaga),
        fork(gamesSaga),
        fork(avatarsSaga),
        fork(warningsSaga),
        fork(addSaga),
        fork(learningSaga),
        fork(themeSaga),
        fork(testsSaga),
        fork(librarySaga),
    ]);
}
