import styled from "styled-components";

export const ExampleContainer = styled.button<{ active: boolean; border: boolean; multipleExamples: boolean }>`
    padding-left: ${(props) => props.theme.base.spacing};
    padding-right: ${(props) => props.theme.base.spacing};
    display: flex;
    flex-direction: row;
    align-items: center;
    border: none;
    cursor: pointer;
    padding-top: ${(props) => props.theme.base.spacing};
    padding-bottom: ${(props) => props.theme.base.spacing};
    background-color: ${(props) =>
        props.active ? props.theme.colors.backgroundContrast : props.theme.colors.backgroundContrast};
    /* only set border-left when entry contains children and show right border-left color when entry is active */
    border-left: 5px solid
        ${(props) =>
            props.border && props.multipleExamples
                ? "#cccccc"
                : props.active
                ? props.theme.colors.backgroundContrast
                : props.theme.colors.backgroundContrast};
    &:hover {
        background-color: ${(props) => props.theme.colors.backgroundContrast};
        border-color: ${(props) =>
            props.border && props.multipleExamples ? "#cccccc" : props.theme.colors.backgroundContrast};
    }
`;

export const Word = styled.span<{ active: boolean }>`
    display: flex;
    font-family: ${(props) => props.theme.base.fontFamily};
    color: ${(props) => (props.active ? props.theme.colors.primary : props.theme.colors.text)};
    font-size: ${(props) => props.theme.base.bigSize};
    flex: 2;
`;

export const ExampleSource = styled.span<{ active: boolean }>`
    display: flex;
    align-items: baseline;
    font-family: ${(props) => props.theme.base.fontFamily};
    color: ${(props) => (props.active ? props.theme.colors.primary : props.theme.colors.text)};
    font-size: ${(props) => props.theme.base.bigSize};
    flex: 4;
    white-space: pre-wrap;
`;

export const ArrowContainer = styled.div`
    display: flex;
    color: ${(props) => props.theme.colors.text};
    font-size: ${(props) => props.theme.base.bigSize};
    padding-left: ${(props) => props.theme.base.spacing};
    padding-right: ${(props) => props.theme.base.spacing};
    align-items: center;
    justify-content: center;
`;

export const ExampleTarget = styled.span<{ active: boolean }>`
    display: flex;
    justify-content: flex-start;
    font-family: ${(props) => props.theme.base.fontFamily};
    color: ${(props) => (props.active ? props.theme.colors.primary : props.theme.colors.text)};
    font-size: ${(props) => props.theme.base.bigSize};
    font-weight: ${(props) => props.theme.base.bigWeight};
    flex: 4;
`;

export const ArrowRightContainer = styled.div`
    display: flex;
    color: ${(props) => props.theme.colors.text};
    font-size: ${(props) => props.theme.base.bigSize};
    padding-left: ${(props) => props.theme.base.spacingSmall};
    padding-right: ${(props) => props.theme.base.spacingSmall};
    width: 50px;
    align-items: center;
    justify-content: center;
`;
