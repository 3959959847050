//DEPS
import React, { memo, forwardRef } from "react";
import Loading from "../../connected/loading/Loading";

//STYLES
import { Background, StyledIframe, LoadingBox } from "./styles";

//COMPONENTS
import { DefaultHeader } from "../../basic/modal/DefaultHeader";

export interface ShareModalProps {
    url: string;
    isIframeLoading: boolean;
    close: () => any;
}

const DataProtectionModal = forwardRef<HTMLIFrameElement, ShareModalProps>((props, ref) => {
    const { url, isIframeLoading, close } = props;

    return (
        <Background>
            <DefaultHeader
                title=""
                close={close}
            />
            {isIframeLoading && (
                <LoadingBox>
                    <Loading />
                </LoadingBox>
            )}
            <StyledIframe
                src={url}
                ref={ref}
            />
        </Background>
    );
});

export default memo(DataProtectionModal);
