import styled from "styled-components";
import ReactQuill from "react-quill";

export const Editor = styled(ReactQuill)`
    flex: 1;
    min-width: 400px;
    word-break: break-all;

    .ql-toolbar {
        border: none;
        padding: ${({ theme: { base } }) => base.spacingInContent} 0;
        margin-bottom: ${({ theme: { base } }) => base.spacingSmall};

        .ql-picker-label {
            border: none;
            padding: 0;
        }

        button,
        .ql-color-picker {
            display: flex;
            align-items: center;
            justify-content: center;
            background: none;
            cursor: pointer;
            float: left;
            height: 30px;
            width: 38px;
            margin-right: 10px;
            border: none;
            border-radius: 0.5rem;
            line-height: 30px;

            .ql-picker-label {
                display: flex;
                align-items: center;
                justify-content: center;
            }

            svg {
                height: 85%;
                stroke: ${({ theme: { colors } }) => colors.text} !important;

                > * {
                    stroke: ${({ theme: { colors } }) => colors.text} !important;
                }
            }

            &:hover {
                stroke: ${({ theme: { colors } }) => colors.text} !important;
                color: ${({ theme: { colors } }) => colors.text} !important;
                background: ${({ theme: { colors } }) => colors.backgroundMain} !important;
            }
        }
    }

    .ql-container {
        height: calc(100vh - 342px);
        border: none;
        font-family: ${({ theme: { base } }) => base.fontFamily};

        .ql-editor {
            border: 1px solid ${({ theme: { colors } }) => colors.backgroundMain};
            border-radius: ${({ theme: { base } }) => base.borderRadius};
            padding: ${({ theme: { base } }) => base.spacing};
            font-size: inherit;
            background-color: ${({ theme: { colors } }) => colors.backgroundMain};
            box-shadow: inset 0 2px 8px ${({ theme: { colors } }) => colors.shadow};
        }
    }

    p,
    .ql-editor::before {
        text-align: center;
        color: ${(props) => props.theme.colors.text};
        font-size: ${(props) => props.theme.base.cardPracticeTextSize};
        word-break: break-word;
    }
`;

export const EditorContainer = styled.div`
    flex: 1;
    position: relative;
`;
export const MediaContainer = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    padding: ${(props) => props.theme.base.spacingSmall};
    height: 32px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: center;
`;

export const MediaItem = styled.div`
    margin-left: ${(props) => props.theme.base.spacing};
    font-size: ${({ theme: { base } }) => base.titleSize};
    color: ${({ theme: { colors } }) => colors.text};
    cursor: pointer;

    &:hover {
        color: ${(props) => props.theme.colors.primary};
    }
`;

export const EditorFooter = styled.div`
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 2;
    width: 100%;
    height: 70px;
    padding: 0;
`;

export const AnnotationWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    position: absolute;
    left: ${({ theme: { base } }) => base.spacing};
    right: 75px;
    z-index: 2;
    height: 100%;
`;

export const AnnotationIconWrapper = styled.div`
    padding-top: 4px;
    font-size: 36px;
    color: ${({ theme: { colors } }) => colors.text};
`;

export const AnnotationTextarea = styled.textarea`
    width: 100%;
    height: 100%;
    padding: ${({ theme: { base } }) => base.spacing};
    padding-bottom: 0;
    overflow-y: auto;
    resize: none;
    border: none;
    background: none;
    outline: none !important;
    font-size: ${({ theme: { base } }) => base.mediumSize};
    font-family: ${(props) => props.theme.base.fontFamily};
    color: ${({ theme: { colors } }) => colors.text};
`;

export const CharsWrapper = styled.div`
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: 3;
    width: 100%;
    height: 0;

    > div {
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 0;

        > div:first-child {
            height: 0;

            > span {
                position: absolute;
                right: ${({ theme: { base } }) => base.spacing};
                bottom: 20px;
            }
        }

        > div:last-child:not(:first-child) {
            width: 100%;
        }
    }
`;
