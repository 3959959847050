//DEPENDENCIES
import React, { FunctionComponent, useState } from "react";
import { T, useT } from "@transifex/react";
import { Link, useHistory } from "react-router-dom";

//COMPONENTS
import { Container, Row, Col } from "../../../../components/basic/grid";
import SubjectItem from "../../../../components/complex/wordMatchGame/SubjectItem/SubjectItem";
import PhaseSixIcon from "../../../../components/basic/phaseSixIcon/PhaseSixIcon";

//TYPES
import { WordMatchGameType } from "../WordMatchGame";

//STYLES
import { Title, SecondaryTitle, BackIconContainer } from "../../styles";
import { WordMatchBG, AddContentBox, StyledPhaseSixIcon, FitContentCol, BeforeGameStartCounter } from "./styles";

type Props = {
    subjects: {
        id: string;
        image: string;
        name: string;
        time: number;
        hasActiveCards: boolean;
    }[];
    beforeStartGame: (id: string, type: WordMatchGameType) => void;
    showModal: () => void;
};

const WordMatchList: FunctionComponent<Props> = (props) => {
    const { subjects, beforeStartGame, showModal } = props;
    const [start, setStart] = useState(false);
    const history = useHistory();
    const t = useT();

    const t_getReady = t("Get Ready!", {});

    function startGame(id: string, type: WordMatchGameType = WordMatchGameType.explore) {
        setStart(true);
        beforeStartGame(id, type);
        setTimeout(() => {
            history.push(`/games/wordMatch/${id}/${type}`);
        }, 3000);
    }

    return (
        <Container fullWidth>
            <WordMatchBG>
                <Row vertical="center">
                    <Col xs="auto">
                        <BackIconContainer>
                            <Link to="/games">
                                <PhaseSixIcon name="arrow-left" />
                            </Link>
                        </BackIconContainer>
                    </Col>
                    <Col xs="auto">
                        <Title>
                            <T _str="Word Match" />
                        </Title>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <SecondaryTitle>
                            <T _str="Select a subject to start the game." />
                        </SecondaryTitle>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <Row cols={{ xs: 1, s: 2, m: 3, xl: 4 }}>
                            {subjects.map(({ ...props }) => (
                                <FitContentCol key={props.id}>
                                    <SubjectItem
                                        {...props}
                                        startNormal={(id: string) => startGame(id, WordMatchGameType.normal)}
                                        startExplorer={(id: string) => startGame(id)}
                                    />
                                </FitContentCol>
                            ))}
                            <FitContentCol>
                                <AddContentBox onClick={showModal}>
                                    <StyledPhaseSixIcon
                                        name="add"
                                        size={"big"}
                                    />
                                    <T _str="Add content for testing" />
                                </AddContentBox>
                            </FitContentCol>
                        </Row>
                    </Col>
                </Row>
                {start && <BeforeGameStartCounter text={t_getReady} />}
            </WordMatchBG>
        </Container>
    );
};

export default WordMatchList;
