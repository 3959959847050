// LIBRARIES
import React from "react";
import { useSelector } from "react-redux";

// REDUX
import { selectors as userSelectors } from "../../../redux/user/userSlice";

// COMPONENTS
import LogoutIframe from "./LogoutIframe";

const LogoutIframeView: React.FC = () => {
    const renderLogoutIframe = useSelector(userSelectors.renderLogoutIframe);

    return renderLogoutIframe ? <LogoutIframe /> : null;
};

export default LogoutIframeView;
