import React, { FunctionComponent } from "react";
import { GoalsDataEntry } from "p6m-goalsData";
import { Wrapper, LinkWrapper, Icon, Title, GoalsDescription } from "./styles";
import PhaseSixIcon from "../../../components/basic/phaseSixIcon/PhaseSixIcon";

const ItemWrapper: FunctionComponent<any> = ({ link, onClick, children, className }) =>
    link ? (
        <LinkWrapper
            to={link}
            className={className}
        >
            {children}
        </LinkWrapper>
    ) : (
        <Wrapper
            className={className}
            onClick={onClick ? onClick : undefined}
        >
            {children}
        </Wrapper>
    );

type AchievementItemProps = GoalsDataEntry & { achieved: boolean };

const AchievementItem: React.FC<AchievementItemProps> = (props): any => {
    const { icon, title, achieved, additionalInfoDone, additionalInfoDue } = props;
    const description = achieved ? additionalInfoDone : additionalInfoDue;
    const iconComponent = (
        <PhaseSixIcon
            name={achieved ? icon : "achievement-task"}
            highlighted={achieved}
            size={"big"}
        />
    );
    return (
        <ItemWrapper
            {...props}
            className={achieved ? "achieved" : ""}
        >
            <Icon data-qa="achievement-icon">{iconComponent}</Icon>
            <Title data-qa="achievement-title">{title}</Title>
            <GoalsDescription data-qa="achievement-type">{description}</GoalsDescription>
        </ItemWrapper>
    );
};

export default AchievementItem;
