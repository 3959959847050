import styled from "styled-components";
import PhaseSixIcon from "../../../components/basic/phaseSixIcon/PhaseSixIcon";

export const StyledActions = styled.ul`
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    gap: ${(props) => props.theme.base.spacing};
`;

export const Entry = styled.li`
    margin: 0;
`;
export const EntryLink = styled.a<{ disabled?: boolean }>`
    display: flex;
    flex: 1;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
    cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
    color: ${(props) => (props.disabled ? props.theme.colors.textLight : props.theme.colors.text)};
    text-decoration: none;

    &:hover {
        color: ${(props) => (props.disabled ? props.theme.colors.textLight : props.theme.colors.primary)};
    }
`;
export const EntryText = styled.span`
    position: relative;
    flex: 1;
    margin-left: ${(props) => props.theme.base.spacingSmall};
    white-space: nowrap;
`;

export const EntryIcon = styled(PhaseSixIcon)`
    color: inherit;
`;
