import styled from "styled-components";
import PhaseSixIcon from "../phaseSixIcon/PhaseSixIcon";
import { TAvatarSize } from "./UserAvatar";

type TAvatarSizeProp = {
    standard: string;
    medium: string;
    big: string;
};

const avatarSizes: TAvatarSizeProp = {
    standard: "50px",
    medium: "80px",
    big: "120px",
};

export const UserAvatarContainer = styled.div<{ size: TAvatarSize; isOnBackground: boolean; clickable: boolean }>`
    position: relative;
    text-align: center;
    width: ${(props) => avatarSizes[props.size]};
    height: ${(props) => avatarSizes[props.size]};
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: end;
    cursor: ${(props) => (props.clickable ? "pointer" : "inherit")};

    &:before {
        content: "";
        position: absolute;
        left: 0;
        bottom: 0;
        z-index: 1;
        height: 0;
        width: 100%;
        padding-bottom: 100%;
        border-radius: 50%;
        background-color: ${({ isOnBackground, theme: { colors } }) =>
            isOnBackground ? colors.backgroundContrast : colors.backgroundMain};
    }
`;

export const UserAvatarIcon = styled(PhaseSixIcon)`
    z-index: 2;
    display: flex;
    align-self: center;
`;

export const UserAvatarImage = styled.img`
    display: block;
    width: 100%;
    position: relative;
    z-index: 2;
`;
