import axios, { AxiosPromise } from "axios";

//TYPES
import { IResponse } from "p6m-response";
import { SubjectUnitCard } from "p6m-subjects";

type CompareData = {
    correctAnswerText: string;
    forceWrong?: boolean;
    forceRight?: boolean;
    learnDirection: "NORMAL" | "OPPOSITE";
    phaseBeforeAnswering: number;
    userAnswerText: string;
};

type SubmitAnswerData = {
    answerCorrect: boolean;
    answerText: string;
    learnDirection: "NORMAL" | "OPPOSITE";
    phaseBeforeAnswering: number;
    type: "NORMAL" | "NO_TYPE_RIGHT" | "NO_TYPE_WRONG" | "I_WAS_RIGHT" | "I_WAS_WRONG";
    sessionId: string;
};

type CompareProps<T> = {
    ownerId: string;
    cardId: string;
    data: T;
};

type ActivationData = {
    activate: boolean;
    direction: "NORMAL" | "OPPOSITE";
    cards: string[];
    subjectId: string;
};

export function compareAnswer({
    ownerId,
    cardId,
    data,
}: CompareProps<CompareData>): AxiosPromise<IResponse<{ isCorrect: boolean; phase: number }>> {
    return axios.post(`/${ownerId}/cards/${cardId}/compareAnswer`, data);
}

export function compareAnswerNoTyping({
    ownerId,
    cardId,
    data,
}: CompareProps<CompareData>): AxiosPromise<IResponse<{ isCorrect: boolean; phase: number }>> {
    return axios.post(`/${ownerId}/cards/${cardId}/compareAnswerNoTyping`, data);
}

export function submitAnswer({ ownerId, cardId, data }: CompareProps<SubmitAnswerData>): AxiosPromise<IResponse<any>> {
    return axios.post(`/${ownerId}/cards/${cardId}/submitAnswer`, data);
}

export function cardActivationFiltered(data: ActivationData): AxiosPromise<IResponse<{ cards: SubjectUnitCard[] }>> {
    const { activate, direction, cards, subjectId } = data;
    return axios.post(`/cardActivationFiltered`, {
        cardActivationData: {
            activate,
            direction,
        },
        cards,
        subjectId,
    });
}

export type StatisticData = {
    cardId: string[];
    Type:
        | "CORRECT_ANSWER"
        | "INCORRECT_ANSWER"
        | "CARD_ACTIVATED"
        | "PREPARE_CORRECT_ANSWER"
        | "PREPARE_INCORRECT_ANSWER";
    typedLetters: number;
};

export function updateStatistic(data: StatisticData): AxiosPromise<IResponse<any>> {
    return axios.post(`/updateStatistic`, data);
}

export function sendFeedback(data: {
    article_uuid: string;
    content_set_uuid: string;
    content_uuid: string;
    question: string;
    answer: string;
    user_answer: string;
    user_message: string;
}): AxiosPromise<IResponse<any>> {
    return axios.post("contentFeedback", data);
}

type PracticeCardData = {
    direction: "NORMAL" | "OPPOSITE";
    sessionId: string;
    type: "PRACTICE" | "PREPARE_FOR_TEST";
};

export function practiceCard({ ownerId, cardId, data }: CompareProps<PracticeCardData>): AxiosPromise<IResponse<any>> {
    return axios.post(`/${ownerId}/cards/${cardId}/practiced`, data);
}
