// LIBRARIES
import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

// REDUX
import { selectors as themeSelectors, actions as themeActions } from "../redux/theme/themeSlice";
import { selectors as userSelectors, actions as userActions } from "../redux/user/userSlice";
import { actions as modalActions } from "../redux/modal/modalSlice";
import { selectors as testsSelectors } from "../redux/tests/testsSlice";

// COMPONENTS
import Layout from "./Layout";
import MobileAppAdvertisement from "./mobileAppAdvertisement/MobileAppAdvertisement";

//TYPES
import { StyleTheme } from "p6m-themes";
import { GlobalModalView } from "../helpers/Modal";
import { Theme } from "../themes/constants";

//UTILS && ASSETS
import orangeBackgroundImage from "../assets/orangeTheme/orange-bg.jpeg";
import pinkBackgroundImage from "../assets/pinkTheme/pink-bg.jpeg";
import blueBackgroundImage from "../assets/blueTheme/blue-bg.jpeg";
import { getMobileOS } from "../helpers/UserAgent";
import { getShowWelcomeScreenData } from "../helpers/GetShowWelcomeScreen";

const LayoutWrapper: React.FC = () => {
    const dispatch = useDispatch();
    const { showWelcomeScreen, deleteWelcomeScreenCookie } = getShowWelcomeScreenData();
    const { jossoSessionId } = useSelector(userSelectors.user);
    const activeTheme: StyleTheme = useSelector(themeSelectors.theme);
    const themeName: Theme = useSelector(themeSelectors.themeName);
    const { lang = "de" } = useSelector(userSelectors.userPreferences);
    const sharedTestMetaData = useSelector(testsSelectors.sharedTestMetaData);

    const os = getMobileOS();

    useEffect(() => {
        dispatch(userActions.removeLogoutIframe());
    }, [dispatch]);

    useEffect(() => {
        const langToUse = lang.split("_")[0];
        const htmlElement = document.querySelector("html");
        htmlElement?.setAttribute("lang", langToUse);
    }, [lang]);

    useEffect(() => {
        dispatch(themeActions.setTheme(themeName));
    }, [dispatch, themeName]);

    useEffect(() => {
        const userIsLoggedIn = !!jossoSessionId;
        if (userIsLoggedIn && showWelcomeScreen && !sharedTestMetaData) {
            dispatch(modalActions.setModalView(GlobalModalView.Welcome));
            deleteWelcomeScreenCookie();
        }
    }, [dispatch, sharedTestMetaData, showWelcomeScreen, jossoSessionId, deleteWelcomeScreenCookie]);

    let backgroundImage;
    switch (themeName) {
        case Theme.ORANGE:
            backgroundImage = orangeBackgroundImage;
            break;
        case Theme.PINK:
            backgroundImage = pinkBackgroundImage;
            break;
        case Theme.BLUE:
            backgroundImage = blueBackgroundImage;
            break;
        default:
            break;
    }

    if (os) {
        return (
            <MobileAppAdvertisement
                theme={activeTheme}
                os={os}
            />
        );
    }

    return (
        <Layout
            theme={activeTheme}
            backgroundImage={backgroundImage}
        />
    );
};

export default LayoutWrapper;
