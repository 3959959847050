import { ACTIVATION_STATUS, LEARNING_DIRECTION, PHASE_OPTION } from "p6m-cards";

export const LearningDirectionElement = ["FORWARD", "BACKWARD", "BOTH"] as const;
export function isLearningDirection(optionKey: string): optionKey is LEARNING_DIRECTION {
    return LearningDirectionElement.includes(optionKey as LEARNING_DIRECTION);
}

export const ActivationElement = ["DEACTIVATE", "REACTIVATE"] as const;
export function isActivationElement(optionKey: string): optionKey is ACTIVATION_STATUS {
    return ActivationElement.includes(optionKey as ACTIVATION_STATUS);
}

export const PhaseOptionElement = ["RELEARN", "LEARNED"] as const;
export function isPhaseOption(optionKey: string): optionKey is PHASE_OPTION {
    return PhaseOptionElement.includes(optionKey as PHASE_OPTION);
}
