// DEPS
import React, { FunctionComponent } from "react";

// COMPONENTS
import Component from "./TopNavigationCentralMenu";

// HOOKS
import { useFirstPracticeResult } from "../../../hooks/useFirstPracticeResult";

export const TopNavigationCentralMenu: FunctionComponent = () => {
    const hasFirstPractice = useFirstPracticeResult();

    return <Component hasFirstPractice={hasFirstPractice} />;
};
