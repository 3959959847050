import React, { FunctionComponent, HTMLAttributes } from "react";
import FocusLock from "react-focus-lock";
import * as Styled from "./styles";

export interface ModalProps extends HTMLAttributes<HTMLDivElement> {
    close?: () => void;
    width?: number;
}

const GlobalModal: FunctionComponent<ModalProps> = ({ children, width, close, ...rest }) => {
    return (
        <FocusLock autoFocus={false}>
            <Styled.FixedWrapper {...rest}>
                <Styled.ModalWrapper>
                    <Styled.Background onClick={close} />
                    <Styled.ModalBody
                        role="dialog"
                        width={width}
                    >
                        <Styled.ModalContent>{children}</Styled.ModalContent>
                    </Styled.ModalBody>
                </Styled.ModalWrapper>
            </Styled.FixedWrapper>
        </FocusLock>
    );
};

export default GlobalModal;
