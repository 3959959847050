// REACT
import React, { FunctionComponent, memo } from "react";
import { useT } from "@transifex/react";

// COMPONENTS
import Tooltip from "../../../basic/tooltip/Tooltip";
import PhaseSixIcon from "../../../basic/phaseSixIcon/PhaseSixIcon";

// STYLED COMPONENTS
import { Microphone, Record, RedPhaseSixIcon, TooltipText } from "./styles";

const MicIcon: FunctionComponent<Parameters<typeof Microphone>[0]> = (props) => (
    <Microphone {...props}>
        <PhaseSixIcon name="mic" />
    </Microphone>
);
const RecordIcon: FunctionComponent<Parameters<typeof Record>[0]> = (props) => (
    <Record {...props}>
        <RedPhaseSixIcon name="stop" />
    </Record>
);

type Props = {
    canRecord: boolean;
    isRecording: boolean;
    hasPermission: boolean;
    onStart: () => void;
    onStop: () => void;
};

const RecordAudio: FunctionComponent<Props> = (props) => {
    const { isRecording, canRecord, hasPermission, onStart, onStop } = props;
    const t = useT();

    const t_permission = t("To record something it is necessary to give us permission using your microphone.", {});

    const microphoneWithTooltip = hasPermission ? (
        <MicIcon
            disabled={!canRecord}
            onClick={onStart}
        />
    ) : (
        <Tooltip content={<TooltipText>{t_permission}</TooltipText>}>
            <MicIcon
                disabled={true}
                onClick={onStart}
            />
        </Tooltip>
    );

    return isRecording ? <RecordIcon onClick={onStop} /> : microphoneWithTooltip;
};

export default memo(RecordAudio);
