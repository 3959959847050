// REACT
import React, { useContext } from "react";
import { useT } from "@transifex/react";

// STYLED COMPONENTS
import styled, { ThemeContext } from "styled-components";

// TYPES
import { FamilyMember } from "p6m-user";

// COMPONENTS
import SettingsMainStyledCTA from "../settingsMainStyledCTA/SettingsMainStyledCTA";

export interface SettingsFamilyViewProps {
    openChildrenSettings: (children: number) => any;
    childrenList?: Array<FamilyMember>;
}

const MainWrapper = styled.div`
    padding-top: 1em;
`;

const SettingsFamilyView: React.FC<SettingsFamilyViewProps> = (props) => {
    const t = useT();

    const t_noChildren = t("It's empty here. Add children in Mein Konto", { _tags: "settings,family" });

    const theme = useContext(ThemeContext);

    function callOpenChildrenSettings(children: number) {
        props.openChildrenSettings(children);
    }

    function renderChildrenList() {
        if (!props.childrenList || props.childrenList.length === 0) {
            return <p>{t_noChildren}</p>;
        }

        return props.childrenList.map((c, i) => (
            <SettingsMainStyledCTA
                title={c.name ? c.name : ""}
                svgIcon={theme.icons.defaultAvatar}
                subtitle={c.email ? c.email : undefined}
                onClickAction={() => callOpenChildrenSettings(i)}
                key={i}
            />
        ));
    }

    return <MainWrapper>{renderChildrenList()}</MainWrapper>;
};

export default SettingsFamilyView;
