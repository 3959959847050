// LIBRARIES
import React from "react";
import { SubjectData, SubjectActions, SubjectAssignmentModal as TSubjectAssignmentModal } from "p6m-subjects";

// COMPONENTS
import UserSubjectsList from "../../components/complex/userSubjectsList/UserSubjectsList";
import { T } from "@transifex/react";
import SubjectAssignmentModal from "../../components/connected/subjectAssignmentModal";
import ActiveSubject from "../../components/connected/activeSubject";
import SearchInDictionary from "../../components/connected/searchInDictionary";
import SubjectCarousel from "../../components/connected/subjectCarousel";
import { GrammarTrainerBanner } from "./GrammarTrainerBanner";

//TYPES
import { SharedTestMetaData } from "p6m-tests";

// HOOKS
import { useAppVersionCheck } from "../../hooks/useAppVersionCheck";

// STYLES
import {
    AdText,
    CenterContainer,
    CloseContainer,
    FullWidthContainer,
    StyledPhaseSixIcon,
    TopWidgets,
    SubjectList,
    TopRow,
} from "./styles";

export interface HomeProps {
    userId: string | undefined;
    userFirstName: string | undefined;
    showFreeContentAd: boolean;
    freeContentAmount: number;
    userRoles: { isParent: boolean; isTeacher: boolean; hasGrammarTrainer: boolean };
    allSubjects: SubjectData[];
    dueSubjects: SubjectData[];
    inactiveSubjects: SubjectData[];
    furtherSubjects: SubjectData[];
    expiredSubjects: SubjectData[];
    subjectActions: SubjectActions;
    subjectAssignmentModal: TSubjectAssignmentModal | null;
    closeAd: () => void;
    openShop: () => void;
    openChooseIncludedContentModal: () => void;
    consumableSharedTest: { subject?: SubjectData; metaData: SharedTestMetaData | null };
}
interface FreeContentAdvertisementProps {
    count: number;
    close: () => void;
    openChooseIncludedContentModal: () => void;
}
const FreeContentAdvertisement: React.FC<FreeContentAdvertisementProps> = (props) => {
    return (
        <FullWidthContainer>
            <CenterContainer onClick={props.openChooseIncludedContentModal}>
                <StyledPhaseSixIcon name={"arrow-right"} />
                <AdText>
                    <T
                        _str="Select the content included in your package ({contentCount})"
                        contentCount={props.count}
                        _tags="message"
                    />
                </AdText>
            </CenterContainer>
            <CloseContainer onClick={props.close}>
                <StyledPhaseSixIcon name={"close"} />
            </CloseContainer>
        </FullWidthContainer>
    );
};

const Home: React.FC<HomeProps> = (props) => {
    const {
        subjectActions,
        dueSubjects,
        inactiveSubjects,
        furtherSubjects,
        expiredSubjects,
        openShop,
        userId,
        showFreeContentAd,
        freeContentAmount,
        userRoles,
        userFirstName,
        closeAd,
        openChooseIncludedContentModal,
        consumableSharedTest,
    } = props;

    useAppVersionCheck();

    return (
        <>
            {!!consumableSharedTest.metaData && (
                <SubjectAssignmentModal
                    subject={consumableSharedTest.subject}
                    subjectActions={subjectActions}
                    sharedTestMetaData={consumableSharedTest.metaData}
                />
            )}

            {showFreeContentAd && (
                <FreeContentAdvertisement
                    count={freeContentAmount}
                    close={closeAd}
                    openChooseIncludedContentModal={openChooseIncludedContentModal}
                />
            )}

            <TopWidgets>
                <ActiveSubject
                    openShop={openShop}
                    userRoles={userRoles}
                    userFirstName={userFirstName}
                    allSubjects={props.allSubjects}
                />
                <SubjectList>
                    <TopRow>
                        <SearchInDictionary />
                    </TopRow>
                    <SubjectCarousel
                        userRoles={userRoles}
                        allSubjects={props.allSubjects}
                    />
                </SubjectList>
                {userRoles.hasGrammarTrainer && <GrammarTrainerBanner />}
            </TopWidgets>

            {!!dueSubjects.length && (
                <UserSubjectsList
                    userId={userId}
                    subjectActions={subjectActions}
                    type="due"
                    subjects={dueSubjects}
                />
            )}
            {!!inactiveSubjects.length && (
                <UserSubjectsList
                    userId={userId}
                    subjectActions={subjectActions}
                    type="inactive"
                    subjects={inactiveSubjects}
                />
            )}
            {!!furtherSubjects.length && (
                <UserSubjectsList
                    userId={userId}
                    subjectActions={subjectActions}
                    type="further"
                    subjects={furtherSubjects}
                />
            )}
            {!!expiredSubjects.length && (
                <UserSubjectsList
                    userId={userId}
                    subjectActions={subjectActions}
                    type="expired"
                    subjects={expiredSubjects}
                />
            )}
        </>
    );
};

export default Home;
