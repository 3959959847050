// REACT
import React, { FunctionComponent, memo } from "react";
import { useT } from "@transifex/react";

// COMPONENTS
import { Props as ContentProps } from "../ContentModal";
import PhaseSixIcon from "../../../../basic/phaseSixIcon/PhaseSixIcon";

// STYLED COMPONENTS
import { Wrapper, Title, BackIcon } from "./styles";

type Props = {
    type: ContentProps["type"] | "share";
    goBack: ContentProps["goBack"];
};

const ModalHeader: FunctionComponent<Props> = (props) => {
    const { type, goBack } = props;
    const t = useT();

    const t_subjectHeader = t("Select content", {});
    const t_unitsHeader = t("Select unit / topic", {});
    const t_shareHeader = t("Select the session you want to share", {});

    const title = {
        subject: t_subjectHeader,
        unit: t_unitsHeader,
        share: t_shareHeader,
    }[type];

    return (
        <Wrapper>
            {type !== "subject" && (
                <BackIcon onClick={() => goBack()}>
                    <PhaseSixIcon name="arrow-left" />
                </BackIcon>
            )}
            <Title>{title}</Title>
        </Wrapper>
    );
};

export default memo(ModalHeader);
