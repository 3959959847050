import styled from "styled-components";

// Webkit cannot style progress so we fake it with a long shadow on the thumb element
const makeLongShadow = (color: any, size: string) => {
    let i = 18;
    let shadow = `${i}px 0 0 ${size} ${color}`;

    for (; i < 706; i++) {
        shadow = `${shadow}, ${i}px 0 0 ${size} ${color}`;
    }

    return shadow;
};

export const Range = styled.input`
    overflow: hidden;
    display: block;
    appearance: none;
    max-width: 700px;
    width: 100%;
    margin: 0;
    height: ${(props) => props.theme.base.iconSmall};
    cursor: pointer;
    background-color: ${(props) => props.theme.colors.backgroundContrast};

    &:focus {
        outline: none;
    }

    &::-webkit-slider-runnable-track {
        width: 100%;
        height: 5px;
        background: ${(props) => props.theme.colors.backgroundMain};
    }

    &::-webkit-slider-thumb {
        position: relative;
        appearance: none;
        height: 25px;
        width: 25px;
        //ball background
        background: ${(props) => props.theme.colors.primary};
        border-radius: 100%;
        border: 0;
        top: 50%;
        transform: translateY(-50%);
        box-shadow: ${makeLongShadow((props: any) => props.theme.colors.text, "-10px")};
        transition: background-color 150ms;
    }

    &::-moz-range-track,
    &::-moz-range-progress {
        width: 100%;
        height: 5px;
        background: ${(props) => props.theme.colors.backgroundMain};
    }

    &::-moz-range-progress {
        background: ${(props) => props.theme.colors.backgroundMain};
    }

    &::-moz-range-thumb {
        appearance: none;
        margin: 0;
        height: 25px;
        width: 25px;
        //ball background
        background: ${(props) => props.theme.colors.primary};
        border-radius: 100%;
        border: 0;
        transition: background-color 150ms;
    }

    &::-ms-track {
        width: 100%;
        height: 5px;
        border: 0;
        /* color needed to hide track marks */
        color: transparent;
        background: transparent;
    }

    &::-ms-fill-lower {
        background: ${(props) => props.theme.colors.backgroundContrast};
    }

    &::-ms-fill-upper {
        background: ${(props) => props.theme.colors.backgroundContrast};
    }

    &::-ms-thumb {
        appearance: none;
        height: 25px;
        width: 25px;
        //ball background
        background: ${(props) => props.theme.colors.primary};
        border-radius: 100%;
        border: 0;
        transition: background-color 150ms;
        /* IE Edge thinks it can support -webkit prefixes */
        top: 0;
        margin: 0;
        box-shadow: none;
    }
`;
