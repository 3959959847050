import axios from "axios";
import { AxiosPromise } from "axios";
import { IResponse } from "p6m-response";
import { GameScoreData, CardsList } from "p6m-games";

export function getGamesScore(): AxiosPromise<IResponse<GameScoreData[]>> {
    return axios.get("/gameScore");
}

export function getCardsList(subjectId: string): AxiosPromise<IResponse<CardsList>> {
    return axios.post("/cardList", {
        filterMode: "LIBRARY",
        subjectId,
    });
}

export function addGameScore(
    gameScore: number,
    subjectId: { id: string; ownerId: "string" }
): AxiosPromise<IResponse<any>> {
    return axios.post("/addGameScore", {
        gameName: "match",
        gameScore,
        subjectId,
    });
}
