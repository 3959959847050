import React, { FunctionComponent } from "react";
import { useT } from "@transifex/react";
import { IUserGoals } from "p6m-goalsData";

import { Row } from "../../../components/basic/grid";

import { Wrapper, Title, TitleWrapper, Item, ContentWrapper, ContentRow } from "./styles";

type Props = Pick<IUserGoals, "currentDaysInAStreak" | "maxDaysInAStreak" | "practicedDays">;

const AchievementsStatistic: FunctionComponent<Props> = (props) => {
    const t = useT();
    const { currentDaysInAStreak, maxDaysInAStreak, practicedDays } = props;

    const t_title = t("Finish tasks and get rewards");
    const t_currentLearningStreak = t("Current learning streak");
    const t_recordLearningStreak = t("Record learning streak");
    const t_totalPracticedDays = t("Total practiced days");

    return (
        <Wrapper>
            <Row
                vertical="center"
                horizontal={{ s: "around", l: "between" }}
            >
                <TitleWrapper
                    xs={12}
                    m="auto"
                >
                    <Title>{t_title}</Title>
                </TitleWrapper>
                <ContentWrapper m="auto">
                    <ContentRow cols={{ xs: 1, s: 3 }}>
                        {[
                            {
                                value: currentDaysInAStreak,
                                title: t_currentLearningStreak,
                                qa: "current-streak",
                            },
                            {
                                value: maxDaysInAStreak,
                                title: t_recordLearningStreak,
                                qa: "record-streak",
                            },
                            {
                                value: practicedDays,
                                title: t_totalPracticedDays,
                                qa: "total-days",
                            },
                        ].map(({ value, title, qa }: any, i: number) => (
                            <Item key={i}>
                                <span data-qa={`achievements-counter_${qa}`}>{value || 0}</span>
                                <div>{title}</div>
                            </Item>
                        ))}
                    </ContentRow>
                </ContentWrapper>
            </Row>
        </Wrapper>
    );
};

export default AchievementsStatistic;
