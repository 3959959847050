import styled from "styled-components";
import { Row } from "../../basic/grid";
import PhaseSixIcon from "../../../components/basic/phaseSixIcon/PhaseSixIcon";

export const MarginLeftPhaseSixIcon = styled(PhaseSixIcon)`
    margin-left: -10px;
    color: inherit;
`;

export const MarginRightPhaseSixIcon = styled(PhaseSixIcon)`
    margin-right: -10px;
    color: inherit;
`;

export const FooterRow = styled(Row)`
    margin-bottom: 0 !important;
`;

export const Button = styled.button<{ disabled?: boolean }>`
    padding: ${({ theme: { base } }) => base.borderRadiusSmall} ${({ theme: { base } }) => base.spacingSmall};
    border-radius: ${({ theme: { base } }) => base.borderRadiusSmall};
    background: ${({ theme: { colors } }) => colors.primary};
    font-size: ${({ theme: { base } }) => base.bigSize};
    line-height: ${({ theme: { base } }) => base.bigSize};
    color: ${(props) => props.theme.colors.backgroundContrast};
    cursor: pointer;
    border: none;
    outline: none !important;
    transition: opacity ${({ theme: { base } }) => base.transition}s;
    display: flex;
    align-items: center;

    &:hover {
        opacity: ${({ theme: { base } }) => base.hoverOpacity};
    }

    ${({ disabled = false }) =>
        disabled &&
        `
        cursor: default;
        opacity: 0.5;
        &:hover {
            opacity: 0.5;
        }
    `}
`;
