//LIBRARIES
import styled from "styled-components";

//STYLES
import { OutlinedButton } from "../../basic/button/Button";

export const MainBodyWrapper = styled.div``;

export const UserInfoContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-bottom: ${(props) => props.theme.base.spacing};
`;

export const EditAvatarIconWrapper = styled.div`
    display: flex;
    position: absolute;
    right: -15px;
    top: 0;
    z-index: 2;
    color: #999;
    padding: 6px;
    border-radius: 50%;
    background-color: ${({ theme: { colors } }) => colors.backgroundContrast};
    font-size: 12px;
`;

export const UserNameContainer = styled.div`
    color: ${(props) => props.theme.colors.text};
`;

export const NameContainer = styled.p`
    text-align: center;
`;

export const EmailContainer = styled.p`
    color: ${(props) => props.theme.colors.primary};
`;

export const StyledLogoutButton = styled(OutlinedButton)`
    margin-top: ${(props) => props.theme.base.spacingBig};
    width: 100%;
`;

export const UserAvatarWrapper = styled.div`
    margin-top: ${(props) => props.theme.base.spacingSmall};
`;
