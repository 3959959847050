// REACT
import React, { FunctionComponent, MouseEventHandler, ReactNode } from "react";

// STYLED COMPONENTS
import { TooltipWrapper, TooltipTop, TooltipBottom, TooltipLeft, TooltipRight } from "./styles";

type Positions = "top" | "bottom" | "left" | "right";
type Props = {
    content: ReactNode;
    position?: Positions;
    showTooltip?: boolean;
    isBlock?: boolean;
    onMouseLeave?: MouseEventHandler<HTMLSpanElement>;
    onMouseOverCapture?: MouseEventHandler<HTMLSpanElement>;
};

const componentRelation = {
    top: TooltipTop,
    bottom: TooltipBottom,
    left: TooltipLeft,
    right: TooltipRight,
};

const Tooltip: FunctionComponent<Props> = (props) => {
    const { children, content, position = "bottom", showTooltip = true, isBlock = false, onMouseLeave, onMouseOverCapture } = props;
    const TooltipComponent = componentRelation[position];

    return (
        <TooltipWrapper
            onMouseLeave={onMouseLeave}
            onMouseOverCapture={onMouseOverCapture}
            $isBlock={isBlock}
        >
            {children}
            {showTooltip && <TooltipComponent>{content}</TooltipComponent>}
        </TooltipWrapper>
    );
};

export default Tooltip;
