import axios, { AxiosPromise } from "axios";
import { IResponse } from "p6m-response";
import { INotifications, INotificationsEvents } from "p6m-notifications";

export function getNotifications(): AxiosPromise<IResponse<INotifications>> {
    return axios.get("/notificationEvents");
}

export function uploadNotifications(notifications: Partial<INotificationsEvents>[]): AxiosPromise<IResponse<string>> {
    return axios.post("/notificationEvents", { events: notifications });
}
export function getRemindMe(): AxiosPromise<IResponse<string>> {
    return axios.get("/remindMe");
}

export function changeRemindMe(payload: boolean): AxiosPromise<IResponse<string>> {
    return axios.post("remindMe", { remindMe: payload });
}
