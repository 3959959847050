import styled from "styled-components";
import BasicIcon from "../../../../basic/phaseSixIcon/PhaseSixIcon";
import { Row as BasicRow, Col as BasicCol } from "../../../../basic/grid";

export const ModalBody = styled.div`
    padding: ${({ theme: { base } }) => base.spacing};
`;

export const Row = styled(BasicRow)`
    margin: 0 -${({ theme: { base } }) => base.spacingInContent};
    &:first-child {
        margin-top: -${({ theme: { base } }) => base.spacingInContent};
    }
    &:last-child {
        margin-bottom: -${({ theme: { base } }) => base.spacingInContent};
    }
`;

export const Col = styled(BasicCol)`
    padding: ${({ theme: { base } }) => base.spacingInContent};
`;

export const Icon = styled(BasicIcon)`
    font-size: ${({ theme: { base } }) => base.iconBig};
    color: inherit;
`;

export const H3 = styled.h3`
    margin: 0;
    font-weight: normal;
    font-size: ${({ theme: { base } }) => base.titleSize};
`;

export const P = styled.p<{ $primary?: boolean }>`
    ${({ $primary = false, theme: { colors } }) =>
        $primary
            ? `
            color: ${colors.primary};
        `
            : ""}
    ${({ onClick }) =>
        !!onClick
            ? `
            cursor: pointer;
        `
            : ""}

    a {
        color: inherit;
        text-decoration: none;

        &:hover {
            opacity: ${({ theme: { base } }) => base.hoverOpacity};
        }
    }
`;

export const Box = styled.div`
    height: 100%;
    padding: ${({ theme: { base } }) => base.spacingInContent};
    background-color: #f2f2f2;
    color: ${({ theme: { sidebar } }) => sidebar.mobileColor};

    ${P} {
        font-size: ${({ theme: { base } }) => base.mediumSize};
        text-align: inherit;
    }
    ${H3} {
        font-size: ${({ theme: { base } }) => base.bigSize};
    }
`;

export const BoxBack = styled(Box)`
    position: absolute;
    top: 0;
    z-index: 1;
    transform: rotateY(180deg);
    opacity: 0;
    transition: ${({ theme: { base } }) => base.transition}s;
`;
export const BoxFront = styled(Box)`
    position: relative;
    top: 0;
    z-index: 2;
    transition: ${({ theme: { base } }) => base.transition}s;
`;

export const AnimatedBox = styled.div<{ $disableAnimation?: boolean }>`
    position: relative;
    height: 100%;
    text-align: center;

    ${({ $disableAnimation = false }) =>
        !$disableAnimation
            ? `
            &:hover ${BoxBack} {
                transform: rotateY(0deg);
                opacity: 1;
                z-index: 2;
            }
            &:hover ${BoxFront} {
                transform: rotateY(-180deg);
                opacity: 0;
                z-index: 1;
            }
        `
            : ""}
`;

export const PrimaryBox = styled.a`
    display: block;
    height: 100%;
    padding: ${({ theme: { base } }) => base.spacingInContent};
    background-color: ${({ theme: { colors } }) => colors.primary};
    color: ${({ theme: { colors } }) => colors.backgroundContrast};
    border: 1px solid ${({ theme: { colors } }) => colors.primary};
    text-decoration: none;

    &:hover {
        background-color: ${({ theme: { colors } }) => colors.primary}30;
        color: ${({ theme: { colors } }) => colors.primary};
    }
`;
