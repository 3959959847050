// REACT
import React, { memo, FunctionComponent } from "react";

// TYPES
import { Item } from "p6m-learning";

// COMPONENTS
import { DragDrop } from "../DragDrop";
import { Card } from "../Card";
import AnimatedContent from "./AnimatedContent";

// STYLED COMPONENTS
import { CardsWrapper, GoNextIconWrapper, GoBackIconWrapper, CardsNavigationIcon } from "./styles";

export type Props = {
    item: Item;
    index: number;
    goNext?: () => void;
    goBack?: () => void;
};

const Cards: FunctionComponent<Props> = (props) => {
    const { item, index, goNext, goBack } = props;

    const { type } = item;

    return (
        <CardsWrapper>
            <AnimatedContent
                index={index}
                type={type}
            >
                {type === "drag" ? <DragDrop key={index} /> : <Card key={index} />}
            </AnimatedContent>
            {!!goNext && (
                <GoNextIconWrapper onClick={goNext}>
                    <CardsNavigationIcon
                        name="chevron-right"
                        size={"big"}
                    />
                </GoNextIconWrapper>
            )}
            {!!goBack && (
                <GoBackIconWrapper onClick={goBack}>
                    <CardsNavigationIcon
                        name="chevron-left"
                        size={"big"}
                    />
                </GoBackIconWrapper>
            )}
        </CardsWrapper>
    );
};

export default memo(Cards);
