import React, { ReactNode } from "react";
import { TIconNames } from "../../../components/basic/phaseSixIcon/PhaseSixIcon";
import { Entry, EntryLink, EntryText, EntryIcon } from "./styles";

interface DropdownActionsItemProps {
    text: ReactNode;
    iconName: TIconNames;
    disabled?: boolean;
    onClick?: () => void;
}

export const DropdownActionsItem = ({ text, iconName, onClick, disabled }: DropdownActionsItemProps) => {
    return (
        <Entry>
            <EntryLink
                disabled={disabled}
                onClick={onClick}
            >
                <EntryText>{text}</EntryText>
                <EntryIcon name={iconName} />
            </EntryLink>
        </Entry>
    );
};
