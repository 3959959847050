export const getLogoById = (publisherId: string): string => {
    let staticImageUrl;

    try {
        staticImageUrl = require(`../assets/img/publishers/logo-${publisherId}.png`);
    } catch (e) {
        staticImageUrl = "";
    }

    return staticImageUrl;
};
