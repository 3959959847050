import React, { FunctionComponent } from "react";
import { NavLink } from "react-router-dom";
import PhaseSixIcon from "../../../basic/phaseSixIcon/PhaseSixIcon";
import styled from "styled-components";
import { T } from "@transifex/react";
import Tooltip from "../../../basic/tooltip/Tooltip";
import { ampli } from "../../../../ampli";

type Props = {
    disabled?: boolean;
};

const TransparentPhaseSixIcon = styled(PhaseSixIcon)`
    opacity: 0.5;
    color: ${(props) => props.theme.colors.textOnBackground};
`;

const StyledPhaseSixIcon = styled(PhaseSixIcon)`
    color: inherit;
`;

const LeaderBoardItem: FunctionComponent<Props> = ({ disabled = true }) => {
    return disabled ? (
        <Tooltip
            content={
                <T
                    _str="Have a first practice!"
                    _tags="leaderboard,header,tooltip"
                />
            }
        >
            <TransparentPhaseSixIcon
                aria-label="Leaderboards"
                aria-hidden
                name="leaderboard-nav"
                size="medium"
            />
        </Tooltip>
    ) : (
        <Tooltip
            content={
                <T
                    _str="Leaderboard"
                    _tags="leaderboard,header,tooltip"
                />
            }
        >
            <NavLink
                activeClassName="is-active"
                to="/leaderBoard"
                onClick={() => ampli.openLeaderboard()}
            >
                <StyledPhaseSixIcon
                    aria-label="Leaderboards"
                    aria-hidden
                    name="leaderboard-nav"
                    size="medium"
                />
            </NavLink>
        </Tooltip>
    );
};

export default LeaderBoardItem;
