import styled from "styled-components";
import PhaseSixIcon from "../phaseSixIcon/PhaseSixIcon";

export const GoToShopContainer = styled.div`
    position: relative;
    width: 100%;
    height: 85vh;
    margin-bottom: 10px;
`;
export const PremiumDictionaryImage = styled.img`
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: auto;
    margin-right: 30px;
    border-radius: ${(props) => props.theme.base.borderRadius};
`;
export const GoToShopGradient = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    background: linear-gradient(
        to right,
        ${(props) => props.theme.colors.backgroundContrast} 0 35%,
        ${(props) => props.theme.colors.backgroundContrast + "20"} 100%
    );
    border-radius: ${(props) => props.theme.base.borderRadius};
`;
export const GoToShopContent = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 40%;
    min-width: 400px;
    padding: 30px 50px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
`;
export const GoToShopTitleContainer = styled.div`
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: stretch;
`;
export const GoToShopTitleIcon = styled.img`
    width: 70px;
`;
export const GoToShopTitleTextContainer = styled.div`
    flex: 2;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    padding-left: ${(props) => props.theme.base.spacingSmall};
`;
export const GoToShopTitle = styled.span`
    color: ${(props) => props.theme.colors.primary};
    font-size: ${(props) => props.theme.base.titleBigSize};
`;
export const GoToShopSubtitle = styled.span`
    color: ${(props) => props.theme.colors.primary};
    font-size: ${(props) => props.theme.base.titleBigSize};
    font-weight: ${(props) => props.theme.base.bigWeight};
`;
export const GoToShopDescriptionContainer = styled.div`
    flex: 5;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    padding-top: ${(props) => props.theme.base.spacing};
    padding-left: ${(props) => props.theme.base.spacing};
`;
export const GoToShopDescriptionTitle = styled.span`
    color: ${(props) => props.theme.colors.text};
    font-size: ${(props) => props.theme.base.titleSize};
    font-weight: ${(props) => props.theme.base.bigWeight};
    margin-top: ${(props) => props.theme.base.spacing};
`;
export const GoToShopDescriptionList = styled.ul`
    list-style-type: none;
`;
export const GoToShopDescriptionEntry = styled.li`
    color: ${(props) => props.theme.colors.primary};
    padding-bottom: ${(props) => props.theme.base.spacingSmall};
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
`;
export const GoToShopDescriptionEntryText = styled.span`
    color: ${(props) => props.theme.colors.text};
    font-size: ${(props) => props.theme.base.bigSize};
    padding-left: ${(props) => props.theme.base.spacing};
    padding-bottom: ${(props) => props.theme.base.spacingTiny};
`;
export const GoToShopCTA = styled.a`
    border-radius: ${(props) => props.theme.base.borderRadiusSmall};
    background-color: ${(props) => props.theme.colors.primary};
    margin-left: ${(props) => props.theme.base.spacing};
    align-self: flex-start;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    color: ${(props) => props.theme.colors.backgroundContrast};
    font-size: ${(props) => props.theme.base.bigSize};
    cursor: pointer;
    text-decoration: none;
    transition: all 0.25s;

    &:hover {
        opacity: 0.7;
    }
`;
export const GoToShopCTAIcon = styled(PhaseSixIcon)`
        color: ${(props) => props.theme.colors.backgroundContrast};
        margin: ${(props) => props.theme.base.spacingSmall} ${(props) => props.theme.base.spacing};
`;
export const GoToShopCTAText = styled.span`
    color: ${(props) => props.theme.colors.backgroundContrast};
    font-size: ${(props) => props.theme.base.titleSize};
    font-weight: bold;
    margin-right: ${(props) => props.theme.base.spacing};
`;
