// LIBRARIES
import React, { useCallback, useEffect, useRef, useState } from "react";
import { ampli } from "../../../../ampli";
import { useT, T } from "@transifex/react";

// TYPES
import { PreviewDateStatistic, SubjectData } from "p6m-subjects";

// COMPONENTS
import ReportTTHSetting from "../../../../components/connected/reportTTHSetting";

// NETWORKING
import { getReportPreviewData } from "../../../../networking/reports";

// STYLES
import { Container, InfoHighlight, LimitContainer, LimitLabel, List, Entry, DateText, DueText } from "./styles";
import { InfoTextContainer, InfoText, InformationBox, InfoImageContainer, InfoImage } from "../styles";

export interface ReportsPreviewProps {
    ownerId?: string;
    selectedSubject: SubjectData;
    isOwnSubject: boolean;
}

const ReportPreview: React.FC<ReportsPreviewProps> = (props) => {
    const t = useT();
    const [selectedDay, setSelectedDay] = useState<number | null>(null);
    const dayRefs = [
        useRef<HTMLDivElement>(null),
        useRef<HTMLDivElement>(null),
        useRef<HTMLDivElement>(null),
        useRef<HTMLDivElement>(null),
        useRef<HTMLDivElement>(null),
        useRef<HTMLDivElement>(null),
        useRef<HTMLDivElement>(null),
        useRef<HTMLDivElement>(null),
    ];
    const [data, setData] = useState<Array<PreviewDateStatistic> | null>(null);

    const weekday_t = t("Weekday", { _tags: "title,report,preview" });
    const dueCards_t = t("Due cards", { _tags: "title,report,preview" });
    const noData_t = t("No preview available", { _tags: "placeholder,report,preview" });

    const daily_limit_t = t("Daily card limit", { _tags: "report,label" });
    const basic_info_t = t("This shows how many cards will be due in the next days.", {
        _tags: "report,description",
    });

    const loadData = useCallback(async () => {
        const response = await getReportPreviewData(
            props.ownerId || "",
            props.selectedSubject.subjectMetadata.subjectIdToOwner
        );
        const {
            data: {
                replyContent: { predictionList: data },
            },
        } = response;
        setData(data);
    }, [props.ownerId, props.selectedSubject]);

    useEffect(() => {
        loadData();
    }, [loadData]);

    useEffect(() => {
        ampli.openReportsTab({
            reports_tab: "7day_preview",
        });
    }, []);

    const onDayClick = (index: number) => {
        setSelectedDay(index);
    };

    // unselect phases
    const handleClick = useCallback(
        (event: MouseEvent) => {
            const target = event.target;
            const phaseClicked = dayRefs.some((ref) => ref && target instanceof Node && ref.current?.contains(target));
            if (!phaseClicked) {
                setTimeout(() => {
                    setSelectedDay(null);
                }, 100);
            }
        },
        [dayRefs, setSelectedDay]
    );

    useEffect(() => {
        if (selectedDay !== null) {
            document.addEventListener("mousedown", handleClick);
            return () => document.removeEventListener("mousedown", handleClick);
        }
    }, [selectedDay, handleClick]);

    const weekdayNames = [
        t("Sunday", { _tags: "label,list,reports" }),
        t("Monday", { _tags: "label,list,reports" }),
        t("Tuesday", { _tags: "label,list,reports" }),
        t("Wednesday", { _tags: "label,list,reports" }),
        t("Thursday", { _tags: "label,list,reports" }),
        t("Friday", { _tags: "label,list,reports" }),
        t("Saturday", { _tags: "label,list,reports" }),
    ];
    const getWeekdayName = (entry: PreviewDateStatistic) => {
        const weekday = new Date(entry.date).getDay();
        return weekdayNames[weekday];
    };

    const renderInfoBox = () => {
        let image = require("../../../../assets/img/avatar/monster_basic.png");
        let text = <InfoText>{basic_info_t}</InfoText>;
        let vocabularyAmount = (
            <InfoHighlight>{t("{amount} cards", { amount: 0, _tags: "report,description" })}</InfoHighlight>
        );
        if (data && selectedDay !== null) {
            vocabularyAmount = (
                <InfoHighlight>
                    {t("{amount} cards", {
                        amount: data[selectedDay].recommendedCards,
                        _tags: "report,description",
                    })}
                </InfoHighlight>
            );
            image = require("../../../../assets/img/avatar/monster_glasses.png");
            text = (
                <InfoText>
                    <T
                        _str="On {date} there will be {vocabularyAmount} cards due in total if you don't keep practicing."
                        date={getWeekdayName(data[selectedDay])}
                        vocabularyAmount={vocabularyAmount}
                    />
                </InfoText>
            );
        }
        return (
            <InformationBox>
                <InfoImageContainer>
                    <InfoImage src={image} />
                </InfoImageContainer>
                <InfoTextContainer>{text}</InfoTextContainer>
                <LimitContainer>
                    {props.isOwnSubject && (
                        <>
                            <LimitLabel>{daily_limit_t}</LimitLabel>
                            <ReportTTHSetting onUpdate={loadData} />
                        </>
                    )}
                </LimitContainer>
            </InformationBox>
        );
    };

    return (
        <Container>
            {renderInfoBox()}
            <List>
                <Entry header>
                    <DateText>{weekday_t}</DateText>
                    <DueText>{dueCards_t}</DueText>
                </Entry>
                {data &&
                    (data.length > 0 ? (
                        data.map((day, index) => {
                            return (
                                <Entry
                                    key={"day_" + index}
                                    ref={dayRefs[index]}
                                    selected={index === selectedDay}
                                    onClick={() => onDayClick(index)}
                                >
                                    <DateText>{getWeekdayName(day)}</DateText>
                                    <DueText>{day.recommendedCards}</DueText>
                                </Entry>
                            );
                        })
                    ) : (
                        <Entry>
                            <DateText>{noData_t}</DateText>
                        </Entry>
                    ))}
            </List>
        </Container>
    );
};

export default ReportPreview;
