//DEPENDENCIES
import { call, put, takeEvery, select } from "redux-saga/effects";
import { AxiosResponse } from "axios";
import { PayloadAction } from "@reduxjs/toolkit";

//NETWORK
import {
    getTestsList,
    updateTest,
    deleteTest,
    GetTestListResponse,
    fetchSharedTest,
    FetchSharedTestResponse,
} from "../../networking/tests";
import { fetchAllUserSubjectsMetaData, fetchSubjectInfo } from "../../networking/subjects";

//REDUX
import { actions, selectors, UpdateTestPayload } from "./testsSlice";
import { selectors as subjectSelectors, actions as subjectActions } from "../subjects/subjectsSlice";
import { selectors as userSelectors } from "../user/userSlice";

//TYPES
import { SharedTestMetaData, TestListItemInfo } from "p6m-tests";
import { IResponse } from "p6m-response";
import {
    PublisherSubjectResponseData,
    SelfCreatedSubjectResponseData,
    SubjectAssignmentModal,
    SubjectData,
    SubjectMetadata,
} from "p6m-subjects";

//UTILS
import { calculatePercentage } from "../../helpers/calculatePercentage";
import { getDefaultLanguageCoverImage } from "../../helpers/Subjects";
import { getPublisherSubjectStatus } from "../../helpers/getPublisherSubjectStatus";

const isPublisherContent = (
    data: SelfCreatedSubjectResponseData | PublisherSubjectResponseData
): data is PublisherSubjectResponseData => {
    return "inAppId" in data && data.inAppId.length > 0;
};

function* getTests() {
    try {
        const res: AxiosResponse<IResponse<GetTestListResponse>> = yield call(getTestsList);
        const allTests = res.data.replyContent.objects;

        const ownTests: TestListItemInfo[] = allTests
            // keep own tests and items with a subject
            .filter(([, testItem]) => {
                return testItem.userCardsTest.sharedTestId.id === "" && !!testItem.userCardsTest.subjectContentId;
            })
            // map to TestOverview object
            .map(([test, details]) => ({
                id: test.id,
                type: "OWN_TESTS",
                name: details.userCardsTest.testName ?? "",
                totalCards: details.userCardsTest.cardIds.length,
                subjectId: details.userCardsTest.subjectContentId!.id,
                cardIds: details.userCardsTest.cardIds,
                direction: details.userCardsTest.direction,
                subjectContentId: details.userCardsTest.subjectContentId!,
                modificationDate: !!details.lastResult
                    ? details.lastResult.modificationDate
                    : details.userCardsTest.modificationDate,
                lastTestResultPercent: !!details.lastResult
                    ? calculatePercentage({
                          totalAmount: details.lastResult.totalCardsInTest,
                          fractionAmount: details.lastResult.cardsAnsweredRight,
                      })
                    : -1,
            }));

        yield put(actions.setOwnTests(ownTests));

        const sharedTests: TestListItemInfo[] = allTests
            // keep shared tests (exercises) and items with subject
            .filter(
                ([, testItem]) =>
                    testItem.userCardsTest.sharedTestId.id !== "" && !!testItem.userCardsTest.subjectContentId
            )
            // map to TestOverview object
            .map(([test, details]) => ({
                id: test.id,
                type: "SHARED_TESTS",
                name: details.userCardsTest.testName ?? "",
                totalCards: details.userCardsTest.cardIds.length,
                cardIds: details.userCardsTest.cardIds,
                direction: details.userCardsTest.direction,
                subjectContentId: details.userCardsTest.subjectContentId!,
                modificationDate: !!details.lastResult
                    ? details.lastResult.modificationDate
                    : details.userCardsTest.modificationDate,
                lastTestResultPercent: !!details.lastResult
                    ? calculatePercentage({
                          totalAmount: details.lastResult.totalCardsInTest,
                          fractionAmount: details.lastResult.cardsAnsweredRight,
                      })
                    : -1,
            }));

        yield put(actions.setSharedTests(sharedTests));

        //setAllTests
        yield put(actions.setAllTests([...ownTests, ...sharedTests]));
    } catch (err) {
        console.log(err);
        yield put(actions.setOwnTests([]));
        yield put(actions.setSharedTests([]));
        yield put(actions.setAllTests([]));
    }
}

function* updateTestSaga({ payload }: PayloadAction<UpdateTestPayload>) {
    yield call(updateTest, payload.testId, payload.data);
    yield call(getTests);
}

function* deleteTestSaga({ payload }: PayloadAction<string>) {
    yield call(deleteTest, payload);
    yield call(getTests);
}

function* fetchSharedTestFromMetadataSaga() {
    const userDnsId: string = yield select(userSelectors.userId);
    const metaData: SharedTestMetaData | null = yield select(selectors.sharedTestMetaData);

    if (!metaData) return;

    try {
        const { data: sharedTestData }: AxiosResponse<IResponse<FetchSharedTestResponse>> = yield call(
            fetchSharedTest,
            {
                testId: metaData.testId,
                userId: metaData.teacherId,
            }
        );

        const testSubjectId = sharedTestData.replyContent.subjectContentId.id;

        const {
            data: subjectData,
        }: AxiosResponse<IResponse<PublisherSubjectResponseData | SelfCreatedSubjectResponseData>> = yield call(
            fetchSubjectInfo,
            {
                subjectId: testSubjectId,
                ownerId: sharedTestData.replyContent.subjectContentId.ownerId,
            }
        );

        const {
            data: {
                replyContent: { subjectMetadataList },
            },
        }: AxiosResponse<IResponse<{ subjectMetadataList: SubjectMetadata[]; revisionNumber: number }>> = yield call(
            fetchAllUserSubjectsMetaData,
            userDnsId
        );

        const userMetaDataForTestSubject = subjectMetadataList.find(
            (subjectMetadata) => subjectMetadata.subjectIdToOwner.id === testSubjectId
        );

        const userSubject = yield select(subjectSelectors.getSubjectById(testSubjectId));

        const modal = createAssignmentModal(
            sharedTestData.replyContent.cardIds.length,
            subjectData.replyContent,
            userMetaDataForTestSubject,
            userSubject
        );

        yield put(subjectActions.setSubjectAssignmentModal(modal));
    } catch (err) {
        console.log(err);
    }
}

export function* testsSaga() {
    yield takeEvery(actions.fetchTests, getTests);
    yield takeEvery(actions.updateTest, updateTestSaga);
    yield takeEvery(actions.deleteTest, deleteTestSaga);
    yield takeEvery(actions.fetchSharedTestFromMetadata, fetchSharedTestFromMetadataSaga);
}

export function createAssignmentModal(
    totalCardsCount: number,
    subject: PublisherSubjectResponseData | SelfCreatedSubjectResponseData,
    ownSubjectMetaData?: SubjectMetadata,
    userSubject?: SubjectData
): SubjectAssignmentModal {
    const subjectName = subject.name;

    if (isPublisherContent(subject)) {
        const publisherSubjectStatus = getPublisherSubjectStatus(ownSubjectMetaData);
        const publisherSubjectImageUrl = `${process.env.REACT_APP_BACKEND_API_URL}media/${subject.imageId}`;

        switch (publisherSubjectStatus) {
            case "EXPIRED_30D_TRIAL": {
                return {
                    subjectName,
                    totalCards: totalCardsCount,
                    imageUrl: publisherSubjectImageUrl,
                    type: "TrialExpired",
                };
            }
            case "NEW":
            case "EXPIRED_7D_TRIAL":
            case "ACTIVE_7D_TRIAL": {
                return {
                    subjectName,
                    totalCards: totalCardsCount,
                    contentIdentifier: subject.inAppId,
                    imageUrl: publisherSubjectImageUrl,
                    type: "NewSubject",
                };
            }
            case "ACTIVE_30D_TRIAL":
            case "PURCHASED": {
                if (!!userSubject) {
                    return {
                        subjectName,
                        totalCards: totalCardsCount,
                        contentIdentifier: subject.inAppId,
                        imageUrl: publisherSubjectImageUrl,
                        type: "AvailableSubject",
                    };
                }
                return {
                    subjectName,
                    totalCards: totalCardsCount,
                    contentIdentifier: subject.inAppId,
                    imageUrl: publisherSubjectImageUrl,
                    type: "DeletedActiveSubject",
                };
            }
        }
    } else {
        const selfCreatedModalType = !!userSubject ? "LoadedSelfCreatedContent" : "SelfCreatedContent";

        return {
            subjectName,
            totalCards: totalCardsCount,
            imageUrl: getDefaultLanguageCoverImage(subject.secondaryLang),
            type: selfCreatedModalType,
        };
    }
}
