import styled from "styled-components";
import PhaseSixIcon from "../../../basic/phaseSixIcon/PhaseSixIcon";

export const Container = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: ${(props) => props.theme.base.spacingSmall};
    margin-bottom: ${(props) => props.theme.base.spacingInContent};
    border-radius: ${(props) => props.theme.base.borderRadiusSmall};
    background: ${(props) => props.theme.colors.backgroundContrast};
`;

export const PopupContainer = styled.div`
    flex: 1;
    color: ${(props) => props.theme.colors.text};
    display: flex;
`;

export const TitleContainer = styled.div`
    display: flex;
    flex: 5;
    overflow: hidden;
    margin-left: ${(props) => props.theme.base.spacingSmall};
`;

export const TitleLink = styled.a`
    white-space: nowrap;
    text-decoration: none;
    cursor: pointer;
    display: flex;
    position: relative;
`;

export const TitleText = styled.span`
    white-space: nowrap;
    font-size: ${(props) => props.theme.base.bigSize};
    color: ${(props) => props.theme.colors.text};
    font-weight: ${(props) => props.theme.base.weight};
    font-family: ${(props) => props.theme.base.fontFamily};
    padding: 20px 0;
`;

export const ExpirationContainer = styled.div`
    position: absolute;
    top: 60%;
    display: flex;
    align-items: center;
    color: ${(props) => props.theme.colors.red};
`;

export const StyledPhaseSixIcon = styled(PhaseSixIcon)`
    color: ${(props) => props.theme.colors.red};
`;

export const SubjectActionContainer = styled.div`
    flex: 3;
`;

export const Statistics = styled.div`
    display: flex;
    justify-content: flex-start;
    flex: 3;
    color: ${(props) => props.theme.colors.text};
`;
export const Stat = styled.span`
    text-align: center;
    flex: 1;
    max-width: 50px;
    font-size: ${(props) => props.theme.base.bigSize};
`;
export const Divider = styled.span`
    text-align: center;
`;

export const MoreActionsButtonContainer = styled.div`
    cursor: pointer;
`;

export const MoreActionsPhaseSixIcon = styled(PhaseSixIcon)`
    &:hover {
        color: ${(props) => props.theme.colors.primary};
    }
`;
