import styled from "styled-components";
import MouseEventIcon from "../../basic/mouseEventIcon/MouseEventIcon";
import { Link } from "react-router-dom";

export const Text = styled.p`
    margin: 0;
    font-size: 14px;
    color: ${(props) => props.theme.colors.text};
    text-align: left;
`;

export const StyledLinkWrapper = styled.div<{ disableHover?: boolean; $disabled?: boolean }>`
    height: 40px;
    width: 100%;
    margin-bottom: 0.5em;

    &.no-hover:hover {
        background: unset;

        * {
            color: ${(props) => props.theme.colors.backgroundContrast} !important;
            fill: ${(props) => props.theme.colors.backgroundContrast} !important;
        }
    }
`;

export const StyledLinkContainer = styled(Link)`
    width: 100%;
    height: 100%;
    cursor: pointer;
    text-decoration: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;

    p {
        flex: 4;
    }

    &.reverse-direction {
        flex-direction: row-reverse;

        > p {
            text-align: left;
        }
    }
`;

export const StyledExternalLinkContainer = styled.a`
    width: 100%;
    height: 100%;
    cursor: pointer;
    text-decoration: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;

    p {
        flex: 4;
    }

    &.reverse-direction {
        flex-direction: row-reverse;

        > p {
            text-align: left;
        }
    }
`;

export const StyledModalTriggerContainer = styled.div<{ disabled?: boolean }>`
    width: 100%;
    height: 100%;
    cursor: ${(props) => (!props.disabled ? "pointer" : "not-allowed")};
    text-decoration: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;

    opacity: ${({
        disabled = false,
        theme: {
            base: { hoverOpacity },
        },
    }) => (disabled ? hoverOpacity : 1)}}

    &:hover {
      * {
        color: ${({ disabled, theme }) => (disabled ? "" : theme.colors.primary + " !important")};
        fill: ${({ disabled, theme }) => (disabled ? "" : theme.colors.primary + " !important")};
      }
    }

    p {
        flex: 4;
    }

    &.reverse-direction {
        flex-direction: row-reverse;

        > p {
            text-align: left;
        }
    }
`;

export const StyledIframe = styled.iframe`
    display: block;
    box-sizing: border-box;
    width: 100%;
    height: 80vh;
    border: none;
`;

export const UserMenuIcon = styled(MouseEventIcon)`
    padding: 5px 0;
    flex: 1;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
`;
