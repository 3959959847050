import styled from "styled-components";

export const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

export const Title = styled.h1`
    margin: 0 0 0 ${({ theme: { base } }) => base.spacingSmall};
    font-size: ${({ theme: { base } }) => base.titleBigSize};
    color: ${({ theme: { colors } }) => colors.text};
    font-weight: normal;
`;

export const BackIcon = styled.div`
    margin-right: ${({ theme: { base } }) => base.spacingSmall};
    font-size: ${({ theme: { base } }) => base.titleBigSize};
    color: ${({ theme: { colors } }) => colors.primary};
    cursor: pointer;
    transition: ${({ theme: { base } }) => base.transition};
    display: flex;

    &:hover {
        opacity: ${({ theme: { base } }) => base.hoverOpacity};
    }
`;
