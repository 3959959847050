//LIBRARIES
import React from "react";
import { T, useT } from "@transifex/react";

//STYLED COMPONENTS
import { DescriptionHighlighted } from "../../../complex/cardActionButtons/Modals/ModifyCard/styles";

// COMPONENTS
import ModifyCard from "../../../complex/cardActionButtons/Modals/ModifyCard";

//TYPES
import { GlobalModalProps } from "../../../../helpers/Modal";

interface ChangeCardPhaseStatusProps extends GlobalModalProps {
    changeableCardCount: number;
    selectedCardCount?: number;
    onClick: () => void;
}

const ChangeCardPhaseStatusModal: React.FC<ChangeCardPhaseStatusProps> = (props) => {
    const t = useT();

    const change_phase_title_t = t("Set learning progress", { _tags: "changePhase,modal,title" });
    const ok_t = t("Ok", { _tags: "changePhase,modal,button" });

    const { changeableCardCount, selectedCardCount = 0, onClick } = props;
    const nonDeletableCardCount = selectedCardCount - changeableCardCount;

    const cardAmount = <DescriptionHighlighted>{changeableCardCount}</DescriptionHighlighted>;
    const cardAmountNonDeletable = <DescriptionHighlighted>{nonDeletableCardCount}</DescriptionHighlighted>;
    const descriptionWarning = (
        <T
            _str="You are about to change the phase status for {cardAmount} card(s)."
            cardAmount={cardAmount}
        />
    );
    const additionalNotice = changeableCardCount > 0 && nonDeletableCardCount > 0 && (
        <T
            _str="Please note that {cardAmount} card(s) have not yet been learned. Their phase status cannot be changed."
            cardAmount={cardAmountNonDeletable}
            _tags="modal,warning"
        />
    );

    return (
        <ModifyCard
            confirmButtonText={ok_t}
            title={change_phase_title_t}
            descriptionWarning={descriptionWarning}
            additionalNotice={additionalNotice}
            onClickMethod={onClick}
        />
    );
};

export default ChangeCardPhaseStatusModal;
