// REACT
import React, { FunctionComponent } from "react";
import { T } from "@transifex/react";

// REDUX
import { useDispatch } from "react-redux";
import { actions as modalActions } from "../../../../redux/modal/modalSlice";
import { actions as settingsActions } from "../../../../redux/settings/settingsSlice";

// COMPONENTS
import { OpenSettingsButton } from "../CardResult/styles";
import Tooltip from "../../../basic/tooltip/Tooltip";

// UTILS
import { GlobalModalView, SettingsView } from "../../../../helpers/Modal";

export const OpenSettingsPopup: FunctionComponent = ({ children }) => {
    const dispatch = useDispatch();

    const onClick = () => {
        dispatch(modalActions.setModalView(GlobalModalView.Settings));
        dispatch(settingsActions.setSettingsView(SettingsView.Learning));
    };

    return (
        <Tooltip
            position="top"
            content={
                <T
                    _str='The feature "Force correct answer" is active.{newLine}You can deactivate it in the {button}'
                    button={
                        <OpenSettingsButton onClick={onClick}>
                            <T _str="settings" />
                        </OpenSettingsButton>
                    }
                    newLine={<br />}
                />
            }
        >
            {children}
        </Tooltip>
    );
};
