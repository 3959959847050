//DEPENDENCIES
import React from "react";
import { useT } from "@transifex/react";

//STYLES
import { Header as StyledHeader } from "../styles";

const Heading = () => {
    const t = useT();

    const learnGrammar_t = t("Learn Grammar _ with phase6 _", {
        _tags: "modal, header, grammarTutor",
    });

    const splitText = learnGrammar_t.split("_");
    const isThirdObsolete = splitText[2].length < 2;

    const formattedText = (
        <>
            {splitText[0]}
            <span>
                {splitText[1]}
                {isThirdObsolete && splitText[2]}
            </span>
            {!isThirdObsolete && splitText[2]}
        </>
    );

    return <StyledHeader>{formattedText}</StyledHeader>;
};

export default Heading;
