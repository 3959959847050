import styled from "styled-components";
import PhaseSixIcon from "../../components/basic/phaseSixIcon/PhaseSixIcon";

export const FullWidthContainer = styled.div`
    position: relative;
    width: calc(100% - ${(props) => props.theme.base.spacingSmall});
    padding: ${(props) => props.theme.base.spacingSmall};
    margin-bottom: ${(props) => props.theme.base.spacingSmall};
    background-color: ${(props) => props.theme.colors.primary};
    color: ${(props) => props.theme.colors.backgroundContrast};
    border-radius: ${(props) => props.theme.base.borderRadiusSmall};
`;
export const CenterContainer = styled.div`
    width: fit-content;
    margin: 0 auto;
    text-align: center;
    display: flex;
    flex-direction: row;
    cursor: pointer;
`;
export const StyledPhaseSixIcon = styled(PhaseSixIcon)`
    color: ${(props) => props.theme.colors.backgroundContrast};
`;
export const CloseContainer = styled.div`
    position: absolute;
    top: 0;
    right: ${(props) => props.theme.base.spacingSmall};
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    cursor: pointer;
`;
export const AdText = styled.span`
    margin-left: ${(props) => props.theme.base.spacingSmall};
    color: ${(props) => props.theme.colors.backgroundContrast};
    font-size: ${(props) => props.theme.base.titleSize};
`;

export const TopWidgets = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: ${(props) => props.theme.base.spacing};
    padding-right: ${(props) => props.theme.base.spacing};
`;

export const TopRow = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: ${(props) => props.theme.base.spacingInContent};
    gap: ${(props) => props.theme.base.spacing};
`;

export const SubjectList = styled.div`
    display: flex;
    background-color: ${(props) => props.theme.colors.backgroundContrast};
    flex: 4;
    padding: ${(props) => props.theme.base.spacing};
    border-radius: ${(props) => props.theme.base.borderRadius};
    flex-direction: column;
    justify-content: space-evenly;
    align-items: stretch;
    align-self: stretch;
    justify-self: stretch;
    @media (max-width: ${process.env.REACT_APP_UPPER_GRID_STEP_BORDER}px) {
        flex: 7;
    }
`;
