const ResponseConstants: { [key: string]: ResponseType } = {
    ERROR: "ERROR",
    WARNING: "WARNING",
    SUCCESS: "SUCCESS",
};

const RESPONSE_TIMEOUT = 5000;

export type ResponseType = "ERROR" | "WARNING" | "SUCCESS";

export default ResponseConstants;
export { RESPONSE_TIMEOUT };
