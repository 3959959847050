// LIBRARIES
import styled from "styled-components";

export const Container = styled.div`
    padding-bottom: ${(props) => props.theme.base.spacingSmall};
    height: 100%;
    width: 100%;
    padding-right: ${(props) => props.theme.base.spacingSmall};
    max-height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
`;
export const TitleContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`;
export const DropdownContainer = styled.div`
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
`;
export const ContentContainer = styled.div`
    width: 100%;
    max-height: 90%;
    flex: 1;
    display: flex;
`;
export const BottomContainer = styled.div`
    width: 100%;
    display: block;
    height: 10px;
`;
