import styled from "styled-components";
import { Col, Row } from "../../../../basic/grid";
import { ActionSave as ParentAS, Input as ParentInput } from "../ModalCreation/styles";
export { ActionsWrapper, ActionClose } from "../ModalCreation/styles";

export const ItemRow = styled(Row)`
    flex-wrap: nowrap;
`;

export const ItemInformationCol = styled(Col)`
    min-width: 0;
`;

export const TitleWrapper = styled.div`
    display: flex;
    align-items: center;
`;

export const Title = styled.h3`
    margin: 0;
    padding: 0;
    color: ${({ theme: { colors } }) => colors.text};
    font-weight: normal;
    font-size: ${({ theme: { base } }) => base.bigSize};
    align-items: center;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
`;

export const FlagRow = styled(Row)`
    display: flex;
    justify-content: end;
    margin-right: 0;
`;

export const FlagCol = styled(Col)`
    padding: ${({ theme: { base } }) => base.spacingInContent};
`;

export const EditIcon = styled.div`
    opacity: 0;

    &:hover {
        color: ${({ theme: { colors } }) => colors.primary};
    }
`;

export const Wrapper = styled.div<{ $edit: boolean }>`
    padding: ${({ theme: { base } }) => base.spacingSmall};
    margin-bottom: ${({ theme: { base } }) => base.spacingInContent};
    background-color: ${({ theme: { colors } }) => colors.backgroundContrast};
    cursor: ${({ $edit }) => ($edit ? "default" : "pointer")};
    border-radius: ${({ theme: { base } }) => base.borderRadiusBig};

    &:hover {
        opacity: 0.8;

        ${EditIcon} {
            opacity: 1;
        }
    }
`;

export const CoverImageCol = styled(Col)`
    line-height: 0;
`;

export const LanguageImage = styled.img`
    height: 24px;
    width: 36px;
    border-radius: ${({ theme: { base } }) => base.borderRadius};
    cursor: pointer;
`;

export const ActionSave = styled(ParentAS)`
    color: ${({ theme: { colors } }) => colors.primary};
`;

export const Input = styled(ParentInput)`
    background-color: ${({ theme: { colors } }) => colors.backgroundMain};
    border-color: ${({ theme: { colors } }) => colors.backgroundMain};
`;
