// REACT
import React, { FunctionComponent, ReactNode } from "react";

// COMPONENTS
import PhaseSixIcon from "../../phaseSixIcon/PhaseSixIcon";
import Popup from "../../popup/Popup";

// LIBRARIES
import { T } from "@transifex/react";

import { SelectedLang } from "./styles";

type Props = {
    selectedLang: string;
    menu: ReactNode;
    isOpen: boolean;
    onPopupToggle: (isOpenState: boolean) => void;
};

const LanguageSelect: FunctionComponent<Props> = (props) => {
    const { selectedLang, menu, isOpen, onPopupToggle } = props;

    return (
        <Popup
            content={menu}
            position={["top", "center"]}
            arrow
            onToggle={onPopupToggle}
        >
            <SelectedLang>
                <T _str={selectedLang} />
                &nbsp; <PhaseSixIcon name={isOpen ? "chevron-up" : "chevron-down"} />
            </SelectedLang>
        </Popup>
    );
};

export default LanguageSelect;
