const logEvent = (eventName: string) => {
    window.dataLayer?.push({ event: "logEvent", eventName: eventName });
};

const setUserId = (userId: string) => {
    window.dataLayer?.push({ event: "setUserId", userId: userId });
};

const setVersionName = (versionName: string) => {
    window.dataLayer?.push({ event: "setVersionName", versionName: versionName });
};

const setUserProperties = (userProperties: { role: string; allRoles: string[] }) => {
    window.dataLayer?.push({ event: "setUserProperties", userProperties: userProperties });
};

const setGaUserId = (userId: string) => {
    window.dataLayer?.push({ gaUserID: userId });
};

const setGaUserRole = (userRole: string) => {
    window.dataLayer?.push({ gaUserRole: userRole });
};

export { logEvent, setUserId, setVersionName, setUserProperties, setGaUserId, setGaUserRole };
