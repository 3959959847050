// REACT
import React, { FunctionComponent, useMemo, useCallback } from "react";
import { useHistory } from "react-router-dom";

// REDUX
import { useDispatch, useSelector } from "react-redux";
import { selectors, actions as learningActions } from "../../../../../redux/learning/learningSlice";

// COMPONENTS
import { Props as ModalProps } from "../../../../basic/modal/Modal";
import Component from "./Autoactivation";

export const Autoactivation: FunctionComponent<Partial<ModalProps>> = (props) => {
    const { slice = 0 } = useSelector(selectors.currentItem) || {};
    const items = useSelector(selectors.filteredItems({ slice, type: ["drag", "memorize"] }));
    const history = useHistory();
    const dispatch = useDispatch();

    const count = useMemo(() => {
        return items.filter(({ resolved }) => !resolved).length;
    }, [items]);

    const onSubmit = useCallback(
        (actions) => () => {
            const { hide } = actions || { hide: () => {} };
            dispatch(learningActions.activateSlice());
            hide();
        },
        [dispatch]
    );

    const onDecline = useCallback(
        (actions) => () => {
            const { hide } = actions || { hide: () => {} };
            history.push("/home");
            hide();
        },
        [history]
    );

    return (
        <Component
            count={count}
            onDecline={onDecline}
            onSubmit={onSubmit}
            {...props}
        />
    );
};
