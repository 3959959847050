import React from "react";
import { T } from "@transifex/react";
import Modal from "../../../components/basic/modal/Modal";
import { Actions, InnerModal, Warning, Title } from "./styles";
import { FilledButton, OutlinedButton } from "../../../components/basic/button/Button";
import { DefaultFocus } from "../../../components/basic/defaultFocus/DefaultFocus";

interface DeleteTestModalProps {
    active: boolean;
    onClose(): void;
    onSubmit(): void;
}

export const DeleteTestModal = ({ active, onClose, onSubmit }: DeleteTestModalProps) => {
    return (
        <Modal
            active={active}
            headerComponent={false}
            afterClose={onClose}
            modalBodyStyle={{
                width: 343,
            }}
        >
            <InnerModal>
                <DefaultFocus />
                <Title>
                    <T
                        _str="Do you really wanna delete?"
                        _tags="tests"
                    />
                </Title>
                <Warning>
                    <T
                        _str="Attention, the process cannot be undone!"
                        _tags="tests"
                    />
                </Warning>
                <Actions>
                    <FilledButton
                        onClick={() => {
                            onSubmit();
                            onClose();
                        }}
                        borderRadius="big"
                    >
                        <T
                            _str="Yes"
                            _tags="tests"
                        />
                    </FilledButton>
                    <OutlinedButton
                        onClick={onClose}
                        borderRadius="big"
                    >
                        <T
                            _str="Cancel"
                            _tags="tests"
                        />
                    </OutlinedButton>
                </Actions>
            </InnerModal>
        </Modal>
    );
};
