import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../store/store";

interface ManageState {
    subjectId: string;
}

const initialState: ManageState = {
    subjectId: "",
};

export const manageSlice = createSlice({
    name: "manage",
    initialState,
    reducers: {
        setSubjectId: (state, action: PayloadAction<string>) => {
            state.subjectId = action.payload;
        },
    },
});

/* EXPORTS */
export const { actions, reducer } = manageSlice;

export const selectors = {
    subjectId: (state: RootState) => state.manage.subjectId,
};
