import styled, { css } from "styled-components";
import PhaseSixIcon from "../../components/basic/phaseSixIcon/PhaseSixIcon";

export const TestsContentContainer = styled.div`
    min-height: 600px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    padding: ${(props) => props.theme.base.spacing};
    border-radius: ${(props) => props.theme.base.borderRadius};
    gap: ${(props) => props.theme.base.spacing};
`;

export const Header = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`;

export const Subject = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`;

export const SubjectTitle = styled.h1`
    color: ${(props) => props.theme.colors.text};
    font-size: ${(props) => props.theme.base.titleBigSize};
    font-weight: 500;
    font-family: ${(props) => props.theme.base.fontFamily};
    margin: 0;
    overflow: hidden;
    text-transform: none;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 100%;
`;
export const SubjectInfo = styled.div`
    color: ${(props) => props.theme.colors.text};
    display: flex;
    gap: ${(props) => props.theme.base.spacing};
    background: ${(props) => props.theme.colors.backgroundContrast};
    border-radius: 15px;
    padding: ${(props) => props.theme.base.spacingInContent};
`;
export const SubjectName = styled.span`
    color: ${(props) => props.theme.colors.text};
    text-align: center;
    vertical-align: center;
    font-weight: bold;
    padding: ${(props) => props.theme.base.spacingInContent} 0;
    font-size: ${(props) => props.theme.base.titleSize};
`;
export const MainSection = styled.div<{ hideBackgroundColor?: boolean }>`
    overflow: hidden;
    display: flex;
    flex: 1;
    flex-direction: column;
    gap: ${(props) => props.theme.base.spacing};
    background: ${(props) => (props.hideBackgroundColor ? "transparent" : props.theme.colors.backgroundContrast)};
    border-radius: ${(props) => props.theme.base.borderRadius};
`;

export const Content = styled.div<{ fullHeight?: boolean }>`
    height: ${(props) => (props.fullHeight ? "100%" : "unset")};
    padding: ${(props) => props.theme.base.spacingInContent};
    background: ${(props) => props.theme.colors.backgroundContrast};
    overflow: ${(props) => (props.fullHeight ? "auto" : "unset")};
`;

export const TabMenu = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
`;
export const Tab = styled.div<{ selected: boolean }>`
    padding: ${(props) => props.theme.base.spacingSmall} ${(props) => props.theme.base.spacing};
    color: ${(props) => props.theme.colors.text};
    border: 1px solid transparent;
    border-bottom: 1px solid ${(props) => props.theme.colors.textLight};
    cursor: pointer;

    ${(props) =>
        props.selected &&
        css`
            color: ${(props) => props.theme.colors.primary};
            border-top: 1px solid ${(props) => props.theme.colors.textLight};
            border-right: 1px solid ${(props) => props.theme.colors.textLight};
            border-bottom: 1px solid transparent;
            border-left: 1px solid ${(props) => props.theme.colors.textLight};

            border-top-left-radius: ${(props) => props.theme.base.borderRadiusSmall};
            border-top-right-radius: ${(props) => props.theme.base.borderRadiusSmall};
        `};
`;

export const TabText = styled.span`
    position: relative;
`;

export const TestsListContainer = styled.div`
    overflow: auto;
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: ${(props) => props.theme.base.spacing};
    > div:last-child {
        border: unset;
        padding: unset;
    }
`;

export const TestListItemContainer = styled.div`
    color: ${(props) => props.theme.colors.text};
    display: flex;
    justify-content: space-between;
    padding-bottom: ${(props) => props.theme.base.spacing};
    align-items: center;
    border-bottom: 1px solid #e8e8e8;
    gap: ${(props) => props.theme.base.spacing};
    > div:last-child {
        border: unset;
        padding: unset;
    }
`;

export const ForwardToDetailsSection = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
`;

export const TestListItemInfo = styled.div`
    display: grid;
    grid-template-columns: auto auto;
    gap: ${(props) => props.theme.base.spacingInContent};
`;

export const TestListItemIcon = styled.div`
    height: 60px;
    min-width: 60px;
    width: 60px;
    border-radius: ${(props) => props.theme.base.borderRadius};
    background: ${(props) => props.theme.colors.backgroundMain};
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const TestListItemDetailsContainer = styled.div`
    display: grid;
    grid-template-rows: 1fr 1fr;
    align-items: center;
`;
export const TestName = styled.h4`
    color: ${(props) => props.theme.colors.text};
    font-size: ${(props) => props.theme.base.bigSize};
    font-weight: ${(props) => props.theme.base.bigWeight};
    margin: 0;
    padding: ${(props) => props.theme.base.spacingTiny} 0;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
`;

export const TotalCards = styled.div`
    display: flex;
    align-items: center;
    font-size: ${(props) => props.theme.base.mediumSize};
    line-height: 1;
    color: ${(props) => props.theme.colors.text};
    gap: ${(props) => props.theme.base.spacingInContent};
`;

export const NoResults = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

export const NoResultsTitle = styled.h4`
    font-size: 24px;
    margin: 0 0 ${(props) => props.theme.colors.spacingInContent};
    color: ${(props) => props.theme.colors.primary};
    font-weight: ${(props) => props.theme.base.weight};
`;

export const NoResultsText = styled.p`
    font-size: ${(props) => props.theme.base.mediumSize};
    color: ${(props) => props.theme.colors.text};
    margin: ${(props) => props.theme.base.spacingInContent} 0 0;
    text-align: center;
`;

export const NoResultsList = styled.ul`
    margin: ${(props) => props.theme.base.spacingInContent} 0 0;
    padding: 0;
    list-style: none;
`;

export const NoResultsListItem = styled.li`
    padding: ${(props) => `${props.theme.base.spacingTiny} ${props.theme.base.spacingInContent}`};
    display: flex;
    gap: ${(props) => props.theme.base.spacingInContent};
    font-size: ${(props) => props.theme.base.mediumSize};
    color: ${(props) => props.theme.colors.text};
`;

export const TestsBtnContainer = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
`;

export const TestsPrimaryBtn = styled.button`
    border: 0;
    outline: none;
    cursor: pointer;
    background: ${(props) => props.theme.colors.primary};
    display: inline-flex;
    justify-content: center;
    align-items: center;
    gap: ${(props) => props.theme.base.spacingTiny};
    border-radius: 50px;
    padding: 10px ${(props) => props.theme.colors.spacing};
    color: white;
    font-size: ${(props) => props.theme.base.mediumSize};
    font-weight: ${(props) => props.theme.base.bigWeight};
    min-width: 188px;
    margin-left: auto;
    height: calc(32px + 2 * 10px);
    &:disabled {
        cursor: unset;
        opacity: 0.5;
    }
`;

export const TestsSecondaryBtn = styled.button`
    border: 0;
    outline: 1px solid ${(props) => props.theme.colors.text};
    color: ${(props) => props.theme.colors.text};
    cursor: pointer;
    background-color: transparent;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    gap: ${(props) => props.theme.base.spacingTiny};
    border-radius: 50px;
    padding: 10px ${(props) => props.theme.colors.spacing};
    font-size: ${(props) => props.theme.base.mediumSize};
    font-weight: ${(props) => props.theme.base.bigWeight};
    min-width: 188px;
`;

export const AddIcon = styled(PhaseSixIcon)`
    color: inherit;
`;

export const LastElementOfListItem = styled.div`
    margin-left: auto;
`;

export const ActionsMenu = styled(PhaseSixIcon)`
    cursor: pointer;
`;

export const ToolTipWrapper = styled.p`
    white-space: normal;
    width: 200px;
    text-align: center;
`;

export const LastResult = styled.p`
    margin-left: auto;
`;

export const NewTestFlag = styled.span`
    border: 1px solid ${({ theme: { colors } }) => colors.primary};
    border-radius: calc(${({ theme: { base } }) => base.borderRadiusBig} * 2);
    color: ${({ theme: { colors } }) => colors.primary};
    font-size: ${({ theme: { base } }) => base.mediumSize};
    display: flex;
    padding: 0 ${({ theme: { base } }) => base.spacingInContent};
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;
