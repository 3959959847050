import styled, { css } from "styled-components";
import PhaseSixIcon from "../../../basic/phaseSixIcon/PhaseSixIcon";
import Popup from "../../../basic/popup/Popup";

export const BarWrapper = styled.span`
    margin-right: ${({ theme: { base } }) => base.spacing};
    cursor: pointer;
    vertical-align: middle;
`;

export const MenuList = styled.ul`
    list-style: none;
    margin: -${({ theme: { base } }) => base.spacingSmall};
    padding: 0;
`;

export const MenuEntryText = styled.span`
    padding-left: ${({ theme: { base } }) => base.spacingSmall};
    font-size: ${({ theme: { base } }) => base.mediumSize};
`;
export const PopupEntryContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0;
    font-size: ${({ theme: { base } }) => base.size};
    white-space: nowrap;
    cursor: pointer;
`;

export const MenuItem = styled.li<{ active?: boolean }>`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0;
    padding: ${({ theme: { base } }) => base.spacingSmall} 24px 0 24px;
    font-size: ${({ theme: { base } }) => base.size};
    white-space: nowrap;
    cursor: pointer;

    ${({ theme: { colors }, active = false }) => `
        color: ${active ? colors.primary : colors.text};
    `}

    &:hover {
        color: ${({ theme: { colors } }) => colors.primary};
    }

    h3 {
        margin: 0;
        text-transform: uppercase;
        font-weight: normal;
        font-size: ${({ theme: { base } }) => base.size};
    }

    p {
        font-size: ${({ theme: { base } }) => base.smallSize};
        margin: 0;
    }

    &:last-of-type {
        padding-bottom: ${({ theme: { base } }) => base.spacingSmall};
    }
`;

export const DirectionSubMenuItem = styled(MenuItem)`
    width: 560px;

    div {
        padding-left: ${({ theme: { base } }) => base.spacingSmall};
    }

    h3 {
        font-size: ${({ theme: { base } }) => base.mediumSize};
    }

    p {
        font-size: ${({ theme: { base } }) => base.size};
        white-space: normal;
    }
`;

export const MenuIcon = styled(PhaseSixIcon)`
    color: inherit;
`;

export const IconContainer = styled.div`
    font-size: 0.7em;
    color: ${(props) => props.theme.colors.text};
`;

export const ContextMenuPopup = styled(Popup)`
    min-width: 356px;
`;

export const ContextMenuHeader = styled.div`
    margin: ${(props) => props.theme.base.spacingBig};
    margin-bottom: 0;
    padding-bottom: ${({ theme: { base } }) => base.spacing};
    border-bottom: 2px solid ${(props) => props.theme.colors.border};
    display: flex;
`;

export const HeaderText = styled.div<{ withCover: boolean }>`
    display: flex;
    flex-direction: column;
    padding-left: ${({ withCover, theme: { base } }) => (withCover ? base.spacing : 0)};
    color: ${(props) => props.theme.colors.text};
`;

const baseTitleStyles = css`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 400px;
`;

export const SubjectTitle = styled.span`
    font-weight: 600;
    font-size: ${({ theme: { base } }) => base.mediumSize};
    ${baseTitleStyles}
`;

export const UnitTitle = styled.span`
    font-size: ${({ theme: { base } }) => base.smallSize};
    ${baseTitleStyles}
`;

export const PublisherImage = styled.img`
    max-height: 28px;
    margin-left: ${({ theme: { base } }) => base.spacingInContent};
`;
