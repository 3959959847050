import styled from "styled-components";

export const Header = styled.div<{ clickable: boolean }>`
    cursor: ${({ clickable }) => (clickable ? "pointer" : "default")};
`;

export const CollapseContent = styled.div`
    display: block;
    height: max-content;
    overflow: hidden;
`;

export const CollapseWrapper = styled.div<{ isOpen: boolean; maxHeight?: number }>`
    display: block;
    overflow: hidden;
    max-height: ${({ isOpen, maxHeight = 0 }) => (isOpen ? maxHeight + "px !important" : "0 !important")};
    transition: max-height 0.3s;
`;
