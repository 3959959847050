// REACT
import React, { useState } from "react";

// LIBRARIES
import { uniqueId } from "lodash";

// COMPONENTS
import CustomToggle from "../customToggle/CustomToggle";
import PhaseSixIcon from "../phaseSixIcon/PhaseSixIcon";

// STYLED COMPONENTS
import { MainWrapper, LabelComponent, ToggleText } from "./styles";

export enum ToggleTypes {
    SwitchButton,
    CheckIcon,
}

export interface SettingsToggleInputProps {
    checkedValue?: boolean;
    labelText: string;
    className?: string;
    toggleType?: ToggleTypes;
    onValueChanged?: (checked?: boolean) => any;
}

const SettingsToggleInput: React.FC<SettingsToggleInputProps> = (props) => {
    const { checkedValue } = props;
    const [id] = useState(uniqueId("toggle-"));
    const { className, onValueChanged } = props;

    function renderToggle() {
        if (props.toggleType === ToggleTypes.CheckIcon && checkedValue) {
            return (
                <PhaseSixIcon
                    name={"exercise-ok"}
                    highlighted
                />
            );
        } else if (props.toggleType === ToggleTypes.SwitchButton) {
            return <CustomToggle checkedValue={checkedValue} />;
        }
    }

    function handleChange() {
        if (onValueChanged) {
            onValueChanged(!checkedValue);
        }
    }

    return (
        <MainWrapper className={className + " toggle-wrapper"}>
            <LabelComponent htmlFor={id}>
                <ToggleText>{props.labelText}</ToggleText>
                {renderToggle()}
            </LabelComponent>
            <input
                type="checkbox"
                id={id}
                checked={checkedValue}
                onChange={() => handleChange()}
                hidden
            />
        </MainWrapper>
    );
};

SettingsToggleInput.defaultProps = {
    toggleType: ToggleTypes.SwitchButton,
};

export default SettingsToggleInput;
