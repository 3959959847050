export type UserAgentType = "Android" | "iOS" | undefined;

export const getMobileOS = () => {
    const ua = navigator.userAgent;
    if (/android/i.test(ua)) {
        return "Android";
    }
    if (/iPad|iPhone|iPod/.test(ua)) {
        return "iOS";
    }
    return undefined;
};
