//LIBRARIES
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { tx } from "@transifex/native";
import dayjs from "dayjs";
// if new languages will be enabled in the WebApp we need to import the dayjs translations for it here as well
import "dayjs/locale/de";

//REDUX
import { RootState } from "../../store/store";

//TYPES
import {
    User,
    IUserMetadata,
    IUserStars,
    UserPreferences,
    UserPremiumExpiration,
    UserGroupsData,
    FamilyMember,
} from "p6m-user";
import { UpdateOperationType } from "p6m-synchronisation";
import {
    ChangeEmailParamsType,
    ChildrenSettingsParams,
    ResendPasswordParamsType,
    SaveUserPreferencesParams,
} from "p6m-networking";
import { AvailableLanguageCodes } from "p6m-dictionary";

//UTILS
import { REHYDRATE } from "redux-persist/lib/constants";
import {
    addPersistedDictionaryLanguage,
    getPersistedDictionaryLanguage,
} from "../../helpers/GetPersistedDictionaryLanguage";

interface UserState {
    user: User;
    userMetadata: IUserMetadata;
    clientId?: string;
    userPreferences: UserPreferences;
    userPremiumExpiration: UserPremiumExpiration;
    userGroups: UserGroupsData;
    userFamilyMembers: Array<FamilyMember>;
    userStars?: IUserStars;
    renderLogoutIframe: boolean;
}

const initialState: UserState = {
    user: {},
    userMetadata: {} as IUserMetadata,
    userPreferences: {} as UserPreferences,
    userPremiumExpiration: {},
    userGroups: {},
    userFamilyMembers: [],
    userStars: undefined,
    renderLogoutIframe: false,
};

export const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        setUser: (state, action: PayloadAction<User>) => {
            const {
                jossoSessionId,
                isFirstWebLogin,
                p6pSessionToken,
                userDnsId,
                email,
                displayName,
                firstName,
                lastName,
                roles,
                registrationDate,
                p6AutologinPassword,
                shopFreeContentsCounts,
                revisionNumber,
                gdprStatus,
                partnerName,
                partnerLogoUrl,
                partnerLogOutUrl,
            } = action.payload;

            state.user = {
                jossoSessionId: jossoSessionId,
                isFirstWebLogin: isFirstWebLogin,
                p6pSessionToken: p6pSessionToken,
                userDnsId: userDnsId,
                email: email,
                displayName: displayName,
                firstName: firstName,
                lastName: lastName,
                roles: roles,
                registrationDate: registrationDate,
                p6AutologinPassword: p6AutologinPassword,
                shopFreeContentsCounts: shopFreeContentsCounts,
                revisionNumber: revisionNumber,
                gdprStatus: gdprStatus,
                partnerName,
                partnerLogoUrl,
                partnerLogOutUrl,
            };
        },
        setClientId: (state, action: PayloadAction<string>): void => {
            state.clientId = action.payload;
        },
        setJossoSessionId: (state, action: PayloadAction<string>): void => {
            state.user.jossoSessionId = action.payload;
        },
        setUserNames: (
            state,
            action: PayloadAction<{
                displayName: string;
                firstName: string;
                lastName: string;
            }>
        ) => {
            state.user.displayName = action.payload.displayName;
            state.user.firstName = action.payload.firstName;
            state.user.lastName = action.payload.lastName;
        },
        setUserEmail: (state, action: PayloadAction<string>) => {
            state.user.email = action.payload;
        },
        setUserRoles: (state, action: PayloadAction<string[]>) => {
            state.user.roles = action.payload;
        },
        setUserMetadata: (state, action: PayloadAction<IUserMetadata>) => {
            state.userMetadata = action.payload;
        },
        setUserPartnerInformation: (
            state,
            {
                payload,
            }: PayloadAction<{
                partnerName: string;
                partnerLogoUrl?: string;
                partnerLogOutUrl?: string;
            }>
        ) => {
            const { partnerName, partnerLogoUrl, partnerLogOutUrl } = payload;
            state.user.partnerName = partnerName;
            state.user.partnerLogoUrl = partnerLogoUrl;
            state.user.partnerLogOutUrl = partnerLogOutUrl;
        },
        setLastTimeGDPRModalChecked: (state, action: PayloadAction<Date>) => {},
        setVisitedReactAppInMetaData: (state, action: PayloadAction<boolean>) => {},
        setEnableGrammarTutorPromotionInMetaData: (state, action: PayloadAction<boolean>) => {},
        setUserPhaseActive: (state, { payload }: PayloadAction<any>) => {
            if (!state.userMetadata || !state.userMetadata.phasesShownData) return;
            state.userMetadata.phasesShownData[payload] = true;
        },
        setUserPreferences: (state, action: PayloadAction<UserPreferences>) => {
            state.userPreferences = action.payload;
            updateAppPreferencesAsync(state.userPreferences);
        },
        setUserPremiumExpiration: (state, action: PayloadAction<UserPremiumExpiration>) => {
            state.userPremiumExpiration = action.payload;
        },
        logout: () => {
            sessionStorage.removeItem("switchInfoModalClosed");
        },
        unsetUser: (state) => {
            state.user = {};
            state.userPreferences = {} as UserPreferences;
            state.userMetadata = {} as IUserMetadata;
            state.userPremiumExpiration = {};
            state.userGroups = {};
            state.userFamilyMembers = [];
        },
        logoutUserWithoutPermission: (state) => {},
        changeEmail: (state, action: PayloadAction<ChangeEmailParamsType>) => {},
        confirmEmail: (state, action: PayloadAction<ResendPasswordParamsType>) => {},
        resendPassword: (state, action: PayloadAction<ResendPasswordParamsType>) => {},
        fetchUserStars: () => {},
        setUserStars: (state, action: PayloadAction<IUserStars>): void => {
            state.userStars = action.payload;
        },
        fetchAddUserStar: () => {},
        fetchRemoveUserStar: () => {},
        clearUserStars: (state) => {
            state.userStars = undefined;
        },
        loadUserGroups: () => {},
        setUserGroups: (state, action: PayloadAction<UserGroupsData>) => {
            state.userGroups = action.payload;
        },
        setUserFamilyMembers: (state, action: PayloadAction<Array<FamilyMember>>) => {
            const parentUserId = state.user.userDnsId;
            state.userFamilyMembers = action.payload.filter((fm) => fm.userId !== parentUserId);
        },
        updateChildPreferences: (state, action: PayloadAction<UserPreferences & { userId: string }>) => {
            const childIndex = state.userFamilyMembers.findIndex((u) => u.userId === action.payload.userId);
            if (childIndex > -1) {
                state.userFamilyMembers[childIndex].preferences = action.payload;
                return state;
            }
        },
        refreshPreferencesData: (state, action: PayloadAction<string>) => {},
        saveUserPreferences: (state, action: PayloadAction<SaveUserPreferencesParams>) => {},
        saveChildrenPreferences: (state, action: PayloadAction<ChildrenSettingsParams>) => {},
        loadUserMetadata: (state, action: PayloadAction) => {},
        startSynchronization: (state, action: PayloadAction) => {},
        endSynchronization: (state, action: PayloadAction) => {},
        updateUserRoles: (
            state,
            action: PayloadAction<{
                updatedType: UpdateOperationType;
                roles: string[];
            }>
        ) => {
            let roles = state.user.roles;
            if (roles && action.payload.updatedType === "DELETE") {
                roles = roles.filter((role) => action.payload.roles.every((payloadRole) => payloadRole !== role));
                if (roles.length === 0) roles = undefined;
            } else if (action.payload.updatedType === "CREATE_UPDATE") {
                if (!roles) roles = [];
                roles = roles.concat(action.payload.roles);
            }
            state.user.roles = roles;
            return state;
        },
        jossoUpdate: () => {},
        addLogoutIframe: (state) => {
            state.renderLogoutIframe = true;
        },
        removeLogoutIframe: (state) => {
            state.renderLogoutIframe = false;
        },
        setDictionaryLanguage: (state, action: PayloadAction<AvailableLanguageCodes>) => {
            const userId = state.user.userDnsId;
            userId && addPersistedDictionaryLanguage({ language: action.payload, userId });
        },
    },
    extraReducers: {
        [REHYDRATE]: (state, store) => {
            if (store.payload && store.payload.userPreferences) {
                updateAppPreferencesAsync(store.payload.userPreferences);
            }
        },
    },
});

async function updateAppPreferencesAsync(preferences: UserPreferences) {
    let localeToSet = "de";
    if (preferences && preferences.hasOwnProperty("lang")) {
        const languages = await tx.getLanguages();
        const userLanguage = languages.find(
            (l: any) => l.code === preferences.lang || (l.code === "en_GB" && preferences.lang === "en")
        );
        if (userLanguage) {
            localeToSet = userLanguage.code;
        }
    }

    if (tx.getCurrentLocale() !== localeToSet) {
        await tx.setCurrentLocale(localeToSet);
    }

    if (dayjs.locale() !== preferences.lang) {
        const locale = preferences.lang?.split("_")[0];
        await dayjs.locale(locale);
    }
}

/* EXPORTS */
export const { actions, reducer } = userSlice;

export const selectors = {
    user: (state: RootState) => state.user.user,
    userId: (state: RootState) => state.user.user.userDnsId,
    email: (state: RootState) => state.user.user.email,
    jossoId: (state: RootState) => state.user.user.jossoSessionId,
    userGroups: (state: RootState) => state.user.userGroups,
    userGroupsList: (state: RootState) => state.user.userGroups.groups,
    userMetadata: (state: RootState) => state.user.userMetadata,
    userStars: (state: RootState) => state.user.userStars,
    userPreferences: (state: RootState) => state.user.userPreferences,
    userPremiumExpiration: (state: RootState) => state.user.userPremiumExpiration,
    userFamilyMembers: (state: RootState) => state.user.userFamilyMembers,
    isTeacher: (state: RootState) => !!state.user.user.roles?.includes("teacher"),
    isParent: (state: RootState) => !!state.user.user.roles?.includes("parent"),
    isChild: (state: RootState) => !!state.user.user.roles?.includes("student"),
    isStrict: (state: RootState) => !!state.user.user.roles?.includes("p6o.p6o2.strict"),
    isBetaInvited: (state: RootState) => !!state.user.user.roles?.includes("p6o.p6o2.betainvite"),
    isForceSwitched: (state: RootState) => !!state.user.user.roles?.includes("p6o.p6o2.react-switch"),
    hasGrammarTutor: (state: RootState) => !!state.user.user.roles?.includes("p6o.p6o2.grammar"),
    isGrammarTutorInvited: (state: RootState) => !!state.user.user.roles?.includes("p6o.p6o2.grammar-invite"),
    hasGrammarTutorPromotionEnabled: (state: RootState) => {
        const enableGrammarTutorPromotion = state.user.userMetadata.enableGrammarTutorPromotion;
        return enableGrammarTutorPromotion === undefined || !!enableGrammarTutorPromotion;
    },
    isUserAnonymous: (state: RootState) => {
        if (!state.user.user.email || !state.user.user.firstName) return true;
        return (
            !state.user.user.firstName.length ||
            state.user.user.firstName === "Anonymous" ||
            state.user.user.email.indexOf("@phase-6.org") >= 0
        );
    },
    userHasPremium: (state: RootState) => !!state.user.user.roles && state.user.user.roles.includes("p6o.p6o2.sync"),
    userIsGroupAdmin: (state: RootState) => {
        const isParent = !!state.user.user.roles && state.user.user.roles.includes("parent");
        const isTeacher = !!state.user.user.roles && state.user.user.roles.includes("teacher");
        const isFamilyAdmin =
            !!state.user.userGroups &&
            !!state.user.userGroups.groups &&
            state.user.userGroups.groups.some((g) => g.type === "Family" && g.isAdmin);
        return isParent || isTeacher || isFamilyAdmin;
    },
    shopFreeContentsCounts: (state: RootState) => state.user.user.shopFreeContentsCounts,
    getUserAvatarId: (state: RootState) => {
        let preferences = state.user.userPreferences;
        //@ts-ignore
        if (!!preferences.replyContent) {
            //@ts-ignore
            preferences = preferences.replyContent;
        }

        return preferences.userAvatarId;
    },
    isInRole:
        (rolesToFind: string | string[]) =>
        (state: RootState): boolean => {
            const {
                user: {
                    user: { roles = [] },
                },
            } = state;
            if (!roles) return false;
            if (typeof rolesToFind === "string") rolesToFind = [rolesToFind];
            return !!rolesToFind.find((roleToFind) => roles.includes(roleToFind));
        },
    getUserPhases: (state: RootState) => {
        let preferences = state.user.userPreferences;
        //@ts-ignore
        if (!!preferences.replyContent) {
            //@ts-ignore
            preferences = preferences.replyContent;
        }

        return preferences.phases?.phaseList || [];
    },
    getUserShownPhases: (state: RootState) => selectors.userMetadata(state).phasesShownData,
    partnerName: (state: RootState) => state.user.user.partnerName,
    partnerLogoUrl: (state: RootState) => state.user.user.partnerLogoUrl,
    partnerLogOutUrl: (state: RootState) => state.user.user.partnerLogOutUrl,
    renderLogoutIframe: (state: RootState) => state.user.renderLogoutIframe,
    dictionarySearchLanguage: (state: RootState) => getPersistedDictionaryLanguage(state.user.user.userDnsId),
};
