// LIBRARIES
import React, { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useT } from "@transifex/react";
import { ampli } from "../../../ampli";

//REDUX
import { actions as modalActions, selectors as modalSelectors } from "../../../redux/modal/modalSlice";
import { selectors as userSelectors } from "../../../redux/user/userSlice";
import { actions as responseAction } from "../../../redux/response/responseSlice";
import { actions as subjectsActions } from "../../../redux/subjects/subjectsSlice";

//QUERIES AND MUTATIONS
import { moveCardsToSubjectAndUnit } from "../../../networking/subjects";
import { postUnit } from "../../../networking/units";

//HOOKS
import { useEditDropdown } from "../../../hooks/dropdown/useEditDropdown";

//HELPERS
import { GlobalModalView } from "../../../helpers/Modal";
import { getNextUnitOrder, isDefaultUnit, getDefaultUnitId } from "../../../helpers/Units";

//TYPES
import { IdToOwnerId, SubjectData } from "p6m-subjects";
import { User } from "p6m-user";

//COMPONENTS
import ChangeCardSubjectModal from "./ChangeCardSubjectModal";

type InitialMessageParams = {
    subjectName: string;
    unitName: string;
    moveCount: number;
    duplicateCount: number;
};

const initialMessageParams: InitialMessageParams = {
    subjectName: "",
    unitName: "",
    moveCount: 0,
    duplicateCount: 0,
};

const ChangeCardSubjectModalWrapper: React.FC = (props) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const t = useT();

    const user: User = useSelector(userSelectors.user);

    const {
        cardIds,
        initialSubjectId,
        containsProtectedCardIds,
        refreshCardList,
    }: {
        cardIds: IdToOwnerId[];
        initialSubjectId: string;
        containsProtectedCardIds?: boolean;
        refreshCardList?: () => void;
    } = useSelector(modalSelectors.data);

    const [selectedSubjectId, setSelectedSubjectId] = useState<string>(initialSubjectId);
    const [selectedUnitId, setSelectedUnitId] = useState<string>("");

    const onAutoSelectSubject = useCallback(
        (id: string) => {
            setSelectedSubjectId(id);
        },
        [dispatch]
    );

    const onAutoSelectUnit = useCallback(
        (id: string) => {
            setSelectedUnitId(id);
        },
        [dispatch]
    );

    const {
        subjects: { dropdownSubjectLabel, saveSubjectsEdit, allSubjects, selectedSubject, dropdownSubjectItems },
        units: { dropdownUnitLabel, saveUnitsEdit, dropdownUnitItems },
    } = useEditDropdown({
        subjectId: selectedSubjectId,
        unitId: selectedUnitId,
        confirmDeletions: false,
        onAutoSelectSubject,
        onAutoSelectUnit,
    });

    const nonFilteredSubjectsIdMap: { [key: string]: SubjectData } = allSubjects.reduce(
        (acc, s) => ({
            ...acc,
            [s.subjectMetadata.subjectIdToOwner.id]: s,
        }),
        {}
    );

    const [messageParams, setMessageParams] = useState<InitialMessageParams>(initialMessageParams);

    const defaultUnit_t = t("General", {});
    const noUserId_t = t("Your userId could not be recognized", {
        _tags: "library,edit,response",
    });
    const cardsChangedSuccessfully_t = t(
        "{X} card(s) were moved to subject {subject_name} and assigned to unit {unit_name}.",
        {
            _tags: "library,edit,response",
            X: messageParams.moveCount,
            subject_name: messageParams.subjectName,
            unit_name: messageParams.unitName,
        }
    );
    const cardsChangedWarning_t = t(
        "{X} card(s) were moved to subject {subject_name} and assigned to unit {unit_name}. {newline}{Y} of {Z} card(s) could not be moved because they already exist in the chosen destination",
        {
            _tags: "library,edit,response.",
            X: messageParams.moveCount,
            subject_name: messageParams.subjectName,
            unit_name: messageParams.unitName,
            Y: messageParams.duplicateCount,
            Z: messageParams.duplicateCount + messageParams.moveCount,
            newline: <br />,
        }
    );
    const cardsChangedUnsuccessfully_t = t(
        "{Y} of {Z} card(s) could not be moved because they already exist in the chosen destination.",
        {
            _tags: "library,edit,response",
            Y: messageParams.duplicateCount,
            Z: messageParams.duplicateCount + messageParams.moveCount,
        }
    );
    const cardChangeFailed_t = t("Changing cards data failed", {
        _tags: "library,edit,response",
    });

    const selectSubject = useCallback(
        (selectedIds: string[]) => {
            if (dropdownSubjectItems.length && selectedIds[0]) {
                setSelectedSubjectId(selectedIds[0]);
            }
        },
        [dropdownSubjectItems]
    );

    const selectUnit = useCallback(
        (selectedIds: string[]) => {
            if (dropdownUnitItems.length && selectedIds[0]) {
                setSelectedUnitId(selectedIds[0]);
            }
        },
        [dropdownUnitItems]
    );

    const close = () => {
        dispatch(modalActions.setModalView(GlobalModalView.None));
    };

    const saveCardsAssignment = useCallback(async () => {
        const userId = user.userDnsId;
        if (userId && selectedSubject) {
            const unitToSave = selectedSubject.units?.find((unit) => unit.unitId.id === selectedUnitId);
            let selectedUnitIdToOwnerId: IdToOwnerId = {
                id: selectedUnitId,
                ownerId: unitToSave ? unitToSave.unitId.ownerId : userId,
            };

            if (!selectedUnitId) {
                const defaultUnit = selectedSubject.units?.find((unit) => isDefaultUnit(unit));
                if (defaultUnit) {
                    // move to existing general unit
                    selectedUnitIdToOwnerId = defaultUnit.unitId;
                } else {
                    // create general unit
                    const newDefaultUnitId = getDefaultUnitId(selectedSubjectId);
                    const highestOrder = getNextUnitOrder(selectedSubject.units);

                    await postUnit({
                        ownerId: userId,
                        unitId: newDefaultUnitId,
                        subjectId: selectedSubjectId,
                        name: defaultUnit_t,
                        order: highestOrder,
                    });
                    dispatch(
                        subjectsActions.loadSubjectUnits({
                            subjectId: selectedSubjectId,
                            target: "librarySubjects",
                        })
                    );

                    selectedUnitIdToOwnerId = { id: newDefaultUnitId, ownerId: userId };
                }
            }
            try {
                let moveCardsCounter: number = 0;
                let duplicateCardsCounter: number = 0;

                const selectedSubjectIdToOwnerIds =
                    nonFilteredSubjectsIdMap[selectedSubjectId].subjectMetadata.subjectIdToOwner;

                const movedCardsResponses = await moveCardsToSubjectAndUnit(
                    initialSubjectId,
                    cardIds,
                    selectedSubjectIdToOwnerIds,
                    selectedUnitIdToOwnerId,
                    userId
                );

                movedCardsResponses.forEach((res) =>
                    res.data.httpCode === 200 ? moveCardsCounter++ : duplicateCardsCounter++
                );

                if (selectedSubjectIdToOwnerIds.id !== initialSubjectId) {
                    ampli.clickedEditCardsButton({
                        cards_number: moveCardsCounter,
                        user_action: "changeSubject",
                    });
                } else if (selectedUnitIdToOwnerId) {
                    ampli.clickedEditCardsButton({
                        cards_number: moveCardsCounter,
                        user_action: "changeUnit",
                    });
                }

                setMessageParams(() => {
                    return {
                        subjectName: selectedSubject.subjectContent.name,
                        unitName: unitToSave ? unitToSave.unitContent.name : defaultUnit_t,
                        moveCount: moveCardsCounter,
                        duplicateCount: duplicateCardsCounter,
                    };
                });
            } catch (e) {
                dispatch(
                    responseAction.showResponse({
                        type: "ERROR",
                        text: [cardChangeFailed_t],
                    })
                );
            }
        } else if (!userId) {
            dispatch(
                responseAction.showResponse({
                    type: "ERROR",
                    text: [noUserId_t],
                })
            );
        }
        close();
    }, [
        dispatch,
        close,
        cardChangeFailed_t,
        noUserId_t,
        defaultUnit_t,
        user,
        selectedSubject,
        selectedSubjectId,
        selectedUnitId,
        initialSubjectId,
        cardIds,
    ]);

    useEffect(() => {
        // use current state of messageParams for useT-strings with parameters for responseActions-text
        if (!messageParams.subjectName) return; // return if no changes made
        if (messageParams.moveCount === cardIds.length) {
            dispatch(
                responseAction.showResponse({
                    type: "SUCCESS",
                    text: [cardsChangedSuccessfully_t],
                })
            );
            refreshCardList?.();
        } else if (messageParams.moveCount > 0) {
            dispatch(
                responseAction.showResponse({
                    type: "WARNING",
                    text: [cardsChangedWarning_t],
                })
            );
        } else if (!messageParams.moveCount) {
            dispatch(
                responseAction.showResponse({
                    type: "ERROR",
                    text: [cardsChangedUnsuccessfully_t],
                })
            );
        } else {
            dispatch(
                responseAction.showResponse({
                    type: "ERROR",
                    text: [cardChangeFailed_t],
                })
            );
        }

        setMessageParams(() => initialMessageParams);

        if (initialSubjectId === selectedSubjectId) {
            dispatch(subjectsActions.loadSubjectUnits(selectedSubjectId));
        } else {
            history.push(`/manage/${selectedSubjectId}`);
        }
    }, [
        messageParams,
        dispatch,
        cardIds.length,
        initialSubjectId,
        cardsChangedSuccessfully_t,
        cardsChangedWarning_t,
        cardsChangedUnsuccessfully_t,
        cardChangeFailed_t,
        history,
        selectedSubjectId,
        refreshCardList,
    ]);

    return (
        <ChangeCardSubjectModal
            allSubjects={allSubjects}
            dropdownSubjectItems={dropdownSubjectItems}
            dropdownUnitItems={dropdownUnitItems}
            selectedSubjectId={selectedSubjectId}
            selectedUnitId={selectedUnitId}
            containsProtectedCards={containsProtectedCardIds}
            subjectLabel={dropdownSubjectLabel}
            unitLabel={dropdownUnitLabel}
            selectSubject={selectSubject}
            selectUnit={selectUnit}
            saveSubjectsEdit={saveSubjectsEdit}
            saveUnitsEdit={saveUnitsEdit}
            saveCardsAssignment={saveCardsAssignment}
            close={close}
        />
    );
};

export default ChangeCardSubjectModalWrapper;
