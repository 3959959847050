// LIBRARIES
import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ampli } from "../../../ampli";

//REDUX
import { actions as modalActions } from "../../../redux/modal/modalSlice";
import { selectors as userSelectors, actions as userActions } from "../../../redux/user/userSlice";

//TYPES
import { GlobalModalView } from "../../../helpers/Modal";

//COMPONENTS;
import GrammarTutorPromotionModal from "./GrammarTutorPromotionModal";

const GrammarTutorPromotionModalWrapper = () => {
    const dispatch = useDispatch();

    const jossoSessionId = useSelector(userSelectors.jossoId);

    const closeModal = useCallback(() => {
        dispatch(modalActions.setModalView(GlobalModalView.None));
    }, [dispatch]);

    const disableGrammarTutorPromotion = useCallback(() => {
        dispatch(userActions.setEnableGrammarTutorPromotionInMetaData(false));
        closeModal();
    }, [dispatch, closeModal]);

    const handleGoToGrammarTutor = useCallback(() => {
        const grammarTutorUrl = process.env.REACT_APP_GRAMMAR_TUTOR_AUTOLOGIN_URL;

        if (grammarTutorUrl && jossoSessionId) {
            ampli.clickedWebAppToGrammarLink({
                click_source: "grammar_trainer_popup_web",
            });
            window.open(`${grammarTutorUrl}${jossoSessionId}`);
            closeModal();
        }
    }, [jossoSessionId, closeModal]);

    return (
        <GrammarTutorPromotionModal
            onGoToGrammarTutor={handleGoToGrammarTutor}
            onDisable={disableGrammarTutorPromotion}
            onClose={closeModal}
        />
    );
};

export default GrammarTutorPromotionModalWrapper;
