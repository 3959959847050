import styled from "styled-components";

export const Container = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: stretch;
`;
export const InfoHighlight = styled.span`
    color: ${(props) => props.theme.colors.primary};
`;
export const LimitContainer = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
`;
export const LimitLabel = styled.span`
    padding-bottom: ${(props) => props.theme.base.spacingSmall};
    text-align: center;
    color: ${(props) => props.theme.colors.text};
    font-size: ${(props) => props.theme.base.bigSize};
`;

export const List = styled.div`
    margin-top: 25px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
`;
export const Entry = styled.div<{ header?: boolean; selected?: boolean }>`
    padding: ${(props) => props.theme.base.spacing} 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: ${(props) => (props.selected ? props.theme.colors.primary : props.theme.colors.text)};
    border-top: ${(props) => (props.header ? 0 : "1px")} solid ${(props) => props.theme.colors.textLight};
    cursor: ${(props) => (props.header ? "auto" : "pointer")};

    > span {
        font-weight: ${(props) => (props.header ? "bold" : "normal")};
    }
`;
export const DateText = styled.span`
    flex: 1;
`;
export const DueText = styled.span`
    flex: 3;
    text-align: center;
`;
