import React, { FunctionComponent, memo } from "react";
import Response from "../../components/connected/response";
import { Header } from "../../components/complex/learning/Header";
import { Modals } from "../../components/complex/learning/Modals";
import { Cards } from "../../components/complex/learning/Cards";
import PracticeContextWrapper from "../../context/practice";

import { PracticeWrapper } from "./styles";

const Practice: FunctionComponent = () => {
    return (
        <>
            <PracticeContextWrapper>
                <PracticeWrapper>
                    <Response />
                    <Header />
                    <Cards />
                </PracticeWrapper>
                <Modals />
            </PracticeContextWrapper>
        </>
    );
};

export default memo(Practice);
