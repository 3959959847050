// LIBRARIES
import React, { useCallback, useMemo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { orderBy } from "lodash";

// REDUX
import { actions, selectors } from "../../../redux/tests/testsSlice";
import { actions as subjectActions } from "../../../redux/subjects/subjectsSlice";

//HOOKS
import { useGetSearchParams } from "../../../hooks/useGetSearchParams";

// COMPONENTS
import Component from "./TestsOverview";

// TYPES
import { TestListItemInfo, TestsType } from "p6m-tests";

//STYLES
import { TestsContentContainer } from "../styles";

const TestsOverview: React.FC = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const { overviewType: overviewTypeParam } = useGetSearchParams() as { overviewType: TestsType };
    const subject = useSelector(selectors.subject);

    const subjectId = useMemo(() => subject?.subjectMetadata.subjectIdToOwner.id || "", [subject]);
    const ownTests = useSelector(selectors.ownTestsBySubjectId(subjectId));
    const sharedTests = useSelector(selectors.sharedTestsBySubjectId(subjectId));

    const activationMetaData = useSelector(selectors.activationMetaData);

    const sortTests = useCallback((testList: TestListItemInfo[]) => {
        return orderBy(
            testList,
            [
                //primary grouping-method
                (item: TestListItemInfo) => (item.lastTestResultPercent == -1 ? 0 : 1),
                //secondary grouping-method
                "modificationDate",
            ],
            [
                //primary sorting-order - new tests first
                "asc",
                //primary sorting-order - last practiced tests first
                "desc",
            ]
        );
    }, []);

    const ownTestsSorted = sortTests(ownTests);
    const sharedTestsSorted = sortTests(sharedTests);

    const [activeOverviewScreenType, setActiveOverviewScreenType] = useState<TestsType>(
        overviewTypeParam || "OWN_TESTS"
    );

    const changeOverviewScreen = useCallback(
        (type: TestsType) => {
            setActiveOverviewScreenType(type);
        },
        [dispatch]
    );

    const addTest = useCallback(() => {
        history.push(`/prepare-test/${subjectId}`);
    }, [history, subjectId]);

    useEffect(() => {
        dispatch(actions.fetchTests());
    }, [dispatch]);

    useEffect(() => {
        if (!!activationMetaData) dispatch(actions.setActivationMetaData(null));
    }, [activationMetaData]);

    useEffect(() => {
        if (!subject?.units) {
            dispatch(subjectActions.loadSubjectUnits(subjectId));
        }
    }, [subject]);

    return (
        <TestsContentContainer>
            {subject && (
                <Component
                    overviewScreenType={activeOverviewScreenType}
                    selectedSubject={subject}
                    ownTests={ownTestsSorted}
                    sharedTests={sharedTestsSorted}
                    onChangeOverviewScreen={changeOverviewScreen}
                    onAddTest={addTest}
                />
            )}
        </TestsContentContainer>
    );
};

export default TestsOverview;
