// REACT
import React, { FunctionComponent, useContext } from "react";
import { useT, T } from "@transifex/react";

// COMPONENTS
import ActivationLayout from "../../complex/activationLayout/ActivationLayout";
import { Row, Col } from "../../basic/grid";
import { getSubjectCover } from "../../../helpers/Subjects";
import PhaseSixIcon from "../../basic/phaseSixIcon/PhaseSixIcon";

// UTILS
import { LanguagesContext } from "../../../context/languages";

// TYPES
import { SubjectData } from "p6m-subjects";

// STYLED COMPONENTS
import {
    SummaryImage,
    SummaryTitle,
    SummaryTypeWrapper,
    RadioWrapper,
    Radio,
    SummaryTypeTitle,
    SummaryTypeDescr,
    LanguageWrapper,
    FlagCol,
    Flag,
    LangBox,
    NoSwapText,
    UnitCardsInfo,
    UnitCardsTitle,
    UnitCardsText,
    SummaryWrapper,
} from "./styles";

export type Props = {
    subject: SubjectData;
    canSwap: boolean;
    isPrepareForTest: boolean;
    type?: number;
    direction?: number;
    unitNamesString: string;
    cardsCount: number;
    allCardsCount: number;
    onChange: (result: { type?: number; direction?: number }) => void;
};

const ActivationSummary: FunctionComponent<Props> = (props) => {
    const { getFlagSource } = useContext(LanguagesContext);
    const {
        subject,
        canSwap,
        isPrepareForTest,
        type = 0,
        direction = 0,
        unitNamesString,
        cardsCount,
        allCardsCount,
        onChange,
    } = props;
    const t = useT();

    const {
        subjectContent: { name, primaryLang, secondaryLang },
    } = subject;

    const cover: string = getSubjectCover(subject);

    const t_title = t("Summary", {});
    const t_type_titles = [
        t("Memorise the card at first (recommended)", {}),
        t("Memorise through assignment", {}),
        t("Mixed practice", {}),
        t("Practise immediately", {}),
    ];
    const t_prepare_titles = [
        t("Add automatically new cards to regular practice", {}),
        t("Practice only for a test", {}),
    ];
    const t_type_descriptions = [
        t("Copy the card content once.", {}),
        t("Match the cards by drag & drop.", {}),
        t("Activate first and practise after in stacks of 5 cards.", {}),
        t("Start immediately with the regular practice.", {}),
    ];
    const t_prepare_descriptions = [
        t("Add automatically new cards to regular practice", {}),
        t("Don't add new vocabulary automatically to regular practice", {}),
    ];
    const t_noSwap = t("The language direction(s) of one or more card(s) can't be changed", {});

    const directions = [
        `${primaryLang} - ${secondaryLang}`,
        t("Both learning directions", {}),
        `${secondaryLang} - ${primaryLang}`,
    ];

    const summaryTypes = (isPrepareForTest ? t_prepare_titles : t_type_titles).map((title: string, i: number) => {
        const description = isPrepareForTest ? t_prepare_descriptions[i] : t_type_descriptions[i];
        return { title, description };
    });

    return (
        <ActivationLayout title={t_title}>
            <SummaryWrapper>
                <Row>
                    <Col xs="auto">
                        <SummaryImage src={cover} />
                    </Col>
                    <Col>
                        <SummaryTitle>{name}</SummaryTitle>
                        {isPrepareForTest && (
                            <>
                                <UnitCardsInfo>
                                    <UnitCardsTitle>
                                        <b>
                                            <T _str="Chosen units:" />
                                        </b>
                                    </UnitCardsTitle>
                                    <UnitCardsText>{unitNamesString}</UnitCardsText>
                                </UnitCardsInfo>
                                <UnitCardsInfo>
                                    <UnitCardsTitle>
                                        <b>
                                            <T _str="Selected cards:" />
                                        </b>
                                    </UnitCardsTitle>
                                    <p>
                                        <T
                                            _str="{cards} of {count} the available cards selected"
                                            cards={cardsCount}
                                            count={allCardsCount}
                                        />
                                    </p>
                                </UnitCardsInfo>
                                <SummaryTitle>
                                    <T _str="Practice mode" />
                                </SummaryTitle>
                            </>
                        )}
                        <SummaryTypeWrapper>
                            {summaryTypes.map(({ title, description }, i: number) => (
                                <RadioWrapper
                                    key={i}
                                    active={type === i}
                                >
                                    <Row vertical="center">
                                        <Col xs="auto">
                                            <input
                                                type="radio"
                                                name="radio-input"
                                                value={i}
                                                checked={type === i}
                                                onChange={(e: any) => {
                                                    const {
                                                        target: { value },
                                                    } = e;
                                                    onChange({ type: parseInt(value) });
                                                }}
                                            />
                                            <Radio />
                                        </Col>
                                        <Col>
                                            <SummaryTypeTitle>{title}</SummaryTypeTitle>
                                            <SummaryTypeDescr>{description}</SummaryTypeDescr>
                                        </Col>
                                    </Row>
                                </RadioWrapper>
                            ))}
                        </SummaryTypeWrapper>
                        {canSwap && (
                            <LanguageWrapper>
                                <Row
                                    horizontal="between"
                                    vertical="center"
                                    cols={{ xs: "auto" }}
                                >
                                    <FlagCol>
                                        <Flag src={getFlagSource(primaryLang)} />
                                    </FlagCol>
                                    <Col>
                                        <T _str="Choose language direction" />:
                                    </Col>
                                    <FlagCol>
                                        <Flag
                                            right
                                            src={getFlagSource(secondaryLang)}
                                        />
                                    </FlagCol>
                                </Row>
                                <Row>
                                    {["arrow-right", "arrows-right-left", "arrow-left"].map(
                                        (iconName: any, i: number) => (
                                            <Col
                                                xs="auto"
                                                key={i}
                                            >
                                                <LangBox>
                                                    <input
                                                        type="radio"
                                                        name="language-radio-input"
                                                        value={i}
                                                        checked={direction === i}
                                                        onChange={(e: any) => {
                                                            const {
                                                                target: { value },
                                                            } = e;
                                                            onChange({ direction: parseInt(value) });
                                                        }}
                                                    />
                                                    <Radio />
                                                    <PhaseSixIcon name={iconName} />
                                                </LangBox>
                                            </Col>
                                        )
                                    )}
                                </Row>
                                <br />
                                <Row
                                    horizontal="center"
                                    cols={{ xs: "auto" }}
                                >
                                    <Col>
                                        <T _str="Language direction" />
                                        :&nbsp;
                                        {directions[direction]}
                                    </Col>
                                </Row>
                            </LanguageWrapper>
                        )}
                        {!canSwap && <NoSwapText>{t_noSwap}</NoSwapText>}
                    </Col>
                </Row>
            </SummaryWrapper>
        </ActivationLayout>
    );
};

export default ActivationSummary;
