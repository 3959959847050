import React, { useMemo } from "react";
import { useT } from "@transifex/react";
import { GoalsData } from "p6m-goalsData";
import { actions as modalActions } from "../redux/modal/modalSlice";
import { GlobalModalView, SettingsView } from "../helpers/Modal";
import { actions as settingsActions } from "../redux/settings/settingsSlice";
import { ResendPasswordParamsType } from "p6m-networking";
import { actions as userActions, selectors as userSelectors } from "../redux/user/userSlice";
import { selectors as goalsSelectors } from "../redux/goals/goalsSlice";
import { useDispatch, useSelector } from "react-redux";

const initialGoals: GoalsData = {
    friendsReferedCount: {
        // this goal is not used anymore
        title: "",
        link: undefined,
        icon: "",
        additionalInfoDue: "",
        additionalInfoDone: "",
    },
    firstPracticeFinished: {
        title: "Finished first practice",
        link: undefined,
        icon: "challenge-award",
        additionalInfoDue: "Unlock leaderboards",
        additionalInfoDone: "Leaderboards unlocked",
    },
    finished1GtTopic: {
        title: "Finish first grammar topic",
        onClick: undefined,
        icon: "grammar",
        additionalInfoDue: "Receive the Sherlock Sidney avatar",
        additionalInfoDone: "Received the Sherlock Sidney avatar",
    },
    numberOfFinishedGtTopics: {
        // this goal is not used
        title: "",
        link: undefined,
        icon: "",
        additionalInfoDue: "",
        additionalInfoDone: "",
    },
    learned3DaysInRow: {
        title: "Learn 3 days in a row",
        onClick: undefined,
        icon: "darkmode",
        additionalInfoDue: "Unlock dark mode",
        additionalInfoDone: "Unlocked dark mode",
    },
    practiced50Cards: {
        title: "Practiced 50 cards",
        link: undefined,
        icon: "no-star",
        additionalInfoDue: "Unlock game",
        additionalInfoDone: "Game unlocked",
    },
    gdprSentToParent: {
        title: "Data protection: Inform parents!",
        onClick: undefined,
        icon: "data-protection-awaiting",
        additionalInfoDue: "Click to send a short info!",
        additionalInfoDone: "Step 1 of 2 done!",
    },
    gdprConfirmedByParent: {
        title: "Data protection: Finished",
        icon: "data-protection-on",
        additionalInfoDue: "",
        additionalInfoDone: "Account confirmed successfully",
    },
    dictionaryUsed: {
        title: "Used the dictionary",
        link: "/dictionary",
        icon: "dictionary",
        additionalInfoDue: "Discover the unknown",
        additionalInfoDone: "Discovered the unknown",
    },
    learned7DaysIn10DaysPeriod: {
        title: "Learn 7 times in 10 days",
        onClick: undefined,
        icon: "appearance",
        additionalInfoDue: "Unlock designs",
        additionalInfoDone: "Unlocked designs",
    },
    confirmed: {
        title: "Confirm your E-Mail",
        onClick: undefined,
        icon: "mail",
        additionalInfoDue: "Confirm e-mail address",
        additionalInfoDone: "Confirmed e-mail address",
    },
    friendRefered: {
        title: "Successfully refer a friend",
        onClick: undefined,
        icon: "share",
        additionalInfoDue: "A small gift",
        additionalInfoDone: "A small gift",
    },
};

export type GoalsActionsType = {
    openThemePreferences: () => void;
    openAvatarChoice: () => void;
    openGDPRWindow: () => void;
    referFriend: () => void;
    confirmEmail: () => void;
};

export type GoalsContextType = GoalsData;

export const GoalsContext = React.createContext<GoalsContextType>(initialGoals);

const GoalsContextWrapper: React.FC = (props) => {
    const userGoals = useSelector(goalsSelectors.goals);
    const userEmail = useSelector(userSelectors.email);

    const dispatch = useDispatch();
    const t = useT();

    const actions: GoalsActionsType = useMemo(() => {
        return {
            openThemePreferences: () => {
                dispatch(modalActions.setModalView(GlobalModalView.Settings));
                dispatch(settingsActions.setSettingsView(SettingsView.Visual));
            },
            openAvatarChoice: () => {
                dispatch(modalActions.setModalView(GlobalModalView.Settings));
                dispatch(settingsActions.setSettingsView(SettingsView.Avatars));
            },
            openGDPRWindow: () => dispatch(modalActions.setModalView(GlobalModalView.GDPRModal)),
            referFriend: () => dispatch(modalActions.setModalView(GlobalModalView.Share)),
            confirmEmail: () => {
                const payload: ResendPasswordParamsType = {
                    login: userEmail ? userEmail : "",
                };
                dispatch(userActions.confirmEmail(payload));
            },
        };
    }, [dispatch, userEmail]);

    const goalsData = useMemo(() => {
        return {
            friendsReferedCount: {
                // this goal is not used anymore
                title: "",
                link: undefined,
                icon: "",
                additionalInfoDue: "",
                additionalInfoDone: "",
            },
            firstPracticeFinished: {
                title: t("Finished first practice"),
                link: userGoals?.goals["firstPracticeFinished"] ? "/leaderboard" : undefined,
                icon: "challenge-award",
                additionalInfoDue: t("Unlock leaderboards"),
                additionalInfoDone: t("Leaderboards unlocked"),
            },
            finished1GtTopic: {
                title: t("Finish first grammar topic"),
                onClick: userGoals?.goals["finished1GtTopic"] ? actions.openAvatarChoice : undefined,
                icon: "grammar",
                additionalInfoDue: t("Receive the Sherlock Sidney avatar"),
                additionalInfoDone: t("Received the Sherlock Sidney avatar"),
            },
            numberOfFinishedGtTopics: {
                // this goal is not used
                title: "",
                link: undefined,
                icon: "",
                additionalInfoDue: "",
                additionalInfoDone: "",
            },
            learned3DaysInRow: {
                title: t("Learn 3 days in a row"),
                onClick: userGoals?.goals["learned3DaysInRow"] ? actions.openThemePreferences : undefined,
                icon: "darkmode",
                additionalInfoDue: t("Unlock dark mode"),
                additionalInfoDone: t("Unlocked dark mode"),
            },
            practiced50Cards: {
                title: t("Practiced 50 cards"),
                link: userGoals?.goals["practiced50Cards"] ? "/games" : undefined,
                icon: "no-star",
                additionalInfoDue: t("Unlock game"),
                additionalInfoDone: t("Game unlocked"),
            },
            gdprSentToParent: {
                title: userGoals?.goals["gdprSentToParent"]
                    ? t("Data protection: Inform parents again?")
                    : t("Data protection: Inform parents!"),
                onClick: actions.openGDPRWindow,
                icon: userGoals?.goals["gdprSentToParent"] ? "data-protection-awaiting" : "data-protection-on",
                additionalInfoDue: t("Click to send a short info!"),
                additionalInfoDone: t("Step 1 of 2 done!"),
            },
            gdprConfirmedByParent: {
                title: t("Data protection: Finished"),
                icon: "data-protection-on",
                additionalInfoDue: "",
                additionalInfoDone: t("Account confirmed successfully"),
            },
            dictionaryUsed: {
                title: t("Used the dictionary"),
                link: "/dictionary",
                icon: "dictionary",
                additionalInfoDue: t("Discover the unknown"),
                additionalInfoDone: t("Discovered the unknown"),
            },
            learned7DaysIn10DaysPeriod: {
                title: t("Learn 7 times in 10 days"),
                onClick: userGoals?.goals["learned7DaysIn10DaysPeriod"] ? actions.openThemePreferences : undefined,
                icon: "appearance",
                additionalInfoDue: t("Unlock designs"),
                additionalInfoDone: t("Unlocked designs"),
            },
            confirmed: {
                title: t("Confirm your E-Mail"),
                onClick: userGoals?.goals["confirmed"] ? undefined : actions.confirmEmail,
                icon: "mail",
                additionalInfoDue: t("Confirm e-mail address"),
                additionalInfoDone: t("Confirmed e-mail address"),
            },
            friendRefered: {
                title: t("Successfully refer a friend"),
                onClick: actions.referFriend,
                icon: "share",
                additionalInfoDue: t("A small gift"),
                additionalInfoDone: t("A small gift"),
            },
        };
    }, [t, userGoals, actions]);

    return <GoalsContext.Provider value={goalsData}>{props.children}</GoalsContext.Provider>;
};

export default GoalsContextWrapper;
