// REACT
import React from "react";
import { Link } from "react-router-dom";

// COMPONENTS
import { TopNavigationCentralMenu } from "../../connected/topNavigationCentralMenu";
import { TopNotifications } from "../../connected/topNotifications";
import UserMenu from "../../connected/userMenu/";

// REDUX
import { useSelector } from "react-redux";
import { selectors as themeSelector } from "../../../redux/theme/themeSlice";
import { selectors as userSelector } from "../../../redux/user/userSlice";

// UTILS
import { Theme } from "../../../themes/constants";

// LIBRARIES
import moment from "moment";

// STYLED COMPONENTS
import {
    Item,
    ItemJustifyEnd,
    SmallDateOnBackground,
    SpacingRow,
    StyledContainer,
    StyledPhase6Logo,
    StyledPhase6LogoWhite,
    TopBarIconsContainer,
    PartnerLogo,
} from "./styles";

export interface TopNavigationProps {
    isMobileView?: boolean;
    showSmallDate?: boolean;
}

const TopNavigation: React.FC<TopNavigationProps> = (props) => {
    const selectedTheme: Theme = useSelector(themeSelector.themeName);
    const partnerLogo = useSelector(userSelector.partnerLogoUrl);

    let icon = <StyledPhase6LogoWhite title={"Phase 6 Logo"} />;
    if (selectedTheme === Theme.MAIN) {
        icon = <StyledPhase6Logo title={"Phase 6 Logo"} />;
    }

    if (props.isMobileView) {
        return (
            <StyledContainer {...props}>
                <TopBarIconsContainer>
                    <TopNavigationCentralMenu />
                </TopBarIconsContainer>
            </StyledContainer>
        );
    } else {
        return (
            <>
                <StyledContainer {...props}>
                    <Item>
                        <Link to={"/home"}>{icon}</Link>
                    </Item>
                    <TopNavigationCentralMenu />
                    <ItemJustifyEnd>
                        {!!partnerLogo && <PartnerLogo src={partnerLogo} />}
                        <TopNotifications />
                        <UserMenu />
                    </ItemJustifyEnd>
                </StyledContainer>
                <SpacingRow>
                    {props.showSmallDate && (
                        <SmallDateOnBackground>{moment(new Date()).format("DD.MM.YYYY")}</SmallDateOnBackground>
                    )}
                </SpacingRow>
            </>
        );
    }
};

TopNavigation.defaultProps = {
    isMobileView: false,
};

export default TopNavigation;
