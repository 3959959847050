import styled from "styled-components";

export const MainWrapper = styled.div`
    margin-top: ${(props) => props.theme.base.spacing};
`;

export const PhasesTable = styled.table`
    width: 100%;
    background: ${(props) => props.theme.colors.backgroundContrast};
    color: ${(props) => props.theme.colors.text};
`;

export const StyledPhasesTableRow = styled.tr`
    border-top: solid 1px ${(props) => props.theme.colors.backgroundMain};
    border-bottom: solid 1px ${(props) => props.theme.colors.backgroundMain};

    > td {
        padding: 10px 20px;
    }
`;

export const PhasesTableHeadColumn = styled.th`
    width: 20%;
    text-transform: uppercase;
    padding: 10px 20px;
    font-weight: normal;
    text-align: left;
`;

export const PhasesTableDuration = styled.th`
    text-transform: uppercase;
    padding: 10px 20px;
    font-weight: normal;
    text-align: left;
`;
