// LIBRARIES
import React, { useState } from "react";

// COMPONENTS
import SwitchInfoModal from "./SwitchInfoModal";

// REDUX
import { useDispatch } from "react-redux";
import { actions as modalActions } from "../../../redux/modal/modalSlice";

// HELPERS
import { GlobalModalView } from "../../../helpers/Modal";

// NETWORKING
import { removeUserRole } from "../../../networking/user";

const SwitchInfoModalWrapper: React.FC = () => {
    const [isPermanent, setIsPermanent] = useState(false);

    const dispatch = useDispatch();

    const closeModal = () => {
        if (isPermanent) {
            // remove user role, meaning the user will never be shown this modal again
            removeUserRole("p6o.p6o2.react-switch");
        } else {
            // add var to local storage, meaning the user will be shown this modal after their session expires
            sessionStorage.setItem("switchInfoModalClosed", "true");
        }
        dispatch(modalActions.setModalView(GlobalModalView.None));
    };

    // toggles if the modal should be closed permanently or not based on user input
    const togglePermanent = () => {
        setIsPermanent((isPermanent) => !isPermanent);
    };

    return (
        <SwitchInfoModal
            closeFn={closeModal}
            isPermanent={isPermanent}
            togglePermanent={togglePermanent}
        />
    );
};

export default SwitchInfoModalWrapper;
