import styled from "styled-components";
import PhaseSixIcon from "../../basic/phaseSixIcon/PhaseSixIcon";

export const Container = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    margin-top: ${(props) => props.theme.base.spacingInContent};
    background-color: ${(props) => props.theme.colors.backgroundContrast};
    border-radius: ${(props) => props.theme.base.borderRadiusBig};
`;
export const SubjectHeader = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 0 ${(props) => props.theme.base.spacing};
    gap: ${(props) => `calc(3 * ${props.theme.base.spacingSmall})`};
`;
export const SubjectSelection = styled.div`
    cursor: pointer;
    flex: 1;
    padding: ${(props) => `calc(2 * ${props.theme.base.spacingSmall}) 0`};
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    gap: ${(props) => props.theme.base.spacingSmall};
`;
export const Title = styled.span`
    flex: 1;
    width: 0; // no direct effect, needed for text-overflow to trigger
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: ${(props) => props.theme.colors.text};
`;
export const CardCount = styled.span`
    color: ${(props) => props.theme.colors.text};
    margin: 0 4px;
`;
export const FlagImage = styled.img`
    height: 24px;
    width: 36px;
    border-radius: ${(props) => props.theme.base.borderRadius};
`;
export const DeleteEntry = styled.div`
    color: ${(props) => props.theme.colors.text};
    cursor: pointer;

    &:hover {
        color: ${(props) => props.theme.colors.primary};
    }
`;
export const StyledPhaseSixIcon = styled(PhaseSixIcon)`
    color: inherit;
`;
export const ActionContainer = styled.div<{ isSearchResult?: boolean }>`
    display: flex;
    flex-direction: row;
    justify-content: ${(props) => (props.isSearchResult ? "flex-end" : "space-between")};
    align-items: center;
    padding: 0 ${(props) => props.theme.base.spacing};
`;
export const ActionButtonsContainer = styled.div`
    padding: ${(props) => props.theme.base.spacing} 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
`;
export const SelectedCardsCount = styled.span`
    white-space: nowrap;
    color: ${(props) => props.theme.colors.text};
`;
export const CardList = styled.div`
    max-height: calc(100vh - 295px); // 300px is approximately the title, filter and header height combined
    overscroll-behavior-x: none;
    height: 100%;
`;
export const ShowMore = styled.div`
    margin-top: ${(props) => props.theme.base.spacingSmall};
    padding-top: ${(props) => props.theme.base.spacingSmall};
    padding-bottom: ${(props) => props.theme.base.spacingSmall};
    padding-right: ${(props) => props.theme.base.spacingSmall};
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`;
export const NoCardContainer = styled.div`
    width: 100%;
    padding: ${(props) => props.theme.base.spacing};
    display: flex;
    justify-content: center;
    align-items: center;
`;
export const NoCardText = styled.span`
    color: ${(props) => props.theme.colors.text};
`;
export const MoreCardsText = styled.span`
    color: ${(props) => props.theme.colors.text};
`;
export const ShowLink = styled.span`
    cursor: pointer;
    color: ${(props) => props.theme.colors.text};
    text-decoration: underline;
`;
export const LoadingContainer = styled.div`
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const StyledSpinner = styled.div`
    border: 2px solid ${(props) => props.theme.colors.text};
    border-top: 2px solid ${(props) => props.theme.colors.primary};
    border-radius: 50%;
    width: 15px;
    height: 15px;
    animation: spin 2s linear infinite;

    @keyframes spin {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
`;
