import { call, put, takeEvery, select } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import { DispatchAction, ActionsType, WarningsResponse, WarningType } from "p6m-warnings";
import { warningTypeRelation } from "../../constants/WarningConstants";
import { IResponse } from "p6m-response";
import { LeaderboardData } from "p6m-user";
import { actions, selectors } from "./warningsSlice";
import { actions as appStatusActions } from "../appStatus/appStatusSlice";
import { selectors as userSelectors } from "../user/userSlice";
import { actions as responseActions } from "../response/responseSlice";
import { getWarnings, postWarning } from "../../networking/warnings";
import { getLeaderboard } from "../../networking/leaderBoard";
import { ResponseMessageKeys } from "../../components/connected/response";
import { ampli } from "../../ampli";

const warningsTimerLimit: number = parseInt(process.env.REACT_APP_WARNINGS_TIMER_LIMIT || "100");
const warningsTimerMinutesLimit: number = parseInt(process.env.REACT_APP_WARNINGS_TIMER_TIME || "10");
const warningsWithTypingLimit: number = parseInt(process.env.REACT_APP_WARNINGS_WITH_TYPING || "50");
const warningsNoTypingLimit: number = parseInt(process.env.REACT_APP_WARNINGS_NO_TYPING || "50");

const messages = [
    ResponseMessageKeys.WARNING_NO_POINTS_ANYMORE,
    ResponseMessageKeys.WARNING_COUNTING_STOPPED,
    ResponseMessageKeys.WARNING_QUICK_LEARNING,
    ResponseMessageKeys.WARNING_HONESTY,
];

let checkFunction: undefined | ReturnType<typeof checkGenerator>;

function startWarnings() {
    checkFunction = checkGenerator();
}

function stopWarnings() {
    checkFunction = undefined;
}

function* fetchWarnings() {
    const userId: string = yield select(userSelectors.userId) || "";
    const ready: boolean = yield select(selectors.ready);
    stopWarnings();
    startWarnings();
    if (ready) return;

    yield put(appStatusActions.setLoading(true));
    try {
        const {
            data: {
                replyContent: { warningCount = 0 },
            },
        }: AxiosResponse<IResponse<WarningsResponse>> = yield call(getWarnings);
        const {
            data: {
                replyContent: {
                    currentUser: { learnedCardsToday = 0 },
                },
            },
        }: AxiosResponse<IResponse<LeaderboardData>> = yield call(getLeaderboard, "all", 1000, 0, 1, userId);

        yield put(
            actions.setWarnings({
                count: warningCount,
                startCount: learnedCardsToday,
                ready: true,
            })
        );
    } catch (e) {
        console.log(e);
    } finally {
        yield put(appStatusActions.setLoading(false));
    }
}

function* doAction({ payload }: PayloadAction<DispatchAction>) {
    const ready: boolean = yield select(selectors.ready);
    if (!ready) {
        yield fetchWarnings();
    }

    const startCount: number = yield select(selectors.startCount);
    const { showLearningHints = true } = yield select(userSelectors.userPreferences);

    if (startCount > 0) {
        yield put(actions.setWarnings({ startCount: 1 }));
        return;
    }

    const { action, typing, isCorrect } = payload;

    const type = (function () {
        const actionTypes: ActionsType[] = ["I_WAS_RIGHT_NO_TYPING", "I_WAS_RIGHT_WITH_TYPING"];
        return actionTypes[+typing];
    })();

    const actionFunction = (function () {
        if (!checkFunction) startWarnings();
        const actionsRelation: Partial<Record<DispatchAction["action"], () => WarningType | undefined>> = {
            onCompare: () => {
                if (!isCorrect) return;
                return checkFunction?.(type, true);
            },
            onAsCorrect: () => {
                return checkFunction?.(type);
            },
            onWasWrong: () => {
                return checkFunction?.(type, true);
            },
        };
        return actionsRelation[action];
    })();

    if (!actionFunction) return;

    const result = actionFunction();

    if (!result) return;

    try {
        yield call(postWarning, result);
        ampli.shownWarning({ warning_type: warningTypeRelation[result] });
    } catch (e) {
        console.log(e);
    }

    yield put(actions.setWarnings({ count: 1 }));

    const messageKey: number = yield select(selectors.count);
    const message = messages[messageKey];

    if (!message) return;
    if (message === ResponseMessageKeys.WARNING_NO_POINTS_ANYMORE) {
        ampli.blockedLeaderboard();
    }

    if (!showLearningHints) return;

    yield put(responseActions.showResponse({ type: "ERROR", responseMessage: message }));
}

export function* warningsSaga() {
    yield takeEvery(actions.fetchWarnings, fetchWarnings);
    yield takeEvery(actions.stopWarnings, stopWarnings);
    yield takeEvery(actions.doAction, doAction);
}

function checkGenerator() {
    const checkTimers = generateTimersCheck();
    const checkTyping = generateWarningsCheck();
    return (type: ActionsType, reset?: boolean): WarningType | undefined => {
        //TODO FOR TECH-DEBT: Ask backend and frontend to change "TO_MUCH_CARDS" to "TOO_MANY_CARDS" wherever it was used.
        if (checkTimers()) return "TO_MUCH_CARDS";
        if (!type) return;
        return checkTyping(type, reset);
    };
}

function generateTimersCheck(limit: number = warningsTimerLimit, time: number = warningsTimerMinutesLimit) {
    const msLimit = time * 60 * 1000;
    let pushToTimer = generateTimers(limit);
    return () => {
        const timersArr = pushToTimer();
        if (timersArr.length < limit) return false;
        if (timersArr[limit - 1] - timersArr[0] >= msLimit) return false;
        pushToTimer = generateTimers(limit);
        return true;
    };
}

function generateTimers(limit: number) {
    const timersArr: number[] = [];
    return () => {
        const index = timersArr.push(Date.now());
        if (index > limit) {
            timersArr.splice(0, 1);
        }
        return timersArr;
    };
}

function generateWarningsCheck() {
    const rules: Record<ActionsType, number> = {
        I_WAS_RIGHT_NO_TYPING: warningsNoTypingLimit,
        I_WAS_RIGHT_WITH_TYPING: warningsWithTypingLimit,
    };

    const warnings: Record<ActionsType, number> = {
        I_WAS_RIGHT_NO_TYPING: 0,
        I_WAS_RIGHT_WITH_TYPING: 0,
    };

    return (type: ActionsType, reset: boolean = false) => {
        if (warnings[type] === undefined) return;
        if (reset) {
            warnings[type] = 0;
            return;
        }
        warnings[type] += 1;
        return Object.keys(rules).find((key) => {
            const rule = rules[key as ActionsType];
            if (rule > warnings[key as ActionsType]) return false;
            warnings[key as ActionsType] = 0;
            return true;
        }) as ActionsType | undefined;
    };
}
