import md5 from "md5";
import { SelectedSkinProperties } from "../ampli";
// UTILS
import { Theme } from "../themes/constants";

export enum ActivationModes {
    "manual",
    "match",
    "mixed",
    "auto",
    "none",
}
export const practiceTypeToActivationMode = (practiceType: number): "manual" | "match" | "mixed" | "auto" | "none" => {
    return ActivationModes[practiceType] as "manual" | "match" | "mixed" | "auto" | "none";
};

// todo: refactor -> let's not use numbers for activationType...
export enum Mode {
    "test_moved_to_practice",
    "test_only",
    "exercise_moved_to_practice",
    "exercise_only",
    "regular_practice",
}

export const activationTypeToMode = (
    activationType: number,
    isPrepareForTest: boolean = false,
    isExercise: boolean = false
): "test_moved_to_practice" | "test_only" | "exercise_moved_to_practice" | "exercise_only" | "regular_practice" => {
    if (isPrepareForTest) {
        if (isExercise) {
            return Mode[activationType + 2] as "exercise_moved_to_practice" | "exercise_only";
        } else {
            return Mode[activationType] as "test_moved_to_practice" | "test_only";
        }
    }
    return "regular_practice";
};

export const hashUserDnsId = (userDnsId: string, userName: string) => {
    userName = userName.trim();
    userDnsId = userDnsId.trim();
    const userIdInteger = Number(userDnsId);

    let hashedId = "";

    if (userIdInteger) {
        if (userIdInteger >= 4000000) {
            hashedId = md5(md5(userDnsId));
        } else if (userName) {
            hashedId = md5(userName + userName);
        }
    }

    return hashedId;
};

export function getAmplitudeThemeName(theme: Theme = Theme.MAIN) {
    const relation: Record<Theme, SelectedSkinProperties["skin"]> = {
        [Theme.MAIN]: "grayTheme",
        [Theme.DARK]: "darkMode",
        [Theme.BLUE]: "blueTheme",
        [Theme.ORANGE]: "orangeTheme",
        [Theme.PINK]: "pinkTheme",
    };

    return relation[theme];
}
