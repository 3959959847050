const event = new Event("tabFocused");

export function tabListener(element?: HTMLElement, attribute: string = "tabindex"): () => void {
    const tabEventListener = (e: KeyboardEvent) => {
        if (e.code !== "Tab") return;
        e.preventDefault();
        const elements = getElements(element, attribute);
        const index = elements.findIndex((search) => {
            return search === document.activeElement;
        });
        let nextIndex = index + 1;
        if (!elements[nextIndex]) nextIndex = 0;
        elements[nextIndex]?.focus();
        elements[nextIndex]?.dispatchEvent(event);
    };

    document.addEventListener("keydown", tabEventListener);
    return function () {
        document.removeEventListener("keydown", tabEventListener);
    };
}

export function getElements(element?: HTMLElement, attribute: string = "tabindex") {
    if (!element) return [];
    return Array.from(element.querySelectorAll(`[${attribute}]`))
        .filter((element) => parseInt(element.getAttribute(attribute) || "0") > 0)
        .filter((element) => {
            //@ts-ignore
            const disabled = element.disabled;
            return !disabled;
        })
        .sort((curr, next) => {
            const curTab = parseInt(curr.getAttribute(attribute) || "0");
            const nextTab = parseInt(next.getAttribute(attribute) || "0");
            return curTab - nextTab;
        }) as HTMLElement[];
}
