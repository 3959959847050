import styled, { css } from "styled-components";
import { Link } from "react-router-dom";
import GameBg from "../../assets/img/bg_games.png";

export const Background = styled.div`
    min-height: 518px;
    padding: ${({ theme: { base } }) => base.spacing};
    background-color: ${({ theme: { colors } }) => colors.backgroundMain};
    border-radius: ${({ theme: { base } }) => base.borderRadiusSmall};
    background-image: url(${GameBg});
    background-repeat: no-repeat;
    background-size: 35%;
    background-position: top right;
`;

export const BackIconContainer = styled.div`
    > * {
        text-decoration: none;

        > i {
            color: ${({ theme: { colors } }) => colors.primary};
            font-size: 45px;
        }
    }
`;
export const Title = styled.h1`
    margin: 0;
    line-height: 1;
    font-size: ${({ theme: { base } }) => parseInt(base.size) * 3 + "px"};
    font-weight: normal;
    color: ${({ theme: { colors } }) => colors.primary};

    @media (max-width: 991px) {
        font-size: 22px;
    }
`;

export const SecondaryTitle = styled.h2`
    margin: 0;
    line-height: 1;
    font-size: ${({ theme: { base } }) => base.extraBigSize};
    font-weight: normal;
    color: ${({ theme: { colors } }) => colors.text};

    @media (max-width: 575px) {
        font-size: 14px;
    }
`;

export const PlayButton = styled.div`
    display: flex;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    opacity: 0;
    background-color: rgba(255, 255, 255, 0.2);
    transition: opacity 0.3s;
    font-size: 60px;
    color: ${({ theme: { colors } }) => colors.primary};

    > * {
        margin: auto;
    }
`;

const GameBoxCss = css`
    display: flex;
    flex-direction: column;
    position: relative;
    height: 100%;
    padding: ${({ theme: { base } }) => base.spacingSmall};
    border-radius: ${({ theme: { base } }) => base.borderRadiusSmall};
    background-color: ${({ theme: { colors } }) => colors.backgroundContrast};
    text-decoration: none;
    box-sizing: border-box;
    overflow: hidden;

    > *:last-child {
        margin-top: auto;
    }

    &:hover > ${PlayButton} {
        opacity: 1;
    }
`;

export const GameBox = styled(Link)`
    ${GameBoxCss}
`;

export const NoLinkGameBox = styled.div`
    ${GameBoxCss}
`;

const GameTitleComonStyles = css`
    margin: 0;
    line-height: 1;
    font-size: ${({ theme: { base } }) => base.extraBigSize};
    font-weight: normal;
    color: ${({ theme: { colors } }) => colors.primary};
`;

export const GameTitle = styled.h2`
    ${GameTitleComonStyles}
`;

export const StarIcon = styled.div`
    ${GameTitleComonStyles};
    position: relative;

    & > span {
        position: absolute;
        left: 0;
        width: 100%;
        top: 35%;
        font-size: 1em;
        font-weight: bold;
        text-align: center;
    }
`;

export const GameDescr = styled.p`
    margin: 0;
    line-height: 1;
    font-size: ${({ theme: { base } }) => base.bigSize};
    font-weight: normal;
    color: ${({ theme: { colors } }) => colors.text};
`;

export const GameImage = styled.img`
    display: block;
    width: 100%;
    min-height: 115px;
    border-radius: ${({ theme: { base } }) => base.borderRadiusSmall};
`;
