import { takeEvery, put, call, all } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import { IResponse } from "p6m-response";
import { SubjectUnitCard } from "p6m-subjects";
import { actions as appStatusActions } from "../appStatus/appStatusSlice";
import { actions, ActivateCardsParams } from "./activationSlice";
import { actions as learningActions } from "../learning/learningSlice";
import { Directions } from "p6m-learning";
import { cardActivationFiltered } from "../../networking/practice";

function* activateAndStart({ payload }: PayloadAction<ActivateCardsParams>) {
    const { subjectId, cardsId: idsFromProps = [], cards = [], direction = 0 } = payload;

    const cardsId: string[] = !cards.length ? [...idsFromProps] : cards.map(({ cardIdToOwner: { id } }) => id);

    const itemsByDirections: Record<Directions, string[]> = {
        normal: [],
        opposite: [],
    };

    const keysRelation: Directions[][] = [["normal"], ["normal", "opposite"], ["opposite"]];

    const whereToPush: Directions[] = keysRelation[direction];

    whereToPush.forEach((key: Directions) => {
        itemsByDirections[key] = cardsId;
    });

    yield put(appStatusActions.setLoading(true));
    try {
        const results: AxiosResponse<IResponse<{ cards: SubjectUnitCard[] }>>[] = yield all(
            Object.entries(itemsByDirections)
                .filter(([key, items]) => items.length)
                .map(([key, items]) => {
                    return call(cardActivationFiltered, {
                        activate: true,
                        // direction: key.toUpperCase() as Uppercase<Directions>,
                        direction: key.toUpperCase() as any,
                        cards: items,
                        subjectId,
                    });
                })
        );
        if (!results) {
            yield put(learningActions.start(payload));
            return;
        }
        const {
            data: {
                replyContent: { cards: newCards },
            },
        } = results[results.length - 1];
        yield put(
            learningActions.start({
                ...payload,
                cardsId: [],
                cards: newCards,
            })
        );
    } catch (e) {
        console.log(e);
    } finally {
        yield put(appStatusActions.setLoading(false));
    }
}

export function* activationSaga() {
    yield takeEvery(actions.activateAndStart, activateAndStart);
}
