import styled from "styled-components";
import PhaseSixIcon from "../../../basic/phaseSixIcon/PhaseSixIcon";

export const StyledCounter = styled.span`
    font-size: 16px;
    color: ${({
        theme: {
            colors: { textOnBackground },
        },
    }) => textOnBackground};
`;

export const StyledPhaseSixIcon = styled(PhaseSixIcon)`
    color: ${(props) => props.theme.colors.textOnBackground};
`;
