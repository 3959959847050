import styled from "styled-components";
import { Row, Col } from "../../basic/grid";

export const ListWrapper = styled.div`
    display: flex;
    flex-direction: column;
    background-color: ${({ theme: { colors } }) => colors.backgroundContrast};
    border-radius: ${({ theme: { base } }) => base.borderRadius};
    overflow-x: hidden;
`;

export const Arrow = styled.div`
    margin: 0 ${({ theme: { base } }) => base.borderRadius};
    cursor: pointer;
    display: flex;
`;

export const Item = styled(Row)<{ checked?: boolean }>`
    font-size: ${(props) => props.theme.base.bigSize};
    height: 50px;

    ${({ theme: { base, colors }, checked = false }) => `
        margin-top: 0 !important;
        margin-bottom: 0 !important;
        padding: ${base.borderRadius};
        border-bottom: 1px solid ${colors.border};
        font-size: ${base.size};
        color: ${checked ? colors.primary : colors.text};
    `}

    > ${Col} {
        padding-top: 0;
        padding-bottom: 0;
    }

    &:hover {
        color: ${(props) => props.theme.colors.primary};
    }
`;

export const ChildrenItems = styled.div`
    ${Item} {
        background-color: ${({ theme: { colors } }) => colors.backgroundMain};
    }
`;

export const ListEntry = styled.span<{ isTitle?: boolean }>`
    font-size: ${(props) => props.theme.base.bigSize};
    ${(props) =>
        props.isTitle &&
        `
        font-weight: bold;
        padding: ${props.theme.base.spacing} 0;
    `}
`;

export const CardAmountSelectedCol = styled(Col)`
    font-size: ${(props) => props.theme.base.mediumSize};
`;

export const TextCol = styled(Col)`
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    display: inline-block;
`;

export const CheckedIds = styled.span<{ highlighted: boolean }>`
    color: ${({ highlighted, theme: { colors } }) => (highlighted ? colors.primary : colors.text)};
`;
