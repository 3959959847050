import styled from "styled-components";
import PhaseSixIcon from "../../basic/phaseSixIcon/PhaseSixIcon";

export const Container = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    margin-top: ${(props) => props.theme.base.spacingInContent};
    background-color: ${(props) => props.theme.colors.backgroundContrast};
    border-radius: ${(props) => props.theme.base.borderRadiusBig};
`;
export const SubjectHeader = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 0 ${(props) => props.theme.base.spacing};
    gap: ${(props) => `calc(3 * ${props.theme.base.spacingSmall})`};
`;
export const SubjectSelection = styled.div`
    cursor: pointer;
    flex: 1;
    padding: ${(props) => `calc(2 * ${props.theme.base.spacingSmall}) 0`};
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    gap: ${(props) => props.theme.base.spacingSmall};
`;
export const Title = styled.span`
    flex: 1;
    width: 0; // no direct effect, needed for text-overflow to trigger
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: ${(props) => props.theme.colors.text};
`;
export const CardCount = styled.span`
    color: ${(props) => props.theme.colors.text};
    margin: 0 4px;
`;
export const FlagImage = styled.img`
    height: 24px;
    width: 36px;
    border-radius: ${(props) => props.theme.base.borderRadius};
`;
export const DeleteEntry = styled.div`
    color: ${(props) => props.theme.colors.text};
    cursor: pointer;

    &:hover {
        color: ${(props) => props.theme.colors.primary};
    }
`;
export const StyledPhaseSixIcon = styled(PhaseSixIcon)`
    color: inherit;
`;
