// REACT
import React, { FunctionComponent, memo } from "react";
import { T } from "@transifex/react";

// STYLED COMPONENTS
import { Title } from "./styles";

export type Props = {
    count: number; // count of maximum items
    perPage: number; // count of items on page
    page: number; // current page number
};

const GamePagination: FunctionComponent<Props> = (props) => {
    const { count, perPage, page } = props;

    const lastPage: number = getLastPage(count, perPage);
    const currentPage: number = getCurrentPage(page, lastPage);

    return (
        <Title>
            <T
                _str="Page {currentPage} of {lastPage}"
                currentPage={<b>{currentPage}</b>}
                lastPage={lastPage}
            />
        </Title>
    );
};

export default memo(GamePagination);

function getLastPage(count: number, perPage: number): number {
    return Math.floor(count / (perPage > count ? count : perPage));
}

function getCurrentPage(page: number, lastPage: number): number {
    return page > lastPage ? lastPage : page;
}
