// LIBRARIES
import React from "react";
import { useT } from "@transifex/react";
import { UserPreferences } from "p6m-user";

// COMPONENTS
import SettingsMainStyledCTA from "../../complex/settingsMainStyledCTA/SettingsMainStyledCTA";
import PhaseSixIcon, { TIconNames } from "../../basic/phaseSixIcon/PhaseSixIcon";
import UserAvatar from "../../basic/userAvatar/UserAvatar";

//TYPES
import { SettingsView } from "../../../helpers/Modal";

//STYLES
import {
    EditAvatarIconWrapper,
    EmailContainer,
    MainBodyWrapper,
    NameContainer,
    StyledLogoutButton,
    UserAvatarWrapper,
    UserInfoContainer,
    UserNameContainer,
} from "./styles";

export interface SettingsMainBodyProps {
    userEmail: string;
    userName: string;
    changeViewCallback: (view: SettingsView) => any;
    contentTitles: { [key in SettingsView]: string };
    userPreferences: UserPreferences;
    logoutAction: () => any;
    hasPremium: boolean;
    userIsAdmin: boolean;
    userAvatar?: string;
}

const SettingsMainBody: React.FC<SettingsMainBodyProps> = (props) => {
    const {
        userAvatar,
        hasPremium: userHasPremium,
        userPreferences,
        changeViewCallback,
        contentTitles,
        logoutAction,
        userIsAdmin,
    } = props;
    const t = useT();

    const t_logout = t("logout", { _tags: "settings" });
    const t_premium = t("You need PLUS to use this feature.", { _tags: "settings, tooltip" });
    const t_parentLock = t("Deactivated by your parents", {
        _tags: "settings, tooltip",
    });
    const t_noAdmin = t("Enabled for Admin of the group only", {
        _tags: "settings, tooltip",
    });
    let t_anonymousUser = t("Anonymous User", { _tags: "name, placeholder" });

    //reminder: only children and parents have parentSettings:
    const parentSettingsOfUser = userPreferences.parentSettings;
    const hasAccessToPhasesSettings =
        userHasPremium && (!!parentSettingsOfUser ? parentSettingsOfUser.phasePrefAccess : true);

    const settingsItems: {
        type: string;
        view: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8;
        icon: TIconNames;
        disabledTooltipText?: string;
        disabled?: boolean;
        plusFlag?: boolean;
    }[] = [
        {
            type: "family",
            view: SettingsView.Family,
            icon: "family",
            disabledTooltipText: t_noAdmin,
            disabled: !userIsAdmin,
        },
        {
            type: "practice",
            view: SettingsView.Learning,
            icon: "reset-alt",
        },
        {
            type: "phases",
            view: SettingsView.Phases,
            icon: "sliders",
            disabledTooltipText: userHasPremium ? t_parentLock : t_premium,
            disabled: !hasAccessToPhasesSettings,
            plusFlag: !hasAccessToPhasesSettings,
        },
        {
            type: "general",
            view: SettingsView.General,
            icon: "settings",
        },
        {
            type: "design",
            view: SettingsView.Visual,
            icon: "paint",
        },
        {
            type: "language",
            view: SettingsView.Language,
            icon: "globe",
        },
        {
            type: "avatars",
            view: SettingsView.Avatars,
            icon: "change-avatar",
        },
    ];

    return (
        <MainBodyWrapper>
            <UserInfoContainer>
                <UserAvatarWrapper data-qa="user-settings-modal_user-avatar">
                    <UserAvatar
                        userAvatar={userAvatar}
                        size={"big"}
                        isOnBackground
                        onClick={() => changeViewCallback(SettingsView.Avatars)}
                    >
                        <EditAvatarIconWrapper>
                            <PhaseSixIcon name="edit-avatar" />
                        </EditAvatarIconWrapper>
                    </UserAvatar>
                </UserAvatarWrapper>
                <UserNameContainer data-qa="user-settings-modal_user-info">
                    <NameContainer data-qa="user-settings-modal_user-name">
                        {props.userName.trim() || t_anonymousUser}
                    </NameContainer>
                    <EmailContainer data-qa="user-settings-modal_user-email">{props.userEmail}</EmailContainer>
                </UserNameContainer>
            </UserInfoContainer>
            {settingsItems.map((option) => (
                <SettingsMainStyledCTA
                    key={option.type}
                    data-qa={`user-settings-modal_settings-item ${option.type}`}
                    title={contentTitles[option.view]}
                    icon={option.icon}
                    onClickAction={() => changeViewCallback(option.view)}
                    disabledTooltipText={option.disabledTooltipText}
                    disabled={option.disabled}
                    plusFlag={option.plusFlag}
                />
            ))}
            <StyledLogoutButton
                onClick={logoutAction}
                data-qa="user-settings-modal_settings_logout"
                useVerticalPadding
                textSize="xl"
                upperCase
                useModalZIndex
            >
                {t_logout}
            </StyledLogoutButton>
        </MainBodyWrapper>
    );
};

export default SettingsMainBody;
