import React, { useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { selectors, actions } from "../../redux/user/userSlice";
import { actions as responseActions } from "../../redux/response/responseSlice";
import { actions as goalsActions, selectors as goalsSelectors } from "../../redux/goals/goalsSlice";
import ResponseConstants from "../../constants/ResponseConstants";
import { Container, Row, Col } from "../../components/basic/grid";
import PhaseSixIcon from "../../components/basic/phaseSixIcon/PhaseSixIcon";
import { GamesContext } from "../../context/games";
import { useT } from "@transifex/react";
import { useAppVersionCheck } from "../../hooks/useAppVersionCheck";

import {
    Background,
    Title,
    GameBox,
    NoLinkGameBox,
    GameTitle,
    GameDescr,
    GameImage,
    StarIcon,
    PlayButton,
} from "./styles";

const ConditionalLink: React.FC<{ link: string; disabled: boolean; onClick: any }> = ({
    children,
    link,
    disabled,
    onClick,
}) =>
    disabled ? <NoLinkGameBox onClick={onClick}>{children}</NoLinkGameBox> : <GameBox to={link}>{children}</GameBox>;

const Games: React.FC = () => {
    const games = useContext(GamesContext);
    const userStars = useSelector(selectors.userStars);
    const userGoalsData = useSelector(goalsSelectors.goals);
    const practiced50Cards = userGoalsData?.goals.practiced50Cards;

    const history = useHistory();
    const dispatch = useDispatch();
    const t = useT();

    useAppVersionCheck();

    useEffect(() => {
        if (practiced50Cards) return;
        if (!userGoalsData) {
            dispatch(goalsActions.fetchGoals());
            return;
        }
        history.push("/home");
    }, [practiced50Cards, userGoalsData, dispatch, history]);

    const error_t = t("You run out of stars", {});
    const learnForStars_t = t("Learn for Stars", {});

    const { starsCount = 0 } = userStars || { starsCount: 0 };

    useEffect(() => {
        if (userStars) return;
        dispatch(actions.fetchUserStars());
    }, [userStars, userGoalsData, dispatch]);

    const onClickCallback = (): void => {
        dispatch(responseActions.showResponse({ type: ResponseConstants.ERROR, text: [error_t] }));
    };

    const isDisabled = (cost: number): boolean => {
        if (cost <= 0) return false;
        return starsCount <= 0;
    };

    return (
        <Container fullWidth>
            <Background>
                <Row>
                    <Col>
                        <Title>{learnForStars_t}</Title>
                    </Col>
                </Row>
                <Row
                    cols={{
                        xs: 1,
                        s: 2,
                        l: 3,
                        xl: 4,
                    }}
                >
                    {games.map(({ title, description, image, link, cost }) => (
                        <Col key={title}>
                            <ConditionalLink
                                link={link}
                                disabled={isDisabled(cost)}
                                onClick={onClickCallback}
                            >
                                <Row horizontal="between">
                                    <Col xs="auto">
                                        <GameTitle>{title}</GameTitle>
                                    </Col>
                                    <Col xs="auto">
                                        <StarIcon>
                                            {cost ? (
                                                <PhaseSixIcon name={"star-minus"} />
                                            ) : (
                                                <PhaseSixIcon name={"star-plus"} />
                                            )}
                                        </StarIcon>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <GameDescr>{description}</GameDescr>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <GameImage src={image} />
                                    </Col>
                                </Row>
                                <PlayButton>
                                    <PhaseSixIcon name="play" />
                                </PlayButton>
                            </ConditionalLink>
                        </Col>
                    ))}
                </Row>
            </Background>
        </Container>
    );
};

export default Games;
