import React, { useEffect } from "react";
import { useFocusScope } from "react-focus-lock";
import styled from "styled-components";

const StyledDiv = styled.div`
    outline: none;
`;

export const DefaultFocus = () => {
    const { autoFocus } = useFocusScope();

    useEffect(() => {
        autoFocus();
    }, [autoFocus]);

    return (
        <StyledDiv
            data-autofocus
            tabIndex={-1}
        />
    );
};
