// REACT
import React, { FunctionComponent, useRef, useState } from "react";
import { useT } from "@transifex/react";

// STYLED COMPONENTS
import { Wrapper, Textarea, IconContainer, MainIcon } from "./styles";

type Props = {
    value?: string;
    onAnnotate: (result: string) => void;
    rows?: number;
};

const Annotate: FunctionComponent<Props> = (props) => {
    const { onAnnotate, rows = 4, value } = props;
    const t = useT();
    const [isActive, setActive] = useState(false);
    const [result, setResult] = useState(value);

    const textAreaRef = useRef<HTMLTextAreaElement>(null);

    const textAreaFilled =
        value !== "" || (textAreaRef?.current?.value !== undefined && textAreaRef?.current?.value !== "");
    const shouldShowTextarea = isActive || textAreaFilled;

    const t_placeholder = t("Type to add a note.", {});

    const onChange = ({ target: { value } }: any) => {
        setResult(value);
    };

    const onFocus = () => {
        setActive(true);
    };

    const saveAnnotation = () => {
        // no need to save if nothing changed
        if (textAreaRef?.current?.value === value) {
            setActive(false);
            return;
        }

        if (result) {
            onAnnotate(result);
        } else {
            onAnnotate(textAreaRef?.current?.value || "");
        }
        setActive(false);
    };

    const toggleActive = () => {
        setActive(!isActive);
        textAreaRef?.current?.focus();
    };

    return (
        <Wrapper>
            <IconContainer>
                <MainIcon
                    name="annotation-alt"
                    active={isActive}
                    onClick={toggleActive}
                />
                {shouldShowTextarea && (
                    <Textarea
                        ref={textAreaRef}
                        defaultValue={value}
                        placeholder={t_placeholder}
                        rows={rows}
                        onFocus={onFocus}
                        onChange={onChange}
                        autoFocus={!textAreaFilled}
                        onBlur={saveAnnotation}
                    />
                )}
            </IconContainer>
        </Wrapper>
    );
};

export default Annotate;
