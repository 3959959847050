import styled from "styled-components";

export const Wrapper = styled.ul`
    padding: 0;
    margin: 0;
    img {
        max-width: 100%;
    }
`;

export const AvatarInfo = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
`;

export const OtherInfo = styled.div`
    width: 100%;
    margin-left: ${({ theme: { base } }) => base.spacingInContent};
    color: ${({ theme: { colors } }) => colors.text};

    p {
        margin: ${({ theme: { base } }) => base.spacingTiny} 0 0 0;
        font-size: ${({ theme: { base } }) => base.size};
        color: ${({ theme: { colors } }) => colors.text};
    }
`;

export const AvatarTitleIconContainer = styled.div`
    display: none;
`;

export const AvatarTitle = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    font-size: ${({ theme: { base } }) => base.titleSize};
    color: ${({ theme: { colors } }) => colors.textLight};

    > h2 {
        margin: 0;
        font-weight: normal;
        font-size: inherit;
        color: inherit;
    }
`;

export const AchievementsInfo = styled.div`
    width: 100%;
    margin-top: ${({ theme: { base } }) => base.spacingSmall};
    color: ${({ theme: { colors } }) => colors.textLight};
`;

export const AchievementsProgress = styled.div`
    position: relative;
    width: 75%;
    height: 5px;
    margin-top: 10px;
    border-radius: 5px;
    background-color: ${({ theme: { colors } }) => colors.backgroundMain};
    overflow: hidden;
    @media (max-width: ${process.env.REACT_APP_UPPER_GRID_STEP_BORDER}px) {
        width: 90%;
    }
`;

export const AchievementsProgressBar = styled.div<{ $width?: number }>`
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
    height: 100%;
    width: ${({ $width = 0 }) => $width}%;
    border-radius: inherit;
    background-color: ${({ theme: { colors } }) => colors.primary};
`;

export const AchievementsTitle = styled.div`
    width: 100%;
    margin-top: 5px;
    font-size: 14px;
    color: var(--color-six);

    > p {
        margin: 0;
        font-size: inherit;
        color: inherit;
    }
`;

export const VariantsWrapper = styled.div`
    margin-top: ${({ theme: { base } }) => base.spacing};
    width: 100%;

    overflow-y: auto;
`;

export const VariantsList = styled.ul`
    list-style: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: left;
    flex-wrap: nowrap;
    min-width: min-content;
    margin: 0;
    padding: 0 0 0 ${({ theme: { base } }) => base.spacingInContent};
`;

export const VariantsItem = styled.li<{ $active: boolean }>`
    position: relative;
    width: 48px;
    margin: 0;
    opacity: 0.5;
    cursor: pointer;

    &:not(:first-child) {
        margin-left: ${(props) => props.theme.base.spacingBig};
    }

    &:before {
        content: " ";
        position: absolute;
        left: 0;
        bottom: 0;
        z-index: 1;
        height: 0;
        width: 100%;
        padding-bottom: 100%;
        border-radius: 50%;
        background-color: ${({ theme: { colors } }) => colors.backgroundMain};
    }

    &:hover {
        opacity: 0.8;
    }

    > img {
        position: relative;
        z-index: 2;
        display: block;
        width: 100%;
    }

    ${AvatarTitleIconContainer} {
        position: absolute;
        right: -18px;
        top: 4px;
        z-index: 3;
    }

    ${({ $active }) =>
        $active
            ? `
        position: relative;
        opacity: 1;
    `
            : ""}
`;

export const Item = styled.li<{ $active?: boolean; $disabled?: boolean }>`
    padding: ${({ theme: { base } }) => base.spacingSmall};
    margin: ${({ theme: { base } }) => base.spacingSmall} 0;
    border-radius: ${({ theme: { base } }) => base.borderRadius};
    background-color: ${({ theme: { colors } }) => colors.backgroundContrast};
    cursor: default !important;
    list-style-type: none;

    ${({ $active = false, theme: { colors } }) =>
        $active
            ? `
        ${AvatarTitle} {
            color: ${colors.primary};
        }
        ${AvatarTitleIconContainer} {
            display: block;
        }
    `
            : ""}

    ${({ $disabled = false }) =>
        $disabled
            ? `
        opacity: 1 !important;
        
        ${AvatarTitle}, ${AchievementsTitle}, ${AvatarTitle} ~ p {
            opacity: 0.5;
        }
    `
            : ""}
`;
