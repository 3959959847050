// REACT
import React, { FunctionComponent, useMemo } from "react";

// REDUX
import { useSelector, useDispatch } from "react-redux";
import { selectors, actions } from "../../../../redux/learning/learningSlice";

// COMPONENTS
import Component from "./Cards";

export const Cards: FunctionComponent = () => {
    const currentIndex = useSelector(selectors.currentItemIndex);
    const iterator = useSelector(selectors.iterator);
    const item = useSelector(selectors.currentItem);

    const dispatch = useDispatch();

    const [goNext] = useMemo(() => {
        if (!item) return [];
        const getChanger = (isBack: boolean) => {
            if (item.type === "drag") return;
            return () => {
                const action = [
                    [actions.goNext, actions.goNextSlice],
                    [actions.goBack, actions.goBackSlice],
                ][+isBack][+(item.type === "drag")];
                dispatch(action());
            };
        };
        return [getChanger(false), getChanger(true)];
    }, [dispatch, item]);

    return (
        <>
            {!!item && (
                <Component
                    goNext={goNext}
                    // goBack={goBack}
                    item={item}
                    index={currentIndex + iterator}
                />
            )}
        </>
    );
};
