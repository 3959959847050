import styled from "styled-components";

export const UserData = styled.div`
    > div {
        margin-top: ${(props) => props.theme.base.spacingInContent};
    }
`;

export const Label = styled.span`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: ${(props) => props.theme.colors.textOnBackground};
`;

export const HighlightedLabel = styled.span`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: ${(props) => props.theme.colors.primary};
`;
