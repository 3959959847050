// REACT
import React, { FunctionComponent, useEffect, useCallback } from "react";

import { ampli } from "../../../ampli";

// REDUX
import { useSelector, useDispatch } from "react-redux";
import { actions, selectors } from "../../../redux/avatars/avatarsSlice";
import { selectors as userSelectors, actions as userActions } from "../../../redux/user/userSlice";

// NETWORKING
import { SaveUserPreferencesParams } from "p6m-networking";

// TYPES
import { Avatar } from "p6m-avatars";

// COMPONENTS
import Component from "./SettingsAvatars";

const SettingsAvatars: FunctionComponent = () => {
    const userPreferences = useSelector(userSelectors.userPreferences);
    const avatars = useSelector(selectors.getFilteredAvatars);
    const userAvatarId = useSelector(userSelectors.getUserAvatarId);
    const userAvatar = useSelector(selectors.getAvatarById(userAvatarId));
    const dispatch = useDispatch();

    useEffect(() => {
        if (avatars !== false) return;
        dispatch(actions.fetchAvatars());
    }, [avatars, dispatch]);

    const saveAvatar = useCallback(
        (avatar: Avatar) => {
            let newPreferences = { ...userPreferences };
            newPreferences.userAvatarId = avatar.avatarId;

            ampli.selectedAvatar({
                avatar_id: avatar.avatarId,
            });

            let request: SaveUserPreferencesParams = {
                newPreferences: newPreferences,
                oldPreferences: { ...userPreferences },
            };

            dispatch(userActions.saveUserPreferences(request));
        },
        [dispatch]
    );

    return (
        <Component
            avatars={avatars || {}}
            userAvatar={userAvatar}
            saveAvatar={saveAvatar}
        />
    );
};

export default SettingsAvatars;
