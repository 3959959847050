// REACT
import React, { FunctionComponent, memo, useCallback, useMemo } from "react";
import { useT, T } from "@transifex/react";
import { useHistory } from "react-router-dom";

// UTILS
import Tooltip from "../../../basic/tooltip/Tooltip";
import { Row } from "../../../basic/grid";

// TYPES
import { SnapshotErrors } from "p6m-cards";
import { HistoryState } from "p6m-routing";

// COMPONENTS
import FirstCardModal from "../../../../components/complex/addCards/firstCardModal/FirstCardModal";

// STYLED COMPONENTS
import { StyledPrimaryButton, ButtonCol } from "./styles";

type Props = {
    isEdit: boolean;
    loading: boolean;
    firstCardModal: boolean;
    errors?: SnapshotErrors[];
    errorText?: string;
    afterCloseFirstCard: () => void;
    onSave: () => void;
    onGoBack: () => void;
};

const AddCardsButtons: FunctionComponent<Props> = (props) => {
    const history = useHistory<HistoryState>();
    const { isEdit, loading, firstCardModal, errors = [], afterCloseFirstCard, onSave, onGoBack } = props;
    const t = useT();

    const t_errorNoSubjectUnit = t("To save the card you need to select subject and unit", {});
    const t_errorNoQuestionAnswer = t("To save the card question and answer are requred", {});

    const errorText = (function () {
        const relation: Record<SnapshotErrors, string> = {
            EMPTY_SNAPSHOT: t_errorNoSubjectUnit,
            NO_SUBJECT: t_errorNoSubjectUnit,
            NO_UNIT: t_errorNoSubjectUnit,
            NO_ANSWER: t_errorNoQuestionAnswer,
            NO_QUESTION: t_errorNoQuestionAnswer,
        };

        if (!errors.length) return;

        return relation[errors[0]];
    })();

    const disabledSavebutton = !!errors.length || loading;

    const comingFromDictionary = useMemo(() => {
        const previousUrl = history.location.state?.from;
        const dictionaryRegex = new RegExp(/^\/dictionary/);
        return !!previousUrl?.match(dictionaryRegex);
    }, [history.location.state?.from]);

    const handleGoBackWithoutSaving = useCallback(() => {
        onGoBack();
    }, [onGoBack]);

    const handleSaveAndGoBack = useCallback(() => {
        onSave();
        onGoBack();
    }, [onSave, onGoBack]);

    const handleSave = useCallback(() => {
        onSave();
    }, [onSave]);

    return (
        <>
            <Row
                vertical="center"
                horizontal="end"
            >
                <ButtonCol xs="auto">
                    <StyledPrimaryButton
                        onClick={handleGoBackWithoutSaving}
                        data-tabindex={4}
                        tabIndex={4}
                    >
                        <T _str="End input of cards" />
                    </StyledPrimaryButton>
                </ButtonCol>
                {comingFromDictionary && (
                    <ButtonCol xs="auto">
                        <StyledPrimaryButton
                            onClick={handleSaveAndGoBack}
                            disabled={disabledSavebutton}
                            data-tabindex={2}
                            tabIndex={2}
                        >
                            <T _str="Save and back" />
                        </StyledPrimaryButton>
                    </ButtonCol>
                )}
                <ButtonCol xs="auto">
                    {errorText ? (
                        <Tooltip
                            content={errorText}
                            position="top"
                        >
                            <StyledPrimaryButton
                                onClick={handleSave}
                                disabled={disabledSavebutton}
                                data-tabindex={3}
                                tabIndex={3}
                            >
                                <T _str="Save and next" />
                            </StyledPrimaryButton>
                        </Tooltip>
                    ) : (
                        <StyledPrimaryButton
                            onClick={handleSave}
                            disabled={disabledSavebutton}
                            data-tabindex={3}
                            tabIndex={3}
                        >
                            {isEdit ? <T _str="Update Card" /> : <T _str="Save and next" />}
                        </StyledPrimaryButton>
                    )}
                </ButtonCol>
            </Row>
            {firstCardModal && <FirstCardModal afterClose={afterCloseFirstCard} />}
        </>
    );
};

export default memo(AddCardsButtons);
