import styled from "styled-components";

export const BadgeItem = styled.div<{ type: string }>`
    display: inline-block;
    height: 18px;
    min-width: 18px;
    padding: 0 2px;
    border-radius: 9px;
    ${({ type, theme: { colors } }) => {
        switch (type) {
            case "red":
                return `
                background-color: ${colors.red};
                border: 1px solid ${colors.red};
                color: ${colors.backgroundContrast};
            `;
            default:
                return `
                background-color: ${colors.primary};
                border: 1px solid ${colors.backgroundContrast};
                color: ${colors.backgroundContrast};
            `;
        }
    }}

    line-height: 15px;
    font-size: 12px;
    text-align: center;
    vertical-align: middle;
`;

export const BadgeWrapper = styled.span<{ bigIcon?: boolean }>`
    display: inline-block;
    position: relative;

    & > ${BadgeItem} {
        position: absolute;
        z-index: 1;
        top: 5px;
        right: ${(props) => (props.bigIcon ? "30px" : "20px")};
        transform: translate(30%, -30%);
    }
`;
