import styled from "styled-components";

export const BaseContainer = styled.div`
    width: 100%;
    padding-bottom: 10px;
    height: 100%;
    p {
        line-height: 1.65;
        margin: auto;
        width: fit-content;
    }
    a {
        font-weight: bold;
        cursor: pointer;
    }
    hr {
        border: none;
        border-bottom: 1px solid #d8d4d0;
        margin-top: 20px;
        margin-bottom: 30px;
    }
    @-webkit-keyframes fade-in {
        0% {
            opacity: 0;
        }

        100% {
            opacity: 1;
        }
    }
    @-moz-keyframes fade-in {
        0% {
            opacity: 0;
        }

        100% {
            opacity: 1;
        }
    }
    @keyframes fade-in {
        0% {
            opacity: 0;
        }

        100% {
            opacity: 1;
        }
    }
`;

export const GameContainer = styled.div`
    position: relative;
    cursor: default;
    -webkit-touch-callout: none;
    -ms-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -ms-touch-action: none;
    touch-action: none;
    border-radius: 6px;
    width: 100%;
    height: 460px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
`;

export const GameMessage = styled.div`
    display: none;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: calc(100% - 15px);
    background: rgba(238, 228, 218, 0.5);
    z-index: 50;
    text-align: center;
    -webkit-animation: fade-in 800ms ease 1200ms;
    -moz-animation: fade-in 800ms ease 1200ms;
    animation: fade-in 800ms ease 1200ms;
    -webkit-animation-fill-mode: both;
    -moz-animation-fill-mode: both;
    animation-fill-mode: both;
    a {
        display: inline-block;
        background: #8f7a66;
        border-radius: 3px;
        padding: 0 20px;
        text-decoration: none;
        color: #f9f6f2;
        height: 40px;
        line-height: 42px;
        margin-left: 9px;
    }
    &.game-won {
        background: rgba(237, 194, 46, 0.5);
        color: #f9f6f2;
        display: block;
    }
    &.game-over {
        display: block;
    }
`;

export const MessageText = styled.p`
    font-size: 60px;
    font-weight: bold;
    height: 60px;
    color: #666;
`;

export const LowerMessageButtons = styled.div`
    display: block;
    margin-top: 59px;
`;

export const KeepPlayingButton = styled.div`
    display: none;
    &.game-won {
        display: inline-block;
    }
`;

export const GridContainer = styled.div`
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
`;

export const GridRow = styled.div`
    margin-bottom: 15px;
    height: calc(25% - 11px);
    &:last-child {
        margin-bottom: 0;
    }
    &:after {
        content: "";
        display: block;
        clear: both;
    }
`;

export const GridCell = styled.div`
    width: calc(25% - 15px);
    margin-right: 15px;
    float: left;
    border-radius: 0.7rem;
    background: ${(props) => props.theme.buttons.secondary.backgroundColor};
    position: relative;
    height: 100%;
    &:last-child {
        margin-right: 0;
    }
`;

export const TitleContainer = styled.div`
    position: absolute;
    z-index: 2;
    width: 100%;
    height: 100%;
`;

export const RightContainer = styled.div`
    border: 1px solid #999;
    border-radius: 0.7rem;
    padding: 10px;
    position: relative;
    width: 320px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;

export const ScoresContainer = styled.div`
    width: 100%;
    height: 30%;
    display: flex;
    justify-content: center;
    align-items: baseline;
    min-height: 100px;
`;

export const ScoreContainer = styled.div`
    position: relative;
    display: inline-block;
    padding: 10px;
    font-size: 25px;
    height: 55px;
    color: ${(props) => props.theme.colors.text};
    margin-top: 8px;
    text-align: center;
    span {
        width: 100%;
        font-size: 16px;
    }
    b {
        font-weight: normal;
        font-size: 36px;

        > div {
            position: absolute;
            right: 30px;
            font-size: 25px;
            line-height: 25px;
            font-weight: bold;
            z-index: 100;
            -webkit-animation: move-up 600ms ease-in;
            -moz-animation: move-up 600ms ease-in;
            animation: move-up 600ms ease-in;
            -webkit-animation-fill-mode: both;
            -moz-animation-fill-mode: both;
            animation-fill-mode: both;
        }
    }
    &.best-container {
        span {
            font-weight: bold;
        }
    }
`;

export const GameHint = styled.div`
    img {
        width: 200px;
        margin: auto;
        display: block;
    }
    p {
        text-align: center;
        padding-top: 10px;
        color: ${(props) => props.theme.colors.text};
    }
`;

export const RestartButton = styled.div`
    display: flex;
    background: #ff8c00;
    border-radius: 0.7rem;
    padding: 0 20px;
    color: ${(props) => props.theme.buttons.primary.textColor};
    border: 1px solid #ff8c00;
    height: 50px;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    cursor: pointer;
    box-sizing: border-box;
    transition: 0.3s;

    &:hover {
        background: #ff8c00;
        border: 2px solid #fff;
    }
`;
