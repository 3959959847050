import styled, { keyframes } from "styled-components";
import { Col } from "../../../../components/basic/grid";
import { Background } from "../../styles";
import PhaseSixIcon from "../../../../components/basic/phaseSixIcon/PhaseSixIcon";

export const WordMatchBG = styled(Background)`
    position: relative;
`;

export const FitContentCol = styled(Col)`
    min-width: min-content !important;
`;

export const AddContentBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    height: 150px;
    min-height: 100%;
    min-width: 320px;
    padding: 16px 32px 32px 32px;
    background: none;
    border: 2px dashed ${({ theme: { colors } }) => colors.text};
    border-radius: ${({ theme: { base } }) => base.borderRadius};
    color: ${({ theme: { colors } }) => colors.text};
    font-size: ${({ theme: { base } }) => base.mediumSize};
    line-height: 1;
    cursor: pointer;
    transition: ${({ theme: { base } }) => base.transition}s;

    &:hover {
        color: ${({ theme: { colors } }) => colors.primary};
        border-color: ${({ theme: { colors } }) => colors.primary};
    }
`;

export const StyledPhaseSixIcon = styled(PhaseSixIcon)`
    margin-bottom: ${({ theme: { base } }) => base.spacingSmall};
    color: inherit;
`;

const counterAnimation = ({
    theme: {
        base: { iconBig, smallSize },
    },
}: any) => keyframes`
    0% {
        font-size: ${smallSize};
        counter-set: num 3;
    }
    32% {
        font-size: ${iconBig};
        counter-set: num 3;
    }
    33% {
        font-size: ${smallSize};
        counter-set: num 2;
    }
    66% {
        font-size: ${iconBig};
        counter-set: num 2;
    }
    67% {
        font-size: ${smallSize};
        counter-set: num 1;
    }
    100% {
        font-size: ${iconBig};
        counter-set: num 1;
    }
`;

export const BeforeGameStartCounter = styled.div<{ text: string }>`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 9;
    background-color: ${({ theme: { colors } }) => colors.backgroundMain + "cc"}; // todo: double check themes
    font-size: ${({ theme: { base } }) => base.smallSize};

    animation-name: ${counterAnimation};
    animation-timing-function: linear;
    animation-duration: 3s;
    counter-set: num 3;

    &:before {
        content: "${({ text }) => text}";
        margin-bottom: 10px;
        font-size: ${({ theme: { base } }) => base.extraBigSize};
        color: ${({ theme: { colors } }) => colors.primary};
        text-transform: uppercase;
    }

    &:after {
        content: counter(num);
        width: 100px;
        height: 100px;
        border-radius: 50%;
        background-color: ${({ theme: { colors } }) => colors.primary};
        font-size: inherit;
        color: #fff;
        text-align: center;
        line-height: 97px;
    }
`;
