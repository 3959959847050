// REACT
import React, { FunctionComponent, memo } from "react";
import { T } from "@transifex/react";

// COMPONENTS
import Modal, { Props as ModalProps } from "../../../../basic/modal/Modal";

// UTILS
import { getTeacherUrl, getParentUrl, getLearningSystemInfoUrl, getAppDownloadUrl } from "../../../../../helpers/Url";

// STYLED COMPONENTS
import { ModalBody, H3, Icon, P, BoxFront, BoxBack, PrimaryBox, AnimatedBox, Row, Col } from "./styles";

export type Props = {
    jossoId: string;
    permission: NotificationPermission | "unsupported";
    askPermission: () => void;
} & Partial<ModalProps>;

const ExitFirstPractice: FunctionComponent<Props> = (props) => {
    const { jossoId, permission, askPermission, ...restProps } = props;

    const parentUrl = getParentUrl(jossoId).href;
    const teacherUrl = getTeacherUrl(jossoId).href;
    const requestAppDownloadUrl = getAppDownloadUrl(jossoId).href;
    const moreInfoUrl = getLearningSystemInfoUrl().href;

    const notificationsDenied = permission === "denied";
    const notificationsEnabled = permission === "granted";

    return (
        <Modal
            active={true}
            title={
                <T
                    _str="Stick to me window's title"
                    _tags="Exit Intent"
                />
            }
            footerComponent={false}
            preventCloseOnBg
            {...restProps}
        >
            <ModalBody>
                <Row>
                    <Col>
                        <AnimatedBox $disableAnimation={notificationsEnabled || notificationsDenied}>
                            <BoxFront>
                                <Icon name="smartphone" />
                                <H3>
                                    <T
                                        _str="Regular reminder"
                                        _tags="Exit Intent"
                                    />
                                </H3>
                                {!notificationsEnabled && !notificationsDenied && (
                                    <>
                                        <P>
                                            <T
                                                _str="phase6 automatically reminds you to learn"
                                                _tags="Exit Intent"
                                            />
                                        </P>
                                        <P $primary>
                                            <b>
                                                <T
                                                    _str="about reminder"
                                                    _tags="Exit Intent"
                                                />
                                            </b>
                                        </P>
                                    </>
                                )}
                                {notificationsEnabled && (
                                    <>
                                        <P $primary>
                                            <T
                                                _str="You successfully activated daily reminder!"
                                                _tags="Exit Intent"
                                            />
                                        </P>
                                    </>
                                )}
                                {notificationsDenied && (
                                    <>
                                        <P $primary>
                                            <T
                                                _str="Oh no! Seems like you denied notifications. Try once more."
                                                _tags="Exit Intent"
                                            />
                                        </P>
                                    </>
                                )}
                            </BoxFront>
                            <BoxBack>
                                {!notificationsEnabled && permission !== "unsupported" && (
                                    <>
                                        <H3>
                                            <T
                                                _str="Try the browser notifications now and let me remind you regularly"
                                                _tags="Exit Intent"
                                            />
                                        </H3>
                                        <P
                                            onClick={askPermission}
                                            $primary
                                        >
                                            <b>
                                                <T
                                                    _str="Turn on notifications"
                                                    _tags="Exit Intent"
                                                />
                                            </b>
                                        </P>
                                    </>
                                )}
                                {permission === "unsupported" && (
                                    <>
                                        <H3>
                                            <T
                                                _str="Unfortunately, your browser doesn't support push notifications"
                                                _tags="Exit Intent"
                                            />
                                        </H3>
                                        <P>
                                            <T
                                                _str="Try updating your browser or use FF, Chrome"
                                                _tags="Exit Intent"
                                            />
                                        </P>
                                    </>
                                )}
                            </BoxBack>
                        </AnimatedBox>
                    </Col>
                    <Col>
                        <AnimatedBox>
                            <BoxFront>
                                <Icon name="clock" />
                                <H3>
                                    <T
                                        _str="Always in your pocket"
                                        _tags="Exit Intent"
                                    />
                                </H3>
                                <P>
                                    <T
                                        _str="phase6 is also available as an app for Android or iOS"
                                        _tags="Exit Intent"
                                    />
                                </P>
                                <P $primary>
                                    <b>
                                        <T
                                            _str="more info"
                                            _tags="Exit Intent"
                                        />
                                    </b>
                                </P>
                            </BoxFront>
                            <BoxBack>
                                <H3>
                                    <T
                                        _str="Learn offline anytime, wherever you are."
                                        _tags="Exit Intent"
                                    />
                                </H3>
                                <P>
                                    <T
                                        _str="So vocabulary learning is already ticked off when you're home"
                                        _tags="Exit Intent"
                                    />
                                </P>
                                <P $primary>
                                    <a
                                        href={requestAppDownloadUrl}
                                        target="_blank"
                                    >
                                        <b>
                                            <T
                                                _str="Request app download via e-mail"
                                                _tags="Exit Intent"
                                            />
                                        </b>
                                    </a>
                                </P>
                            </BoxBack>
                        </AnimatedBox>
                    </Col>
                    <Col>
                        <AnimatedBox>
                            <BoxFront>
                                <Icon name="cards_1" />
                                <H3>
                                    <T
                                        _str="Proven learning methodology"
                                        _tags="Exit Intent"
                                    />
                                </H3>
                                <P>
                                    <T
                                        _str="with phase6 you learn fast and effectively"
                                        _tags="Exit Intent"
                                    />
                                </P>
                                <P $primary>
                                    <b>
                                        <T
                                            _str="more info"
                                            _tags="Exit Intent"
                                        />
                                    </b>
                                </P>
                            </BoxFront>
                            <BoxBack>
                                <H3>
                                    <T
                                        _str="Would you like more information about learning systems, functions or prices? Then you are right here!"
                                        _tags="Exit Intent"
                                    />
                                </H3>
                                <P $primary>
                                    <a
                                        href={moreInfoUrl}
                                        target="_blank"
                                    >
                                        <b>
                                            <T
                                                _str="Call up information page"
                                                _tags="Exit Intent"
                                            />
                                        </b>
                                    </a>
                                </P>
                            </BoxBack>
                        </AnimatedBox>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <PrimaryBox
                            href={parentUrl}
                            target="_blank"
                        >
                            <Row>
                                <Col xs="auto">
                                    <Icon name="reports-add-user" />
                                </Col>
                                <Col>
                                    <H3>
                                        <T
                                            _str="Parent"
                                            _tags="Exit Intent"
                                        />
                                    </H3>
                                    <P>
                                        <T
                                            _str="Parents can start a Family with us and invite their Children to Study"
                                            _tags="Exit Intent"
                                        />
                                    </P>
                                    <br />
                                    <P>
                                        <b>
                                            <T
                                                _str="Create Family now"
                                                _tags="Exit Intent"
                                            />
                                        </b>
                                    </P>
                                </Col>
                            </Row>
                        </PrimaryBox>
                    </Col>
                    <Col>
                        <PrimaryBox
                            href={teacherUrl}
                            target="_blank"
                        >
                            <Row>
                                <Col xs="auto">
                                    <Icon name="genius" />
                                </Col>
                                <Col>
                                    <H3>
                                        <T
                                            _str="Teacher"
                                            _tags="Exit Intent"
                                        />
                                    </H3>
                                    <p>
                                        <T
                                            _str="Are you a teacher? Then create a digital learning group for your class"
                                            _tags="Exit Intent"
                                        />
                                    </p>
                                    <br />
                                    <p>
                                        <b>
                                            <T
                                                _str="Create Learngroup now"
                                                _tags="Exit Intent"
                                            />
                                        </b>
                                    </p>
                                </Col>
                            </Row>
                        </PrimaryBox>
                    </Col>
                </Row>
            </ModalBody>
        </Modal>
    );
};

export default memo(ExitFirstPractice);
