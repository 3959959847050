// REACT
import React, { useCallback } from "react";

// COMPONENTS
import SettingsPractice from "./SettingsPractice";

// TYPES
import { UserPreferences, PracticeSettingsBooleanPreferences } from "p6m-user";

// REDUX
import { useDispatch, useSelector } from "react-redux";
import { actions as userActions, selectors as userSelectors } from "../../../redux/user/userSlice";

// NETWORKING
import { SaveUserPreferencesParams } from "p6m-networking";

const SettingsPracticeView: React.FC = () => {
    const userPreferences = useSelector(userSelectors.userPreferences);
    function updateWrongAnswerBehaviour(enforce: boolean, retype: boolean) {
        const updatedChildrenPrefs = { ...userPreferences };
        updatedChildrenPrefs.enforceCorrectAnswerWeb = enforce;
        updatedChildrenPrefs.retypeCorrectAnswerWeb = retype;
        updateUserPreferences(updatedChildrenPrefs);
    }

    function updatePreferenceItem(
        entry: keyof Pick<UserPreferences, PracticeSettingsBooleanPreferences>,
        value?: boolean
    ) {
        const updatedPreference: UserPreferences = { ...userPreferences };
        updatedPreference[entry] = !!value;
        updateUserPreferences(updatedPreference);
    }

    function updateTthLimit(value: number) {
        const updatedPreference = { ...userPreferences };
        updatedPreference.tthLimit = value;
        updateUserPreferences(updatedPreference);
    }

    const dispatch = useDispatch();

    const updateUserPreferences = useCallback(
        async (newPreferences: UserPreferences) => {
            let request: SaveUserPreferencesParams = {
                newPreferences: newPreferences,
                oldPreferences: { ...userPreferences },
            };
            dispatch(userActions.saveUserPreferences(request));
        },
        [userPreferences, dispatch]
    );

    const practiceProps = {
        userPreferences,
        parentSettings: userPreferences.parentSettings,
        updatePreferenceItem,
        updateWrongAnswerBehaviour,
        updateTthLimit,
    };

    return <SettingsPractice {...practiceProps} />;
};

export default SettingsPracticeView;
