import styled from "styled-components";
import SimpleLink from "../../components/basic/simpleLink/SimpleLink";
import PhaseSixIcon from "../../components/basic/phaseSixIcon/PhaseSixIcon";

export const Container = styled.div`
    display: flex;
    flex-direction: row;
    padding: 10px 20px;
    justify-content: space-between;
    align-items: center;
    border-radius: ${(props) => props.theme.base.borderRadius};
    background-color: ${(props) => props.theme.colors.backgroundContrast};
`;

export const Title = styled.h1`
    font-size: ${(props) => props.theme.base.mediumSize};
    color: ${(props) => props.theme.colors.text};
    font-weight: ${(props) => props.theme.base.weight};
    font-family: ${(props) => props.theme.base.fontFamily};
    margin: 0 ${(props) => props.theme.base.spacingSmall} ${(props) => props.theme.base.spacing} 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
`;

export const Text = styled.div`
    font-size: ${(props) => props.theme.base.cardPracticeTextSize};
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${(props) => props.theme.colors.text};
`;

export const SmallerText = styled(Text)`
    font-size: ${(props) => props.theme.base.mediumSize};
    margin-top: -10px;
`;

export const HighlightText = styled.div`
    color: ${(props) => props.theme.colors.primary};
    font-size: ${(props) => props.theme.base.cardPracticeTextSize};
    font-weight: ${(props) => props.theme.base.weight};
    font-family: ${(props) => props.theme.base.fontFamily};
    padding-bottom: ${(props) => props.theme.base.spacing};
    text-align: center;
`;

export const HighlightTitle = styled.span`
    color: ${(props) => props.theme.colors.primary};
`;

export const List = styled.div`
    height: calc(100% - 354px);
    overflow-y: scroll;
`;

export const Wrapper = styled.div`
    position: relative;
    background: none;
    padding: 0 15px;
    height: 100%;
    min-height: 400px;
`;

export const Tab = styled.div`
    height: ${(props) => props.theme.base.iconBig};
    padding: ${(props) => props.theme.base.spacing};
    margin-right: ${(props) => props.theme.base.spacingTiny};
    border-bottom-width: ${(props) => props.theme.base.spacingTiny};
    border-bottom-style: solid;
    border-bottom-color: ${(props) =>
        props.color === "primary" ? props.theme.colors.backgroundContrast : "transparent"};
    border-top-left-radius: ${(props) => props.theme.base.borderRadiusBig};
    border-top-right-radius: ${(props) => props.theme.base.borderRadiusBig};
    background-color: ${(props) => props.theme.colors.backgroundContrast};
    background-clip: padding-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color: ${(props) => (props.color === "primary" ? props.theme.colors.primary : props.theme.colors.text)};
`;

export const TitleRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`;

export const IconsWrapper = styled.div`
    display: flex;
    flex: 1;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`;
export const TabWrapper = styled.div`
    margin-top: ${(props) => props.theme.base.spacingSmall};
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
`;

export const HeaderAngleIconRight = styled.div<{ week: number }>`
    font-size: ${(props) => props.theme.base.iconSmall};
    cursor: ${(props) => (props.week < 1000 ? "pointer" : "default")};

    &:hover {
        color: ${(props) => (props.week < 1000 ? props.theme.colors.primary : props.theme.colors.text)};
    }
`;

export const HeaderAngleIconLeft = styled.div<{ week: number }>`
    font-size: ${(props) => props.theme.base.iconSmall};
    cursor: ${(props) => (props.week > -1 ? "pointer" : "default")};

    &:hover {
        color: ${(props) => (props.week > -1 ? props.theme.colors.primary : props.theme.colors.text)};
    }
`;

export const HighlightedLink = styled(SimpleLink)`
    text-align: center;
    text-decoration: underline;
    font-size: inherit;
`;

export const InactiveLink = styled(SimpleLink)`
    cursor: default;
`;

export const HeaderContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 0 20px;
`;

export const IconRow = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-around;
    align-items: center;
    margin-top: -10px;
`;

export const IconCol = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

export const SearchPhaseSixIcon = styled(PhaseSixIcon)`
    float: left;
    color: ${(props) => props.theme.colors.textOnBackground};
    margin-left: 10px;
    cursor: pointer;

    &:hover {
        color: ${(props) => props.theme.colors.primary};
    }
`;

export const InfoPhaseSixIcon = styled(PhaseSixIcon)`
    float: right;
    color: ${(props) => props.theme.colors.textOnBackground};
    margin-right: 10px;
    cursor: pointer;

    &:hover {
        color: ${(props) => props.theme.colors.primary};
    }
`;

export const PlaceholderContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: ${(props) => props.theme.colors.backgroundContrast};
    padding: ${(props) => props.theme.base.spacing};
`;

export const PlaceholderText = styled.span`
    font-size: ${(props) => props.theme.base.bigSize};
    color: ${(props) => props.theme.colors.text};
`;

export const SelectSchoolButton = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${(props) => props.theme.colors.primary};
    cursor: pointer;
    position: absolute;
    bottom: 0;
    left: 15px;
    border-radius: 10px;
    box-shadow: 3px 3px 5px 0 rgba(50, 50, 50, 0.5);
    margin: 15px 0;
    height: 54px;
    width: calc(100% - 30px);
`;

export const SelectSchoolButtonText = styled.span`
    color: ${(props) => props.theme.colors.backgroundMain};
    font-size: ${(props) => props.theme.base.bigSize};
    text-transform: uppercase;
`;

/*
    INDEX STYLES
 */

export const PageWrapper = styled.div`
    height: 100%;
    min-height: 350px;
`;
