const LoadScript = (() => {
    const alreadyLoaded: string[] = [];

    return (paths: string | string[]): Promise<void[]> => {
        if (!paths) throw new Error("Path is required");
        if (typeof paths === "string") paths = [paths];

        const scriptsPromises = paths
            .filter((path: string) => !alreadyLoaded.includes(path))
            // eslint-disable-next-line
            .map((path: string) => (alreadyLoaded.push(path), path))
            .map(
                (path: string) =>
                    new Promise<void>((resolve, reject) => {
                        const script = document.createElement("script");
                        script.src = path;
                        script.onload = () => resolve();
                        script.onerror = (err) => reject(err);
                        document.getElementsByTagName("body")[0].appendChild(script);
                    })
            );

        if (!scriptsPromises.length) return Promise.resolve([]);

        return Promise.all(scriptsPromises);
    };
})();

export default LoadScript;
