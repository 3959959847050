// LIBRARIES
import React, { useState, useCallback, useMemo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

//NETWORK
import { CardDirection, TestResultsData, getTestResults } from "../../../networking/tests";
import { getCardsFromSubject } from "../../../networking/cards";

// REDUX
import { actions, selectors } from "../../../redux/tests/testsSlice";
import { selectors as userSelectors } from "../../../redux/user/userSlice";
import { StartActionPayload, actions as learningActions } from "../../../redux/learning/learningSlice";

// COMPONENTS
import Component from "./TestDetails";

//TYPE
import { IdToOptionalOwnerId, TestListItemInfo } from "p6m-tests";
import { PracticeType } from "p6m-learning";

//STYLES
import { TestsContentContainer } from "../styles";

const getCardsFromCardIds = async ({ cardIds }: { cardIds: IdToOptionalOwnerId[] }) => {
    const cardIdsOfSelectedTest = cardIds.map((idToOwnerId) => idToOwnerId.id) || [];

    const {
        data: {
            replyContent: { cards },
        },
    } = await getCardsFromSubject({ cardIds: cardIdsOfSelectedTest, noLimit: true });

    return cards;
};

const TestDetails: React.FC = () => {
    const history = useHistory();
    const { testId } = useParams<{ testId: string }>();
    const dispatch = useDispatch();

    const subject = useSelector(selectors.subject);
    const subjectId = useMemo(() => subject?.subjectMetadata.subjectIdToOwner.id || "", [subject]);

    const hasPremium = useSelector(userSelectors.userHasPremium);
    const allTests = useSelector(selectors.allTestsBySubjectId(subjectId));

    const [testInfo, setTestInfo] = useState<TestListItemInfo | undefined>();
    const [testResults, setTestResults] = useState<TestResultsData>({ objects: [], revisionNumber: 0 });

    const goBack = useCallback(() => {
        history.push({ pathname: "/tests", search: `?overviewType=${testInfo?.type}` });
    }, [history, testInfo]);

    const startPractice = useCallback(async () => {
        const { direction, cardIds }: { direction: CardDirection; cardIds: IdToOptionalOwnerId[] } = testInfo || {
            direction: "NORMAL",
            cardIds: [],
        };

        const cards = await getCardsFromCardIds({ cardIds });

        const directionByKey: { [key in CardDirection]: number } = {
            NORMAL: 0,
            BOTH: 1,
            OPPOSITE: 2,
        };

        let practiceType: PracticeType;
        switch (testInfo?.type) {
            case "OWN_TESTS":
                practiceType = "test";
                break;
            case "SHARED_TESTS":
                practiceType = "exercise";
                break;
            default:
                practiceType = "practice";
        }

        const startData: StartActionPayload = {
            history,
            subjectId,
            testId,
            cards,
            direction: directionByKey[direction],
            type: 3, //Practice Immediately
            isPrepareForTest: true,
            filter: "LIBRARY",
            practiceType: practiceType,
        };

        dispatch(learningActions.start(startData));
    }, [history, dispatch, history, subjectId, testInfo]);

    useEffect(() => {
        if (!allTests.length) {
            dispatch(actions.fetchTests());
        }
    }, [dispatch, allTests.length]);

    useEffect(() => {
        const info = allTests.find((test) => test.id === testId);
        info && setTestInfo(info);
    }, [allTests, testId]);

    useEffect(() => {
        const retrieveTestResults = async () => {
            const results = await getTestResults({ testId });
            if (!Array.isArray(results.data.replyContent.objects)) {
                results.data.replyContent.objects = [];
            }
            setTestResults(results.data.replyContent);
        };

        retrieveTestResults();
    }, [testId]);

    return (
        <TestsContentContainer>
            {subject && !!testInfo && (
                <Component
                    testInfo={testInfo}
                    testResults={testResults}
                    selectedSubject={subject}
                    onGoBack={goBack}
                    onStartPractice={startPractice}
                    userHasPremium={hasPremium}
                />
            )}
        </TestsContentContainer>
    );
};

export default TestDetails;
