//LIBRARIES
import { AxiosResponse } from "axios";
import { PayloadAction } from "@reduxjs/toolkit";
import { takeEvery, select, call, put } from "redux-saga/effects";
import { ampli } from "../../ampli";

//REDUX
import { actions as responseActions } from "../response/responseSlice";
import { selectors as userSelectors } from "../user/userSlice";
import { actions, selectors } from "./addSlice";
import { selectors as subjectSelectors, actions as subjActions } from "../subjects/subjectsSlice";
import { actions as appStatusActions } from "../appStatus/appStatusSlice";

//QUERIES&MUTATIONS
import {
    getCardContent,
    getCardAnnotation,
    postNewCard,
    updateCardAnnotation,
    sharingCardsList,
    getShortLink,
} from "../../networking/cards";

//TYPES
import { AddCardSnapshot, SnapshotErrors, CardContent, SharingCardsListResponse, ShortLinkResponse } from "p6m-cards";
import { IResponse } from "p6m-response";
import { SubjectData, SubjectUnitData, SubjectUnitCountResponseData } from "p6m-subjects";

//COMPONENTS
import { CardEditTemplate } from "../../mock/cards/cardTemplate";
import { ResponseMessageKeys } from "../../components/connected/response";

//HELPERS
import { defaultUnitOrder, defaultSubjectOrder } from "../../constants/ContentConstants";
import { getCardContentInfo, generateCardMediaString } from "../../helpers/Cards";
import { generateUuid } from "../../helpers/Id";
import { getShareCardsUrl } from "../../helpers/Share";
import { createSingleSubject, getDefaultSubjectId } from "../../helpers/Subjects";
import { createSingleUnit, getDefaultUnitId } from "../../helpers/Units";
import { t } from "@transifex/native";
import { getSubjectCardsCount } from "../../networking/subjects";
import { getSubjectUnitsCount } from "../../networking/units";

const createDefaultSubject = async (myId: string, subjectId?: string) => {
    if (subjectId && subjectId !== "default") return subjectId;
    const subjectIdToCreate = getDefaultSubjectId(myId);
    await createSingleSubject({
        userId: myId,
        subjectId: subjectIdToCreate,
        subjectName: t("General", {}),
        subjectOrder: defaultSubjectOrder,
        withDefaultUnit: false,
    });

    return subjectIdToCreate;
};

const createDefaultUnit = async (myId: string, unitId?: string, subjectId?: string) => {
    if (unitId && unitId !== "default") return unitId;
    if (!subjectId) return unitId;
    const unitIdToCreate = getDefaultUnitId(subjectId);
    await createSingleUnit({
        userId: myId,
        subjectId,
        unitId: unitIdToCreate,
        unitName: t("General", {}),
        unitOrder: defaultUnitOrder,
    });
    return unitIdToCreate;
};

// will transform any occurrences of "rgb(a, b, c)" in a string to its corresponding hex value
// no need to check for other rgb formats as this is the only one we'll get here (from the Quill editor)
function RGBToHex(contentText: string): string {
    // search for all RGB matches in given text
    const rgbMatchesInText = Array.from(contentText.matchAll(/rgb\(\d+, \d+, \d+\)/g));

    rgbMatchesInText.forEach((resultAsArray) => {
        const currentRgbMatch = resultAsArray[0];
        // Turn "rgb(r,g,b)" into [r,g,b]
        const rgb = currentRgbMatch.toString().substr(4).split(")")[0].split(",");

        let r = (+rgb[0]).toString(16),
            g = (+rgb[1]).toString(16),
            b = (+rgb[2]).toString(16);

        if (r.length == 1) r = "0" + r;
        if (g.length == 1) g = "0" + g;
        if (b.length == 1) b = "0" + b;

        const hexColorValue = "#" + r + g + b;

        contentText = contentText.replace(currentRgbMatch.toString(), hexColorValue);
    });

    return contentText;
}

export function* saveCardAsync({
    payload,
}: PayloadAction<{
    hasQuestionAnnotationChanged: boolean;
    hasAnswerAnnotationChanged: boolean;
    isPublisherSubject?: () => boolean;
}>) {
    yield put(appStatusActions.setLoading(true));
    yield put(actions.setSaving(true));

    try {
        const errors: SnapshotErrors[] = yield select(selectors.validateSnapshot);
        if (errors.length) throw errors;

        const areAnnotationsValid: boolean = yield select(selectors.areAnnotationsValid);
        if (!areAnnotationsValid) throw ["ANNOTATION_VALIDATION_ERROR"];

        const myId: string = yield select(userSelectors.userId);
        const snapshot: AddCardSnapshot = yield select(selectors.getAddCardsSnapshot) || {};
        let {
            cardId = generateUuid(),
            subjectId,
            unitId,
            ownerId: cardOwnerId = myId,
            answer = {},
            question = {},
            question: { annotation: questionAnnotation, hasAnnotationChanges: questionHasAnnotationChanges } = {},
            answer: { annotation: answerAnnotation, hasAnnotationChanges: answerHasAnnotationChanges } = {},
        } = snapshot;

        // check for rgb colors and replace them with hex values before sending the data to the API
        const transformedQuestionText = RGBToHex(question.text || "");
        const transformedAnswerText = RGBToHex(answer.text || "");

        subjectId = yield createDefaultSubject(myId, subjectId);
        unitId = yield createDefaultUnit(myId, unitId, subjectId);

        let subject: SubjectData | undefined = undefined;
        let unit: (SubjectUnitData & SubjectUnitCountResponseData) | undefined = undefined;
        if (subjectId && subjectId !== "default") {
            subject = yield select(subjectSelectors.getSubjectById(subjectId, "librarySubjects"));
            if (unitId && unitId !== "default") {
                unit = yield select(subjectSelectors.getUnitById(unitId, subjectId));
            }
        }

        let subjectOwnerId = myId;
        if (subject) {
            subjectOwnerId = subject.subjectMetadata.subjectIdToOwner.ownerId;
        }
        let unitOwnerId = myId;
        if (unit) {
            unitOwnerId = unit.unitId.ownerId;
        }

        const questionMediaString = generateCardMediaString({
            images: question.images,
            audios: question.audios,
        });

        const answerMediaString = generateCardMediaString({
            images: answer.images,
            audios: answer.audios,
        });

        const requestData = {
            ...CardEditTemplate,
            question: transformedQuestionText + questionMediaString,
            answer: transformedAnswerText + answerMediaString,
            ownerId: myId,
            subjectIdToOwner: {
                id: subjectId,
                ownerId: subjectOwnerId,
            },
            unitIdToOwner: {
                id: unitId,
                ownerId: unitOwnerId,
            },
        };

        const {
            data: { httpCode: httpCodeAfterCardWasPosted, replyContent },
        }: AxiosResponse<any> = yield call(postNewCard, cardId, cardOwnerId, requestData);

        if (httpCodeAfterCardWasPosted !== 200) {
            throw Array(replyContent);
        }

        if (questionHasAnnotationChanges || answerHasAnnotationChanges) {
            yield call(updateCardAnnotation, cardId, subjectOwnerId, myId, subjectId, {
                questionAnnotation,
                answerAnnotation,
            });
        }
        if (!snapshot.cardId) {
            yield put(actions.clearAddCardContents());
            yield put(
                responseActions.showResponse({
                    type: "SUCCESS",
                    responseMessage: ResponseMessageKeys.CARD_SAVED,
                })
            );
        } else {
            yield put(
                responseActions.showResponse({
                    type: "SUCCESS",
                    responseMessage: ResponseMessageKeys.CARD_UPDATED,
                })
            );
        }

        // send connected amplitude events
        const { hasQuestionAnnotationChanged, hasAnswerAnnotationChanged, isPublisherSubject } = payload;

        // this will only be set if the card that needs to be saved is being created (instead of edited later)
        if (!!isPublisherSubject)
            ampli.createCardA({ app_screen: "input", is_publisher_content: isPublisherSubject() });

        if (hasQuestionAnnotationChanged) {
            ampli.noteAdded({ app_screen: "input", location: "question" });
        }
        if (hasAnswerAnnotationChanged) {
            ampli.noteAdded({ app_screen: "input", location: "answer" });
        }

        if (subjectId && unitId) {
            const {
                data: {
                    replyContent: { cardCount: subjectCardCount },
                },
            } = yield call(getSubjectCardsCount, subjectId);

            const {
                data: { replyContent: unitCountResponse },
            }: AxiosResponse<IResponse<{ units: SubjectUnitCountResponseData[] }>> = yield call(
                getSubjectUnitsCount,
                subjectId
            );

            const unitCardCount = unitCountResponse.units.find((unit) => unit.unitId.id === unitId)?.cardCount;

            if (subjectCardCount !== undefined && unitCardCount !== undefined) {
                yield put(
                    subjActions.setLibrarySubjectCardCount({ subjectId, unitId, subjectCardCount, unitCardCount })
                );
            }

            if (unitId) {
                yield put(actions.setCardUnitId(unitId));
            }
        }

        const subjectUnits: AddCardSnapshot = yield select(
            subjectSelectors.getUnitsBySubjectId(subjectId, "librarySubjects")
        );
        if (!subjectUnits) {
            yield put(subjActions.loadSubjectUnits({ subjectId, target: "librarySubjects" }));
        }
    } catch (e) {
        console.log(e);
        const errors: Record<
            SnapshotErrors | "DUPLICATED_CONTENT" | "ANNOTATION_VALIDATION_ERROR",
            ResponseMessageKeys
        > = {
            EMPTY_SNAPSHOT: ResponseMessageKeys.TO_SAVE_SELECT_SUBJECT_UNIT,
            NO_SUBJECT: ResponseMessageKeys.TO_SAVE_SELECT_SUBJECT_UNIT,
            NO_UNIT: ResponseMessageKeys.TO_SAVE_SELECT_SUBJECT_UNIT,
            NO_ANSWER: ResponseMessageKeys.TO_SAVE_QUESTION_ANSWER_REQUIRED,
            NO_QUESTION: ResponseMessageKeys.TO_SAVE_QUESTION_ANSWER_REQUIRED,
            DUPLICATED_CONTENT: ResponseMessageKeys.SAVING_FAILED_VIA_CONTENT_DUPLICATION,
            ANNOTATION_VALIDATION_ERROR: ResponseMessageKeys.ANNOTATION_VALIDATION_ERROR,
        };
        const key: SnapshotErrors | "DUPLICATED_CONTENT" = Array.isArray(e) ? e[0] : undefined;

        if (errors[key]) {
            yield put(responseActions.showResponse({ type: "ERROR", responseMessage: errors[key] }));
        } else {
            yield put(
                responseActions.showResponse({
                    type: "ERROR",
                    responseMessage: ResponseMessageKeys.SAVING_FAILED,
                })
            );
        }
    } finally {
        yield put(appStatusActions.setLoading(false));
        yield put(actions.setSaving(false));
    }
}

function* fetchCardContent({ payload }: PayloadAction<Parameters<typeof getCardContent>[0]>) {
    yield appStatusActions.setLoading(true);
    try {
        const { cardId, ownerId } = payload;
        const {
            data: { replyContent },
        }: AxiosResponse<IResponse<CardContent>> = yield call(getCardContent, payload);
        const {
            question,
            answer,
            unitIdToOwner: { id: unitId },
            subjectIdToOwner: { id: subjectId },
        } = replyContent;
        const questionContent = getCardContentInfo(question);
        const answerContent = getCardContentInfo(answer);

        yield put(
            actions.saveAddCardSnapshot({
                cardId,
                ownerId,
                subjectId,
                unitId,
                question: {
                    text: questionContent.htmlTitle,
                    images: questionContent.images,
                    audios: questionContent.audios,
                },
                answer: {
                    text: answerContent.htmlTitle,
                    images: answerContent.images,
                    audios: answerContent.audios,
                },
            })
        );

        try {
            const {
                data: { replyContent: annotationReply },
            }: AxiosResponse<IResponse<any>> = yield call(getCardAnnotation, cardId, ownerId, subjectId);
            const { answerAnnotation, questionAnnotation } = annotationReply;
            yield put(actions.setQuestionAnnotation(questionAnnotation));
            yield put(actions.setAnswerAnnotation(answerAnnotation));
        } catch (e) {
            console.log(e);
            yield put(appStatusActions.setMaintenance(true));
        }
    } catch (e) {
        console.log(e);
        yield put(appStatusActions.setMaintenance(true));
    } finally {
        yield appStatusActions.setLoading(false);
    }
}

function updateLanguageGenerator(target: "primaryLang" | "secondaryLang") {
    return function* ({ payload }: PayloadAction<string>) {
        const subjectId: string | undefined = yield select(selectors.getAddCardsSubjectId);
        if (!subjectId) return;
        const subject: SubjectData | undefined = yield select(
            subjectSelectors.getSubjectById(subjectId, "librarySubjects")
        );
        if (!subject) return;
        const {
            subjectContent: { name },
            subjectContent,
            subjectMetadata: {
                subjectIdToOwner: { ownerId },
            },
        } = subject;

        if (subjectContent[target] === payload) return;

        yield put(
            subjActions.complexSubjectsRest({
                updated: [
                    {
                        subjectId,
                        ownerId,
                        data: {
                            name,
                            [target]: payload,
                        },
                    },
                ],
            })
        );
    };
}

const updatePrimaryLang = updateLanguageGenerator("primaryLang");
const updateSecondaryLang = updateLanguageGenerator("secondaryLang");

function* fetchSharingContent() {
    yield appStatusActions.setLoading(true);
    try {
        const {
            data: {
                replyContent: { sharingContentDataMap },
            },
        }: AxiosResponse<IResponse<SharingCardsListResponse>> = yield call(sharingCardsList);
        if (!sharingContentDataMap) return;
        yield put(actions.setSharingContent(sharingContentDataMap));
    } catch (e) {
        console.log(e);
    } finally {
        yield appStatusActions.setLoading(false);
    }
}

function* fetchShareLink({ payload }: PayloadAction<string>) {
    yield appStatusActions.setLoading(true);
    try {
        const userId: string = yield select(userSelectors.userId);
        const link = getShareCardsUrl(userId, payload);
        const {
            data: {
                replyContent: { shortLink },
            },
        }: AxiosResponse<IResponse<ShortLinkResponse>> = yield call(getShortLink, {
            addSessionId: payload,
            link,
        });
        if (!shortLink) return;
        yield put(
            actions.updateSharingContentLink({
                id: payload,
                newLink: shortLink,
            })
        );
    } catch (e) {
        console.log(e);
    } finally {
        yield appStatusActions.setLoading(false);
    }
}

function* toggleDontShowConfirmEditModal({ payload }: PayloadAction<string>) {
    if (payload) return;
    const userId: string = yield select(userSelectors.userId);
    yield put(actions.toggleDontShowConfirmEditModal(userId));
}

function* setFirstCardReminder({ payload }: PayloadAction<string>) {
    if (payload) return;
    const userId: string = yield select(userSelectors.userId);
    yield put(actions.setFirstCardReminder(userId));
}

export function* addSaga() {
    yield takeEvery(actions.saveCard, saveCardAsync);
    yield takeEvery(actions.setCardPrimaryLang, updatePrimaryLang);
    yield takeEvery(actions.setCardSecondaryLang, updateSecondaryLang);
    yield takeEvery(actions.fetchCardContent, fetchCardContent);
    yield takeEvery(actions.fetchSharingContent, fetchSharingContent);
    yield takeEvery(actions.fetchShareLink, fetchShareLink);
    yield takeEvery(actions.toggleDontShowConfirmEditModal, toggleDontShowConfirmEditModal);
    yield takeEvery(actions.setFirstCardReminder, setFirstCardReminder);
}
