// LIBRARIES
import React, { FunctionComponent, useCallback, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectors } from "../../../../redux/add/addSlice";
import { isSupported as isLanguagesSupportedByDictionary } from "../../../../helpers/Dictionary";

// COMPONENTS
import Component from "../../../complex/addCards/dictionaryButton/DictionaryButton";

export const DictionaryButton: FunctionComponent = () => {
    const primaryLang = useSelector(selectors.getAddCardsPrimaryLang);
    const secondaryLang = useSelector(selectors.getAddCardsSecondaryLang);
    const question = useSelector(selectors.getAddCardQuestionRawText);
    const answer = useSelector(selectors.getAddCardAnswerRawText);
    const history = useHistory();

    const language: string | undefined = question ? secondaryLang : answer ? primaryLang : secondaryLang;

    const isSupported: boolean = useMemo(() => {
        return isLanguagesSupportedByDictionary({ primaryLang, secondaryLang });
    }, [primaryLang, secondaryLang]);

    const disabled: boolean = useMemo(() => {
        const words: number = question.split(" ").filter((question) => !!question).length;
        return !isSupported || (!question && !answer) || words > 2;
    }, [question, answer, isSupported]);

    const onClick = useCallback(() => {
        if (disabled) return;
        const text = question || answer;
        // select the non "de" language for dictionary
        const lang = question && primaryLang !== "de" ? primaryLang : secondaryLang;

        history.push("/dictionary/" + lang + "/" + text);
    }, [question, answer, primaryLang, secondaryLang, history, disabled]);

    return (
        <Component
            language={language}
            isSupported={isSupported}
            disabled={disabled}
            text={question || answer}
            onClick={onClick}
        />
    );
};
