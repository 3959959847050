// REACT
import React from "react";

// STYLED COMPONENTS
import { ReactComponent as Phase6Logo } from "../../../assets/img/p6_logo.svg";
import { Background, Container } from "./styles";

const Welcome: React.FC = (props) => {
    return (
        <Background {...props}>
            <Container>
                <Phase6Logo title={"Phase 6 Logo"} />
            </Container>
        </Background>
    );
};

export default Welcome;
