// REACT
import React, { FunctionComponent, useEffect, useRef } from "react";
import { useHistory, useParams } from "react-router-dom";

// REDUX
import { useDispatch, useSelector } from "react-redux";
import { actions, selectors } from "../../redux/learning/learningSlice";
import { actions as subjectsActions, actions as subjectActions } from "../../redux/subjects/subjectsSlice";
import { actions as goalsActions } from "../../redux/goals/goalsSlice";
import { actions as warningsActions } from "../../redux/warnings/warningsSlice";

// COMPONENTS
import Component from "./Practice";

// UTILS
import { ampli, PracticeFinishedPxpProperties, PracticeStartedPxpProperties } from "../../ampli";
import { getTestResults } from "../../networking/tests";
import { useFirstPracticeResult } from "../../hooks/useFirstPracticeResult";

const Practice: FunctionComponent = () => {
    const history = useHistory();
    const { testId } = useParams<{ testId?: string }>();

    const cards = useSelector(selectors.cards);
    const items = useSelector(selectors.items);
    const areCardsLoaded = useSelector(selectors.areCardsLoaded);
    const areItemsLoaded = !!(cards.length && items.length === cards.length);
    const subjectId = useSelector(selectors.subjectId);
    const isFinished = useSelector(selectors.isFinished);
    const accelerate = useSelector(selectors.accelerate);
    const dispatch = useDispatch();
    const hasFirstPractice = useFirstPracticeResult();

    useEffect(() => {
        dispatch(subjectActions.setActiveSubjectId(subjectId));
    }, [dispatch, subjectId]);

    useEffect(() => {
        if (!isFinished || !accelerate) return;

        // Added a delay to allow time to update the number of cards.
        setTimeout(() => {
            dispatch(
                subjectsActions.loadUserSubjects({
                    callback: () => {
                        dispatch(actions.pushModal("done"));
                    },
                })
            );
        }, 1000);
    }, [dispatch, isFinished, accelerate]);

    useEffect(() => {
        if (!isFinished) return;
        if (testId) {
            dispatch(actions.savePracticeTestResult(testId));
        }
    }, [dispatch, isFinished, testId]);

    useEffect(
        function () {
            if (!isFinished || hasFirstPractice) return;
            dispatch(goalsActions.finishFirstPractice());
        },
        [dispatch, isFinished, hasFirstPractice]
    );

    useEffect(() => {
        if (areCardsLoaded && !cards.length) {
            history.push("/home");
        } else if (cards.length) {
            dispatch(actions.fetch());
        }
    }, [dispatch, cards.length, areCardsLoaded]);

    useEffect(() => {
        dispatch(subjectActions.loadSubjectUnits(subjectId));
    }, [dispatch, subjectId]);

    useEffect(() => {
        dispatch(warningsActions.fetchWarnings());
    }, [dispatch]);

    useLogPracticeEvents({ isFinished, areCardsLoaded, areItemsLoaded });

    return <>{!!cards && <Component />}</>;
};

export default Practice;

// todo: split these into multiple files in the future
function useLogPracticeEvents({
    isFinished,
    areCardsLoaded,
    areItemsLoaded,
}: {
    isFinished: boolean;
    areCardsLoaded: boolean;
    areItemsLoaded: boolean;
}) {
    const { testId } = useParams<{ testId?: string }>();
    const memorized = useSelector(selectors.filteredItems({ type: ["drag", "memorize"] }));
    const practiced = useSelector(selectors.filteredItems({ type: "practice" }));
    const practiceLogParams = useSelector(selectors.practiceLogParams);
    const isPracticeStartedDone = useRef<boolean>(false);
    const isPracticeFinishedDone = useRef<boolean>(false);

    const cards_learned: number = practiced.filter(({ resolved }) => resolved)?.length || 0;
    const getTestResultAmount = async (testId: string) => {
        const result = await getTestResults({ testId });
        return result.data.replyContent.objects.length;
    };

    useEffect(() => {
        if (!practiceLogParams || isPracticeFinishedDone.current || !isFinished) return;
        const { book_has_accessment_mode, activation_mode, mode } = practiceLogParams;

        const practiceFinishedProps: PracticeFinishedPxpProperties = {
            cards_for_practice: practiced.length || memorized.length,
            book_has_accessment_mode,
            activation_mode,
            mode,
            cards_learned,
        };

        if (practiceFinishedProps.mode !== "regular_practice" && testId) {
            getTestResultAmount(testId).then((resultAmount) => {
                practiceFinishedProps.revision_no = resultAmount || 0;
            });

            if (
                practiceFinishedProps.mode === "exercise_moved_to_practice" ||
                practiceFinishedProps.mode === "exercise_only"
            ) {
                practiceFinishedProps.test_origin = "teacher";
                practiceFinishedProps.exercise_id = testId;
            }
        }

        isPracticeFinishedDone.current = true;
        ampli.practiceFinishedPxp(practiceFinishedProps);
    }, [practiceLogParams, cards_learned, isFinished, testId]);

    useEffect(() => {
        if (!practiceLogParams || isPracticeStartedDone.current || !areItemsLoaded) return;
        const { book_has_accessment_mode, activation_mode, mode, startsFrom } = practiceLogParams;
        if (!startsFrom) return;

        const practiceStartedProps: PracticeStartedPxpProperties = {
            cards_for_practice: practiced.length || memorized.length,
            book_has_accessment_mode,
            activation_mode,
            mode,
        };

        if (practiceStartedProps.mode !== "regular_practice" && testId) {
            getTestResultAmount(testId).then((resultAmount) => {
                // as the first time the exercise will be done should result in the revisionNo equaling 1
                // we need to increase the number of already finished tests by 1 respectively
                practiceStartedProps.revision_no = (resultAmount || 0) + 1;
            });

            if (
                practiceStartedProps.mode === "exercise_moved_to_practice" ||
                practiceStartedProps.mode === "exercise_only"
            ) {
                practiceStartedProps.test_origin = "teacher";
                practiceStartedProps.exercise_id = testId;
            }
        }

        isPracticeStartedDone.current = true;
        ampli.practiceStartedPxp(practiceStartedProps);
    }, [practiceLogParams, testId, memorized, practiced, areItemsLoaded]);

    useEffect(() => {
        if (!areCardsLoaded) {
            isPracticeStartedDone.current = false;
            isPracticeFinishedDone.current = false;
        }
    }, [areCardsLoaded]);
}
