// REACT
import React, { FunctionComponent, memo } from "react";

// STYLED COMPONENTS
import { ProgressWrapper, ProgressLine } from "./styles";

type Props = {
    total: number;
    practiced?: number;
    memorised?: number;
};

const Progress: FunctionComponent<Props> = (props) => {
    const { total, practiced, memorised } = props;

    const width = [getWidth(total, memorised), getWidth(total, practiced)];

    return (
        <ProgressWrapper>
            {width
                .filter((value: number) => !!value)
                .map((value: number) => value - 1) // because getWidth returns value + 1
                .map((value: number, i: number) => (
                    <ProgressLine
                        key={i}
                        value={value}
                    />
                ))}
        </ProgressWrapper>
    );
};

function getWidth(total: number, value?: number): number {
    return typeof value === "undefined" ? 0 : (100 / total) * value + 1; // will return result + 1, so maximum is 101 and minimum is 1
}

export default memo(Progress);
