// REACT
import React, { useState } from "react";
import { useT } from "@transifex/react";

// STYLED COMPONENTS
import {
    Container,
    Row,
    Column,
    ColumnWide,
    ColumnNarrow,
    Block,
    HighlightedLabel,
    HighlightedValue,
    More,
    UserAvatarWrapper,
} from "./styles";

// TYPES
import { UserStats } from "p6m-user";

// COMPONENTS
import LeaderBoardUserStatistic from "../../leaderboardUserStatistic/LeaderBoardUserStatistic";
import PhaseSixIcon from "../../phaseSixIcon/PhaseSixIcon";
import UserAvatar from "../../userAvatar/UserAvatar";

export interface leaderBoardUserItemProps {
    user: UserStats;
    userAvatarLink?: string;
    isSchool?: boolean;
    isUserCheater?: boolean;
    showWarning?: (message: string) => void;
}

const OwnLeaderBoardUserItem: React.FC<leaderBoardUserItemProps> = (props) => {
    const t = useT();
    const {isUserCheater = false, showWarning} = props;

    const [details, toggleDetails] = useState<boolean>(false);

    const regularlyLearned_t = t("Regularly learned", { _tags: "label" });
    const learnedForTest_t = t("Learned for Test", { _tags: "label" });
    const newVocabulary_t = t("New vocabulary", { _tags: "label" });
    const typedLetters_t = t("Typed letters", { _tags: "label" });
    const t_warningsNotification = t("No more points will be counted this week as we have identified some suspicious learning behavior.");

    return (
        <Container>
            <Row>
                <Column>
                    <HighlightedLabel>
                        {props.user.position + 1 > 0 ? (props.user.position + 1).toLocaleString("de-DE") : "-"}
                    </HighlightedLabel>
                </Column>
                <Column>
                    {props.isSchool ? (
                        <PhaseSixIcon
                            name="school_building"
                            size="medium"
                            highlighted
                        />
                    ) : (
                        <UserAvatarWrapper>
                            <UserAvatar
                                userAvatar={props.userAvatarLink}
                                highlighted
                            />
                        </UserAvatarWrapper>
                    )}
                </Column>
                <Block>
                    <ColumnWide>
                        <HighlightedLabel>
                            {props.user.userName}
                            <br />
                            {!!props.user.countryRegionName && `${props.user.countryRegionName + " - "}`}
                            {!!props.user.street && `${props.user.street} `}
                            {!!props.user.zip && `${props.user.zip} `}
                            {!!props.user.city && `${props.user.city} `}
                        </HighlightedLabel>
                    </ColumnWide>
                    {
                        !!isUserCheater && <ColumnNarrow>
                            <HighlightedValue $pointer>
                                <PhaseSixIcon
                                    name="limit"
                                    size="medium"
                                    onClick={() => showWarning?.(t_warningsNotification)}
                                    highlighted
                                />
                            </HighlightedValue>
                        </ColumnNarrow>
                    }
                    <ColumnNarrow>
                        <HighlightedValue>{props.user.points?.toLocaleString("de-DE")}</HighlightedValue>
                    </ColumnNarrow>
                </Block>
                <Column>
                    <More
                        key={"up" + props.user.userId}
                        onClick={() => toggleDetails(!details)}
                    >
                        <PhaseSixIcon
                            name={details ? "chevron-up" : "chevron-down"}
                            highlighted
                        />
                    </More>
                </Column>
            </Row>
            {details && (
                <Row>
                    <Column />
                    <Column />
                    <Block>
                        <ColumnWide>
                            {details ? (
                                <LeaderBoardUserStatistic
                                    lineOne={regularlyLearned_t}
                                    lineTwo={learnedForTest_t}
                                    lineThree={newVocabulary_t}
                                    lineFour={typedLetters_t}
                                    highlighted
                                />
                            ) : null}
                        </ColumnWide>
                        <ColumnNarrow>
                            {details ? (
                                <LeaderBoardUserStatistic
                                    lineOne={props.user.learnedCards.toLocaleString("de-DE")}
                                    lineTwo={props.user.learnedCardsForTest.toLocaleString("de-DE")}
                                    lineThree={props.user.activatedCards.toLocaleString("de-DE")}
                                    lineFour={props.user.typedLetters.toLocaleString("de-DE")}
                                    highlighted
                                />
                            ) : null}
                        </ColumnNarrow>
                    </Block>
                    <Column />
                </Row>
            )}
        </Container>
    );
};

export default OwnLeaderBoardUserItem;
