// REACT
import React from "react";
import { useT } from "@transifex/react";

// STYLED COMPONENTS
import styled from "styled-components";

// COMPONENTS
import SettingsToggleInput from "../../basic/settingsToggleInput/SettingsToggleInput";
import SettingsItemWrapper from "../settingsItemWrapper/SettingsItemWrapper";

// TYPES
import { UserPreferences, ParentSettings } from "p6m-user";

export interface SettingsGeneralProps {
    userPreferences: UserPreferences;
    parentSettings?: ParentSettings;
    updateUseFirstName: (value: boolean) => any;
}

const MainWrapper = styled.div`
    margin-top: ${(props) => props.theme.base.spacing};
`;

const SettingsGeneral: React.FC<SettingsGeneralProps> = (props) => {
    const t = useT();

    const t_deactivated = t("Deactivated by your parents", { _tags: "generalSettings" });

    const t_leaderboards = t("leaderboard_label", { _tags: "generalSettings" });
    const t_leaderboardsInput = t("replace_name_name", { _tags: "generalSettings" });
    const t_leaderboardsInfo = t("replace_name_information", { _tags: "generalSettings" });

    const { userPreferences, parentSettings, updateUseFirstName } = props;

    let itemDisabled = parentSettings ? !!parentSettings.useFirstNameLock : false;
    let useFirstName = userPreferences ? !!userPreferences.useFirstName : false;

    return (
        <MainWrapper>
            <SettingsItemWrapper
                title={t_leaderboards}
                description={t_leaderboardsInfo}
                disabledDescription={t_deactivated}
                disabled={itemDisabled}
            >
                <SettingsToggleInput
                    labelText={t_leaderboardsInput}
                    checkedValue={!useFirstName}
                    onValueChanged={(checked) => (typeof checked === "undefined" ? null : updateUseFirstName(!checked))}
                />
            </SettingsItemWrapper>
        </MainWrapper>
    );
};

export default SettingsGeneral;
