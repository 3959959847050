// REACT
import React, { FunctionComponent, memo } from "react";
import { T } from "@transifex/react";

// COMPONENTS
import Modal, { Props as ModalProps, WithModalActions } from "../../../../basic/modal/Modal";
import { Col, Row } from "../../../../basic/grid";

// STYLED COMPONENTS
import { ModalFooter, ExplanationText } from "../styles";
import { BlankButton, FilledButton } from "../../../../basic/button/Button";

export type Props = {
    count: number;
    onSubmit: (actions: WithModalActions["modalActions"]) => () => void;
    onDecline: (actions: WithModalActions["modalActions"]) => () => void;
} & Partial<ModalProps>;

const Autoactivation: FunctionComponent<Props> = (props) => {
    const { count, onSubmit, onDecline, ...restProps } = props;

    return (
        <Modal
            active={true}
            title={<T _str="Already tired from copying all these cards?" />}
            footerComponent={({ modalActions }) => (
                <ModalFooter>
                    <Row horizontal="end">
                        <Col xs="auto">
                            <FilledButton
                                onClick={onDecline(modalActions)}
                                textSize="xl"
                            >
                                <T _str="Back to learning center" />
                            </FilledButton>
                        </Col>
                        <Col xs="auto">
                            <BlankButton
                                onClick={onSubmit(modalActions)}
                                textSize="xl"
                            >
                                <T
                                    _str="Skip memorising ({count})"
                                    count={count}
                                />
                            </BlankButton>
                        </Col>
                    </Row>
                </ModalFooter>
            )}
            preventCloseOnBg
            {...restProps}
        >
            <ExplanationText>
                <T
                    _str="You can auto activate cards which are left and jump right into practice with {count} additional cards"
                    count={count}
                />
            </ExplanationText>
        </Modal>
    );
};

export default memo(Autoactivation);
