import React, { FunctionComponent } from "react";
import Tooltip from "../../../basic/tooltip/Tooltip";

import { StyledCounter, StyledPhaseSixIcon } from "./styles";
import { useT } from "@transifex/react";

export type Props = {
    count?: number;
};

const RocketItem: FunctionComponent<Props> = (props) => {
    const { count } = props;
    const t = useT();

    const posStreakTranslation = t("Days learned in a row:", { _tags: "rocket,header" });
    const negStreakTranslation = t("Days not learned in a row:", { _tags: "rocket,header" });

    let tooltipContent = posStreakTranslation + " 0";
    if (count && count >= 0) {
        tooltipContent = posStreakTranslation + " " + count;
    } else if (count) {
        tooltipContent = negStreakTranslation + " " + count * -1;
    }

    return (
        <>
            {!!count && <StyledCounter>{count}</StyledCounter>}
            <Tooltip content={tooltipContent}>
                <StyledPhaseSixIcon
                    aria-label={tooltipContent}
                    aria-hidden
                    name="rocket"
                    size={"medium"}
                />
            </Tooltip>
        </>
    );
};

export default RocketItem;
