import styled from "styled-components";
import PhaseSixIcon from "../phaseSixIcon/PhaseSixIcon";

export const CheckboxWrapper = styled.div<{ checked: boolean }>`
    width: 32px;
    height: 32px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const StyledPhaseSixIcon = styled(PhaseSixIcon)<{ contrastColors: boolean }>`
    color: ${(props) => (props.contrastColors ? props.theme.colors.backgroundContrast : props.theme.colors.primary)};
`;

export const SelectableSquareDiv = styled.div<{ contrastColors: boolean }>`
    cursor: pointer;
    height: 18px;
    width: 18px;
    border: 2px solid
        ${(props) => (props.contrastColors ? props.theme.colors.backgroundContrast : props.theme.colors.text)};
    border-radius: 2px;
`;