// REACT
import React, { useCallback, useEffect, useState } from "react";

// LIBRARIES
import { useT } from "@transifex/react";
import { range } from "lodash";

// COMPONENTS
import SettingsItemWrapper from "../settingsItemWrapper/SettingsItemWrapper";
import SettingsRangeSlider from "../../basic/settingsRangeSlider/SettingsRangeSlider";

// STYLED COMPONENTS
import { MainWrapper, PhasesTable, PhasesTableDuration, PhasesTableHeadColumn, StyledPhasesTableRow } from "./styles";

export interface SettingsPhasesProps {
    updatePhases: (phasesNo: number, retention: number) => any;
    learnedPeriod: number;
    selectedNumberOfPhases: number;
    phasesDuration: number;
    userPhases: Array<number>;
}

const SettingsPhases: React.FC<SettingsPhasesProps> = (props) => {
    const t = useT();

    const t_minRetention = t("minimum_retention_period_label", {
        _tags: "phaseSettings",
    });
    const t_minRetentionInput = t("minimum_retention_period_name", {
        _tags: "phaseSettings",
    });
    const t_minRetentionInfo = t("minimum_retention_period_information", {
        _tags: "phaseSettings",
    });

    const t_repetitionsInput = t("number_of_repetitions_name", {
        _tags: "phaseSettings",
    });
    const t_repetitionsInfo = t("number_of_repetitions_information", {
        _tags: "phaseSettings",
    });

    const t_phasesDuration = t("Phases Duration", { _tags: "phaseSettings" });
    const t_phases = t("Phases", { _tags: "phaseSettings" });
    const t_duration = t("Duration (days)", { _tags: "phaseSettings" });

    const t_7days = t("7 days", { _tags: "phaseSettings" });
    const t_2weeks = t("2 weeks", { _tags: "phaseSettings" });
    const t_1month = t("1 month", { _tags: "phaseSettings" });
    const t_3month = t("3 months", { _tags: "phaseSettings" });
    const t_6month = t("6 months", { _tags: "phaseSettings" });
    const t_1year = t("1 year", { _tags: "phaseSettings" });
    const t_2year = t("2 years", { _tags: "phaseSettings" });
    const t_3year = t("3 years", { _tags: "phaseSettings" });

    const t_longMemoryInfo = t("The minimum period a card can take to be in your long-term memory is {days} days", {
        _tags: "phaseSettings",
        days: props.learnedPeriod,
    });

    const { updatePhases, selectedNumberOfPhases, phasesDuration: currentDurationInDays, userPhases } = props;

    const availableLearningIntervals = {
        intervalsInDays: [7, 14, 30, 90, 180, 365, 730, 1095],
        intervalLabels: [t_7days, t_2weeks, t_1month, t_3month, t_6month, t_1year, t_2year, t_3year],
    };

    const inputIndexOfChosenInterval = availableLearningIntervals.intervalsInDays.indexOf(currentDurationInDays);

    const [indexOfChosenInterval, setIndexOfChosenInterval] = useState(inputIndexOfChosenInterval);
    const [noPhases, setNoPhases] = useState(selectedNumberOfPhases);

    const generateTableForPhases = () =>
        userPhases.map((phase, i) => (
            <StyledPhasesTableRow key={i}>
                <td>{i + 1}</td>
                <td>{phase}</td>
            </StyledPhasesTableRow>
        ));

    const updateTable = useCallback(() => {
        updatePhases(noPhases, availableLearningIntervals.intervalsInDays[indexOfChosenInterval]);
    }, [updatePhases, noPhases, availableLearningIntervals, indexOfChosenInterval]);

    useEffect(() => {
        if (selectedNumberOfPhases !== noPhases || indexOfChosenInterval !== inputIndexOfChosenInterval) {
            updateTable();
        }
    }, [indexOfChosenInterval, noPhases]); // eslint-disable-line

    return (
        <MainWrapper>
            <SettingsItemWrapper
                title={t_minRetention}
                description={t_minRetentionInfo}
            >
                <SettingsRangeSlider
                    label={t_minRetentionInput}
                    optionsValueList={range(0, availableLearningIntervals.intervalLabels.length)}
                    optionsLabelList={availableLearningIntervals.intervalLabels}
                    defaultValue={indexOfChosenInterval}
                    debounced={false}
                    standardOptionIndex={3}
                    onChange={(value) => setIndexOfChosenInterval(value)}
                />
            </SettingsItemWrapper>

            <SettingsItemWrapper description={t_repetitionsInfo}>
                <SettingsRangeSlider
                    label={t_repetitionsInput}
                    optionsValueList={range(4, 21)}
                    defaultValue={noPhases}
                    debounced={false}
                    standardOptionIndex={2}
                    onChange={(value) => setNoPhases(value)}
                />
            </SettingsItemWrapper>

            <SettingsItemWrapper
                title={t_phasesDuration}
                description={t_longMemoryInfo}
            >
                <PhasesTable>
                    <thead>
                        <StyledPhasesTableRow>
                            <PhasesTableHeadColumn>{t_phases}</PhasesTableHeadColumn>
                            <PhasesTableDuration>{t_duration}</PhasesTableDuration>
                        </StyledPhasesTableRow>
                    </thead>
                    <tbody>{generateTableForPhases()}</tbody>
                </PhasesTable>
            </SettingsItemWrapper>
        </MainWrapper>
    );
};

export default SettingsPhases;
