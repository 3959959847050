import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../store/store";

interface ApproveState {
    subjectId: string;
}

const initialState: ApproveState = {
    subjectId: "",
};

export const approveSlice = createSlice({
    name: "approve",
    initialState,
    reducers: {
        setSubjectId: (state, action: PayloadAction<string>) => {
            state.subjectId = action.payload;
        },
    },
});

/* EXPORTS */
export const { actions, reducer } = approveSlice;

export const selectors = {
    subjectId: (state: RootState) => state.approve.subjectId,
};
