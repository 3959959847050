import axios, { AxiosPromise } from "axios";
import {
    IdToOwnerId,
    SubjectReportActivityData,
    SubjectReportPhasesData,
    ReportPeriod,
    SubjectReportDifficultCardData,
} from "p6m-subjects";
import { IResponse } from "p6m-response";

export const getReportPhasesData = (
    userId: string,
    subjectIdToOwner: IdToOwnerId
): AxiosPromise<IResponse<SubjectReportPhasesData>> => {
    let data = {
        userId: userId,
        subjectId: subjectIdToOwner,
    };
    return axios.post("report/phases", data);
};

export const getReportActivityData = (
    userId: string,
    subjectIdToOwner: IdToOwnerId
): AxiosPromise<IResponse<SubjectReportActivityData>> => {
    let data = {
        userId: userId,
        subjectId: subjectIdToOwner,
    };
    return axios.post("report/activity", data);
};

export const getReportPreviewData = (
    userId: string,
    subjectIdToOwner: IdToOwnerId
): AxiosPromise<IResponse<SubjectReportActivityData>> => {
    let data = {
        userId: userId,
        subjectId: subjectIdToOwner,
    };
    return axios.post("report/prediction", data);
};

export const getReportDifficultData = (
    userId: string,
    subjectIdToOwner: IdToOwnerId,
    reportPeriod: ReportPeriod
): AxiosPromise<IResponse<SubjectReportDifficultCardData[]>> => {
    let data = {
        userId: userId,
        subjectId: subjectIdToOwner,
        period: reportPeriod,
    };
    return axios.post("report/difficult", data);
};

export const getReportInputControlData = (
    userId: string,
    subjectIdToOwner: IdToOwnerId,
    reportPeriod: ReportPeriod
): AxiosPromise<IResponse<SubjectReportActivityData[]>> => {
    let data = {
        userId: userId,
        subjectId: subjectIdToOwner,
        period: reportPeriod,
    };
    return axios.post("report/inputControl", data);
};
