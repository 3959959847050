import axios, { AxiosPromise } from "axios";
import { IResponse } from "p6m-response";
import { DictionarySearchResults, DictionaryWordResults } from "p6m-dictionary";

export function searchDictionary(
    searchString: string,
    motherTongue: string,
    targetLang: string
): AxiosPromise<IResponse<DictionarySearchResults>> {
    return axios.get("/autoComplete/" + searchString + "/" + motherTongue + "/" + targetLang);
}
export function getWordDetailed(
    word: string,
    motherTongue: string,
    targetLang: string
): AxiosPromise<IResponse<DictionaryWordResults>> {
    return axios.get(
        "/ponsTranslation/" + encodeURIComponent(word) + "/" + motherTongue + targetLang + "/" + motherTongue
    );
}
