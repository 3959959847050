import React from "react";
import { IUserGoals } from "p6m-goalsData";
import { GoalsData, GoalKeys } from "p6m-goalsData";
import { Col, Row, Container } from "../../components/basic/grid";
import { AchievementsStatistic } from "./AchievementsStatistic";
import AchievementItem from "./AchievementItem/AchievementItem";

type Props = {
    userGoals: IUserGoals;
    goalsToShow: GoalKeys[];
    goals: GoalsData;
};

const Achievements: React.FC<Props> = (props) => {
    const {
        userGoals: { currentDaysInAStreak = 0, maxDaysInAStreak = 0, practicedDays = 0 },
        goals,
    } = props;

    return (
        <div>
            <Container
                fullWidth
                data-qa="achievements-page"
            >
                <Row>
                    <Col xs={12}>
                        <AchievementsStatistic
                            {...{
                                currentDaysInAStreak,
                                maxDaysInAStreak,
                                practicedDays,
                            }}
                        />
                    </Col>
                </Row>
                <Row
                    cols={{
                        xs: 12,
                        s: 2,
                        l: 4,
                    }}
                >
                    {(Object.keys(goals) as GoalKeys[]).map((key) => {
                        const goal = goals[key];
                        if (props.goalsToShow.includes(key)) {
                            const achieved = props.userGoals.goals[key];
                            return (
                                <Col
                                    key={key}
                                    data-qa={`achievements-item achievement_${key} ${
                                        achieved ? "achieved" : "not-achieved"
                                    }`}
                                >
                                    <AchievementItem
                                        achieved={achieved}
                                        {...goal}
                                    />
                                </Col>
                            );
                        }
                        return null;
                    })}
                </Row>
            </Container>
        </div>
    );
};

export default Achievements;
