// DEPS
import React, { FunctionComponent, useState, useEffect, useCallback, useMemo } from "react";
import he from "he";

// REDUX
import { useSelector, useDispatch } from "react-redux";
import { selectors, actions } from "../../../../redux/learning/learningSlice";
import { getCardContentInfo, getCardData } from "../../../../helpers/Cards";
import { getPlaylist } from "../../../../helpers/Audio";

// TYPES
import { SubjectUnitCard } from "p6m-subjects";

// COMPONENTS
import Component, { Props as ComponentProps } from "./DragDrop";

export const DragDrop: FunctionComponent = () => {
    const cards = useSelector(selectors.currentSliceCards);
    const autoplay = useSelector(selectors.playAudio);

    const [resolvedIds, setResolvedIds] = useState<string[]>([]);
    const [selectedCardId, setSelectedCardId] = useState<string>("");

    const dispatch = useDispatch();

    useEffect(() => {
        if (!autoplay || !resolvedIds.length) return;
        const cardId = resolvedIds[resolvedIds.length - 1].split("__")[0];
        const card = cards.find(({ cardIdToOwner: { id } }) => id === cardId);
        if (!card) return;

        const {
            cardContent: { answer },
        } = card;
        const audio = getCardData(answer).audios[0];
        if (!audio) return;

        const playlist = getPlaylist();
        playlist.stop(); // stop prev audios
        playlist.setAudios([audio], true); // start play new audio
    }, [cards, resolvedIds, autoplay]);

    const onStart = useCallback((id: string) => {
        setSelectedCardId(id);
    }, []);

    const onDrop = useCallback(
        (dropId: string): boolean => {
            if (selectedCardId) setSelectedCardId("");
            if (!selectedCardId || selectedCardId === dropId) return false;
            if (selectedCardId.split("__")[0] !== dropId.split("__")[0]) return true;
            setResolvedIds((prevState) => [...prevState, dropId, selectedCardId]);
            dispatch(actions.incrementDragNDropResolvedCards());
            return false;
        },
        [dispatch, selectedCardId]
    );

    const componentCards: Pick<ComponentProps["cards"][number], "id" | "title" | "question">[] = useMemo(() => {
        return cards
            .reduce(
                (
                    result: Pick<ComponentProps["cards"][number], "id" | "title" | "question">[],
                    card: SubjectUnitCard
                ) => {
                    const {
                        cardContent: { question, answer },
                        cardIdToOwner: { id },
                    } = card;
                    result.push({
                        id,
                        title: he.decode(getCardContentInfo(question, "title")),
                        question: true,
                    });
                    result.push({
                        id,
                        title: he.decode(getCardContentInfo(answer, "title")),
                        question: false,
                    });
                    return result;
                },
                []
            )
            .sort(() => Math.random() - 0.5);
    }, [cards]);

    const result: ComponentProps["cards"] = useMemo(() => {
        if (resolvedIds.length >= componentCards.length) return [];
        return componentCards.map((card, i: number) => {
            const id: string = `${card.id}__${i}`;
            const resolved: boolean = resolvedIds.includes(id);
            const selected: boolean = id === selectedCardId;
            return {
                ...card,
                id,
                resolved,
                selected,
                onDrop: !resolved ? onDrop : undefined,
                onStart: !resolved ? onStart : undefined,
            };
        });
    }, [componentCards, resolvedIds, selectedCardId, onDrop, onStart]);

    useEffect(() => {
        if (!componentCards.length || resolvedIds.length < componentCards.length) return;
        setResolvedIds([]);
        dispatch(actions.activateSlice());
    }, [resolvedIds.length, componentCards.length, dispatch]);

    return <Component cards={result} />;
};
