//LIBRARY
import React, { FunctionComponent, ReactElement } from "react";

//REDUX
import { useDispatch, useSelector } from "react-redux";
import { actions as modalsActions, selectors as modalsSelectors } from "../../redux/modal/modalSlice";
import { actions as settingsActions } from "../../redux/settings/settingsSlice";

//COMPONENTS
import GlobalModal from "./GlobalModal";
import SettingsWrapper from "../../wrapper/SettingsWrapper/SettingsWrapper";
import ConfirmEmailModal from "../../components/connected/confirmEmailModal";
import ShareModal from "../../components/connected/shareModal";
import AddSubjectModal from "../../components/connected/addSubjectModal";
import ChangeCardSubjectModal from "../../components/connected/changeCardSubjectModal";
import ResetSubjectCardsModal from "../../components/connected/resetSubjectCardsModal";
import ConfirmEditModal from "../../components/connected/confirmEditModal";
import DeleteContentModal from "../../components/connected/deleteContentModal";
import DeleteMediaModal from "../../components/connected/deleteMediaModal";
import { GDPRModal } from "../../components/connected/GDPRModal";
import AgreementModal from "../../components/connected/agreementModal";
import SelectIncludedContentModal from "../../components/connected/selectIncludedContentModal";
import DataProtectionModal from "../../components/connected/dataProtectionModal";
import StrictUserImpressumModal from "../../components/connected/impressum";
import GrammarTutorPromotionModal from "../../components/connected/grammarTutorPromotionModal";
import { StrictPremiumModal } from "../../components/connected/strictPremiumModal";
import SwitchInfoModal from "../../components/connected/switchInfoModal";

//UTILS
import { GlobalModalView, SettingsView } from "../../helpers/Modal";
import ModifyCardAttributes from "../../components/connected/modifyCardAttributes";

const ModalWrapper: FunctionComponent = () => {
    const modalType = useSelector(modalsSelectors.modalView);
    const dispatch = useDispatch();

    let modalComponent: ReactElement | string = "";
    let active = true;
    let preventClose = false;
    let optionalWidth;

    switch (modalType) {
        case GlobalModalView.None:
            active = false;
            break;
        case GlobalModalView.Settings:
            modalComponent = <SettingsWrapper />;
            break;
        case GlobalModalView.MailAddressConfirmation:
            modalComponent = <ConfirmEmailModal />;
            break;
        case GlobalModalView.Share:
            modalComponent = <ShareModal />;
            break;
        case GlobalModalView.AddSubject:
            modalComponent = <AddSubjectModal />;
            optionalWidth = 820;
            break;
        case GlobalModalView.ChangeCardSubject:
            modalComponent = <ChangeCardSubjectModal />;
            break;
        case GlobalModalView.ResetSubjectCards:
            modalComponent = <ResetSubjectCardsModal />;
            break;
        case GlobalModalView.ConfirmEdit:
            modalComponent = <ConfirmEditModal />;
            break;
        case GlobalModalView.DeleteContent:
            modalComponent = <DeleteContentModal />;
            break;
        case GlobalModalView.ModifyCardAttributes:
            modalComponent = <ModifyCardAttributes />;
            break;
        case GlobalModalView.DeleteMedia:
            modalComponent = <DeleteMediaModal />;
            break;
        case GlobalModalView.GDPRModal:
            modalComponent = <GDPRModal />;
            break;
        case GlobalModalView.Agreement:
            modalComponent = <AgreementModal />;
            preventClose = true;
            break;
        case GlobalModalView.ChooseIncludedContent:
            modalComponent = <SelectIncludedContentModal />;
            optionalWidth = 820;
            break;
        case GlobalModalView.DataProtectionIframe:
            modalComponent = <DataProtectionModal />;
            break;
        case GlobalModalView.StrictUserImpressumIframe:
            modalComponent = <StrictUserImpressumModal />;
            break;
        case GlobalModalView.Welcome:
            modalComponent = <AddSubjectModal />;
            optionalWidth = 820;
            break;
        case GlobalModalView.PromoteGrammarTutor:
            modalComponent = <GrammarTutorPromotionModal />;
            break;
        case GlobalModalView.StrictPremium:
            modalComponent = <StrictPremiumModal />;
            break;
        case GlobalModalView.SwitchInfo:
            modalComponent = <SwitchInfoModal />;
            break;
        default:
            break;
    }

    const close = preventClose
        ? undefined
        : () => {
              dispatch(modalsActions.setModalView(GlobalModalView.None));
              dispatch(settingsActions.setSettingsView(SettingsView.Main));
          };

    if (active) {
        return (
            <GlobalModal
                close={close}
                width={optionalWidth}
                style={modalType === GlobalModalView.Welcome ? { zIndex: 10000 } : {}}
            >
                {modalComponent}
            </GlobalModal>
        );
    }
    return null; // Don't show
};

export default ModalWrapper;
