// DEPS
import React, { FunctionComponent, memo } from "react";
import { useT } from "@transifex/react";

// STYLES
import { ModalHeader } from "../../basic/modal/styles";
import { Background, StyledIframe } from "../GDPRModal/styles";
import { ButtonsContainer } from "../changeCardSubjectModal/styles";

// COMPONENTS
import { DefaultFocus } from "../../basic/defaultFocus/DefaultFocus";
import { FilledButton, OutlinedButton } from "../../basic/button/Button";

export interface AgreementModalProps {
    url: string;
    accept: () => void;
    decline: () => void;
}

const AgreementModal: FunctionComponent<AgreementModalProps> = (props) => {
    const t = useT();
    const { url, accept, decline } = props;

    const title_t = t("In order to obtain access you need to accept agreement", { _tags: "modal,title" });
    const accept_t = t("accept", { _tags: "modal,agb,button" });
    const decline_t = t("decline", { _tags: "modal,agb,button" });

    return (
        <Background>
            <DefaultFocus />
            <ModalHeader>{title_t}</ModalHeader>
            <StyledIframe src={url} />
            <ButtonsContainer>
                <OutlinedButton
                    onClick={decline}
                    upperCase
                    textSize="xl"
                    useModalZIndex
                    useVerticalPadding
                >
                    {decline_t}
                </OutlinedButton>
                <FilledButton
                    onClick={accept}
                    upperCase
                    textSize="xl"
                    useModalZIndex
                    useVerticalPadding
                >
                    {accept_t}
                </FilledButton>
            </ButtonsContainer>
        </Background>
    );
};

export default memo(AgreementModal);
