// LIBRARIES
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, Route, useHistory } from "react-router-dom";

// REDUX
import { actions as userActions, selectors as userSelectors } from "../../redux/user/userSlice";

const PrivateRoute: React.FunctionComponent<any> = ({ children, ...rest }) => {
    const history = useHistory();
    const dispatch = useDispatch();

    const user = useSelector(userSelectors.user);

    const isLoggedIn = !!user.jossoSessionId;
    const userDataExists = !!Object.keys(user).length;
    const missingAgreementAcceptance = user.roles ? user.roles.every((role) => role !== "p6o.p6o2") : false;

    useEffect(() => {
        if (missingAgreementAcceptance) history.push("/agreement");
        if (userDataExists && !isLoggedIn) {
            dispatch(userActions.logout());
        }
    }, [dispatch, missingAgreementAcceptance, isLoggedIn, history, userDataExists]);

    return <Route {...rest}>{isLoggedIn ? children : <Redirect to="/login" />}</Route>;
};

export default PrivateRoute;
