// REACT
import React, { FunctionComponent, useEffect, useState } from "react";
import { T } from "@transifex/react";

// UTILS
import timeFormat from "../../../../helpers/timeFormat";
import { getMaxTimeByScore } from "../../../../helpers/MatchGame";

// STYLED COMPONENTS
import { Title } from "../GamePagination/styles";

export type Props = {
    tick?: number;
    time?: number;
    pause?: boolean;
    stop?: boolean;
};

const TimeStatistic: FunctionComponent<Props> = (props) => {
    const { time = getMaxTimeByScore(1), tick = 1000, pause = false, stop = false } = props;

    const [currentTime, setTime] = useState<number>(0);

    useEffect(() => {
        if (!stop) return;
        setTime(0);
        // eslint-disable-next-line
    }, [stop]);

    useEffect(() => {
        if (pause || stop) return;
        if (currentTime > time) return;

        const resultTime: number = 1 + currentTime;

        let timeout = setTimeout(() => {
            setTime(resultTime);
        }, tick);

        return () => clearTimeout(timeout);
    }, [time, currentTime, tick, pause, stop]);

    return (
        <Title right>
            <T
                _str="Time: {currentTime} of {time}"
                currentTime={<b>{timeFormat(currentTime)}</b>}
                time={timeFormat(time)}
            />
        </Title>
    );
};

export default TimeStatistic;
