//DEPENDENCIES
import styled from "styled-components";

export const SubTitle = styled.h2`
    margin: 5px 0 0 0;
    font-size: ${({ theme: { base } }) => base.bigSize};
    color: ${({ theme: { colors } }) => colors.text};
    text-align: center;
    font-weight: normal;

    > span {
        color: ${({ theme: { colors } }) => colors.text};
    }
`;

export const ModalFooter = styled.div`
    padding: ${({ theme: { base } }) => base.spacingSmall};
`;

export const ExplanationText = styled.p`
    padding: ${({ theme: { base } }) => base.spacingSmall};
    color: ${({ theme: { colors } }) => colors.text};
`;
