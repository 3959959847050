//DEPENDENCIES
import React from "react";
import { T } from "@transifex/react";

//TYPES
import { TestsType } from "p6m-tests";

//COMPONENTS
import PhaseSixIcon from "../../../components/basic/phaseSixIcon/PhaseSixIcon";

//STYLES
import { NoResultsList, NoResultsListItem, NoResultsTitle, NoResults, NoResultsText } from "../styles";

export const NoTests = ({ testsType }: { testsType: TestsType }) => {
    if (testsType === "OWN_TESTS") {
        return (
            <NoResults>
                <PhaseSixIcon
                    highlighted
                    size="big"
                    name="clipboard"
                />
                <NoResultsTitle>
                    <T
                        _str="Learn for a test"
                        _tags="tests"
                    />
                </NoResultsTitle>
                <NoResultsText>
                    <T
                        _str="You can train all vocabulary - including words that are not currently due."
                        _tags="tests"
                    />
                </NoResultsText>

                <NoResultsList>
                    <NoResultsListItem>
                        <PhaseSixIcon
                            highlighted
                            size="small"
                            name="checked"
                        />
                        <T
                            _str="Create and start learning"
                            _tags="tests"
                        />
                    </NoResultsListItem>
                    <NoResultsListItem>
                        <PhaseSixIcon
                            highlighted
                            size="small"
                            name="checked"
                        />
                        <T
                            _str="Find the right words"
                            _tags="tests"
                        />
                    </NoResultsListItem>
                    <NoResultsListItem>
                        <PhaseSixIcon
                            highlighted
                            size="small"
                            name="checked"
                        />
                        <T
                            _str="Repeat and improve"
                            _tags="tests"
                        />
                    </NoResultsListItem>
                </NoResultsList>
            </NoResults>
        );
    }

    return (
        <NoResults>
            <PhaseSixIcon
                highlighted
                size="big"
                name="school-building"
            />
            <NoResultsTitle>
                <T
                    _str="Learn regulary"
                    _tags="tests"
                />
            </NoResultsTitle>

            <NoResultsText>
                <T
                    _str="Your teacher can give you exercises."
                    _tags="tests"
                />
            </NoResultsText>
            <NoResultsText>
                <T
                    _str="Haven’t received any exercises yet?{br} Ask at your school!"
                    _tags="tests"
                    br={<br />}
                />
            </NoResultsText>
        </NoResults>
    );
};
